import axios from "axios";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CardBody,
  Card,
  Table,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import { useState, useEffect } from "react";
// import { BASEURL, RECORDING_BASE_URL } from "config/api/URL";
// import { RECORDING_PATH } from "../constant";
import { RECORDING_BASE_URL } from "config/api/URL";
import moment from "moment";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
const Information = ({ viewReqList, modal, toggle }) => {
  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader style={{ backgroundColor: "#d69929" }} toggle={toggle}>
        <h3 style={{ color: "white" }}>Details Of Feedback</h3>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col lg="2" md="2" sm="6">
            <label>ID:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{viewReqList.id}</label>
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Name:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{viewReqList.name}</label>
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Email:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{viewReqList.email}</label>
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Subject:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{viewReqList.subject}</label>
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Date & Time:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>
              {moment(viewReqList.createdAt).format("MMMM Do YYYY, h:mm:ss a")}{" "}
            </label>
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="2">
            <label>Message:</label>
          </Col>
          <Col lg="8" md="8" sm="8">
            <p style={{ wordWrap: "break-word" }}>{viewReqList.message}</p>
          </Col>
        </Row>
      </ModalBody>
      {/* <ModalFooter style={{ backgroundColor: "#d69929" }}>
        <Button
          style={{ backgroundColor: "#015652", color: "white" }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default Information;
