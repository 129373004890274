import React, { useEffect, useRef, useState } from "react";
// import Loader from "react-loader-spinner";
// import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table, Label } from "reactstrap";
import swal from "sweetalert";
// import ComponentToPrint from "./ComponentToPrint";
// import PriceUpdateModal from "./PriceUpdateModal";

const TableBankAppDetailsListView = ({ paginatedPosts }) => {
  console.log(paginatedPosts);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [oldPrice, setOldPrice] = useState("");

  const ToggleViewReqListModal = (price) => {
    setIsViewModalOpen(!isViewModalOpen);
    setOldPrice(price);
  };
  const onSuccessCancelation = () => {
    swal("Form Cancel successfully!", "", "success");
  };
  const onFailureCancelation = () => {};
  // const cancelForm = (formNo) => {
  //   let body = {
  //     FormNo: formNo,
  //   };
  //   _FormCancelation(body, onSuccessCancelation, onFailureCancelation);
  // };
  return (
    <div>
      {/* <PriceUpdateModal
        toggle={ToggleViewReqListModal}
        modal={isViewModalOpen}
        oldPrice={oldPrice}
        _UpdateFormPricing_Middleware={_UpdateFormPricing_Middleware}
      /> */}
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Name</th>
            <th scope="col">Application Id</th>
            <th scope="col">Phone</th>
            <th scope="col">Payment Ref</th>
            <th scope="col">Status</th>
            {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {paginatedPosts !== null &&
          paginatedPosts !== undefined &&
          paginatedPosts.length > 0 ? (
            paginatedPosts?.map((request, index) => {
              console.log(request);
              return (
                <tr key={request.id}>
                  <td>{index + 1}</td>
                  <td>{request?.name}</td>
                  <td>{request?.applicationId}</td>
                  <td>{request?.phone}</td>
                  <td>{request?.paymentRef}</td>
                  <td>{request?.status}</td>
                  {/* <td>
                    {request?.status == "Pending" ? (
                      <span className="badge badge-danger">
                        {request?.status}
                      </span>
                    ) : request?.status == "Paid" ? (
                      <span className="badge badge-success">
                        {request?.status}
                      </span>
                    ) : (
                      ""
                    )}
                  </td> */}
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableBankAppDetailsListView;
