import {
  CUSTOMER,
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILURE,
  PLOTTYPE,
  PLOTTYPE_SUCCESS,
  PLOTTYPE_FAILURE,
  CUSTOMERDRAFT_SUCCESS,
  CUSTOMERDRAFT,
  CUSTOMERDRAFT_FAILURE,
  CUSTOMERDRAFTID,
  CUSTOMERDRAFTID_FAILURE,
  CUSTOMERDRAFTID_SUCCESS,
  CUSTOMERUPDATE,
  CUSTOMERUPDATE_FAILURE,
  CUSTOMERUPDATE_SUCCESS,
  CUSTOMERDRAFTUPDATE,
  CUSTOMERDRAFTUPDATE_FAILURE,
  CUSTOMERDRAFTUPDATE_SUCCESS,
  VERIFY_BY_CNIC,
  VERIFY_BY_CNIC_SUCCESS,
  VERIFY_BY_CNIC_FAILURE,
} from "../constant";

export class VerifyResidentialTypeByCNIC {
  static verify() {
    return {
      type: VERIFY_BY_CNIC,
    };
  }
  static verify_Success(response) {
    return {
      type: VERIFY_BY_CNIC_SUCCESS,
      payload: response,
    };
  }
  static verify_Failure(error) {
    return {
      type: VERIFY_BY_CNIC_FAILURE,
      error,
    };
  }
}
export class CustomerDraftUpdateDetail {
  static CustomerDraftUpdate() {
    return {
      type: CUSTOMERDRAFTUPDATE,
    };
  }
  static CustomerDraftUpdate_Success(response) {
    return {
      type: CUSTOMERDRAFTUPDATE_SUCCESS,
      payload: response,
    };
  }
  static CustomerDraftUpdate_Failure(error) {
    return {
      type: CUSTOMERDRAFTUPDATE_FAILURE,
      error,
    };
  }
}

export class CustomerUpdateDetail {
  static CustomerUpdate() {
    return {
      type: CUSTOMERUPDATE,
    };
  }
  static CustomerUpdate_Success(response) {
    return {
      type: CUSTOMERUPDATE_SUCCESS,
      payload: response,
    };
  }
  static CustomerUpdate_Failure(error) {
    return {
      type: CUSTOMERUPDATE_FAILURE,
      error,
    };
  }
}

export class CustomerDraftIdDetail {
  static CustomerDraftId() {
    return {
      type: CUSTOMERDRAFTID,
    };
  }
  static CustomerDraftId_Success(response) {
    return {
      type: CUSTOMERDRAFTID_SUCCESS,
      payload: response,
    };
  }
  static CustomerDraftId_Failure(error) {
    return {
      type: CUSTOMERDRAFTID_FAILURE,
      error,
    };
  }
}

export class CustomerDraftDetail {
  static CustomerDraft() {
    return {
      type: CUSTOMERDRAFT,
    };
  }
  static CustomerDraft_Success(response) {
    return {
      type: CUSTOMERDRAFT_SUCCESS,
      payload: response,
    };
  }
  static CustomerDraft_Failure(error) {
    return {
      type: CUSTOMERDRAFT_FAILURE,
      error,
    };
  }
}

export class CustomerDetail {
  static Customer() {
    return {
      type: CUSTOMER,
    };
  }
  static Customer_Success(response) {
    return {
      type: CUSTOMER_SUCCESS,
      payload: response,
    };
  }
  static Customer_Failure(error) {
    return {
      type: CUSTOMER_FAILURE,
      error,
    };
  }
}

export class PlotTypeDetail {
  static PlotType() {
    return {
      type: PLOTTYPE,
    };
  }
  static PlotType_Success(response) {
    return {
      type: PLOTTYPE_SUCCESS,
      payload: response,
    };
  }
  static PlotType_Failure(error) {
    return {
      type: PLOTTYPE_FAILURE,
      error,
    };
  }
}

// export class PlotSizeDetail {
//   static PlotSize() {
//     return {
//       type: PLOTSIZE,
//     };
//   }
//   static PlotSize_Success(response) {
//     return {
//       type: PLOTSIZE_SUCCESS,
//       payload: response,
//     };
//   }
//   static PlotSize_Failure(error) {
//     return {
//       type: PLOTSIZE_FAILURE,
//       error,
//     };
//   }

// }
