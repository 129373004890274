import {
 
  BANKLIST,BANKLIST_SUCCESS,BANKLIST_FAILURE, 
  BANKDELETE,BANKDELETE_SUCCESS,BANKDELETE_FAILURE

  
} from "../constant";
const INITIAL_STATE = {

  response:null,
  del:null,

 
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;

      case BANKDELETE:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          del: null,
          error: {},
        };
      case BANKDELETE_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          del: action.payload,
        };
      case BANKDELETE_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


  case BANKLIST:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          response: null,
          error: {},
        };
      case BANKLIST_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          response: action.payload,
        };
      case BANKLIST_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };

     
  }
};
