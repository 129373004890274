import axios from "axios";
import { Verify, Blocked } from "../action/index";
import { VERIFY_PATH, BLOCKED_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const VerifyMiddleware = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(Verify._Verify());
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${VERIFY_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(Verify._Verify_Success(res.data.data));
      dispatch(onSuccess(res.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
      }
    });
};

export const BlockedMiddleware = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(Blocked._Blocked());
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${BLOCKED_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(Blocked._Blocked_Success(res.data.data));
      dispatch(onSuccess(res.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
      }
    });
};
