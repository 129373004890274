import {
 
  BANK,BANK_SUCCESS,BANK_FAILURE, 
  BANKEDIT,BANKEDIT_SUCCESS,BANKEDIT_FAILURE
} from "../constant";
const INITIAL_STATE = {

  bank:null,
  edit:null,
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
      case BANKEDIT:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          edit: null,
          error: {},
        };
      case BANKEDIT_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          edit: action.payload,
        };
      case BANKEDIT_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


  case BANK:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          bank: null,
          error: {},
        };
      case BANK_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          bank: action.payload,
        };
      case BANK_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };

     
  }
};
