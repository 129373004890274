import {
  DEALER_REG_SUCCESS,
  DEALER_REG,
  DEALER_REG_FAILURE,
} from "../constant";

export class DealerRegAction {
  static Registration() {
    return {
      type: DEALER_REG,
    };
  }
  static Registration_Success(response) {
    return {
      type: DEALER_REG_SUCCESS,
      payload: response,
    };
  }
  static Registration_Failure(error) {
    return {
      type: DEALER_REG_FAILURE,
      error,
    };
  }
}
