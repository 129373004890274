import React, { useState, useEffect, useRef } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import TableOfBankList from "./TableOfBranchList";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const Filteration = (props) => {
  const history = useHistory();
  const inputFileRef = useRef(null);
  const [excelFile, setExcelFile] = useState({
    docs: [],
  });
  const onSuccess = () => {};
  const onFailure = () => {};
  const onFileUploadSuccess = (message) => {
    swal("Succesful!", message, "success");
    setExcelFile({
      docs: [],
    });
    if (inputFileRef.current?.value) {
      inputFileRef.current.value = null;
    }
  };
  const onFileUploadFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  // let dataInArrayForPaginaion = [];
  // useEffect(() => {
  //   setPosts(null);
  //   setPageNumber(1);
  //   if (props.Client !== null && props.Client !== undefined) {
  //     for (let i = 0; i < props.Client.length; i++) {
  //       dataInArrayForPaginaion.push(props.Client[i]);
  //       setPosts(props.Client);
  //     }
  //   } else {
  //   }
  // }, [props.Client]);
  useEffect(() => {
    if (props.responseBranch) {
      setPosts(props.responseBranch);
    }
  }, [props.responseBranch]);

  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);

  ///////////----------Pagination--------------//////////////
  
  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const handleSendData = () => {
    let formData = new FormData();
    let file = excelFile.docs[0];
    formData.append("file", file);
    props._FileUpload_Middleware(
      formData,
      onFileUploadSuccess,
      onFileUploadFailure
    );
  };
  useEffect(() => {
    props.GetBranchListMiddleware(pageNumber, noOfRows, onSuccess, onFailure);
  }, [pageNumber, noOfRows]);
  const addNewBranch = () => {
    //history.push({pathname:'/user/BankList'})
    let path = "/user/Branch";
    history.push(path);
  };
  useEffect(() => {
    props.GetBranchListMiddleware(pageNumber, noOfRows, onSuccess, onFailure);
  }, [props.del]);

  return (
    <CardHeader className="border-0">
      <Row>
        <Col lg="2" md="2" sm="6">
          <h2>Branch List</h2>
        </Col>
        <Col lg="8" md="8" sm="6"></Col>
        <Col lg="2" md="2" sm="6">
          <Button
            style={{ backgroundColor: "#015652", color: "white" }}
            size="sm"
            onClick={addNewBranch}
            id="info"
          >
            <span className="btn-inner--icon">Add New Branch</span>
          </Button>
        </Col>
      </Row>
      <hr />

      {/* <CardBody>
        <form>
            <Row>
            <Col lg="2" md="2" sm="6">
            <label className="form-control-label" for="input-username">
                 Search
                </label>
                <input
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9-+]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={13}
                  onBlur={() => CheckFields("cnic")}
                  id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.Cnic}
                  onChange={(e) => onChange(e.target.value, "Cnic")}
                ></input>
              </Col>
            </Row>
        </form>
      </CardBody> */}
      <CardBody>
        <Row>
          <Col lg="2" md="2" sm="6">
            <Label className="form-control-label mr-2" for="input-username">
              Upload Branches:
            </Label>
            <Input
              type="file"
              name="docs"
              style={{ color: "rgba(0, 0, 0, 0)", marginBottom: "30px" }}
              onChange={(e) => {
                let arr = [];
                Array.from(e.target.files).map((file) => {
                  arr.push(file);
                });
                setExcelFile({
                  ...excelFile,
                  docs: excelFile.docs.concat(arr),
                });
              }}
              ref={inputFileRef}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Col>
          <Col lg="2" md="2" sm="6">
            <Button
              style={{
                backgroundColor: "#015652",
                color: "white",
                marginTop: "30px",
              }}
              size="sm"
              onClick={handleSendData}
              id="info"
            >
              <span className="btn-inner--icon">Upload File</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
        </Row>
        <br />
        <TableOfBankList
          DeleteBranchMiddleware={props.DeleteBranchMiddleware}
          del={props.del}
          // onChangeNoOfRows={onChangeNoOfRows}
          paginatedPosts={paginatedPosts}

          //response={props.response}
        ></TableOfBankList>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
