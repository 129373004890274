import AddNewOrdersForm from "views/StaffViews/AddNewOrders/container";
import StaffRequestView from "views/StaffViews/StaffRequest/container";
import StaffSearchView from "views/StaffViews/StaffSearch/container";
import SendQuery from "../components/Support/Sendquery/container";
import QueryList from "../components/Support/Querieslist/container";
var routes = [
  {
    path: "/addNewOrder",
    name: "Generate Form",
    icon: "fas fa-angle-right",
    component: AddNewOrdersForm,
    layout: "/user",
    isView: true,
  },
  {
    path: "/requestStaffView",
    name: "Form Requests",
    icon: "fas fa-angle-right",
    component: StaffRequestView,
    layout: "/user",
    isView: true,
  },
  {
    path: "/staffSearchView",
    name: "Form Submit",
    icon: "fas fa-angle-right",
    component: StaffSearchView,
    layout: "/user",
    isView: true,
  },
  {
    name: "Support",
    icon: "fab fa-wpforms ",
    isView: true,
    subNav: [
      {
        path: "/sendQuery",
        name: "Send query",
        icon: "fas fa-angle-right",
        component: SendQuery,
        layout: "/user",
        isView: true,
      },
      {
        path: "/getQueries",
        name: "View Queries List",
        icon: "fas fa-angle-right",
        component: QueryList,
        layout: "/user",
        isView: true,
      },
    ],
  },
];
export default routes;
