export const BANK =  "BANK";
export const BANK_SUCCESS =  "BANK_SUCCESS";
export const BANK_FAILURE =  "BANK_FAILURE";
export const BANK_PATH = "bank";

export const BANKEDIT =  "BANKEDIT";
export const BANKEDIT_SUCCESS =  "BANKEDIT_SUCCESS";
export const BANKEDIT_FAILURE =  "BANKEDIT_FAILURE";
export const BANKEDIT_PATH = "bank";


