export const CUSTOMER =  "CUSTOMER";
export const CUSTOMER_SUCCESS =  "CUSTOMER_SUCCESS";
export const CUSTOMER_FAILURE =  "CUSTOMER_FAILURE";
export const CUSTOMER_PATH = "customs/user/signup/for-admin";

export const CUSTOMEREDIT =  "CUSTOMEREDIT";
export const CUSTOMEREDIT_SUCCESS =  "CUSTOMEREDIT_SUCCESS";
export const CUSTOMEREDIT_FAILURE =  "CUSTOMEREDIT_FAILURE";
export const CUSTOMEREDIT_PATH = "customs";


