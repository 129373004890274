import axios from "axios";
import {
  FetchBanksAction,
  FetchFormDepositAction,
  FormGenerateAction,
} from "../action/index";
import {
  FETCH_BANKS_PATH,
  FORM_DEPOSIT_PATH,
  FORM_GENERATE_PATH,
} from "../constant";
import { BASEURL } from "config/api/URL";

export const FetchBanksMiddleware = () => (dispatch) => {
  dispatch(FetchBanksAction.Fetch());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${FETCH_BANKS_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(FetchBanksAction.Fetch_Success(res.data.data));
      // dispatch(onSuccess(res.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        // onFailure(error.response.data);
      }
    });
};

export const FetchFormDepositMiddleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchFormDepositAction.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${FORM_DEPOSIT_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(FetchFormDepositAction.Fetch_Success(res.data.data));
        dispatch(onSuccess(res.data));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

export const FormGenerateMiddleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(FormGenerateAction.Generate());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${FORM_GENERATE_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(FormGenerateAction.Generate_Success(res.data.data));
        dispatch(onSuccess(res.data.message));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

