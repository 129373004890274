
export const APPLICATIONREQUEST = "APPLICATIONREQUEST";
export const APPLICATIONREQUEST_SUCCESS = "APPLICATIONREQUEST_SUCCESS";
export const APPLICATIONREQUEST_FAILURE = "APPLICATIONREQUEST_FAILURE";
export const APPLICATIONREQUEST_PATH = "real-estate/create-application-request";

export const FETCH_ALL_REQUEST = "FETCH_ALL_REQUEST";
export const FETCH_ALL_REQUEST_SUCCESS = "FETCH_ALL_REQUEST_SUCCESS";
export const FETCH_ALL_REQUEST_FAILURE = "FETCH_ALL_REQUEST_FAILURE";
export const FETCH_ALL_REQUEST_PATH = "application-request/list-for-agent-or-staff";

// export const FETCH_COUNT = "FETCH_COUNT";
// export const FETCH_COUNT_SUCCESS = "FETCH_COUNT_SUCCESS";
// export const FETCH_COUNT_FAILURE = "FETCH_COUNT_FAILURE";
// export const FETCH_COUNT_PATH = "application-request/my-applications/count";

export const FETCH_PRICE = "FETCH_PRICE";
export const FETCH_PRICE_SUCCESS = "FETCH_PRICE_SUCCESS";
export const FETCH_PRICE_FAILURE = "FETCH_PRICE_FAILURE";
export const FETCH_PRICE_PATH = "pricing/get-form-price";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_PATH = "application-request/check-unpaid-application-request";
