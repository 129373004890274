import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormText,
  Media,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Container,
  Col,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import axios from "axios";
import { BASEURL } from "../../config/api/URL";
import Loading from "components/Loader/Loading";
const ChangeProfile = (props) => {
  const [token, setToken] = useState("");
  const [loading, SetLoading] = useState(false);
  const apiPath =
    props.role == "1"
      ? "customs/update/my-profile"
      : props.role == "4" || props.role == "2"
      ? "staff-profile/update/my-profile"
      : props.role == "5"
      ? "real-estate/update-agent-profile"
      : props.role == "6"
      ? "real-estate/estate-staff-profile-self-update"
      : "";
  const [selectedFile, setSelectedFile] = useState();
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [estateName, setEstateName] = useState("");
  const [text, setText] = useState(
    Math.random().toString(36).substring(2) + new Date().getTime().toString(36)
  );
  const [isSelected, setIsSelected] = useState(false);

  const handleSubmit = () => {
    // console.log("SUBMITTED");
    SetLoading(true);
    //changes by iqra
    let formData = new FormData();
    if (props.role == "5") {
      formData.append("fullName", name);
      formData.append("phone", phone?.includes("+") ? phone : `+${phone}`);
      formData.append(
        "whatsAppNo",
        whatsapp?.includes("+") ? whatsapp : `+${whatsapp}`
      );
      formData.append("estateName", estateName);
      formData.append("file", selectedFile);
    } else {
      formData.append("fullName", name);
      formData.append("phone", phone?.includes("+") ? phone : `+${phone}`);
      formData.append(
        "whatsAppNo",
        whatsapp?.includes("+") ? whatsapp : `+${whatsapp}`
      );
      formData.append("file", selectedFile);
    }

    hitApi(formData);
  };
  const hitApi = (body) => {
    // console.log(apiPath);
    axios
      .post(`${BASEURL}${apiPath}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESDARA", res.data);
        if (res.data) {
          SetLoading(false);
          if (props.userdata) {
            // let user = props.userdata;
            // console.log("USERS BEFORE", props.userdata);
            // user.profilepic = res.data.data.custom.profilePhoto;
            // user.fullName = res.data.data.fullName;
            // user.phone = res.data.data.phone;
            // user.whatsAppNo = res.data.data.whatsAppNo;
            // console.log("USER AFTER", user);
            const updatedUser = JSON.stringify(res.data.data);
            // console.log("UPDATTEDUSER", updatedUser);
            localStorage.setItem("user", updatedUser);
            swal({
              title: "Congratulations!",
              text: "Your profile is updated successfully",
              icon: "success",
              // buttons : true,
            }).then((isOk) => {
              if (isOk) {
                props.toggle();
              }
            });
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          SetLoading(false);
          swal("Sorry!", `${error.response.data.message}`, "error");
          console.log(error.response.data);
        } else {
          SetLoading(false);
          swal("Sorry!", `${error}`, "error");
        }
      });
  };
  let img = "";
  useEffect(() => {
    let token = localStorage.getItem("auth");
    console.log("POPS", props.userdata);
    if (props.userdata) {
      setName(props.userdata?.fullName);
      setWhatsapp(props.userdata?.whatsAppNo);
      setPhone(props.userdata?.phone);
      setEstateName(props.userdata?.realEstate?.estateName);
      //setUrl(props.userdata?.realEstateStaffProfile?.profilePhoto)
      if (
        props.userdata?.realEstateStaffProfile?.profilePhoto !== undefined &&
        props.userdata?.realEstateStaffProfile?.profilePhoto !== null
      ) {
        img = props.userdata?.realEstateStaffProfile?.profilePhoto;
      } else if (
        props.userdata?.realEstate?.profilePhoto !== undefined &&
        props.userdata?.realEstate?.profilePhoto !== "abc.jpg"
      ) {
        img = props.userdata?.realEstate?.profilePhoto;
      } else if (
        props.userdata?.custom?.profilePhoto !== undefined &&
        props.userdata?.custom?.profilePhoto !== null
      ) {
        img = props.userdata?.custom?.profilePhoto;
      } else if (
        props.userdata?.managerProfile?.profilePhoto !== undefined &&
        props.userdata?.managerProfile?.profilePhoto !== "ok.png"
      ) {
        img = props.userdata?.managerProfile?.profilePhoto;
      } else {
        img = "";
      }
      setUrl(img);
    }

    console.log("token", token);
    setToken(token);
  }, []);

  const changeHandler = (event) => {
    const url = URL.createObjectURL(event.target.files[0]);
    setUrl(url);
    // console.log("DATA", url);
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  return (
    <Modal size="sm" centered={true} isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader
        // title="Upload picture"
        toggle={props.toggle}
        style={{ backgroundColor: "#d69929" }}
      >
        {/* Upload picture */}
        {/* <Media className="align-items-center">
          <span className="avatar avatar-sm rounded-circle">
            <img
              alt="..."
              src={
                url !== "" ? url : require("../../assets/img/logo.png").default
              }
            />
          </span>
        </Media> */}
        <h3 style={{ color: "white", textAlign: "center" }}>Update profile</h3>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label for="Passowrd1">Profile picture</Label>
              <br />
              <img
                style={{ width: 70, height: 70, borderRadius: 5 }}
                alt="..."
                src={
                  url !== ""
                    ? url
                    : require("../../assets/img/logo.png").default
                }
              />
              {/* {isSelected ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 10,
                    borderWidth: 1,
                    border: "solid",
                    borderColor: "#E4E4E5",
                    //   marginHorizontal: 5,

                    padding: 5,
                  }}
                >
                  <img
                    style={{ width: 70, height: 70, borderRadius: 5 }}
                    alt="..."
                    src={
                      url !== ""
                        ? url
                        : require("../../assets/img/logo.png").default
                    }
                  />
                  <span>{text}</span>
                  <i
                    onClick={() => {
                      setIsSelected(false);
                    }}
                    style={{ fontSize: "20px", cursor: "pointer" }}
                    className="ni ni-fat-remove"
                  />
                </div>
              ) : ( 
                <>*/}

              <Input
                accept="image/*"
                style={{
                  fontSize: "10px",
                  //   borderColor: "red",
                  //   border: "solid",
                  //   borderColor: "#D5D5D5",
                  //   borderWidth: "2px",
                  //   borderRadius: "5px",
                  padding: "5px",
                  //   maxWidth: "95%",
                }}
                type="file"
                id="formFile"
                onChange={changeHandler}
              />
              {/* </FormGroup> </> */}
              {/* )} */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                id="name"
                name="name"
                placeholder="Enter your name"
                // onBlur={() => checkField("oldpass")}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {/* {err !== "" && err.includes("Old") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )} */}
            </FormGroup>
          </Col>
        </Row>
        {/* start Code By iqra */}
        {/* {props.role == "5" ? (
          <Row>
            <Col>
              <FormGroup>
                <Label for="name">Agency Name</Label>
                <Input
                  id="estateName"
                  name="estateName"
                  placeholder="Enter your Agency name"
                  // onBlur={() => checkField("oldpass")}
                  type="text"
                  value={estateName}
                  onChange={(e) => setEstateName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )} */}

        <Row>
          <Col>
            <FormGroup>
              <Label for="Passowrd1">Phone</Label>
              <PhoneInput
                className="input-group-alternative"
                value={phone}
                onChange={(e) => setPhone(e)}
                // onChange={(e) => OnChange(e, "phone")}
                inputStyle={{
                  width: "100%",
                  // border: "none",
                }}
                inputExtraProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                fullWidth="true"
                //  containerStyle={{border:"none"}}
                countryCodeEditable={false}
                country={"pk"}
                // value={state.phone}
              />
              {/* {err !== "" && err.includes("Old") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )} */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="Passowrd1">Whatsapp</Label>
              <PhoneInput
                className="input-group-alternative"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e)}
                // onChange={(e) => OnChange(e, "phone")}
                inputStyle={{
                  width: "100%",
                  // border: "none",
                }}
                inputExtraProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                fullWidth="true"
                //  containerStyle={{border:"none"}}
                countryCodeEditable={false}
                country={"pk"}
                // value={state.phone}
              />
              {/* {err !== "" && err.includes("Old") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )} */}
            </FormGroup>
          </Col>
        </Row>

        <Button
          disabled={loading}
          onClick={() => {
            if (
              name?.trim().length === 0 ||
              whatsapp?.trim().length === 0 ||
              phone?.trim().length === 0
            ) {
              swal("Sorry!", "empty fields are not allowed", "error");
            } else {
              handleSubmit();
            }
          }}
          style={{
            width: "100%",
            backgroundColor: "#015652",
            color: "white",
            marginTop: "0%",
          }}
          // color="warning"
          outline
        >
          {loading ? <Loading attr="Update" /> : "Update"}
        </Button>
      </ModalBody>
    </Modal>
  );
};
export default ChangeProfile;
