import { 
  BRANCH,BRANCH_SUCCESS,BRANCH_FAILURE,
  BRANCHEDIT,BRANCHEDIT_FAILURE,BRANCHEDIT_SUCCESS
} from "../constant.js";
export class BranchEditDetail {
  static BranchEdit() {
    return {
      type: BRANCHEDIT
  }
}
  static BranchEdit_Success(response) {
    return {
      type: BRANCHEDIT_SUCCESS,
      payload: response,
    };
  }
  static BranchEdit_Failure(error) {
    return {
      type: BRANCHEDIT_FAILURE,
      error,
    };
  }
 
}

export class BranchDetail {
  static Branch() {
    return {
      type: BRANCH
  }
}
  static Branch_Success(response) {
    return {
      type: BRANCH_SUCCESS,
      payload: response,
    };
  }
  static Branch_Failure(error) {
    return {
      type: BRANCH_FAILURE,
      error,
    };
  }
 
}

