import {
  FETCH_BANKS,
  FETCH_BANKS_FAILURE,
  FETCH_BANKS_SUCCESS,
  FORM_DEPOSIT,
  FORM_DEPOSIT_SUCCESS,
  FORM_DEPOSIT_FAILURE,
  FORM_GENERATE,
  FORM_GENERATE_SUCCESS,
  FORM_GENERATE_FAILURE,
} from "../constant";

export class FormGenerateAction {
  static Generate() {
    return {
      type: FORM_GENERATE,
    };
  }
  static Generate_Success(response) {
    return {
      type: FORM_GENERATE_SUCCESS,
      payload: response,
    };
  }
  static Generate_Failure(error) {
    return {
      type: FORM_GENERATE_FAILURE,
      error,
    };
  }
}

export class FetchFormDepositAction {
  static Fetch() {
    return {
      type: FORM_DEPOSIT,
    };
  }
  static Fetch_Success(response) {
    return {
      type: FORM_DEPOSIT_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: FORM_DEPOSIT_FAILURE,
      error,
    };
  }
}

export class FetchBanksAction {
  static Fetch() {
    return {
      type: FETCH_BANKS,
    };
  }
  static Fetch_Success(response) {
    return {
      type: FETCH_BANKS_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: FETCH_BANKS_FAILURE,
      error,
    };
  }
}
