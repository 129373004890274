


export const AGENTLIST =  "AGENTLIST";
export const AGENTLIST_SUCCESS =  "AGENTLIST_SUCCESS";
export const AGENTLIST_FAILURE =  "AGENTLIST_FAILURE";
export const AGENTLIST_PATH = "real-estate/agentStaff-list/for-admin";

export const AGENTSTAFFDELETE =  "AGENTSTAFFDELETE";
export const AGENTSTAFFDELETE_SUCCESS =  "AGENTSTAFFDELETE_SUCCESS";
export const AGENTSTAFFDELETE_FAILURE =  "AGENTSTAFFDELETE_FAILURE";
export const AGENTSTAFFDELETE_PATH = "real-estate/delete-estate-staff";

export const AGENTSTAFFVERIFY =  "AGENTSTAFFVERIFY";
export const AGENTSTAFFVERIFY_SUCCESS =  "AGENTSTAFFVERIFY_SUCCESS";
export const AGENTSTAFFVERIFY_FAILURE =  "AGENTSTAFFVERIFY_FAILURE";
export const AGENTSTAFFVERIFY_PATH = "real-estate/verify-estate-staff/for-admin";




