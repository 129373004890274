export const CUSTOMER = "CUSTOMER";
export const CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS";
export const CUSTOMER_FAILURE = "CUSTOMER_FAILURE";
export const CUSTOMER_PATH = "user/createuser";



export const PLOTTYPE= "PLOTTYPE";
export const PLOTTYPE_SUCCESS = "PLOTTYPE_SUCCESS";
export const PLOTTYPE_FAILURE = "PLOTTYPE_FAILURE";
export const PLOTTYPE_PATH = "plotType/findplotType";

export const PLOTSIZE= "PLOTSIZE";
export const PLOTSIZE_SUCCESS = "PLOTSIZE_SUCCESS";
export const PLOTSIZE_FAILURE = "PLOTSIZE_FAILURE";
export const PLOTSIZE_PATH = "plotSize/findPlotSize";

export const PLOTPROJECT= "PLOTPROJECT";
export const PLOTPROJECT_SUCCESS = "PLOTPROJECT_SUCCESS";
export const PLOTPROJECT_FAILURE = "PLOTPROJECT_FAILURE";
export const PLOTPROJECT_PATH = "project/findproject";

