import React, { useState, useRef, useEffect } from "react";
import Logo from "assets/img/whiteee1.png";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";

import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from "react-redux";
import { JwtDashboard } from "../../../../store/helpers/JwtTimer/middleware";
import { CustomerLogin } from "../middleware";

const AgentLogin = (props) => {
  //hooks import
  let history = useHistory();

  const [body, setBody] = useState({
    cnic: "",
    password: "",
    // fcm: "",
  });
  // const [type, settype] = useState({
  //   inputType:"password"
  // })
  const [type, settype] = useState(false);
  const handleClick = () => {
    // settype({...type,inputType:"password"? "text":"password"})
    settype(!type);
  };

  const ValidateLogin = () => {
    if (body.cnic !== "" && body.password !== "" && body.cnic.length == 13) {
      props._CustomerLogin(body, OnSuccess, OnFailure);
    } else if (body.cnic.length < 13) {
      swal("Sorry!", "CNIC is not valid, 13 digits required", "error");
    } else {
      swal("Sorry!", "Please Enter CNIC and Password", "error");
    }
  };
  const onEnterKey = (e) => {
    if (e.key === "Enter") {
      if (body.cnic !== "" && body.password !== "" && body.cnic.length == 13) {
        props._CustomerLogin(body, OnSuccess, OnFailure);
      } else if (body.cnic.length < 13) {
        swal("Sorry!", "CNIC is not valid, 13 digits required", "error");
      } else {
        swal("Sorry!", "Please Enter CNIC and Password", "error");
      }
    }
  };

  const OnSuccess = () => {
    swal({
      title: "Congratulations!",
      text: "You have Logged in successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      setSignal(true);
      history.push("user/requestUserView");
    });
  };
  const OnFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  useEffect(() => {
    if (props.Data !== null && props.Data !== undefined) {
      let values = {
        token: props.Data.data.token,
        user: JSON.stringify(props.Data.data.user),
        roleid: props.Data.data.user.roleId,
      };
      localStorage.setItem("auth", values.token);
      localStorage.setItem("user", values.user);
      localStorage.setItem("roleid", values.roleid);
      if (values.token === "") {
        let path = "/auth/login";
        history.push(path);
      } else {
        // let path = "/user/requestUserView";
        // history.push(path);
      }
    }
  }, [props.Data]);

  const [signal, setSignal] = useState(false);
  useEffect(() => {
    if (signal === true) {
      let path = "/user/requestUserView";
      history.push(path);
    }
    // if (signal === true && localStorage.getItem("roleid") == 5) {
    //   let path = "/user/requestUserView";
    //   history.push(path);
    // } else if (signal === true && localStorage.getItem("roleid") == 6) {
    //   let path = "/user/requestUserViewstaff";
    //   history.push(path);
    // }
  }, [signal]);

  const onHandleChange = (value, name) => {
    setBody({
      ...body,
      [name]: value,
    });
  };
  const [error, setError] = useState({
    cnicError: "",
    passwordError: "",
  });
  const CheckFields = (name) => {
    if (name === "cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", body.cnic),
      });
    } else if (name === "password") {
      setError({
        ...error,
        passwordError: validate("password", body.password),
      });
    }
  };
  // const onKeyPressed = (e) => {
  //   if (e.key === "Enter") {
  //     // ValidateLogin();
  //   }
  // };

  // localStorage.setItem("auth", props.Data);

  useEffect(() => {
    props.JwtDashboard(OnSuccessJwt, OnFailureJwt);
  }, [true]);

  const OnSuccessJwt = () => {};
  const OnFailureJwt = () => {};

  useEffect(() => {
    if (props.GetUserJWTLogin === false) {
      localStorage.removeItem("auth");
      window.location.href = "/auth/login";
    }
  });

  return (
    <>
      <Col lg="5" md="7">
        <Card className="customLogin">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img height="150px" width="150px" src={Logo} /> <br />
              {/* <small>Sign In</small> */}
              <h2 style={{ color: "white" }}>
                <b>Agent Sign In</b>
              </h2>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i class="fas fa-id-card"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="CNIC"
                    type="text"
                    maxLength={13}
                    onBlur={() => CheckFields("cnic")}
                    value={body.cnic}
                    onChange={(e) => onHandleChange(e.target.value, "cnic")}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
                {error.cnicError !== "" && error.cnicError !== null && (
                  <small>
                    <span style={{ color: "red" }}>
                      {error.cnicError}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup
                  style={{ backgroundColor: "white" }}
                  className="input-group-alternative"
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={type ? "text" : "password"}
                    onBlur={() => CheckFields("password")}
                    value={body.password}
                    onChange={(e) => onHandleChange(e.target.value, "password")}
                    onKeyDown={onEnterKey}
                  />
                  <span
                    className="password__show"
                    onClick={handleClick}
                    style={{
                      paddingTop: "10px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    {type == false ? (
                      <i class="fa fa-eye"></i>
                    ) : (
                      <i class="fas fa-eye-slash"></i>
                    )}
                  </span>
                </InputGroup>
                {error.passwordError !== "" && error.passwordError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.passwordError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  onClick={ValidateLogin}
                >
                  Sign in
                </Button>
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  // onClick={ValidateLogin}
                >
                  <a href="/auth/agentSignUp" style={{ color: "white" }}>
                    Sign Up
                  </a>
                </Button>
              </div>
              <div className="text-center"></div>
            </Form>
            <Row className=" text-center">
              <Col xs="12">
                <a
                  className="text-light"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: "/auth/forgetPassword",
                      state: { string: "AGENT" },
                    });
                  }}
                >
                  <b style={{ color: "white" }}>Forgot password?</b>
                </a>
              </Col>
              {/* <Col xs="12">
                <a className="text-light" href="/auth/signup">
                  <small style={{ color: "black" }}>
                    Don't have an account ? Create account.
                  </small>
                </a>
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.login.isLoading,
  isError: state.login.isError,
  Data: state.login.Data,
  Error: state.login.error,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    JwtDashboard: (OnSuccess, OnFailure) =>
      dispatch(JwtDashboard(OnSuccess, OnFailure)),
    _CustomerLogin: (body, OnSuccess, OnFailure) =>
      dispatch(CustomerLogin(body, OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AgentLogin);
