import React, { useEffect, useState } from "react";
import { Row, Container, Card, CardHeader, CardBody, Col } from "reactstrap";
import Headers from "components/Headers/Header1";
import { connect } from "react-redux";
import { AdminFeedbackList_Middleware } from "../middleware";
import Pagination from "components/Pagination/Pagination";
import TableFeedbackListView from "../components/TableFeedbackListView";

const AdminFeedbackList = (props) => {
  const [noOfRows, setnoOfRows] = useState(10);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};

  //////////-------useEffects--------//////////////
  useEffect(() => {
    if (
      props.AdminFeedbackList !== null &&
      props.AdminFeedbackList !== undefined
    ) {
      setData(props.AdminFeedbackList);
    }
  }, [props.AdminFeedbackList]);

  useEffect(() => {
    props.AdminFeedbackList_Middleware(
      pageNumber,
      noOfRows,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows]);

  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Feedback List</h2>
                  </Col>
                </Row>
                <hr />
              </CardHeader>
              <CardBody>
                {/* <Row>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      value={search}
                      onChange={(e) => console.log("Test", e)}
                    ></input>
                    <br />
                  </Col>
                </Row>

                <hr /> */}
                <TableFeedbackListView
                  onChangeNoOfRows={onChangeNoOfRows}
                  post={data}
                />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  // totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  AdminFeedbackList: state.adminFeedbackList.AdminFeedbackList,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    AdminFeedbackList_Middleware: (
      pageNumber,
      noOfRows,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        AdminFeedbackList_Middleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AdminFeedbackList);
