import React, { useEffect, useState } from "react";
import { Row, Container, Card, CardHeader, CardBody, Col } from "reactstrap";
import Headers from "components/Headers/Header1";
import TableBankAppListView from "../components/TableBankAppListView";
import { connect } from "react-redux";
import { FetchBankAppList_Middleware } from "../middleware";
import Pagination from "components/Pagination/Pagination";
import {
  FetchManagerList_Middleware,
  FetchStaffList_Middleware,
} from "views/AdminView/AdminApplicationList/middleware";
import { GetBankListMiddleware } from "views/AdminView/BankList/middleware";
const BankAppList = ({
  _FetchBankAppList_Middleware,
  BankAppList,
  _GetBankListMiddleware,
  response,
  _FetchManagerList_Middleware,
  ManagerList,
  _FetchStaffList_Middleware,
  StaffList,
}) => {
  const [filter, setFilter] = useState({});
  const [valueAndKey, setValueAndKey] = useState({});
  const [criteriaType, setCriteriaType] = useState({
    criteria: "",
  });
  const [portal, setPortal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankId, setBankId] = useState("");
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [managerId, setManagerId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [managers, setManagers] = useState([]);
  const [staff, setStaff] = useState([]);
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  // const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  ///////////----------Pagination--------------//////////////
  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  /////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};

  // const ToggleViewReceiptModal = () => {
  //   setIsViewModalOpen(!isViewModalOpen);
  // };
  //////////-------useEffects--------//////////////

  useEffect(() => {
    _FetchBankAppList_Middleware(
      pageNumber,
      noOfRows,
      valueAndKey,
      searchFilter,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows, valueAndKey, searchFilter]);
  useEffect(() => {
    _GetBankListMiddleware(1, 100, onSuccess, onFailure);
  }, []);
  useEffect(() => {
    _FetchManagerList_Middleware(1, 10000, bankId, onSuccess, onFailure);
  }, [bankId]);

  useEffect(() => {
    _FetchStaffList_Middleware(1, 100000, managerId, onSuccess, onFailure);
  }, [managerId]);

  useEffect(() => {
    setPosts(BankAppList);
  }, [BankAppList]);

  useEffect(() => {
    setBanks(response?.items);
  }, [response]);

  useEffect(() => {
    setManagers(ManagerList);
  }, [ManagerList]);

  useEffect(() => {
    setStaff(StaffList);
  }, [StaffList]);

  useEffect(() => {
    if (portal == "bank") {
    } else if (portal == "") {
      setBankId("");
    }
    if (criteriaType.criteria == "") {
      setFilter({ "": "" });
    }
  }, [portal, criteriaType]);

  useEffect(() => {
    setSearch({ bankId: bankId });
    if (bankId == "") {
      setStaffId("");
      setManagerId("");
    } else if (bankId !== "") {
      setStaffId("");
      setManagerId("");
    }
  }, [bankId]);

  useEffect(() => {
    setSearch({ managerId: managerId });
    if (managerId == "") {
      setSearch({ bankId: bankId });
      setStaffId("");
    } else if (managerId !== "") {
      setStaffId("");
    }
  }, [managerId]);

  useEffect(() => {
    setSearch({ staffId: staffId });
    if (staffId == "") {
      setSearch({ managerId: managerId });
    }
  }, [staffId]);

  useEffect(() => {
    Object.entries(search).forEach(([key, value]) => {
      setSearchFilter({
        key: key,
        value: value,
      });
    });
  }, [search]);

  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      setValueAndKey({
        key: key,
        value: value,
      });
    });
  }, [filter]);
  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Bank Application List</h2>
                  </Col>
                </Row>
                <hr />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      maxLength={13}
                      value={filter[criteriaType.criteria]}
                      onChange={(e) =>
                        setFilter({ [criteriaType.criteria]: e.target.value })
                      }
                      disabled={criteriaType.criteria == ""}
                    ></input>
                    <br />
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Selection
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setCriteriaType({
                          ...criteriaType,
                          criteria: e.target.value,
                        })
                      }
                    >
                      <option value="">Search Criteria</option>
                      <option value="name">Name</option>
                      <option value="CNIC">CNIC</option>
                      <option value="phone">Phone</option>
                      <option value="formNo">Form No</option>
                      <option value="depositSlipNo">DepositSlip No </option>
                      <option value="status">Status</option>
                    </select>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Portal
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setPortal(e.target.value)}
                      value={portal}
                    >
                      <option value="">Search Portal</option>
                      <option value="bank">Bank</option>
                    </select>
                  </Col>
                </Row>
                {portal === "bank" ? (
                  <Row>
                    <Col lg="4" md="4" sm="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Search by Bank
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setBankId(e.target.value)}
                        value={bankId}
                      >
                        <option value="">Select Bank</option>
                        {banks !== null && banks !== undefined
                          ? banks.map((data) => {
                              return (
                                <option key={data.id} value={data.id}>
                                  {data.bankName}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <br />
                    </Col>
                    <Col lg="4" md="4" sm="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Search by Manager
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setManagerId(e.target.value)}
                        value={managerId}
                        disabled={bankId == ""}
                      >
                        <option value="">Select Manager</option>
                        {managers !== null && managers !== undefined
                          ? managers?.map((data) => {
                              return (
                                <option key={data.id} value={data.user.id}>
                                  {data.user.fullName}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </Col>
                    <Col lg="4" md="4" sm="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Search by Staff
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setStaffId(e.target.value)}
                        disabled={managerId == ""}
                        value={staffId}
                      >
                        <option value="">Select Staff</option>
                        {staff !== null && staff !== undefined
                          ? staff?.map((data) => {
                              return (
                                <option key={data.id} value={data.user.id}>
                                  {data.user.fullName}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <hr />
                <TableBankAppListView paginatedPosts={paginatedPosts} />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  BankAppList: state.bankAppList.BankAppList,
  ManagerList: state.adminRequestList.ManagerList,
  StaffList: state.adminRequestList.StaffList,
  response: state.bankList.response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _GetBankListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
    _FetchBankAppList_Middleware: (
      pageNumber,
      noOfRows,
      valueAndKey,
      searchFilter,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchBankAppList_Middleware(
          pageNumber,
          noOfRows,
          valueAndKey,
          searchFilter,
          onSuccess,
          onFailure
        )
      ),
    _FetchManagerList_Middleware: (
      pageNumber,
      noOfRows,
      id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchManagerList_Middleware(
          pageNumber,
          noOfRows,
          id,
          onSuccess,
          onFailure
        )
      ),
    _FetchStaffList_Middleware: (
      pageNumber,
      noOfRows,
      id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchStaffList_Middleware(
          pageNumber,
          noOfRows,
          id,
          onSuccess,
          onFailure
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(BankAppList);
