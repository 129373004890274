export const PLOT_TYPE = "PLOT_TYPE";
export const PLOT_TYPE_SUCCESS = "PLOT_TYPE_SUCCESS";
export const PLOT_TYPE_FAILURE = "PLOT_TYPE_FAILURE";
export const PLOT_TYPE_PATH = "residental";

export const BALLOTING = "BALLOTING";
export const BALLOTING_SUCCESS = "BALLOTING_SUCCESS";
export const BALLOTING_FAILURE = "BALLOTING_FAILURE";
export const BALLOTING_PATH = "all-applications/balloting";

export const WINNER_LIST = "WINNER_LIST";
export const WINNER_LIST_SUCCESS = "WINNER_LIST_SUCCESS";
export const WINNER_LIST_FAILURE = "WINNER_LIST_FAILURE";
export const WINNER_LIST_PATH = "all-applications/get-balloting-winner-list";

export const MEMBERS_COUNT_RESIDENTIAL = "MEMBERS_COUNT_RESIDENTIAL";
export const MEMBERS_COUNT_RESIDENTIAL_SUCCESS =
  "MEMBERS_COUNT_RESIDENTIAL_SUCCESS";
export const MEMBERS_COUNT_RESIDENTIAL_FAILURE =
  "MEMBERS_COUNT_RESIDENTIAL_FAILURE";
export const MEMBERS_COUNT_RESIDENTIAL_PATH =
  "all-applications/get-members-count-for-residental";
