import React from "react";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import ComponentToPrint from "./ComponentToPrint";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../../assets/img/black variation.png";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { Input } from "reactstrap";
import {
  CardBody,
  Button,
  CardHeader,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import Select from "react-select";
import { Prompt } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import Loading from "components/Loader/Loading";
function Personal(props) {
  const [loading, SetLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState([]);
  let location = useLocation();
  console.log("LOCATION OF CHECKBOXES", location.forms);
  const [checked, setChecked] = useState(false);
  const handleChecked = () => {
    setChecked(!checked);
  };
  const componentRef = useRef();
  const history = useHistory();
  const [newArr, setNewArr] = useState([]);
  const [getlead, setlead] = useState({});
  const [formCount, setFormCount] = useState([]);
  const [update, setUpdate] = useState([]);
  const [state, setState] = useState({});
  const [initialstate, setInitialState] = useState("");
  // const [size, setsize] = useState([null]);
  const [disabledd, setdisabledd] = useState(false);
  const [error, setError] = useState({
    fatherNameError: null,
    plotProjectError: null,
    emailError: null,
    plotSizeError: null,
    passwordError: null,
    CNICError: null,
    whatsappNumberError: null,
    nameError: null,
    lastError: null,
    addressError: null,
    AddOError: null,
    ProError: null,
    OError: null,
    dobError: null,
    RError: null,
    TError: null,
    KError: null,
    CError: null,
    imageError: null,
    NationError: null,
    fullNameError: null,
    FfullNameError: null,
    ContactNumber: null,
    DOB: null,
    ContactNumberError: null,
    phoneNumberError: null,
    NationalityError: null,
    NomineeCnicError: null,
    NomineePhoneError: null,
    NomineeNameError: null,
    NomineeRelationError: null,
    residentalIdError: null,
    // ageError: null,
    dateError: null,
  });
  useEffect(() => {
    setlead({
      ...getlead,
      whatsAppNo: checked == true ? getlead.mobile : "92",
    });
    // setstate({ ...state, whatsAppNo: checked == true ? state.phone : "92" });
  }, [checked]);
  useEffect(() => {
    if (props.DraftData !== null && props.DraftData !== undefined) {
      setlead({
        ...getlead,
        dob: props.DraftData.dob,
        name: props.DraftData.name,
        organization: props.DraftData.organization,
        email: props.DraftData.email,
        fatherName: props.DraftData.fatherName,
        passport: props.DraftData.passport,
        cnic: props.DraftData.cnic,
        // age: props.DraftData.age,
        address: props.DraftData.address,
        // applicationId: props.DraftData.applicationId,
        residentalId: props.DraftData.residentalId,
        profession: props.DraftData.profession,
        mobile: props.DraftData.mobile,
        whatsAppNo: props.DraftData.whatsAppNo,
        telephone: props.DraftData.telephone,
      });
    }
    console.log("ORIGINAL DRAFT DATA", props.DraftData);
    // console.log("PORPDRAFT", update);

    // filterPriceInitial(props.DraftData.residentalId);
    if (update.length > 0) {
      // console.log("RESIDENTIAL ID", props.DraftData.residentalId);
      const arr = update.find((object) => {
        // console.log("INITIA PRICE", object.id);
        return object.id == props?.DraftData?.residentalId;
      });
      setInitialState(arr);
      // setState({ ...state, residentalId: initialstate?.price });
      // console.log("Initial price", arr);
      // if (arr !== undefined) {
      //   setState({ ...state, residentalId: arr.price });
      // }
      // console.log("stateid", state.residentalId);
    }
  }, [props.DraftData]);

  // console.log("INITIALSTATE", state);
  // useEffect(() => {
  //   setState({ ...state, residentalId: initialstate?.price });
  // }, [initialstate]);
  useEffect(() => {
    let id = location.forms[0].id;
    console.log("IDDDDD from form ID at true", id);
    props.showDraftCustomer(id, onSuccessDraftID, onFailureDraftID);
    props.showPlotType(onSuccess, onFailure);
  }, [true]);

  useEffect(() => {
    if (location.forms !== null && location.forms !== undefined) {
      let id = location.forms[0].id;
      console.log("FSDFFSDF second useefect id", id);
      props.showDraftCustomer(id, onSuccessDraftID, onFailureDraftID);
      // setlead({ ...getlead, applicationId: location.forms[0].id });
    }
  }, [location.forms]);
  const handleCheck = (event, value) => {
    // if (location.forms.length == selectedForm.length && event.target.checked) {
    //   swal(
    //     "Sorry!",
    //     `Plots length must be equal to selected form please check mark only ${location.forms.length} plots`,
    //     "error"
    //   );
    //   event.target.checked = false;
    // }
    if (location.forms.length == selectedForm.length && event.target.checked) {
      swal(
        "Sorry!",
        `Plots length must be equal to the forms you have selected! please check mark only ${location.forms.length} plots`,
        "error"
      );
      event.target.checked = false;

      return;
    }
    var updatedList = [...selectedForm];
    if (event.target.checked) {
      updatedList = [...selectedForm, value.id];
      console.log("UPDATEDLIST", updatedList);
      var items = updatedList.map((data, index) => {
        return {
          residentalId: data,
          appV2Id: location.forms[index].id,
        };
      });

      console.log("ARRAY DESTRUCTED", items);
      setlead({ ...getlead, applicationV2Ids: items });
    } else {
      updatedList.splice(selectedForm.indexOf(value.id), 1);
      console.log("UPDATEDLIST", updatedList);
    }
    //  if (location.forms.length === selectedForm.length) {

    //   console.log("APPLY logic for array transformation");
    // }
    setSelectedForm(updatedList);
  };

  const filterPrice = () => {
    const id = getlead?.residentalId;
    // console.log("IDDDD", id);
    const arr = newArr.find((object) => {
      return object.id == id;
    });
    // console.log("Destructed", arr);
    if (arr !== undefined) {
      setState({ ...state, residentalId: arr.price });
    } else {
      setState({ ...state, residentalId: "" });
      // setlead({ ...getlead, residentalId: "" });
      // setFormCount([]);
    }
  };
  useEffect(() => {
    if (getlead?.residentalId !== null) {
      filterPrice();
    }
    // if (props.Type !== null && props.Type !== undefined) {
    //   for (let i = 0; i < props.Type.length; i++) {
    //     if (props.Type[i].id == getlead.residentalId) {
    //       setState({ ...state, residentalId: props.Type[i].price });
    //     }
    //   }
    // }
  }, [getlead.residentalId]);

  // useEffect(() => {
  //   if (props.Type !== null && props.Type !== undefined) {
  //     let arr = [];
  //     for (let i = 0; i < props.Type.length; i++) {

  //         let obj = {
  //           value: props.Type[i].id,
  //           label: props.Type[i].title,
  //           price: props.Type[i].price,
  //         };
  //         arr.push(obj);
  //       }

  //     setsize(arr);
  //   }
  // }, [props.Type]);

  //   useEffect(() => {

  //     window.addEventListener('beforeunload', alertUser)
  //   //    window.addEventListener('popstate', function(event) {
  //   //     // The popstate event is fired each time when the current history entry changes.

  //   //     var r = this.confirm("You pressed a Back button! Are you sure?!");
  //   //
  //   //     if (r == true) {
  //   //         // Call Back button programmatically as per user confirmation.
  //   //         history.back();
  //   //         // Uncomment below line to redirect to the previous page instead.
  //   //         // window.location = document.referrer // Note: IE11 is not supporting this.
  //   //     } else {
  //   //         // Stay on the current page.
  //   //         history.pushState(null, null, "/user/CustomerMembershipForm");
  //   //     }

  //   //     history.pushState(null, null, "/user/ApplicationList");

  //   // }, false);
  //   // window.addEventListener('unload',  swal({
  //   //   title: "Success!",
  //   //   text: "leaving Successfully.",
  //   //   icon: "success",
  //   //   // buttons : true,
  //   // }).then((isOk) => {
  //   //   if (isOk) {
  //   //     // let path = "/user/ApplicationList";
  //   //     // history.push(path);
  //   //   }
  //   // })  )
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser)
  //     //window.removeEventListener('popstate', onBackButtonEvent
  //     //onBackButtonEvent
  //    // alert("You message")
  //     // swal({
  //     //     title: "warning!",
  //     //     text: "Are You Sure You Want To leave.",
  //     //     icon: "warning",
  //     //     // buttons : true,
  //     //   }).then((isOk) => {
  //     //     if (isOk) {
  //     //       // let path = "/user/ApplicationList";
  //     //       // history.push(path);
  //     //     }
  //     //   })
  //     //)
  //     // window.onhashchange = function() {
  //     //   alert("You message")
  //     //  }

  //   }

  //     // return(
  //     // window.removeEventListener('unload',
  //     // // alertUser

  //     // swal({
  //     //   title: "Success!",
  //     //   text: "Information Submitted Successfully.",
  //     //   icon: "success",
  //     //   // buttons : true,
  //     // }).then((isOk) => {
  //     //   if (isOk) {
  //     //     // let path = "/user/ApplicationList";
  //     //     // history.push(path);
  //     //   }
  //     // })
  //     // )
  //     // )
  //   }, [])

  //   const onBackButtonEvent = (e) => {
  //     e.preventDefault();
  //   //  if (!finishStatus) {
  //         if (window.confirm("Do you want to go back ?")) {
  //             //setfinishStatus(true)
  //             // your logic
  //             props.history.push("/");
  //         } else {
  //             window.history.pushState(null, null, window.location.pathname);
  //            // setfinishStatus(false)
  //         }
  //    // }
  // }
  //   const alertUser = e => {
  //     e.preventDefault()
  //     e.returnValue = ''
  //   }

  const OnChange = (value, name) => {
    //     if(name=="residentalId")
    // {
    //   let a=value;
    //   // setlead({
    //   //     ...getlead,
    //   //     residentalId:a,
    //   //   });
    //   let b=a.split("+")
    //    console.log(b[0],"aaaa")

    // setlead({
    //   ...getlead,
    //   residentalId:b[0],
    // });
    // setState({
    //   ...state,
    //   residentalId: b[1],
    // });

    // }
    // else{
    setlead({
      ...getlead,
      [name]: value,
    });
    //}
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const onVerifySuccess = () => {};
  const onVerifyFailure = () => {};
  const CheckFields = (name) => {
    if (name === "cnic") {
      // props._VerifyByCNIC(
      //   { cnic: getlead?.cnic },
      //   onVerifySuccess,
      //   onVerifyFailure
      // );
      props._VerifyByCNIC(getlead?.cnic, onVerifySuccess, onVerifyFailure);
      setError({
        ...error,
        CNICError: validate("CNIC", getlead.cnic),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", getlead.email),
      });
    } else if (name === "name") {
      setError({
        ...error,
        nameError: validate("fullName", getlead.name),
      });
    } else if (name === "fatherName") {
      setError({
        ...error,
        fatherNameError: validate("fullName", getlead.fatherName),
      });
    } else if (name === "phone") {
      // if (getlead.phone.trim().length === 0) {
      //   getlead.phone == "";
      // }
      setError({
        ...error,
        phoneNumberError: validate("ContactNumberr", getlead.mobile),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", getlead.email),
      });
    } else if (name === "address") {
      setError({
        ...error,
        addressError: validate("fullName", getlead.address),
      });
    }
    // else if (name === "age") {
    //   setError({
    //     ...error,
    //     ageError: validate("required", getlead.age),
    //   });
    // }
    else if (name === "dob") {
      setError({
        ...error,
        dobError: validate("required", getlead.dob),
      });
    } else if (name === "residentalId") {
      setError({
        ...error,
        residentalIdError: validate("required", getlead.residentalId),
      });
    } else if (name === "whatsAppNo") {
      // if (getlead.whatsAppNo !== "") {
      //   console.log("Whatsapp hit", getlead.whatsAppNo);
      // } else {
      //   getlead.whatsAppNo == null;
      //   console.log("Whatsapp hit after", getlead.whatsAppNo);
      // }
      //  else {
      //   setlead({
      //     ...getlead,
      //     whatsAppNo: null,
      //   });
      //   console.log("Whatsapp hit after state update", getlead.whatsAppNo);
      // }
      setError({
        ...error,
        whatsappNumberError: validate("ContactNumberr", getlead.whatsAppNo),
      });
    }
  };
  const getListAgain = () => {
    if (props.CNIC_Response?.length > 0) {
      // console.log("CNINCRESOONSEDATAAA", props.CNIC_Response);
      const arr = props.CNIC_Response.filter((el) => el.quantity < 5);
      // console.log("NEWAARESPONSE ARRAY", arr);
      if (arr.length > 0) {
        setUpdate(arr);
      }
      if (getlead?.cnic?.length === 13) {
        setFormCount(arr);
        // if (props.Type !== null && props.Type !== undefined) {
        //   for (let i = 0; i < props.Type.length; i++) {
        //     if (props.Type[i].id == getlead.residentalId) {
        //       setState({ ...state, residentalId: props.Type[i].price });
        //     }
        //   }
        // }
      } else {
        setState({ ...state, residentalId: "" });
        setInitialState("");
        setFormCount([]);
      }
    }
  };
  //---- Checkbox Function to Lock the Entering
  const Addd = () => {
    // setdisabledd(true);
    // if (location.forms.length === selectedForm.length) {
    //   console.log("APPLY logic for array transformation");
    // }
    if (
      getlead?.whatsAppNo?.trim().length > 0 &&
      getlead?.email?.trim().length <= 0
    ) {
      delete getlead.email;
    }
    if (
      getlead?.whatsAppNo?.trim().length <= 0 &&
      getlead?.email?.trim().length > 0
    ) {
      delete getlead.whatsAppNo;
    }
    if (getlead?.telephone !== null && getlead?.telephone?.length < 5) {
      delete getlead.telephone;
    }
    if (getlead?.dob !== null && getlead?.dob == "") {
      delete getlead.dob;
    }
    if (getlead?.organization !== null && getlead?.organization == "") {
      delete getlead.organization;
    }
    if (getlead?.passport !== null && getlead?.passport == "") {
      delete getlead.passport;
    }
    if (getlead?.profession !== null && getlead?.profession == "") {
      delete getlead.profession;
    }
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setError({
      ...error,
      CNICError: validate("CNIC", getlead.cnic),
      nameError: validate("fullName", getlead.name),
      phoneNumberError: validate("ContactNumberr", getlead.mobile),
      fatherNameError: validate("fullName", getlead.fatherName),
      // emailError: validate("email", getlead.email),
      addressError: validate("fullName", getlead.address),
      dobError: validate("required", getlead.dob),
      // ageError: validate("required", getlead.age),
      residentalIdError: validate("required", getlead.residentalId),
      // whatsappNumberError: validate("ContactNumberr", getlead.whatsAppNo),
    });

    if (
      getlead.name == "" ||
      getlead.name == null ||
      getlead.cnic == "" ||
      getlead.cnic == null ||
      getlead.cnic.length !== 13 ||
      getlead.fatherName == "" ||
      getlead.fatherName == null ||
      getlead.address == "" ||
      getlead.address == null ||
      getlead.dob == "" ||
      getlead.dob == null
    ) {
      // getlead.status === "DRAFT"
      setlead({ ...getlead, status: "" });
      swal("Sorry!", "Please Fill all required fields", "error");
    } else if (
      (getlead.whatsAppNo == "" || getlead.whatsAppNo == null) &&
      (getlead.whatsAppNo == "" || getlead.whatsAppNo == null)
    ) {
      setlead({ ...getlead, status: "" });
      swal("Sorry!", "Please enter either whatsapp or email", "error");
    } else if (
      getlead?.whatsAppNo?.trim().length > 0 &&
      getlead?.whatsAppNo?.trim().length <= 12
    ) {
      setlead({ ...getlead, status: "" });
      swal("Sorry!", "Please Enter valid Whatsapp No.", "error");
    } else if (
      getlead?.mobile?.trim().length <= 12 ||
      getlead?.mobile == null
    ) {
      setlead({ ...getlead, status: "" });
      swal("Sorry!", "Please Enter valid Phone No.", "error");
    } else if (
      getlead?.email?.trim().length > 0 &&
      !getlead?.email?.match(mailformat)
    ) {
      setlead({ ...getlead, status: "" });
      swal("Sorry!", "Please Enter valid email.", "error");
    } else if (getlead?.address?.trim().length <= 9) {
      setlead({ ...getlead, status: "" });
      swal(
        "Sorry!",
        "Address must be longer than or equal to 10 characters",
        "error"
      );
    }
    //  else if (props.DraftData !== null && props.DraftData !== undefined) {
    //   let id = props.DraftData?.id;
    //   props.UpdateCustomerMiddleware(
    //     id,
    //     getlead,
    //     onSuccessUpdate,
    //     onFailureUpdate
    //   );
    // }
    else if (getlead?.cnic?.length !== 13) {
      setlead({ ...getlead, status: "" });
      swal("Sorry!", "Invalid CNIC", "error");
    } else if (selectedForm.length < location.forms.length) {
      swal(
        "Sorry!",
        `Plots length must be equal to the forms you have selected! please check mark only ${location.forms.length} plots`,
        "error"
      );
      setlead({ ...getlead, status: "" });
    } else {
      SetLoading(true);
      // setlead({ ...getlead, applicationId: location.state.id });
      console.log("saveAsdSubmit", getlead);
      // let id = props.DraftData?.id;
      props.InsertCustomerDetail(getlead, onSuccessInsert, OnFailureInsert);
    }
  };
  const payNow = (receipt) => {
    history.push({ pathname: "/user/PaymentConfirmation", state: receipt });
  };
  const saveAsDraft = () => {
    // console.log("DATA After spread", getlead);
    let id = props.DraftData?.id;
    // console.log("DRAFT ID AT INITIAL", id);
    // const parseddata = parseInt(id);
    if (getlead?.whatsAppNo !== null && getlead?.whatsAppNo?.length <= 5) {
      delete getlead.whatsAppNo;
    }
    if (getlead?.telephone !== null && getlead?.telephone?.length <= 5) {
      delete getlead.telephone;
    }
    if (getlead?.mobile !== null && getlead?.mobile?.length <= 5) {
      delete getlead.mobile;
    }
    if (getlead?.address !== null && getlead?.address == "") {
      delete getlead.address;
    }
    if (getlead?.dob !== null && getlead?.dob == "") {
      delete getlead.dob;
    }
    if (getlead?.cnic !== null && getlead?.cnic == "") {
      delete getlead.cnic;
    }
    if (getlead?.dob !== null && getlead?.dob == "") {
      delete getlead.dob;
    }
    if (getlead?.email !== null && getlead?.email == "") {
      delete getlead.email;
    }
    if (getlead?.fatherName !== null && getlead?.fatherName == "") {
      delete getlead.fatherName;
    }
    if (getlead?.name !== null && getlead?.name == "") {
      delete getlead.name;
    }
    if (getlead?.organization !== null && getlead?.organization == "") {
      delete getlead.organization;
    }
    if (getlead?.passport !== null && getlead?.passport == "") {
      delete getlead.passport;
    }
    if (getlead?.profession !== null && getlead?.profession == "") {
      delete getlead.profession;
    }
    // if (getlead?.residentalId !== null && getlead?.residentalId == "") {
    //   delete getlead.residentalId;
    // }
    // if (getlead?.residentalId !== null && getlead?.residentalId <= -0) {
    //   delete getlead.residentalId;
    // }
    else if (selectedForm.length < location.forms.length) {
      swal(
        "Sorry!",
        `Plots length must be equal to the forms you have selected! please check mark only ${location.forms.length} plots`,
        "error"
      );
      setlead({ ...getlead, status: "" });
    } else {
      console.log("saveAsdraft", getlead);
      setdisabledd(true);
      props.UpdateDraftCustomerMiddleware(
        getlead,
        onSuccessDraftUpdate,
        onFailureDraftUpdate
      );
    }

    // if (props.DraftData !== null && props.DraftData !== undefined) {
    //   let id = props.DraftData?.id;
    //   props.UpdateDraftCustomerMiddleware(
    //     id,
    //     getlead,
    //     onSuccessDraftUpdate,
    //     onFailureDraftUpdate
    //   );
    // } else {
    //   props.InsertCustomerDraftDetail(getlead, onSuccessDraft, onFailureDraft);
    // }
  };
  // console.log("DATA After spread", getlead);
  const onSuccessInsert = (data) => {
    SetLoading(false);
    window.fbq("track", "FormSubmitted");
    console.log("DATA FROM SUBMITTED", data);
    swal({
      title: "Success!",
      text: "Information Submitted Successfully.",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        // history.push({ pathname: "/user/ApplicationList" });
        history.push({ pathname: "/user/ApplicationList", state: data });
        // const url = location.state?.blinqInvoice?.clickToPayUrl;
        // console.log("urrrrr", url);
        // window.open(url, "_blank");
        // let path = "/user/ApplicationList";
        // history.push(path);
      } else {
        history.push({ pathname: "/user/ApplicationList", state: data });
      }
    });
  };
  const OnFailureInsert = (error) => {
    SetLoading(false);
    setlead({ ...getlead, status: "" });
    swal("Sorry!", error.message, "error");
  };

  const onSuccessUpdate = () => {
    swal({
      title: "Success!",
      text: "Information Updated Successfully.",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/ApplicationList";
        history.push(path);
      }
    });
  };
  const onFailureUpdate = (error) => {
    swal("Sorry!", error.message, "error");
  };

  const onSuccessDraftUpdate = () => {
    swal({
      title: "Success!",
      text: "Information save to draft Successfully.",
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/ApplicationList";
        history.push(path);
      }
    });
  };
  const onFailureDraftUpdate = (error) => {
    setdisabledd(false);
    setlead({ ...getlead, status: "" });
    swal("Sorry!", error.message, "error");
  };

  const onSuccessDraft = () => {
    swal({
      title: "Success!",
      text: "Information save to draft Successfully.",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/ApplicationList";
        history.push(path);
      }
    });
  };
  const onFailureDraft = (error) => {
    swal("Sorry!", error.message, "error");
  };

  const onSuccess = () => {};
  const onFailure = () => {};
  const onSuccessDraftID = () => {};
  const onFailureDraftID = () => {};
  useEffect(() => {
    let tempArr = [];
    formCount.map((data, index) => {
      console.log("COUNT DATA", data);
      tempArr.push(data);
    });

    setNewArr(tempArr);
  }, [formCount]);
  // console.log("NEW ARRAY ", newArr);
  useEffect(() => {
    if (props.CNIC_Response?.length > 0) {
      // console.log("CNINCRESOONSEDATAAA", props.CNIC_Response);
      const arr = props.CNIC_Response.filter((el) => el.quantity < 5);
      // console.log("NEWAARESPONSE ARRAY", arr);
      if (arr.length > 0) {
        setUpdate(arr);
      }
      if (getlead?.cnic?.length === 13) {
        setFormCount(arr);
        // if (getlead?.residentalId !== null) {
        //   filterPrice();
        // }
        // if (props.Type !== null && props.Type !== undefined) {
        //   for (let i = 0; i < props.Type.length; i++) {
        //     if (props.Type[i].id == getlead.residentalId) {
        //       setState({ ...state, residentalId: props.Type[i].price });
        //     }
        //   }
        // }
      } else {
        setState({ ...state, residentalId: "" });
        setlead({ ...getlead, residentalId: "" });
        setInitialState("");
        setFormCount([]);
      }
    }
  }, [props.CNIC_Response, getlead.cnic]);
  // console.log("newARR", newArr);
  useEffect(() => {
    if (getlead.status === "DRAFT") {
      saveAsDraft();
    } else if (getlead.status === "SUBMITTED") {
      Addd();
    }
  }, [getlead]);

  return (
    <div>
      <ComponentToPrint
        ref={componentRef}
        FormNo={state.FormNo}
        name={getlead.name}
        email={getlead.email}
        father={getlead.fatherName}
        mobile={getlead.mobile}
        cnic={getlead.cnic}
        passport={getlead.passportNo}
        address={getlead.address}
        drivingLicense={getlead.licenseNo}
        profession={getlead.Profession}
        organization={getlead.organization}
        whatsappNumber={getlead.whatsappNumber}
        date={getlead.DOB}
        nationality={getlead.Nationality}
        plotType={state.plotType}
        plotProject={state.plotProject}
        plotSize={state.plotSize}
        plotQuantity={getlead.plotQuantity}
      />
      <Card style={{ margin: "10px" }} className="shadow">
        <CardHeader className="border-0">
          <Row>
            <Col lg="4" md="4" sm="4">
              <img style={{ marginTop: -30 }} width="55%" src={Logo} />
            </Col>
            <Col style={{ marginLeft: "8%" }} lg="4" md="4" sm="4">
              <h1
                className="mb-0"
                style={{
                  color: "#d69929",
                  marginTop: 30,
                  // marginRight: 50,
                }}
              >
                Registration Form
              </h1>
            </Col>
            <Col style={{}} lg="4" md="4" sm="4"></Col>
          </Row>
        </CardHeader>

        <CardHeader className="border-0">
          <Row style={{ marginTop: -35, marginLeft: 12 }}>
            <Col
              style={{ textAlign: "center", alignSelf: "center" }}
              lg="12"
              md="12"
              sm="12"
            >
              <p className="mb-0">
                Note: You have selected{" "}
                <strong> {location.forms.length} </strong> forms for submittion
                please note that you are only able to select{" "}
                {location.forms.length} plots below.
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: 10, marginLeft: 12 }}>
            <Col style={{}} lg="4" md="4" sm="4">
              <h3 className="mb-0">Applicant(درخواست گزار)</h3>
            </Col>
            {/* <Col lg="4" md="4" sm="4">
              <h3
                style={{
                  justifyContent: "center",
                  alignSelf: "center",
                  textAlign: "center",
                }}
                className="mb-0"
              >
                Application Request ID: {props?.DraftData?.applicationRequestId}
              </h3>
            </Col>
            <Col lg="4" md="4" sm="4">
              <h3
                style={{
                  justifyContent: "center",
                  alignSelf: "center",
                  textAlign: "center",
                }}
                className="mb-0"
              >
                Form ID: {props?.DraftData?.uniqueFormId}
              </h3>
            </Col> */}
          </Row>
        </CardHeader>

        <CardBody style={{ marginTop: -25 }}>
          <form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Name * (نام)
                  </label>

                  <Input
                    type="text"
                    id="input-username"
                    // pattern="[A-Za-z]"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("name")}
                    value={getlead.name}
                    onChange={(e) => {
                      let val = e.target.value;

                      val = val.replace(/[^A-Za-z ]/gi, "");

                      OnChange(val, "name");
                    }}
                    // onChange={(e) => OnChange(e.target.value, "name")}
                    autoComplete="off"
                  />
                  {error.nameError !== "" && error.nameError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.nameError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
                <Col lg="6" md="5" sm="5">
                  <label className="form-control-label" for="input-username">
                    Father/Husband Name * (والد/شوہر کا نام)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("fatherName")}
                    value={getlead.fatherName}
                    onChange={(e) => {
                      let val = e.target.value;

                      val = val.replace(/[^A-Za-z ]/gi, "");

                      OnChange(val, "fatherName");
                    }}
                    // onChange={(e) => OnChange(e.target.value, "fatherName")}
                    autoComplete="off"
                  ></input>
                  {error.fatherNameError !== "" &&
                    error.fatherNameError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.fatherNameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                </Col>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Phone * (موبائل)
                  </label>
                  <PhoneInput
                    className="input-group-alternative"
                    onBlur={() => CheckFields("phone")}
                    value={getlead.mobile ? getlead?.mobile : ""}
                    // onChange={(e) => OnChange(e, "phone")}
                    onChange={(e, value, country, formattedValue) => {
                      // console.log("EEEEEE", value);
                      let val = `+${e}`;
                      OnChange(val, "mobile");
                    }}
                    inputStyle={{
                      width: "100%",
                      border: "solid 1px lightgrey",
                      // border: "none",
                    }}
                    inputExtraProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    fullWidth="true"
                    //  containerStyle={{border:"none"}}
                    countryCodeEditable={false}
                    country={"pk"}
                    // value={state.phone}
                  />
                  {/* <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={11}
                    placeholder=""
                    onBlur={() => CheckFields("phone")}
                    value={getlead.phone}
                    onChange={(e) => OnChange(e.target.value, "phone")}
                    autoComplete="off"
                  ></input> */}
                  {error.phoneNumberError !== "" &&
                    error.phoneNumberError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.phoneNumberError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                </Col>
                <Col lg="3" md="3" sm="6">
                  <label className="form-control-label" for="input-username">
                    Email * (ای میل)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("email")}
                    value={getlead.email}
                    onChange={(e) => OnChange(e.target.value, "email")}
                    autoComplete="off"
                  ></input>
                  {error.emailError !== "" && error.emailError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.emailError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
                <Col lg="3" md="3" sm="6">
                  <label className="form-control-label" for="input-username">
                    Whatsapp No. * (واٹس ایپ نمبر)
                  </label>
                  <PhoneInput
                    disabled={checked}
                    className="input-group-alternative"
                    onBlur={() => CheckFields("whatsAppNo")}
                    value={getlead.whatsAppNo ? getlead?.whatsAppNo : ""}
                    onChange={(e, value, country, formattedValue) => {
                      // console.log("EEEEEE", value);
                      let val = `+${e}`;
                      OnChange(val, "whatsAppNo");
                    }}
                    inputStyle={{
                      width: "100%",
                      border: "solid 1px lightgrey",
                      // border: "none",
                    }}
                    inputExtraProps={{
                      name: "whatsAppNo",
                      required: true,
                      autoFocus: true,
                    }}
                    // style={{ borderColor: "green", border: "solid" }}
                    fullWidth="true"
                    //  containerStyle={{border:"none"}}
                    countryCodeEditable={false}
                    country={"pk"}
                    // value={state.phone}
                    // value={getlead.whatsAppNo}
                  />
                  {/* <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={11}
                    placeholder=""
                    onBlur={() => CheckFields("whatsAppNo")}
                    value={getlead.whatsAppNo}
                    onChange={(e) => OnChange(e.target.value, "whatsAppNo")}
                    autoComplete="off"
                  ></input> */}
                  {error.whatsappNumberError !== "" &&
                    error.whatsappNumberError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.whatsappNumberError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  {getlead.mobile?.length == 13 ? (
                    <div style={{ position: "relative", float: "left" }}>
                      <input
                        type="checkbox"
                        onClick={handleChecked}
                        // disabled={isUserExist}
                      />
                      <small>
                        <b> Same As Phone No</b>
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    CNIC * (شناختی کارڈ)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("cnic")}
                    maxLength={13}
                    value={getlead.cnic}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => OnChange(e.target.value, "cnic")}
                    autoComplete="off"
                  ></input>
                  {error.CNICError !== "" && error.CNICError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.CNICError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>

                <Col lg="12" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Passport No. (پاسپورٹ نمبر)
                  </label>
                  <input
                    maxLength={9}
                    type="text"
                    id="input-username"
                    className="form-control"
                    value={getlead.passport}
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    // onChange={(e) => {
                    //   let val = e.target.value;

                    //   val = val.replace(/[^A-Za-z]/gi, "");

                    //   OnChange(val, "passport");
                    // }}
                    onChange={(e) => OnChange(e.target.value, "passport")}
                    autoComplete="off"
                  ></input>
                </Col>
              </Row>

              <Row>
                <Col lg="12" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Address (Residence) * (پتہ (رہائش))
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("address")}
                    value={getlead.address}
                    onChange={(e) => OnChange(e.target.value, "address")}
                    autoComplete="off"
                  ></input>
                  {error.addressError !== "" && error.addressError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.addressError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
              </Row>

              <Row>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Organization (تنظیم)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    value={getlead.organization}
                    onChange={(e) => OnChange(e.target.value, "organization")}
                    autoComplete="off"
                  ></input>
                </Col>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Profession (پیشہ)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    value={getlead.profession}
                    onChange={(e) => OnChange(e.target.value, "profession")}
                    autoComplete="off"
                  ></input>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Telephone (ٹیلی فون)
                  </label>
                  <PhoneInput
                    className="input-group-alternative"
                    onBlur={() => CheckFields("phone")}
                    value={getlead.telephone ? getlead?.telephone : ""}
                    // onChange={(e) => OnChange(e, "phone")}
                    onChange={(e, value, country, formattedValue) => {
                      let val = `+${e}`;
                      OnChange(val, "telephone");
                    }}
                    inputStyle={{
                      width: "100%",
                      border: "solid 1px lightgrey",
                      // border: "none",
                    }}
                    inputExtraProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    fullWidth="true"
                    //  containerStyle={{border:"none"}}
                    countryCodeEditable={false}
                    country={"pk"}
                    // value={state.phone}
                  />
                  {/* <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    maxLength={11}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={getlead.telephone}
                    onChange={(e) => {
                      // let val = `+92${e.target.value}`;
                      OnChange(e.target.val, "telephone");
                    }}
                    autoComplete="off"
                  ></input> */}
                </Col>
                {/* <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Age (عمر)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    maxLength={11}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => CheckFields("age")}
                    value={getlead.age}
                    onChange={(e) => OnChange(e.target.value, "age")}
                    autoComplete="off"
                  ></input>
                  {error.ageError !== "" && error.ageError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.ageError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col> */}
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Date of Birth * (پیدائش کی تاریخ)
                  </label>
                  <input
                    type="date"
                    max={moment().format("YYYY-MM-DD")}
                    id="input-username"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    //  max={abc}
                    //  onClick={checker}
                    onBlur={() => CheckFields("dob")}
                    value={getlead.dob}
                    onChange={(e) => OnChange(e.target.value, "dob")}
                    autoComplete="off"
                  ></input>
                  {error.dobError !== "" && error.dobError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.dobError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
              </Row>
              <br />
            </div>
          </form>
        </CardBody>
      </Card>
      <Card style={{ margin: "10px" }} className="shadow">
        <CardHeader className="border-0">
          <h3 className="mb-0"> Plot Prefrence.</h3>
          {/* <p className="mb-0">
            Note: If plots are not show then please re-enter CNIC
          </p> */}
          <CardBody>
            <form>
              {/* <span style={{ marginLeft: "2.4%" }}>
                Note: 5 marla(price:10000) 7 marla(price:15000) 10
                marla(price:20000) 14 marla(price:25000) 1 kanal(price:30000)
              </span> */}
              <div className="pl-lg-4 mt-2">
                <Row>
                  <Col style={{ marginTop: -15 }} lg="12" md="12" sm="12">
                    <Row>
                      <table
                        style={{
                          border: "1px solid lightgrey",
                          width: "100%",
                          // marginTop: "-5px",
                        }}
                        className="align-items-center"
                        responsive
                      >
                        <thead
                          style={{
                            backgroundColor: "#D69929",
                            color: "white",
                            fontSize: "12px",
                          }}
                        >
                          <tr>
                            <th scope="col">Category</th>
                            {/* <th scope="col">Price</th> */}
                            <th scope="col">Count</th>
                            <th scope="col">Remaining Selectable</th>
                          </tr>
                        </thead>

                        <tbody style={{ fontSize: "12px" }}>
                          {newArr !== null && newArr !== undefined ? (
                            newArr.map((data, index) => {
                              let arr = new Array(
                                data?.remaining <= location?.forms?.length
                                  ? data?.remaining
                                  : location?.forms?.length
                              )
                                .fill("")
                                .map((_, i) => i + 1);
                              return (
                                <tr>
                                  <td> {data?.title}</td>
                                  {/* <td>{data?.price}</td> */}
                                  <td>{data?.quantity}</td>

                                  <td>
                                    <Form>
                                      {arr.map(() => {
                                        return (
                                          <FormGroup check inline>
                                            <Label check>
                                              <Input
                                                disabled={loading}
                                                onChange={(e) =>
                                                  handleCheck(e, data)
                                                }
                                                type="checkbox"
                                              />
                                            </Label>
                                          </FormGroup>
                                        );
                                      })}
                                    </Form>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <th></th>
                              <th>
                                <h3>No data found</h3>
                              </th>
                              <th></th>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Row>
                    {/* <Row>
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Plot Type *
                      </label>

                      <Input
                        id="exampleFormControlSelect1"
                        type="select"
                        onBlur={() => CheckFields("residentalId")}
                        value={getlead.residentalId}
                        onChange={(e) => {
                          setInitialState("");

                          // if (e.target.value === "select") {
                          //   delete getlead.residentalId;
                          // }
                          OnChange(parseInt(e.target.value), "residentalId");
                        }}
                      >
                        <option key={-0} value={-0}>
                          {"Select"}
                        </option>

                        {newArr !== null &&
                          newArr !== undefined &&
                          newArr.map((source) => {
                            return (
                              <option
                                value={source.id}
                                // value={source.id+"+"+source.price}
                              >
                                {source.title}
                              </option>
                            );
                          })}
                      </Input>
                      {error.residentalIdError !== "" &&
                        error.residentalIdError !== null && (
                          <small style={{ marginTop: "2px" }}>
                            <span style={{ color: "red" }}>
                              {error.residentalIdError}{" "}
                              <i className="fas fa-exclamation-circle"></i>
                            </span>
                          </small>
                        )}
                    </Row> */}
                    {/* <Row style={{ marginTop: 10 }}>
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Price
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control"
                        placeholder=""
                        disabled
                        value={initialstate?.price || state.residentalId}
                        // onChange={(e) => OnChange(e.target.value, "age")}
                      ></input>
                    </Row> */}

                    <Row style={{ marginTop: 25 }}>
                      <Button
                        style={{ backgroundColor: "#015652", color: "white" }}
                        onClick={() => {
                          setlead({ ...getlead, status: "DRAFT" });
                        }}
                        size="sm"
                        // disabled
                        disabled={disabledd}
                      >
                        Save as Draft
                      </Button>

                      <Button
                        style={{ backgroundColor: "#015652", color: "white" }}
                        // onClick={Addd}
                        disabled={loading}
                        onClick={() => {
                          setlead({ ...getlead, status: "SUBMITTED" });
                        }}
                        size="sm"
                      >
                        {/* <i class="fas fa-print"></i> */}
                        {loading ? <Loading attr="Submit" /> : "Submit"}
                      </Button>
                    </Row>
                  </Col>
                  {/* <Col style={{ marginTop: 15 }} lg="6" md="6" sm="6">
                    <table
                      style={{
                        border: "1px solid lightgrey",
                        width: "100%",
                        // marginTop: "-5px",
                      }}
                      className="align-items-center"
                      responsive
                    >
                      <thead
                        style={{
                          backgroundColor: "#D69929",
                          color: "white",
                          fontSize: "12px",
                        }}
                      >
                        <tr>
                          <th scope="col">Category</th>
                          
                          <th scope="col">Count</th>
                          <th scope="col">Remaining Selectable</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {newArr !== null && newArr !== undefined ? (
                          newArr.map((data, index) => {
                            let arr = new Array(data?.remaining)
                              .fill("")
                              .map((_, i) => i + 1);
                            return (
                              <tr>
                                <td> {data?.title}</td>
                               
                                <td>{data?.quantity}</td>

                                <td>
                                  <Form>
                                    {arr.map(() => {
                                      return (
                                        <FormGroup check inline>
                                          <Label check>
                                            <Input type="checkbox" />
                                          </Label>
                                        </FormGroup>
                                      );
                                    })}
                                  </Form>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <th></th>
                            <th>
                              <h3>No data found</h3>
                            </th>
                            <th></th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Col> */}
                </Row>
              </div>
            </form>
          </CardBody>
        </CardHeader>
      </Card>
    </div>
  );
}
export default Personal;
