

import { 
  CLIENT,CLIENT_SUCCESS,CLIENT_FAILURE,
 
} from "../constant.js";




export class ClientDetail {
  static Client() {
    return {
      type: CLIENT,
    };
  }
  static Client_Success(response) {
    return {
      type: CLIENT_SUCCESS,
      payload: response,
    };
  }
  static Client_Failure(error) {
    return {
      type: CLIENT_FAILURE,
      error,
    };
  }
 
}

