import axios from "axios";
import { UserLogin, VerifyByEmail, GetIssues, SendQuery } from "../action";
import {
  LOGIN_PATH,
  VERIFY_BY_EMAIL_PATH,
  QEURY,
  QEURY_SUCCESS,
  QEURY_FAILURE,
  QEURY_PATH,
  SEND_QUERY,
  SEND_QUERY_SUCCESS,
  SEND_QUERY_FAILURE,
  SEND_QUERY_PATH,
} from "../constant";
import { BASEURL } from "config/api/URL";

export const CustomerLogin = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(UserLogin.Login());
  axios
    .post(`${BASEURL}${LOGIN_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.data.status === true) {
        console.log("CHECK VERIFY DATA", res.data);
        dispatch(UserLogin.LoginSuccess(res.data));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    });
};

export const QueryList_Middleware = (onSuccess, onFailure) => (dispatch) => {
  console.log("HIT API");
  dispatch(GetIssues.Get());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${QEURY_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("ISSUE TYPE DATA", res.data.data);
        dispatch(GetIssues.GetIssuesSuccess(res.data.data));
        // dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        console.log("ERR", error.response);
        // onFailure(error.response.data);
      }
    });
};

export const VerifyUserByEmail = (body, onSuccess, onFailure) => (dispatch) => {
  console.log("HIT API", body);
  dispatch(VerifyByEmail.Account_verify());
  axios
    .post(`${BASEURL}${VERIFY_BY_EMAIL_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("CHECK VERIFY DATA", res.data);
      if (res.status === 201) {
        dispatch(VerifyByEmail.Account_verifySuccess(res.data));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};

export const Sendquerydetail = (body, OnSuccess, OnFailure) => (dispatch) => {
  dispatch(SendQuery.Send());
  console.log("BODY", body);
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${SEND_QUERY_PATH}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("DATA", res.data);
      dispatch(SendQuery.SendSuccess(res.data));
      dispatch(OnSuccess(res.data.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response);
        OnFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};
