import { CUSTOMER, CUSTOMER_SUCCESS, CUSTOMER_FAILURE,
  PLOTTYPE,PLOTTYPE_FAILURE,PLOTTYPE_SUCCESS,
  PLOTSIZE,PLOTSIZE_SUCCESS,PLOTSIZE_FAILURE, 
PLOTPROJECT_SUCCESS,PLOTPROJECT,PLOTPROJECT_FAILURE} from "../constant.js";


export class ProjectDetail {
  static PlotProject() {
    return {
      type: PLOTPROJECT,
    };
  }
  static PlotProject_Success(response) {
    return {
      type: PLOTPROJECT_SUCCESS,
      payload: response,
    };
  }
  static PlotProject_Failure(error) {
    return {
      type: PLOTPROJECT_FAILURE,
      error,
    };
  }
}


export class CustomerDetail {
  static Customer() {
    return {
      type: CUSTOMER,
    };
  }
  static Customer_Success(response) {
    return {
      type: CUSTOMER_SUCCESS,
      payload: response,
    };
  }
  static Customer_Failure(error) {
    return {
      type: CUSTOMER_FAILURE,
      error,
    };
  }
}

export class PlotTypeDetail {
  static PlotType() {
    return {
      type: PLOTTYPE,
    };
  }
  static PlotType_Success(response) {
    return {
      type: PLOTTYPE_SUCCESS,
      payload: response,
    };
  }
  static PlotType_Failure(error) {
    return {
      type: PLOTTYPE_FAILURE,
      error,
    };
  }
}

export class PlotSizeDetail {
  static PlotSize() {
    return {
      type: PLOTSIZE,
    };
  }
  static PlotSize_Success(response) {
    return {
      type: PLOTSIZE_SUCCESS,
      payload: response,
    };
  }
  static PlotSize_Failure(error) {
    return {
      type: PLOTSIZE_FAILURE,
      error,
    };
  }
  
 
}
