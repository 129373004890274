import {
  FETCH_ALL_REQUEST,
  FETCH_ALL_REQUEST_SUCCESS,
  FETCH_ALL_REQUEST_FAILURE,
  PRINT_FORM,
  PRINT_FORM_SUCCESS,
  PRINT_FORM_FAILURE,
  PAY_FORM_FEE,
  PAY_FORM_FEE_SUCCESS,
  PAY_FORM_FEE_FAILURE,
} from "../constant.js";

const INITIAL_STATE = {
  Reports: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
  Count: null,
  StaffRequests: [],
  PrintForm: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;

    case FETCH_ALL_REQUEST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        StaffRequests: [],
        error: {},
      };
    case FETCH_ALL_REQUEST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        StaffRequests: action.payload,
      };
    case FETCH_ALL_REQUEST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case PRINT_FORM:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        PrintForm: null,
        error: {},
      };
    case PRINT_FORM_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        PrintForm: action.payload,
      };
    case PRINT_FORM_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case PAY_FORM_FEE:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        PayResponse: null,
        error: {},
      };
    case PAY_FORM_FEE_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        PayResponse: action.payload,
      };
    case PAY_FORM_FEE_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
