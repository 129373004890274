import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import TablePricingListView from "../components/TablePricingListView";
import { connect } from "react-redux";
// import { AdminAppList_Middleware } from "../middleware";
import Pagination from "components/Pagination/Pagination";
import {
  FormPricing_Middleware,
  UpdateFormPricing_Middleware,
} from "../middleware";

const Pricing = ({
  _FormPricing_Middleware,
  FormPrice,
  _UpdateFormPricing_Middleware,
  PriceResponse
}) => {
  // const [noOfRows, setnoOfRows] = useState(10);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [posts, setPosts] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  ///////////----------Pagination--------------//////////////
  // let postNumber = 10;
  // let paginatedPosts, total_pages;

  // if (noOfRows != "") {
  //   postNumber = noOfRows;
  // }
  // const start = pageNumber * postNumber - postNumber;
  // const end = start + postNumber;

  // if (posts) {
  //   paginatedPosts = posts;
  //   // paginatedPosts = posts?.slice(start, end);
  //   total_pages = Math.ceil(posts.length / postNumber);
  // }

  ///////////----------Functions--------------//////////////

  // const onChangeNoOfRows = (val) => {
  //   setnoOfRows(parseInt(val));
  //   setPageNumber(1);
  // };
  // const handlePrev = () => {
  //   if (pageNumber === 1) return;
  //   setPageNumber(pageNumber - 1);
  // };

  // const handleNext = () => {
  //   if (pageNumber) {
  //     setPageNumber(pageNumber + 1);
  //   } else {
  //     return;
  //   }
  // };
  const onSuccess = () => {};
  const onFailure = () => {};

  // const ToggleViewReceiptModal = () => {
  //   setIsViewModalOpen(!isViewModalOpen);
  // };
  //////////-------useEffects--------//////////////

  useEffect(() => {
    _FormPricing_Middleware(onSuccess, onFailure);
  }, [PriceResponse]);
  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Pricing List</h2>
                  </Col>
                </Row>
                <hr />
              </CardHeader>
              <CardBody>
                <TablePricingListView
                  _UpdateFormPricing_Middleware={_UpdateFormPricing_Middleware}
                  formPrice={FormPrice}
                />
                {/* <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                /> */}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  FormPrice: state.pricingModule.FormPrice,
  PriceResponse: state.pricingModule.PriceResponse,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _FormPricing_Middleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        FormPricing_Middleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
    _UpdateFormPricing_Middleware: (body, onSuccess, onFailure) =>
      dispatch(UpdateFormPricing_Middleware(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Pricing);
