import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import TableOfApplicationList from "./TableOfApplicationList";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
const Filteration = (props) => {
  const [filter, setFilter] = useState("");
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [valuesAndKey, setValueAndKey] = useState({});
  const [checkedData, setCheckedData] = useState(false);
  const [criteriaType, setCriteriaType] = useState({
    criteria: "cnic",
  });
  const [isTrue, setisTrue] = useState(false);
  const handleData = (data) => {
    console.log("Datafromparent", data);
    setCheckedData(data);
  };
  useEffect(() => {
    setPageNumber(1);
    props.GetApplicationListMiddleware(
      pageNumber,
      noOfRows,
      valuesAndKey,
      onSuccess,
      onFailure
    );
  }, [valuesAndKey]);

  useEffect(() => {
    props.GetApplicationListMiddleware(
      pageNumber,
      noOfRows,
      valuesAndKey,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows, props.info, isTrue]);
  // useEffect(() => {

  //   props.GetApplicationListMiddleware(pageNumber, noOfRows, valuesAndKey, onSuccess, onFailure);

  // }, [props.info]);
  // useEffect(() => {
  //   props.GetApplicationListMiddleware(pageNumber, noOfRows, valuesAndKey, onSuccess, onFailure);

  // }, [isTrue])

  useEffect(() => {
    if (props.List !== null && props.List !== undefined) {
      setPosts(props.List);
    }
  }, [props.List]);
  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      setValueAndKey({
        key: key,
        value: value,
      });
    });
  }, [filter]);
  useEffect(() => {
    setFilter({ [criteriaType.criteria]: "" });
  }, [criteriaType]);

  ///////////----------Pagination--------------//////////////

  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    if (checkedData == true) {
      // console.log("show alert msg");
      swal({
        title: "Are you sure?",
        text: "If yes, you will lost your checked items",
        icon: "warning",
        buttons: ["Cancel", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          onChangeNoOfRows("0");
          onChangeNoOfRows("10");
        }
      });
    } else {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {
    setPosts("");
    setisTrue(false);
  };
  const onFailure = () => {
    setisTrue(false);
  };

  return (
    <CardHeader className="border-0">
      <Row>
        <Col lg="2" md="2" sm="6">
          <h2 className="mb--3">Application List</h2>
        </Col>
        <Col lg="8" md="8" sm="6"></Col>
        <Col lg="2" md="2" sm="6"></Col>
      </Row>
      <hr />
      <CardBody className="mt--4">
        <Row>
          <Col lg="2" md="2" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
          <Col lg="4" md="4" sm="4">
            <label className="form-control-label" for="input-username">
              Search Criteria
            </label>
            <select
              className="form-control"
              onChange={(e) =>
                setCriteriaType({
                  ...criteriaType,
                  criteria: e.target.value,
                })
              }
            >
              {/* <option value="">Search Criteria</option> */}
              <option value="cnic">CNIC</option>
              <option value="name">Name</option>

              <option value="phone">Phone</option>
              <option value="email">Email</option>
              <option value="whatsAppNo">WhatsApp No</option>
              <option value="formNo">Form No</option>
              {/* <option value="depositSlipNo">DepositSlip No</option> */}
              <option value="status">Status</option>
            </select>
          </Col>
          <Col lg="4" md="4" sm="4">
            <label className="form-control-label" for="input-username">
              Search
            </label>
            <input
              type="text"
              id="input-username"
              className="form-control"
              placeholder={
                criteriaType.criteria == "name"
                  ? "Name"
                  : criteriaType.criteria == "cnic"
                  ? "XXXXXXXXXXXXX"
                  : criteriaType.criteria == "phone"
                  ? "92XXXXXXXXXX"
                  : criteriaType.criteria == "whatsAppNo"
                  ? "92XXXXXXXXXX"
                  : criteriaType.criteria == "email"
                  ? "abc@xyz.com"
                  : criteriaType.criteria == "formNo"
                  ? "Form No"
                  : criteriaType.criteria == "depositSlipNo"
                  ? "DepositSlip No"
                  : criteriaType.criteria == "status"
                  ? "New/Submitted"
                  : ""
              }
              value={filter[criteriaType.criteria]}
              onChange={(e) =>
                setFilter({ [criteriaType.criteria]: e.target.value })
              }
            ></input>
            {/* <br /> */}
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        {/* <Row>
         
        </Row>
        <br /> */}
        <TableOfApplicationList
          paginatedPosts={paginatedPosts}
          FormSubmitMiddleware={props.FormSubmitMiddleware}
          info={props.info}
          middleware={props}
          valuesAndKey={valuesAndKey}
          pageNumber={pageNumber}
          noOfRows={noOfRows}
          onSuccess={onSuccess}
          onFailure={onFailure}
          setisTrue={setisTrue}
          pageSize={noOfRows}
          pageIndex={pageNumber}
          handleData={handleData}
          getChalanDataMiddleware={props.getChalanDataMiddleware}
          // UploadImageMiddleware={props.UploadImageMiddleware} image={props.image}
        ></TableOfApplicationList>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
