import { connect } from "react-redux";

import ApplicationList from "./CustomerList";
import { GetCustomerListMiddleware,DeleteCustomerMiddleware,GetCustomerFileMiddleware,InsertCustomerMiddleware} from "../middleware";
import { FetchUser_Middleware,FetchPrice_Middleware } from "views/AgentView/AgentRequest/middleware";

const mapStateToProps = (state) => ({

  responseCustomer:state.customerListAdmin.responseCustomer,
  del:state.customerListAdmin.del,
  file:state.customerListAdmin.file,
  Price: state.agentRequest.Price,
  User: state.agentRequest.User, 
  customer:state.customerListAdmin.customer,


 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    GetCustomerFileMiddleware:(onSuccess, onFailure)=>
    dispatch(GetCustomerFileMiddleware(onSuccess, onFailure)),
    DeleteCustomerMiddleware:(id,onSuccess,onFailure)=>
    dispatch(DeleteCustomerMiddleware(id,onSuccess,onFailure)),
    GetCustomerListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(GetCustomerListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)),

      FetchUser_Middleware:(cnic, onSuccess,onFailure)=>
      dispatch(FetchUser_Middleware(cnic,onSuccess,onFailure)),
      _FetchPrice_Middleware: (onSuccess, onFailure) =>
      dispatch(FetchPrice_Middleware(onSuccess, onFailure)),
      InsertCustomerMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(InsertCustomerMiddleware(body, OnSuccess, OnFailure)),
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(ApplicationList);
