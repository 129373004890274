import axios from "axios";
import {
  FetchAllStaff,
  FetchBranchs,
  StaffSignUp,
  DeleteStaff,
  EditStaff,
} from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_STAFF_PATH,
  FETCH_BRANCHS_BY_ID_PATH,
  STAFF_SIGNUP_FOR_MANAGER_PATH,
  DELETE_STAFF_PATH,
  EDIT_STAFF_PATH,
} from "../constant";

export const FetchStaff_Middleware =
  (pageNumber, noOfRows, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchAllStaff.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_STAFF_PATH}?page=${pageNumber}&limit=${noOfRows}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchAllStaff.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

export const FetchBranchesById_Middleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchBranchs.Fetch());
    axios
      .get(`${BASEURL}${FETCH_BRANCHS_BY_ID_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchBranchs.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

export const Staff_SignUpForManager =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(StaffSignUp.SignUp());
    let token = localStorage.getItem("auth");

    axios
      .post(`${BASEURL}${STAFF_SIGNUP_FOR_MANAGER_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch(StaffSignUp.SignUpSuccess(res.data.message));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data.message);
        }
      });
  };

export const DeleteById_Middleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(DeleteStaff.Delete());
    let token = localStorage.getItem("auth");
    axios
      .delete(`${BASEURL}${DELETE_STAFF_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(DeleteStaff.DeleteSuccess(res.data.message));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

export const EditById_Middleware =
  (body, id, onSuccess, onFailure) => (dispatch) => {
    dispatch(EditStaff.Edit());
    let token = localStorage.getItem("auth");
    axios
      .patch(`${BASEURL}${EDIT_STAFF_PATH}/${id}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(EditStaff.EditSuccess(res.data.message));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data.message);
        }
      });
  };
