export const FETCH_ALL_REQUEST = "FETCH_ALL_REQUEST";
export const FETCH_ALL_REQUEST_SUCCESS = "FETCH_ALL_REQUEST_SUCCESS";
export const FETCH_ALL_REQUEST_FAILURE = "FETCH_ALL_REQUEST_FAILURE";
export const FETCH_ALL_REQUEST_PATH = "application-request/list-for-staff";

export const PRINT_FORM = "PRINT_FORM";
export const PRINT_FORM_SUCCESS = "PRINT_FORM_SUCCESS";
export const PRINT_FORM_FAILURE = "PRINT_FORM_FAILURE";
export const PRINT_FORM_PATH = "uploadfile/download/form";

export const PAY_FORM_FEE = "PAY_FORM_FEE";
export const PAY_FORM_FEE_SUCCESS = "PAY_FORM_FEE_SUCCESS";
export const PAY_FORM_FEE_FAILURE = "PAY_FORM_FEE_FAILURE";
export const PAY_FORM_FEE_PATH = "application-request/pay-form-fee-for-staff";
