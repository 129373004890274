export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_PATH = "real-estate/agent-signup";

export const CITY = "CITY";
export const CITY_SUCCESS = "CITY_SUCCESS";
export const CITY_FAILURE = "CITY_FAILURE";
export const CITY_PATH = "city/by-country";

export const COUNTRY = "COUNTRY";
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";
export const COUNTRY_FAILURE = "COUNTRY_FAILURE";
export const COUNTRY_PATH = "country";
