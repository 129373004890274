import React, { useEffect, useRef, useState } from "react";
// import Loader from "react-loader-spinner";
// import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table, Label } from "reactstrap";
import swal from "sweetalert";
// import ComponentToPrint from "./ComponentToPrint";
// import PriceUpdateModal from "./PriceUpdateModal";

const TableBankAppListView = ({ paginatedPosts }) => {
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [oldPrice, setOldPrice] = useState("");

  const ToggleViewReqListModal = (price) => {
    setIsViewModalOpen(!isViewModalOpen);
    setOldPrice(price);
  };
  const onSuccessCancelation = () => {
    swal("Form Cancel successfully!", "", "success");
  };
  const onFailureCancelation = () => {};
  return (
    <div>
    
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Name</th>
            <th scope="col">CNIC</th>
            <th scope="col">Email</th>
            <th scope="col">Application Request Id</th>
            <th scope="col">Deposit Slip No</th>
            <th scope="col">Phone</th>
            <th scope="col">WhatsApp No</th>
            <th scope="col">Payment Ref</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {paginatedPosts !== null &&
          paginatedPosts !== undefined &&
          paginatedPosts.length > 0 ? (
            paginatedPosts?.map((request, index) => {
              return (
                <tr key={request.id}>
                  <td>{index + 1}</td>
                  <td>{request?.name}</td>
                  <td>{request?.cnic}</td>
                  <td>{request?.email}</td>
                  <td>{request?.applicationRequestId}</td>
                  <td>{request?.depositSlipNo}</td>
                  <td>{request?.phone}</td>
                  <td>{request?.whatsAppNo}</td>
                  <td>{request?.paymentRef}</td>
                  <td>
                    {request?.Status == "Pending" ? (
                      <span className="badge badge-danger">
                        {request?.Status}
                      </span>
                    ) : request?.Status == "Paid" ? (
                      <span className="badge badge-success">
                        {request?.Status}
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableBankAppListView;
