import {
 
  CUSTOMERLIST,CUSTOMERLIST_SUCCESS,CUSTOMERLIST_FAILURE, 
  CUSTOMERDELETE,CUSTOMERDELETE_SUCCESS,CUSTOMERDELETE_FAILURE,
  CUSTOMERFILE_FAILURE,CUSTOMERFILE_SUCCESS,CUSTOMERFILE,
   
  CUSTOMER,CUSTOMER_SUCCESS,CUSTOMER_FAILURE,

  
} from "../constant";
const INITIAL_STATE = {

  responseCustomer:null,
  del:null,
  file:null,
 

 
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
      case CUSTOMERFILE:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          file: null,
          error: {},
        };
      case CUSTOMERFILE_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          file: action.payload,
        };
      case CUSTOMERFILE_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


      case CUSTOMERDELETE:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          del: null,
          error: {},
        };
      case CUSTOMERDELETE_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          del: action.payload,
        };
      case CUSTOMERDELETE_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


  case CUSTOMERLIST:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          responseCustomer: null,
          error: {},
        };
      case CUSTOMERLIST_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          responseCustomer: action.payload,
        };
      case CUSTOMERLIST_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };
        case CUSTOMER:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          customer: null,
          error: {},
        };
      case CUSTOMER_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          customer: action.payload,
        };
      case CUSTOMER_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


     
  }
};
