import {
  FETCH_BANK_APP_LIST,
  FETCH_BANK_APP_LIST_SUCCESS,
  FETCH_BANK_APP_LIST_FAILURE,
} from "../constant";

export class FetchBankAppList {
  static Fetch() {
    return {
      type: FETCH_BANK_APP_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_BANK_APP_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_BANK_APP_LIST_FAILURE,
      error,
    };
  }
}
