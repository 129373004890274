import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import TableOfCustomerList from "./TableOfCustomerList";
import { useHistory } from "react-router-dom";
import Information from "../helpers/Information";
import RequestCustomerViewModal from "../components/RequestCustomerViewModal";



const Filteration = (props) => {
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [filter, setFilter] = useState("");
  const [valuesAndKey, setValueAndKey] = useState({});
  const [criteriaType, setCriteriaType] = useState({
    criteria: "",
  });
  const history = useHistory();

  useEffect(() => {
    
    props.GetCustomerListMiddleware(pageNumber, noOfRows, valuesAndKey, onSuccess, onFailure);
   }, [pageNumber, noOfRows, valuesAndKey]);
   useEffect(() => {
 
     Object.entries(filter).forEach(([key, value]) => {
       setValueAndKey({
         key: key,
         value: value,
       });
     });
   }, [filter]);
   useEffect(() => {
    setFilter({ [criteriaType.criteria]: ""})
   }, [criteriaType])
   useEffect(() => {
     console.log(props.file,"props.file");
     window.open(props.file?.url,"_self");
    }, [props.file])
    useEffect(() => {
     props.GetCustomerListMiddleware(pageNumber, noOfRows, valuesAndKey, onSuccess, onFailure);
   }, [props.del])
 

 

 
  // let dataInArrayForPaginaion = [];
  // useEffect(() => {
  //   setPosts(null);
  //   setPageNumber(1);
  //   if (props.Client !== null && props.Client !== undefined) {
  //     for (let i = 0; i < props.Client.length; i++) {
  //       dataInArrayForPaginaion.push(props.Client[i]);
  //       setPosts(props.Client);
  //     }
  //   } else {
  //   }
  // }, [props.Client]);
  useEffect(() => {
  
    if (props.responseCustomer!==null && props.responseCustomer!==undefined) {
      setPosts(props.responseCustomer);
    }
  }, [props.responseCustomer]);

  console.log(props.responseCustomer,"props.responseCustomer")
 


  
 
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  ///////////----------Pagination--------------//////////////

  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };

  
  
console.log(paginatedPosts,"paginatedpost")



//   const addNewCustomer=()=>{
// //history.push({pathname:'/user/BankList'})
// let path = "/user/Customer";
// history.push(path);

//   }




  const excelDownload=()=>{
    props.GetCustomerFileMiddleware(onSuccessFile,onFailureFile)
  }

const onSuccessFile=()=>{}
const onFailureFile=()=>{}  
 const onSuccess = () => {
    
  };
  const onFailure= () => {};
  const [isOpen, setIsOpen] = useState(false);
  const toggler = (detail) => {
 
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    props._FetchPrice_Middleware(onSuccess, onFailure);
  }, []);
  useEffect(() => {
    props.GetCustomerListMiddleware(pageNumber, noOfRows, valuesAndKey, onSuccess, onFailure);
  }, [props.customer])
  


  return (
  
    <CardHeader className="border-0">
        <RequestCustomerViewModal
    modal={isOpen}
    toggle={toggler}
    FetchUser_Middleware={props.FetchUser_Middleware} User={props.User}
     pricing={props.Price}
   // _FetchPrice_Middleware={props._FetchPrice_Middleware}
   InsertCustomerMiddleware ={props.InsertCustomerMiddleware}  
  // customer={props.customer}

    
  />
    <Row>
      <Col  lg="2" md="2" sm="6">
        <h2>
        Customer List 
        </h2>
      </Col>
      <Col  lg="8" md="8" sm="6"></Col>
      <Col  lg="2" md="2" sm="6"> 
      <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={toggler}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       Add New Customer
                      </span>
                    </Button>
      </Col>
      
    </Row>
    <hr/>
      <CardBody>
      
        <form>
          
        <Row>
      <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Selection
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setCriteriaType({
                          ...criteriaType,
                          criteria: e.target.value,
                        })
                      }
                    >
                      <option value="">Search Criteria</option>
                      <option value="name">Name</option>
                      <option value="cnic">CNIC</option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                    
                    </select>
                  </Col>
      <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      value={filter[criteriaType.criteria]}
                      onChange={(e) =>
                        setFilter({ [criteriaType.criteria]: e.target.value })
                      }
                    ></input>
                    <br />
                  </Col>
                
                </Row>
           
     
        </form>
      
      </CardBody>
      <hr/>
      <CardBody>
      <Row>
          <Col lg="2" md="2" sm="4" xs="4">
              <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="lg"
                      onClick={excelDownload}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       Excel
                      </span>
                    </Button>
            {/* <label className="form-control-label"> Rows Per Pages </label> */}
           
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
        </Row>
        <br />
        <TableOfCustomerList
           
           DeleteCustomerMiddleware={props.DeleteCustomerMiddleware} del={props.del}
            // onChangeNoOfRows={onChangeNoOfRows}
            paginatedPosts={paginatedPosts}
         
          //response={props.response}
        ></TableOfCustomerList>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
