import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import TableOfBankList from "./TableOfBankList";
import { useHistory } from "react-router-dom";

const Filteration = (props) => {
  const history = useHistory();
  const onSuccess = () => {};
  const onFailure = () => {};

  // let dataInArrayForPaginaion = [];
  // useEffect(() => {
  //   setPosts(null);
  //   setPageNumber(1);
  //   if (props.Client !== null && props.Client !== undefined) {
  //     for (let i = 0; i < props.Client.length; i++) {
  //       dataInArrayForPaginaion.push(props.Client[i]);
  //       setPosts(props.Client);
  //     }
  //   } else {
  //   }
  // }, [props.Client]);
  useEffect(() => {
    if (props.response?.items) {
      setPosts(props.response?.items);
    }
  }, [props.response?.items]);

  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  ///////////----------Pagination--------------//////////////

  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };

  useEffect(() => {
    props.GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure);
  }, [pageNumber, noOfRows]);
  const addNewBank = () => {
    //history.push({pathname:'/user/BankList'})
    let path = "/user/Bank";
    history.push(path);
  };
  useEffect(() => {
    props.GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure);
  }, [props.del]);

  return (
    <CardHeader className="border-0">
      <Row>
        <Col lg="2" md="2" sm="6">
          <h2>Bank List</h2>
        </Col>
        <Col lg="8" md="8" sm="6"></Col>
        <Col lg="2" md="2" sm="6">
          <Button
            style={{ backgroundColor: "#015652", color: "white" }}
            size="sm"
            onClick={addNewBank}
            id="info"
          >
            <span className="btn-inner--icon">Add New Bank</span>
          </Button>
        </Col>
      </Row>
      <hr />
      {/* <CardBody>
      
        <form>
          
            <Row>
            <Col lg="2" md="2" sm="6">
            <label className="form-control-label" for="input-username">
                 Search
                </label>
                <input
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9-+]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={13}
                  onBlur={() => CheckFields("cnic")}
                  id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.Cnic}
                  onChange={(e) => onChange(e.target.value, "Cnic")}
                ></input>
              </Col>
             
            </Row>
           
        
        </form>
      
      </CardBody> */}
      <CardBody>
        <Row>
          <Col lg="2" md="2" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
        </Row>
        <br />
        <TableOfBankList
          DeleteBankMiddleware={props.DeleteBankMiddleware}
          del={props.del}
          // onChangeNoOfRows={onChangeNoOfRows}
          paginatedPosts={paginatedPosts}

          //response={props.response}
        ></TableOfBankList>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
