import {
    FETCH_PRICING_DATA,
    FETCH_PRICING_DATA_SUCCESS,
    FETCH_PRICING_DATA_FAILURE,
    UPDATE_PRICING_DATA,
    UPDATE_PRICING_DATA_SUCCESS,
    UPDATE_PRICING_DATA_FAILURE,
  } from "../constant.js";
  
  
  export class FetchFormPricing {
    static Fetch() {
      return {
        type: FETCH_PRICING_DATA,
      };
    }
    static FetchSuccess(response) {
      return {
        type: FETCH_PRICING_DATA_SUCCESS,
        payload: response,
      };
    }
    static FetchFailure(error) {
      return {
        type: FETCH_PRICING_DATA_FAILURE,
        error,
      };
    }
  }
  
  export class UpdateFormPricing {
    static Update() {
      return {
        type: UPDATE_PRICING_DATA,
      };
    }
    static UpdateSuccess(response) {
      return {
        type: UPDATE_PRICING_DATA_SUCCESS,
        payload: response,
      };
    }
    static UpdateFailure(error) {
      return {
        type: UPDATE_PRICING_DATA_FAILURE,
        error,
      };
    }
  }