import {
  FETCH_PRICING_DATA,
  FETCH_PRICING_DATA_SUCCESS,
  FETCH_PRICING_DATA_FAILURE,
  UPDATE_PRICING_DATA,
  UPDATE_PRICING_DATA_SUCCESS,
  UPDATE_PRICING_DATA_FAILURE,
} from "../constant.js";

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  PriceResponse: null,
  FormPrice: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_PRICING_DATA:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        FormPrice: null,
        error: {},
      };
    case FETCH_PRICING_DATA_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        FormPrice: action.payload,
      };
    case FETCH_PRICING_DATA_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case UPDATE_PRICING_DATA:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        PriceResponse: null,
        error: {},
      };
    case UPDATE_PRICING_DATA_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        PriceResponse: action.payload,
      };
    case UPDATE_PRICING_DATA_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
