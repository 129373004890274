import axios from "axios";
import { AgentListDetail,AgentDeleteDetail } from "../action/index";
import { AGENTLIST_PATH ,AGENTDELETE_PATH} from "../constant";
import { BASEURL } from "config/api/URL";



export const DeleteAgentMiddleware =
  (id, onSuccess, onFailure) => (dispatch) => {
   
    dispatch(AgentDeleteDetail.AgentDelete());
    let token = localStorage.getItem("auth");
    axios
      .delete(
        `${BASEURL}${AGENTDELETE_PATH}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(AgentDeleteDetail.AgentDelete_Success(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
           onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };





export const GetAgentListMiddleware =

  (pageNumber, noOfRows, valueAndKey, onSuccess, onFailure) => (dispatch) => {
    
    
    console.log(pageNumber, noOfRows);
    dispatch(AgentListDetail.AgentList());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${AGENTLIST_PATH}?page=${pageNumber}&limit=${noOfRows}&${valueAndKey.key}=${valueAndKey.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        
  
          dispatch(AgentListDetail.AgentList_Success(res.data.data.items));
          dispatch(onSuccess(res.message));
      
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
        } else {}
      });
   
  };
