import React, { useEffect } from "react";

import { useState } from "react";
import { Button, Table } from "reactstrap";




import { useHistory } from "react-router";
import Information from "../helpers/Information";

const TableOfViewCustomer = (props) => {
  let roleId = localStorage.getItem("roleid");
  let sNo = 1;
  const [isOpen, setIsOpen] = useState(false);

  let history = useHistory();

  const [ClientDetail, setClientDetail] = useState({});
  const toggler = (detail) => {
    setClientDetail(detail);
    setIsOpen(!isOpen);
  };


  const onMembership=()=>{

    history.push({
      pathname: "/user/membershipForm",
      // state: post,
    });
  }




  const dateFunction = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Karachi",
    });
    return nDate;
  };
  

  // useEffect(() => {
  //   if (props.GetUserJWTLogin === false) {
  //     localStorage.removeItem("auth");
  //     window.location.href = "/auth/login";
  //   } else {
  //     if (props.paginatedPosts !== null && props.paginatedPosts !== undefined) {
  //       let str = toString(props.paginatedPosts.Datetime);
  //       // str = str.split("T");
  //       for (let i = 0; i < props.paginatedPosts.length; i++) {
  //         if (
  //           props.paginatedPosts.Agentname == props.state.agentName &&
  //           props.state.date === ""
  //         ) {
  //         } else if (
  //           str[0] == props.state.date &&
  //           props.state.agentName === ""
  //         ) {
  //         } else if (
  //           str[0] == props.state.date &&
  //           props.paginatedPosts.Agentname == props.state.agentName
  //         ) {
  //         } else if (
  //           props.state.agentName === "" &&
  //           props.state.date === "" &&
  //           (props.paginatedPosts.teamid == props.teamId ||
  //             props.teamId == null)
  //         ) {
  //         }
  //       }
  //     }
  //   }
  // }, [props.paginatedPosts]);

 
  const [isOpenChangeModal, setIsOpenChangeModal] = useState(false);
  const [ClientDetailModal, setClientDetailModal] = useState({});
  const Modaldata = (posts) => {
    setClientDetailModal(posts);
    setIsOpenChangeModal(!isOpenChangeModal);
  };

  return (
    <>
      <Information
        modal={isOpen}
        toggle={toggler}
        detail={ClientDetail}
        state={props.state}
      />

      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929", color:"white" }} >
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Application No</th>
            <th scope="col">Applicant Name</th>
            <th scope="col">CNIC</th>
            <th scope="col">Email</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Payment Ref</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {/* {props.paginatedPosts !== null &&
          props.paginatedPosts !== undefined ? (
            props.paginatedPosts.map((posts, index) => {
              return (
                <tr>
                  <td>{sNo++}</td>
                  <td>{posts.name}</td>
                  <td>{posts.mobile}</td>
                  <td>{posts.plotQuantity}</td>
                  <td>{posts.Project?.title}</td>

                  <td>{posts.plot_type?.title}</td>
                  <td>{posts.plot_category?.title}</td>
                 <td>
                    <Button
                      color="success"
                      size="sm"
                      onClick={(e) => toggler(posts)}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                        <i class="fas fa-info-circle"></i>
                      </span>
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={(e) => proceed(posts)}
                      id="info"
                      disabled={posts.receiptStatusId !== 1}
                    >
                      <span className="btn-inner--icon">
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </Button>
                 </td>
                </tr>
              );
            }
            )
          ) : (
            <tr>
              <th></th>
              <th></th>

              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )} */}
          <tr>
            <th>1</th>
            <th>1001</th>
            <th>iqra</th>
            <th>iqra</th>
            <th>iqra</th>
            <th>iqra</th>
            <th> 
              <span class="badge badge-danger">PENDING</span>
            
            </th>
            <th> 
              <Button
                      style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                    onClick={(e) => onMembership()}
                      id="info"
                   
                    >
                      <span className="btn-inner--icon">
                       Proceed
                      </span>
                    </Button>
                    <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={(e) => toggler()}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       View
                      </span>
                    </Button>
                    </th>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
export default TableOfViewCustomer;
