import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

import Headers from "components/Headers/Header1";
import { Button } from "reactstrap";
import newwlogoo from "assets/img/squarepro1.png";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LaptopWindows } from "@material-ui/icons";
import { GetSocketRequestDetails } from "sockets/socket";
import { GetSocketPaymentDetails } from "sockets/socket";
import NewWindow from "react-new-window";
const PaymentConfirmation = () => {
  var history = useHistory();
  var location = useLocation();

  const [state, setstate] = useState("");
  const [stateUser, setStateUser] = useState("");
  const [url, seturl] = useState("");

  let a;
  let user;
  useEffect(() => {
    setstate(location.state);
    console.log(location.state, "location.state from registeration");
    // GetSocketRequestDetails(location.state.id, history);
    seturl(location.state?.blinqInvoice?.clickToPayUrl);

    a = localStorage?.getItem("user");
    user = JSON.parse(a);
    setStateUser(user);
  }, [location.state]);

  const dateFunction = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Karachi",
    });
    return nDate;
  };
  const paymentLater = () => {
    window.fbq("track", "PayLater");
    state?.NumberOfApplication
      ? history.push({ pathname: "/user/requestUserView" })
      : history.push({ pathname: "/user/ApplicationList" });
  };
  const onblinq = () => {
    // <NewWindow>
    //   <h1>Hi 👋</h1>
    // </NewWindow>;
    if (state?.NumberOfApplication) {
      window.fbq("track", "PayNowButton");
      GetSocketRequestDetails(state.id, history);
      window.open(`${url}`, "", "width=700,height=500,left=400,top=120,");
      // window.open(url, "_blank");
    } else {
      window.fbq("track", "PayNowButton");
      GetSocketPaymentDetails(state.id, history);
      window.open(`${url}`, "", "width=700,height=500,left=400,top=120");
      // window.open(url, "_blank");
    }
  };

  return (
    <div>
      <Headers />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card
              // style={{ marginLeft: "120px", marginRight: "120px" }}
              className="shadow m-auto w-50 "
            >
              <CardHeader
                className="border-0"
                style={{ backgroundColor: "#d69929" }}
              >
                <Row>
                  <Col style={{ marginTop: -30 }} lg="6" md="6" sm="6">
                    <img
                      // alt={logo.imgAlt}
                      // className="navbar-brand-img"
                      style={{ width: "65%" }}
                      src={newwlogoo}
                    />
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <h1 style={{ color: "#ffff" }}>Order ID:{state.id}</h1>
                    {/* <br /> */}
                    <h3 style={{ color: "#ffff" }}>
                      {dateFunction(state.updatedAt)}
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              {/* <Divider /> */}
              {/* <hr /> */}

              <CardBody>
                <form>
                  <div>
                    <Row>
                      {/* <Col lg="2" md="2" sm="6">
                        {" "}
                      </Col> */}
                      <Col lg="12" md="12" sm="12">
                        <h1>{state.name}</h1>
                      </Col>

                      {/* <Col lg="6" md="6" sm="5">
                        <h4> Email: {stateUser.email}</h4>
                        <h4> Phone: {stateUser.phone}</h4>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <h4>
                          {" "}
                          Email:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {stateUser.email}
                          </span>
                        </h4>
                      </Col>
                    </Row>
                    {/* <br /> */}
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <h4>
                          {" "}
                          Phone:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {stateUser.phone}
                          </span>
                        </h4>
                      </Col>
                    </Row>
                    {/* <br /> */}
                    {state?.NumberOfApplication ? (
                      <Row>
                        <Col lg="12" md="12" sm="12">
                          <h4>
                            {" "}
                            No of Forms:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {state.NumberOfApplication}
                            </span>
                          </h4>
                        </Col>
                      </Row>
                    ) : null}
                    {/* <br /> */}
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <h4>
                          {" "}
                          Total:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            RS: {state.blinqInvoice?.invoiceAmount}
                          </span>
                        </h4>
                      </Col>
                    </Row>
                    {/* <br /> */}

                    <Row style={{ marginTop: 10 }}>
                      {/* <Col lg="4" md="4" sm="5"></Col> */}
                      <Col lg="6" md="6" sm="6">
                        <Button
                          style={{
                            backgroundColor: "#015652",
                            color: "white",
                            width: "100%",
                          }}
                          // style={{ backgroundColor: "#015652", color: "white" }}
                          id="search"
                          size="md"
                          onClick={paymentLater}
                        >
                          <span className="btn-inner--text"> Pay Later</span>
                        </Button>
                      </Col>
                      <Col lg="6" md="6" sm="6">
                        <Button
                          style={{
                            backgroundColor: "#015652",
                            color: "white",
                            width: "100%",
                          }}
                          // style={{ backgroundColor: "#015652", color: "white" }}
                          id="search"
                          size="md"
                          onClick={onblinq}
                        >
                          <span className="btn-inner--text"> Pay Now</span>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentConfirmation;
