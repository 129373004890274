import {
 
  // STAFF,STAFF_SUCCESS,STAFF_FAILURE, 
  STAFFEDIT,STAFFEDIT_SUCCESS,STAFFEDIT_FAILURE
} from "../constant";
const INITIAL_STATE = {

  //staff:null,
  edit:null,
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
      case STAFFEDIT:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          edit: null,
          error: {},
        };
      case STAFFEDIT_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          edit: action.payload,
        };
      case STAFFEDIT_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


  // case STAFF:
  //       return {
  //         ...states,
  //         isLoading: true,
  //         isError: false,
  //         isLoggedIn: false,
  //         staff: null,
  //         error: {},
  //       };
  //     case STAFF_SUCCESS:
  //       return {
  //         ...states,
  //         isLoading: false,
  //         isLoggedIn: true,
  //         staff: action.payload,
  //       };
  //     case STAFF_FAILURE:
  //       return {
  //         ...states,
  //         isLoading: false,
  //         isError: true,
  //         error: action.error,
  //       };

     
  }
};
