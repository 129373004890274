


export const BANKLIST =  "BANKLIST";
export const BANKLIST_SUCCESS =  "BANKLIST_SUCCESS";
export const BANKLIST_FAILURE =  "BANKLIST_FAILURE";
export const BANKLIST_PATH = "bank";

export const BANKDELETE =  "BANKDELETE";
export const BANKDELETE_SUCCESS =  "BANKDELETE_SUCCESS";
export const BANKDELETE_FAILURE =  "BANKDELETE_FAILURE";
export const BANKDELETE_PATH = "bank";


