import React from "react";
import Headers from "components/Headers/Header1";
import Filteration from "./Filteration";
import { Container, Card, Row } from "reactstrap";

const ViewCustomerDetail = (props) => {
  return (
    <>
      <Headers />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filteration
                List={props.List}
                GetApplicationListMiddleware={
                  props.GetApplicationListMiddleware
                }
                FormSubmitMiddleware={props.FormSubmitMiddleware}
                info={props.info}
                // UploadImageMiddleware={props.UploadImageMiddleware} image={props.image}
                getChalanDataMiddleware={props.getChalanDataMiddleware}
              ></Filteration>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default ViewCustomerDetail;
