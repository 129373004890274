import {
  VERIFY,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  BLOCKED,
  BLOCKED_SUCCESS,
  BLOCKED_FAILURE,
} from "../constant";

const INITIAL_STATE = {
  verify: null,
  blocked: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case VERIFY:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        verify: null,
        error: {},
      };
    case VERIFY_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        verify: action.payload,
      };
    case VERIFY_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case BLOCKED:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        blocked: null,
        error: {},
      };
    case BLOCKED_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        blocked: action.payload,
      };
    case BLOCKED_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
