import React, { useEffect, useRef, useState } from "react";
// import Loader from "react-loader-spinner";
// import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table, Label } from "reactstrap";
import swal from "sweetalert";
// import ComponentToPrint from "./ComponentToPrint";
import Information from "./Information";
import jsPDF from "jspdf";
import "jspdf-autotable";

const TableAdminReqListView = ({
  post,
  _FormCancelation,
  onChangeNoOfRows,
}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewReqList, setViewReqList] = useState(false);
  // const [isOk, setIsOk] = useState(false);
  // const [printReceiptInfo, setPrintReceiptInfo] = useState({});
  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  // const print = (receipt) => {
  //   setPrintReceiptInfo(receipt);
  //   setIsOk(true);
  // };
  // const printDocument = (receipt) => {
  //   let date = new Date().toISOString().split("T")[0];
  //   const doc = new jsPDF();
  //   doc.addImage(Logo, "png", 15, 5, 50, 20);
  //   doc.text("Payment Receipt", 88, 40);
  //   doc.setFontSize(10);
  //   doc.text("Date: " + date, 168, 18);
  //   doc.autoTable({
  //     theme: "grid",
  //     head: [["Customer Details", ""]],
  //     body: [
  //       ["Receipt No.", receipt.receiptNo],
  //       ["Name", receipt.name],
  //       ["Contact No.", receipt.whatsappNumber],
  //       ["Email", receipt.email],
  //       ["CNIC No.", receipt.cnic],
  //     ],
  //     margin: { top: 55 },
  //     styles: { lineColor: 10, cellWidth: 91 },
  //   });
  //   doc.autoTable({
  //     theme: "grid",
  //     head: [["Payment Details", ""]],
  //     body: [
  //       ["Payment Through", receipt.receipts?.[0].paymentThrough.title],
  //       [
  //         "Payment Ref #",
  //         receipt.receipts?.[0].PaymentReferenceNo
  //           ? receipt.receipts?.[0].PaymentReferenceNo
  //           : "-",
  //       ],
  //       ["Amount", receipt.receipts?.[0].amount],
  //       ["Remarks", receipt.receipts?.[0].remarks],
  //     ],
  //     margin: { top: 55 },
  //     styles: { lineColor: 10, cellWidth: 91 },
  //   });
  //   doc.setFontSize(10);
  //   doc.text("Singnature:________________", 145, 170);

  //   doc.save(`Payment receipt ${receipt.receiptNo}.pdf`);
  // };
  // const ToggleUpdateReceiptModal = () => {
  //   setIsOpen(!isOpen);
  // };
  const ToggleViewReqListModal = (post) => {
    setIsViewModalOpen(!isViewModalOpen);
    setViewReqList(post);
  };
  const onSuccessCancelation = () => {
    swal("Form Cancel successfully!", "", "success");
  };
  const onFailureCancelation = () => {};
  const cancelForm = (formNo) => {
    let body = {
      FormNo: formNo,
    };
    _FormCancelation(body, onSuccessCancelation, onFailureCancelation);
  };
  // useEffect(() => {
  //   if (isOk === true) {
  //     handlePrint();
  //     setIsOk(false);
  //   }
  // }, [isOk]);
  return (
    <div>
      <Information
        toggle={ToggleViewReqListModal}
        modal={isViewModalOpen}
        viewReqList={viewReqList}
      />

      {/* <ComponentToPrint
        ref={componentRef}
        // printReceiptInfo={printReceiptInfo}
      /> */}
      <Row className="mt-5">
        <Col xl="2" lg="4" md="4" sm="6" xs="6">
          <Label className="form-control-label"> Rows Per Pages </Label>
          <Input
            id="exampleFormControlSelect1"
            type="select"
            onChange={(e) => onChangeNoOfRows(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Input>
        </Col>
      </Row>
      <br />
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Applicant Name</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Type</th>
            <th scope="col">Date</th>
            <th scope="col">No Of Applications</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {post !== null && post !== undefined && post.length > 0 ? (
            post.map((request, index) => {
              return (
                <tr key={request.id}>
                  <td>{request?.id}</td>
                  <td>{request?.name}</td>
                  <td>{request?.user?.phone}</td>
                  <td>{request?.type}</td>
                  <td>{request?.createdAt?.split("T")[0]}</td>
                  <td>{request?.NumberOfApplication}</td>
                  <td>{request?.Amount}</td>
                  <td>
                    <span class="badge badge-danger">{request?.Status}</span>
                  </td>
                  <td>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      id="search"
                      size="sm"
                      onClick={() => ToggleViewReqListModal(request)}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">View</span>
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableAdminReqListView;
