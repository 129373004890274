import { connect } from "react-redux";

import StaffList from "./StaffList";
import { GetAgentListMiddleware,DeleteAgentMiddleware} from "../middleware";


const mapStateToProps = (state) => ({

  responseAgent:state.agentStaffList.responseAgent,
  del:state.agentStaffList.del,



 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    
    DeleteAgentMiddleware:(id,onSuccess,onFailure)=>
    dispatch(DeleteAgentMiddleware(id,onSuccess,onFailure)),
    GetAgentListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(GetAgentListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)),
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(StaffList);
