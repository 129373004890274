import axios from "axios";
import { StaffDetail,StaffEditDetail } from "../action/index";
import { STAFF_PATH,STAFFEDIT_PATH } from "../constant";
import { BASEURL } from "config/api/URL";
export const EditStaffMiddleware = (id,body, OnSuccess, OnFailure) => (dispatch) => {
 
 
 
   dispatch(StaffEditDetail.StaffEdit());
 console.log(body,"body")
   let token = localStorage.getItem("auth");
   axios
     .post(`${BASEURL}${STAFFEDIT_PATH}/${id}`, body, {
       headers: {
        "Content-Type": "multipart/form-data" ,
         Authorization: `bearer ${token}`,
        },
      })
     .then((res) => {
       
      // if (res.data.status === true) {
         dispatch(StaffEditDetail.StaffEdit_Success(res.data));
         dispatch(OnSuccess(res.data.message));
      // } 
       
     })
     .catch(function (error) {
       if (error.response) {
        console.log(error.response)
         OnFailure(error.response.data)
       } else if (error.request) {
       } else {
       }
     });
 };
 


export const InsertStaffMiddleware = (body, OnSuccess, OnFailure) => (dispatch) => {
 

  dispatch(StaffDetail.Staff());

  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${STAFF_PATH}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
        dispatch(StaffDetail.Staff_Success(res.data));
        dispatch(OnSuccess(res.data.message));

    })
    .catch(function (error) {
      if (error.response) {
        OnFailure(error.response.data)
      } else if (error.request) {
      } else {
      }
    });
};
