import axios from "axios";
import { FetchFeedbackList, Resolveissue } from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_FEEDBACK_LIST,
  FETCH_FEEDBACK_LIST_SUCCESS,
  FETCH_FEEDBACK_LIST_FAILURE,
  FETCH_FEEDBACK_LIST_PATH,
  RESOLVE,
  RESOLVE_SUCCESS,
  RESOLVE_FAILURE,
  RESOLVE_PATH,
} from "../constant";

export const AdminFeedbackList_Middleware =
  (pageNumber, noOfRows, status, onSuccess, onFailure) => (dispatch) => {
    console.log("STATUS", status);
    dispatch(FetchFeedbackList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_FEEDBACK_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}&status=${status}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // console.log("SUPPORT SYSTEM DATA", res.data.data);
          dispatch(FetchFeedbackList.FetchSuccess(res.data.data.items));
          // dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log("ERR", error.response);
          // onFailure(error.response.data);
        }
      });
  };
export const Resolve_Middleware = (id, onSuccess, onFailure) => (dispatch) => {
  dispatch(Resolveissue.Resolve());
  let token = localStorage.getItem("auth");
  fetch(`${BASEURL}${RESOLVE_PATH}/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);
      if (data.statusCode === 200) {
        dispatch(onSuccess(data.message));
      } else {
        onFailure(data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  // console.log("TOKEN", token);
  // axios
  //   .patch(`${BASEURL}${RESOLVE_PATH}/${id}`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `bearer ${token}`,
  //     },
  //   })
  //   .then((res) => {
  //     if (res.status === 200) {
  //       console.log("Resolve data", res.data.data);
  //       // dispatch(Resolveissue.ResolveSuccess(res.data.data.items));
  //       // dispatch(onSuccess(res.data.message));
  //     }
  //   })
  //   .catch(function (error) {
  //     if (error.response) {
  //       console.log("ERR", error.response);
  // onFailure(error.response.data);
  //     }
  //   });
};
