/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar/container/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routesUser from "routes/UserRoutes.js";
import routesStaff from "routes/StaffRoutes.js";
import routesAdmin from "routes/AdminRoutes.js";
import routesManager from "routes/ManagerRoutes.js";
import routesAgent from "routes/AgentRoutes.js";
import routesAgentStaff from "routes/AgentStaffRoutes.js"

const Admin = (props) => {
  const [role, setRole] = useState(null);
  const [routesToRedner, setRoutesToRedner] = useState(null);
  const mainContent = React.useRef(null);
  const location = useLocation();
  useEffect(() => {
    setRole(localStorage.getItem("roleid"));
  }, [true]);
  // useEffect(() => {
  //   setRoutesToRedner(routesUser);
  // }, []);
  useEffect(() => {
    if (role !== null && role !== undefined && role !== "") {
      if (role == 1) setRoutesToRedner(routesUser);
      else if (role == 2) setRoutesToRedner(routesStaff);
      else if (role == 3) setRoutesToRedner(routesAdmin);
      else if (role == 4) setRoutesToRedner(routesManager);
      else if (role == 5) setRoutesToRedner(routesAgent);
      else if (role == 6) setRoutesToRedner(routesAgentStaff);
    }
  }, [role]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    if (routes !== false) {
      return routes.map((prop, key) => {
        if (prop.layout === "/user") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else if (prop.subNav) {
          return prop.subNav.map((item, index) => {
            return (
              <Route
                path={item.layout + item.path}
                component={item.component}
                key={index}
              />
            );
          });
        }
      });
    }
  };

  // const getBrandText = (path) => {
  //   for (let i = 0; i < routes.length; i++) {
  //     if (
  //       props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
  //       -1
  //     ) {
  //       return routes[i].name;
  //     }
  //   }
  //   return "Dashboard";
  // };
  // const [routes, setroutes] = useState('initialState')

  return (
    <>
      <Sidebar
        {...props}
        // routes={routesUser}
        routes={
          role === 1
            ? routesUser
            : role == 2
            ? routesStaff
            : role == 3
            ? routesAdmin
            : role == 4
            ? routesManager
            : role == 5
            ? routesAgent
            :role ==6
            ?routesAgentStaff 
            :routesUser
        }
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/square-pro-1.png").default,
        }}
      />

      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          // brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(
            routesToRedner !== null &&
              routesToRedner !== undefined &&
              routesToRedner
          )}
          {/* <Redirect from="*" to="/auth/login" /> */}
        </Switch>
        <Container>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
