import {
  PLOT_TYPE,
  PLOT_TYPE_SUCCESS,
  PLOT_TYPE_FAILURE,
  BALLOTING,
  BALLOTING_SUCCESS,
  BALLOTING_FAILURE,
  WINNER_LIST,
  WINNER_LIST_SUCCESS,
  WINNER_LIST_FAILURE,
  MEMBERS_COUNT_RESIDENTIAL,
  MEMBERS_COUNT_RESIDENTIAL_SUCCESS,
  MEMBERS_COUNT_RESIDENTIAL_FAILURE,
} from "../constant";

export class MembersCount {
  static Fetch() {
    return {
      type: MEMBERS_COUNT_RESIDENTIAL,
    };
  }
  static Fetch_Success(response) {
    return {
      type: MEMBERS_COUNT_RESIDENTIAL_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: MEMBERS_COUNT_RESIDENTIAL_FAILURE,
      error,
    };
  }
}

export class PlotType {
  static Fetch() {
    return {
      type: PLOT_TYPE,
    };
  }
  static Fetch_Success(response) {
    return {
      type: PLOT_TYPE_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: PLOT_TYPE_FAILURE,
      error,
    };
  }
}

export class Balloting {
  static Balloting() {
    return {
      type: BALLOTING,
    };
  }
  static Balloting_Success(response) {
    return {
      type: BALLOTING_SUCCESS,
      payload: response,
    };
  }
  static Balloting_Failure(error) {
    return {
      type: BALLOTING_FAILURE,
      error,
    };
  }
}

export class WinnerList {
  static Fetch() {
    return {
      type: WINNER_LIST,
    };
  }
  static Fetch_Success(response) {
    return {
      type: WINNER_LIST_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: WINNER_LIST_FAILURE,
      error,
    };
  }
}
