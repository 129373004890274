export const FETCH_MANAGER_APPLICATION_LIST = "FETCH_MANAGER_APPLICATION_LIST";
export const FETCH_MANAGER_APPLICATION_LIST_SUCCESS =
  "FETCH_MANAGER_APPLICATION_LIST_SUCCESS";
export const FETCH_MANAGER_APPLICATION_LIST_FAILURE =
  "FETCH_MANAGER_APPLICATION_LIST_FAILURE";
export const FETCH_MANAGER_APPLICATION_LIST_PATH =
  "bank-application/list-manager";

export const POST_APPLICATION_LIST = "POST_APPLICATION_LIST";
export const POST_APPLICATION_LIST_SUCCESS = "POST_APPLICATION_LIST_SUCCESS";
export const POST_APPLICATION_LIST_FAILURE = "POST_APPLICATION_LIST_FAILURE";
export const POST_APPLICATION_LIST_PATH =
  "bank-application/form-submit-for-manager";

export const CHECK_BY_FORM_NO = "CHECK_BY_FORM_NO";
export const CHECK_BY_FORM_NO_SUCCESS = "CHECK_BY_FORM_NO_SUCCESS";
export const CHECK_BY_FORM_NO_FAILURE = "CHECK_BY_FORM_NO_FAILURE";
export const CHECK_BY_FORM_NO_PATH = "bank-application/check-form-no";
