import axios from "axios";
import { CreateManagerCustomer } from "../action";
import { BASEURL } from "config/api/URL";
import { CREATE_MANAGER_CUSTOMER_PATH } from "../constant";

export const _CreateManagerCustomer =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(CreateManagerCustomer.create());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CREATE_MANAGER_CUSTOMER_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch(CreateManagerCustomer.createSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data.message);
        }
      });
  };
