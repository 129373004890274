import {
  FETCH_ALL_RECEIPT,
  FETCH_ALL_RECEIPT_SUCCESS,
  FETCH_ALL_RECEIPT_FAILURE,
  CANCELATION,
  CANCELATION_SUCCESS,
  CANCELATION_FAILURE,
  STATUS,
  STATUS_SUCCESS,
  STATUS_FAILURE,
} from "../constant.js";

const INITIAL_STATE = {
  Reports: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
  AssignResponse: null,
  StatusResponse: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_ALL_RECEIPT:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        AllReceipts: null,
        error: {},
      };
    case FETCH_ALL_RECEIPT_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        AllReceipts: action.payload,
      };
    case FETCH_ALL_RECEIPT_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case CANCELATION:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Response: null,
        error: {},
      };
    case CANCELATION_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Response: action.payload,
      };
    case CANCELATION_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case STATUS:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        StatusResponse: null,
        error: {},
      };
    case STATUS_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        StatusResponse: action.payload,
      };
    case STATUS_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
