import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";
import "react-h5-audio-player/lib/styles.css";
const ViewReceiptModal = ({ toggle, modal }) => {
  // const dateFunction = (date) => {
  //   const nDate = new Date(date).toLocaleString("en-US", {
  //     timeZone: "Asia/Karachi",
  //   });
  //   return nDate;
  // };
  //  Counter is a state initialized to 0
  const [counter, setCounter] = useState(0);

  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    setCounter(counter + 1);
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    setCounter(counter - 1);
  };

  return (
    <Modal size="md" isOpen={modal} toggle={toggle}>
      {/* <ModalHeader toggle={toggle}>
        <h3>Details</h3>
      </ModalHeader> */}
      <ModalBody>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "200%",
            // position: "absolute",
            width: "100%",
            height: "100%",
            // top: "-15%",
          }}
        >
          Number Of Forms
          <div
            style={{
              fontSize: "120%",
              position: "relative",
              top: "2vh",
            }}
          >
            {counter}
          </div>
          <div className="buttons">
            <button
              style={{
                fontSize: "100%",
                position: "relative",
                top: "2vh",
                marginRight: "5px",
                backgroundColor: "#6d7075",
                borderRadius: "100%",
                color: "white",
                padding: "0px 18px",
                border: "none",
              }}
              onClick={handleClick2}
            >
              -
            </button>
            <button
              style={{
                fontSize: "100%",
                position: "relative",
                top: "2vh",
                marginLeft: "5px",
                backgroundColor: "#6d7075",
                borderRadius: "100%",
                color: "white",
                padding: "0px 15px",
                border: "none",
              }}
              onClick={handleClick1}
            >
              +
            </button>
          </div>
          <div  style={{
              fontSize: "120%",
              position: "relative",
              top: "5vh",
            }}>
            <p>You can get upto 30 forms from one account</p>
          </div>
          <div></div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <h1>Rs:6000</h1>
          </Col>
          <Col>
            <Button color="success" onClick={toggle}>
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default ViewReceiptModal;
