import axios from "axios";
import {
  BankRegistrationAction,
  UploadMultiFilesAction,
} from "../action/index";
import { BANK_REGISTRATION_PATH, UPLOAD_MULTI_FILE_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const UploadMultiFilesMiddleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(UploadMultiFilesAction.upload());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${UPLOAD_MULTI_FILE_PATH}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(UploadMultiFilesAction.upload_Success(res.data.data));
        dispatch(onSuccess(res.data));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

export const BankRegistrationMiddleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(BankRegistrationAction._Reg());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${BANK_REGISTRATION_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(BankRegistrationAction._Reg_Success(res.data.data));
        dispatch(onSuccess(res.data.message));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };
