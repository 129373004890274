import axios from "axios";
import { ForgetPass, VerifyForgetPassCode, ResetPassword } from "../action";
import {
  FORGET_PASSWORD_PATH,
  VERIFY_FORGET_PASSWORD_CODE_PATH,
  RESET_PASSWORD_PATH,
} from "../constant";
import { BASEURL } from "config/api/URL";

export const ForgotPassword = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(ForgetPass.ForgetPassword());
  axios
    .post(`${BASEURL}${FORGET_PASSWORD_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 201) {
        dispatch(ForgetPass.ForgetPasswordSuccess(res.data));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    });
};

export const VerifyForgotPass_Code =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(VerifyForgetPassCode.ForgetPasswordCode());
    axios
      .post(`${BASEURL}${VERIFY_FORGET_PASSWORD_CODE_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch(VerifyForgetPassCode.ForgetPasswordCodeSuccess(res.data));
          dispatch(onSuccess(res.data));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);

          onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

export const ResetForgotPassword =
  (body, token, onSuccess, onFailure) => (dispatch) => {
    dispatch(ResetPassword._ResetPassword());
    axios
      .patch(`${BASEURL}${RESET_PASSWORD_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(ResetPassword._ResetPasswordSuccess(res.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);

          onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };
