import {
  FETCH_FEEDBACK_LIST,
  FETCH_FEEDBACK_LIST_SUCCESS,
  FETCH_FEEDBACK_LIST_FAILURE,
  FETCH_FEEDBACK_LIST_PATH,
  SEND_QUERY,
  SEND_QUERY_SUCCESS,
  SEND_QUERY_FAILURE,
  SEND_QUERY_PATH,
} from "../constant.js";
export class FetchFeedbackList {
  static Fetch() {
    return {
      type: FETCH_FEEDBACK_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_FEEDBACK_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_FEEDBACK_LIST_FAILURE,
      error,
    };
  }
}
export class SendQuery {
  static Send() {
    return {
      type: SEND_QUERY,
    };
  }
  static SendSuccess(response) {
    return {
      type: SEND_QUERY_SUCCESS,
      payload: response,
    };
  }
  static SendFailure(error) {
    return {
      type: SEND_QUERY_FAILURE,
      error,
    };
  }
}
