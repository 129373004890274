import { connect } from "react-redux";

import Bank from "./Customer";
import { EditCustomerMiddleware} from "../middleware";



const mapStateToProps = (state) => ({


edit:state.customer.edit,


 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    EditCustomerMiddleware:(id,body,onSuccess,onFailure)=>
    dispatch(EditCustomerMiddleware(id, body, onSuccess, onFailure)),
   
     
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Bank);
