import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Input,
  Table,
} from "reactstrap";
import "react-h5-audio-player/lib/styles.css";
import {
  AppReqMiddleware,
  CheckForm_Middleware,
  PayForm_Fee_Middleware,
  PostAppList_Middleware,
} from "../middleware";
import { connect } from "react-redux";
import swal from "sweetalert";
import validate from "components/Utilities Component/ValidationWrapper";
import { VerifyByCNIC } from "views/UserViews/MembershipForm/middleware";
const FormSubmissionModal = ({
  toggle,
  modal,
  _showPlotType,
  _Type,
  _PostAppList_Middleware,
  _CheckForm_Middleware,
  CheckRes,
  _VerifyByCNIC,
  CNIC_Response,
}) => {
  let testPhoneNum = /^(\+92|92)[0-9]{10}$/;

  const [isTrue, setIsTrue] = useState(true);
  const [paymentRef, setPaymentRef] = useState("");
  const [newArr, setNewArr] = useState([]);
  const [formCount, setFormCount] = useState([]);

  const [body, setBody] = useState({
    phone: "",
    cnic: "",
    name: "",
    formNo: "",
    depositSlipNo: "",
    residentalId: "",
  });
  const [error, setError] = useState({
    cnicError: null,
    nameError: null,
    formNoError: null,
    phoneError: null,
    slipNoError: null,
    categoryError: null,
  });
  const onSuccess = (message) => {
    swal("Succesful!", "Form Submitted Succesfully", "success");
    setBody({
      phone: "",
      cnic: "",
      name: "",
      formNo: "",
      depositSlipNo: "",
      residentalId: "",
    });
    setError({
      cnicError: null,
      nameError: null,
      formNoError: null,
      phoneError: null,
      slipNoError: null,
      categoryError: null,
    });
    setPaymentRef("");
    toggle();
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
    setIsTrue(false);
  };
  const onPlotSuccess = () => {};
  const onPlotFailure = () => {};
  const onVerifySuccess = (data) => {
    setFormCount(data);
  };
  const onVerifyFailure = () => {};
  const onCheckSuccess = (data) => {
    setPaymentRef(data);
    setIsTrue(false);
  };
  const onCheckFailure = (error) => {
    swal("Sorry!", error.message, "error");
    setPaymentRef("");
    setIsTrue(true);
  };
  const CheckFields = (name) => {
    if (name === "cnic") {
      if (body.cnic?.length == 13) {
        _VerifyByCNIC(body?.cnic, onVerifySuccess, onVerifyFailure);
      }
      setError({
        ...error,
        cnicError: validate("CNIC", body.cnic),
      });
    } else if (name === "name") {
      setError({
        ...error,
        nameError: validate("required", body.name),
      });
    } else if (name === "mobile") {
      setError({
        ...error,
        phoneError: validate("phNumber", body.phone),
      });
    } else if (name === "formNo") {
      let body1 = {
        formNo: body.formNo,
      };
      _CheckForm_Middleware(body1, onCheckSuccess, onCheckFailure);
      setError({
        ...error,
        formNoError: validate("required", body.formNo),
      });
    } else if (name === "slipNo") {
      if (body.depositSlipNo?.length < 4) {
        setError({
          ...error,
          slipNoError: "Not valid, minimum 4 and maxiumum 11 digits required ",
        });
      } else {
        setError({
          ...error,
          slipNoError: "",
        });
      }
    } else if (name === "category") {
      setError({
        ...error,
        categoryError: validate("required", body.residentalId),
      });
    }
  };
  const handleSendData = () => {
    setError({
      ...error,
      nameError: validate("required", body.name),
      cnicError: validate("CNIC", body.cnic),
      categoryError: validate("required", body.residentalId),
      phoneError: validate("phNumber", body.phone),
      slipNoError:
        body.depositSlipNo?.length >= 4
          ? ""
          : "Not valid, minimum 4 and maxiumum 11 digits required ",
      formNoError: validate("required", body.formNo),
    });
    chk();
    if (
      body.name !== "" &&
      body.cnic?.length == 13 &&
      // body.depositSlipNo?.length >= 4 &&
      testPhoneNum.test(body.phone) &&
      body.residentalId !== "" &&
      body.formNo !== ""
    ) {
      setIsTrue(true);
      let body1 = {
        phone: `+${body.phone}`,
        cnic: body.cnic,
        name: body.name,
        formNo: body.formNo,
        // depositSlipNo: body.depositSlipNo,
        residentalId: body.residentalId,
      };
      _PostAppList_Middleware(body1, onSuccess, onFailure);
    }
  };

  const chk = () => {
    if (!testPhoneNum.test(body.phone)) {
      setError({ ...error, phoneError: "Enter number in valid format" });
    }
  };

  const close = () => {
    setBody({
      phone: "",
      cnic: "",
      name: "",
      formNo: "",
      depositSlipNo: "",
      residentalId: "",
    });
    setError({
      cnicError: null,
      nameError: null,
      formNoError: null,
      phoneError: null,
      slipNoError: null,
      categoryError: null,
    });
    setPaymentRef("");
    toggle();
    setIsTrue(true);
    setFormCount([]);
  };

  useEffect(() => {
    let tempArr = [];
    formCount.map((data, index) => {
      if (data.quantity < 5) {
        tempArr.push(data);
      }
    });
    setNewArr(tempArr);
  }, [formCount]);

  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d6992a" }}>
        <h2 style={{ color: "white", textAlign: "center" }}>Form Submission</h2>
      </ModalHeader>
      <ModalBody>
        <div>
          <Row className=" mt-3">
            <Col lg="3" md="3" sm="3" xs="3">
              <label className="form-control-label" for="input-username">
                Form No:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Form No."
                value={body.formNo}
                autoComplete="off"
                onBlur={() => CheckFields("formNo")}
                onChange={(e) => setBody({ ...body, formNo: e.target.value })}
              />
              {error.formNoError !== "" && error.formNoError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.formNoError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="3" md="3" sm="3" xs="3">
              <label className="form-control-label" for="input-username">
                Payment Ref:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                value={paymentRef}
                disabled={true}
              />
            </Col>
            <Col lg="3" md="3" sm="3" xs="3">
              <label className="form-control-label" for="input-username">
                Name:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Enter Full Name"
                value={body.name}
                onBlur={() => CheckFields("name")}
                onChange={(e) => setBody({ ...body, name: e.target.value })}
                onKeyPress={(event) => {
                  if (!/^[A-Za-z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                autoComplete="off"
              />
              {error.nameError !== "" && error.nameError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.nameError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="3" md="3" sm="3" xs="3">
              <label className="form-control-label" for="input-username">
                CNIC:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Enter CNIC Number"
                value={body.cnic}
                maxLength={13}
                autoComplete="off"
                onBlur={() => CheckFields("cnic")}
                onChange={(e) => setBody({ ...body, cnic: e.target.value })}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {error.cnicError !== "" && error.cnicError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.cnicError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
          </Row>
          <Row className=" mt-3">
            <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Phone No:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="92xxxxxxxxxx"
                value={body.phone}
                maxLength={12}
                autoComplete="off"
                onBlur={() => CheckFields("mobile")}
                onChange={(e) => setBody({ ...body, phone: e.target.value })}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {error.phoneError !== "" && error.phoneError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.phoneError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            {/* <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Bank Deposit Slip No:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Enter Slip Number"
                value={body.depositSlipNo}
                autoComplete="off"
                maxLength={11}
                onBlur={() => CheckFields("slipNo")}
                onChange={(e) =>
                  setBody({ ...body, depositSlipNo: e.target.value })
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {error.slipNoError !== "" && error.slipNoError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.slipNoError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col> */}
            <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Plot Category:
              </label>
              <select
                className="form-control"
                onBlur={() => CheckFields("category")}
                onChange={(e) =>
                  setBody({
                    ...body,
                    residentalId: JSON.parse(e.target.value).id,
                    price: JSON.parse(e.target.value).price,
                  })
                }
                disabled={newArr?.length == 0}
              >
                <option value="">Select Category</option>
                {newArr?.map((data) => {
                  return (
                    <option
                      key={data.id}
                      value={JSON.stringify({ id: data.id, price: data.price })}
                      // value={data.id}
                    >
                      {data.title}
                    </option>
                  );
                })}
              </select>
              {error.categoryError !== "" && error.categoryError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.categoryError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
          </Row>
          <Row>
            {/* <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Plot Category:
              </label>
              <select
                className="form-control"
                onBlur={() => CheckFields("category")}
                onChange={(e) =>
                  setBody({
                    ...body,
                    residentalId: JSON.parse(e.target.value).id,
                    price: JSON.parse(e.target.value).price,
                  })
                }
                disabled={newArr?.length == 0}
              >
                <option value="">Select Category</option>
                {newArr?.map((data) => {
                  return (
                    <option
                      key={data.id}
                      value={JSON.stringify({ id: data.id, price: data.price })}
                      // value={data.id}
                    >
                      {data.title}
                    </option>
                  );
                })}
              </select>
              {error.categoryError !== "" && error.categoryError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.categoryError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="6" md="6" sm="6">
              <label className="form-control-label" for="input-username">
                Price
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder=""
                disabled
                value={body.price}
              ></input>
            </Col> */}
          </Row>
          <br />
          {formCount?.length > 0 ? (
            <Row>
              <Col lg="6" md="6" sm="6">
                <table
                  className="align-items-center"
                  style={{ border: "1px solid lightgrey", width: "350px" }}
                  responsive
                >
                  <thead
                    style={{
                      backgroundColor: "#d69929",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    <tr>
                      <th scope="col">Category</th>
                      {/* <th scope="col">Price</th> */}
                      <th scope="col">Purchased</th>
                      <th scope="col">Remaining</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formCount !== null &&
                    formCount !== undefined &&
                    formCount.length > 0
                      ? formCount.map((request, index) => {
                          return (
                            <tr key={request?.id}>
                              <td>{request?.title}</td>
                              {/* <td>{request?.price}</td> */}
                              <td>{request?.quantity}</td>
                              <td>{request?.remaining}</td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={handleSendData}
              disabled={isTrue}
            >
              Submit
            </Button>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={close}
            >
              Close
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  CheckRes: state.staffAppList.CheckRes,
  CNIC_Response: state.CustomerMembershipForm.CNIC_Response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _PostAppList_Middleware: (body, onSuccess, onFailure) =>
      dispatch(PostAppList_Middleware(body, onSuccess, onFailure)),
    _CheckForm_Middleware: (body, onSuccess, onFailure) =>
      dispatch(CheckForm_Middleware(body, onSuccess, onFailure)),
    _VerifyByCNIC: (body, onSuccess, onFailure) =>
      dispatch(VerifyByCNIC(body, onSuccess, onFailure)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToPrpos
)(FormSubmissionModal);
