


export const AGENTLIST =  "AGENTLIST";
export const AGENTLIST_SUCCESS =  "AGENTLIST_SUCCESS";
export const AGENTLIST_FAILURE =  "AGENTLIST_FAILURE";
export const AGENTLIST_PATH = "real-estate/agentStaff-list/for-agent";

export const AGENTDELETE =  "AGENTDELETE";
export const AGENTDELETE_SUCCESS =  "AGENTDELETE_SUCCESS";
export const AGENTDELETE_FAILURE =  "AGENTDELETE_FAILURE";
export const AGENTDELETE_PATH = "real-estate/delete-estate-staff";




