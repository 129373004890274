import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// const ENDPOINT = "http://localhost:5000";
import socketIOClient from "socket.io-client";
import { networkPoll } from "./Networkpolling";

export const Socket = () => {
  console.log("NETWOKR POLL", networkPoll);
  // const ENDPOINT =
  //   "https://f197-2400-adc1-1d0-cc00-4915-3c29-2b40-f3c1.ngrok.io/realtime";
  const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
  // const ENDPOINT = "http://43.204.38.222:3000/realtime";
  const token = localStorage.getItem("auth");
  const socket = socketIOClient(
    `${ENDPOINT}?token=Bearer ${token}`,
    networkPoll
  );
  // console.log(`${ENDPOINT}?token=Bearer ${token}`);
  console.log("SOCKETDATA", socket);
};

export const GetSocketRequestDetails = (id, history) => {
  // console.log("ID", id);
  // const ENDPOINT =
  //   "https://f197-2400-adc1-1d0-cc00-4915-3c29-2b40-f3c1.ngrok.io/realtime";
  // // console.log("OrderID", id);
  const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
  // // const ENDPOINT = "http://65.0.184.178:3000";
  const token = localStorage.getItem("auth");

  const socket = socketIOClient(
    `${ENDPOINT}?token=Bearer ${token}`,
    networkPoll
  );
  // console.log("SOCKETDATA", socket);
  socket.on(`application-request-paid-${id}`, (data) => {
    console.log("DATAFROMSOCKET", data);
    if (data) {
      history.push("/user/ApplicationList");
      history.push("/user/requestUserView");
      window.fbq("track", "FormPaid");
      // history.push({
      //   pathname: "/user/ApplicationList",
      //   data,
      // });
      // history.push({
      //   pathname: "/user/requestUserView",
      //   data,
      // });
    }
  });
  return null;
};
export const GetSocketPaymentDetails = (id, history) => {
  const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
  const token = localStorage.getItem("auth");

  const socket = socketIOClient(
    `${ENDPOINT}?token=Bearer ${token}`,
    networkPoll
  );
  socket.on(`application-paid-${id}`, (data) => {
    console.log("DATAFROMSOCKET", data);
    if (data) {
      history.push("/user/requestUserView");
      history.push("/user/ApplicationList");

      // history.push({
      //   pathname: "/user/ApplicationList",
      //   data,
      // });
      // history.push({
      //   pathname: "/user/requestUserView",
      //   data,
      // });
    }
  });
  return null;
};
