import {
  FETCH_FEEDBACK_LIST,
  FETCH_FEEDBACK_LIST_SUCCESS,
  FETCH_FEEDBACK_LIST_FAILURE,
  FETCH_FEEDBACK_LIST_PATH,
  RESOLVE,
  RESOLVE_SUCCESS,
  RESOLVE_FAILURE,
  RESOLVE_PATH,
  REPLY,
  REPLY_SUCCESS,
  REPLY_FAILURE,
  REPLY_PATH,
} from "../constant.js";
export class FetchFeedbackList {
  static Fetch() {
    return {
      type: FETCH_FEEDBACK_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_FEEDBACK_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_FEEDBACK_LIST_FAILURE,
      error,
    };
  }
}
export class Resolveissue {
  static Resolve() {
    return {
      type: RESOLVE,
    };
  }
  static ResolveSuccess(response) {
    return {
      type: RESOLVE_SUCCESS,
      payload: response,
    };
  }
  static ResolveFailure(error) {
    return {
      type: RESOLVE_FAILURE,
      error,
    };
  }
}
export class Replyissue {
  static Reply() {
    return {
      type: REPLY,
    };
  }
  static ReplySuccess(response) {
    return {
      type: REPLY_SUCCESS,
      payload: response,
    };
  }
  static ReplyFailure(error) {
    return {
      type: REPLY_FAILURE,
      error,
    };
  }
}
