import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import TableRequestUserView from "../components/TableRequestUserView";
import { connect } from "react-redux";
import {
  FetchAllRequest_Middleware,
  FetchCount_Middleware,
  FetchPrice_Middleware,
} from "../middleware";
import Pagination from "components/Pagination/Pagination";
import RequestUserViewModal from "../components/RequestUserViewModal";

const RequestUserView = ({
  _FormCancelation,
  _FetchAllRequest_Middleware,
  AllRequests,
  _FetchCount_Middleware,
  Count,
  _FetchPrice_Middleware,
  Price,
}) => {
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  ///////////----------Pagination--------------//////////////
  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};

  const ToggleViewReceiptModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };
  //////////-------useEffects--------//////////////

  useEffect(() => {
    if (AllRequests?.items) {
      setPosts(AllRequests?.items);
    }
  }, [AllRequests?.items]);

  useEffect(() => {
    _FetchAllRequest_Middleware(pageNumber, noOfRows, onSuccess, onFailure);
  }, [pageNumber, noOfRows]);

  useEffect(() => {
    _FetchCount_Middleware(onSuccess, onFailure);
  }, [AllRequests?.items]);

  useEffect(() => {
    _FetchPrice_Middleware(onSuccess, onFailure);
  }, []);

  return (
    <>
      <Headers />
      <RequestUserViewModal
        toggle={ToggleViewReceiptModal}
        modal={isViewModalOpen}
        count={Count}
        pricing={Price}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="5" md="5" sm="5">
                    <h2 className="mb-0">Request List</h2>
                  </Col>
                  <Col lg="2" md="2" sm="2">
                    {/* <h2 className="mb-0">Request List</h2> */}
                  </Col>
                  <Col lg="5" md="5" sm="5">
                    <Button
                      style={{
                        backgroundColor: "#015652",
                        color: "white",
                        float: "right",
                        justifyContent: "flex-end",
                        alignSelf: "flex-end",
                      }}
                      size="md"
                      id="search"
                      onClick={() => {
                        window.fbq("track", "FormRequest");
                        ToggleViewReceiptModal();
                      }}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Form Request</span>
                    </Button>
                  </Col>
                </Row>
                <hr style={{ marginTop: 10 }} />
              </CardHeader>
              <CardBody>
                {/* <Row>
                  <Col lg="2" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search
                    </label>
                    <input
                      type="text"
                      // onKeyPress={(event) => {
                      //   if (!/[0-9-+]/.test(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      maxLength={13}
                      value={filter.filt}
                      onChange={(e) => setFilter(e.target.value)}
                    ></input>
                    <br />
                  </Col>
                </Row> */}
                <TableRequestUserView
                  _FormCancelation={_FormCancelation}
                  onChangeNoOfRows={onChangeNoOfRows}
                  post={paginatedPosts}
                />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  AllRequests: state.customerRequest.AllRequests,
  Count: state.customerRequest.Count,
  Price: state.customerRequest.Price,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _FetchCount_Middleware: (onSuccess, onFailure) =>
      dispatch(FetchCount_Middleware(onSuccess, onFailure)),
    _FetchPrice_Middleware: (onSuccess, onFailure) =>
      dispatch(FetchPrice_Middleware(onSuccess, onFailure)),
    _FetchAllRequest_Middleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        FetchAllRequest_Middleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(RequestUserView);
