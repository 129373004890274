import axios from "axios";
import { UserSignUp } from "../action";
import { SIGNUP_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const SignUpMiddleware = (body, OnSuccess, OnFailure) => (dispatch) => {
  dispatch(UserSignUp.SignUp());
  // let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${SIGNUP_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        //Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      // console.log(res.data);
      if (res.data.status === true) {
        dispatch(UserSignUp.SignUpSuccess(res.data));
        dispatch(OnSuccess(res.data.message));
      }
      // else {
      //   dispatch(UserSignUp.SignUpFailure(res.data.message));
      //   dispatch(OnFailure(res.data.message));
      // }
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        // console.log(error.response.data);
        OnFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};
