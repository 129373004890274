import axios from "axios";

import { AgentListDetail,
  AgentVerifyDetail
 } from "../action/index";

import { AGENTLIST_PATH, 
  AGENTVERIFY_PATH
 } from "../constant";

import { BASEURL } from "config/api/URL";

export const AgentVerifyMiddleware =
  (id, onSuccess, onFailure) => (dispatch) => {
  
    dispatch(AgentVerifyDetail.AgentVerify());
    let token = localStorage.getItem("auth");
    console.log(token,"token")
    axios
      .patch(
        
        `${BASEURL}${AGENTVERIFY_PATH}/${id}`, {},
        { headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          }
        )
      .then((res) => {
   
     
        dispatch(AgentVerifyDetail.AgentVerify_Success(res.data.data));
        dispatch(onSuccess(res.message));
        
      })
      .catch(function (error) {
    
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {} 
        else {}
      });
   
  };



export const GetAgentListMiddleware =
  (pageNumber, noOfRows,valueAndKey,onSuccess, onFailure) => (dispatch) => {
    
   
    dispatch(AgentListDetail.Agentlist());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${AGENTLIST_PATH}?page=${pageNumber}&limit=${noOfRows}&${valueAndKey?.key}=${valueAndKey?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
     
     
        dispatch(AgentListDetail.Agentlist_Success(res.data.data.items));
        dispatch(onSuccess(res.message));
        
      })
      .catch(function (error) {
       
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {} 
        else {}
      });
   
  };


