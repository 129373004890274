import axios from "axios";
import { DealerRegAction } from "../action/index";
import { DEALER_REG_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const DealerRegMiddleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    console.log(body);
    dispatch(DealerRegAction.Registration());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${DEALER_REG_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        window.fbq("track", "dealerRegistered");
        dispatch(DealerRegAction.Registration_Success(res.data.data));
        dispatch(onSuccess(res.data.message));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };
