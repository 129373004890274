import React from "react";
import Headers from "components/Headers/Header1";
import Filteration from "./Filteration";
import { Container, Card, Row } from "reactstrap";

const StaffList = (props) => {
  console.log(props.verify,"verify")
  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filteration
              GetAgentListMiddleware={props.GetAgentListMiddleware}
              responseAgent={props.responseAgent}
              DeleteAgentStaffMiddleware={props.DeleteAgentStaffMiddleware}
                del={props.del}
              AgentStaffVerifyMiddleware={props.AgentStaffVerifyMiddleware}
              verify={props.verify}
              ></Filteration>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default StaffList;
