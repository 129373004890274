import {
  IMPORT_FILE,
  IMPORT_FILE_SUCCESS,
  IMPORT_FILE_FAILURE,
} from "../constant";

export class ImportFileAction {
  static Upload() {
    return {
      type: IMPORT_FILE,
    };
  }
  static Upload_Success(response) {
    return {
      type: IMPORT_FILE_SUCCESS,
      payload: response,
    };
  }
  static Upload_Failure(error) {
    return {
      type: IMPORT_FILE_FAILURE,
      error,
    };
  }
}
