import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";
import { AppReqMiddleware, FetchUser_Middleware } from "../middleware";
import { connect } from "react-redux";
import swal from "sweetalert";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import PhoneInput from "react-phone-input-2";
const RequestAgentViewModal = ({
  AppReqMiddleware,
  FetchUser_Middleware,
  toggle,
  modal,
  pricing,
  User,
}) => {
  //let numCount = parseInt(count?.total_forms);
  const [tempWhatsapp, settempWhatsapp] = useState("")
  const history = useHistory();
  const [values, setValues] = useState({ counter: 1, price: pricing });
  const [state, setState] = useState("");
  const [user, setUser] = useState({  name: "",
  cnic: "",
  email: "",
  phone: "",
  whatsAppNo: "",
});
  const [disabledFields, setDisabledFields] = useState(true);
  const [disabledButton, setdisabledButton] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState({
    CNICError: "",
    emailError: null,
    nameError: "",
    phoneError: null,
    whatsAppNoError: null,
  });

  const handleClick1 = () => {
    setValues({
      counter: values.counter + 1,
      price: values.price + pricing,
    });
  };

  const handleClick2 = () => {
    setValues({
      counter: values.counter - 1,
      price: values.price - pricing,
    });
  };
  const onChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };
  const onCheck = (name) => {
    if (name === "cnic") {
      setError({
        ...error,
        CNICError: validate("CNIC", user.cnic),
      });
      if (user?.cnic?.length == 13) {
        getUser();
      }
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", user.email),
      });
    } else if (name === "name") {
      setError({
        ...error,
        nameError: validate("fullName", user.name),
      });
    } else if (name === "phone") {
      setError({
        ...error,
        phoneError: validate("phoneNumber", user.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("phoneNumber", user.whatsAppNo),
      });
    }
  };

  const confirm = () => {
    setError({
      ...error,

      phoneError: validate("phoneNumber", user.phone),
      emailError: validate("email", user.email),
      CNICError: validate("CNIC", user.cnic),
      nameError: validate("fullName", user.name),
      whatsAppNoError: validate("phoneNumber", user.whatsAppNo),
    });
   // checkNumber();
 
    if (
      user.name !== "" &&
      user.cnic !== "" &&
      user?.cnic?.trim().length == 13 &&

      user?.whatsAppNo?.trim().length===(user?.whatsAppNo?.includes("+") ? 13 :  12) &&
      user?.phone?.trim().length===(user?.phone?.includes("+") ? 13 :  12)&&
  
      error.emailError == null 
   
    ) {
      let body = {
        fullName: user.name,
        cnic: user.cnic,
        email: user.email,
        phone: user?.phone?.includes("+") ? user?.phone : `+${user?.phone}`,
        whatsAppNo: user?.whatsAppNo?.includes("+")
          ? user?.whatsAppNo
          : `+${user?.whatsAppNo}`,
        profilePhoto: "abc.jpg",
        noOfApplication: values.counter,
      };
      setdisabledButton(true);
      AppReqMiddleware(body, onSuccess, onFailure);
    }

  };
  const onSuccess = (e) => {
    setUser({
      ...user,
      name: "",
      cnic: "",
      email: "",
      phone: "92",
      whatsAppNo: "92",
      forms: 30,
    });
    setState({ ...state, cnic: "" });
    setdisabledButton(false);
    swal({
      title: "Congratulations!",
      text: "Application Request",
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push({
          pathname: "/user/PaymentConfirmation",
          state: e,
        });
      } else {
        history.push({
          pathname: "/user/PaymentConfirmation",
          state: e,
        });
      }
    });
  };
  const onFailure = (error) => {
    setdisabledButton(false);
    swal("Sorry!", error.message, "error");
  };

  useEffect(() => {
    setValues({ ...values, price: pricing });
  }, [pricing]);

  useEffect(() => {
    setUser({
      ...user,
      name: "",
      cnic: "",
      email: "",
      phone: "",
      whatsAppNo: "",
      forms: 30,
    });
    setState({ ...state, cnic: "" });
    setDisabledFields(true);
  }, []);

  const getUser = () => {
    setUser({
      ...user,
      name: "",
      //cnic:"",
      email: "",
      phone: "",
      whatsAppNo: "",
      forms: 30,
    });
    setValues({ ...values, counter: 1, price: pricing });
    FetchUser_Middleware(user.cnic, onSuccessUser, onFailureUser);
  };
  const onSuccessUser = (e) => {
    console.log(e, "onsuccessuser");
    if (e?.userData !== null && e?.userData !== undefined) {
      swal({
        title: "Found!",
        text: "User  Found!",
        icon: "success",
      });
      settempWhatsapp(e?.userData?.whatsAppNo)
      setUser({
        ...user,
        name: e?.userData?.fullName,
        cnic: e?.userData?.cnic,
        phone: e?.userData?.phone,
        email: e?.userData?.email,
        whatsAppNo: e?.userData?.whatsAppNo!==null?e?.userData?.whatsAppNo:"92",
        forms: 30 - e?.applicationData[0]?.sum,
      });
      setError({...error, 
       CNICError: "",
      emailError: null,
      nameError: "",
      phoneError: null,
      whatsAppNoError: null})
      // setValidationPhoneNo("");
      // setValidationWhatsAppNo("");
     
      setDisabledFields(true);
    } else {
      // swal({
      //   title: "Not Found!",
      //   text: "User Not Found!",
      //   icon: "warning",
      // });

      setDisabledFields(false);
    
      setValues({ ...values, counter: 1, price: pricing });
      setUser({
        ...user,
        name: "",
        //cnic: "",
        phone: "92",
        email: "",
        forms: 30,
        whatsAppNo: "92",
      });
      setError({...error, 
        CNICError: "",
       emailError: null,
       nameError: "",
       phoneError: null,
       whatsAppNoError: null})
      //  setValidationPhoneNo("");
      //  setValidationWhatsAppNo("");
    }
  };
  const onFailureUser = () => {};
  const handleChecked = () => {
    setChecked(!checked);
  };
  useEffect(() => {
    // if(location.state!==undefined && location.state!==null)
    // {
    //   setState({...state, whatsAppNo: checked == true ? state.phone : location.state.whatsAppNo });
    // }
    // else{
      setUser({...user, whatsAppNo: checked == true ? user.phone : tempWhatsapp });
   // }
   
  }, [checked]);
console.log(user.phone,"user.phone")
console.log(user.whatsAppNo,"user.whatsapp")
  
  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d69929" }}>
        <h2 style={{ color: "white", textAlign: "center" }}>Form Request</h2>
      </ModalHeader>
      <ModalBody>
        <Row>
       
          <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
              CNIC
            </label>
            <input
              type="text"
              id="input-username"
              className="form-control"
              placeholder=""
              value={user.cnic}
              autoComplete="off"
              maxLength={13}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              // disabled={disabledFields}
              onBlur={(e) => onCheck("cnic")}
              onChange={(e) => onChange("cnic", e.target.value)}
            ></input>
            {error.CNICError !== "" && error.CNICError !== null && (
              <small style={{ marginTop: "2px" }}>
                <span style={{ color: "red" }}>
                  {error.CNICError}{" "}
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              </small>
            )}
          </Col>
          <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
              Name
            </label>
            <input
              type="text"
              id="input-username"
              className="form-control"
              placeholder=""
              value={user.name}
              disabled={disabledFields}
              onBlur={(e) => onCheck("name")}
              onChange={(e) => onChange("name", e.target.value)}
              autoComplete="off"
              onKeyPress={(event) => {
                if (!/[a-z, A-Z]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            ></input>
            {error.nameError !== "" && error.nameError !== null && (
              <small style={{ marginTop: "2px" }}>
                <span style={{ color: "red" }}>
                  {error.nameError}{" "}
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              </small>
            )}
          </Col>
          <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
              Email
            </label>
            <input
              type="text"
              id="input-username"
              className="form-control"
              placeholder=""
              value={user.email}
              disabled={disabledFields}
              autoComplete="off"
              onBlur={(e) => onCheck("email")}
              onChange={(e) => onChange("email", e.target.value)}
            ></input>
            {error.emailError !== "" && error.emailError !== null && (
              <small style={{ marginTop: "2px" }}>
                <span style={{ color: "red" }}>
                  {error.emailError}{" "}
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              </small>
            )}
          </Col>
          <Col lg="4" md="4" sm="6">
                <label className="form-control-label" for="input-username">
                  Phone No
                </label>
                <PhoneInput
                   placeholder="+92"
                     disabled={disabledFields}
                  className="input-group-alternative"
                  onChange={(e) => onChange("phone", e)}
                  onBlur={() => onCheck("phone")}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                    height:"100%"
                    
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="100%"
                  
                  //  containerStyle={{border:"none"}}
                  countryCodeEditable={false}
                  country={"pk"}
                  value={user.phone?user.phone:""}
                />

                {error.phoneError !== "" && error.phoneError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.phoneError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                  Whatsapp No
                </label>
                <PhoneInput
                  placeholder="+92"
                  className="input-group-alternative"
                  onChange={(e) => onChange("whatsAppNo", e)}
                  onBlur={() => onCheck("whatsAppNo")}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                    height:"100%"
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="true"
                  countryCodeEditable={false}
                  country={"pk"}
                  value={user.whatsAppNo?user.whatsAppNo:""}
                  disabled={checked}
                />

                {error.whatsAppNoError !== "" &&
                  error.whatsAppNoError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.whatsAppNoError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                   { user.phone?.length >=12 ? (
                    <div style={{ position: "relative",
                      float: "right"}}>
                      <input
                        type="checkbox"
                        onClick={handleChecked}
                        // disabled={isUserExist}
                      />
                      <small><b> Same As Phone No</b></small>
                      
                    </div>
                  ) : (
                    ""
                  )}
              </Col>
          {/* <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
              Phone
            </label>
            <input
              type="text"
              id="input-username"
              className="form-control"
              placeholder="92----------"
              value={user.phone}
              autoComplete="off"
              onKeyPress={(event) => {
                if (!/[0-9 +]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={user?.phone?.includes("+") ? 13 : 12}
              onBlur={(e) => onCheck("phone")}
              disabled={disabledFields}
              onChange={(e) => onChange("phone", e.target.value)}
            ></input>
            {error.phoneError !== "" && error.phoneError !== null ? (
              <small style={{ marginTop: "2px" }}>
                <span style={{ color: "red" }}>
                  {error.phoneError}{" "}
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              </small>
            ) : validationPhoneNo !== "" ? (
              <small>
                <span style={{ color: "red" }}>
                  {validationPhoneNo}{" "}
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              </small>
            ) : (
              ""
            )}
          </Col>
          <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
              whatsAppNo
            </label>
            <input
              type="text"
              id="input-username"
              className="form-control"
              placeholder="92----------"
              value={user.whatsAppNo}
              autoComplete="off"
              maxLength={user?.whatsAppNo?.includes("+") ? 13 : 12}
              onKeyPress={(event) => {
                if (!/[0-9 +]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onBlur={(e) => onCheck("whatsAppNo")}
              //disabled={disabledFields}
              onChange={(e) => onChange("whatsAppNo", e.target.value)}
            ></input>
            {error.whatsAppNoError !== "" && error.whatsAppNoError !== null ? (
              <small style={{ marginTop: "2px" }}>
                <span style={{ color: "red" }}>
                  {error.whatsAppNoError}{" "}
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              </small>
            ) : validationWhatsAppNo !== "" ? (
              <small>
                <span style={{ color: "red" }}>
                  {validationWhatsAppNo}{" "}
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              </small>
            ) : (
              ""
            )}
            <br />
          </Col> */}
          <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
              Available Forms
            </label>
            <input
              type="text"
              id="input-username"
              className="form-control"
              disabled
              value={user.forms}
              autoComplete="off"
            ></input>
            <br />
          </Col>
        </Row>

        <div
          style={{
            // border: "1px solid #C0C0C0",
            width: "100%",
            fontSize: "200%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "100%",
              border: "1px solid grey",
              position: "relative",
              width: "50%",
              height: "50%",
              top: "80%",
              marginLeft: "25%",
              borderRadius: "15px",
            }}
          >
            <div
              style={{
                border: "1px solid #C0C0C0",
                fontSize: "100%",
                marginTop: "3%",
                borderRadius: "10px",
                //  position: "relative",
                width: "50px",
                textAlign: "center",
                top: "20%",
              }}
            >
              {values.counter}
            </div>

            <div className="buttons">
              <button
                style={{
                  fontSize: "70%",
                  position: "relative",
                  top: "0vh",
                  marginRight: "5px",
                  backgroundColor: "#6d7075",
                  borderRadius: "100%",
                  color: "white",
                  padding: "0px 15px",
                  border: "none",
                }}
                onClick={handleClick2}
                disabled={values.counter === 1}
              >
                -
              </button>

              <button
                style={{
                  fontSize: "70%",
                  position: "relative",
                  top: "0vh",
                  marginLeft: "15px",
                  backgroundColor: "#6d7075",
                  borderRadius: "100%",
                  color: "white",
                  padding: "0px 11px",
                  border: "none",
                }}
                onClick={handleClick1}
                disabled={values.counter === user.forms || user.forms == 0}
                //values.counter === 30 - numCount || numCount === 30 ||
              >
                +
              </button>
            </div>
            <div
              style={{
                fontSize: "120%",
                position: "relative",
                top: "5vh",
              }}
            >
              <p>You can get upto 30 forms from one CNIC</p>
            </div>
          </div>

          <div></div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <h1 style={{ color: "#6d7075" }}>Rs:{values.price}</h1>
          </Col>
          <Col>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={confirm}
              disabled={disabledButton}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  AllReceipts: state.agentRequest.AllReceipts,
  StatusResponse: state.viewPaymentReceipt.StatusResponse,
  Response: state.viewPaymentReceipt.Response,
  User: state.agentRequest.User,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    AppReqMiddleware: (body, onSuccess, onFailure) =>
      dispatch(AppReqMiddleware(body, onSuccess, onFailure)),
    FetchUser_Middleware: (body, onSuccess, onFailure) =>
      dispatch(FetchUser_Middleware(body, onSuccess, onFailure)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToPrpos
)(RequestAgentViewModal);
