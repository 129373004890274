import {
  FETCH_STAFF,
  FETCH_STAFF_SUCCESS,
  FETCH_STAFF_FAILURE,
  DELETE_STAFF,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAILURE,
  EDIT_STAFF,
  EDIT_STAFF_SUCCESS,
  EDIT_STAFF_FAILURE,
} from "../constant.js";

const INITIAL_STATE = {
  Reports: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
  Staff_List: null,
  BranchesById: [],
  Roles: [],
  StaffRes: null,
  AdminRes: null,
  DeleteRes: null,
  EditRes: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_STAFF:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Staff_List: null,
        error: {},
      };
    case FETCH_STAFF_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Staff_List: action.payload,
      };
    case FETCH_STAFF_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case DELETE_STAFF:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        DeleteRes: null,
        error: {},
      };
    case DELETE_STAFF_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        DeleteRes: action.payload,
      };
    case DELETE_STAFF_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case EDIT_STAFF:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        EditRes: null,
        error: {},
      };
    case EDIT_STAFF_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        EditRes: action.payload,
      };
    case EDIT_STAFF_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
