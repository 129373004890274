import React, { useEffect, useState } from "react";
import { Row, Container, Card, CardHeader, CardBody, Col } from "reactstrap";
import Headers from "components/Headers/Header1";
import TableRealstateDealer from "../components/TableRealstateDealer";
import { connect } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import { RealstateDealer_Middleware } from "../middleware";
import { ExportCSV } from "views/AdminView/FormExport/components/ExportToCSV";

const RealStateDealer = ({
  _RealstateDealer_Middleware,
  getRealstateDealers,
}) => {
  console.log(getRealstateDealers);
  const [excelData, setExcelData] = useState(null);

  // const [noOfRows, setnoOfRows] = useState(10);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [posts, setPosts] = useState([]);
  ///////////----------Pagination--------------//////////////
  // let postNumber = 10;
  // let paginatedPosts, total_pages;

  // if (noOfRows != "") {
  //   postNumber = noOfRows;
  // }
  // const start = pageNumber * postNumber - postNumber;
  // const end = start + postNumber;

  // if (posts) {
  //   paginatedPosts = posts;
  //   // paginatedPosts = posts?.slice(start, end);
  //   total_pages = Math.ceil(posts.length / postNumber);
  // }

  ///////////----------Functions--------------//////////////

  // const onChangeNoOfRows = (val) => {
  //   setnoOfRows(parseInt(val));
  //   setPageNumber(1);
  // };
  // const handlePrev = () => {
  //   if (pageNumber === 1) return;
  //   setPageNumber(pageNumber - 1);
  // };

  // const handleNext = () => {
  //   if (pageNumber) {
  //     setPageNumber(pageNumber + 1);
  //   } else {
  //     return;
  //   }
  // };
  const onSuccess = () => {};
  const onFailure = () => {};

  // const ToggleViewReceiptModal = () => {
  //   setIsViewModalOpen(!isViewModalOpen);
  // };
  //////////-------useEffects--------//////////////

  useEffect(() => {
    _RealstateDealer_Middleware(onSuccess, onFailure);
  }, []);
  useEffect(() => {
    if (getRealstateDealers && getRealstateDealers?.length > 0) {
      let tempArr = [];
      getRealstateDealers?.map((item, index) => {
        tempArr.push({
          ["Sno"]: index + 1,
          ["Name"]: item?.name,
          ["Agency Name"]: item?.agencyName,
          // ["CNIC"]: item?.cnic,
          // ["Email"]: item?.email,
          ["Phone No."]: item?.phone,
          ["WhatsApp No."]: item?.whatsAppNo,
          ["Address One"]: item?.addressOne,
          // ["Address Two"]: item?.addressTwo,
          ["Country"]: item?.country?.title,
          ["City"]: item?.city?.title,
          // ["No. Of Staff"]: item?.noOfStaff,
        });
      });
      setExcelData(tempArr);
    }
  }, [getRealstateDealers]);
  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Real State Dealer List</h2>
                  </Col>
                </Row>
                <hr />
              </CardHeader>
              <CardBody>
                <ExportCSV csvData={excelData} fileName="dealersList" />
                <br />
                <br />
                <TableRealstateDealer
                  getRealstateDealers={getRealstateDealers}
                  // onChangeNoOfRows={onChangeNoOfRows}
                />
                {/* <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                /> */}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  getRealstateDealers: state.RealStateDealers.getRealstateDealers,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _RealstateDealer_Middleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        RealstateDealer_Middleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(RealStateDealer);
