import {
  GET_PAYMENT_THROUGH,
  GET_PAYMENT_THROUGH_SUCCESS,
  GET_PAYMENT_THROUGH_FAILURE,
  CREATE_PAYMENT_RECEIPT,
  CREATE_PAYMENT_RECEIPT_SUCCESS,
  CREATE_PAYMENT_RECEIPT_FAILURE,
} from "../constant";

const INITIAL_STATE = {
  Data: null,
  isLoading: false,
  isError: false,
  error: {},
  PaymentThroughData: null,
  Response: null,
};
export default (body = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return body;
    case GET_PAYMENT_THROUGH:
      return {
        ...body,
        isLoading: true,
        isError: false,
        PaymentThroughData: null,
      };
    case GET_PAYMENT_THROUGH_SUCCESS:
      return {
        ...body,
        isLoading: false,
        PaymentThroughData: action.payload,
      };
    case GET_PAYMENT_THROUGH_FAILURE:
      return {
        ...body,
        isError: true,
        error: action.error,
      };

    case CREATE_PAYMENT_RECEIPT:
      return {
        ...body,
        isLoading: true,
        isError: false,
        Response: null,
      };
    case CREATE_PAYMENT_RECEIPT_SUCCESS:
      return {
        ...body,
        isLoading: false,
        Response: action.payload,
      };
    case CREATE_PAYMENT_RECEIPT_FAILURE:
      return {
        ...body,
        isError: true,
        error: action.error,
      };
  }
};
