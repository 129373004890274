import axios from "axios";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CardBody,
  Card,
  Table,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import { useState, useEffect } from "react";

import "react-h5-audio-player/lib/styles.css";
const Information = (props) => {
  const [state, setState] = useState({});
  useEffect(() => {
    if (props.detail !== null && props.detail !== undefined) {
      //console.log(props.detail,"props.state")
      console.log("CLIENT DATA AT NEW PAGE", props.detail);
      setState({
        ...state,
        name: props?.detail?.name,
        fatherName: props?.detail?.fatherName,
        email: props?.detail?.email,
        phone: props?.detail?.mobile,
        cnic: props?.detail?.cnic,
        passport: props?.detail?.passport,
        dob: props?.detail?.dob,
        address: props?.detail?.address,
        age: props?.detail?.age,
        profession: props?.detail?.profession,
        organization: props?.detail?.organization,
        residental: props?.detail?.residental?.title,
        price: props?.detail?.residental?.price,
      });

      let id = props.detail?.id;
      // console.log(id);
      props.CustomerInfoMiddleware(id, onSuccess, onFailure);
    } else {
      setState("");
    }
  }, [props.detail]);

  // useEffect(() => {
  //   console.log("CLIENT DATA AT NEW PAGE", props.detail);
  // }, []);
  // useEffect(() => {
  //   if (props.info !== null && props.info !== undefined) {
  //     // console.log(props.info, "info");
  //     setState({
  //       ...state,
  //       name: props?.info?.name,
  //       fatherName: props?.info?.fatherName,
  //       email: props?.info?.email,
  //       phone: props?.info?.phone,
  //       cnic: props?.info?.cnic,
  //       passport: props?.info?.passport,
  //       dob: props?.info?.dob,
  //       address: props?.info?.address,
  //       age: props?.info?.age,
  //       profession: props?.info?.profession,
  //       organization: props?.info?.organization,
  //       residental: props?.info?.residental?.title,
  //       price: props?.info?.residental?.price,
  //     });
  //   } else {
  //     setState("");
  //   }
  // }, [props.info]);

  const onSuccess = () => {};
  const onFailure = () => {};
  // console.log(state.dob, "state");

  const dateFunction = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Karachi",
    });
    return nDate;
  };

  return (
    <Modal size="lg" isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader style={{ backgroundColor: "#d69929" }} toggle={props.toggle}>
        <h3 style={{ color: "white" }}>
          Details Of application No: {props?.detail?.id}
        </h3>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col lg="6" md="6" sm="2">
            <h2>Applicant Information</h2>
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Name:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>{state?.name}</label>{" "}
          </Col>
          <Col lg="2" md="2" sm="6">
            <label>Father/Spouse:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>{state?.fatherName}</label>{" "}
          </Col>
        </Row>

        <Row>
          <Col lg="2" md="2" sm="6">
            <label>CNIC:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>{state?.cnic}</label>{" "}
          </Col>
          <Col lg="2" md="2" sm="6">
            <label>Email:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>{state?.email}</label>{" "}
          </Col>
        </Row>

        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Phone:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>{state?.phone}</label>{" "}
          </Col>
          <Col lg="2" md="2" sm="6">
            <label>Date of birth:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>
              {state?.dob}
              {/* {state.dob !== undefined
                ? dateFunction(state?.dob).toLocaleString("en-US", {
                    timeZone: "Asia/Karachi",
                  })
                : ""} */}
            </label>{" "}
          </Col>
        </Row>

        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Passport No:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{state?.passport}</label>{" "}
          </Col>
          <Col lg="2" md="2" sm="6">
            <label>Age:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>{state?.age}</label>{" "}
          </Col>
        </Row>

        <Row>
          <Col lg="2" md="2" sm="6">
            <label>profession:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>{state?.profession}</label>{" "}
          </Col>

          <Col lg="2" md="2" sm="6">
            <label>Organization:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="6">
            {" "}
            <label>{state?.organization}</label>{" "}
          </Col>

          <Col lg="2" md="2" sm="2">
            <label>Address:</label>{" "}
          </Col>
          <Col lg="6" md="6" sm="6">
            {" "}
            <label>{state?.address}</label>{" "}
          </Col>
        </Row>
        {/* <Row>
          <Col lg="2" md="2" sm="2">
            <label>Plot Size:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="4">
            {" "}
            <label>{state?.residental}</label>{" "}
          </Col>
          <Col lg="2" md="2" sm="2">
            <label>Price:</label>{" "}
          </Col>
          <Col lg="4" md="4" sm="4">
            {" "}
            <label>{state?.price}</label>{" "}
          </Col>
        </Row> */}
      </ModalBody>
      <ModalFooter style={{ backgroundColor: "#d69929" }}>
        <Button
          style={{ backgroundColor: "#015652", color: "white" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Information;
