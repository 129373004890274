import React, { useState, useRef, useEffect } from "react";
import Logo from "assets/img/whiteee1.png";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import Loading from "components/Loader/Loading";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from "react-redux";
import { JwtDashboard } from "../../../../store/helpers/JwtTimer/middleware";
import {
  CustomerLogin,
  QueryList_Middleware,
  Sendquerydetail,
} from "../middleware";
import { IoChatbubblesSharp } from "react-icons/io5";
import PublicSupportModal from "../components/PublicSupprotModal";
const Login = (props) => {
  const [loading, SetLoading] = useState(false);
  const [loading1, SetLoading1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, settype] = useState(false);
  const [data, setData] = useState([]);
  const handleClick = () => {
    // settype({...type,inputType:"password"? "text":"password"})
    settype(!type);
  };
  //hooks import
  let history = useHistory();
  const notify = useRef(null);
  const [body, setBody] = useState({
    cnic: "",
    password: "",
    // fcm: "",
  });

  //option for notify alert
  // let options = {
  //   place: "tr",
  //   message: (
  //     <div>
  //       <div>
  //         Welcome to <b>Now UI Dashboard React</b> - a beautiful freebie for
  //         every web developer.
  //       </div>
  //     </div>
  //   ),
  //   type: "danger",
  //   icon: "now-ui-icons ui-1_bell-55",
  //   autoDismiss: 7,
  // };
  //method import
  const ValidateLogin = () => {
    if (body.cnic !== "" && body.password !== "" && body.cnic.length == 13) {
      SetLoading(true);
      props._CustomerLogin(body, OnSuccess, OnFailure);
    } else if (body.cnic.length < 13) {
      SetLoading(false);
      swal("Sorry!", "CNIC is not valid, 13 digits required", "error");
    } else {
      SetLoading(false);
      swal("Sorry!", "Please Enter CNIC and Password", "error");
    }
  };
  // useEffect(() => {
  //   if (
  //     body.email !== undefined &&
  //     body.email !== null &&
  //     body.email !== "" &&
  //     body.password !== undefined &&
  //     body.password !== null &&
  //     body.password !== "" &&
  //     body.fcm !== undefined &&
  //     body.fcm !== null &&
  //     body.fcm !== ""
  //   ) {
  //   }
  // }, [body]);

  // const Notify = () => {
  //   swal("Sorry!", "Incorrect Email or Password", "error");
  // };

  const OnSuccess = () => {
    SetLoading(false);
    swal({
      title: "Congratulations!",
      text: "You have Logged in successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        setSignal(true);
      }
    });
  };
  const OnFailure = (error) => {
    if (error.message === "Please verify your account") {
      SetLoading(false);
      swal({
        title: "Sorry!",
        text: error.message,
      }).then(function (isConfirm) {
        if (isConfirm) {
          history.push("/auth/verifyLoginUser");
        } else {
        }
      });
    } else {
      SetLoading(false);
      swal("Sorry!", error.message, "error");
    }
  };
  useEffect(() => {
    if (props.Data !== null && props.Data !== undefined) {
      let values = {
        token: props.Data.data.token,
        user: JSON.stringify(props.Data.data.user),
        roleid: props.Data.data.user.roleId,
      };
      localStorage.setItem("auth", values.token);
      console.log("SETINNGLOCALUSER", values.user);
      localStorage.setItem("user", values.user);
      localStorage.setItem("roleid", values.roleid);
      if (values.token === "") {
        let path = "/auth/login";
        history.push(path);
      } else {
        // let path = "/user/requestUserView";
        // history.push(path);
      }
    }
  }, [props.Data]);
  useEffect(() => {
    props.QueryList_Middleware(onSuccess, onFailure);
  }, []);
  useEffect(() => {
    if (props.QueryIssue !== null && props.QueryIssue !== undefined) {
      setData(props.QueryIssue);
      console.log("ISSUE DATA AT COMPOMENT", props.QueryIssue);
    }
  }, [props.QueryIssue]);
  const onSuccess = () => {};
  const onFailure = (error) => {};

  const [signal, setSignal] = useState(false);
  useEffect(() => {
    if (signal === true) {
      let path = "/user/requestUserView";
      history.push(path);
    }
  }, [signal]);
  // useEffect(() => {
  //   if (
  //     localStorage.getItem("token") !== undefined &&
  //     localStorage.getItem("token") !== null
  //   ) {
  //     let path = "admin/index";
  //     history.push(path);
  //   }
  // }, [true]);

  const onHandleChange = (value, name) => {
    setBody({
      ...body,
      [name]: value,
    });
  };
  const [error, setError] = useState({
    cnicError: "",
    passwordError: "",
  });
  const CheckFields = (name) => {
    if (name === "cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", body.cnic),
      });
    } else if (name === "password") {
      setError({
        ...error,
        passwordError: validate("password", body.password),
      });
    }
  };
  // const onKeyPressed = (e) => {
  //   if (e.key === "Enter") {
  //     // ValidateLogin();
  //   }
  // };

  // localStorage.setItem("auth", props.Data);

  useEffect(() => {
    props.JwtDashboard(OnSuccessJwt, OnFailureJwt);
  }, [true]);

  const OnSuccessJwt = () => {};
  const OnFailureJwt = () => {};

  useEffect(() => {
    if (props.GetUserJWTLogin === false) {
      localStorage.removeItem("auth");
      window.location.href = "/auth/login";
    }
  });
  const showChange = () => {
    setShowModal(!showModal);
    console.log("clicked");
  };
  return (
    <>
      <NotificationAlert ref={notify} />
      <Col lg="5" md="7">
        <Card className="customLogin">
          <PublicSupportModal
            data={data}
            middleware={props}
            toggle={showChange}
            modal={showModal}
          />
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img height="150px" width="150px" src={Logo} /> <br />
              {/* <small>Sign In</small> */}
              <h2 style={{ color: "white" }}>
                <b>Sign In</b>
              </h2>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i class="fas fa-id-card"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="CNIC"
                    type="text"
                    maxLength={13}
                    onBlur={() => CheckFields("cnic")}
                    value={body.cnic}
                    onChange={(e) => onHandleChange(e.target.value, "cnic")}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
                {error.cnicError !== "" && error.cnicError !== null && (
                  <small>
                    <span style={{ color: "red" }}>
                      {error.cnicError}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup
                  style={{ backgroundColor: "white" }}
                  className="input-group-alternative"
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={type ? "text" : "password"}
                    onBlur={() => CheckFields("password")}
                    value={body.password}
                    onChange={(e) => onHandleChange(e.target.value, "password")}
                  />
                  <span
                    className="password__show"
                    onClick={handleClick}
                    style={{
                      paddingTop: "10px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    {type == false ? (
                      <i class="fa fa-eye"></i>
                    ) : (
                      <i class="fas fa-eye-slash"></i>
                    )}
                  </span>
                </InputGroup>
                {error.passwordError !== "" && error.passwordError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.passwordError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  disabled={loading}
                  onClick={ValidateLogin}
                >
                  {loading ? <Loading attr="Sign in" /> : "Sign in"}
                </Button>
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  onClick={() => {
                    history.push("/auth/signup");
                  }}
                >
                  Sign Up
                  {/* <a href="/auth/signup" style={{ color: "white" }}>
                    Sign Up
                  </a> */}
                </Button>
              </div>
              <div className="text-center"></div>
            </Form>
            <Row className=" text-center">
              <Col xs="12">
                <a
                  className="text-light"
                  onClick={() => {
                    history.push("/auth/forgetPassword");
                  }}
                  // href="/auth/forgetPassword"
                >
                  <b style={{ color: "white", cursor: "pointer" }}>
                    <u>Forgot password?</u>
                  </b>
                </a>
              </Col>
              {/* <Col xs="12">
                <a className="text-light" href="/auth/signup">
                  <small style={{ color: "black" }}>
                    Don't have an account ? Create account.
                  </small>
                </a>
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Col>
      <div
        style={{
          position: "absolute",
          cursor: "pointer",
          right: 20,
          bottom: 20,
        }}
      >
        <IoChatbubblesSharp onClick={showChange} size={60} color="#D79A29" />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.login.isLoading,
  isError: state.login.isError,
  Data: state.login.Data,
  QueryIssue: state.login.QueryIssue,
  Error: state.login.error,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    JwtDashboard: (OnSuccess, OnFailure) =>
      dispatch(JwtDashboard(OnSuccess, OnFailure)),
    _CustomerLogin: (body, OnSuccess, OnFailure) =>
      dispatch(CustomerLogin(body, OnSuccess, OnFailure)),
    QueryList_Middleware: (OnSuccess, OnFailure) =>
      dispatch(QueryList_Middleware(OnSuccess, OnFailure)),
    Sendquerydetail: (body, onSuccess, onFailure) =>
      dispatch(Sendquerydetail(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Login);
