import axios from "axios";
import { CreateStaffCustomer, VerifyUser_ByCNIC } from "../action";
import { BASEURL } from "config/api/URL";
import {
  CREATE_STAFF_CUSTOMER_PATH,
  VERIFY_USER_BY_CNIC_PATH,
} from "../constant";

export const _CreateStaffCustomer =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(CreateStaffCustomer.create());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CREATE_STAFF_CUSTOMER_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch(CreateStaffCustomer.createSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          onFailure(error.response.data.message);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        }
      });
  };

export const VerifyUserByCNIC = (cnic, OnSuccess, OnFailure) => (dispatch) => {
  dispatch(VerifyUser_ByCNIC.verify());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${VERIFY_USER_BY_CNIC_PATH}/${cnic}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(VerifyUser_ByCNIC.verify_Success(res.data.data));
        dispatch(OnSuccess(res.data.data));
      }
    })
    .catch(function (error) {
      if (error.response) {
        OnFailure(error.response.data);
      }
    });
};
