import axios from "axios";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CardBody,
  Card,
  Table,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
const ImageModal = ({ url, modal, toggle }) => {
  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader style={{ backgroundColor: "#d69929" }} toggle={toggle}>
        {/* <h3 style={{ color: "white" }}>Details Of query</h3> */}
      </ModalHeader>

      <ModalBody>
        <Row>
          <img src={url} width="100%" height="100%" />
        </Row>
      </ModalBody>
      {/* <ModalFooter style={{ backgroundColor: "#d69929" }}>
        <Button
          style={{ backgroundColor: "#015652", color: "white" }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default ImageModal;
