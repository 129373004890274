import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Input,
} from "reactstrap";
import "react-h5-audio-player/lib/styles.css";
import { AppReqMiddleware } from "../middleware";
import { connect } from "react-redux";
import swal from "sweetalert";
const PayNowModal = ({ toggle, modal, user }) => {
  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d6992a" }}>
        <h1 style={{ color: "white", textAlign: "center" }}>
          Order Details: 111132
        </h1>
      </ModalHeader>
      <ModalBody>
        <div>
          <Row className=" mt-3">
            <Col lg="6" md="6" sm="6" xs="12">
              <label className="form-control-label" for="input-username">
                Amount :
              </label>
              {/* {user?.user?.applicationRequest?.[0]?.Amount} */}
            </Col>
            <Col lg="6" md="6" sm="6" xs="12">
              <label className="form-control-label" for="input-username">
                Status :
              </label>
              {/* {user?.user?.applicationRequest?.[0]?.Status} */}
              <br />
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="6" xs="12">
              <label className="form-control-label" for="input-username">
                Order Type :
              </label>
              {/* {user?.user?.applicationRequest?.[0]?.orderType} */}
            </Col>
            <Col lg="6" md="6" sm="6" xs="12">
              <label className="form-control-label" for="input-username">
                Order Date : 
              </label>
              {/* {user?.user?.applicationRequest?.[0]?.createdAt.split("T")[0]} */}
              <br />
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={toggle}
            >
              Close
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default PayNowModal;
