export const FETCH_FEEDBACK_LIST = "FETCH_ADMIN_REQ_LIST";
export const FETCH_FEEDBACK_LIST_SUCCESS = "FETCH_ADMIN_REQ_LIST_SUCCESS";
export const FETCH_FEEDBACK_LIST_FAILURE = "FETCH_ADMIN_REQ_LIST_FAILURE";
export const FETCH_FEEDBACK_LIST_PATH = "public-support/Get-public-requests";

export const RESOLVE = "RESOLVE";
export const RESOLVE_SUCCESS = "RESOLVE_SUCCESS";
export const RESOLVE_FAILURE = "RESOLVE_FAILURE";
export const RESOLVE_PATH = "public-support/resolve";

export const REPLY = "REPLY";
export const REPLY_SUCCESS = "REPLY_SUCCESS";
export const REPLY_FAILURE = "REPLY_FAILURE";
export const REPLY_PATH = "support-system/reply";
