import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Card,
  Form,
  FormGroup,
  CardHeader,
  Button,
  CardBody,
  Col,
  Label,
  Input,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import { connect } from "react-redux";
import { Insertquerydetail } from "../middleware";
import swal from "sweetalert";

const CreateQuery = (props) => {
  const [text, setText] = useState("");
  const onSuccess = () => {
    swal({
      title: "Success!",
      text: "Issue created Successfully.",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        setText("");
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const handleSubmit = () => {
    let body = {
      name: text,
    };
    props.Insertquerydetail(body, onSuccess, onFailure);
  };
  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Create issue</h2>
                  </Col>
                </Row>
                <hr style={{ marginTop: 10 }} />
              </CardHeader>
              <CardBody>
                <Row style={{ marginTop: -40 }}>
                  <Col xl="8" lg="8" md="8" sm="8" xs="8">
                    <Form>
                      <FormGroup>
                        <Label className="form-control-label">
                          Create a issue *
                        </Label>
                        <Input
                          value={text}
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                          // rows={6}
                          placeholder="Write your issue here"
                          // style={{ resize: "none" }}
                          type="text"
                          name="text"
                          id="exampleText"
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col lg="5" md="5" sm="5">
                    <Button
                      style={{
                        backgroundColor: "#015652",
                        color: "white",
                        // float: "right",
                        justifyContent: "flex-end",
                        alignSelf: "flex-end",
                      }}
                      size="md"
                      id="search"
                      onClick={() => {
                        if (text?.trim().length > 0) {
                          handleSubmit();
                        } else {
                          swal("Sorry!", "All * fields are required", "error");
                        }
                      }}
                    >
                      <span className="btn-inner--icon">Create issue</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  Response: state.createIssue.Response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    Insertquerydetail: (body, OnSuccess, OnFailure) =>
      dispatch(Insertquerydetail(body, OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(CreateQuery);
