import { connect } from "react-redux";

import SignUp from "./SignUp.js";
import { SignUpMiddleware, city_Middleware,country_Middleware } from "../middleware";
const mapStateToProps = (state) => ({
  // isLoading: state.signUp.isLoading,
  // isError: state.signUp.isError,
   cityData: state.agentSignUp.cityData,
  countryData: state.agentSignUp.countryData,
  // Error: state.signUp.error,
});
const mapDispatchToProps = (dispatch) => {
  return {
    SignUpMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(SignUpMiddleware(body, OnSuccess, OnFailure)),
      city_Middleware: (countryId,OnSuccess, OnFailure) =>
      dispatch(city_Middleware(countryId, OnSuccess, OnFailure)),
      country_Middleware: ( OnSuccess, OnFailure) =>
      dispatch(country_Middleware( OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
