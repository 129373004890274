import axios from "axios";
import { CustomerListDetail,CustomerDeleteDetail, CustomerFileDetail,CustomerDetail } from "../action/index";
import { CUSTOMERLIST_PATH ,CUSTOMERDELETE_PATH, CUSTOMERFILE_PATH,CUSTOMER_PATH} from "../constant";
import { BASEURL } from "config/api/URL";

export const GetCustomerFileMiddleware =

  ( onSuccess, onFailure) => (dispatch) => {


    dispatch(CustomerFileDetail.CustomerFile());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${CUSTOMERFILE_PATH}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
     
      //  if (res.status === 200) {
          dispatch(CustomerFileDetail.CustomerFile_Success(res.data));
          dispatch(onSuccess(res.message));
       // }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          // onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };



export const DeleteCustomerMiddleware =
  (id, onSuccess, onFailure) => (dispatch) => {
   
    dispatch(CustomerDeleteDetail.CustomerDelete());
    let token = localStorage.getItem("auth");
    axios
      .delete(
        `${BASEURL}${CUSTOMERDELETE_PATH}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(CustomerDeleteDetail.CustomerDelete_Success(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
           onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };





export const GetCustomerListMiddleware =

  (pageNumber, noOfRows, valueAndKey, onSuccess, onFailure) => (dispatch) => {//&createdBy=${null}
    
    dispatch(CustomerListDetail.CustomerList());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${CUSTOMERLIST_PATH}?page=${pageNumber}&limit=${noOfRows}&${valueAndKey.key}=${valueAndKey.value}`,
        {
          headers: {
            "Content-Type": "application/json",
             Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        
      //  if (res.status === 200) {
          dispatch(CustomerListDetail.CustomerList_Success(res.data.data.items));
          dispatch(onSuccess(res.message));
       // }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          // onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };
  export const InsertCustomerMiddleware = (body, OnSuccess, OnFailure) => (dispatch) => {
 

    dispatch(CustomerDetail.Customer());
  
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CUSTOMER_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        //if (res.data.status === true) {
          dispatch(CustomerDetail.Customer_Success(res.data));
          dispatch(OnSuccess(res.data.message));
     //   } 
        // else {
        //   dispatch(UserSignUp.SignUpFailure(res.data.message));
        //   dispatch(OnFailure(res.data.message));
        // }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          // console.log(error.response.data);
          OnFailure(error.response.data)
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
  };
  
