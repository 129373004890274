import {
  APPLICATIONLIST,
  APPLICATIONLIST_SUCCESS,
  APPLICATIONLIST_FAILURE,
  FORMSUBMIT,
  FORMSUBMIT_FAILURE,
  FORMSUBMIT_SUCCESS,
  CHALAN_DATA,
  CHALAN_DATA_SUCCESS,
  CHALAN_DATA_FAILURE,
} from "../constant.js";

export class getChalanDataAction {
  static Fetch() {
    return {
      type: CHALAN_DATA,
    };
  }
  static Fetch_Success(response) {
    return {
      type: CHALAN_DATA_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: CHALAN_DATA_FAILURE,
      error,
    };
  }
}
export class FormSubmitDetail {
  static FormSubmit() {
    return {
      type: FORMSUBMIT,
    };
  }
  static FormSubmit_Success(response) {
    return {
      type: FORMSUBMIT_SUCCESS,
      payload: response,
    };
  }
  static FormSubmit_Failure(error) {
    return {
      type: FORMSUBMIT_FAILURE,
      error,
    };
  }
}

export class ApplicationListDetail {
  static Applicationlist() {
    return {
      type: APPLICATIONLIST,
    };
  }
  static Applicationlist_Success(response) {
    return {
      type: APPLICATIONLIST_SUCCESS,
      payload: response,
    };
  }
  static Applicationlist_Failure(error) {
    return {
      type: APPLICATIONLIST_FAILURE,
      error,
    };
  }
}
