import {
  FETCH_STAFF_APPLICATION_LIST,
  FETCH_STAFF_APPLICATION_LIST_SUCCESS,
  FETCH_STAFF_APPLICATION_LIST_FAILURE,
  POST_APPLICATION_LIST,
  POST_APPLICATION_LIST_SUCCESS,
  POST_APPLICATION_LIST_FAILURE,
  CHECK_BY_FORM_NO,
  CHECK_BY_FORM_NO_SUCCESS,
  CHECK_BY_FORM_NO_FAILURE,
} from "../constant.js";

const INITIAL_STATE = {
  Reports: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
  Data: [],
  PostRes: null,
  CheckRes: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_STAFF_APPLICATION_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Data: [],
        error: {},
      };
    case FETCH_STAFF_APPLICATION_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Data: action.payload,
      };
    case FETCH_STAFF_APPLICATION_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case POST_APPLICATION_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        PostRes: null,
        error: {},
      };
    case POST_APPLICATION_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        PostRes: action.payload,
      };
    case POST_APPLICATION_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case CHECK_BY_FORM_NO:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        CheckRes: null,
        error: {},
      };
    case CHECK_BY_FORM_NO_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        CheckRes: action.payload,
      };
    case CHECK_BY_FORM_NO_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
