import CreateCustomer from "views/ManagerView/CreateCustomer/container";
import ManagerRequestView from "views/ManagerView/ManagerRequest/container";
import ManagerFormList from "views/ManagerView/ManagerFormList/container";
import StaffList from "views/ManagerView/StaffList/container";
import AddNewStaff from "views/ManagerView/StaffList/components/AddNewStaff";
import SendQuery from "../components/Support/Sendquery/container";
import QueryList from "../components/Support/Querieslist/container";
var routes = [
  {
    path: "/createCustomer",
    name: "Generate Form",
    icon: "fas fa-angle-right",
    component: CreateCustomer,
    layout: "/user",
    isView: true,
  },

  {
    path: "/requestManagerView",
    name: "Form Requests",
    icon: "fas fa-angle-right",
    component: ManagerRequestView,
    layout: "/user",
    isView: true,
  },
  {
    path: "/managerFormList",
    name: "Form Submit",
    icon: "fas fa-angle-right",
    component: ManagerFormList,
    layout: "/user",
    isView: true,
  },
  {
    path: "/staffList",
    name: "View Staff List",
    icon: "fas fa-angle-right",
    component: StaffList,
    layout: "/user",
    isView: true,
  },
  {
    path: "/AddNewStaff",
    name: "Add New Staff",
    icon: "fas fa-angle-right",
    component: AddNewStaff,
    layout: "/user",
    isView: false,
  },
  {
    name: "Support",
    icon: "fab fa-wpforms ",
    isView: true,
    subNav: [
      {
        path: "/sendQuery",
        name: "Send query",
        icon: "fas fa-angle-right",
        component: SendQuery,
        layout: "/user",
        isView: true,
      },
      {
        path: "/getQueries",
        name: "View Queries List",
        icon: "fas fa-angle-right",
        component: QueryList,
        layout: "/user",
        isView: true,
      },
    ],
  },
];
export default routes;
