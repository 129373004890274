import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/img/square-pro-1.png";
import { connect } from "react-redux";
import addNotification from "react-push-notification";
import {
  CheckNotification,
  UpdateNotificationStatus,
  GetNotificationListMiddleware,
  ReadNotifcationMiddleware,
} from "../middleware";
import axios from "axios";
import moment from "moment";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  Badge,
  // InputGroupAddon,
  // InputGroupText,
  // Input,
  // InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import ChangePassword from "components/ChangePassword/changepassword";
import ChangeProfile from "components/ChangeProfilePicture/changeprofile";
import avatar from "../../../../assets/img/logo.png";
import ReadNotificationModal from "./ReadNotificationModal";
// import axios from "axios";
import { BASEURL } from "../../../../config/api/URL";
import { networkPoll } from "sockets/Networkpolling";
import socketIOClient from "socket.io-client";
import useSound from "use-sound";
import bellAudio from "../../../../assets/mp3/chime.mp3";

const apiPath = "notification/mark-read";
const logoPath = "../../../../../public/logo192.png";
// import swal from 'sweetalert';

const AdminNavbar = (props) => {
  const listInnerRef = useRef();
  const [play] = useSound(bellAudio);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [visible, setVisible] = useState(false);
  const [notifyData, setNotifyData] = useState("");
  const [data, setData] = useState();
  const [truee, setTrue] = useState({});
  const [noOfNotify, setNoOfNitfy] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [readcount, setReadCount] = useState(0);
  const buttonClick = () => {
    addNotification({
      title: "Warning",
      subtitle: "This is a subtitle",
      message: "This is a very long message",
      theme: "darkblue",

      native: true, // when using native, your OS will handle theming.
    });
  };
  const MINUTE_MS = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      notificationSocket();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  useEffect(() => {
    props.GetNotificationListMiddleware(
      pageNumber,
      noOfNotify,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfNotify]);
  useEffect(() => {
    if (props.Data !== null && props.Data !== undefined) {
      // console.log("NOTIFICATION DATA AT COMPONENT", props.Data);
      setData(props.Data);
    }
    // if (truee?.chk == true) {
    //   findObj(truee.obj);
    // }
    // return () => {
    //   console.log("Ueffect closed"); //whenever the component removes it will executes
    // };
    // console.log("NOTIFICATION DATA AT COMPONENT", props.Data);
  }, [props.Data]);
  useEffect(() => {
    if (
      props.isRead !== null &&
      props.isRead !== undefined &&
      props.isRead !== ""
    ) {
      console.log("Read SUCCESS", props.isRead);
      // setData(props.Data);
    }
    // console.log("NOTIFICATION DATA AT COMPONENT", props.Data);
  }, [props.isRead]);
  const playSound = (data) => {
    // console.log("DATA PLAY SOUND", data);
    const currentURL = window.location.href;
    addNotification({
      title: data.notification.title,
      onClick: () => {
        window.open(currentURL);
      },
      // subtitle: "This is a subtitle",
      message: data.notification.message,
      theme: "darkblue",
      // badge: "https://picsum.photos/300/200",
      icon: "https://res.cloudinary.com/zamcloud/image/upload/v1652082449/tusjkxrxigrvj5ehpapb.png",
      native: true, // when using native, your OS will handle theming.
    });
  };
  const notificationSocket = () => {
    // play();
    let userData = JSON.parse(user);
    // console.log("USER ID", userData.id);
    const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
    const token = localStorage.getItem("auth");

    const socket = socketIOClient(
      `${ENDPOINT}?token=Bearer ${token}`,
      networkPoll
    );
    socket.once(`notification-${userData.id}`, (data) => {
      // play();
      if (data) {
        console.log("DATA NOTIFICATIOn", data);
        // console.log("DATA Length", Object.keys(data).length);
        // const currentURL = window.location.href;
        // for (var i = 0; i <= Object.keys(data).length; i++) {
        //   addNotification({
        //     title: data.notification.title,
        //     onClick: () => {
        //       window.open(currentURL);
        //     },
        //     // subtitle: "This is a subtitle",
        //     message: data.notification.message,
        //     theme: "darkblue",
        //     // badge: "https://picsum.photos/300/200",
        //     icon: "https://res.cloudinary.com/zamcloud/image/upload/v1652082449/tusjkxrxigrvj5ehpapb.png",
        //     native: true, // when using native, your OS will handle theming.
        //   });
        // }

        // playSound(data);

        props.GetNotificationListMiddleware(
          pageNumber,
          noOfNotify,
          onSuccess,
          onFailure
        );
        // setTrue({ obj: data, chk: true });
        // socket.close();
        // socket.off(`notification-${userData.id}`, playSound(data));
      }
    });
    // socket.close();
  };
  const findObj = (obj) => {
    // console.log("DATA", data);
    // console.log("DATA 2", obj);
    const notf = data.notification.items.find((element) => {
      if (element.userId === obj.notification.userId) {
        return true;
      }

      return false;
    });
    console.log("Find Sucess", notf);
  };
  // beep.play();
  const onSuccess = () => {};
  const onFailure = () => {};
  // const [tog,setTog] = useState(false);
  let history = useHistory();
  const [state, setstate] = useState("");
  let role = localStorage.getItem("roleid");
  const removeData = () => {
    // localStorage.removeItem('token');

    localStorage.removeItem("roleid");
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    setstate("/auth/login");
    // localStorage.removeItem("role");
    // location.href = ("../");
  };
  if (
    localStorage.user === undefined &&
    localStorage.roleid === undefined &&
    localStorage.auth === undefined
  ) {
    let path = state;
    history.push(path);
  }
  let user = localStorage.getItem("user");
  let userParse = JSON.parse(user);
  const removeDataForAgent = () => {
    localStorage.removeItem("roleid");
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    setstate("/auth/agentlogin");
  };
  const removeDataForManager = () => {
    localStorage.removeItem("roleid");
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    setstate("/auth/staffLogin");
  };
  const removeDataForAdmin = () => {
    localStorage.removeItem("roleid");
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    setstate("/auth/adminLogin");
  };

  // let localStore = parseInt(localStorage.teamid);

  // useEffect(() => {
  //   props.CheckNotification(Body, onSuccess, onFailure);
  // }, [true]);

  // const onSuccess = () => {};
  // const onFailure = () => {
  //   // window.alert("Fail");
  // };

  // const [data, setData] = useState(null);
  // const [count, setCount] = useState(0);
  // const [Body, setBodyAPI] = useState({
  //   id: "",
  //   Taskid: "",
  //   Datetime: "",
  //   status_id: "",
  //   Note: "",
  //   Meetingdatetime: "",
  //   orderstatus: "",
  //   logtype: "",
  //   CallOutcome: "",
  //   Dashboarduserid: "",
  //   teamid: localStore,
  //   Agentname: "",
  //   status_name: "",
  // });
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // console.log("EXISTING DATA", data.notification.items);
        handleNotification(data.notification.items);
        setNoOfNitfy(noOfNotify + 10);
      }
    }
  };
  var style = {
    boxShadow:
      "3px 3px 3px 3px rgba(0, 0, 0, 0.16), 3px 3px 3px 3px rgba(0, 0, 0, 0.16)",
    borderRadius: "3%",
    overflowY: "scroll",
    overflow: "hidden",
    height: "350px",
    // width: "300px",
    // wordWrap: "break-word",
    padding: "10px",
    marginLeft: "40%",
  };

  var style1 = {
    boxShadow:
      "3px 3px 3px 3px rgba(0, 0, 0, 0.08), 3px 3px 3px 3px rgba(0, 0, 0, 0.16)",
    borderRadius: "3%",
    // padding: '5px'
  };
  const handleNotification = (items) => {
    // console.log("DATA", data);
    let arr = [];
    items.forEach((item, i) => {
      arr.push(item.id);
    });
    let body = {
      notificationIds: arr,
    };
    setData({ ...data, Unreadcount: 0 });
    ReadNotificationMiddlerware(body);
    // console.log("Body", body);
    // props.ReadNotifcationMiddleware(body, onSuccess, onFailure);
    // props.GetNotificationListMiddleware(
    //   pageNumber,
    //   noOfNotify,
    //   onSuccess,
    //   onFailure
    // );
    // setNoOfNitfy(10);
  };
  const ReadNotificationMiddlerware = (body) => {
    let token = localStorage.getItem("auth");
    axios
      .patch(`${BASEURL}${apiPath}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data) {
          setData({ ...data, Unreadcount: 0 });
        }
      })
      .catch(function (error) {
        console.log("err", error);
      });
  };
  const handleChangeStatus = (id) => {
    // let body = {
    //   NotifyId: id,
    // };
    // props.UpdateNotificationStatus(body, onSuccess, onFailure);
  };
  const showChange = () => {
    setShowModal(!showModal);
    console.log("clicked");
  };
  const showChange1 = () => {
    setShowModal1(!showModal1);
    console.log("clicked");
  };
  // console.log(userParse, "userparse");
  const showNotificationModal = (data) => {
    setVisible(true);
    setNotifyData(data);
  };
  const handleClose = () => {
    setVisible(false);
    setNotifyData("");
  };
  return (
    <>
      <ChangePassword toggle={showChange} modal={showModal} />
      <ChangeProfile
        userdata={userParse}
        toggle={showChange1}
        modal={showModal1}
        role={role}
      />
      {/* {notifyData && (
        <ReadNotificationModal
          data={notifyData}
          visible={visible}
          close={handleClose}
        />
      )} */}
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-black bold-lg text-uppercase d-none d-lg-inline-block"
            to="/admin/dashboard"
          >
            {props.brandText}
          </Link>
          {/* <Link style={{marginBottom:'-30px', marginLeft:'-150px'}}>
          <img src={logo} style={{ marginRight:900,marginTop:40, height:50}}></img> </Link> */}
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              {/* <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                 <Input placeholder="Search" type="text" />
              </InputGroup> */}
            </FormGroup>
          </Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            {/* <button
              // onClick={buttonClick}
              // onClick={play}
              onClick={() => {
                playSound();
              }}
            >
              play
            </button> */}
            <div
              style={{
                color: "white",
                fontWeight: 600,
                letterSpacing: "2px",
                fontSize: "18px",
              }}
            >
              Welcome, {userParse?.fullName}
            </div>
            <UncontrolledDropdown
              onClick={() =>
                handleNotification(
                  props.Data !== null &&
                    props.Data !== undefined &&
                    props.Data.notification.items
                )
              }
              nav
            >
              <DropdownToggle
                // style={{ marginLeft: "35%" }}
                nav
                className="nav-link-icon mr--4"
              >
                {" "}
                <i
                  style={{ fontSize: "20px" }}
                  className="ni ni-bell-55 text-yellow"
                />
                <Badge
                  color=""
                  style={{
                    backgroundColor: "white",
                    padding: "3px",
                    fontSize: "8px",
                    color: "black",
                    position: "absolute",
                    left: 24,
                    top: 0,
                  }}
                >
                  {data !== null && data !== undefined ? data.Unreadcount : 0}
                  {/* {count} */}
                </Badge>
              </DropdownToggle>

              <DropdownMenu
                style={style}
                // style={{ marginLeft: "10%" }}
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow"
                right
              >
                <DropdownItem className="text-center">
                  Notifications
                </DropdownItem>
                {/* {notificationSocket()} */}
                <div
                  onScroll={onScroll}
                  ref={listInnerRef}
                  style={{
                    height: "280px",
                    overflowY: "auto",
                  }}
                >
                  {data !== null &&
                    data !== undefined &&
                    data.notification.items.map((item, i) => {
                      return (
                        <div>
                          <DropdownItem
                            style={
                              item.isRead === false
                                ? { backgroundColor: "rgba(112,181,249,0.2)" }
                                : { backgroundColor: "white" }
                            }
                            // href={opt.orderstatus === 8 ? "/admin/lead" : ""}
                            // href={`/admin/${opt.RouteName}`}
                            // style={
                            //   (style1,
                            //   {
                            //     backgroundColor:
                            //       opt.NotificationStatus === 65
                            //         ? "rgba(112,181,249,0.2)"
                            //         : "",
                            //   })
                            // }
                            key={i}
                            // onClick={() => handleChangeStatus("")}
                            // onClick={() => showNotificationModal(item)}
                          >
                            <h4>{item.title}</h4> <h5>{item.message}</h5>
                            <div className={"d-flex justify-content-between"}>
                              <span>
                                {moment(item.createdAt).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}
                              </span>
                            </div>
                          </DropdownItem>
                          <DropdownItem divider></DropdownItem>
                        </div>
                      );
                    })}
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              {/* <DropdownToggle nav>
                <Media className="align-items-center"></Media>
              </DropdownToggle> */}
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        role == "1" && userParse?.custom?.profilePhoto !== null
                          ? userParse?.custom?.profilePhoto
                          : role == "4"
                          ? userParse?.managerProfile?.profilePhoto
                          : role == "2"
                          ? userParse?.staffProfile?.profilePhoto
                          : role == "5" &&
                            userParse?.realEstate?.profilePhoto !== "abc.jpg"
                          ? userParse?.realEstate?.profilePhoto
                          : role == "6" &&
                            userParse?.realEstateStaffProfile?.profilePhoto !==
                              null
                          ? userParse?.realEstateStaffProfile?.profilePhoto
                          : require("../../../../assets/img/logo.png").default
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold"></span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider /> */}

                <DropdownItem onClick={showChange1}>
                  <i className="ni ni-circle-08" />
                  <span>Profile</span>
                </DropdownItem>
                <DropdownItem onClick={showChange}>
                  <i className="fa fa-lock" />
                  <span>Change Password</span>
                </DropdownItem>
                <DropdownItem
                  onClick={
                    role == "5" || role == "6"
                      ? removeDataForAgent
                      : role == "4" || role == "2"
                      ? removeDataForManager
                      : role == "3"
                      ? removeDataForAdmin
                      : removeData
                  }
                >
                  <i className="ni ni-user-run" />
                  <span
                    onClick={
                      role == "5" || role == "6"
                        ? removeDataForAgent
                        : role == "4" || role == "2"
                        ? removeDataForManager
                        : role == "3"
                        ? removeDataForAdmin
                        : removeData
                    }
                  >
                    Logout
                  </span>
                </DropdownItem>

                {/* <DropdownItem href="/auth/forget">
                  <i className="ni ni-ni ni-lock-circle-open" />
                  <span>Forget Password</span>
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  Data: state.notificationList.Data,
  // isRead: state.notificationList.isRead,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    GetNotificationListMiddleware: (
      pageNumber,
      noOfNotify,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        GetNotificationListMiddleware(
          pageNumber,
          noOfNotify,
          onSuccess,
          onFailure
        )
      ),
    ReadNotifcationMiddleware: (body, onSuccess, onFailure) =>
      dispatch(ReadNotifcationMiddleware(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AdminNavbar);
