import React, { useRef, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Container,
  Card,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import validate from "components/Utilities Component/ValidationWrapper";
import swal from "sweetalert";
import {
  BankRegistrationMiddleware,
  UploadMultiFilesMiddleware,
} from "../middleware";
import { useHistory } from "react-router-dom";

const BankRegistration = ({
  BankRegistrationMiddleware,
  UploadMultiFilesMiddleware,
}) => {
  const history = useHistory();
  const [body, setBody] = useState({
    name: "",
    code: "",
    logo: "",
    footer: "",
    contantOne: "",
    contantTwo: "",
    bankAccountNo: "",
  });

  const [error, setError] = useState({
    nameError: null,
    codeError: null,
    logoError: null,
    footerError: null,
    contantOneError: null,
    contantTwoError: null,
  });

  const CheckFields = (name) => {
    if (name === "name") {
      setError({
        ...error,
        nameError: validate("required", body.name),
      });
    } else if (name === "code") {
      setError({
        ...error,
        codeError: validate("required", body.code),
      });
    } else if (name === "footer") {
      setError({
        ...error,
        footerError: validate("required", body.footer),
      });
    } else if (name === "contantOne") {
      setError({
        ...error,
        contantOneError: validate("required", body.contantOne),
      });
    } else if (name === "logo") {
      setError({
        ...error,
        logoError: validate("required", body.logo),
      });
    } else if (name === "contantTwo") {
      setError({
        ...error,
        contantTwoError: validate("required", body.contantTwo),
      });
    }
  };

  const onSuccess = (msg) => {
    swal({
      title: "Congratulations!",
      text: msg,
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push("/user/FormGenerate");
      }
    });
  };
  const onFailure = (msg) => {
    swal("Sorry!", msg.message, "error");
  };

  const handleSendData = () => {
    setError({
      ...error,
      nameError: validate("required", body.name),
      codeError: validate("required", body.code),
      logoError: validate("required", body.logo),
      footerError: validate("required", body.footer),
      contantOneError: validate("required", body.contantOne),
      contantTwoError: validate("required", body.contantTwo),
    });
    if (
      body.code !== "" &&
      body.contantOne !== "" &&
      body.contantTwo !== "" &&
      body.footer !== "" &&
      body.logo !== "" &&
      body.name !== ""
    ) {
      BankRegistrationMiddleware(body, onSuccess, onFailure);
    }
  };
  const handleUploadImage = (files) => {
    let formData = new FormData();
    formData.append("files", files[0]);
    UploadMultiFilesMiddleware(
      formData,
      onFileUploadSuccess,
      onFileUploadFailure
    );
  };
  const inputFileRef = useRef(null);
  const onFileUploadSuccess = (res) => {
    setBody({ ...body, logo: res[0] });
    // if (inputFileRef.current?.value) {
    //   inputFileRef.current.value = null;
    // }
  };
  const onFileUploadFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h1 className="mb-0">Bank Registration</h1>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Bank Name <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Bank Name here"
                      value={body.name}
                      onBlur={() => CheckFields("name")}
                      onChange={(e) =>
                        setBody({ ...body, name: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.nameError !== "" && error.nameError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.nameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Branch Code <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Branch Code here"
                      value={body.code}
                      onBlur={() => CheckFields("code")}
                      onChange={(e) =>
                        setBody({ ...body, code: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.codeError !== "" && error.codeError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.codeError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Account Tittle <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Account Tittle here"
                      value={body.contantTwo}
                      onBlur={() => CheckFields("contantTwo")}
                      onChange={(e) =>
                        setBody({ ...body, contantTwo: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.contantTwoError !== "" &&
                      error.contantTwoError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.contantTwoError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Account Number
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Account Number here"
                      value={body.bankAccountNo}
                      onBlur={() => CheckFields("bankAccountNo")}
                      onChange={(e) =>
                        setBody({ ...body, bankAccountNo: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Bank Description <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Bank Description here"
                      value={body.contantOne}
                      onBlur={() => CheckFields("contantOne")}
                      onChange={(e) =>
                        setBody({ ...body, contantOne: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.contantOneError !== "" &&
                      error.contantOneError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.contantOneError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Bank Disclaimer <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Bank Disclaimer here"
                      value={body.footer}
                      onBlur={() => CheckFields("footer")}
                      onChange={(e) =>
                        setBody({ ...body, footer: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.footerError !== "" && error.footerError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.footerError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Label
                      className="form-control-label mr-2"
                      for="input-username"
                    >
                      Upload Bank Logo:
                    </Label>
                    <Input
                      type="file"
                      name="logo"
                      onChange={(e) => {
                        let arr = [];
                        Array.from(e.target.files).map((file) => {
                          arr.push(file);
                        });
                        handleUploadImage(arr);
                      }}
                      ref={inputFileRef}
                      accept="image/*"
                    />
                    {error.logoError !== "" && error.logoError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.logoError}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      id="save"
                      onClick={handleSendData}
                    >
                      <span className="btn-inner--icon">Save</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  bankRegRes: state.BankRegistrationReducer.bankRegRes,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    BankRegistrationMiddleware: (body, onSuccess, onFailure) =>
      dispatch(BankRegistrationMiddleware(body, onSuccess, onFailure)),
    UploadMultiFilesMiddleware: (body, onSuccess, onFailure) =>
      dispatch(UploadMultiFilesMiddleware(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(BankRegistration);
