import React, { useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import logo from "../../../../assets/img/black.png";
import Frame from "../../../../assets/img/Frame.png";
import validate from "components/Utilities Component/ValidationWrapper";
import { useState } from "react";
import swal from "sweetalert";
import { DealerRegMiddleware } from "../middleware";
import { city_Middleware } from "views/AgentView/SignUp/middleware";
import { country_Middleware } from "views/AgentView/SignUp/middleware";
import { connect } from "react-redux";
import "../CSS/DealerCss.css";
const DealerRegistration = ({
  DealerRegMiddleware,
  city_Middleware,
  country_Middleware,
  cityData,
  countryData,
}) => {
  const [arrCountry, setArrCountry] = useState([]);
  const [arrCity, setArrCity] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [body, setBody] = useState({
    name: "",
    // cnic: "",
    phone: "",
    address1: "",
    // address2: "",
    country: "168",
    city: "",
    // email: "",
    agencyName: "",
    // noOfStaff: 0,
    whatsapp: "",
  });

  const [error, setError] = useState({
    nameError: null,
    // cnicError: null,
    phoneError: null,
    address1Error: null,
    whatsappError: null,
    // countryError: null,
    cityError: null,
    // emailError: null,
    agencyNameError: null,
  });

  const CheckFields = (name) => {
    if (name === "name") {
      setError({
        ...error,
        nameError: validate("required", body.name),
      });
    }
    // else if (name === "cnic") {
    //   setError({
    //     ...error,
    //     cnicError: validate("CNIC", body.cnic),
    //   });
    // }
    else if (name === "address1") {
      setError({
        ...error,
        address1Error: validate("required", body.address1),
      });
    } else if (name === "whatsapp") {
      if (body.whatsapp !== "") {
        let isNum = /^03\d{2}-?\d{7}$/.test(body.whatsapp);
        setError({
          ...error,
          whatsappError:
            isNum === false
              ? "WhatsApp number should be like this 03XXXXXXXX"
              : null,
        });
      } else {
        setError({
          ...error,
          whatsappError: null,
        });
      }
    } else if (name === "phone") {
      if (/^03\d{2}-?\d{7}$/.test(body.phone)) {
        setError({
          ...error,
          phoneError: null,
        });
      } else {
        setError({
          ...error,
          phoneError: "Phone number should be like this 03XXXXXXXX",
        });
      }
    }
    // else if (name === "country") {
    //   setError({
    //     ...error,
    //     countryError: validate("required", body.country),
    //   });
    // }
    else if (name === "city") {
      setError({
        ...error,
        cityError: validate("required", body.city),
      });
    } else if (name === "agencyName") {
      setError({
        ...error,
        agencyNameError: validate("required", body.agencyName),
      });
    }
    // else if (
    //   name === "email" &&
    //   validate("email", body.email) == "Please enter valid email"
    // ) {
    //   setError({
    //     ...error,
    //     emailError: validate("email", body.email),
    //   });
    // }
    // else if (name === "phone" && /^03\d{2}-?\d{7}$/.test(body.phone)) {
    //   setError({
    //     ...error,
    //     phoneError: null,
    //   });
    // }
  };

  const onSuccess = (msg) => {
    swal({
      title: "Congratulations!",
      text: msg,
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        // history.push("/user/FormGenerate");
      }
    });
    setBody({
      name: "",
      // cnic: "",
      phone: "",
      address1: "",
      // address2: "",
      country: "168",
      city: "",
      // email: "",
      agencyName: "",
      // noOfStaff: 0,
      whatsapp: "",
    });
    setError({
      nameError: null,
      // cnicError: null,
      phoneError: null,
      address1Error: null,
      // countryError: null,
      cityError: null,
      // emailError: null,
      agencyNameError: null,
      whatsappError: null,
    });
    setChecked(false);
    setIsLoading(false);
  };
  const onFailure = (msg) => {
    swal("Sorry!", msg.message, "error");
    setIsLoading(false);
  };

  const handleSendData = () => {
    setError({
      ...error,
      nameError: validate("required", body.name),
      // cnicError: validate("CNIC", body.cnic),
      phoneError: /^03\d{2}-?\d{7}$/.test(body.phone)
        ? null
        : "Phone number should be like this 03XXXXXXXX",
      address1Error: validate("required", body.address1),
      // address2Error: validate("required", body.address2),
      // countryError: validate("required", body.country),
      cityError: validate("required", body.city),
      // emailError: validate("email", body.email),
      agencyNameError: validate("required", body.agencyName),
    });
    if (
      // body.cnic !== "" &&
      body.phone !== "" &&
      /^03\d{2}-?\d{7}$/.test(body.phone) &&
      body.address1 !== "" &&
      body.country !== "" &&
      body.name !== "" &&
      body.city !== "" &&
      body.agencyName !== ""
      // &&
      // validate("email", body.email) !== "Please enter valid email"
    ) {
      let newBody = {
        name: body.name,
        agencyName: body.agencyName,
        // email: body?.email || "-",
        // cnic: body?.cnic || "-",
        phone: `${body.phone}`,
        whatsAppNo: body?.whatsapp || "-",
        // noOfStaff: +body?.noOfStaff || 0,
        addressOne: body.address1,
        // addressTwo: body?.address2 || "-",
        cityId: +body.city,
        countryId: +body.country,
      };
      // console.log(newBody);
      setIsLoading(true);
      DealerRegMiddleware(newBody, onSuccess, onFailure);
      setError({
        ...error,
        phoneError: null,
      });
    }
    // else if (validate("email", body.email) == "Please enter valid email") {
    //   setError({
    //     ...error,
    //     emailError: validate("email", body.email),
    //   });
    // }
  };
  const handleChecked = () => {
    setChecked(!checked);
  };
  useEffect(() => {
    setBody({
      ...body,
      whatsapp: checked == true ? body.phone : "",
    });
  }, [checked]);
  // const onSuccessCountry = () => {};
  // const onFailureCountry = () => {};
  const onSuccessCity = () => {};
  const onFailureCity = () => {};
  // useEffect(() => {
  //   country_Middleware(onSuccessCountry, onFailureCountry);
  // }, [true]);
  useEffect(() => {
    let tempArrCountry = [];

    if (cityData !== null && cityData !== undefined) {
      cityData.map((data, index) => {
        tempArrCountry.push(data);
      });
    }

    setArrCity(tempArrCountry);
  }, [cityData]);
  // useEffect(() => {
  //   let tempArrCountry = [];

  //   if (countryData !== null && countryData !== undefined) {
  //     countryData.map((data, index) => {
  //       tempArrCountry.push(data);
  //     });
  //   }

  //   setArrCountry(tempArrCountry);
  // }, [countryData]);
  useEffect(() => {
    if (
      body.country !== null &&
      body.country !== undefined &&
      body.country !== ""
    ) {
      city_Middleware(body.country, onSuccessCity, onFailureCity);
    } else {
      setArrCity([]);
      setBody({ ...body, city: "" });
    }
  }, [body.country]);
  return (
    <div
      style={{
        height: "120vh",
        backgroundColor: "white",
        margin: 0,
      }}
    >
      <Row>
        <Col lg="5" md="5" sm="5">
          <div
            // style={{
            //   height: "150vh",
            //   width: "100%",
            //   backgroundColor: "#042523",
            //   padding: "30px 40px",
            // }}
            className="dealerSectionOne"
          >
            <div
              // style={{
              //   width: "100%",
              //   display: "flex",
              //   justifyContent: "center",
              // }}
              className="imgDiv"
            >
              <img
                className="navbar-brand-img"
                // style={{ width: "35%", cursor: "pointer" }}
                src={logo}
              />
            </div>

            <div
              // style={{
              //   color: "#FFFFFF",
              //   // fontFamily: "Poppins",
              //   fontWeight: "500",
              //   fontSize: "30px",
              //   marginTop: "100px",
              //   marginBottom: "100px",
              // }}
              className="sectionOneText"
            >
              “Expand Your Reach: Become Our Authorized Dealer and Receive
              Exclusive <b style={{ color: "#D79A29" }}>'Flagship'</b> Benefits
              for Your Real Estate Agency
              <span style={{ color: "#D79A29" }}>.”</span>
            </div>
            <div
              // style={{
              //   width: "100%",
              //   display: "flex",
              //   justifyContent: "center",
              // }}
              className="imgDiv2"
            >
              <img
                // className="sectionOneFlagship"
                // style={{ width: "70%", cursor: "pointer" }}
                src={Frame}
              />
            </div>
          </div>
        </Col>

        <Col lg="7" md="7" sm="7">
          <div
            style={{
              height: "120vh",
              width: "100%",
              padding: "30px 20px",
            }}
          >
            <div
              style={{
                color: "black",
                fontSize: "18px",
              }}
            >
              {/* <small>
                Our platform is excited to offer real estate agencies the
                opportunity to join our network as authorized dealers. By
                becoming an authorized dealer, your agency will receive
                exclusive benefits, including access to our{" "}
                <span style={{ color: "#D79A29" }}>'Flagship'</span> program.
                Our <span style={{ color: "#D79A29" }}>'Flagship'</span> program
                offers a range of tools and resources to help your agency
                thrive, such as enhanced visibility, lead generation, and
                marketing support. Don't miss out on this chance to take your
                agency to the next level!
              </small>
              <br />
              <br /> */}
              <small>
                <b>
                  Please fill & Submit the form below to successfully register
                  yourself in our Flagship Program.
                </b>
              </small>
            </div>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <Row>
                <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    Name <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter Name here"
                    value={body.name}
                    onBlur={() => CheckFields("name")}
                    onChange={(e) => setBody({ ...body, name: e.target.value })}
                    autoComplete="off"
                  ></input>
                  {error.nameError !== "" && error.nameError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.nameError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
                <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    Agency Name <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter Name here"
                    value={body.agencyName}
                    onBlur={() => CheckFields("agencyName")}
                    onChange={(e) =>
                      setBody({ ...body, agencyName: e.target.value })
                    }
                    autoComplete="off"
                  ></input>
                  {error.agencyNameError !== "" &&
                    error.agencyNameError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.agencyNameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                </Col>
                {/* <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    CNIC (optional)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter CNIC here"
                    value={body.cnic}
                    onBlur={() => CheckFields("cnic")}
                    onChange={(e) => setBody({ ...body, cnic: e.target.value })}
                    autoComplete="off"
                    maxLength={13}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></input>
                  {error.cnicError !== "" && error.cnicError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.cnicError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col> */}
                {/* </Row>
              <Row className="mt-3"> */}
                <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    Phone No. <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter Phone No. here"
                    value={body.phone}
                    onBlur={() => CheckFields("phone")}
                    onChange={(e) =>
                      setBody({ ...body, phone: e.target.value })
                    }
                    disabled={checked}
                    maxLength={11}
                    autoComplete="off"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></input>
                  {error.phoneError !== "" && error.phoneError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.phoneError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
                <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    Whatsapp No (optional)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter Whatsapp No here"
                    value={body.whatsapp}
                    onBlur={() => CheckFields("whatsapp")}
                    onChange={(e) => {
                      setBody({ ...body, whatsapp: e.target.value });
                    }}
                    disabled={checked}
                    maxLength={11}
                    autoComplete="off"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></input>
                  {body.phone?.length == 11 ? (
                    <div style={{ position: "relative", float: "right" }}>
                      <input
                        type="checkbox"
                        onClick={handleChecked}
                        // disabled={isUserExist}
                      />
                      <small>
                        <b> Same As Phone No</b>
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                  {error.whatsappError !== "" &&
                    error.whatsappError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.whatsappError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                </Col>
                {/* <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    Email (optional)
                  </label>
                  <input
                    type="email"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter Email here"
                    value={body.email}
                    onBlur={() => CheckFields("email")}
                    onChange={(e) =>
                      setBody({ ...body, email: e.target.value })
                    }
                    autoComplete="off"
                  ></input>
                  {error.emailError !== "" && error.emailError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.emailError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col> */}
                {/* </Row>
              <Row className="mt-3"> */}
                {/* <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    No of Staff (optional)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter No of Staff here"
                    value={body.noOfStaff}
                    onBlur={() => CheckFields("staff")}
                    onChange={(e) =>
                      setBody({ ...body, noOfStaff: e.target.value })
                    }
                    autoComplete="off"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></input>
                </Col> */}
                <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    Address line 1 <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter Address line 1 here"
                    value={body.address1}
                    onBlur={() => CheckFields("address1")}
                    onChange={(e) =>
                      setBody({ ...body, address1: e.target.value })
                    }
                    autoComplete="off"
                  ></input>
                  {error.address1Error !== "" &&
                    error.address1Error !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.address1Error}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                </Col>
                {/* <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    Address line 2
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder="Enter Address line 2 here"
                    value={body.address2}
                    onBlur={() => CheckFields("address2")}
                    onChange={(e) =>
                      setBody({ ...body, address2: e.target.value })
                    }
                    autoComplete="off"
                  ></input>
                </Col> */}
                {/* </Row>
              <Row className="mt-3"> */}
                {/* <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    Country <span className="text-red">*</span>
                  </label>
                  <select
                    className="form-control"
                    onBlur={() => CheckFields("country")}
                    value={body.country}
                    onChange={(e) =>
                      setBody({ ...body, country: e.target.value })
                    }
                    disabled={arrCountry?.length == 0}
                  >
                    <option value="">Select Country</option>
                    {arrCountry?.map((data) => {
                      return (
                        <option
                          key={data.id}
                          //value={JSON.stringify({ id: data.id, price: data.price })}
                          value={data.id}
                        >
                          {data.title}
                        </option>
                      );
                    })}
                  </select>

                  {error.countryError !== "" && error.countryError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.countryError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col> */}
                <Col lg="4" md="6" sm="12" xs="12" className="p-2">
                  <label className="form-control-label" for="input-username">
                    City <span className="text-red">*</span>
                  </label>
                  <select
                    className="form-control"
                    onBlur={() => CheckFields("city")}
                    value={body.city}
                    onChange={(e) => setBody({ ...body, city: e.target.value })}
                    disabled={arrCity?.length == 0}
                  >
                    <option value="">Select City</option>
                    {arrCity?.map((data) => {
                      return (
                        <option
                          key={data.id}
                          //value={JSON.stringify({ id: data.id, price: data.price })}
                          value={data.id}
                        >
                          {data.title}
                        </option>
                      );
                    })}
                  </select>
                  {error.cityError !== "" && error.cityError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.cityError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
                {/* </Row>
              <Row className="mt-3"> */}
                <Col lg="12" md="12" sm="12" xs="12" className="p-2">
                  <Button
                    style={{
                      background: "#042523",
                      borderRadius: "30px",
                      color: "white",
                      float: "right",
                    }}
                    onClick={handleSendData}
                    disabled={isLoading}
                  >
                    Submit Now &nbsp;
                    <span className="btn-inner--icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </Button>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12" className="p-2">
                  <br />
                  <br />
                  <div
                    style={{
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <small>
                      Our platform is excited to offer real estate agencies the
                      opportunity to join our network as authorized dealers. By
                      becoming an authorized dealer, your agency will receive
                      exclusive benefits, including access to our{" "}
                      <span style={{ color: "#D79A29" }}>'Flagship'</span>{" "}
                      program. Our{" "}
                      <span style={{ color: "#D79A29" }}>'Flagship'</span>{" "}
                      program offers a range of tools and resources to help your
                      agency thrive, such as enhanced visibility, lead
                      generation, and marketing support. Don't miss out on this
                      chance to take your agency to the next level!
                    </small>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cityData: state.agentSignUp.cityData,
  // countryData: state.agentSignUp.countryData,
});
const mapDispatchToProps = (dispatch) => {
  return {
    DealerRegMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(DealerRegMiddleware(body, OnSuccess, OnFailure)),
    city_Middleware: (countryId, OnSuccess, OnFailure) =>
      dispatch(city_Middleware(countryId, OnSuccess, OnFailure)),
    // country_Middleware: (OnSuccess, OnFailure) =>
    //   dispatch(country_Middleware(OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DealerRegistration);
