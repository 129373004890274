import axios from "axios";
import {
  FetchBanksByIdAction,
  FormListAction,
  LaunchedFormAction,
} from "../action/index";
import {
  FORM_LIST_PATH,
  FETCH_BANKS_BY_ID_PATH,
  LAUNCHED_FROMS_PATH,
} from "../constant";
import { BASEURL } from "config/api/URL";

export const LaunchedFormMiddleware = (id) => (dispatch) => {
  dispatch(LaunchedFormAction.Fetch());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${LAUNCHED_FROMS_PATH}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(LaunchedFormAction.Fetch_Success(res.data.data));
      // dispatch(onSuccess(res.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        // onFailure(error.response.data);
      }
    });
};

export const FormListMiddleware =
  (params, limit, onSuccess, onFailure) => (dispatch) => {
    dispatch(FormListAction.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FORM_LIST_PATH}?page=1&limit=${limit}&bankId=${params.bankId}&startForm=${params.startForm}&endForm=${params.endForm}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(FormListAction.Fetch_Success(res.data.data));
        dispatch(onSuccess(res.data.message));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

export const FetchBanksByIdMiddleware = (bankId) => (dispatch) => {
  dispatch(FetchBanksByIdAction.Fetch());
  let token = localStorage.getItem("auth");
  axios
    .get(
      `${BASEURL}${FETCH_BANKS_BY_ID_PATH}/${bankId}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => {
      dispatch(FetchBanksByIdAction.Fetch_Success(res.data.data));
      // dispatch(onSuccess(res.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        // onFailure(error.response.data);
      }
    });
};
