import React, { useEffect, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Input,
  Container,
  Card,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import validate from "components/Utilities Component/ValidationWrapper";
import {
  _GetpaymentThrough,
  _CreatePaymentReceipt,
  EditById_Middleware,
  Staff_SignUpForManager,
} from "../middleware";
import swal from "sweetalert";
import "jspdf-autotable";
import { useHistory, useLocation } from "react-router-dom";

const AddNewStaff = ({ _EditById_Middleware, _Staff_SignUpForManager }) => {
  let testPhoneNum = /^(\+92|92)[0-9]{10}$/;

  let storage = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const userInfo = location.state;
  const history = useHistory();
  const [isTrue, setIsTrue] = useState(false);
  const [validationPhoneNo, setValidationPhoneNo] = useState("");
  const [validationWhatsAppNo, setValidationWhatsAppNo] = useState("");
  const [checked, setChecked] = useState(false);
  const [staff, setStaff] = useState({
    fullName: userInfo?.user ? userInfo.user.fullName : "",
    cnic: userInfo?.user ? userInfo.user.cnic : "",
    email: userInfo?.user ? userInfo.user.email : "",
    phone: userInfo?.user ? userInfo.user.phone : "",
    profilePhoto: userInfo?.user ? userInfo.user.profilePhoto : "",
    whatsAppNo: userInfo?.user ? userInfo.user.whatsAppNo : "",
  });

  const [error, setError] = useState({
    nameError: null,
    cnicError: null,
    mobileError: null,
    whatsAppNoError: null,
    emailError: null,
  });

  const CheckFields = (name) => {
    if (name === "name") {
      setError({
        ...error,
        nameError: validate("required", staff.fullName),
      });
    } else if (name === "CNIC") {
      setError({
        ...error,
        cnicError: validate("CNIC", staff.cnic),
      });
    } else if (name === "mobile") {
      setError({
        ...error,
        mobileError: validate("phNumber", staff.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("whatsappNumber", staff.whatsAppNo),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", staff.email),
      });
    }
  };
  const onStaffSignUpSuccess = (msg) => {
    swal({
      title: "Congratulations!",
      text: `${msg} successfully!`,
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push("/user/StaffList");
      }
    });
  };
  const onStaffSignUpFailure = (msg) => {
    swal("Sorry!", msg, "error");
    setIsTrue(false);
  };
  const onSuccessUpdate = () => {
    swal({
      title: "Congratulations!",
      text: "Successfully updated",
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push("/user/StaffList");
      }
    });
  };
  const onFailureUpdate = (msg) => {
    swal("Sorry!", msg, "error");
    setIsTrue(false);
  };

  const handleSendData = () => {
    setError({
      ...error,
      nameError: validate("required", staff.fullName),
      cnicError: validate("CNIC", staff.cnic),
      mobileError: validate("phNumber", staff.phone),
      emailError: validate("email", staff.email),
      whatsAppNoError: validate("whatsappNumber", staff.whatsAppNo),
    });
    checkNumber();
    if (
      staff.fullName !== "" &&
      staff.cnic !== "" &&
      testPhoneNum.test(staff.phone) &&
      storage?.managerProfile?.bankId !== "" &&
      staff.email !== "" &&
      storage?.managerProfile?.branchId !== "" &&
      testPhoneNum.test(staff.whatsAppNo) &&
      userInfo === undefined
    ) {
      setIsTrue(true);
      let body = {
        fullName: staff.fullName,
        cnic: staff.cnic,
        phone: `+${staff.phone}`,
        whatsAppNo: `+${staff.whatsAppNo}`,
        bankId: storage?.managerProfile?.bankId,
        email: staff.email,
        branchId: storage?.managerProfile?.branchId,
        profilePhoto: "ok",
      };
      _Staff_SignUpForManager(body, onStaffSignUpSuccess, onStaffSignUpFailure);
    } else if (
      staff.fullName !== "" &&
      staff.cnic !== "" &&
      staff.phone !== "" &&
      staff.bankId !== "" &&
      staff.email !== "" &&
      staff.branchId !== "" &&
      userInfo !== undefined
    ) {
      setIsTrue(true);
      let body = {
        fullName: staff.fullName,
        cnic: staff.cnic,
        mobile: staff.phone,
        whatsAppNo: staff.whatsAppNo,
        Email: staff.email,
        profilePhoto: "ok",
      };
      _EditById_Middleware(body, userInfo.id, onSuccessUpdate, onFailureUpdate);
    }
  };
  const checkNumber = () => {
    if (!testPhoneNum.test(staff.phone) && error.mobileError == null) {
      setValidationPhoneNo("Enter Phone number in valid form");
    } else {
      setValidationPhoneNo("");
    }
    if (!testPhoneNum.test(staff.whatsAppNo) && error.whatsAppNoError == null) {
      setValidationWhatsAppNo("Enter WhatsApp number in valid form");
    } else {
      setValidationWhatsAppNo("");
    }
  };
  const handleChecked = () => {
    setChecked(!checked);
  };
  useEffect(() => {
    setStaff({
      ...staff,
      whatsAppNo:
        checked == true
          ? staff.phone
          : userInfo?.user
          ? userInfo.user.whatsAppNo
          : "",
    });
  }, [checked]);
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h3 className="mb-0">Add New Staff</h3>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Staff Name <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Staff Name.."
                      value={staff.fullName}
                      onBlur={() => CheckFields("name")}
                      onChange={(e) =>
                        setStaff({ ...staff, fullName: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/^[A-Za-z ]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                    ></input>
                    {error.nameError !== "" && error.nameError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.nameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      CNIC <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Staff CNIC"
                      value={staff.cnic}
                      maxLength={13}
                      onBlur={() => CheckFields("CNIC")}
                      onChange={(e) =>
                        setStaff({ ...staff, cnic: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      disabled={userInfo?.hasOwnProperty("user")}
                    ></input>
                    {error.cnicError !== "" && error.cnicError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.cnicError}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Phone No. <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={staff.phone}
                      placeholder="92xxxxxxxxxx"
                      maxLength={userInfo?.user ? 13 : 12}
                      onBlur={() => CheckFields("mobile")}
                      onChange={(e) =>
                        setStaff({ ...staff, phone: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      disabled={checked}
                    ></input>
                    {error.mobileError !== "" && error.mobileError !== null ? (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.mobileError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    ) : validationPhoneNo !== "" ? (
                      <small>
                        <span style={{ color: "red" }}>
                          {validationPhoneNo}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      WhatsApp No. <span className="text-red">*</span>
                    </label>

                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={staff.whatsAppNo}
                      placeholder="92xxxxxxxxxx"
                      maxLength={userInfo?.user ? 13 : 12}
                      onBlur={() => CheckFields("whatsAppNo")}
                      onChange={(e) =>
                        setStaff({ ...staff, whatsAppNo: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      disabled={checked}
                    ></input>
                    {error.whatsAppNoError !== "" &&
                    error.whatsAppNoError !== null ? (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.whatsAppNoError}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    ) : validationWhatsAppNo !== "" ? (
                      <small>
                        <span style={{ color: "red" }}>
                          {validationWhatsAppNo}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    ) : (
                      ""
                    )}
                    {staff.phone?.length == 12 ? (
                      <div>
                        <input
                          type="checkbox"
                          onClick={handleChecked}
                          disabled={userInfo?.hasOwnProperty("user")}
                        />
                        <small> Same as Phone number</small>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <label className="form-control-label" for="input-username">
                      Email <span className="text-red">*</span>
                    </label>
                    <input
                      type="email"
                      id="input-username"
                      className="form-control"
                      value={staff.email}
                      placeholder="Enter Email .."
                      onBlur={() => CheckFields("email")}
                      onChange={(e) =>
                        setStaff({ ...staff, email: e.target.value })
                      }
                      autoComplete="off"
                      disabled={userInfo?.hasOwnProperty("user")}
                    ></input>
                    {error.emailError !== "" && error.emailError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.emailError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <br />
                </Row>
                <br />
                {userInfo?.user ? (
                  <Row>
                    <Col>
                      <h5>Note: CNIC and Email editing is not allowed</h5>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row>
                  <Col>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      id="save"
                      onClick={handleSendData}
                      disabled={isTrue}
                    >
                      <span className="btn-inner--icon">Submit</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _Staff_SignUpForManager: (body, onSuccess, onFailure) =>
      dispatch(Staff_SignUpForManager(body, onSuccess, onFailure)),
    _EditById_Middleware: (body, id, onSuccess, onFailure) =>
      dispatch(EditById_Middleware(body, id, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AddNewStaff);
