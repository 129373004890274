


export const CUSTOMERLIST =  "CUSTOMERLIST";
export const CUSTOMERLIST_SUCCESS =  "CUSTOMERLIST_SUCCESS";
export const CUSTOMERLIST_FAILURE =  "CUSTOMERLIST_FAILURE";
//export const CUSTOMERLIST_PATH = "customs";
export const CUSTOMERLIST_PATH = "customs/customers/for-admin";

export const CUSTOMERDELETE =  "CUSTOMERDELETE";
export const CUSTOMERDELETE_SUCCESS =  "CUSTOMERDELETE_SUCCESS";
export const CUSTOMERDELETE_FAILURE =  "CUSTOMERDELETE_FAILURE";
export const CUSTOMERDELETE_PATH = "customs/profile-delete-for-admin";

export const CUSTOMERFILE =  "CUSTOMERFILE";
export const CUSTOMERFILE_SUCCESS =  "CUSTOMERFILE_SUCCESS";
export const CUSTOMERFILE_FAILURE =  "CUSTOMERFILE_FAILURE";
export const CUSTOMERFILE_PATH = "customs/customer-xlsx/for-admin";

export const CUSTOMER =  "CUSTOMER";
export const CUSTOMER_SUCCESS =  "CUSTOMER_SUCCESS";
export const CUSTOMER_FAILURE =  "CUSTOMER_FAILURE";
export const CUSTOMER_PATH = "customs/user/signup/for-admin";


