

import { 
  AGENTLIST,AGENTLIST_SUCCESS,AGENTLIST_FAILURE,
  AGENTSTAFFDELETE,AGENTSTAFFDELETE_FAILURE,AGENTSTAFFDELETE_SUCCESS,
  AGENTSTAFFVERIFY,AGENTSTAFFVERIFY_FAILURE,AGENTSTAFFVERIFY_SUCCESS,
 
 
} from "../constant.js";

export class AgentStaffVerifyDetail {
  static AgentStaffVerify() {
    return {
      type: AGENTSTAFFVERIFY
  }
}
  static AgentStaffVerify_Success(response) {
    return {
      type: AGENTSTAFFVERIFY_SUCCESS,
      payload: response,
    };
  }
  static AgentStaffVerify_Failure(error) {
    return {
      type: AGENTSTAFFVERIFY_FAILURE,
      error,
    };
  }
 
}


export class AgentStaffDeleteDetail {
  static AgentStaffDelete() {
    return {
      type: AGENTSTAFFDELETE
  }
}
  static AgentStaffDelete_Success(response) {
    return {
      type: AGENTSTAFFDELETE_SUCCESS,
      payload: response,
    };
  }
  static AgentStaffDelete_Failure(error) {
    return {
      type: AGENTSTAFFDELETE_FAILURE,
      error,
    };
  }
 
}

export class AgentListDetail {
  static AgentList() {
    return {
      type: AGENTLIST
  }
}
  static AgentList_Success(response) {
    return {
      type: AGENTLIST_SUCCESS,
      payload: response,
    };
  }
  static AgentList_Failure(error) {
    return {
      type: AGENTLIST_FAILURE,
      error,
    };
  }
 
}

