export const APPLICATIONLIST = "APPLICATIONLIST";
export const APPLICATIONLIST_SUCCESS = "APPLICATIONLIST_SUCCESS";
export const APPLICATIONLIST_FAILURE = "APPLICATIONLIST_FAILURE";
export const APPLICATIONLIST_PATH = "real-estate/application/for-real-estate";

// export const UPLOADIMAGES = "UPLOADIMAGES";
// export const UPLOADIMAGES_SUCCESS = "UPLOADIMAGES_SUCCESS";
// export const UPLOADIMAGES_FAILURE = "UPLOADIMAGES_FAILURE";
// export const UPLOADIMAGES_PATH = "real-estate/upload-application-images";

export const FORMSUBMIT = "FORMSUBMIT";
export const FORMSUBMIT_SUCCESS = "FORMSUBMIT_SUCCESS";
export const FORMSUBMIT_FAILURE = "FORMSUBMIT_FAILURE";
// export const FORMSUBMIT_PATH = "real-estate/submit-application/single/for-agent";
export const FORMSUBMIT_PATH = "real-estate/submit-multi-application-request";

export const CHALAN_DATA = "CHALAN_DATA";
export const CHALAN_DATA_SUCCESS = "CHALAN_DATA_SUCCESS";
export const CHALAN_DATA_FAILURE = "CHALAN_DATA_FAILURE";
export const CHALAN_DATA_PATH = "real-estate/application-data/for-chalan";
