import {
  FETCH_REALSTATE_DEALER,
  FETCH_REALSTATE_DEALER_SUCCESS,
  FETCH_REALSTATE_DEALER_FAILURE,
} from "../constant.js";

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  getRealstateDealers: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_REALSTATE_DEALER:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        getRealstateDealers: null,
        error: {},
      };
    case FETCH_REALSTATE_DEALER_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        getRealstateDealers: action.payload,
      };
    case FETCH_REALSTATE_DEALER_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
