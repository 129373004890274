import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";

const Loading = (props) => {
  return (
    <>
      <Spinner animation="border" color="white" size="sm" />{" "}
      <span style={{ marginLeft: "2px" }}>{props.attr}</span>
    </>
  );
};
export default Loading;
