import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Input,
  Form,
  Label,
  FormGroup,
  FormFeedback,
  Container,
  Col,
  Row,
} from "reactstrap";
import swal from "sweetalert";
const PublicSupportModal = (props) => {
  const inputFileRef = useRef(null);
  const [queryType, setQueryType] = useState("");
  const [cnic, setCnic] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [queryText, setQueryText] = useState("");
  // const [data, setData] = useState([]);
  const [report, setReport] = useState({
    title: "",
    docs: [],
    description: "",
    support_type: 1,
  });
  const handleDelete = (name) => {
    let filteredArray = report.docs?.filter((i) => i.name !== name);
    setReport({
      ...report,
      docs: filteredArray,
    });
  };
  const handleSubmit = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email?.trim().length > 0 && !email?.match(mailformat)) {
      swal("Sorry!", "Please Enter valid email.", "error");
    } else if (phone?.trim().length > 0 && phone?.trim().length <= 11) {
      swal("Sorry!", "Please Enter valid Phone No.", "error");
    } else if (report.docs?.length > 3) {
      swal("Sorry!", "Cannot upload files more than 3", "error");
    } else {
      let formData = new FormData();
      let file = report.docs;
      file.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("issueTypeId", parseInt(queryType));
      formData.append("query", queryText);
      formData.append("cnic", cnic);
      formData.append("email", email);
      formData.append("phone", phone?.includes("+") ? phone : `+${phone}`);
      props.middleware.Sendquerydetail(formData, onSuccess, onFailure);
    }
  };
  const onSuccess = () => {
    swal({
      title: "Success!",
      text: "Issue submitted Successfully.",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        setQueryText("");
        setEmail("");
        setCnic("");
        setQueryType("select");
        setPhone("");
        setReport({
          ...report,
          docs: [],
        });
        props.toggle();
        // setQueryType("");
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  return (
    <Modal
      size="lg"
      centered={true}
      style={{ marginTop: 5 }}
      isOpen={props.modal}
      toggle={props.toggle}
    >
      <ModalHeader
        close={
          <button className="close" onClick={props.toggle}>
            ×
          </button>
        }
        // close={props.toggle}
        toggle={props.close}
        style={{ backgroundColor: "#D79A29" }}
      >
        <h3 style={{ color: "white", textAlign: "center" }}>Support Modal</h3>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="6" lg="6" md="6" sm="6" xs="6">
            <Label className="form-control-label">
              <span style={{ color: "red" }}>*</span>Select issue
            </Label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              value={queryType}
              onChange={(e) => setQueryType(e.target.value)}
            >
              <option key={null} value="">
                select
              </option>
              {props.data !== null &&
              props.data !== undefined &&
              props.data.length > 0 ? (
                props.data.map((item, i) => {
                  return <option value={item.id}>{item.name}</option>;
                })
              ) : (
                <option unselectable={true} key={null} value={null}>
                  data not found
                </option>
              )}
            </Input>
          </Col>
          <Col xl="6" lg="6" md="6" sm="6" xs="6">
            <FormGroup>
              <Label for="cninc">
                <span style={{ color: "red" }}>*</span> Cnic
              </Label>
              <Input
                id="cninc"
                name="cninc"
                placeholder="Enter your cninc"
                maxLength={13}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                // onBlur={() => checkField("oldpass")}
                type="text"
                value={cnic}
                onChange={(e) => setCnic(e.target.value)}
              />
              {/* {err !== "" && err.includes("Old") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )} */}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xl="6" lg="6" md="6" sm="6" xs="6">
            <FormGroup>
              <Label for="name">
                <span style={{ color: "red" }}>*</span> Email
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Enter your name"
                // onBlur={() => checkField("oldpass")}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* {err !== "" && err.includes("Old") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )} */}
            </FormGroup>
          </Col>
          <Col xl="6" lg="6" md="6" sm="6" xs="6">
            <FormGroup>
              <Label for="Passowrd1">
                <span style={{ color: "red" }}>*</span>Phone
              </Label>
              <PhoneInput
                className="input-group-alternative"
                value={phone}
                onChange={(e) => setPhone(e)}
                // onChange={(e) => OnChange(e, "phone")}
                inputStyle={{
                  width: "100%",
                  height: 40,
                  // border: "none",
                }}
                inputExtraProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                fullWidth="true"
                //  containerStyle={{border:"none"}}
                countryCodeEditable={false}
                country={"pk"}
                // value={state.phone}
              />
              {/* {err !== "" && err.includes("Old") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )} */}
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <Form>
              <FormGroup>
                <Label className="form-control-label">
                  <span style={{ color: "red" }}>*</span>Write a query
                </Label>
                <Input
                  value={queryText}
                  onChange={(e) => {
                    setQueryText(e.target.value);
                  }}
                  rows={6}
                  placeholder="Write your any query here"
                  style={{ resize: "none" }}
                  type="textarea"
                  name="text"
                  id="exampleText"
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row style={{ marginTop: -10 }}>
          <Col xl="8" lg="8" md="8" sm="8" xs="8">
            <Form>
              <FormGroup>
                <Label className="form-control-label mr-2" for="input-username">
                  Upload Images
                </Label>
                <Input
                  type="file"
                  multiple={true}
                  name="docs"
                  style={{ color: "rgba(0, 0, 0, 0)", width: "23%" }}
                  onChange={
                    (e) => {
                      let arr = [];
                      const MAX_LENGTH = 3;
                      if (Array.from(e.target.files).length > MAX_LENGTH) {
                        e.preventDefault();
                        swal(
                          "Sorry!",
                          `Cannot upload files more than ${MAX_LENGTH}`,
                          "error"
                        );
                        // alert(
                        //   `Cannot upload files more than ${MAX_LENGTH}`
                        // );
                        return;
                      } else {
                        Array.from(e.target.files).map((file) => {
                          arr.push(file);
                        });
                        setReport({
                          ...report,
                          docs: report.docs.concat(arr),
                        });
                      }
                    }
                    // setReport({
                    //   ...report,
                    //   docs: Array.from(e.target.files),
                    // })
                  }
                  ref={inputFileRef}
                  // accept="image/gif, image/jpeg, image/png"
                  accept=".png, .jpg, .jpeg"
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {report.docs.length > 0
          ? report.docs.map((file, index) => {
              return (
                <Row key={index} style={{ marginTop: -15 }}>
                  <Col
                    lg="6"
                    md="6"
                    sm="6"
                    className="d-flex justify-content-between"
                  >
                    <Label for="files" className=" mt-2">
                      {file.name}
                    </Label>
                    <i
                      className="fas fa-trash "
                      style={{ cursor: "pointer", marginTop: 14 }}
                      onClick={() => handleDelete(file.name)}
                    ></i>
                  </Col>
                </Row>
              );
            })
          : ""}
        <Row className="mt-1">
          <Col lg="10" md="10" sm="10">
            <Button
              style={{
                backgroundColor: "#015652",
                color: "white",
                // float: "right",
                justifyContent: "flex-end",
                alignSelf: "flex-end",
              }}
              size="md"
              id="search"
              onClick={() => {
                if (
                  queryText?.trim().length > 0 &&
                  queryType?.trim().length > 0 &&
                  cnic?.trim().length > 0 &&
                  email?.trim().length > 0 &&
                  phone?.trim().length > 0
                ) {
                  handleSubmit();
                } else {
                  swal("Sorry!", "All * fields are required.", "error");
                }
              }}
            >
              <span className="btn-inner--icon">Send query</span>
            </Button>
          </Col>
        </Row>
      </ModalBody>
      {/* <ModalFooter>
          <Row>
            <Col>
              <h1 style={{ color: "#6d7075" }}>Rs:{values.price}</h1>
            </Col>
            <Col>
              <Button
                style={{ backgroundColor: "#015652  ", color: "white" }}
                onClick={confirm}
                disabled={numCount === 30}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </ModalFooter> */}
    </Modal>
  );
};
export default PublicSupportModal;
