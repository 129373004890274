import { 
  
  CUSTOMEREDIT,CUSTOMEREDIT_FAILURE,CUSTOMEREDIT_SUCCESS
} from "../constant.js";
export class CustomerEditDetail {
  static CustomerEdit() {
    return {
      type: CUSTOMEREDIT
  }
}
  static CustomerEdit_Success(response) {
    return {
      type: CUSTOMEREDIT_SUCCESS,
      payload: response,
    };
  }
  static CustomerEdit_Failure(error) {
    return {
      type: CUSTOMEREDIT_FAILURE,
      error,
    };
  }
 
}

