import { SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE ,CITY,CITY_SUCCESS,CITY_FAILURE, COUNTRY,COUNTRY_FAILURE,COUNTRY_SUCCESS} from "../constant.js";
const INITIAL_STATE = {
  Data: null,
  cityData:null,
  countryData:null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default  (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
      case COUNTRY:
     
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          countryData: null,
          error: {},
        };
      case COUNTRY_SUCCESS:
     console.log(action.payload,"payload")  
      
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          countryData: action.payload,
        };
      case COUNTRY_FAILURE:
   
    
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };
  

      case SIGNUP:
     
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Data: null,
        error: {},
      };
    case SIGNUP_SUCCESS:
     
    
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Data: action.payload,
      };
    case SIGNUP_FAILURE:
 
  
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case CITY:
     
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        cityData: null,
        error: {},
      };
    case CITY_SUCCESS:
     
    
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        cityData: action.payload,
      };
    case CITY_FAILURE:
 
  
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
