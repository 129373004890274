import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import TableViewPaymentReceipts from "../components/TableViewPaymentReceipts";
import { connect } from "react-redux";
import { _FetchAllReceipt, _FormCancelation, _FormStatus } from "../middleware";
import Pagination from "components/Pagination/Pagination";
import ViewReceiptModal from "../components/ViewReceiptModal";

const ViewPaymentReceipt = ({
  _FetchAllReceipt,
  AllReceipts,
  _FormCancelation,
  Response,
  _FormStatus,
  StatusResponse,
}) => {
  const [filter, setFilter] = useState({
    cnic: "",
    FormNo: "",
    status: "",
  });
  const [noOfRows, setnoOfRows] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  ///////////----------Pagination--------------//////////////

  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (total_pages > pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};
  const onStatusSuccess = () => {};
  const onStatusFailure = () => {};

  const getReceiptByFields = () => {
    if (filter.cnic !== "" && filter.FormNo === "" && filter.status === "") {
      let body = {
        FormNo: null,
        cnic: filter.cnic,
      };
      _FetchAllReceipt(body, onSuccess, onFailure);
    } else if (
      filter.cnic === "" &&
      filter.FormNo !== "" &&
      filter.status === ""
    ) {
      let body = {
        FormNo: filter.FormNo,
        cnic: "",
      };
      _FetchAllReceipt(body, onSuccess, onFailure);
    } else if (
      filter.cnic === "" &&
      filter.FormNo === "" &&
      filter.status !== ""
    ) {
      let Filter = AllReceipts?.filter((i) => {
        return i.receipt_status?.id == filter.status;
      });
      setPosts(Filter);
    } else {
      let body = {
        FormNo: null,
        cnic: "",
      };
      _FetchAllReceipt(body, onSuccess, onFailure);
    }
  };
  const reset = () => {
    setFilter({
      cnic: "",
      FormNo: "",
      status: "",
    });
  };
  const ToggleViewReceiptModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };
  //////////-------useEffects--------//////////////

  useEffect(() => {
    if (AllReceipts) {
      setPosts(AllReceipts);
    }
  }, [AllReceipts]);

  useEffect(() => {
    let body = {
      FormNo: null,
      cnic: "",
    };
    _FetchAllReceipt(body, onSuccess, onFailure);
    _FormStatus(onStatusSuccess, onStatusFailure);
  }, [Response]);
 
  return (
    <>
      <Headers />
      <ViewReceiptModal
        toggle={ToggleViewReceiptModal}
        modal={isViewModalOpen}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h3 className="mb-0">Request List</h3>
                  </Col>
                  <Col lg="2" md="2" sm="2">
                    <Button
                      color="primary"
                      size="md"
                      id="search"
                      // onClick={reset}
                      onClick={() => ToggleViewReceiptModal()}
                      // style={{ marginTop: "30px" }}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        {/* <i className="fas fa-undo"></i> */}
                        Form Request
                      </span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {/* <Col lg="3" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Status
                    </label>
                    <select
                      id="input-username"
                      className="form-control"
                      value={filter.status}
                      onChange={(e) =>
                        setFilter({ ...filter, status: e.target.value })
                      }
                    >
                      <option value="">Select Status</option>
                      {StatusResponse !== null && StatusResponse !== undefined
                        ? StatusResponse.map((status, index) => {
                            return (
                              <option key={status.id} value={status.id}>
                                {status.name}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </Col> */}
                  <Col lg="3" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search
                    </label>
                    <input
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      maxLength={13}
                      value={filter.cnic}
                      onChange={(e) =>
                        setFilter({ ...filter, cnic: e.target.value })
                      }
                    ></input>
                    <br />
                  </Col>
                  {/* <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Form No
                    </label>
                    <input
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      value={filter.FormNo}
                      onChange={(e) =>
                        setFilter({ ...filter, FormNo: e.target.value })
                      }
                    ></input>
                  </Col> */}
                  {/* <Col lg="3" md="4" sm="6">
                    <Button
                      color="info"
                      size="md"
                      id="search"
                      onClick={getReceiptByFields}
                      style={{ marginTop: "30px" }}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-search"></i>
                      </span>
                    </Button>
                    <Button
                      color="success"
                      size="md"
                      id="search"
                      onClick={reset}
                      style={{ marginTop: "30px" }}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-undo"></i>
                      </span>
                    </Button>
                    <br />
                  </Col> */}
                </Row>
                <TableViewPaymentReceipts
                  _FormCancelation={_FormCancelation}
                  onChangeNoOfRows={onChangeNoOfRows}
                  post={paginatedPosts}
                />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  AllReceipts: state.viewPaymentReceipt.AllReceipts,
  StatusResponse: state.viewPaymentReceipt.StatusResponse,
  Response: state.viewPaymentReceipt.Response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _FetchAllReceipt: (body, onSuccess, onFailure) =>
      dispatch(_FetchAllReceipt(body, onSuccess, onFailure)),
    _FormCancelation: (body, onSuccess, onFailure) =>
      dispatch(_FormCancelation(body, onSuccess, onFailure)),
    _FormStatus: (onSuccess, onFailure) =>
      dispatch(_FormStatus(onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(ViewPaymentReceipt);
