import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import PhoneInput from "react-phone-input-2";
import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";

const Filteration = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [disabledFields, setDisabledFields] = useState(true)
  useEffect(() => {
    if (location.state !== null && location.state !== undefined) {
      setState({
        ...state,
        fullName: location.state?.fullName,
        cnic: location.state?.cnic,
        phone: location.state?.phone,
        whatsAppNo: location.state?.whatsAppNo,
        email: location.state?.email,
        noOfApplication:
          location.state?.applicationRequests[0]?.NumberOfApplication,
      });
      
    }
  }, [location.state]);

  const onSuccess = () => {
    // setState({...state,  bankName:"",
    // bankDescription:""})

    swal({
      title: "Success!",
      text: "Customer Added Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/CustomerList";
        history.push(path);
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };

  // let dataInArrayForPaginaion = [];
  // useEffect(() => {
  //   setPosts(null);
  //   setPageNumber(1);
  //   if (props.Client !== null && props.Client !== undefined) {
  //     for (let i = 0; i < props.Client.length; i++) {
  //       dataInArrayForPaginaion.push(props.Client[i]);
  //       setPosts(props.Client);
  //     }
  //   } else {
  //   }
  // }, [props.Client]);

  const [state, setState] = useState({
    fullName: "",
    cnic: "",
    email: "",
    phone: "",
    whatsAppNo: "",
    noOfApplication: "",
    profilePhoto: "",
  });

  const onChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  //Start Validation
  const CheckFields = (name) => {
    if (name === "fullName") {
      setError({
        ...error,
        fullNameError: validate("required", state.fullName),
      });
    } else if (name === "cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", state.cnic),
      });
     
    } else if (name === "phone") {
      setError({
        ...error,
        phoneError: validate("phoneNumber", state.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", state.email),
      });
    } else if (name === "noOfApplication") {
      setError({
        ...error,
        noOfApplicationError: validate("required", state.noOfApplication),
      });
    }
  };

  const [error, setError] = useState({
    phoneError: "",
    whatsAppNoError: "",
    emailError: "",
    cnicError: "",
    fullNameError: "",
    noOfApplicationError: "",
  });
 

  const submit = () => {
    setError({
      ...error,
      fullNameError: validate("required", state.fullName),
      cnicError: validate("CNIC", state.cnic),
      emailError: validate("email", state.email),
      phoneError: validate("phoneNumber", state.phone),
      whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
      noOfApplicationError: validate("required", state.noOfApplication),
    });
    if (
      location.state !== null &&
      location.state !== undefined &&
      state.fullName!=="" && state.cnic!=="" && state.phone!=="" && state.whatsAppNo!=="" && state.noOfApplication!=="" 
      // error.fullNameError == null &&
      // error.cnicError == null &&
      // error.noOfApplicationError == null &&
      // error.emailError == null &&
      // error.phoneError == null &&
      // error.whatsAppNoError == null
    ) { 
      let id = location.state.id;
      let body = {
        fullName: state.fullName,
        cnic: state.cnic,
        Email: state.email,
        mobile:  state.phone.includes("+")?state.phone:`+${state.phone}`,
        whatsAppNo:  state.whatsAppNo.includes("+")?state.whatsAppNo:`+${state.whatsAppNo}`,
        noOfApplication: state.noOfApplication.toString(),
      };
      props.EditCustomerMiddleware(id, body, onSuccesEdit, onFailureEdit);
      // props.InsertBankMiddleware(body, onSuccess, onFailure);
    }
    //  else if (
    //   state.fullName!=="" && state.cnic!=="" && state.phone!=="" && state.whatsAppNo!=="" && state.noOfApplication!=="" 
    //   // error.fullNameError == null &&
    //   // error.emailError == null &&
    //   // error.cnicError == null &&
    //   // error.phoneError == null &&
    //   // error.noOfApplicationError == null &&
    //   // error.whatsAppNoError == null
    // ) {
    //   let body = {
    //     fullName: state.fullName,
    //     cnic: state.cnic,
    //     email: state.email,
    //     mobile:  state.phone.includes("+")?state.phone:`+${state.phone}`,
    //     whatsAppNo:  state.whatsAppNo.includes("+")?state.whatsAppNo:`+${state.whatsAppNo}`,
    //     profilePhoto: "abc.png",
    //     noOfApplication: parseInt( state.noOfApplication),
    //   };
    //   props.InsertCustomerMiddleware(body, onSuccess, onFailure);
    // }
  };
  const onSuccesEdit = () => {
    setState({ ...state, bankName: "", bankDescription: "" });

    swal({
      title: "Success!",
      text: "Customer Edited Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/CustomerList";
        history.push(path);
      }
    });
  };
  const onFailureEdit = (error) => {
    swal("Sorry!", error.message, "error");
  };

  return (
    <CardHeader className="border-0">
      <Row>
        <Col lg="2" md="2" sm="6">
          <h2>Issue Form</h2>
        </Col>
        <Col lg="8" md="8" sm="6"></Col>
        <Col lg="2" md="2" sm="6"></Col>
      </Row>
      <hr />
      <CardBody>
        <form>
          <Row>
          <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Cnic*
              </label>
              <input
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={13}
                onBlur={() => CheckFields("cnic")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={state.cnic}
                onChange={(e) => onChange(e.target.value, "cnic")}
                autoComplete="off"
              ></input>
              {error.cnicError !== "" && error.cnicError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.cnicError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Name*
              </label>
              <input
                type="text"
                onBlur={() => CheckFields("fullName")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={state.fullName}
                onChange={(e) => onChange(e.target.value, "fullName")}
                autoComplete="off"
                onKeyPress={(event) => {
                  if (!/[a-z, A-Z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={disabledFields}
              ></input>
              {error.fullNameError !== "" && error.fullNameError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.fullNameError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
           
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Email*
              </label>
              <input
                type="text"
                onBlur={() => CheckFields("email")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={state.email}
                onChange={(e) => onChange(e.target.value, "email")}
                autoComplete="off"
                disabled={disabledFields}
              ></input>
              {error.emailError !== "" && error.emailError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.emailError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="4" md="4" sm="6">
                <label className="form-control-label" for="input-username">
                  Phone No
                </label>
                <PhoneInput
                   placeholder="+92"
                  
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "phone")}
                  onBlur={() => CheckFields("phone")}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                    height:"100%"
                    
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="100%"
                  
                  //  containerStyle={{border:"none"}}
                  countryCodeEditable={false}
                  country={"pk"}
                  value={state.phone?state.phone:""}
                  disabled={disabledFields}
                />

                {error.phoneError !== "" && error.phoneError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.phoneError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                  Whatsapp No
                </label>
                <PhoneInput
                  placeholder="+92"
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "whatsAppNo")}
                  onBlur={() => CheckFields("whatsAppNo")}
                 
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                    height:"100%"
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="true"
                  countryCodeEditable={false}
                  country={"pk"}
                  value={state.whatsAppNo?state.whatsAppNo:""}
                  disabled={disabledFields}
                />

                {error.whatsAppNoError !== "" &&
                  error.whatsAppNoError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.whatsAppNoError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
              </Col>

            {/* <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                WhatsApp No*
              </label>
              <input
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={
                  location.state !== null && location.state !== undefined
                    ? 13
                    : 12
                }
                onBlur={() => CheckFields("whatsAppNo")}
                id="input-username"
                className="form-control"
                placeholder="92XXXXXXXXXX"
                value={state.whatsAppNo}
                onChange={(e) => onChange(e.target.value, "whatsAppNo")}
                autoComplete="off"
              ></input>
              {error.whatsAppNoError !== "" && error.whatsAppNoError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.whatsAppNoError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
           
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Phone No*
              </label>
              <input
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={
                  location.state !== null && location.state !== undefined
                    ? 13
                    : 12
                }
                onBlur={() => CheckFields("phone")}
                id="input-username"
                className="form-control"
                placeholder="92XXXXXXXXXX"
                value={state.phone}
                onChange={(e) => onChange(e.target.value, "phone")}
                autoComplete="off"
              ></input>
              {error.phoneError !== "" && error.phoneError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.phoneError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col> */}
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                No of Application
              </label>
              <input
                type="text"
                onBlur={() => CheckFields("noOfApplication")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={state.noOfApplication}
                onChange={(e) => onChange(e.target.value, "noOfApplication")}
                autoComplete="off"
               // disabled={disabledFields}
              ></input>
              {error.noOfApplicationError !== "" &&
                error.noOfApplicationError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.noOfApplicationError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
            </Col>
            <Col lg="4" md="4" sm="6"></Col>
          </Row>
          <Row>
            <Col lg="4" md="4" sm="6">
              <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={submit}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       Submit
                      </span>
                    </Button>
              </Col>

            </Row>
           
            
           
        
        </form>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
