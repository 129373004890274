import { Table } from "reactstrap";

const TableFormList = ({ formlist }) => {
  return (
    <div>
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Deposit No.</th>
            <th scope="col">Form Status</th>
          </tr>
        </thead>
        <tbody>
          {formlist !== null && formlist !== undefined ? (
            formlist?.items?.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.depositNo}</td>
                  <td>{item.formStatus}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableFormList;
