import {
  FETCH_STAFF,
  FETCH_STAFF_SUCCESS,
  FETCH_STAFF_FAILURE,
  FETCH_BRANCHS_BY_ID,
  FETCH_BRANCHS_BY_ID_SUCCESS,
  FETCH_BRANCHS_BY_ID_FAILURE,
  STAFF_SIGNUP_FOR_MANAGER,
  STAFF_SIGNUP_FOR_MANAGER_SUCCESS,
  STAFF_SIGNUP_FOR_MANAGER_FAILURE,
  DELETE_STAFF,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAILURE,
  EDIT_STAFF,
  EDIT_STAFF_SUCCESS,
  EDIT_STAFF_FAILURE,
} from "../constant.js";


export class FetchAllStaff {
  static Fetch() {
    return {
      type: FETCH_STAFF,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_STAFF_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_STAFF_FAILURE,
      error,
    };
  }
}

export class FetchBranchs {
  static Fetch() {
    return {
      type: FETCH_BRANCHS_BY_ID,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_BRANCHS_BY_ID_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_BRANCHS_BY_ID_FAILURE,
      error,
    };
  }
}

export class StaffSignUp {
  static SignUp() {
    return {
      type: STAFF_SIGNUP_FOR_MANAGER,
    };
  }
  static SignUpSuccess(response) {
    return {
      type: STAFF_SIGNUP_FOR_MANAGER_SUCCESS,
      payload: response,
    };
  }
  static SignUpFailure(error) {
    return {
      type: STAFF_SIGNUP_FOR_MANAGER_FAILURE,
      error,
    };
  }
}

export class DeleteStaff {
  static Delete() {
    return {
      type: DELETE_STAFF,
    };
  }
  static DeleteSuccess(response) {
    return {
      type: DELETE_STAFF_SUCCESS,
      payload: response,
    };
  }
  static DeleteFailure(error) {
    return {
      type: DELETE_STAFF_FAILURE,
      error,
    };
  }
}

export class EditStaff {
  static Edit() {
    return {
      type: EDIT_STAFF,
    };
  }
  static EditSuccess(response) {
    return {
      type: EDIT_STAFF_SUCCESS,
      payload: response,
    };
  }
  static EditFailure(error) {
    return {
      type: EDIT_STAFF_FAILURE,
      error,
    };
  }
}
