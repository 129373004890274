

import { 
  AGENTLIST,AGENTLIST_SUCCESS,AGENTLIST_FAILURE,
  AGENTDELETE,AGENTDELETE_FAILURE,AGENTDELETE_SUCCESS,
 
 
} from "../constant.js";



export class AgentDeleteDetail {
  static AgentDelete() {
    return {
      type: AGENTDELETE
  }
}
  static AgentDelete_Success(response) {
    return {
      type: AGENTDELETE_SUCCESS,
      payload: response,
    };
  }
  static AgentDelete_Failure(error) {
    return {
      type: AGENTDELETE_FAILURE,
      error,
    };
  }
 
}

export class AgentListDetail {
  static AgentList() {
    return {
      type: AGENTLIST
  }
}
  static AgentList_Success(response) {
    return {
      type: AGENTLIST_SUCCESS,
      payload: response,
    };
  }
  static AgentList_Failure(error) {
    return {
      type: AGENTLIST_FAILURE,
      error,
    };
  }
 
}

