import React, { useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Container,
  Card,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import swal from "sweetalert";
import { PrintExcelMiddleware, } from "../middleware";
import { BASEURL } from "config/api/URL";

const Reports = ({ _PrintExcelMiddleware }) => {
  let token = localStorage.getItem("auth")
  const [CNIC, setCNIC] = useState("");

  const onSuccess = (url) => {
    window.open(`${url}`, "_blank")
  };
  const onFailure = (msg) => {
    swal("Sorry!", msg.message, "error");
  };

  const handlePrintExcel = () => {
    _PrintExcelMiddleware(CNIC, onSuccess, onFailure);
  };

  const handlePrintPDF = () => {
    window.open(`${BASEURL}uploadfile/user-Detail-pdf/${CNIC}/${token}`, "_blank")
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h1 className="mb-0">Reports</h1>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h2 className="mb-3">Customer Report</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      CNIC No.
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter CNIC number"
                      value={CNIC}
                      maxLength={13}
                      onChange={(e) =>
                        setCNIC(e.target.value)
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                    ></input>
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12" style={{ marginTop: "30px" }}>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      onClick={handlePrintExcel}
                    >
                      <span className="btn-inner--icon"><i className="fas fa-file-excel p-1" aria-hidden="true" /> Excel</span>
                    </Button>
                    <Button
                      style={{ backgroundColor: "red", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      onClick={handlePrintPDF}
                    >
                      <span className="btn-inner--icon"><i className="far fa-file-pdf p-1"></i> PDF</span>
                    </Button>
                  </Col>
                </Row>
                <br />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _PrintExcelMiddleware: (cnic, onSuccess, onFailure) =>
      dispatch(PrintExcelMiddleware(cnic, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Reports);
