export const STAFF =  "STAFF";
export const STAFF_SUCCESS =  "STAFF_SUCCESS";
export const STAFF_FAILURE =  "STAFF_FAILURE";
export const STAFF_PATH = "real-estate/agent-create-agentStaff-form";

export const STAFFEDIT =  "STAFFEDIT";
export const STAFFEDIT_SUCCESS =  "STAFFEDIT_SUCCESS";
export const STAFFEDIT_FAILURE =  "STAFFEDIT_FAILURE";
export const STAFFEDIT_PATH = "real-estate/agent-staff-profile-for-admin-and-agent";


