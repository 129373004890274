import React from "react";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import ComponentToPrint from "./ComponentToPrint";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../../assets/img/black variation.png"
import { connect } from "react-redux";
import { showAgent, GetUser, GetData, LeadId } from "../middleware";
import swal from "sweetalert";
import { Input } from "reactstrap";
import { CardBody, Button, CardHeader, Card, Row, Col } from "reactstrap";
import Select from "react-select";

function Personal(props) {
  let cnic = "abc";
  function getprops(props) {
    cnic = props.cnic;
  }
  const componentRef = useRef();
  const history = useHistory();
  const [getlead, setlead] = useState({});

  useEffect(() => {
    props.showPlotProject(onSuccess, onFailure);
    props.showPlotType(onSuccess, onFailure);
    props.showPlotSize(onSuccess, onFailure);
  }, [true]);

  const onSuccess = () => {};
  const onFailure = () => {};

  const [error, setError] = useState({
    plotTypeError: null,
    fatherNameError: null,
    plotProjectError: null,
    emailError: null,
    plotSizeError: null,
    passwordError: null,
    CNICError: null,
    whatsappNumberError: null,
    nameError: null,
    lastError: null,
    addressError: null,
    AddOError: null,
    ProError: null,
    OError: null,
    DError: null,
    RError: null,
    TError: null,
    KError: null,
    CError: null,
    imageError: null,
    NationError: null,
    fullNameError: null,
    FfullNameError: null,
    ContactNumber: null,
    DOB: null,
    ContactNumberError: null,
    phoneNumberError: null,
    NationalityError: null,
    NomineeCnicError: null,
    NomineePhoneError: null,
    NomineeNameError: null,
    NomineeRelationError: null,
  });

  var maxBirthdayDate = new Date();
  maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);

  const CheckFields = (name) => {
    if (name === "cnic") {
      setError({
        ...error,
        CNICError: validate("CNIC", getlead.cnic),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", getlead.email),
      });
    } else if (name === "name") {
      setError({
        ...error,
        nameError: validate("fullName", getlead.name),
      });
    } else if (name === "fatherName") {
      setError({
        ...error,
        fatherNameError: validate("fullName", getlead.fatherName),
      });
    } else if (name === "mobile") {
      setError({
        ...error,
        phoneNumberError: validate("ContactNumber", getlead.mobile),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", getlead.email),
      });
    } else if (name === "address") {
      setError({
        ...error,
        addressError: validate("fullName", getlead.address),
      });
    } else if (name === "whatsappNumber") {
      setError({
        ...error,
        whatsappNumberError: validate("ContactNumber", getlead.whatsappNumber),
      });
    
    } else if (name === "ProjectId") {
      setError({
        ...error,
        plotProjectError: validate("required", getlead.ProjectId),
      });
    } else if (name === "ProjectId") {
      setError({
        ...error,
        plotProjectError: validate("required", getlead.ProjectId),
      });
    } else if (name === "plotTypeId") {
      setError({
        ...error,
        plotSizeError: validate("required", getlead.plotTypeId),
      });
    } else if (name === "plotCategoryId") {
      setError({
        ...error,
        plotTypeError: validate("required", getlead.plotCategoryId),
      });
    }
  };

  const OnChange = (value, name) => {
    setlead({
      ...getlead,
      [name]: value,
    });
  };
  const [state, setState] = useState({});
  const OnChangeDropDown = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  // const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onSuccessInsert = () => {
    // swal("Succesful!", "Successfully Inserted", "success");
    // setlead("")
    swal({
      title: "Succesful!",
      text: "Successfully Inserted",
      type: "success",
    }).then(function (isConfirm) {
      if (isConfirm) {
        // history.push({
        //   pathname: "/admin/createPaymentReceipt",
        //   state: getlead,
        // });
      } else {
      }
    });
  };
  const OnFailureInsert = () => {
    swal("Sorry!", "Something went wrong please contact to admin", "error");
  };

  //---- Checkbox Function to Lock the Entering
  const Addd = () => {
    setError({
      ...error,
      CNICError: validate("CNIC", getlead.cnic),
      nameError: validate("fullName", getlead.name),
      phoneNumberError: validate("ContactNumber", getlead.mobile),
      fatherNameError: validate("fullName", getlead.fatherName),
      emailError: validate("email", getlead.email),
      addressError: validate("fullName", getlead.address),
      whatsappNumberError: validate("ContactNumber", getlead.whatsappNumber),
     
      plotProjectError: validate("required", getlead.ProjectId),
      plotSizeError: validate("required", getlead.plotTypeId),
      plotTypeError: validate("required", getlead.plotCategoryId),
    });

    if (
      getlead.name == "" ||
      getlead.name == null ||
      getlead.cnic == "" ||
      getlead.cnic == null ||
      getlead.mobile == "" ||
      getlead.mobile == null ||
      getlead.fatherName == "" ||
      getlead.fatherName == null ||
      getlead.email == "" ||
      getlead.email == null ||
      getlead.address == "" ||
      getlead.address == null ||
      getlead.whatsappNumber == "" ||
      getlead.whatsappNumber == null ||
      getlead.plotQuantity == "" ||
      getlead.plotQuantity == null ||
      getlead.ProjectId == "" ||
      getlead.ProjectId == null ||
      getlead.plotTypeId == "" ||
      getlead.plotTypeId == null ||
      getlead.plotCategoryId == "" ||
      getlead.plotCategoryId == null
    ) {
      swal("Sorry!", "Please Fill all required fields", "error");
    } else {
      props.InsertCustomerDetail(getlead, onSuccessInsert, OnFailureInsert);
    }
  };
  const [project, setProject] = useState([null]);
  useEffect(() => {
    if (props.Project !== null && props.Project !== undefined) {
      let arr = [];
      for (let i = 0; i < props.Project.length; i++) {
        let obj = {
          value: props.Project[i].id,
          label: props.Project[i].title,
        };
        arr.push(obj);
      }

      setProject(arr);
    }
  }, [props.Project]);
  const [size, setsize] = useState([null]);
  useEffect(() => {
    if (props.Size !== null && props.Size !== undefined) {
      let arr = [];
      for (let i = 0; i < props.Size.length; i++) {
        let obj = {
          value: props.Size[i].id,
          label: props.Size[i].title,
        };
        arr.push(obj);
      }

      setsize(arr);
    }
  }, [props.Size]);
  const [type, setType] = useState([null]);
  useEffect(() => {
    if (props.Type !== null && props.Type !== undefined) {
      let arr = [];
      for (let i = 0; i < props.Type.length; i++) {
        let obj = {
          value: props.Type[i].id,
          label: props.Type[i].title,
        };
        arr.push(obj);
      }

      setType(arr);
    }
  }, [props.Type]);
  const [disabledd, setdisabledd] = useState(true);
  useEffect(() => {
    if (props.Data !== null && props.Data !== undefined) {
      setdisabledd(false);
      setState({ ...state, FormNo: props.Data });
    }
  }, [props.Data]);

  return (
    <div>
      <ComponentToPrint
        ref={componentRef}
        FormNo={state.FormNo}
        name={getlead.name}
        email={getlead.email}
        father={getlead.fatherName}
        mobile={getlead.mobile}
        cnic={getlead.cnic}
        passport={getlead.passportNo}
        address={getlead.address}
        drivingLicense={getlead.licenseNo}
        profession={getlead.Profession}
        organization={getlead.organization}
        whatsappNumber={getlead.whatsappNumber}
        date={getlead.DOB}
        nationality={getlead.Nationality}
        plotType={state.plotType}
        plotProject={state.plotProject}
        plotSize={state.plotSize}
        plotQuantity={getlead.plotQuantity}
      />
      <Card style={{ margin: "10px" }} className="shadow">
        <CardHeader className="border-0">
       
          <Row>
          <Col lg="4" md="4" sm="6">
          <img height="120px" width="120px" src={Logo} />
          </Col>
<Col lg="8" md="8" sm="6">  
<h1 className="mb-0" style={{color:"#d69929"}}>Registration Form</h1>

</Col>


          </Row>
        
          </CardHeader>

        <CardHeader className="border-0">
       
          <h3 className="mb-0"> Applicant (درخواست گزار) </h3>
          
        </CardHeader>

        <CardBody>
          <form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Name * (نام)
                  </label>

                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("name")}
                    value={getlead.name}
                    onChange={(e) => OnChange(e.target.value, "name")}
                  ></input>
                  {error.nameError !== "" && error.nameError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.nameError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
                <Col lg="6" md="5" sm="5">
                  <label className="form-control-label" for="input-username">
                    Father/Husband Name * (والد/شوہر کا نام)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("fatherName")}
                    value={getlead.fatherName}
                    onChange={(e) => OnChange(e.target.value, "fatherName")}
                  ></input>
                  {error.fatherNameError !== "" &&
                    error.fatherNameError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.fatherNameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                </Col>
                <Col lg="6" md="4" sm="4">
                  <label className="form-control-label" for="input-username">
                    Email * (ای میل)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("email")}
                    value={getlead.email}
                    onChange={(e) => OnChange(e.target.value, "email")}
                  ></input>
                  {error.emailError !== "" && error.emailError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.emailError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
                <Col lg="4" md="4" sm="4">
                  <label className="form-control-label" for="input-username">
                    Mobile * (موبائل)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={11}
                    placeholder=""
                    onBlur={() => CheckFields("mobile")}
                    value={getlead.mobile}
                    onChange={(e) => OnChange(e.target.value, "mobile")}
                  ></input>
                  {error.phoneNumberError !== "" &&
                    error.phoneNumberError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.phoneNumberError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                </Col>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    CNIC * (شناختی کارڈ)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("cnic")}
                    maxLength={13}
                    value={getlead.cnic}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => OnChange(e.target.value, "cnic")}
                  ></input>
                  {error.CNICError !== "" && error.CNICError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.CNICError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
                {/* <Col lg="2" md="2" sm="2">
                    <br />

                    <Button
                      className="mt-2"
                      color="success"
                      id="search"
                      onClick={copy}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        <i class="fas fa-copy"></i>
                      </span>
                    </Button>
                  </Col> */}
                <Col lg="12" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Passport No. (پاسپورٹ نمبر)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    value={getlead.passportNo}
                    onChange={(e) => OnChange(e.target.value, "passportNo")}
                  ></input>
                </Col>
              </Row>

              <Row>
                <Col lg="12" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Address (Residence) * (پتہ (رہائش))
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("address")}
                    value={getlead.address}
                    onChange={(e) => OnChange(e.target.value, "address")}
                  ></input>
                  {error.addressError !== "" && error.addressError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.addressError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
              </Row>

              <Row>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Organization (تنظیم)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    value={getlead.organization}
                    onChange={(e) => OnChange(e.target.value, "organization")}
                  ></input>
                </Col>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Driving license (ڈرائیونگ لائسنس)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    value={getlead.licenseNo}
                    onChange={(e) => OnChange(e.target.value, "licenseNo")}
                  ></input>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Whatsapp Number * (واٹس ایپ نمبر)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    maxLength={11}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => CheckFields("whatsappNumber")}
                    value={getlead.whatsappNumber}
                    onChange={(e) => OnChange(e.target.value, "whatsappNumber")}
                  ></input>
                  {error.whatsappNumberError !== "" &&
                    error.whatsappNumberError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.whatsappNumberError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                </Col>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Date of Birth (پیدائش کی تاریخ)
                  </label>
                  <input
                    type="date"
                    id="input-username"
                    className="form-control"
                    placeholder="DD-MM-YYYY"
                    //  max={abc}
                    //  onClick={checker}
                    onBlur={() => CheckFields("DOB")}
                    value={getlead.DOB}
                    onChange={(e) => OnChange(e.target.value, "DOB")}
                  ></input>
                  {error.DError !== "" && error.DError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.DError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}

                  {/* {(difff < 6575) ? (
                              <small style={{ marginTop: "2px" }}>
                                <span style={{ color: "red" }}>
                                  Age Less than 18 years{" "}
                                  <i className="fas fa-exclamation-circle"></i>
                                </span>
                              </small>
                            ) : ''}
         */}
                </Col>
                <Col lg="6" md="6" sm="6">
                  <label className="form-control-label" for="input-username">
                    Nationality (قومیت)
                  </label>
                  <input
                    type="text"
                    id="input-username"
                    className="form-control"
                    placeholder=""
                    onBlur={() => CheckFields("Nationality")}
                    value={getlead.Nationality}
                    onChange={(e) => OnChange(e.target.value, "Nationality")}
                  ></input>
                  {error.NationError !== "" && error.NationError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.NationError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                </Col>
              </Row>
              <br />
            </div>
          </form>
        </CardBody>
      </Card>
      <Card style={{ margin: "10px" }} className="shadow">
        <CardHeader className="border-0">
          <h3 className="mb-0"> Plot Details</h3>
          <CardBody>
            <form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <label className="form-control-label" for="input-username">
                      Project Name
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      options={project}
                      id="exampleFormControlSelect1"
                      type="select"
                      onBlur={() => CheckFields("ProjectId")}
                      onChange={(e) => {
                        OnChange(e.value, "ProjectId");
                        OnChangeDropDown(e.label, "plotProject");
                      }}
                      // onChange={(e) => OnChangeDropDown(e.label, "plotSize")}
                    />
                    {error.plotProjectError !== "" &&
                      error.plotProjectError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.plotProjectError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>

                  <Col lg="6" md="6" sm="6">
                    <label className="form-control-label" for="input-username">
                      Plot Size
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      options={size}
                      id="exampleFormControlSelect1"
                      type="select"
                      onBlur={() => CheckFields("plotTypeId")}
                      onChange={(e) => {
                        OnChange(e.value, "plotTypeId");
                        OnChangeDropDown(e.label, "plotSize");
                      }}
                      // onChange={(e) => OnChangeDropDown(e.label, "plotSize")}
                    />
                    {error.plotSizeError !== "" &&
                      error.plotSizeError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.plotSizeError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                    {/* <Input
                      id="exampleFormControlSelect1"
                      type="select"
                       onChange={(e) => OnChange(e.target.value, "plotTypeId")}
                      onChange={(e) => OnChangeDropDown(e.target.value, "plotSize")}
                    
                    
                    >
                      <option
                            
                            value={""}
                          >
                            {"Select"}
                          </option>
                      {props.Size !== null &&
                        props.Size !== undefined &&
                        props.Size.map((source) => {
                          return (
                            <option
                              key={source.id}
                              value={source.id}
                              value1={source.id}
                            >
                              {source.title}
                            </option>
                          );
                        })}
                    </Input> */}
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <label className="form-control-label" for="input-username">
                      Plot Type
                    </label>

                    {/* <Input
                    
                      id="exampleFormControlSelect1"
                      type="select"
                   
                      onChange={(e) => OnChange(e.target.value,"plotCategoryId")}
                    >
                        <option
                            
                              value={""}
                            >
                              {"Select"}
                            </option> */}
                    <Select
                      onBlur={() => CheckFields("plotCategoryId")}
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      options={type}
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={(e) => {
                        OnChange(e.value, "plotCategoryId");
                        OnChangeDropDown(e.label, "plotType");
                      }}
                      // onChange={(e) => OnChange(e.value, "plotCategoryId")}
                    />
                    {error.plotTypeError !== "" &&
                      error.plotTypeError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.plotTypeError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                    {/* {props.Type !== null &&
                        props.Type !== undefined &&
                        props.Type.map((source) => {
                          return (
                            <option
                              key={source.id}
                              value={source.id}
                            >
                              {source.title}
                            </option>
                          );
                        })} */}
                    {/* </Input> */}
                  </Col>
                  {/* <Col lg="6" md="6" sm="6">
                    <label className="form-control-label" for="input-username">
                      Quantity
                    </label>

                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      onBlur={() => CheckFields("quantity")}
                      onChange={(e) => OnChange(e.target.value, "plotQuantity")}
                    ></input>
                    {error.quantityError !== "" &&
                      error.quantityError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.quantityError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col> */}

                  <br />
                </Row>
                <br />
                <Row>
                <Col lg="3" md="4" sm="6" xs="8">
                    <Button style={{backgroundColor:"#015652", color:"white"}}  onClick={Addd} size="sm">
                      {/* <i class="fas fa-print"></i> */}
                      Save as Draft
                    </Button>
                 
                    <Button style={{backgroundColor:"#015652", color:"white"}}  onClick={Addd} size="sm">
                      {/* <i class="fas fa-print"></i> */}
                      Submit
                    </Button>
                    {/* <Button
                      style={{backgroundColor:"#015652", color:"white"}} 
                      disabled={disabledd}
                      onClick={handlePrint}
                      size="sm"
                    >
                      Print &nbsp;
                      <i class="fas fa-print"></i>
                    </Button> */}
                  </Col>
                </Row>
              </div>
            </form>
          </CardBody>
        </CardHeader>
      </Card>
    </div>
  );
}
export default Personal;
