import { connect } from "react-redux";

import SignUp from "./SignUp.js";
import { SignUpMiddleware } from "../middleware";
const mapStateToProps = (state) => ({
  // isLoading: state.signUp.isLoading,
  // isError: state.signUp.isError,
  // Data: state.signUp.Data,
  // Error: state.signUp.error,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // SignUpMiddleware: (body, OnSuccess, OnFailure) =>
    //   dispatch(SignUpMiddleware(body, OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
