export const FETCH_STAFF = "FETCH_STAFF";
export const FETCH_STAFF_SUCCESS = "FETCH_STAFF_SUCCESS";
export const FETCH_STAFF_FAILURE = "FETCH_STAFF_FAILURE";
export const FETCH_STAFF_PATH = "staff-profile/staff-list/for-admin";

export const DELETE_STAFF = "DELETE_STAFF";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_FAILURE = "DELETE_STAFF_FAILURE";
export const DELETE_STAFF_PATH = "staff-profile";

export const EDIT_STAFF = "EDIT_STAFF";
export const EDIT_STAFF_SUCCESS = "EDIT_STAFF_SUCCESS";
export const EDIT_STAFF_FAILURE = "EDIT_STAFF_FAILURE";
export const EDIT_STAFF_PATH = "staff-profile/update-for-admin";
