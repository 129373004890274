import { connect } from "react-redux";

import StaffList from "./StaffList";
import { GetAgentListMiddleware,
  DeleteAgentStaffMiddleware,
  AgentStaffVerifyMiddleware
} from "../middleware";


const mapStateToProps = (state) => ({

  responseAgent:state.agentStaffListAdmin.responseAgent,
  del:state.agentStaffListAdmin.del,
  verify:state.agentStaffListAdmin.verify,




 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    
    DeleteAgentStaffMiddleware:(id,onSuccess,onFailure)=>
    dispatch(DeleteAgentStaffMiddleware(id,onSuccess,onFailure)),
    GetAgentListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(GetAgentListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)),
      AgentStaffVerifyMiddleware:(id, onSuccess,onFailure)=>
      dispatch(AgentStaffVerifyMiddleware(id,onSuccess,onFailure)),
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(StaffList);
