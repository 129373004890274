import {
  BANK_REGISTRATION,
  BANK_REGISTRATION_SUCCESS,
  BANK_REGISTRATION_FAILURE,
  UPLOAD_MULTI_FILE,
  UPLOAD_MULTI_FILE_SUCCESS,
  UPLOAD_MULTI_FILE_FAILURE,
} from "../constant";
const INITIAL_STATE = {
  bankRegRes: null,
  uploadRes: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case UPLOAD_MULTI_FILE:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        uploadRes: null,
        error: {},
      };
    case UPLOAD_MULTI_FILE_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        uploadRes: action.payload,
      };
    case UPLOAD_MULTI_FILE_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case BANK_REGISTRATION:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        bankRegRes: null,
        error: {},
      };
    case BANK_REGISTRATION_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        bankRegRes: action.payload,
      };
    case BANK_REGISTRATION_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
