import { connect } from "react-redux";

import {
  InsertCustomerDetail,
  showPlotType,
  InsertCustomerDraftDetail,
  showDraftCustomer,
  UpdateCustomerMiddleware,
  UpdateDraftCustomerMiddleware,
  VerifyByCNIC,
} from "../middleware";

import Personal from "./Personal";

const mapStateToProps = (state) => ({
  Data: state.CustomerMembershipForm.Data,
  Type: state.CustomerMembershipForm.Type,
  Draft: state.CustomerMembershipForm.Draft,
  DraftData: state.CustomerMembershipForm.DraftData,
  Update: state.CustomerMembershipForm.Update,
  CNIC_Response: state.CustomerMembershipForm.CNIC_Response,
  DraftUpdate: state.CustomerMembershipForm.DraftUpdate,
  // Project:state.membershipForm.Project,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    UpdateCustomerMiddleware: (id, body, onSuccess, onFailure) =>
      dispatch(UpdateCustomerMiddleware(id, body, onSuccess, onFailure)),
    UpdateDraftCustomerMiddleware: (body, onSuccess, onFailure) =>
      dispatch(UpdateDraftCustomerMiddleware(body, onSuccess, onFailure)),
    InsertCustomerDraftDetail: (body, OnSuccess, OnFailure) =>
      dispatch(InsertCustomerDraftDetail(body, OnSuccess, OnFailure)),
    InsertCustomerDetail: (body, OnSuccess, OnFailure) =>
      dispatch(InsertCustomerDetail(body, OnSuccess, OnFailure)),
    showPlotType: (OnSuccess, OnFailure) =>
      dispatch(showPlotType(OnSuccess, OnFailure)),
    showDraftCustomer: (id, OnSuccess, OnFailure) =>
      dispatch(showDraftCustomer(id, OnSuccess, OnFailure)),
    _VerifyByCNIC: (body, OnSuccess, OnFailure) =>
      dispatch(VerifyByCNIC(body, OnSuccess, OnFailure)),
    // showPlotProject: (OnSuccess, OnFailure) =>
    // dispatch(showPlotProject(OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Personal);
