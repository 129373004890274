import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  Label,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import validate from "components/Utilities Component/ValidationWrapper";
import Logo from "assets/img/whiteee1.png";
import { connect } from "react-redux";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { ForgotPassword } from "../middleware";

const ForgetPassword = ({ _ForgotPassword }) => {
  let history = useHistory();
  const location = useLocation();
  let string = location?.state?.string;
  const [body, setBody] = useState({
    email: "",
  });

  const [error, setError] = useState({
    emailError: "",
  });
  const CheckFields = (name) => {
    if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", body.email),
      });
    }
  };
  const onSuccess = () => {
    swal({
      // title: "Congratulations!",
      text: "Password successfully sent on email and on your mobile number",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      let path = "/auth/cuCodeVerify";
      history.push({
        pathname: path,
        state: { string: string, email: body.email },
      });
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const handleDispatch = () => {
    if (body.email !== "") {
      _ForgotPassword(body, onSuccess, onFailure);
    }
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="customLogin">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img height="150px" width="150px" src={Logo} /> <br />
              <h3 style={{ color: "white" }}>
                <b>Forgot Password</b>
              </h3>
            </div>
            <Form role="form">
              <Label
                style={{ color: "white" }}
                className="form-control-label"
                for="input-username"
              >
                To verify your account
              </Label>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Enter your email here"
                    type="email"
                    onBlur={() => CheckFields("email")}
                    value={body.email}
                    onChange={(e) =>
                      setBody({ ...body, email: e.target.value })
                    }
                  />
                </InputGroup>
                {error.emailError !== "" && error.emailError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.emailError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  onClick={handleDispatch}
                >
                  Submit
                </Button>
              </div>
            </Form>

            <Row className="mt-3 text-center">
              <Col xs="12">
                <a
                  className="text-light"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let path =
                      // string == "STAFF" ? "/auth/staffLogin" : "/auth/login";
                      string == "STAFF"
                        ? "/auth/staffLogin"
                        : string == "AGENT"
                        ? "/auth/agentLogin"
                        : "/auth/login";
                    history.push({
                      pathname: path,
                    });
                  }}
                >
                  <small style={{ color: "white" }}>Back to Login Page</small>
                </a>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _ForgotPassword: (body, onSuccess, onFailure) =>
      dispatch(ForgotPassword(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(ForgetPassword);
