import {
  FETCH_ADMIN_REQ_LIST,
  FETCH_ADMIN_REQ_LIST_SUCCESS,
  FETCH_ADMIN_REQ_LIST_FAILURE,
  FETCH_MANAGER_LIST,
  FETCH_MANAGER_LIST_SUCCESS,
  FETCH_MANAGER_LIST_FAILURE,
  FETCH_STAFF_LIST,
  FETCH_STAFF_LIST_SUCCESS,
  FETCH_STAFF_LIST_FAILURE,
  FETCH_AGENT_LIST,
  FETCH_AGENT_LIST_SUCCESS,
  FETCH_AGENT_LIST_FAILURE,
  FETCH_AGENT_STAFF_LIST,
  FETCH_AGENT_STAFF_LIST_SUCCESS,
  FETCH_AGENT_STAFF_LIST_FAILURE,
} from "../constant.js";

const INITIAL_STATE = {
  Reports: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
  RequestList: null,
  ManagerList: [],
  StaffList: [],
  AgentList: [],
  AgentStaffList: [],
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_AGENT_STAFF_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        AgentStaffList: [],
        error: {},
      };
    case FETCH_AGENT_STAFF_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        AgentStaffList: action.payload,
      };
    case FETCH_AGENT_STAFF_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case FETCH_AGENT_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        AgentList: [],
        error: {},
      };
    case FETCH_AGENT_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        AgentList: action.payload,
      };
    case FETCH_AGENT_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case FETCH_ADMIN_REQ_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        RequestList: null,
        error: {},
      };
    case FETCH_ADMIN_REQ_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        RequestList: action.payload,
      };
    case FETCH_ADMIN_REQ_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case FETCH_MANAGER_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        ManagerList: [],
        error: {},
      };
    case FETCH_MANAGER_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        ManagerList: action.payload,
      };
    case FETCH_MANAGER_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case FETCH_STAFF_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        StaffList: [],
        error: {},
      };
    case FETCH_STAFF_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        StaffList: action.payload,
      };
    case FETCH_STAFF_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
