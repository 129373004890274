import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import TableStaffListView from "../components/TableStaffListView";
import { connect } from "react-redux";
import { FetchStaff_Middleware } from "../middleware";
import Pagination from "components/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { GetBankListMiddleware } from "views/AdminView/BankList/middleware";
import { FetchManager_Middleware } from "views/AdminView/ManagerList/middleware";

const StaffList = ({
  _FetchStaff_Middleware,
  _StaffList,
  DeleteRes,
  _GetBankListMiddleware,
  response,
  _FetchManager_Middleware,
  _Manager_List,
}) => {
  const history = useHistory();
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [bankId, setBankId] = useState("");
  const [banks, setBanks] = useState([]);
  const [managerId, setManagerId] = useState("");
  const [managers, setManagers] = useState([]);
  const [valueAndKey, setValueAndKey] = useState({});
  const [bankOrManager, setBankOrManager] = useState({});
  const [criteriaType, setCriteriaType] = useState({
    criteria: "",
  });
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  ///////////----------Pagination--------------//////////////
  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};
  //////////-------useEffects--------//////////////

  useEffect(() => {
    if (_StaffList) {
      setPosts(_StaffList);
    }
  }, [_StaffList]);

  useEffect(() => {
    _FetchStaff_Middleware(
      pageNumber,
      noOfRows,
      valueAndKey,
      bankOrManager,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows, DeleteRes, valueAndKey, bankOrManager]);
  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      setValueAndKey({
        key: key,
        value: value,
      });
    });
  }, [filter]);

  useEffect(() => {
    Object.entries(search).forEach(([key, value]) => {
      setBankOrManager({
        key: key,
        value: value,
      });
    });
  }, [search]);

  useEffect(() => {
    _GetBankListMiddleware(1, 100, onSuccess, onFailure);
  }, []);

  useEffect(() => {
    if (bankId !== "") {
      _FetchManager_Middleware(1, 100000, "", bankId, onSuccess, onFailure);
    }
  }, [bankId]);

  useEffect(() => {
    setBanks(response?.items);
  }, [response]);

  useEffect(() => {
    setManagers(_Manager_List);
  }, [_Manager_List]);

  useEffect(() => {
    setSearch({ bankId: bankId });
    if (bankId == "") {
      setManagerId("");
    }
  }, [bankId]);

  useEffect(() => {
    setSearch({ managerId: managerId });
    if (managerId == "") {
      setSearch({ bankId: bankId });
    }
  }, [managerId]);
  // const Redirect = () => {
  //   history.push("/user/AddNewManager");
  // };
  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Staff List</h2>
                  </Col>
                  {/* <Col lg="2" md="2" sm="2">
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      size="md"
                      id="search"
                      onClick={() => Redirect()}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Add New Manager</span>
                    </Button>
                  </Col> */}
                </Row>
                <hr />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      value={filter[criteriaType.criteria]}
                      onChange={(e) =>
                        setFilter({ [criteriaType.criteria]: e.target.value })
                      }
                    ></input>
                    <br />
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Selection
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setCriteriaType({
                          ...criteriaType,
                          criteria: e.target.value,
                        })
                      }
                    >
                      <option value="">Search Criteria</option>
                      <option value="fullName">Name</option>
                      <option value="CNIC">CNIC</option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                    </select>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Bank
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setBankId(e.target.value)}
                      value={bankId}
                    >
                      <option value="">Search Bank</option>
                      {banks !== null && banks !== undefined
                        ? banks.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>
                                {data.bankName}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Manager
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setManagerId(e.target.value)}
                      disabled={bankId == ""}
                      value={managerId}
                    >
                      <option value="">Search Manager</option>
                      {managers !== null && managers !== undefined
                        ? managers?.map((data) => {
                            return (
                              <option key={data.userId} value={data.userId}>
                                {data.user.fullName}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </Col>
                  <br />
                </Row>
                <TableStaffListView
                  onChangeNoOfRows={onChangeNoOfRows}
                  post={paginatedPosts}
                />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  _StaffList: state.staffList.Staff_List,
  DeleteRes: state.staffList.DeleteRes,
  response: state.bankList.response,
  _Manager_List: state.managerList.Manager_List,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _FetchStaff_Middleware: (
      pageNumber,
      noOfRows,
      valueAndKey,
      bankOrManager,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchStaff_Middleware(
          pageNumber,
          noOfRows,
          valueAndKey,
          bankOrManager,
          onSuccess,
          onFailure
        )
      ),
    _GetBankListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
    _FetchManager_Middleware: (
      pageNumber,
      noOfRows,
      valueAndKey,
      bankId,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchManager_Middleware(
          pageNumber,
          noOfRows,
          valueAndKey,
          bankId,
          onSuccess,
          onFailure
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(StaffList);
