import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import TableOfApplicationList from "./TableOfApplicationList";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
const Filteration = (props) => {
  const history = useHistory();
  const [filter, setFilter] = useState("");
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [checkedData, setCheckedData] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const handleData = (data) => {
    console.log("Datafromparent", data);
    setCheckedData(data);
  };
  const toggler = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    props.GetApplicationListMiddleware(
      pageNumber,
      noOfRows,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows]);

  useEffect(() => {
    if (props.List !== null && props.List !== undefined) {
      setPosts(props.List);
    }
  }, [props.List]);
  const getReceiptByFields = () => {
    // let filterPosts = props.List.filter((i) => {
    //   if (filter == i?.id) {
    //     return i;
    //   } else if (
    //     filter ==
    //     i?.name?.toString().toLowerCase().includes(filter.toLowerCase())
    //   ) {
    //     return i;
    //   } else if (filter == i?.cnic) {
    //     return i;
    //   } else if (filter == "") {
    //     return i;
    //   }
    // });
    const search = props.List.filter(
      (item) =>
        item?.cnic?.toString().includes(filter) ||
        item?.name?.toString().toLowerCase().includes(filter.toLowerCase())
    );
    if (filter == "") {
      setPosts(props.List);
    } else {
      setPosts(search);
    }
  };

  ///////////----------Pagination--------------//////////////

  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    if (checkedData == true) {
      // console.log("show alert msg");
      swal({
        title: "Are you sure?",
        text: "If yes, you will lost your checked items",
        icon: "warning",
        buttons: ["Cancel", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          onChangeNoOfRows("0");
          onChangeNoOfRows("10");
        }
      });
    } else {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};

  return (
    <CardHeader className="border-0">
      <Row>
        <Col lg="5" md="5" sm="5">
          <h2 className="mb-0">Application List</h2>
        </Col>
        <Col lg="2" md="2" sm="2">
          {/* <h2 className="mb-0">Request List</h2> */}
        </Col>
      </Row>
      <hr style={{ marginTop: 10 }} />

      <CardBody>
        <Row style={{ marginTop: -40 }}>
          <Col lg="4" md="4" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              // disabled={true}
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
          <Col lg="8" md="8" sm="8" xs="8">
            <Row style={{ marginLeft: "30%" }}>
              <Col lg="8" md="8" sm="8">
                <label className="form-control-label" for="input-username">
                  Search
                </label>
                <input
                  type="text"
                  id="input-username"
                  className="form-control"
                  placeholder="Search by applicant name or cnic"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                ></input>
                <br />
              </Col>
              <Col lg="4" md="4" sm="4">
                <Button
                  style={{
                    backgroundColor: "#015652",
                    color: "white",
                    marginTop: "30px",
                  }}
                  size="md"
                  id="search"
                  onClick={() => getReceiptByFields()}
                >
                  <span className="btn-inner--text"></span>
                  <span className="btn-inner--icon">Search</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <br /> */}
        <TableOfApplicationList
          paginatedPosts={paginatedPosts}
          CustomerInfoMiddleware={props.CustomerInfoMiddleware}
          info={props.info}
          pageSize={noOfRows}
          pageIndex={pageNumber}
          handleData={handleData}
        ></TableOfApplicationList>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
