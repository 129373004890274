import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Progress,
} from "reactstrap";
import "react-h5-audio-player/lib/styles.css";

import swal from "sweetalert";
import validate from "components/Utilities Component/ValidationWrapper";
const PrintReport = ({ toggle, modal }) => {
  const [isTrue, setIsTrue] = useState(false);
  const [percent, setPercent] = useState(0);

  const [body, setBody] = useState({
    count: "",
    residentalId: "",
  });

  const [error, setError] = useState({
    countError: null,
    categoryError: null,
  });

  const onSuccess = (message) => {
    swal("Succesful!", "Form Submitted Succesfully", "success");
    setBody({
      count: "",
      residentalId: "",
    });
    setError({
      countError: null,
      categoryError: null,
    });
    toggle();
    setPercent(0);
  };

  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
    setIsTrue(false);
  };
  const onProgress = (pro) => {
    setPercent(pro);
  };

  const CheckFields = (name) => {
    if (name === "count") {
      setError({
        ...error,
        countError: validate("required", body.count),
      });
    } else if (name === "category") {
      setError({
        ...error,
        categoryError: validate("required", body.residentalId),
      });
    }
  };

  const handleSendData = () => {
    setError({
      ...error,
      countError: validate("required", body.count),
      categoryError: validate("required", body.residentalId),
    });
    if (body.count !== "" && body.residentalId !== "") {
      setIsTrue(true);
      let body1 = {
        totalPlots: +body.count,
        residentalId: +body.residentalId,
      };
      // _BallotingMiddleware(body1, onSuccess, onFailure, onProgress);
    }
  };

  const close = () => {
    setBody({
      count: "",
      residentalId: "",
    });
    setError({
      countError: null,
      categoryError: null,
    });
    toggle();
    setPercent(0);
  };

  return (
    <Modal size="sm" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d6992a" }}>
        <h2 style={{ color: "white", textAlign: "center" }}>Print Form</h2>
      </ModalHeader>
      <ModalBody>
        <div>
          <Row>
            <Col lg="12" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Category:
              </label>
              <select
                className="form-control"
                onBlur={() => CheckFields("category")}
                onChange={(e) =>
                  setBody({
                    ...body,
                    residentalId: e.target.value,
                  })
                }
              >
                <option value="">Select Category</option>
                {/* {plotType?.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>
                      {data.title}
                    </option>
                  );
                })} */}
              </select>
              {error.categoryError !== "" && error.categoryError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.categoryError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="12" md="6" sm="6">
              <label className="form-control-label" for="input-username">
                Print Type
              </label>
              <select
                className="form-control"
                onBlur={() => CheckFields("category")}
                onChange={(e) =>
                  setBody({
                    ...body,
                    residentalId: e.target.value,
                  })
                }
              >
                <option value="">Select Type</option>
                {/* {plotType?.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>
                      {data.title}
                    </option>
                  );
                })} */}
              </select>
              {error.countError !== "" && error.countError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.countError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
          </Row>
          <br />
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={handleSendData}
              disabled={isTrue}
            >
              Print
            </Button>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={close}
            >
              Close
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default PrintReport;
