import { 
  STAFF,STAFF_SUCCESS,STAFF_FAILURE,
  STAFFEDIT,STAFFEDIT_FAILURE,STAFFEDIT_SUCCESS
} from "../constant.js";
export class StaffEditDetail {
  static StaffEdit() {
    return {
      type: STAFFEDIT
  }
}
  static StaffEdit_Success(response) {
    return {
      type: STAFFEDIT_SUCCESS,
      payload: response,
    };
  }
  static StaffEdit_Failure(error) {
    return {
      type: STAFFEDIT_FAILURE,
      error,
    };
  }
 
}

export class StaffDetail {
  static Staff() {
    return {
      type: STAFF
  }
}
  static Staff_Success(response) {
    return {
      type: STAFF_SUCCESS,
      payload: response,
    };
  }
  static Staff_Failure(error) {
    return {
      type: STAFF_FAILURE,
      error,
    };
  }
 
}

