import React, { useEffect } from "react";

import { useState } from "react";
import { Button, Table, Input } from "reactstrap";
import { BASEURL } from "config/api/URL";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import { useHistory } from "react-router";
import Information from "../helpers/Information";
import ViewForm from "../helpers/ViewForm";
import { async } from "validate.js";
import axios from "axios";
import KGC_AgentSingleVoucher from "views/AgentView/HtmlRecieptAgent/KGC_AgentSingleVoucher/KGC_AgentSingleVoucher";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
const TableOfViewCustomer = (props) => {
  const { pageSize, pageIndex, handleData, getChalanDataMiddleware } = props;
  const componentRef = useRef();

  const [checked, setChecked] = useState([]);
  useEffect(() => {
    setChecked([]);
  }, [pageSize]);
  const resetChecked = () => {
    setChecked([]);
  };
  useEffect(() => {
    if (checked.length > 0 && pageIndex > 1) {
      handleData(true);
    } else {
      handleData(false);
    }
  }, [pageIndex, checked]);
  let sNo = 1;
  const [isOpen, setIsOpen] = useState(false);
  const [chalanData, setChalanData] = useState(null);
  const [isOpenView, setIsOpenView] = useState(false);
  const [disabedButton, setdisabedButton] = useState(false);
  let userId = JSON.parse(localStorage.getItem("user")).id;

  let history = useHistory();
  const role = JSON.parse(localStorage.getItem("roleid"));
  const [ClientDetail, setClientDetail] = useState({});
  const toggler = () => {
    setIsOpen(!isOpen);
  };
  const togglerView = (detail) => {
    setClientDetail(detail);
    setIsOpenView(!isOpenView);
  };

  const print = (id) => {
    let token = localStorage.getItem("auth");

    //window.open(`${BASEURL}real-estate/download-application/${id}/${token}`,"_blank")
    // await  props.middleware.GetApplicationListMiddleware(props.pageNumber, props.noOfRows,props.valuesAndKey,props.onSuccess,props.onFailure)
    //  await props.middleware.GetApplicationListMiddleware(props.pageNumber, props.noOfRows,props.valuesAndKey,props.onSuccess,props.onFailure)
    //  props.setisTrue(true)
    saveAs(`${BASEURL}real-estate/download-form/${id}/${userId}`, "Form.pdf");
    //   let progress = 0;
    //   axios({
    //     url: `${BASEURL}real-estate/download-application/${id}/${token}`,
    //     onDownloadProgress(progressEvent) {
    //       progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    //   }

    // }).then(response => {
    //     if(response){
    //       // window.open(response.config.url, "_blank")
    //       saveAs(
    //         `${response.config.url}`,
    //         "Form.pdf"
    //       );
    //     }
    // }).catch(function (error) {
    //   if (error.response) {

    //   }
    // });
    props.middleware.GetApplicationListMiddleware(
      props.pageNumber,
      props.noOfRows,
      props.valuesAndKey,
      props.onSuccess,
      props.onFailure
    );
  };

  const getApi = () => {
    props.middleware.GetApplicationListMiddleware(
      props.pageNumber,
      props.noOfRows,
      props.valuesAndKey,
      props.onSuccess,
      props.onFailure
    );
  };

  const dateFunction = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Karachi",
    });
    return nDate;
  };

  // const [isOpenChangeModal, setIsOpenChangeModal] = useState(false);
  // const [ClientDetailModal, setClientDetailModal] = useState({});
  // const Modaldata = (posts) => {
  //   setClientDetailModal(posts);
  //   setIsOpenChangeModal(!isOpenChangeModal);
  // };
  const handleCheck = (event, value) => {
    if (checked.length > 4 && event.target.checked) {
      swal("Sorry!", "You can't select more than 5 forms", "error");
      event.target.checked = false;
    }
    // if (value.status == "DRAFT" && event.target.checked) {
    //   setDraftDis(value);
    // } else if (value.status == "DRAFT" && !event.target.checked) {
    //   setDraftDis(null);
    // }

    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, { id: value.id, status: value.status }];
      setClientDetail(value);
    } else if (!event.target.checked) {
      let tempArr = updatedList.filter((item) => value.id !== item.id);
      updatedList = [...tempArr];
    }
    setChecked(updatedList);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `chalan`,
    copyStyles: true,
  });

  const onSuccess = (res) => {
    console.log("RES PONSE", res);
    setChalanData(res);
    handlePrint();
  };
  const onFailure = () => {};

  const handleGetChalanData = (id) => {
    getChalanDataMiddleware(id, onSuccess, onFailure);
  };
  const unCheck = () => {
    var x = document.getElementsByName("check");

    for (let i = 0; i < x.length; i++) {
      if (x[i].checked === true) {
        x[i].checked = false;
      }
    }
  };
  return (
    <>
      <div style={{ display: "none" }}>
        <KGC_AgentSingleVoucher ref={componentRef} chalanData={chalanData} />
      </div>

      <Information
        modal={isOpen}
        toggle={toggler}
        FormSubmitMiddleware={props.FormSubmitMiddleware}
        info={props.info}
        detail={ClientDetail}
        formdata={checked}
        getApi={getApi}
        resetChecked={resetChecked}
        uncheck={unCheck}
      />
      <ViewForm modal={isOpenView} toggle={togglerView} detail={ClientDetail} />
      <Button
        style={{
          backgroundColor: "#015652",
          color: "white",
          // width: "20%",
          marginBottom: "5px",
          marginTop: "-5px",
        }}
        disabled={checked.length <= 0 ? true : false}
        size="md"
        id="search"
        onClick={() => {
          checked.length > 0
            ? toggler()
            : swal("Sorry!", "Please select atleast one form", "error");
        }}
      >
        <span className="btn-inner--text"></span>
        <span className="btn-inner--icon">{`Proceed with ${checked.length} Forms`}</span>
      </Button>
      <Table className="align-items-center" responsive>
        <thead
          style={{
            backgroundColor: "#d69929",
            color: "white",
            overflowX: "scroll",
          }}
        >
          <tr>
            <th scope="col"></th>
            <th scope="col">Form ID</th>

            <th scope="col">Form No</th>
            <th scope="col">Applicant Name</th>
            <th scope="col">CNIC</th>
            <th scope="col">Email</th>
            <th scope="col">Phone Number</th>
            <th scope="col">whatsAppNo</th>
            {/* <th scope="col">DepositSlip No</th> */}
            {role == 5 ? (
              <th scope="col">Created By</th>
            ) : (
              <th scope="col">Submitted By</th>
            )}

            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {props.paginatedPosts !== null &&
          props.paginatedPosts !== undefined ? (
            props.paginatedPosts.map((posts, i) => {
              const index = i + pageIndex * pageSize;
              return (
                <tr key={posts.id}>
                  {/* <td></td> */}
                  <td>
                    <Input
                      style={{ position: "static" }}
                      type="checkbox"
                      name="check"
                      disabled={
                        posts.status == "submitted"
                        //  ||
                        // posts.status == "PAYMENT_PENDING" ||
                        // draftDis !== null
                        //   ? draftDis?.id !== posts.id
                        //   : "" || checked.length > 0
                        //   ? checked[0].status !== posts.status
                        //   : ""
                      }
                      value={posts.id}
                      onChange={(e) => handleCheck(e, posts)}
                    />
                  </td>
                  <td>{posts?.id}</td>
                  <td>{posts?.formNo}</td>
                  <td>{posts?.name}</td>
                  <td>{posts?.cnic}</td>
                  <td>{posts?.email}</td>
                  <td>{posts?.phone}</td>
                  <td>{posts?.whatsAppNo}</td>
                  {/* <td>{posts?.depositSlipNo}</td> */}
                  {role == 5 ? (
                    <td>{posts.createdByUser?.fullName}</td>
                  ) : (
                    <td>{posts.updatedByUser?.fullName}</td>
                  )}
                  <td>{posts.status}</td>
                  <td>
                    {/* <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      size="sm"
                      onClick={(e) => print(posts.id)}
                      id="info"
                      disabled={posts.isPrinted}
                    >
                      <span className="btn-inner--icon">Print</span>
                    </Button>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      size="sm"
                      onClick={(e) => toggler(posts)}
                      id="info"
                      disabled={posts.status == "submitted" || !posts.isPrinted}
                    >
                      <span className="btn-inner--icon">Submit</span>
                    </Button> */}
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      size="sm"
                      onClick={(e) => togglerView(posts)}
                      id="info"
                      disabled={posts.status !== "submitted"}
                    >
                      <i class="fas fa-eye"></i>
                    </Button>
                    <Button
                      color="success"
                      onClick={() => handleGetChalanData(posts.id)}
                      size="sm"
                      disabled={posts.status !== "submitted"}
                    >
                      <i class="fas fa-print"></i>
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
export default TableOfViewCustomer;
