import React from "react";
import { Table } from "reactstrap";

const TablePricingListView = ({ getRealstateDealers }) => {
  return (
    <div>
      {/* <Row className="mt--5">
        <Col xl="2" lg="4" md="4" sm="6" xs="6">
          <Label className="form-control-label"> Rows Per Pages </Label>
          <Input
            id="exampleFormControlSelect1"
            type="select"
            onChange={(e) => onChangeNoOfRows(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Input>
        </Col>
      </Row>
      <br /> */}
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Name</th>
            <th scope="col">Agency Name</th>
            {/* <th scope="col">CNIC</th> */}
            {/* <th scope="col">Email</th> */}
            <th scope="col">Phone No.</th>
            <th scope="col">WhatsApp No.</th>
            <th scope="col">Address One</th>
            {/* <th scope="col">Address Two</th> */}
            <th scope="col">Country</th>
            <th scope="col">City</th>
            {/* <th scope="col">No. Of Staff</th> */}
          </tr>
        </thead>
        <tbody>
          {getRealstateDealers !== null && getRealstateDealers !== undefined ? (
            getRealstateDealers.map((item) => {
              return (
                <tr>
                  <td>{item.id}</td>
                  <td>{item?.name}</td>
                  <td>{item?.agencyName}</td>
                  {/* <td>{item?.cnic}</td> */}
                  {/* <td>{item?.email}</td> */}
                  <td>{item?.phone}</td>
                  <td>{item?.whatsAppNo}</td>
                  <td>{item?.addressOne}</td>
                  {/* <td>{item?.addressTwo}</td> */}
                  <td>{item?.country?.title}</td>
                  <td>{item?.city?.title}</td>
                  {/* <td>{item?.noOfStaff}</td> */}
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TablePricingListView;
