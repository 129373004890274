export const CUSTOMER = "CUSTOMER";
export const CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS";
export const CUSTOMER_FAILURE = "CUSTOMER_FAILURE";
export const CUSTOMER_PATH = "application-v2";

export const CUSTOMERDRAFT = "CUSTOMERDRAFT";
export const CUSTOMERDRAFT_SUCCESS = "CUSTOMERDRAFT_SUCCESS";
export const CUSTOMERDRAFT_FAILURE = "CUSTOMERDRAFT_FAILURE";
export const CUSTOMERDRAFT_PATH = "application/for-user-form-submit-as-draft";

export const CUSTOMERDRAFTUPDATE = "CUSTOMERDRAFTUPDATE";
export const CUSTOMERDRAFTUPDATE_SUCCESS = "CUSTOMERDRAFTUPDATE_SUCCESS";
export const CUSTOMERDRAFTUPDATE_FAILURE = "CUSTOMERDRAFTUPDATE_FAILURE";
export const CUSTOMERDRAFTUPDATE_PATH = "application/update-draft-data";

export const CUSTOMERDRAFTID = "CUSTOMERDRAFTID";
export const CUSTOMERDRAFTID_SUCCESS = "CUSTOMERDRAFTID_SUCCESS";
export const CUSTOMERDRAFTID_FAILURE = "CUSTOMERDRAFTID_FAILURE";
export const CUSTOMERDRAFTID_PATH = "application/draft-data";

export const CUSTOMERUPDATE = "CUSTOMERUPDATE";
export const CUSTOMERUPDATE_SUCCESS = "CUSTOMERUPDATE_SUCCESS";
export const CUSTOMERUPDATE_FAILURE = "CUSTOMERUPDATE_FAILURE";
export const CUSTOMERUPDATE_PATH = "application";

export const PLOTTYPE = "PLOTTYPE";
export const PLOTTYPE_SUCCESS = "PLOTTYPE_SUCCESS";
export const PLOTTYPE_FAILURE = "PLOTTYPE_FAILURE";
export const PLOTTYPE_PATH = "residental";

export const VERIFY_BY_CNIC = "VERIFY_BY_CNIC";
export const VERIFY_BY_CNIC_SUCCESS = "VERIFY_BY_CNIC_SUCCESS";
export const VERIFY_BY_CNIC_FAILURE = "VERIFY_BY_CNIC_FAILURE";
export const VERIFY_BY_CNIC_PATH =
  "application/form-submit-count-check-by-cnic";

// export const PLOTPROJECT= "PLOTPROJECT";
// export const PLOTPROJECT_SUCCESS = "PLOTPROJECT_SUCCESS";
// export const PLOTPROJECT_FAILURE = "PLOTPROJECT_FAILURE";
// export const PLOTPROJECT_PATH = "project/findproject";
