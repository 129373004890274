import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
  CardTitle,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import TableWinnerView from "../components/TableWinnerView";
import { connect } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import BallotingForm from "../components/BallotingForm";
import {
  PlotTypeMiddleware,
  BallotingMiddleware,
  WinnerListMiddleware,
  MembersCountMiddleware,
} from "../middleware";

const Balloting = ({
  _PlotTypeMiddleware,
  plotType,
  _BallotingMiddleware,
  _WinnerListMiddleware,
  winnerList,
  _MembersCountMiddleware,
  members,
}) => {
  var well = {
    boxShadow:
      "3px 3px 3px 3px rgba(0, 0, 0, 0.16), 3px 3px 3px 3px rgba(0, 0, 0, 0.16)",
    borderRadius: "15px",
  };
  const [searchplotType, setPlotType] = useState("");
  const [portal, setPortal] = useState("");
  const [status, setStatus] = useState("");
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  ///////////----------Pagination--------------//////////////
  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////
  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};
  const onCategorySuccess = () => {};
  const onCategoryFailure = () => {};
  const ToggleFormSubmissionModal = () => {
    setIsOpen(!isOpen);
  };
  //////////-------useEffects--------//////////////

  useEffect(() => {
    if (winnerList) {
      setPosts(winnerList);
    }
  }, [winnerList]);
  useEffect(() => {
    _WinnerListMiddleware(
      pageNumber,
      noOfRows,
      portal,
      status,
      searchplotType,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows, portal, status, searchplotType]);
  useEffect(() => {
    _PlotTypeMiddleware(onCategorySuccess, onCategoryFailure);
    _MembersCountMiddleware(onSuccess, onFailure);
  }, []);

  return (
    <>
      <Headers />
      <BallotingForm
        toggle={ToggleFormSubmissionModal}
        _BallotingMiddleware={_BallotingMiddleware}
        modal={isOpen}
        plotType={plotType}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col xl="10" lg="8" md="8" sm="6">
                    <h1 className="mb-0">Balloting</h1>
                  </Col>
                  <Col lg="2" md="2" sm="2">
                    <Button
                      style={{
                        backgroundColor: "#015652",
                        color: "white",
                      }}
                      size="md"
                      id="search"
                      onClick={() => ToggleFormSubmissionModal()}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Balloting Form</span>
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="1" md="3" sm="10"></Col>
                  {members?.map((item) => {
                    return (
                      <Col lg="2" md="3" sm="10">
                        <Card className="card-stats mb-4 mb-xl-0 " style={well}>
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-blue text-uppercase text-muted mb-0"
                                >
                                  {item?.title}
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {item?.members}
                                </span>
                              </div>
                            </Row>
                            <br />
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
                {/* <hr /> */}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Status
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Search Status</option>
                      <option value="PENDING">PENDING</option>
                      <option value="ACCEPTED">ACCEPTED</option>
                      <option value="CANCELED">CANCELED</option>
                    </select>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Portal
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setPortal(e.target.value)}
                    >
                      <option value="">Search Type</option>
                      <option value="CUSTOMER">CUSTOMER</option>
                      <option value="BANKING">BANKING</option>
                      <option value="REALESTATE">REALESTATE</option>
                    </select>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Plot Type
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setPlotType(e.target.value)}
                    >
                      <option value="">Search Category</option>
                      {plotType !== null && plotType !== undefined
                        ? plotType.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>
                                {data.title}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </Col>
                  <br />
                </Row>
                <TableWinnerView
                  onChangeNoOfRows={onChangeNoOfRows}
                  post={paginatedPosts}
                />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  plotType: state.balloting.plotType,
  winnerList: state.balloting.winnerList,
  members: state.balloting.members,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _PlotTypeMiddleware: (onSuccess, onFailure) =>
      dispatch(PlotTypeMiddleware(onSuccess, onFailure)),
    _MembersCountMiddleware: (onSuccess, onFailure) =>
      dispatch(MembersCountMiddleware(onSuccess, onFailure)),
    _BallotingMiddleware: (body, onSuccess, onFailure, onProgress) =>
      dispatch(BallotingMiddleware(body, onSuccess, onFailure, onProgress)),
    _WinnerListMiddleware: (
      pageNumber,
      noOfRows,
      portal,
      status,
      searchplotType,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        WinnerListMiddleware(
          pageNumber,
          noOfRows,
          portal,
          status,
          searchplotType,
          onSuccess,
          onFailure
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Balloting);
