import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
  Input,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import { connect } from "react-redux";
import {
  AdminFeedbackList_Middleware,
  Resolve_Middleware,
  Reply_Middleware,
} from "../middleware";
import Pagination from "components/Pagination/Pagination";
import TableFeedbackListView from "../components/TableFeedbackListView";

const AdminFeedbackList = (props) => {
  const [noOfRows, setnoOfRows] = useState(10);
  // const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [cnic, setCninc] = useState("");
  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};

  //////////-------useEffects--------//////////////
  useEffect(() => {
    if (
      props.AdminFeedbackList !== null &&
      props.AdminFeedbackList !== undefined
    ) {
      setData(props.AdminFeedbackList);
    }
  }, [props.AdminFeedbackList]);

  useEffect(() => {
    props.AdminFeedbackList_Middleware(
      pageNumber,
      noOfRows,
      status,
      cnic,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows]);
  const callBack = () => {
    props.AdminFeedbackList_Middleware(
      pageNumber,
      noOfRows,
      status,
      cnic,
      onSuccess,
      onFailure
    );
  };
  const search = () => {
    props.AdminFeedbackList_Middleware(
      1,
      1000000000,
      status,
      cnic,
      onSuccess,
      onFailure
    );
  };
  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Queries List</h2>
                  </Col>
                </Row>
                <hr style={{ marginTop: 10 }} />
              </CardHeader>
              <CardBody>
                <Row style={{ marginTop: -50 }}>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search query by status
                    </label>
                    <Input
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select status</option>
                      <option value="true">Resolved</option>
                      <option value="false">Unresolved</option>
                    </Input>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search query by cnic
                    </label>
                    <Input
                      id="cninc"
                      name="cninc"
                      placeholder="Enter your cninc"
                      maxLength={13}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      // onBlur={() => checkField("oldpass")}
                      type="text"
                      value={cnic}
                      onChange={(e) => setCninc(e.target.value)}
                    />
                  </Col>
                  <Col lg="4" md="4" sm="6">
                    <Button
                      style={{
                        backgroundColor: "#015652",
                        color: "white",
                        marginTop: "30px",
                      }}
                      size="md"
                      id="search"
                      onClick={() => search()}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Search</span>
                    </Button>
                  </Col>
                </Row>

                <hr />
                <TableFeedbackListView
                  resolve={props}
                  onChangeNoOfRows={onChangeNoOfRows}
                  post={data}
                  callback={callBack}
                />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  // totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  AdminFeedbackList: state.adminqueryList.AdminFeedbackList,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    AdminFeedbackList_Middleware: (
      pageNumber,
      noOfRows,
      status,
      cnic,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        AdminFeedbackList_Middleware(
          pageNumber,
          noOfRows,
          status,
          cnic,
          onSuccess,
          onFailure
        )
      ),
    Resolve_Middleware: (id, onSuccess, onFailure) =>
      dispatch(Resolve_Middleware(id, onSuccess, onFailure)),
    Reply_Middleware: (id, body, onSuccess, onFailure) =>
      dispatch(Reply_Middleware(id, body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AdminFeedbackList);
