export const BRANCH =  "BRANCH";
export const BRANCH_SUCCESS =  "BRANCH_SUCCESS";
export const BRANCH_FAILURE =  "BRANCH_FAILURE";
export const BRANCH_PATH = "branch";

export const BRANCHEDIT =  "BRANCHEDIT";
export const BRANCHEDIT_SUCCESS =  "BRANCHEDIT_SUCCESS";
export const BRANCHEDIT_FAILURE =  "BRANCHEDIT_FAILURE";
export const BRANCHEDIT_PATH = "branch";


