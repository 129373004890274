import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import TableAdminReqListView from "../components/TableAdminReqListView";
import { connect } from "react-redux";
import {
  AdminAppList_Middleware,
  FetchAgentList_Middleware,
  FetchAgentStaffList_Middleware,
  FetchManagerList_Middleware,
  FetchStaffList_Middleware,
} from "../middleware";
import Pagination from "components/Pagination/Pagination";
import { GetBankListMiddleware } from "views/AdminView/BankList/middleware";

const AdminRequestList = ({
  _AdminAppList_Middleware,
  RequestList,
  _GetBankListMiddleware,
  response,
  _FetchManagerList_Middleware,
  ManagerList,
  _FetchStaffList_Middleware,
  StaffList,
  _FetchAgentList_Middleware,
  AgentList,
  _FetchAgentStaffList_Middleware,
  AgentStaffList,
}) => {
  const [filter, setFilter] = useState({});
  const [valueAndKey, setValueAndKey] = useState({});
  const [criteriaType, setCriteriaType] = useState({
    criteria: "",
  });
  const [portal, setPortal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankId, setBankId] = useState("");
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [managerId, setManagerId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [agentStaffId, setAgentStaffId] = useState("");
  const [managers, setManagers] = useState([]);
  const [staff, setStaff] = useState([]);
  const [agentStaff, setAgentStaff] = useState([]);
  const [agent, setAgent] = useState([]);
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  ///////////----------Pagination--------------//////////////
  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};

  //////////-------useEffects--------//////////////

  useEffect(() => {
    _AdminAppList_Middleware(
      pageNumber,
      noOfRows,
      valueAndKey,
      searchFilter,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows, valueAndKey, searchFilter]);

  useEffect(() => {
    _GetBankListMiddleware(1, 100, onSuccess, onFailure);
    _FetchAgentList_Middleware(1, 1000, onSuccess, onFailure);
  }, []);

  useEffect(() => {
    _FetchManagerList_Middleware(1, 10000, bankId, onSuccess, onFailure);
  }, [bankId]);

  useEffect(() => {
    _FetchStaffList_Middleware(1, 100000, managerId, onSuccess, onFailure);
  }, [managerId]);

  useEffect(() => {
    _FetchAgentStaffList_Middleware(1, 1000, agentId, onSuccess, onFailure);
  }, [agentId]);

  useEffect(() => {
    if (RequestList?.items) {
      setPosts(RequestList?.items);
    }
  }, [RequestList?.items]);

  useEffect(() => {
    setBanks(response?.items);
  }, [response]);

  useEffect(() => {
    setManagers(ManagerList);
  }, [ManagerList]);

  useEffect(() => {
    setStaff(StaffList);
  }, [StaffList]);

  useEffect(() => {
    setAgent(AgentList);
  }, [AgentList]);

  useEffect(() => {
    setAgentStaff(AgentStaffList);
  }, [AgentStaffList]);

  useEffect(() => {
    // if (
    //   bankId?.length !== 0 ||
    //   managerId?.length !== 0 ||
    //   staffId?.length !== 0
    // ) {
    //   setIsBankSearchTrue(true);
    // } else if (agentId?.length !== 0 || agentStaffId?.length !== 0) {
    //   setIsAgentSearchTrue(true);
    // } else {
    //   setIsAgentSearchTrue(false);
    //   setIsBankSearchTrue(false);
    // }
    if (portal == "bank") {
      setAgentId("");
    } else if (portal == "realState") {
      setBankId("");
    } else if (portal == "") {
      setBankId("");
      setAgentId("");
    }
    if (criteriaType.criteria == "") {
      setFilter({ "": "" });
    }
  }, [portal, criteriaType]);

  useEffect(() => {
    setSearch({ bankId: bankId });
    if (bankId == "") {
      setStaffId("");
      setManagerId("");
    } else if (bankId !== "") {
      setStaffId("");
      setManagerId("");
    }
  }, [bankId]);

  useEffect(() => {
    setSearch({ managerId: managerId });
    if (managerId == "") {
      setSearch({ bankId: bankId });
      setStaffId("");
    } else if (managerId !== "") {
      setStaffId("");
    }
  }, [managerId]);

  useEffect(() => {
    setSearch({ staffId: staffId });
    if (staffId == "") {
      setSearch({ managerId: managerId });
    }
  }, [staffId]);

  useEffect(() => {
    setSearch({ agentId: agentId });
    if (agentId == "") {
      setAgentStaffId("");
    }
  }, [agentId]);

  useEffect(() => {
    setSearch({ agentStaffId: agentStaffId });
    if (agentStaffId == "") {
      setSearch({ agentId: agentId });
    }
  }, [agentStaffId]);

  // const onHandleChange = (value, name) => {
  //   if (name == "bankId") {
  //     // setBankId("");
  //     // setManagerId("");
  //     // setIsTrue(false);
  //   } else if (name == "managerId") {
  //   } else if (name == "staffId") {
  //   }
  // };
  useEffect(() => {
    Object.entries(search).forEach(([key, value]) => {
      setSearchFilter({
        key: key,
        value: value,
      });
    });
  }, [search]);

  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      setValueAndKey({
        key: key,
        value: value,
      });
    });
  }, [filter]);

  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Request List</h2>
                  </Col>
                </Row>
                <hr />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      value={filter[criteriaType.criteria]}
                      onChange={(e) =>
                        setFilter({ [criteriaType.criteria]: e.target.value })
                      }
                      disabled={criteriaType.criteria == ""}
                    ></input>
                    <br />
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Selection
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setCriteriaType({
                          ...criteriaType,
                          criteria: e.target.value,
                        })
                      }
                    >
                      <option value="">Search Criteria</option>
                      <option value="name">Name</option>
                      <option value="CNIC">CNIC</option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                      <option value="status">Status</option>
                    </select>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Portal
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setPortal(e.target.value)}
                      value={portal}
                    >
                      <option value="">Search Portal</option>
                      <option value="bank">Bank</option>
                      <option value="realState">Real State</option>
                    </select>
                  </Col>
                </Row>
                {portal === "bank" ? (
                  <Row>
                    <Col lg="4" md="4" sm="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Search by Bank
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setBankId(e.target.value)}
                        // onChange={(e) =>
                        //   onHandleChange(e.target.value, "bankId")
                        // }
                        value={bankId}
                      >
                        <option value="">Select Bank</option>
                        {banks !== null && banks !== undefined
                          ? banks.map((data) => {
                              return (
                                <option key={data.id} value={data.id}>
                                  {data.bankName}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <br />
                    </Col>
                    <Col lg="4" md="4" sm="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Search by Manager
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setManagerId(e.target.value)}
                        // onChange={(e) =>
                        //   onHandleChange(e.target.value, "managerId")
                        // }
                        value={managerId}
                        disabled={bankId == ""}
                      >
                        <option value="">Select Manager</option>
                        {managers !== null && managers !== undefined
                          ? managers?.map((data) => {
                              return (
                                <option key={data.id} value={data.user.id}>
                                  {data.user.fullName}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </Col>
                    <Col lg="4" md="4" sm="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Search by Staff
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setStaffId(e.target.value)}
                        // onChange={(e) =>
                        //   onHandleChange(e.target.value, "staffId")
                        // }
                        disabled={managerId == ""}
                        value={staffId}
                      >
                        <option value="">Select Staff</option>
                        {staff !== null && staff !== undefined
                          ? staff?.map((data) => {
                              return (
                                <option key={data.id} value={data.user.id}>
                                  {data.user.fullName}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </Col>
                  </Row>
                ) : portal === "realState" ? (
                  <Row>
                    <Col lg="4" md="4" sm="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Search by Agent
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setAgentId(e.target.value)}
                        value={agentId}
                      >
                        <option value="">Select Agent</option>
                        {agent !== null && agent !== undefined
                          ? agent?.map((data) => {
                              return (
                                <option key={data.id} value={data.id}>
                                  {data.fullName}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </Col>
                    <Col lg="4" md="4" sm="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Search by Agent Staff
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setAgentStaffId(e.target.value)}
                        value={agentStaffId}
                        disabled={agentId == ""}
                      >
                        <option value="">Select Staff</option>
                        {agentStaff !== null && agentStaff !== undefined
                          ? agentStaff?.map((data) => {
                              return (
                                <option key={data.id} value={data.id}>
                                  {data.fullName}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </Col>
                    <br />
                  </Row>
                ) : (
                  ""
                )}
                <hr />
                <TableAdminReqListView
                  onChangeNoOfRows={onChangeNoOfRows}
                  post={paginatedPosts}
                />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  ManagerList: state.adminRequestList.ManagerList,
  RequestList: state.adminRequestList.RequestList,
  StaffList: state.adminRequestList.StaffList,
  AgentList: state.adminRequestList.AgentList,
  AgentStaffList: state.adminRequestList.AgentStaffList,
  response: state.bankList.response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _FetchAgentList_Middleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        FetchAgentList_Middleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
    _GetBankListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
    _AdminAppList_Middleware: (
      pageNumber,
      noOfRows,
      searchFilter,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        AdminAppList_Middleware(
          pageNumber,
          noOfRows,
          searchFilter,
          onSuccess,
          onFailure
        )
      ),

    _FetchManagerList_Middleware: (
      pageNumber,
      noOfRows,
      id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchManagerList_Middleware(
          pageNumber,
          noOfRows,
          id,
          onSuccess,
          onFailure
        )
      ),
    _FetchStaffList_Middleware: (
      pageNumber,
      noOfRows,
      id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchStaffList_Middleware(
          pageNumber,
          noOfRows,
          id,
          onSuccess,
          onFailure
        )
      ),

    _FetchAgentStaffList_Middleware: (
      pageNumber,
      noOfRows,
      agentId,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchAgentStaffList_Middleware(
          pageNumber,
          noOfRows,
          agentId,
          onSuccess,
          onFailure
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AdminRequestList);
