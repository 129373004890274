import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFY_BY_EMAIL,
  VERIFY_BY_EMAIL_SUCCESS,
  VERIFY_BY_EMAIL_FAILURE,
  QEURY,
  QEURY_SUCCESS,
  QEURY_FAILURE,
  QEURY_PATH,
  SEND_QUERY,
  SEND_QUERY_SUCCESS,
  SEND_QUERY_FAILURE,
  SEND_QUERY_PATH,
} from "../constant.js";

export class UserLogin {
  static Login() {
    return {
      type: LOGIN,
    };
  }
  static LoginSuccess(response) {
    return {
      type: LOGIN_SUCCESS,
      payload: response,
    };
  }
  static LoginFailure(error) {
    return {
      type: LOGIN_FAILURE,
      error,
    };
  }
}

export class GetIssues {
  static Get() {
    return {
      type: QEURY,
    };
  }
  static GetIssuesSuccess(response) {
    return {
      type: QEURY_SUCCESS,
      payload: response,
    };
  }
  static GetIssuesFailure(error) {
    return {
      type: QEURY_FAILURE,
      error,
    };
  }
}

export class SendQuery {
  static Send() {
    return {
      type: SEND_QUERY,
    };
  }
  static SendSuccess(response) {
    return {
      type: SEND_QUERY_SUCCESS,
      payload: response,
    };
  }
  static SendFailure(error) {
    return {
      type: SEND_QUERY_FAILURE,
      error,
    };
  }
}

export class VerifyByEmail {
  static Account_verify() {
    return {
      type: VERIFY_BY_EMAIL,
    };
  }
  static Account_verifySuccess(response) {
    return {
      type: VERIFY_BY_EMAIL_SUCCESS,
      payload: response,
    };
  }
  static Account_verifyFailure(error) {
    return {
      type: VERIFY_BY_EMAIL_FAILURE,
      error,
    };
  }
}
