import axios from "axios";
import { UserSignUp, CityDetail, CountryDetail } from "../action";
import { SIGNUP_PATH, COUNTRY_PATH, CITY_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const country_Middleware = (onSuccess, onFailure) => (dispatch) => {
  dispatch(CountryDetail.country());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${COUNTRY_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(CountryDetail.countrySuccess(res.data.data));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
      } else {
      }
    });
};
export const city_Middleware =
  (countryId, onSuccess, onFailure) => (dispatch) => {
    dispatch(CityDetail.city());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${CITY_PATH}/${countryId}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(CityDetail.citySuccess(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
        } else {
        }
      });
  };

export const SignUpMiddleware = (body, OnSuccess, OnFailure) => (dispatch) => {
  dispatch(UserSignUp.SignUp());

  axios
    .post(`${BASEURL}${SIGNUP_PATH}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status === true) {
        dispatch(UserSignUp.SignUpSuccess(res.data.message));
        dispatch(OnSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        OnFailure(error.response.data);
      } else if (error.request) {
      } else {
      }
    });
};
