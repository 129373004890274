export const APPREQUEST = "APPREQUEST";
export const APPREQUEST_SUCCESS = "APPREQUEST_SUCCESS";
export const APPREQUEST_FAILURE = "APPREQUEST_FAILURE";
export const APPREQUEST_PATH =
  "application-request/monthly/count/for/dashboard";

export const BANKAPP = "BANKAPP";
export const BANKAPP_SUCCESS = "BANKAPP_SUCCESS";
export const BANKAPP_FAILURE = "BANKAPP_FAILURE";
export const BANKAPP_PATH = "bank-application/monthly/count/for/dashboard";

export const APP_REQUEST_TOTAL_COUNT = "APP_REQUEST_TOTAL_COUNT";
export const APP_REQUEST_TOTAL_COUNT_SUCCESS =
  "APP_REQUEST_TOTAL_COUNT_SUCCESS";
export const APP_REQUEST_TOTAL_COUNT_FAILURE =
  "APP_REQUEST_TOTAL_COUNT_FAILURE";
export const APP_REQUEST_TOTAL_COUNT_PATH =
  "application-request/count/for/dashboard";

export const BANK_APP_TOTAL_COUNT = "BANK_APP_TOTAL_COUNT";
export const BANK_APP_TOTAL_COUNT_SUCCESS = "BANK_APP_TOTAL_COUNT_SUCCESS";
export const BANK_APP_TOTAL_COUNT_FAILURE = "BANK_APP_TOTAL_COUNT_FAILURE";
export const BANK_APP_TOTAL_COUNT_PATH = "bank-application/count/for/dashboard";

export const REAL_STATE_TOTAL_COUNT = "REAL_STATE_TOTAL_COUNT";
export const REAL_STATE_TOTAL_COUNT_SUCCESS = "REAL_STATE_TOTAL_COUNT_SUCCESS";
export const REAL_STATE_TOTAL_COUNT_FAILURE = "REAL_STATE_TOTAL_COUNT_FAILURE";
export const REAL_STATE_TOTAL_COUNT_PATH =
  "real-estate/application/count/for/dashboard";
export const AGENTAPP = "AGENTAPP";
export const AGENTAPP_SUCCESS = "AGENTAPP_SUCCESS";
export const AGENTAPP_FAILURE = "AGENTAPP_FAILURE";
export const AGENTAPP_PATH =
  "real-estate/monthly/application/count/for/dashboard";

export const CUSTOMERAPP = "CUSTOMERAPP";
export const CUSTOMERAPP_SUCCESS = "CUSTOMERAPP_SUCCESS";
export const CUSTOMERAPP_FAILURE = "CUSTOMERAPP_FAILURE";
export const CUSTOMERAPP_PATH = "application-v2/monthly/count/for/dashboard";

export const CUSTOMER_REQ_TOTAL_COUNT = "CUSTOMER_REQ_TOTAL_COUNT";
export const CUSTOMER_REQ_TOTAL_COUNT_SUCCESS =
  "CUSTOMER_REQ_TOTAL_COUNT_SUCCESS";
export const CUSTOMER_REQ_TOTAL_COUNT_FAILURE =
  "CUSTOMER_REQ_TOTAL_COUNT_FAILURE";
export const CUSTOMER_REQ_TOTAL_COUNT_PATH =
  "application-v2/count/for/dashboard";
