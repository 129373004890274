export const BRANCHLIST = "BRANCHLIST";
export const BRANCHLIST_SUCCESS = "BRANCHLIST_SUCCESS";
export const BRANCHLIST_FAILURE = "BRANCHLIST_FAILURE";
export const BRANCHLIST_PATH = "branch";

export const BRANCHDELETE = "BRANCHDELETE";
export const BRANCHDELETE_SUCCESS = "BRANCHDELETE_SUCCESS";
export const BRANCHDELETE_FAILURE = "BRANCHDELETE_FAILURE";
export const BRANCHDELETE_PATH = "branch";

export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";
export const FILE_UPLOAD_PATH = "uploadfile/file-upload-xlsx";
