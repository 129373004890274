import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import TableOfApplicationList from "./TableOfApplicationList";
import { useHistory } from "react-router-dom";



const Filteration = (props) => {
  const history = useHistory();
  const onSuccessClient = () => {};
  const onFailureClient = () => {};

 

  const [teamId, setteamId] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("teamid") == "null") {
      setteamId(null);
    } else {
      setteamId(parseInt(localStorage.getItem("teamid")));
    }
  }, [true]);
const formRequest=()=>{

}

  let dataInArrayForPaginaion = [];
  useEffect(() => {
    setPosts(null);
    setPageNumber(1);
    if (props.Client !== null && props.Client !== undefined) {
      for (let i = 0; i < props.Client.length; i++) {
        dataInArrayForPaginaion.push(props.Client[i]);
        setPosts(props.Client);
      }
    } else {
    }
  }, [props.Client]);

  const [state, setState] = useState({
    id: "",
    agentName: "",
    phone: "",
    date: "",
    ClientPhone: "",
    ClientEmail: "",
    ClientName: "",
    UserId: "",
    formNo: "",
    Cnic: "",
  });
  const [noOfRows, setnoOfRows] = useState("");
  let numberOfRows;
  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    numberOfRows = parseInt(val);
    setPageNumber(1);
  };
  const onChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  const [posts, setPosts] = useState([]);
  const [pageNumber, setPageNumber] = useState(2);
  let postNumber = 10;
  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  let paginatedPosts, total_pages;
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;
  if (posts !== null) {
    paginatedPosts = posts.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };
  const handleNext = () => {
    if (total_pages > pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };

  //Start Validation
  const CheckFields = (name) => {
    if (name === "cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", state.Cnic),
      });
    }
  };

  const [error, setError] = useState({
    cnicError: "",
  });
  //end validation
  //tooltip start
  const [tooltipOpen, setTooltipOpen] = useState({
    reset: false,
    info: false,
    search: false,
  });
  const toggle = (name) => {
    if (name == "search") {
      setTooltipOpen({ ...tooltipOpen, search: !tooltipOpen.search });
    } else if (name == "info") {
      setTooltipOpen({ ...tooltipOpen, info: !tooltipOpen.info });
    } else if (name == "reset") {
      setTooltipOpen({ ...tooltipOpen, reset: !tooltipOpen.reset });
    }
  };
  //tooltip end

  const [isOpen, setIsOpen] = useState(false);
  const toggler = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    let body = {
      FormNo: null,
      cnic: "",
    };
    props.showClientDetail(body, onSuccessClient, onFailureClient);
  }, []);

  return (
    <CardHeader className="border-0">
    <Row>
      <Col  lg="2" md="2" sm="6">
        <h2>
        Application List 
        </h2>
      </Col>
      <Col  lg="8" md="8" sm="6"></Col>
      <Col  lg="2" md="2" sm="6">
   
      </Col>
      
    </Row>
    <hr/>
      <CardBody>
      
        <form>
          
            <Row>
            <Col lg="2" md="2" sm="6">
            <label className="form-control-label" for="input-username">
                 Search
                </label>
                <input
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9-+]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={13}
                  onBlur={() => CheckFields("cnic")}
                  id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.Cnic}
                  onChange={(e) => onChange(e.target.value, "Cnic")}
                ></input>
              </Col>
             
            </Row>
           
        
        </form>
      
      </CardBody>
      <CardBody>
        <Row>
          <Col lg="2" md="2" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
        </Row>
        <br />
        <TableOfApplicationList
          showReturnPlot={props.showReturnPlot}
          PlotReturn={props.PlotReturn}
          InsertReturnPlot={props.InsertReturnPlot}
          ReturnRequest={props.ReturnRequest}
          paginatedPosts={paginatedPosts}
          state={state}
          teamId={teamId}
          showPlotSector={props.showPlotSector}
          Sector={props.Sector}
          showPlotNo={props.showPlotNo}
          PlotNo={props.PlotNo}
          ShowPlotInformation={props.ShowPlotInformation}
          PlotPositionsValues={props.PlotPositionsValues}
          newPlotMiddleware={props.newPlotMiddleware}
          NewPosition={props.NewPosition}
          ChangePlotMiddleware={props.ChangePlotMiddleware}
          ChangePlotStatus={props.ChangePlotStatus}
        ></TableOfApplicationList>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
