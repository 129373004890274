import React from "react";

import { CardBody, CardHeader, Card, Row, Col, Input } from "reactstrap";
import "../../../../assets/css/style.css";
import Logo from "../../../PaymentReceipt/images/newlogo.png";

// class ComponentToPrint extends React.Component {
//   constructor(props) {

//     super(props);
//     this.state = {
//       filPath: null

//     }
//   }

//   componentDidMount() {

//   }
var date = new Date().getDate(); //Current Date
var month = new Date().getMonth() + 1; //Current Month
var year = new Date().getFullYear(); //Current Year
var currentDate = date + "/" + month + "/" + year;

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="d-none d-print-block">
        <Card
          style={{ margin: "10px" }}
          className=" shadow border border-dark text-dark"
        >
          <CardHeader className="border-0">
            <Row className="mb-5">
              <Col lg="10" md="10" sm="10" xs="10">
                <img
                  src={Logo}
                  style={{
                    height: "50px",
                    width: "230px",
                    // transform: "skewY(1deg)"
                  }}
                />
              </Col>
              <Col lg="2" md="2" sm="2" xs="2">
                <div className="text-dark">{currentDate}</div>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 className="mb-0 text-center">Registration Form</h1>
              </Col>
            </Row>
          </CardHeader>

          <CardHeader className="border-0">
            <h3 className="mb-0"> Personal Information </h3>

            <CardBody>
              <form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12" md="12" sm="6" xs="12">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Form No:
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.FormNo}
                      ></Input>
                    </Col>

                    {/* <Col lg="12" md="12" sm="6" xs="12">
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Form No:</label>
                        </div>
                      </div>
                    </Col>

                    <Col lg="12" md="12" sm="6" xs="12">
                      <div className="carddd">
                        <div className="containerrr">
                          <input type="textbox"className="border border-dark text-dark" value={this.props.FormNo}></input>
                        </div>
                      </div>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Name *
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.name}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Father/Husband Name *
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.father}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Email *
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.email}
                      ></Input>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        CNIC No *
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.cnic}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Passport No
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.passport}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Mobile *
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.mobile}
                      ></Input>
                    </Col>
                    {/* <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>CNIC:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr" >
                          <input type="textbox" className="border border-dark text-dark" value={this.props.cnic}></input>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Passport No:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input
                            type="textbox"className="border border-dark text-dark"
                            value={this.props.passport}
                          ></input>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Mobile:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input
                            type="textbox"
                            value={this.props.mobile}
                            className="border border-dark text-dark"
                          ></input>
                        </div>
                      </div>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Address Residence *
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.address}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Organization
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.organization}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Whatsapp Number *
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.whatsappNumber}
                      ></Input>
                    </Col>
                    {/* <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Address Residence:</label>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input
                          className="border border-dark text-dark"
                            type="textbox"
                            style={{ paddingLeft: "70px" }}
                            value={this.props.address}
                          ></input>
                        </div>
                      </div>
                    </Col> */}

                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Date of birth
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.date}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Nationality
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.nationality}
                      ></Input>
                    </Col>

                    {/* <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Date of birth:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input type="textbox" value={this.props.date}className="border border-dark text-dark"></input>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Nationality:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input
                            type="textbox"
                            value={this.props.nationality}
                            className="border border-dark text-dark"
                          ></input>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </form>
            </CardBody>
          </CardHeader>
        </Card>
        <br />

        <Card
          style={{ margin: "10px" }}
          className="shadow border border-dark text-dark"
        >
          <CardHeader className="border-0">
            <h3 className="mb-0">Plot Information </h3>
            <CardBody>
              <form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Project Name
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.plotProject}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Plot Type
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.plotType}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Plot Size
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.plotSize}
                      ></Input>
                    </Col>

                    {/* <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Project Name:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input
                            type="textbox"
                            value={this.props.plotProject}
                            className="border border-dark text-dark"
                          ></input>
                        </div>
                      </div>
                    </Col> */}
                    {/* 
                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Plot Type:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input
                            type="textbox"
                            value={this.props.plotType}
                            className="border border-dark text-dark"
                          ></input>
                        </div>
                      </div>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Quantity
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.plotQuantity}
                      ></Input>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Driving license
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        // disabled
                        value={this.props.drivingLicense}
                      ></Input>
                    </Col>
                    {/*                     
                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Plot Size:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input
                            type="textbox"
                            value={this.props.plotSize}
                            className="border border-dark text-dark"
                          ></input>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <label>Quantity:</label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="carddd">
                        <div className="containerrr">
                          <input
                            type="textbox"
                            value={this.props.plotQuantity}
                            className="border border-dark text-dark"
                          ></input>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  <br />
                </div>
              </form>
            </CardBody>
          </CardHeader>
        </Card>
      </div>
    );
  }
}

export default ComponentToPrint;
