import {
  BRANCHLIST,
  BRANCHLIST_SUCCESS,
  BRANCHLIST_FAILURE,
  BRANCHDELETE,
  BRANCHDELETE_SUCCESS,
  BRANCHDELETE_FAILURE,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
} from "../constant";
const INITIAL_STATE = {
  responseBranch: null,
  del: null,
  responseFile: null,
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;

    case BRANCHDELETE:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        del: null,
        error: {},
      };
    case BRANCHDELETE_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        del: action.payload,
      };
    case BRANCHDELETE_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case BRANCHLIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        responseBranch: null,
        error: {},
      };
    case BRANCHLIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        responseBranch: action.payload,
      };
    case BRANCHLIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case FILE_UPLOAD:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        responseFile: null,
        error: {},
      };
    case FILE_UPLOAD_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        responseFile: action.payload,
      };
    case FILE_UPLOAD_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
