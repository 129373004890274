import {
  BRANCHLIST,
  BRANCHLIST_SUCCESS,
  BRANCHLIST_FAILURE,
  BRANCHDELETE,
  BRANCHDELETE_SUCCESS,
  BRANCHDELETE_FAILURE,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
} from "../constant";

export class BranchDeleteDetail {
  static BranchDelete() {
    return {
      type: BRANCHDELETE,
    };
  }
  static BranchDelete_Success(response) {
    return {
      type: BRANCHDELETE_SUCCESS,
      payload: response,
    };
  }
  static BranchDelete_Failure(error) {
    return {
      type: BRANCHDELETE_FAILURE,
      error,
    };
  }
}

export class BranchListDetail {
  static BranchList() {
    return {
      type: BRANCHLIST,
    };
  }
  static BranchList_Success(response) {
    return {
      type: BRANCHLIST_SUCCESS,
      payload: response,
    };
  }
  static BranchList_Failure(error) {
    return {
      type: BRANCHLIST_FAILURE,
      error,
    };
  }
}

export class FileUpload {
  static Upload() {
    return {
      type: FILE_UPLOAD,
    };
  }
  static Upload_Success(response) {
    return {
      type: FILE_UPLOAD_SUCCESS,
      payload: response,
    };
  }
  static Upload_Failure(error) {
    return {
      type: FILE_UPLOAD_FAILURE,
      error,
    };
  }
}
