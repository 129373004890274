export const FETCH_ADMIN_REQ_LIST = "FETCH_ADMIN_REQ_LIST";
export const FETCH_ADMIN_REQ_LIST_SUCCESS = "FETCH_ADMIN_REQ_LIST_SUCCESS";
export const FETCH_ADMIN_REQ_LIST_FAILURE = "FETCH_ADMIN_REQ_LIST_FAILURE";
export const FETCH_ADMIN_REQ_LIST_PATH =
  "application-request/application/filter/list/for/admin";

export const FETCH_MANAGER_LIST = "FETCH_MANAGER_LIST";
export const FETCH_MANAGER_LIST_SUCCESS = "FETCH_MANAGER_LIST_SUCCESS";
export const FETCH_MANAGER_LIST_FAILURE = "FETCH_MANAGER_LIST_FAILURE";
export const FETCH_MANAGER_LIST_PATH =
  "staff-profile/manager-list-short-data/for-admin";

export const FETCH_STAFF_LIST = "FETCH_STAFF_LIST";
export const FETCH_STAFF_LIST_SUCCESS = "FETCH_STAFF_LIST_SUCCESS";
export const FETCH_STAFF_LIST_FAILURE = "FETCH_STAFF_LIST_FAILURE";
export const FETCH_STAFF_LIST_PATH = "staff-profile/staff-list/for-admin";

export const FETCH_AGENT_LIST = "FETCH_AGENT_LIST";
export const FETCH_AGENT_LIST_SUCCESS = "FETCH_AGENT_LIST_SUCCESS";
export const FETCH_AGENT_LIST_FAILURE = "FETCH_AGENT_LIST_FAILURE";
export const FETCH_AGENT_LIST_PATH = "real-estate/for-admin";

export const FETCH_AGENT_STAFF_LIST = "FETCH_AGENT_STAFF_LIST";
export const FETCH_AGENT_STAFF_LIST_SUCCESS = "FETCH_AGENT_STAFF_LIST_SUCCESS";
export const FETCH_AGENT_STAFF_LIST_FAILURE = "FETCH_AGENT_STAFF_LIST_FAILURE";
export const FETCH_AGENT_STAFF_LIST_PATH =
  "real-estate/agentStaff-list/for-admin";
