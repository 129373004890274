import {
  FETCH_ALL_RECEIPT,
  FETCH_ALL_RECEIPT_SUCCESS,
  FETCH_ALL_RECEIPT_FAILURE,
  CANCELATION,
  CANCELATION_SUCCESS,
  CANCELATION_FAILURE,
  STATUS,
  STATUS_SUCCESS,
  STATUS_FAILURE,
} from "../constant.js";

export class FetchAllReceipt {
  static Fetch() {
    return {
      type: FETCH_ALL_RECEIPT,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_ALL_RECEIPT_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_ALL_RECEIPT_FAILURE,
      error,
    };
  }
}

export class FormCancelation {
  static Cancelation() {
    return {
      type: CANCELATION,
    };
  }
  static CancelationSuccess(response) {
    return {
      type: CANCELATION_SUCCESS,
      payload: response,
    };
  }
  static CancelationFailure(error) {
    return {
      type: CANCELATION_FAILURE,
      error,
    };
  }
}

export class FormStatus {
  static Status() {
    return {
      type: STATUS,
    };
  }
  static StatusSuccess(response) {
    return {
      type: STATUS_SUCCESS,
      payload: response,
    };
  }
  static StatusFailure(error) {
    return {
      type: STATUS_FAILURE,
      error,
    };
  }
}
