import axios from "axios";
import { FetchFeedbackList } from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_FEEDBACK_LIST,
  FETCH_FEEDBACK_LIST_SUCCESS,
  FETCH_FEEDBACK_LIST_FAILURE,
  FETCH_FEEDBACK_LIST_PATH,
} from "../constant";

export const AdminFeedbackList_Middleware =
  (pageNumber, noOfRows, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchFeedbackList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_FEEDBACK_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("FEEDBACk DATA", res.data.data);
          dispatch(FetchFeedbackList.FetchSuccess(res.data.data.items));
          // dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log("ERR", error.response);
          // onFailure(error.response.data);
        }
      });
  };
