export const FETCH_STAFF = "FETCH_STAFF";
export const FETCH_STAFF_SUCCESS = "FETCH_STAFF_SUCCESS";
export const FETCH_STAFF_FAILURE = "FETCH_STAFF_FAILURE";
export const FETCH_STAFF_PATH = "staff-profile/get-all-staff/for-manager";

export const FETCH_BRANCHS_BY_ID = "FETCH_BRANCHS_BY_ID";
export const FETCH_BRANCHS_BY_ID_SUCCESS = "FETCH_BRANCHS_BY_ID_SUCCESS";
export const FETCH_BRANCHS_BY_ID_FAILURE = "FETCH_BRANCHS_BY_ID_FAILURE";
export const FETCH_BRANCHS_BY_ID_PATH = "branch/byBank";

export const STAFF_SIGNUP_FOR_MANAGER = "STAFF_SIGNUP_FOR_MANAGER";
export const STAFF_SIGNUP_FOR_MANAGER_SUCCESS =
  "STAFF_SIGNUP_FOR_MANAGER_SUCCESS";
export const STAFF_SIGNUP_FOR_MANAGER_FAILURE =
  "STAFF_SIGNUP_FOR_MANAGER_FAILURE";
export const STAFF_SIGNUP_FOR_MANAGER_PATH =
  "auth/staff/user/signup/for-manager";

export const DELETE_STAFF = "DELETE_STAFF";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_FAILURE = "DELETE_STAFF_FAILURE";
export const DELETE_STAFF_PATH = "staff-profile";

export const EDIT_STAFF = "EDIT_STAFF";
export const EDIT_STAFF_SUCCESS = "EDIT_STAFF_SUCCESS";
export const EDIT_STAFF_FAILURE = "EDIT_STAFF_FAILURE";
export const EDIT_STAFF_PATH = "staff-profile/update";
