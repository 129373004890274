import { connect } from "react-redux";

import Bank from "./Bank";
import { InsertBankMiddleware,EditBankMiddleware} from "../middleware";


const mapStateToProps = (state) => ({

  bank:state.bank.bank,
edit:state.bank.edit,

 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    EditBankMiddleware:(id,body,onSuccess,onFailure)=>
    dispatch(EditBankMiddleware(id, body, onSuccess, onFailure)),
    InsertBankMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(InsertBankMiddleware(body, OnSuccess, OnFailure)),
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Bank);
