import jsPDF from "jspdf";
import React from "react";
import {
  CardBody,
  Container,
  CardHeader,
  Card,
  Row,
  Col,
  Input,
} from "reactstrap";
// import "../../../assets/css/style.css";
import Logo from "../images/newlogo.png";

class ComponentToPrint extends React.Component {
  render() {
    let date = new Date().toISOString().split("T")[0];
    // const generatePDF = () => {
    //   var doc = new jsPDF("p", "pt", "a4");
    //   doc.html(document.querySelector("#content"), {
    //     callback: function (pdf) {
    //       pdf.save("ok.pdf");
    //     },
    //   });
    // };
    // generatePDF();
    return (
      <div className="d-none d-print-block mx-4">
        <Container className="mt-7  border border-dark rounded" fluid>
          <Row id="content">
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="mb-5">
                    <Col lg="10" md="10" sm="10" xs="10">
                      <img
                        src={Logo}
                        style={{
                          height: "50px",
                          width: "230px",
                          // transform: "skewY(1deg)"
                        }}
                      />
                    </Col>
                    <Col lg="2" md="2" sm="2" xs="2">
                      <div className="text-dark">{date}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                      <h1 className="mb-0 text-center">Payment Receipt</h1>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="4" md="4" sm="6" xs="2">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Form No.
                      </label>
                      <input
                        value={
                          this.props.user?.receiptNo
                            ? this.props.user?.receiptNo
                            : ""
                        }
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                      ></input>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col lg="4" md="4" sm="6" xs="6">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        value={
                          this.props.user?.name ? this.props.user?.name : ""
                        }
                        disabled
                      ></input>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Contact No.
                      </label>

                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.user?.whatsappNumber
                            ? this.props.user?.whatsappNumber
                            : ""
                        }
                      ></input>
                    </Col>

                    <Col lg="4" md="4" sm="6" xs="6">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.user?.email ? this.props.user?.email : ""
                        }
                      ></input>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        CNIC
                      </label>

                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.user?.cnic ? this.props.user?.cnic : ""
                        }
                      ></input>
                    </Col>
                    <br />
                  </Row>
                  <Row>
                    <Col lg="4" md="4" sm="6" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Plot Category
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.user?.plot_category?.title
                            ? this.props.user?.plot_category?.title
                            : ""
                        }
                      ></input>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Plot Type
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.user?.plot_type?.title
                            ? this.props.user?.plot_type?.title
                            : ""
                        }
                      ></input>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Number Of Plots
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.user?.plotQuantity
                            ? this.props.user?.plotQuantity
                            : ""
                        }
                      ></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4" md="4" sm="6" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Payment Through
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.printReceiptInfo.paymentThroughId == "1"
                            ? "Cash"
                            : this.props.printReceiptInfo.paymentThroughId ==
                              "2"
                            ? "Cheque"
                            : this.props.printReceiptInfo.paymentThroughId ==
                              "3"
                            ? "Online"
                            : this.props.printReceiptInfo.paymentThroughId ==
                              "4"
                            ? "Pay Order"
                            : ""
                        }
                      ></input>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Payment Ref #
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.printReceiptInfo.paymentRef
                            ? this.props.printReceiptInfo.paymentRef
                            : "-"
                        }
                      ></input>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="4">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Amount
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.printReceiptInfo.amount
                            ? this.props.printReceiptInfo.amount
                            : ""
                        }
                      ></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" md="12" sm="6" xs="12">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        Remarks:
                      </label>
                      <Input
                        type="text"
                        className="form-control border border-dark text-dark"
                        disabled
                        value={
                          this.props.printReceiptInfo.remarks
                            ? this.props.printReceiptInfo.remarks
                            : ""
                        }
                      ></Input>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="3"
                      md="3"
                      sm="3"
                      xs="3"
                      style={{ alignItems: "center" }}
                    >
                      <p>___________________________</p>
                      <h4> Applicant Signature</h4>
                    </Col>
                    <Col
                      lg="3"
                      md="3"
                      sm="3"
                      xs="6"
                      style={{ alignItems: "center" }}
                    ></Col>
                    <Col
                      lg="3"
                      md="3"
                      sm="3"
                      xs="3"
                      style={{ alignItems: "center" }}
                    >
                      <p>___________________________</p>
                      <h4> Applicant Signature</h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                      <p className="mb-0 mt-5 text-center">
                        (This receipt is valid only for verification purpose
                        <br />
                        and will be confirmed by Developer)
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ComponentToPrint;
