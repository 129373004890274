

import { 
  CUSTOMERLIST,CUSTOMERLIST_SUCCESS,CUSTOMERLIST_FAILURE,
  CUSTOMERDELETE,CUSTOMERDELETE_FAILURE,CUSTOMERDELETE_SUCCESS,
  CUSTOMERFILE,CUSTOMERFILE_FAILURE,CUSTOMERFILE_SUCCESS,
  CUSTOMER,CUSTOMER_SUCCESS,CUSTOMER_FAILURE,
 
} from "../constant.js";

export class CustomerDetail {
  static Customer() {
    return {
      type: CUSTOMER
  }
}
  static Customer_Success(response) {
    return {
      type: CUSTOMER_SUCCESS,
      payload: response,
    };
  }
  static Customer_Failure(error) {
    return {
      type: CUSTOMER_FAILURE,
      error,
    };
  }
 
}




export class CustomerFileDetail {
  static CustomerFile() {
    return {
      type: CUSTOMERFILE
  }
}
  static CustomerFile_Success(response) {
    return {
      type: CUSTOMERFILE_SUCCESS,
      payload: response,
    };
  }
  static CustomerFile_Failure(error) {
    return {
      type: CUSTOMERFILE_FAILURE,
      error,
    };
  }
 
}


export class CustomerDeleteDetail {
  static CustomerDelete() {
    return {
      type: CUSTOMERDELETE
  }
}
  static CustomerDelete_Success(response) {
    return {
      type: CUSTOMERDELETE_SUCCESS,
      payload: response,
    };
  }
  static CustomerDelete_Failure(error) {
    return {
      type: CUSTOMERDELETE_FAILURE,
      error,
    };
  }
 
}

export class CustomerListDetail {
  static CustomerList() {
    return {
      type: CUSTOMERLIST
  }
}
  static CustomerList_Success(response) {
    return {
      type: CUSTOMERLIST_SUCCESS,
      payload: response,
    };
  }
  static CustomerList_Failure(error) {
    return {
      type: CUSTOMERLIST_FAILURE,
      error,
    };
  }
 
}

