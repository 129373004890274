export const NOTIFICATIONS = "NOTIFICATIONS";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAILURE = "NOTIFICATIONS_FAILURE";
export const NOTIFICATIONS_PATH = "notification";

export const READ_NOTIFICATIONS = "NOTIFICATIONS";
export const READ_NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONS_FAILURE = "NOTIFICATIONS_FAILURE";
export const READ_NOTIFICATIONS_PATH = "notification/mark-read";

export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS";
export const UPDATE_NOTIFICATIONS_FAILURE = "UPDATE_NOTIFICATIONS_FAILURE";
export const UPDATE_NOTIFICATIONS_PATH = "UpdateReadNotification";
