import { connect } from "react-redux";
import OTP from "./OTP";
import { otpMiddleware } from "../middleware";
const mapStateToProps = (state) => ({
  // isLoading: state.otp.isLoading,
  // isError: state.login.isError,
  Data: state.login.Data,
  // Error: state.login.error,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    otpMiddleware: (body, email, OnSuccess, OnFailure) =>
      dispatch(otpMiddleware(body, email, OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(OTP);
