import axios from "axios";
import { CreateQuery } from "../action";
import { BASEURL } from "config/api/URL";
import {
  CREATE_QUERY,
  CREATE_QUERY_SUCCESS,
  CREATE_QUERY_FAILURE,
  CREATE_QUERY_PATH,
} from "../constant";

export const Insertquerydetail = (body, OnSuccess, OnFailure) => (dispatch) => {
  dispatch(CreateQuery.Create());
  console.log("BODY", body);
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${CREATE_QUERY_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("DATA", res.data);
      dispatch(CreateQuery.CreateSuccess(res.data));
      dispatch(OnSuccess(res.data.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response);
        OnFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};
