import {
  FETCH_MANAGER,
  FETCH_MANAGER_SUCCESS,
  FETCH_MANAGER_FAILURE,
  FETCH_BRANCHS_BY_ID,
  FETCH_BRANCHS_BY_ID_SUCCESS,
  FETCH_BRANCHS_BY_ID_FAILURE,
  DELETE_MANAGER,
  DELETE_MANAGER_SUCCESS,
  DELETE_MANAGER_FAILURE,
  EDIT_MANAGER,
  EDIT_MANAGER_SUCCESS,
  EDIT_MANAGER_FAILURE,
  MANAGER_SIGNUP,
  MANAGER_SIGNUP_SUCCESS,
  MANAGER_SIGNUP_FAILURE,
} from "../constant.js";

const INITIAL_STATE = {
  Reports: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
  Manager_List: null,
  BranchesById: [],
  Roles: [],
  StaffRes: null,
  AdminRes: null,
  DeleteRes: null,
  EditRes: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_MANAGER:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Manager_List: null,
        error: {},
      };
    case FETCH_MANAGER_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Manager_List: action.payload,
      };
    case FETCH_MANAGER_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case FETCH_BRANCHS_BY_ID:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        BranchesById: [],
        error: {},
      };
    case FETCH_BRANCHS_BY_ID_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        BranchesById: action.payload,
      };
    case FETCH_BRANCHS_BY_ID_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case MANAGER_SIGNUP:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        ManagerRes: null,
        error: {},
      };
    case MANAGER_SIGNUP_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        ManagerRes: action.payload,
      };
    case MANAGER_SIGNUP_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case DELETE_MANAGER:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        DeleteRes: null,
        error: {},
      };
    case DELETE_MANAGER_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        DeleteRes: action.payload,
      };
    case DELETE_MANAGER_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case EDIT_MANAGER:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        EditRes: null,
        error: {},
      };
    case EDIT_MANAGER_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        EditRes: action.payload,
      };
    case EDIT_MANAGER_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
