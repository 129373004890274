import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Select from "react-select";



const Filteration = (props) => {
  const history = useHistory();
  const location= useLocation();
  const [bank, setBank] = useState([null]);
  const [state, setState] = useState({
    bankId:"",
    branchCode:"",
    branchName:"",
    region:"",
    location:"",
  });

  const [error, setError] = useState({
    bankIdError:"",
    branchCodeError: "",
    branchNameError:"",
    regionError:"",
    locationError:"",

  });
  
  useEffect(() => {

    if (props.response !== null && props.response !== undefined) {
      let arr = [];
      for (let i = 0; i < props.response?.items.length; i++) {
        let obj = {
          value: props.response?.items[i].id,
          label: props.response?.items[i].bankName,
        };
        arr.push(obj);
        //console.log(props.response?.items[i].id,"response")
      }
      
      setBank(arr);
    }
  }, [props.response]);
 

  useEffect(() => {
    if(location.state!==null && location.state!==undefined)
    {
      setState({...state,
        branchCode:location.state?.branchCode,
        branchName:location.state?.branchName,
        location:location.state?.location,
        region:location.state?.region,
        bankId:location.state?.bank?.id

       })
    }
    console.log("location.state",location.state?.bank?.id)
   
  }, [location.state])

  useEffect(() => {
    let pageNumber=1;
    let noOfRows=500;
    props.GetBankListMiddleware(pageNumber, noOfRows,  onSuccessBank, onFailureBank);
  }, [true])
  
  const onFailureBank=()=>{}
  const onSuccessBank=()=>{}


  
  const onSuccess = () => {
    // setState({...state,  bankName:"",
    // bankDescription:""})
  
    swal({
      title: "Success!",
      text: "Branch Added Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/BranchList";
        history.push(path);
      }
    });
  };
  const onFailure = (error) => {
    
    swal("Sorry!", error.message, "error");

  };

 



  // let dataInArrayForPaginaion = [];
  // useEffect(() => {
  //   setPosts(null);
  //   setPageNumber(1);
  //   if (props.Client !== null && props.Client !== undefined) {
  //     for (let i = 0; i < props.Client.length; i++) {
  //       dataInArrayForPaginaion.push(props.Client[i]);
  //       setPosts(props.Client);
  //     }
  //   } else {
  //   }
  // }, [props.Client]);

 
 
  const onChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  console.log(state,"state")

  //Start Validation
  const CheckFields = (name) => {
    if (name === "branchCode") {
      setError({
        ...error,
        branchCodeError: validate("required", state.branchCode),
      });
    }
    else if(name==="bankId") {
      setError({
        ...error,
        bankIdError: validate("required", state.bankId),
      });
    }
    else if(name==="branchName") {
      setError({
        ...error,
        branchNameError: validate("required", state.branchName),
      });
    }
    else if(name==="region") {
      setError({
        ...error,
        regionError: validate("required", state.region),
      });
    }
    else if(name==="location") {
      setError({
        ...error,
        locationError: validate("required", state.location),
      });
    }
  };

  

 

 

 const submit=()=>{
  setError({
    ...error,
    bankIdError: validate("required", state.bankId),
    branchCodeError: validate("required", state.branchCode),
    branchNameError: validate("required", state.branchName),
    regionError: validate("required", state.region),
    locationError: validate("required", state.location),

  });
  if(location.state!==null && location.state!==undefined && 
    state.branchCode!=="" && state.branchName!=="" && state.region!=="" && state.location!==""
    // error.bankIdError==null && error.branchCodeError==null && 
    // error.branchNameError==null && error.regionError==null && error.locationError==null
    )
  {
    let id=location.state.id;
    let body={
      bankId:parseInt(state.bankId),
    branchCode:state.branchCode,
    branchName:state.branchName,
    region:state.region,
    location:state.location,
    }
   
    props.EditBranchMiddleware(id,body,onSuccesEdit,onFailureEdit)
   // props.InsertBankMiddleware(body, onSuccess, onFailure);

  }
  else 
  if(error.bankIdError==null && error.branchCodeError==null && 
    error.branchNameError==null &&error.locationError==null && error.regionError==null)
  { 
    let body={
      bankId:parseInt(state.bankId),
    branchCode:state.branchCode,
    branchName:state.branchName,
    region:state.region,
    location:state.location,
    }
    
  props.InsertBranchMiddleware(body, onSuccess, onFailure);

  }
  

 }
 const onSuccesEdit=()=>{

 setState({...state,  bankName:"",
    bankDescription:""})
  
    swal({
      title: "Success!",
      text: "Branch Edited Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/BranchList";
        history.push(path);
      }
    });
  };
  const onFailureEdit = (error) => {
    swal("Sorry!", error.message, "error");
};




  return (
    <CardHeader className="border-0">
    <Row>
      <Col  lg="2" md="2" sm="6">
        <h2>Branch Form</h2>
      </Col>
      <Col  lg="8" md="8" sm="6"></Col>
      <Col  lg="2" md="2" sm="6"></Col>
    </Row>
    <hr/>
      <CardBody>  
      
        <form>
          
            <Row>
            <Col lg="6" md="6" sm="6">
                    <label className="form-control-label" for="input-username">
                      Bank
                    </label>
                    <Input
                      id="exampleFormControlSelect1"
                      type="select"
                       onChange={(e) => onChange(e.target.value, "bankId")}
                      //onChange={(e) => OnChangeDropDown(e.target.value, "plotSize")}
                      value={state.bankId}
                    
                    >
                      <option
                            
                            value={""}
                          >
                            {"Select"}
                          </option>
                      {props.response !== null &&
                        props.response !== undefined &&
                        props.response.items.map((source) => {
                          return (
                            <option
                              key={source.id}
                              value={source.id}
                              //value1={source.id}
                            >
                              {source.bankName}
                            </option>
                          );
                        })}
                    </Input>


                   
                    {/* <Select
                       onBlur={() => CheckFields("bankId")}
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      options={bank}
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={(e) => onChange(e.value, "bankId")}
                    /> */}
                    {error.bankIdError !== "" &&
                      error.bankIdError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.bankIdError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                   
                  </Col>
            <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
            Branch Code*
                </label>
                <input
                  type="text"
                  onBlur={() => CheckFields("branchCode")}
            
                id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.branchCode}
                  onChange={(e) => onChange(e.target.value, "branchCode")}
                ></input>
                  {error.branchCodeError !== "" && error.branchCodeError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.branchCodeError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}

              </Col>
              <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
            Branch Name*
                </label>
                <input
                  type="text"
               
                  onBlur={() => CheckFields("branchName")}
                  id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.branchName}
                  onChange={(e) => onChange(e.target.value, "branchName")}
                ></input>
                   {error.branchNameError !== "" && error.branchNameError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.branchNameError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
              </Col>
             
            
         
             
            </Row>
            <Row>
            <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
            Region*
                </label>
                <input
                  type="text"
                  onBlur={() => CheckFields("region")}
            
                id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.region}
                  onChange={(e) => onChange(e.target.value, "region")}
                ></input>
                  {error.regionError !== "" && error.regionError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.regionError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}

              </Col>
              <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
            Location*
                </label>
                <input
                  type="text"
               
                  onBlur={() => CheckFields("location")}
                  id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.location}
                  onChange={(e) => onChange(e.target.value, "location")}
                ></input>
                   {error.locationError !== "" && error.locationError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.locationError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
              </Col>
             
            
         
             
            </Row>
            <Row>
            <Col lg="4" md="4" sm="6"> </Col>
            <Col lg="4" md="4" sm="6"></Col>
            <Col lg="4" md="4" sm="6">
              <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={submit}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       Submit
                      </span>
                    </Button>
              </Col>

            </Row>
           
            
           
        
        </form>
      
      </CardBody>
      {/* <CardBody>
        <Row>
          <Col lg="2" md="2" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
        </Row>
        <br />
        <TableOfApplicationList
          showReturnPlot={props.showReturnPlot}
          PlotReturn={props.PlotReturn}
          InsertReturnPlot={props.InsertReturnPlot}
          ReturnRequest={props.ReturnRequest}
          paginatedPosts={paginatedPosts}
          state={state}
          teamId={teamId}
          showPlotSector={props.showPlotSector}
          Sector={props.Sector}
          showPlotNo={props.showPlotNo}
          PlotNo={props.PlotNo}
          ShowPlotInformation={props.ShowPlotInformation}
          PlotPositionsValues={props.PlotPositionsValues}
          newPlotMiddleware={props.newPlotMiddleware}
          NewPosition={props.NewPosition}
          ChangePlotMiddleware={props.ChangePlotMiddleware}
          ChangePlotStatus={props.ChangePlotStatus}
        ></TableOfApplicationList>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody> */}
    </CardHeader>
  );
};
export default Filteration;
