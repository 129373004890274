import {
  APPLICATIONLIST,
  APPLICATIONLIST_SUCCESS,
  APPLICATIONLIST_FAILURE,
  FORMSUBMIT,
  FORMSUBMIT_FAILURE,
  FORMSUBMIT_SUCCESS,
  CHALAN_DATA,
  CHALAN_DATA_SUCCESS,
  CHALAN_DATA_FAILURE,
} from "../constant";
const INITIAL_STATE = {
  List: null,
  info: null,
  chalanData: null,
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case CHALAN_DATA:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        chalanData: null,
        error: {},
      };
    case CHALAN_DATA_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        chalanData: action.payload,
      };
    case CHALAN_DATA_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case FORMSUBMIT:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        info: null,
        error: {},
      };
    case FORMSUBMIT_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        info: action.payload,
      };
    case FORMSUBMIT_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case APPLICATIONLIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        List: null,
        error: {},
      };
    case APPLICATIONLIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        List: action.payload,
      };
    case APPLICATIONLIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
