import { connect } from "react-redux";
import ApplicationList from "./ApplicationList";
import { showClientDetail} from "../middleware";


const mapStateToProps = (state) => ({

  Client:state.applicationList.Client,


 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
   
      showClientDetail: (body, OnSuccess, OnFailure) =>
      dispatch(showClientDetail(body, OnSuccess, OnFailure)),
     


   
  
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(ApplicationList);
