import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Container,
  Col,
  Row,
  InputGroup,
} from "reactstrap";
import swal from "sweetalert";
import axios from "axios";
import { BASEURL } from "../../config/api/URL";
import Loading from "components/Loader/Loading";
const ChangePassword = (props) => {
  const [loading, SetLoading] = useState(false);
  const [token, setToken] = useState("");
  const [oldpass, setOldPass] = useState("");
  const [newpass, setNewPass] = useState("");
  const [confpass, setConfPass] = useState("");
  const [oldPassType, setoldPassType] = useState(false);
  const [confirmType, setconfirmType] = useState(false);
  const [passType, setpassType] = useState(false);
  const [err, setErr] = useState("");
  const apiPath = "auth/reset-password";
  const handleConfirm = () => {
    setconfirmType(!confirmType);
  };
  const handlePass = () => {
    setpassType(!passType);
  };
  const handleOldPass = () => {
    setoldPassType(!oldPassType);
  };
  const handleSubmit = () => {
    SetLoading(true);
    console.log("SUBMITTED");
    const body = {
      oldPassword: oldpass,
      newPassword: newpass,
      confirmPassword: confpass,
    };
    axios
      .post(`${BASEURL}${apiPath}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESDARA", res.data);
        if (res.data.status === true) {
          SetLoading(false);
          // console.log("RES DATA",res.data)
          swal("Password Update successfully!", "", "success");
          props.toggle();
        } else if (res.data.status === false) {
          console.log("RES DATA", res.data);
          SetLoading(false);
          swal("Sorry!", `${res.data.message}`, "error");
        }
      })
      .catch(function (error) {
        if (error.response) {
          SetLoading(false);
          swal("Sorry!", `${error.response.data.message}`, "error");
          console.log(error.response.data);
        } else {
          SetLoading(false);
          swal("Sorry!", `${error}`, "error");
        }
      });
    // .catch((error) =>
    //    {
    //     console.log("Error",error)
    //     swal("Sorry!", `${error}`, "error");
    //    }
    // // dispatch(MeetingNotification.NotificationFailure(error))

    // );
  };
  const onSuccess = () => {
    swal("Price Update successfully!", "", "success");
    props.toggle();
  };
  const onFailure = () => {
    swal("Sorry!", "Something went wrong", "error");
  };
  useEffect(() => {
    let token = localStorage.getItem("auth");
    console.log("token", token);
    setToken(token);
  }, []);
  const checkField = (val) => {
    if (val == "oldpass") {
      if (oldpass !== "") {
        setErr("");
      } else {
        setErr("Old password is required");
      }
    } else if (val == "newpass") {
      if (newpass !== "") {
        setErr("");
      } else {
        setErr("New password is required");
      }
    } else if (val == "conf") {
      if (confpass !== "") {
        setErr("");
      } else {
        setErr("Confirm password is required");
      }
    }
  };
  return (
    <Modal size="sm" centered={true} isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} style={{ backgroundColor: "#d69929" }}>
        <h2 style={{ color: "white", textAlign: "center" }}>Change password</h2>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label for="Passowrd1">Old Password</Label>
              <InputGroup>
                <Input
                  id="Passowrd1"
                  name="password1"
                  placeholder="Enter old password"
                  onBlur={() => checkField("oldpass")}
                  type={oldPassType ? "text" : "password"}
                  value={oldpass}
                  onChange={(e) => setOldPass(e.target.value)}
                />
                <span
                  onClick={handleOldPass}
                  style={{
                    paddingTop: "10px",
                    backgroundColor: "white",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    borderTop: "1px solid lightgrey",
                    borderRight: "1px solid lightgrey",
                    borderBottom: "1px solid lightgrey",
                    paddingRight: "5px",
                  }}
                >
                  {oldPassType == false ? (
                    <i class="fa fa-eye"></i>
                  ) : (
                    <i class="fas fa-eye-slash"></i>
                  )}
                </span>
              </InputGroup>

              {err !== "" && err.includes("Old") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="password2">New Password</Label>
              <InputGroup>
                <Input
                  id="password2"
                  name="password2"
                  placeholder="Enter new password"
                  onBlur={() => checkField("newpass")}
                  type={passType ? "text" : "password"}
                  value={newpass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
                <span
                  onClick={handlePass}
                  style={{
                    paddingTop: "10px",
                    backgroundColor: "white",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    borderTop: "1px solid lightgrey",
                    borderRight: "1px solid lightgrey",
                    borderBottom: "1px solid lightgrey",
                    paddingRight: "5px",
                  }}
                >
                  {passType == false ? (
                    <i class="fa fa-eye"></i>
                  ) : (
                    <i class="fas fa-eye-slash"></i>
                  )}
                </span>
              </InputGroup>
              {err !== "" && err.includes("New") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="password3">Re-enter Password</Label>
              <InputGroup>
                <Input
                  id="password3"
                  name="password3"
                  placeholder="Re-enter password"
                  type={confirmType ? "text" : "password"}
                  onBlur={() => checkField("conf")}
                  value={confpass}
                  onChange={(e) => setConfPass(e.target.value)}
                />
                <span
                  onClick={handleConfirm}
                  style={{
                    paddingTop: "10px",
                    backgroundColor: "white",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    borderTop: "1px solid lightgrey",
                    borderRight: "1px solid lightgrey",
                    borderBottom: "1px solid lightgrey",
                    paddingRight: "5px",
                  }}
                >
                  {confirmType == false ? (
                    <i class="fa fa-eye"></i>
                  ) : (
                    <i class="fas fa-eye-slash"></i>
                  )}
                </span>
              </InputGroup>
              {err !== "" && err.includes("Confirm") && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {err} <i className="fas fa-exclamation-circle mb--2"></i>
                  </span>
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Button
          disabled={loading}
          onClick={() => {
            if (
              oldpass.trim().length === 0 ||
              newpass.trim().length === 0 ||
              confpass.trim().length === 0
            ) {
              swal("Sorry!", "All fields are required", "error");
            } else if (newpass !== confpass) {
              swal("Sorry!", "Password is not matched", "error");
            } else {
              handleSubmit();
            }
          }}
          style={{ width: "100%", backgroundColor: "#015652", color: "white" }}
          // color="warning"
          outline
        >
          {loading ? <Loading attr="Change password" /> : "Change password"}
        </Button>
      </ModalBody>
      {/* <ModalFooter>
          <Row>
            <Col>
              <h1 style={{ color: "#6d7075" }}>Rs:{values.price}</h1>
            </Col>
            <Col>
              <Button
                style={{ backgroundColor: "#015652  ", color: "white" }}
                onClick={confirm}
                disabled={numCount === 30}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </ModalFooter> */}
    </Modal>
  );
};
export default ChangePassword;
