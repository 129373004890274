import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import Logo from "assets/img/whiteee1.png";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const StaffLogin = (props) => {
  let history = useHistory();

  const [body, setBody] = useState({
    email: "",
    password: "",
  });
  const [isTrue, setIsTrue] = useState(false);
  const [type, setType] = useState(false);
  const [error, setError] = useState({
    emailError: "",
    passwordError: "",
  });

  const handlePasswordShow = () => {
    setType(!type);
  };

  const ValidateLogin = () => {
    if (body.email !== "" && body.password !== "") {
      props.StaffLoginMiddleware(body, OnSuccess, OnFailure);
    } else {
      swal("Sorry!", "Please Enter Email and Password", "error");
    }
  };

  const OnSuccess = () => {
    swal({
      title: "Congratulations!",
      text: "You have Logged in successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        setIsTrue(true);
      }
    });
  };
  const OnFailure = (error) => {
    if (error.message == "Please verified your account") {
      swal({
        title: "Sorry!",
        text: error.message,
      }).then(function (isConfirm) {
        if (isConfirm) {
          history.push("/auth/verifyLoginUser");
        }
      });
    } else {
      swal("Sorry!", error.message, "error");
    }
  };
  useEffect(() => {
    if (props.Data !== null && props.Data !== undefined) {
      let values = {
        token: props.Data.data.token,
        user: JSON.stringify(props.Data.data.user),
        roleid: props.Data.data.user.roleId,
      };
      localStorage.setItem("auth", values.token);
      localStorage.setItem("user", values.user);
      localStorage.setItem("roleid", values.roleid);
      if (values.token === "") {
        let path = "/auth/login";
        history.push(path);
      } else {
        // let path = "/user/requestUserView";
        // history.push(path);
      }
    }
  }, [props.Data]);

  const OnChange = (value, name) => {
    setBody({
      ...body,
      [name]: value,
    });
  };

  const CheckFields = (name) => {
    if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", body.email),
      });
    } else if (name === "password") {
      setError({
        ...error,
        passwordError: validate("password", body.password),
      });
    }
  };

  useEffect(() => {
    if (isTrue === true && localStorage.getItem("roleid") == 3) {
      let path = "/user/AdminRequestList";
      history.push(path);
    } else if (isTrue === true && localStorage.getItem("roleid") == 2) {
      let path = "/user/addNewOrder";
      history.push(path);
    } else if (isTrue === true && localStorage.getItem("roleid") == 4) {
      let path = "/user/createCustomer";
      history.push(path);
    }
  }, [isTrue]);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="customLogin">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img height="150px" width="150px" src={Logo} /> <br />
              <h3 style={{ color: "white" }}>
                <b>Sign In</b>
              </h3>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    onBlur={() => CheckFields("email")}
                    value={body.email}
                    onChange={(e) => OnChange(e.target.value, "email")}
                  />
                </InputGroup>
                {error.emailError !== "" && error.emailError !== null && (
                  <small>
                    <span style={{ color: "red" }}>
                      {error.emailError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup
                  style={{ backgroundColor: "white" }}
                  className="input-group-alternative"
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={type ? "text" : "password"}
                    onBlur={() => CheckFields("password")}
                    value={body.password}
                    onChange={(e) => OnChange(e.target.value, "password")}
                  />
                  <span
                    className="password__show"
                    onClick={handlePasswordShow}
                    style={{
                      paddingTop: "10px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      paddingRight: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className={
                        type == false ? "fa fa-eye" : "fas fa-eye-slash"
                      }
                    ></i>
                  </span>
                </InputGroup>
                {error.passwordError !== "" && error.passwordError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.passwordError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  onClick={ValidateLogin}
                >
                  Sign In
                </Button>
              </div>
            </Form>
            <Row className="mt-3 text-center">
              <Col xs="12">
                <a
                  className="text-light"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: "/auth/forgetPassword",
                      state: { string: "STAFF" },
                    });
                  }}
                >
                  <small style={{ color: "white" }}>Forgot password?</small>
                </a>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default StaffLogin;
