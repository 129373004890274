import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Input,
} from "reactstrap";
import "react-h5-audio-player/lib/styles.css";
import { AppReqMiddleware } from "../middleware";
import { connect } from "react-redux";
import swal from "sweetalert";
const RequestStaffViewModal = ({ toggle, modal, user }) => {
  return (
    <Modal size="sm" isOpen={modal} toggle={toggle}>
       <ModalHeader toggle={toggle} style={{ backgroundColor: "#d6992a" }}>
        <h2 style={{ color: "white", textAlign: "center" }}>Details</h2>
      </ModalHeader>
      <ModalBody>
        <div>
          <Row className=" mt-3">
            <label className="form-control-label" for="input-username">
              Amount : {user?.Amount}
            </label>
          </Row>
          <Row className=" mt-3">
            <label className="form-control-label" for="input-username">
              Status : {user?.Status}
            </label>
          </Row>
          <Row className=" mt-3">
            <label className="form-control-label" for="input-username">
              Form Purchased : {user?.count}
            </label>
          </Row>
          <Row className=" mt-3">
            <label className="form-control-label" for="input-username">
              Form Remaining : {30 - user?.count}
            </label>
          </Row>
          <Row className=" mt-3">
            <label className="form-control-label" for="input-username">
              Order Type :{user?.orderType}
            </label>
          </Row>
          <Row className=" mt-3">
            <label className="form-control-label" for="input-username">
              Order Date :{user?.createdAt?.split("T")[0]}
            </label>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={toggle}
            >
              Close
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default RequestStaffViewModal;
