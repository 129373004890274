import axios from "axios";
import {
  CustomerDetail,
  PlotTypeDetail,
  CustomerDraftDetail,
  CustomerDraftIdDetail,
  CustomerUpdateDetail,
  CustomerDraftUpdateDetail,
  VerifyResidentialTypeByCNIC,
} from "../action/index";
import {
  CUSTOMER_PATH,
  PLOTTYPE_PATH,
  CUSTOMERDRAFT_PATH,
  CUSTOMERDRAFTID_PATH,
  CUSTOMERUPDATE_PATH,
  CUSTOMERDRAFTUPDATE_PATH,
  VERIFY_BY_CNIC_PATH,
} from "../constant";
import { BASEURL } from "config/api/URL";

export const UpdateDraftCustomerMiddleware =
  (body, OnSuccess, OnFailure) => (dispatch) => {
    dispatch(CustomerDraftUpdateDetail.CustomerDraftUpdate());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CUSTOMER_PATH}/multi/form/submission`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // if (res.data.status === true) {
        dispatch(
          CustomerDraftUpdateDetail.CustomerDraftUpdate_Success(res.data.data)
        );
        dispatch(OnSuccess(res.message));
        //}
      })
      .catch(function (error) {
        if (error.response) {
          OnFailure(error.response.data);
        } else if (error.request) {
        } else {
        }
      });
  };
export const UpdateCustomerMiddleware =
  (id, body, OnSuccess, OnFailure) => (dispatch) => {
    dispatch(CustomerUpdateDetail.CustomerUpdate());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CUSTOMER_PATH}/${id}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // if (res.data.status === true) {
        dispatch(CustomerUpdateDetail.CustomerUpdate_Success(res.data.data));
        dispatch(OnSuccess(res.message));
        //}
      })
      .catch(function (error) {
        if (error.response) {
          OnFailure(error.response.data);
        } else if (error.request) {
        } else {
        }
      });
  };

export const showDraftCustomer = (id, OnSuccess, OnFailure) => (dispatch) => {
  dispatch(CustomerDraftIdDetail.CustomerDraftId());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${CUSTOMER_PATH}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      // if (res.data.status === true) {
      console.log("DREFFFT DATA", res.data.data);
      dispatch(CustomerDraftIdDetail.CustomerDraftId_Success(res.data.data));
      dispatch(OnSuccess(res.message));
      //}
    })
    .catch(function (error) {
      if (error.response) {
        OnFailure(error.response.data);
      } else if (error.request) {
      } else {
      }
    });
};

export const showPlotType = (OnSuccess, OnFailure) => (dispatch) => {
  dispatch(PlotTypeDetail.PlotType());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${PLOTTYPE_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        //  Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      // if (res.data.status === true) {
      // console.log("PLOT TYPE", res.data.data);
      dispatch(PlotTypeDetail.PlotType_Success(res.data.data));
      dispatch(OnSuccess(res.message));
      //}
    })
    .catch(function (error) {
      if (error.response) {
        OnFailure(error.response.data);
      } else if (error.request) {
      } else {
      }
    });
};
export const InsertCustomerDetail =
  (body, OnSuccess, OnFailure) => (dispatch) => {
    console.log("BODY AT INSERT MIDDLEWARE", body);
    dispatch(CustomerDetail.Customer());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CUSTOMER_PATH}/multi/form/submission`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("DETAILS", res.data.data);
        // if (res.data.status === true) {
        dispatch(CustomerDetail.Customer_Success(res.data.data));
        dispatch(OnSuccess(res.data.data));
        //}
      })
      .catch(function (error) {
        if (error.response) {
          OnFailure(error.response.data);
        } else {
          console.log("Error", error);
        }
      });
  };
export const InsertCustomerDraftDetail =
  (body, OnSuccess, OnFailure) => (dispatch) => {
    dispatch(CustomerDraftDetail.CustomerDraft());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CUSTOMERDRAFT_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("DETAILS", res.data.data);
        // console.log(res.data.data, "res");
        // if (res.data.status === true) {
        dispatch(CustomerDraftDetail.CustomerDraft_Success(res.data.data));
        dispatch(OnSuccess(res.message));
        //}
      })
      .catch(function (error) {
        if (error.response) {
          OnFailure(error.response.data);
        } else if (error.request) {
        } else {
        }
      });
  };

export const VerifyByCNIC = (cnic, onsuccess, onfailure) => (dispatch) => {
  console.log(cnic);
  dispatch(VerifyResidentialTypeByCNIC.verify());
  axios
    .get(`${BASEURL}${CUSTOMER_PATH}/booking-count/remaining/${cnic}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        // console.log("ZAMCNINC", res.data.data);
        dispatch(VerifyResidentialTypeByCNIC.verify_Success(res.data.data));
        dispatch(onsuccess(res.data.data));
      }
    })
    .catch(function (error) {
      console.log("Error", error);
    });
};
