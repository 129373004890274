import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Input,
} from "reactstrap";
import "react-h5-audio-player/lib/styles.css";
import { AppReqMiddleware, PayForm_Fee_Middleware } from "../middleware";
import { connect } from "react-redux";
import swal from "sweetalert";
const PayNowModal = ({ toggle, modal, user, _PayForm_Fee_Middleware }) => {
  const [slip, setSlip] = useState("");
  const [isTrue, setIsTrue] = useState(false);
  const [error, setError] = useState({
    slipError: "",
  });
  const onSuccess = (message) => {
    // swal("Succesful!", "Payment Succesfull", "success");
    swal("Succesful!", message, "success");
    setSlip("");
    setError({
      slipError: "",
    });
    toggle();
    setIsTrue(false);
  };
  const onFailure = (msg) => {
    setIsTrue(false);
    swal("Sorry!", msg, "error");
  };
  const CheckFields = (name) => {
    if (name === "slip") {
      if (slip?.length < 4) {
        setError({
          ...error,
          slipError: "Not valid, minimum 4 and maxiumum 11 digits required ",
        });
      } else {
        setError({
          ...error,
          slipError: "",
        });
      }
    }
  };
  const handleSendData = () => {
    if (slip.length >= 4) {
      setError({
        ...error,
        slipError: "",
      });
      setIsTrue(true);
      let body = {
        applicationRequestId: user.id,
        depositSlipNo: slip,
      };
      _PayForm_Fee_Middleware(body, onSuccess, onFailure);
    } else {
      setError({
        ...error,
        slipError: "Not valid, minimum 4 and maxiumum 11 digits required ",
      });
    }
  };
  const close = () => {
    setSlip("");
    setError({
      slipError: "",
    });
    toggle();
    setIsTrue(false);
  };
  return (
    <Modal size="sm" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d6992a" }}>
        <h2 style={{ color: "white", textAlign: "center" }}>Bank Slip</h2>
      </ModalHeader>
      <ModalBody>
        <div>
          <Row className=" mt-3">
            <Col lg="12" md="12" sm="12" xs="12">
              <label className="form-control-label" for="input-username">
                Bank Deposit Slip No:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Enter Slip Number"
                value={slip}
                autoComplete="off"
                maxLength={11}
                onBlur={() => CheckFields("slip")}
                onChange={(e) => setSlip(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {error.slipError !== "" && error.slipError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.slipError}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={handleSendData}
              disabled={isTrue}
            >
              Submit
            </Button>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={close}
            >
              Close
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  // StaffRequests: state.staffRequest.StaffRequests,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _PayForm_Fee_Middleware: (body, onSuccess, onFailure) =>
      dispatch(PayForm_Fee_Middleware(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(PayNowModal);
