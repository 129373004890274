import React from "react";
import { Col, Input, Row, Table, Label } from "reactstrap";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const TableWinnerView = ({ post = [], onChangeNoOfRows }) => {
  // const history = useHistory();
  //DeleteById_Middleware
  // const [isOpen, setIsOpen] = useState(false);
  // const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  // const [viewReceipt, setViewReceipt] = useState(false);
  // const [isOk, setIsOk] = useState(false);
  // const [printReceiptInfo, setPrintReceiptInfo] = useState({});
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  // const print = (receipt) => {
  //   setPrintReceiptInfo(receipt);
  //   setIsOk(true);
  // };
  // const printDocument = (receipt) => {
  //   let date = new Date().toISOString().split("T")[0];
  //   const doc = new jsPDF();
  //   doc.addImage(Logo, "png", 15, 5, 50, 20);
  //   doc.text("Payment Receipt", 88, 40);
  //   doc.setFontSize(10);
  //   doc.text("Date: " + date, 168, 18);
  //   doc.autoTable({
  //     theme: "grid",
  //     head: [["Customer Details", ""]],
  //     body: [
  //       ["Receipt No.", receipt.receiptNo],
  //       ["Name", receipt.name],
  //       ["Contact No.", receipt.whatsappNumber],
  //       ["Email", receipt.email],
  //       ["CNIC No.", receipt.cnic],
  //     ],
  //     margin: { top: 55 },
  //     styles: { lineColor: 10, cellWidth: 91 },
  //   });
  //   doc.autoTable({
  //     theme: "grid",
  //     head: [["Payment Details", ""]],
  //     body: [
  //       ["Payment Through", receipt.receipts?.[0].paymentThrough.title],
  //       [
  //         "Payment Ref #",
  //         receipt.receipts?.[0].PaymentReferenceNo
  //           ? receipt.receipts?.[0].PaymentReferenceNo
  //           : "-",
  //       ],
  //       ["Amount", receipt.receipts?.[0].amount],
  //       ["Remarks", receipt.receipts?.[0].remarks],
  //     ],
  //     margin: { top: 55 },
  //     styles: { lineColor: 10, cellWidth: 91 },
  //   });
  //   doc.setFontSize(10);
  //   doc.text("Singnature:________________", 145, 170);

  //   doc.save(`Payment receipt ${receipt.receiptNo}.pdf`);
  // };
  // const ToggleUpdateReceiptModal = () => {
  //   setIsOpen(!isOpen);
  // };
  // const ToggleViewReceiptModal = (post) => {
  //   setIsViewModalOpen(!isViewModalOpen);
  //   setViewReceipt(post);
  // };
  // const handleDelete = (id) => {
  //   _DeleteById_Middleware(id, onSuccess, onFailure);
  // };
  // const onSuccess = () => {
  //   swal("Form Deleted successfully!", "success");
  // };
  // const onFailure = () => {};
  // const cancelForm = (formNo) => {
  //   let body = {
  //     FormNo: formNo,
  //   };
  //   _FormCancelation(body, onSuccessCancelation, onFailureCancelation);
  // };
  // useEffect(() => {
  //   if (isOk === true) {
  //     handlePrint();
  //     setIsOk(false);
  //   }
  // }, [isOk]);
  return (
    <div>
      {/* <UpdatePaymentReceipt toggle={ToggleUpdateReceiptModal} modal={isOpen} /> */}
      {/* <ViewReceiptModal
        toggle={ToggleViewReceiptModal}
        modal={isViewModalOpen}
        viewReceipt={viewReceipt}
      /> */}

      {/* <ComponentToPrint
        ref={componentRef}
        // printReceiptInfo={printReceiptInfo}
      /> */}
      <Row className="mt-5">
        <Col xl="2" lg="4" md="4" sm="6" xs="6">
          <Label className="form-control-label"> Rows Per Pages </Label>
          <Input
            id="exampleFormControlSelect1"
            type="select"
            onChange={(e) => onChangeNoOfRows(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Input>
        </Col>
      </Row>
      <br />
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile No.</th>
            <th scope="col">CNIC</th>
            <th scope="col">Portal Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {post !== null && post !== undefined && post.length > 0 ? (
            post.map((request, index) => {
              return (
                <tr key={request.id}>
                  <td>{index + 1}</td>
                  {/* <td>{request?.id}</td> */}
                  <td>{request?.allApplication?.applicationV2?.name}</td>
                  {/* <td>{request?.allApplicationId}</td> */}
                  <td>{request?.allApplication?.applicationV2?.email}</td>
                  <td>{request?.allApplication?.applicationV2?.mobile}</td>
                  <td>{request?.allApplication?.applicationV2?.cnic}</td>
                  <td>{request?.allApplication?.portalType}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableWinnerView;
