import React, { useEffect } from "react";
import swal from "sweetalert";
import { useState } from "react";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router";


const TableOfBankList = (props) => {



  let history = useHistory();
const onEdit=(e)=>{
 
    
    history.push({
      pathname:'/user/Bank',
      state:e
    })

}
 const onDelete=(e)=>{
  swal({
    title: "Warning",
    text: "Are You Sure You Want To Delete This Bank?",
    icon: "warning",
    // buttons : true,
  }).then((isOk) => {
    if (isOk) {
      let id=e;
      props.DeleteBankMiddleware(id,onSuccess, onFailure)
    }
  });

 }

const onSuccess=()=>{
  swal({
    title: "Successfully!",
    text: "Bank Deleted successfully",
    icon: "success",
    // buttons : true,
  }).then((isOk) => {
    if (isOk) {
      
    }
  });
}
const onFailure=()=>{}
  return (
    <>
     

      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929", color:"white" }} >
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Bank Name</th>
            <th scope="col">Bank Description</th>
          <th></th>
          </tr>
        </thead>
        <tbody>
          {props.paginatedPosts !== null &&
          props.paginatedPosts !== undefined ? (
            props.paginatedPosts.map((posts, index) => {
              return (
                <tr>
                  <td>{index+1}</td>
                  <td>{posts.bankName}</td>
                  <td>{posts.bankDescription}</td>
                  
                  
                 <td>
                 <Button
                      style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                    onClick={() => onEdit(posts)}
                      id="info"
                   
                    >
                      <span className="btn-inner--icon">
                       Edit
                      </span>
                    </Button>
                    <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={()=> onDelete(posts.id)}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       Delete
                      </span>
                    </Button>
                 </td>
                </tr>
              );
            }
            )
          ) : (
            <tr>
              <th></th>
              <th></th>

              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
         
        </tbody>
      </Table>
    </>
  );
};
export default TableOfBankList;
