import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";

import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";



const Filteration = (props) => {
  const history = useHistory();
  const location= useLocation();
  const [state, setState] = useState({
    bankName:"",
    bankDescription:""
     });
     const [error, setError] = useState({
       bankNameError: "",
       bankDescriptionError:""
   
     });

  useEffect(() => {
    if(location.state!==null && location.state!==undefined)
    {
      setState({...state,
        bankName:location.state?.bankName,
        bankDescription:location.state?.bankDescription
       })
    }
   
  }, [location.state])
  

 
 



  // let dataInArrayForPaginaion = [];
  // useEffect(() => {
  //   setPosts(null);
  //   setPageNumber(1);
  //   if (props.Client !== null && props.Client !== undefined) {
  //     for (let i = 0; i < props.Client.length; i++) {
  //       dataInArrayForPaginaion.push(props.Client[i]);
  //       setPosts(props.Client);
  //     }
  //   } else {
  //   }
  // }, [props.Client]);


 
  const onChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  

  //Start Validation
  const CheckFields = (name) => {
    if (name === "bankName") {
      setError({
        ...error,
        bankNameError: validate("required", state.bankName),
      });
    }
    else if(name==="bankDescription") {
      setError({
        ...error,
        bankDescriptionError: validate("required", state.bankDescription),
      });
    }
  };

  
  

 

 

 const submit=()=>{
 
  setError({
    ...error,
    bankNameError: validate("required", state.bankName),
    bankDescriptionError: validate("required", state.bankDescription),

  });
 
  if(location.state!==null && location.state!==undefined && 
   // error.bankNameError==null && error.bankDescriptionError==null
   state.bankName!=="" && state.bankDescription!==""
    )
  {
   
    let id=location.state.id;
    // let body = {
    //   bankName: state.bankName,
    //   bankDescription: state.bankDescription,
    // };
    props.EditBankMiddleware(id,state,onSuccesEdit,onFailureEdit)
   // props.InsertBankMiddleware(body, onSuccess, onFailure);

  }
  
  else if( state.bankName!=="" && state.bankDescription!=="")
  {  
  //   let body = {
  //   bankName: state.bankName,
  //   bankDescription: state.bankDescription,
  // };

  props.InsertBankMiddleware(state, onSuccess, onFailure);

  }
  

 }
 const onSuccesEdit=()=>{

 setState({...state,  bankName:"",
    bankDescription:""})
  
    swal({
      title: "Success!",
      text: "Bank Edited Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      //if (isOk) {
        let path = "/user/BankList";
        history.push(path);
     // }
    });
  };
  const onFailureEdit = (error) => {
    
    swal("Sorry!", error.message, "error");

  };

  const onSuccess = () => {
    setState({...state,  bankName:"",
    bankDescription:""})
  
    swal({
      title: "Success!",
      text: "Bank Added Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      //if (isOk) {
        let path = "/user/BankList";
        history.push(path);
      //}
    });
  };
  const onFailure = (error) => {
    
    swal("Sorry!", error.message, "error");

  };




  return (
    <CardHeader className="border-0">
    <Row>
      <Col  lg="2" md="2" sm="6">
        <h2>
        Bank Form
        </h2>
      </Col>
      <Col  lg="8" md="8" sm="6"></Col>
      <Col  lg="2" md="2" sm="6">
   
      </Col>
      
    </Row>
    <hr/>
      <CardBody>
      
        <form>
          
            <Row>
            <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
                Bank Name*
                </label>
                <input
                  type="text"
                  onBlur={() => CheckFields("bankName")}
            
                id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.bankName}
                  onChange={(e) => onChange(e.target.value, "bankName")}
                ></input>
                  {error.bankNameError !== "" && error.bankNameError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.bankNameError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}

              </Col>
              <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
                Bank Description*
                </label>
                <input
                  type="text"
               
                  onBlur={() => CheckFields("bankDescription")}
                  id="input-username"
                  className="form-control"
                  placeholder=""
                  value={state.bankDescription}
                  onChange={(e) => onChange(e.target.value, "bankDescription")}
                ></input>
                   {error.bankDescriptionError !== "" && error.bankDescriptionError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.bankDescriptionError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
              </Col>
             
            
         
             
            </Row>
            <Row>
            <Col lg="4" md="4" sm="6"> </Col>
            <Col lg="4" md="4" sm="6"></Col>
            <Col lg="4" md="4" sm="6">
              <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={submit}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       Submit
                      </span>
                    </Button>
              </Col>

            </Row>
           
            
           
        
        </form>
      
      </CardBody>
    
    </CardHeader>
  );
};
export default Filteration;
