import Bank from "views/AdminView/Bank/container";
import BankList from "views/AdminView/BankList/container";
import AdminRequestList from "views/AdminView/AdminApplicationList/container";
import AdminFeedbackList from "views/AdminView/FeedbackList/container";
import StaffList from "views/AdminView/StaffList/container";
import AddNewStaff from "views/AdminView/StaffList/components/AddNewStaff";
import ManagerList from "views/AdminView/ManagerList/container";
import AddNewManager from "views/AdminView/ManagerList/components/AddNewManager"; //AgentAppList
import BankAppList from "views/AdminView/BankAppList/container";
import BankAppDetailsList from "views/AdminView/BankAppDetailsList/container";
import BranchList from "views/AdminView/BranchList/container";
import Branch from "views/AdminView/Branch/container";
import ApplicationList from "views/AdminView/ApplicationList/container";
import CustomerList from "views/AdminView/CustomerList/container";
import Customer from "views/AdminView/Customer/container";
import MembershipForm from "views/AdminView/MembershipForm/container";
import AgentList from "views/AdminView/AgentList/container";
import Pricing from "views/AdminView/Pricing/container";
import AgentStaffListAdmin from "views/AdminView/AgentStaffListAdmin/container";
import AgentAppList from "views/AdminView/AgentApplicationList/container";
import AgentStaff from "views/AdminView/AgentStaff/container";
import QueryList from "../components/Support/Adminlist/container";
import PublicList from "../components/Support/PublicList/container";
import CreateQuery from "../components/Support/Createquery/container";
import Dashboard from "views/AdminView/Dashboard/container";
import Balloting from "views/AdminView/Balloting/container";
import Verify from "views/AdminView/Verify_Blocked/container";
import Reports from "views/AdminView/Reports/container";
import BankRegistration from "views/AdminView/BankRegistration/container";
import FormGenerate from "views/AdminView/FormGenerate/container";
import FormExport from "views/AdminView/FormExport/container";
import FormImport from "views/AdminView/FormImport/container";
import RealStateDealer from "views/AdminView/RealStateDealer/container";
var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-angle-right",
    component: Dashboard,
    layout: "/user",
    isView: true,
  },
  {
    path: "/realstateDealer",
    name: "Real State Dealers",
    icon: "fas fa-angle-right",
    component: RealStateDealer,
    layout: "/user",
    isView: true,
  },
  {
    path: "/AdminRequestList",
    name: "View Request List",
    icon: "fas fa-angle-right",
    component: AdminRequestList,
    layout: "/user",
    isView: true,
  },

  {
    path: "/AdminFeedbackList",
    name: "View Feedback List",
    icon: "fas fa-angle-right",
    component: AdminFeedbackList,
    layout: "/user",
    isView: true,
  },
  {
    path: "/CustomerList",
    name: "CustomerList",
    icon: "fas fa-angle-right",
    component: CustomerList,
    layout: "/user",
    isView: true,
  },
  {
    path: "/Pricing",
    name: "Pricing",
    icon: "fas fa-angle-right",
    component: Pricing,
    layout: "/user",
    isView: true,
  },
  {
    path: "/verify",
    name: "Verify",
    icon: "fas fa-angle-right",
    component: Verify,
    layout: "/user",
    isView: true,
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "fas fa-angle-right",
    component: Reports,
    layout: "/user",
    isView: true,
  },
  {
    path: "/balloting",
    name: "Balloting",
    icon: "fas fa-angle-right",
    component: Balloting,
    layout: "/user",
    isView: true,
  },
  {
    path: "/BankRegistration",
    name: "BankRegistration",
    icon: "fas fa-angle-right",
    component: BankRegistration,
    layout: "/user",
    isView: true,
  },
  {
    path: "/FormGenerate",
    name: "FormGenerate",
    icon: "fas fa-angle-right",
    component: FormGenerate,
    layout: "/user",
    isView: true,
  },
  {
    path: "/FormExport",
    name: "FormExport",
    icon: "fas fa-angle-right",
    component: FormExport,
    layout: "/user",
    isView: true,
  },
  {
    path: "/FormImport",
    name: "FormImport",
    icon: "fas fa-angle-right",
    component: FormImport,
    layout: "/user",
    isView: true,
  },
  {
    name: "Banking Portal",
    icon: "fab fa-wpforms ",
    isView: true,
    subNav: [
      {
        path: "/managerList",
        name: "Manager List",
        icon: "fas fa-angle-right",
        component: ManagerList,
        layout: "/user",
        isView: true,
      },

      {
        path: "/staffList",
        name: "Staff List",
        icon: "fas fa-angle-right",
        component: StaffList,
        layout: "/user",
        isView: true,
      },
      {
        path: "/bankAppList",
        name: "List Of Applicants",
        icon: "fas fa-angle-right",
        component: BankAppList,
        layout: "/user",
        isView: true,
      },
      {
        path: "/bankAppDetailsList",
        name: "Bank Appliction Details List",
        icon: "fas fa-angle-right",
        component: BankAppDetailsList,
        layout: "/user",
        isView: true,
      },
      {
        path: "/BankList",
        name: "Bank Register",
        icon: "fas fa-angle-right",
        component: BankList,
        layout: "/user",
        isView: true,
      },
      {
        path: "/BranchList",
        name: "Add New Branch",
        icon: "fas fa-angle-right",
        component: BranchList,
        layout: "/user",
        isView: true,
      },
    ],
  },
  {
    name: "Agent Portal",
    icon: "fab fa-wpforms ",
    isView: true,
    subNav: [
      {
        path: "/agentAppList",
        name: "Agent Appliction List",
        icon: "fas fa-angle-right",
        component: AgentAppList,
        layout: "/user",
        isView: true,
      },
      {
        path: "/Agent",
        name: "Register Agent List",
        icon: "fas fa-angle-right",
        component: AgentList,
        layout: "/user",
        isView: true,
      },
      {
        path: "/AgentStaffListAdmin",
        name: "Real State Agent StaffList",
        icon: "fas fa-angle-right",
        component: AgentStaffListAdmin,
        layout: "/user",
        isView: true,
      },
    ],
  },
  {
    name: "Support",
    icon: "fab fa-wpforms ",
    isView: true,
    subNav: [
      {
        path: "/QueriesList",
        name: "View Queries List",
        icon: "fas fa-angle-right",
        component: QueryList,
        layout: "/user",
        isView: true,
      },
      {
        path: "/Createissue",
        name: "create isssue",
        icon: "fas fa-angle-right",
        component: CreateQuery,
        layout: "/user",
        isView: true,
      },
    ],
  },
  {
    path: "/PublicList",
    name: "Public Queries",
    icon: "fas fa-angle-right",
    component: PublicList,
    layout: "/user",
    isView: true,
  },
  // {
  //   name: "Public Support",
  //   icon: "fab fa-wpforms ",
  //   isView: true,
  //   subNav: [
  //     {
  //       path: "/PublicList",
  //       name: "View Queries List",
  //       icon: "fas fa-angle-right",
  //       component: PublicList,
  //       layout: "/user",
  //       isView: true,
  //     },
  //     {
  //       path: "/Createissue",
  //       name: "create isssue",
  //       icon: "fas fa-angle-right",
  //       component: CreateQuery,
  //       layout: "/user",
  //       isView: true,
  //     },
  //   ],
  // },
  {
    path: "/AddNewStaff",
    name: "Add New Staff",
    icon: "fas fa-angle-right",
    component: AddNewStaff,
    layout: "/user",
    isView: false,
  },
  {
    path: "/Bank",
    name: "Bank Form",
    icon: "fas fa-angle-right",
    component: Bank,
    layout: "/user",
    isView: false,
  },
  {
    path: "/Branch",
    name: "Branch",
    icon: "fas fa-angle-right",
    component: Branch,
    layout: "/user",
    isView: false,
  },
  {
    path: "/AddNewManager",
    name: "Add New Manager",
    icon: "fas fa-angle-right",
    component: AddNewManager,
    layout: "/user",
    isView: false,
  },
  {
    path: "/ApplicationList",
    name: "ApplicationList",
    icon: "fas fa-angle-right",
    component: ApplicationList,
    layout: "/user",
    isView: false,
  },
  {
    path: "/MembershipForm",
    name: " Registration Form",
    icon: "fas fa-angle-right",
    component: MembershipForm,
    layout: "/user",
    isView: false,
  },

  {
    path: "/Customer",
    name: "Customer",
    icon: "fas fa-angle-right",
    component: Customer,
    layout: "/user",
    isView: false,
  },

  {
    path: "/AgentStaff",
    name: "AgentStaff",
    icon: "fas fa-angle-right",
    component: AgentStaff,
    layout: "/user",
    isView: false,
  },
];
export default routes;
