import {
  BANK_REGISTRATION,
  BANK_REGISTRATION_SUCCESS,
  BANK_REGISTRATION_FAILURE,
  UPLOAD_MULTI_FILE,
  UPLOAD_MULTI_FILE_SUCCESS,
  UPLOAD_MULTI_FILE_FAILURE,
} from "../constant";

export class UploadMultiFilesAction {
  static upload() {
    return {
      type: UPLOAD_MULTI_FILE,
    };
  }
  static upload_Success(response) {
    return {
      type: UPLOAD_MULTI_FILE_SUCCESS,
      payload: response,
    };
  }
  static upload_Failure(error) {
    return {
      type: UPLOAD_MULTI_FILE_FAILURE,
      error,
    };
  }
}

export class BankRegistrationAction {
  static _Reg() {
    return {
      type: BANK_REGISTRATION,
    };
  }
  static _Reg_Success(response) {
    return {
      type: BANK_REGISTRATION_SUCCESS,
      payload: response,
    };
  }
  static _Reg_Failure(error) {
    return {
      type: BANK_REGISTRATION_FAILURE,
      error,
    };
  }
}
