import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFY_BY_EMAIL,
  VERIFY_BY_EMAIL_SUCCESS,
  VERIFY_BY_EMAIL_FAILURE,
  QEURY,
  QEURY_SUCCESS,
  QEURY_FAILURE,
  QEURY_PATH,
  SEND_QUERY,
  SEND_QUERY_SUCCESS,
  SEND_QUERY_FAILURE,
  SEND_QUERY_PATH,
} from "../constant.js";
const INITIAL_STATE = {
  Data: null,
  QueryIssue: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case LOGIN:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Data: null,
        error: {},
      };
    case LOGIN_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Data: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case QEURY:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Data: null,
        error: {},
      };
    case QEURY_SUCCESS:
      return {
        ...states,

        QueryIssue: action.payload,
      };
    case QEURY_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case SEND_QUERY:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Response: null,
        error: {},
      };
    case SEND_QUERY_SUCCESS:
      return {
        ...states,
        isLoading: false,
        Response: action.payload,
      };
    case SEND_QUERY_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case VERIFY_BY_EMAIL:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Response: null,
        error: {},
      };
    case VERIFY_BY_EMAIL_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Response: action.payload,
      };
    case VERIFY_BY_EMAIL_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
