export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCESS";
export const LOGIN_FAILURE = "LOGIN_FALURE";
export const LOGIN_PATH = "auth/user/login";

export const VERIFY_BY_EMAIL = "VERIFY_BY_EMAIL";
export const VERIFY_BY_EMAIL_SUCCESS = "VERIFY_BY_EMAIL_SUCCESS";
export const VERIFY_BY_EMAIL_FAILURE = "VERIFY_BY_EMAIL_FAILURE";
export const VERIFY_BY_EMAIL_PATH = "auth/account-verify";

export const QEURY = "QEURY";
export const QEURY_SUCCESS = "QEURY_SUCESS";
export const QEURY_FAILURE = "QEURY_FALURE";
export const QEURY_PATH = "support-system/issue-type";

export const SEND_QUERY = "SEND_QUERY";
export const SEND_QUERY_SUCCESS = "SEND_QUERY_SUCCESS";
export const SEND_QUERY_FAILURE = "SEND_QUERY_FAILURE";
export const SEND_QUERY_PATH = "public-support/public-request";
