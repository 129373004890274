import axios from "axios";

import { ApplicationListDetail, CustomerShowDetail } from "../action/index";

import { APPLICATIONLIST_PATH, CUSTOMERSHOW_PATH } from "../constant";

import { BASEURL } from "config/api/URL";

export const CustomerInfoMiddleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(CustomerShowDetail.CustomerShow());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${CUSTOMERSHOW_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(CustomerShowDetail.CustomerShow_Success(res.data.data));
        dispatch(onSuccess(res.message));
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response);
        } else if (error.request) {
        } else {
        }
      });
  };

export const GetApplicationListMiddleware =
  (pageNumber, noOfRows, onSuccess, onFailure) => (dispatch) => {
    dispatch(ApplicationListDetail.Applicationlist());
    let token = localStorage.getItem("auth");
    // const limit = 100;
    axios
      .get(
        `${BASEURL}${APPLICATIONLIST_PATH}?page=${pageNumber}&limit=${noOfRows}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("RESS", res.data.data);
        dispatch(
          ApplicationListDetail.Applicationlist_Success(res.data.data.items)
        );
        dispatch(onSuccess(res.message));
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
        } else {
        }
      });
  };
