import React from "react";
import Headers from "components/Headers/Header1";
import Filteration from "./Filteration";
import {Container,Card,Row,} from "reactstrap";


const Customer = (props) => {
 

  return (
    <>
      <Headers />
  
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
            <Filteration 
            
            EditCustomerMiddleware={props.EditCustomerMiddleware} 
           
            ></Filteration>
             
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Customer;
