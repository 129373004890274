import React from "react";
import Headers from "components/Headers/Header1";
import Filteration from "./Filteration";
import { Container, Card, Row } from "reactstrap";

const BankList = (props) => {
  return (
    <>
      <Headers />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filteration
                GetBranchListMiddleware={props.GetBranchListMiddleware}
                responseBranch={props.responseBranch}
                DeleteBranchMiddleware={props.DeleteBranchMiddleware}
                del={props.del}
                _FileUpload_Middleware={props._FileUpload_Middleware}
              ></Filteration>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default BankList;
