export const FETCH_MANAGER = "FETCH_MANAGER";
export const FETCH_MANAGER_SUCCESS = "FETCH_MANAGER_SUCCESS";
export const FETCH_MANAGER_FAILURE = "FETCH_MANAGER_FAILURE";
export const FETCH_MANAGER_PATH = "staff-profile/manager-list/for-admin";

export const FETCH_BRANCHS_BY_ID = "FETCH_BRANCHS_BY_ID";
export const FETCH_BRANCHS_BY_ID_SUCCESS = "FETCH_BRANCHS_BY_ID_SUCCESS";
export const FETCH_BRANCHS_BY_ID_FAILURE = "FETCH_BRANCHS_BY_ID_FAILURE";
export const FETCH_BRANCHS_BY_ID_PATH = "branch/byBank";

// export const FETCH_ROLES = "FETCH_ROLES";
// export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
// export const FETCH_ROLES_FAILURE = "FETCH_ROLES_FAILURE";
// export const FETCH_ROLES_PATH = "auth/roles";

// export const STAFF_SIGNUP = "STAFF_SIGNUP";
// export const STAFF_SIGNUP_SUCCESS = "STAFF_SIGNUP_SUCCESS";
// export const STAFF_SIGNUP_FAILURE = "STAFF_SIGNUP_FAILURE";
// export const STAFF_SIGNUP_PATH = "auth/staff/user/signup/for-admin";

export const MANAGER_SIGNUP = "MANAGER_SIGNUP";
export const MANAGER_SIGNUP_SUCCESS = "MANAGER_SIGNUP_SUCCESS";
export const MANAGER_SIGNUP_FAILURE = "MANAGER_SIGNUP_FAILURE";
export const MANAGER_SIGNUP_PATH = "staff-profile/manager-signup-for-admin";

// export const ADMIN_SIGNUP = "ADMIN_SIGNUP";
// export const ADMIN_SIGNUP_SUCCESS = "ADMIN_SIGNUP_SUCCESS";
// export const ADMIN_SIGNUP_FAILURE = "ADMIN_SIGNUP_FAILURE";
// export const ADMIN_SIGNUP_PATH = "auth/admin/user/signup";

export const DELETE_MANAGER = "DELETE_MANAGER";
export const DELETE_MANAGER_SUCCESS = "DELETE_MANAGER_SUCCESS";
export const DELETE_MANAGER_FAILURE = "DELETE_MANAGER_FAILURE";
export const DELETE_MANAGER_PATH =
  "staff-profile/delete-manager-profile-for-admin";

export const EDIT_MANAGER = "EDIT_MANAGER";
export const EDIT_MANAGER_SUCCESS = "EDIT_MANAGER_SUCCESS";
export const EDIT_MANAGER_FAILURE = "EDIT_MANAGER_FAILURE";
export const EDIT_MANAGER_PATH =
  "staff-profile/update-manager-profile-for-admin";
