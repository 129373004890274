import {
 
  APPLICATIONLIST,APPLICATIONLIST_SUCCESS,APPLICATIONLIST_FAILURE, 
  CUSTOMERSHOW,CUSTOMERSHOW_FAILURE,CUSTOMERSHOW_SUCCESS

  
} from "../constant";
const INITIAL_STATE = {

  List:null,
  info:null,

 
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
      case CUSTOMERSHOW:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          info: null,
          error: {},
        };
      case CUSTOMERSHOW_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          info: action.payload,
        };
      case CUSTOMERSHOW_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };

  case APPLICATIONLIST:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          List: null,
          error: {},
        };
      case APPLICATIONLIST_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          List: action.payload,
        };
      case APPLICATIONLIST_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };

     
  }
};
