import { SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE ,CITY,CITY_FAILURE,CITY_SUCCESS,COUNTRY,COUNTRY_FAILURE,COUNTRY_SUCCESS} from "../constant.js";
export class CountryDetail {
  static country() {
    return {
      type: COUNTRY,
    };
  }
  static countrySuccess(response) {
    return {
      type: COUNTRY_SUCCESS,
      payload: response,
    };
  }
  static countryFailure(error) {
    return {
      type: COUNTRY_FAILURE,
      error,
    };
  }
}

export class CityDetail {
  static city() {
    return {
      type: CITY,
    };
  }
  static citySuccess(response) {
    return {
      type: CITY_SUCCESS,
      payload: response,
    };
  }
  static cityFailure(error) {
    return {
      type: CITY_FAILURE,
      error,
    };
  }
}
export class UserSignUp {
  static SignUp() {
    return {
      type: SIGNUP,
    };
  }
  static SignUpSuccess(response) {
    return {
      type: SIGNUP_SUCCESS,
      payload: response,
    };
  }
  static SignUpFailure(error) {
    return {
      type: SIGNUP_FAILURE,
      error,
    };
  }
}
