import axios from "axios";
import { FetchAllStaff, DeleteStaff, EditStaff } from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_STAFF_PATH,
  DELETE_STAFF_PATH,
  EDIT_STAFF_PATH,
} from "../constant";

export const FetchStaff_Middleware =
  (pageNumber, noOfRows, valueAndKey, bankOrManager, onSuccess, onFailure) =>
  (dispatch) => {
    dispatch(FetchAllStaff.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_STAFF_PATH}?page=${pageNumber}&limit=${noOfRows}&${valueAndKey?.key}=${valueAndKey.value}&${bankOrManager?.key}=${bankOrManager.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchAllStaff.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          // onFailure(error.response.data);
        }
      });
  };

export const DeleteById_Middleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(DeleteStaff.Delete());
    let token = localStorage.getItem("auth");
    axios
      .delete(`${BASEURL}${DELETE_STAFF_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(DeleteStaff.DeleteSuccess(res.data.message));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data.message);
        }
      });
  };

export const EditById_Middleware =
  (body, id, onSuccess, onFailure) => (dispatch) => {
    dispatch(EditStaff.Edit());
    let token = localStorage.getItem("auth");
    axios
      .patch(`${BASEURL}${EDIT_STAFF_PATH}/${id}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(EditStaff.EditSuccess(res.data.message));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data.message);
        }
      });
  };
