import {
  VERIFY,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  BLOCKED,
  BLOCKED_SUCCESS,
  BLOCKED_FAILURE,
} from "../constant";

export class Verify {
  static _Verify() {
    return {
      type: VERIFY,
    };
  }
  static _Verify_Success(response) {
    return {
      type: VERIFY_SUCCESS,
      payload: response,
    };
  }
  static _Verify_Failure(error) {
    return {
      type: VERIFY_FAILURE,
      error,
    };
  }
}

export class Blocked {
  static _Blocked() {
    return {
      type: BLOCKED,
    };
  }
  static _Blocked_Success(response) {
    return {
      type: BLOCKED_SUCCESS,
      payload: response,
    };
  }
  static _Blocked_Failure(error) {
    return {
      type: BLOCKED_FAILURE,
      error,
    };
  }
}
