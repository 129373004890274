import axios from "axios";
import {
  FetchAgentList,
  FetchAppList,
  FetchManagerList,
  FetchStaffList,
  FetchAgentStaffList,
} from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_ADMIN_REQ_LIST_PATH,
  FETCH_AGENT_LIST_PATH,
  FETCH_MANAGER_LIST_PATH,
  FETCH_STAFF_LIST_PATH,
  FETCH_AGENT_STAFF_LIST_PATH,
} from "../constant";

export const AdminAppList_Middleware =
  (pageNumber, noOfRows, valueAndKey, searchFilter, onSuccess, onFailure) =>
  (dispatch) => {
    dispatch(FetchAppList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_ADMIN_REQ_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}&${valueAndKey.key}=${valueAndKey.value}&${searchFilter.key}=${searchFilter.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchAppList.FetchSuccess(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          // onFailure(error.response.data);
        }
      });
  };

export const FetchManagerList_Middleware =
  (pageNumber, noOfRows, bankId, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchManagerList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_MANAGER_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}&bankId=${bankId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchManagerList.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

export const FetchStaffList_Middleware =
  (pageNumber, noOfRows, managerId, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchStaffList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_STAFF_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}&managerId=${managerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchStaffList.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

export const FetchAgentList_Middleware =
  (pageNumber, noOfRows, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchAgentList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_AGENT_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchAgentList.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

export const FetchAgentStaffList_Middleware =
  (pageNumber, noOfRows, agentId, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchAgentStaffList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_AGENT_STAFF_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}&agentId=${agentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchAgentStaffList.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };
