import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import validate from "components/Utilities Component/ValidationWrapper";
import { useLocation } from "react-router-dom";
import Logo from "assets/img/whiteee1.png";
import swal from "sweetalert";
import { connect } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import "../../../../assets/scss/components/otp.scss";
// import { VerifyUserByEmail } from "views/UserViews/Login/middleware";
import { VerifyUserByEmail } from "views/AgentView/Login/middleware";
const TIMER = {
  MINUTES: 1,
  SECONDS: 30,
};

const OTP = (props) => {
  var location = useLocation();
  const { VerifyUserByEmail } = props;
  let history = useHistory();
  const [minutes, setMinutes] = useState(TIMER.MINUTES);
  const [seconds, setSeconds] = useState(TIMER.SECONDS);
  // const notify = useRef(null);
  const [body, setBody] = useState({
    code: "",
  });

  const [userMail, setUserMail] = useState({});
  const [email, setMail] = useState("");

  // countdown function

  useEffect(() => {
    console.log("STATE", location?.email);
    // setMail()
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    let email = localStorage.getItem("email");
    console.log(email, "email");
    if (email) {
      setUserMail({ email: email });
    }
  }, []);

  // console.log(userMail, "email");

  const [error, setError] = useState({
    otpError: "",
  });
  const CheckFields = (name) => {
    if (name === "otp") {
      setError({
        ...error,
        otpError: validate("required", body.code),
      });
    }
  };
  const verify = () => {
    if (body.code !== "") {
      props.otpMiddleware(body, location?.email, onSuccess, onFailure);
      setBody({ ...body, code: "" });
    }
  };
  const onChange = (value, name) => {
    setBody({ ...body, [name]: value });
  };
  const onSuccess = (data) => {
    setBody({ ...body, code: "" });
    window.fbq("track", "Verified_Customer");
    swal({
      title: "Congratulations!",
      text: "Account Verified",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let values = {
          token: data.data.accessToken,
          user: JSON.stringify(data.data.user),
          roleid: data.data.user.roleId,
        };
        localStorage.setItem("auth", values.token);
        console.log("SETINNGLOCALUSER", values.user);
        localStorage.setItem("user", values.user);
        localStorage.setItem("roleid", values.roleid);
        if (values.token !== "") {
          // history.entries = [];
          // history.index = -1;
          let path = "/user/requestUserView";
          history.replace(path);
        }
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };

  let onSuccessMail = () => {
    swal({
      title: "Congratulations!",
      text: "code resent successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        setMinutes(TIMER.MINUTES);
        setSeconds(TIMER.SECONDS);
      }
    });
  };

  let onFailureMail = (error) => {
    swal("Sorry!", error.message, "error");
  };

  const resetTimer = () => {
    if (location?.email) {
      let body = {
        email: location?.email,
      };
      console.log("Email EEEEE ", location?.email);
      // VerifyUserByEmail
      VerifyUserByEmail(body, onSuccessMail, onFailureMail);
    }
  };
  useEffect(() => {
    if (props.Data !== null && props.Data !== undefined) {
      console.log("DATA PROPS AT OTP", props.Data);
    }
  }, [props.Data]);
  return (
    <>
      {/* <NotificationAlert ref={notify} /> */}
      <Col lg="5" md="7">
        <Card className="customLogin">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img height="150px" width="150px" src={Logo} /> <br />
              <h3 style={{ color: "white" }}>
                {/* <b> */}
                Verification Code
                {/* </b> */}
              </h3>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Enter Code"
                    type="text"
                    onBlur={() => CheckFields("otp")}
                    value={body.code}
                    //onChange={(e) => setBody(e.target.value)}
                    onChange={(e) => onChange(e.target.value, "code")}
                  />
                </InputGroup>
                {error.otpError !== "" && error.otpError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.otpError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  disabled={body.code == ""}
                  onClick={verify}
                >
                  Verify
                </Button>
                <div className="resend__code">
                  <button
                    style={{ color: "white" }}
                    type="button"
                    className="reset__timer"
                    disabled={minutes === 0 && seconds === 0 ? false : true}
                    onClick={resetTimer}
                  >
                    Resend
                  </button>
                  {minutes === 0 && seconds === 0 ? (
                    ""
                  ) : (
                    <h3 style={{ color: "white" }}>
                      {" "}
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </h3>
                  )}
                </div>
              </div>
            </Form>
            <Row className="mt-3 text-center"></Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  // GetUserJWTLogin: state.JwtCredential.GetUserJWTLogin
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    // JwtDashboard: (OnSuccess, OnFailure) =>
    //   dispatch(JwtDashboard(OnSuccess, OnFailure)),

    VerifyUserByEmail: (body, onSuccess, onFailure) =>
      dispatch(VerifyUserByEmail(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(OTP);