import React, { useEffect } from "react";
import swal from "sweetalert";
import { useState } from "react";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router";
import Information from "../helpers/Information";

const TableOfApplicationList = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  let history = useHistory();

  const [ClientDetail, setClientDetail] = useState({});
  const toggler = (detail) => {
    setClientDetail(detail);
    setIsOpen(!isOpen);
  };


  




  const dateFunction = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Karachi",
    });
    return nDate;
  };
  

 
  const [isOpenChangeModal, setIsOpenChangeModal] = useState(false);
  const [ClientDetailModal, setClientDetailModal] = useState({});
  const Modaldata = (posts) => {
    setClientDetailModal(posts);
    setIsOpenChangeModal(!isOpenChangeModal);
  };
const onEdit=(e)=>{
 
    
    history.push({
      pathname:'/user/Customer',
      state:e
    })

}
 const onDelete=(e)=>{
  swal({
    title: "Warning",
    text: "Are You Sure You Want To Delete This Customer?",
    icon: "warning",
    // buttons : true,
  }).then((isOk) => {
    if (isOk) {
      let id=e;
      props.DeleteCustomerMiddleware(id,onSuccess, onFailure)
    }
  });

 }

const onSuccess=()=>{
  swal({
    title: "Successfully!",
    text: "Customer Deleted successfully",
    icon: "success",
    // buttons : true,
  }).then((isOk) => {
    if (isOk) {
      
    }
  });
}
const onFailure=()=>{}
  return (
    <>
      <Information
        modal={isOpen}
        toggle={toggler}
        detail={ClientDetail}
        state={props.state}
      />

      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929", color:"white" }} >
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Name</th>
            <th scope="col">CNIC</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col">Application Quota</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.paginatedPosts !== null &&
          props.paginatedPosts !== undefined ? (
            props.paginatedPosts.map((posts, index) => {
              return (
                <tr>
                  <td>{index+1}</td>
                  <td>{posts.fullName}</td>
                  <td>{posts.cnic}</td>
                  <td>{posts.phone}</td>
                  <td>{posts.email}</td>
                  <td>
                    {posts.applicationRequests[0]?.NumberOfApplication}
                    </td>
                  
                 <td>
                 <Button
                      style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                    onClick={() => onEdit(posts)}
                      id="info"
                   
                    >
                      <span className="btn-inner--icon">
                       Edit
                      </span>
                    </Button>
                    <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={()=> onDelete(posts.id)}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                      Delete
                      </span>
                    </Button>
                 </td>
                </tr>
              );
            }
            )
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th><h3>No data found</h3></th>
              <th></th>
            </tr>
          )
          }
          
        </tbody>
      </Table>
    </>
  );
};
export default TableOfApplicationList;
