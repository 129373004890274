import axios from "axios";
import {CustomerEditDetail } from "../action/index";
import { CUSTOMEREDIT_PATH } from "../constant";
import { BASEURL } from "config/api/URL";
export const EditCustomerMiddleware = (id,body, OnSuccess, OnFailure) => (dispatch) => {
 
 
   dispatch(CustomerEditDetail.CustomerEdit());
 
   let token = localStorage.getItem("auth");
   axios
     .patch(`${BASEURL}${CUSTOMEREDIT_PATH}/${id}`, body, {
       headers: {
         "Content-Type": "application/json",
         Authorization: `bearer ${token}`,
       },
     })
     .then((res) => {
       
      // if (res.data.status === true) {
         dispatch(CustomerEditDetail.CustomerEdit_Success(res.data));
         dispatch(OnSuccess(res.data.message));
      // } 
       
     })
     .catch(function (error) {
       if (error.response) {
        
         OnFailure(error.response.data)
       } else if (error.request) {
       } else {
       }
     });
 };
 


