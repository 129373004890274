import axios from "axios";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  CardBody,
  Card,
  Table,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import { useState, useEffect } from "react";
// import { BASEURL, RECORDING_BASE_URL } from "config/api/URL";
// import { RECORDING_PATH } from "../constant";
import { RECORDING_BASE_URL } from "config/api/URL";
import moment from "moment";
const ReplyQuery = ({ id, modal, toggle, resolve, callback }) => {
  const [rep, setRep] = useState("");
  const repid = id;

  const onSuccess = () => {
    swal({
      title: "Success!",
      text: "Reply submitted Successfully.",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        setRep("");
        callback();
        toggle();
        // setQueryType("");
      } else {
        setRep("");
        callback();
        toggle();
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const handleResolve = () => {
    console.log("IDDDD", repid);
    let body = {
      reply: rep,
    };
    resolve.Reply_Middleware(id, body, onSuccess, onFailure);
  };
  return (
    <Modal size="md" isOpen={modal} toggle={toggle}>
      <ModalHeader style={{ backgroundColor: "#d69929" }} toggle={toggle}>
        <h3 style={{ color: "white" }}>{`Reply to a query no ${id}`}</h3>
      </ModalHeader>

      <ModalBody>
        <Row className="mt-1">
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <Form>
              <FormGroup>
                <Label className="form-control-label">Write a reply</Label>
                <Input
                  rows={6}
                  value={rep}
                  onChange={(e) => {
                    setRep(e.target.value);
                  }}
                  placeholder="Write your reply here"
                  style={{ resize: "none" }}
                  type="textarea"
                  name="text"
                  id="exampleText"
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row style={{ marginTop: -10 }}>
          <Col lg="5" md="5" sm="5">
            <Button
              style={{
                backgroundColor: "#015652",
                color: "white",
                // float: "right",
                justifyContent: "flex-end",
                alignSelf: "flex-end",
              }}
              size="md"
              id="search"
              onClick={() => {
                if (rep?.trim().length > 0) {
                  handleResolve();
                } else {
                  swal("Sorry!", "Reply field is required.", "error");
                }
              }}
              // onClick={() => ToggleViewReceiptModal()}
            >
              <span className="btn-inner--icon">Send</span>
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ReplyQuery;
