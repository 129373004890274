// import Index from "views/Index.js";
import Login from "views/UserViews/Login/container";
import MembershipForm from "views/UserViews/MembershipForm/container";
import PaymentReceipt from "views/PaymentReceipt/container";
import ViewPaymentReceipt from "views/ViewPaymentReceipt/container";
import ApplicationList from "views/UserViews/ApplicationList/container";
// import ViewPaymentReceipt from "views/ViewPaymentReceipt/container";
import RequestUserView from "views/UserViews/Request/container";
import SignUp from "views/UserViews/SignUp/container";
import StaffLogin from "views/StaffViews/StaffLogin/container";
import OTP from "views/UserViews/OTP/container";
import ForgetPassword from "views/UserViews/ForgetPassword/container";
import CustomerChangePass from "views/UserViews/ForgetPassword/components/CustomerChangePass";
import CustomerCodeVerify from "views/UserViews/ForgetPassword/components/CustomerCodeVerify";
import VerifyLoginUser from "views/UserViews/Login/components/verifyLoginUser";
import Bank from "views/AdminView/Bank/container";
import AgentSignUp from "views/AgentView/SignUp/container";
import AgentLogin from "views/AgentView/Login/container";
import DealerRegistration from "views/AdminView/DealerRegistration/container";
var routes = [
  {
    path: "/createPaymentReceipt",
    name: "Create Payment Receipt",
    icon: "fas fa-angle-right",
    component: PaymentReceipt,
    layout: "/admin",
    isView: false,
  },
  {
    path: "/requestUserView",
    name: "Step 1: Request",
    icon: "fas fa-angle-right",
    component: RequestUserView,
    layout: "/admin",
    isView: true,
  },
  {
    name: "Form",
    icon: "fab fa-wpforms ",
    isView: false,
    subNav: [
      {
        path: "/MembershipForm",
        name: "Registration Form",
        icon: "fas fa-angle-right",
        component: MembershipForm,
        layout: "/admin",
        isView: true,
      },
      {
        path: "/ApplicationList",
        name: "ApplicationList",
        icon: "fas fa-angle-right",
        component: ApplicationList,
        layout: "/admin",
        isView: true,
      },
    ],
  },
  // {
  //   name: "Payment Receipt",
  //   icon: "fas fa-file-invoice-dollar",
  //   isView: true,
  //   subNav: [
  //     {
  //       path: "/viewPaymentReceipt",
  //       name: "View Payment Receipt",
  //       icon: "fas fa-angle-right",
  //       component: ViewPaymentReceipt,
  //       layout: "/admin",
  //       // isView: false,
  //     },
  //   ],
  // },
  // {
  //   name: "Payment Receipt",
  //   icon: "fas fa-file-invoice-dollar",
  //   isView: true,
  //   subNav: [
  //     {
  //       path: "/requestUserView",
  //       name: "Request",
  //       icon: "fas fa-angle-right",
  //       component: RequestUserView,
  //       layout: "/admin",
  //       // isView: false,
  //     },
  //   ],
  // },
  {
    path: "/AgentLogin",
    component: AgentLogin,
    layout: "/auth",
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  // {
  //   path: "/dealerReg",
  //   component: DealerRegistration,
  //   layout: "/dealer",
  // },
  {
    path: "/signup",
    component: SignUp,
    layout: "/auth",
  },
  {
    path: "/otp",
    component: OTP,
    layout: "/auth",
  },
  {
    path: "/verifyLoginUser",
    component: VerifyLoginUser,
    layout: "/auth",
  },
  {
    path: "/staffLogin",
    component: StaffLogin,
    layout: "/auth",
  },
  {
    path: "/adminLogin",
    component: StaffLogin,
    layout: "/auth",
  },
  {
    path: "/agentSignUp",
    component: AgentSignUp,
    layout: "/auth",
  },
  {
    path: "/forgetPassword",
    component: ForgetPassword,
    layout: "/auth",
  },
  {
    path: "/cuChangePassword",
    component: CustomerChangePass,
    layout: "/auth",
  },
  {
    path: "/cuCodeVerify",
    component: CustomerCodeVerify,
    layout: "/auth",
  },
  // {
  //   path: "/reset",
  //   component: ResetPassword,
  //   layout: "/auth",
  // },
];
export default routes;
