import axios from "axios";
import { UserLogin, VerifyByEmail } from "../action";
import { LOGIN_PATH, VERIFY_BY_EMAIL_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const CustomerLogin = (body, onSuccess, onFailure) => (dispatch) => {
  
  dispatch(UserLogin.Login());
  axios
    .post(`${BASEURL}${LOGIN_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      
      if (res.data.status === true) {
        console.log("CHECK VERIFY DATA",res.data)
        dispatch(UserLogin.LoginSuccess(res.data));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      
      if (error.response) {
        onFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    });
};

export const VerifyUserByEmail = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(VerifyByEmail.Account_verify());
  axios
    .post(`${BASEURL}${VERIFY_BY_EMAIL_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("CHECK VERIFY DATA",res.data)
      if (res.status === 201) {
        dispatch(VerifyByEmail.Account_verifySuccess(res.data));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};
