import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";

import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import TableOfApplicationList from "views/AdminView/CustomerList/container/TableOfCustomerList";
const Filteration = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [image, setImage] = useState("")
  const [profilePic,setProfilePic]=useState("")
  const formData = new FormData();
 
  const [disabledField, setdisabledField] = useState(false)
  //let testPhoneNum = /^(\+92|92)[0-9]{10}$/;
  const [validationPhoneNo, setValidationPhoneNo] = useState("");
  const [validationWhatsAppNo, setValidationWhatsAppNo] = useState("");
  const [checked, setChecked] = useState(false);
  const [disabledButton, setdisabledButton] = useState(false)
  const [state, setState] = useState({
    fullName:location.state ? location?.state?.fullName  :"",
    cnic: location.state ?location?.state?.cnic:"",
    phone: location.state ?location?.state?.phone:"",
    whatsAppNo:location.state ? location?.state?.whatsAppNo:"",
    email:location.state ? location?.state?.email:"",
    // fullName: "",
    // cnic: "",
    // email: "",
    // phone: "",
    // whatsAppNo: "",
  
    // profilePhoto: "",
  });
  useEffect(() => {
    if (location?.state !== null && location?.state !== undefined) {
      setdisabledField(true)
      setState({
        ...state,
        fullName: location?.state?.fullName,
        cnic: location?.state?.cnic,
        phone: location?.state?.phone,
        whatsAppNo: location?.state?.whatsAppNo,
        email: location?.state?.email,
       
      });
 
    
      if(location?.state?.realEstateStaffProfile?.profilePhoto!==null &&location?.state?.realEstateStaffProfile?.profilePhoto!==undefined)
      {
        setProfilePic(location?.state?.realEstateStaffProfile?.profilePhoto);
     setImage(location?.state?.realEstateStaffProfile?.profilePhoto)

      }

    }
  }, [location.state]);


  const onSuccess = () => {
    setdisabledButton(false)

    swal({
      title: "Success!",
      text: "Staff Added Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
     // if (isOk) {
        let path = "/user/AgentStaffList";
        history.push(path);
      //}
    });
  };
  const onFailure = (error) => {
    setdisabledButton(false)
    swal("Sorry!", error.message, "error");
  };

 



  const onChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  //Start Validation
  const CheckFields = (name) => {
    if (name === "fullName") {
      setError({
        ...error,
        fullNameError: validate("required", state.fullName),
      });
    } else if (name === "cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", state.cnic),
      });
    } else if (name === "phone") {
      setError({
        ...error,
        phoneError: validate("phoneNumber", state.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", state.email),
      });
    } 
  };

  const [error, setError] = useState({
    phoneError: null,
    whatsAppNoError: null,
    emailError: null,
    cnicError: "",
    fullNameError: "",
  
  });

  const submit = () => {
    
    setError({
      ...error,
      fullNameError: validate("required", state.fullName),
      cnicError: validate("CNIC", state.cnic),
      emailError: validate("email", state.email),
      phoneError: validate("phoneNumber", state.phone),
      whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
     
    });
  
  
 
    if (
      
      location?.state !== null &&
      location?.state !== undefined &&
      state?.fullName!=="" &&
     error?.emailError==null &&
     state?.cnic?.trim().length === 13 
     &&
     state.whatsAppNo?.trim().length===(state.whatsAppNo?.includes("+") ? 13 :  12) &&
     state.phone?.trim().length===(state.phone?.includes("+") ? 13 :  12)
    //  state.phone?.includes("+") ? state.phone?.trim().length == 13 : state.phone?.trim().length == 12 
    ) {
      swal({
        title: "Warning",
        text: "Are You Sure You Want To Edit This Staff Information?",
        icon: "warning",
         buttons : true,
        
      }).then((isOk) => {
         if (isOk) {
          console.log("location.state",location.state)
      let id = location?.state?.realEstateStaffProfile?.id;
      formData.append("file", image);
      formData.append("fullName",state.fullName);
      formData.append("whatsAppNo",state?.whatsAppNo?.includes("+")?state?.whatsAppNo:`+${state.whatsAppNo}`);
      formData.append("phone",state?.phone?.includes("+")?state?.phone:`+${state?.phone}`);
      setdisabledButton(true)
      props.EditStaffMiddleware(id, formData, onSuccesEdit, onFailureEdit);
         }
      });
    
    } 
    
    else if (
     
      location?.state == undefined &&
      state.fullName!=="" && 
      //state.cnic!=="" &&
      error.emailError==null &&
     state?.cnic?.trim().length == 13 &&
     state.whatsAppNo?.trim().length===(state.whatsAppNo?.includes("+") ? 13 :  12) &&
     state.phone?.trim().length===(state.phone?.includes("+") ? 13 :  12)
   
    ) {
      
      formData.append("file", image);
      formData.append("fullName",state.fullName);
      formData.append("whatsAppNo",state.whatsAppNo?.includes("+")?state.whatsAppNo:`+${state.whatsAppNo}`);
      formData.append("phone",state.phone?.includes("+")?state.phone:`+${state.phone}`);
      formData.append("cnic",state.cnic);
      formData.append("email",state.email);
      setdisabledButton(true)
      props.InsertStaffMiddleware(formData, onSuccess, onFailure);
    }
  };
  const onSuccesEdit = () => {
 
    setdisabledButton(false)
    swal({
      title: "Success!",
      text: "Staff Edited Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      //if (isOk) {
        let path = "/user/AgentStaffList";
        history.push(path);
      //}
    });
  };
  const onFailureEdit = (error) => {
    setdisabledButton(false)
    swal("Sorry!", error.message, "error");
  };
 

const onImageChange =(event) => {
 

if(event.target.files[0]!==null && event.target.files[0]!==undefined)
{
  setProfilePic(URL.createObjectURL(event.target.files[0]))
  setImage(event.target.files[0])
}
else{
  setProfilePic("")
  setImage("")
}

};
const handleChecked = () => {
  setChecked(!checked);
};
useEffect(() => {
  if(location.state!==undefined && location.state!==null)
  {
    setState({...state, whatsAppNo: checked == true ? state.phone : location.state?.whatsAppNo });
  }
  else{
    setState({...state, whatsAppNo: checked == true ? state.phone : "92" });
  }
 
}, [checked]);


  return (
    <CardHeader className="border-0">
      <Row>
        <Col lg="2" md="2" sm="6">
          <h2>Add New Staff</h2>
        </Col>
        <Col lg="8" md="8" sm="6"></Col>
        <Col lg="2" md="2" sm="6"></Col>
      </Row>
      <hr />
      <CardBody>
        <form>
          <Row className="mt--5">
     
           
            <Col lg="2" md="2" sm="6">
              {/* <div style={{border:"1px solid lightgrey"}}> */}
              <label className="form-control-label" for="input-username">
                Profile Picture
                </label>
                <br/>
              <img height={"100px"} width={"100px"} border
               src={
                profilePic !== ""
                  ? profilePic
                  : require("../../../../assets/img/logo.png").default
              }
              //src={profilePic} alt="preview image"
               />
               
            <input 
             // className="form-control"
              type="file" name="file"
              accept="image/*"
            onChange={onImageChange} 
          style={{fontSize:"10px"}}
             />
              {/* </div> */}
              <br/>
              <br/>
            </Col>
            
          </Row>
          <Row>
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Name*
              </label>
              <input
                type="text"
                
                onBlur={() => CheckFields("fullName")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={state?.fullName}
                onKeyPress={(event) => {
                  if (!/[a-z, A-Z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => onChange(e.target.value, "fullName")}
                autoComplete="off"
              ></input>
              {error.fullNameError !== "" && error.fullNameError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.fullNameError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Cnic*
              </label>
              <input
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={13}
                disabled={disabledField}
                onBlur={() => CheckFields("cnic")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={state?.cnic}
                onChange={(e) => onChange(e.target.value, "cnic")}
                autoComplete="off"
              ></input>
              {error.cnicError !== "" && error.cnicError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.cnicError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Email*
              </label>
              <input
                type="text"
                onBlur={() => CheckFields("email")}
                id="input-username"
                className="form-control"
                disabled={disabledField}
                placeholder=""
                value={state?.email}
                onChange={(e) => onChange(e.target.value, "email")}
                autoComplete="off"
              ></input>
              {error.emailError !== "" && error.emailError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.emailError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="4" md="4" sm="6">
                <label className="form-control-label" for="input-username">
                  Phone No*
                </label>
                <PhoneInput
                   placeholder="+92"
                  
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "phone")}
                  onBlur={() => CheckFields("phone")}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                    height:"100%"
                    
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="100%"
                  disabled={checked}
                  //  containerStyle={{border:"none"}}
                  countryCodeEditable={false}
                  country={"pk"}
                  value={state.phone?state.phone:""}
                />

                {error.phoneError !== "" && error.phoneError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.phoneError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                  Whatsapp No*
                </label>
                <PhoneInput
                  placeholder="+92"
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "whatsAppNo")}
                  onBlur={() => CheckFields("whatsAppNo")}
                  disabled={checked}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                    height:"100%"
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="true"
                  countryCodeEditable={false}
                  country={"pk"}
                  value={state.whatsAppNo?state.whatsAppNo:""}
                />

                {error.whatsAppNoError !== "" &&
                  error.whatsAppNoError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.whatsAppNoError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                 { state.phone?.length >=12 ? (
                    <div style={{ position: "relative",
                      float: "right"}}>
                      <input
                        type="checkbox"
                        onClick={handleChecked}
                        // disabled={isUserExist}
                      />
                      <small><b> Same As Phone No</b></small>
                      
                    </div>
                  ) : (
                    ""
                  )}
                  
              </Col>
            
           
          </Row>
          <Row>
            <Col lg="4" md="4" sm="6">
              <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={submit}
                      id="info"
                      disabled={disabledButton}
                    >
                      <span className="btn-inner--icon">
                       Submit
                      </span>
                    </Button>
              </Col>

            </Row>
           
            
           
        
        </form>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
