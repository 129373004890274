import { connect } from "react-redux";

import BranchList from "./BranchList";
import {
  GetBranchListMiddleware,
  DeleteBranchMiddleware,
  FileUpload_Middleware,
} from "../middleware";

const mapStateToProps = (state) => ({
  responseBranch: state.branchList.responseBranch,
  del: state.branchList.del,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    DeleteBranchMiddleware: (id, onSuccess, onFailure) =>
      dispatch(DeleteBranchMiddleware(id, onSuccess, onFailure)),
    _FileUpload_Middleware: (body, onSuccess, onFailure) =>
      dispatch(FileUpload_Middleware(body, onSuccess, onFailure)),
    GetBranchListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        GetBranchListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(BranchList);
