import axios from "axios";
import { CustomerDetail,  PlotTypeDetail,
  PlotSizeDetail,ProjectDetail } from "../action/index";
import { CUSTOMER_PATH, PLOTTYPE_PATH,
  PLOTSIZE_PATH, PLOTPROJECT_PATH
   } from "../constant";
import { BASEURL } from "config/api/URL";





export const InsertCustomerDetail =
  (body, OnSuccess, OnFailure) => (dispatch) => {
    dispatch(CustomerDetail.Customer());
    let token = localStorage.getItem("token");
    axios
      .post(`${BASEURL}${CUSTOMER_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          dispatch(CustomerDetail.Customer_Success(res.data.response));
          dispatch(OnSuccess(res.data.message));
        } else {
          dispatch(CustomerDetail.Customer_Failure(res.data.message));
          dispatch(OnFailure(res.data.message));
        }
      })
      .catch((error) => dispatch(CustomerDetail.Customer_Failure(error)));
  };
  export const showPlotProject = (OnSuccess, OnFailure) => (dispatch) => {
    dispatch(ProjectDetail.PlotProject());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${PLOTPROJECT_PATH}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          dispatch(ProjectDetail.PlotProject_Success(res.data.response));
          dispatch(OnSuccess(res.data.message));
        } else {
          dispatch(ProjectDetail.PlotProject_Failure(res.data.message));
          dispatch(OnFailure(res.data.message));
        }
      })
      .catch((error) => dispatch(ProjectDetail.PlotProject_Failure(error)));
  };

  export const showPlotType = (OnSuccess, OnFailure) => (dispatch) => {
    dispatch(PlotTypeDetail.PlotType());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${PLOTTYPE_PATH}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          dispatch(PlotTypeDetail.PlotType_Success(res.data.response));
          dispatch(OnSuccess(res.data.message));
        } else {
          dispatch(PlotTypeDetail.PlotType_Failure(res.data.message));
          dispatch(OnFailure(res.data.message));
        }
      })
      .catch((error) => dispatch(PlotTypeDetail.PlotType_Failure(error)));
  };
  export const showPlotSize = (OnSuccess, OnFailure) => (dispatch) => {
    dispatch(PlotSizeDetail.PlotSize());
    let token = localStorage.getItem("auth")
    axios
      .get(`${BASEURL}${PLOTSIZE_PATH}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          dispatch(PlotSizeDetail.PlotSize_Success(res.data.response));
          dispatch(OnSuccess(res.data.message));
        } else {
          dispatch(PlotSizeDetail.PlotSize_Failure(res.data.message));
          dispatch(OnFailure(res.data.message));
        }
      })
      .catch((error) => dispatch(PlotSizeDetail.PlotSize_Failure(error)));
  };