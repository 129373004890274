import axios from "axios";
import { FetchStaffRequests, Pay_Form_Fee, Print_Form } from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_ALL_REQUEST_PATH,
  PAY_FORM_FEE_PATH,
  PRINT_FORM_PATH,
} from "../constant";

export const FetchStaffRequest_Middleware =
  (pageNumber, noOfRows, valueAndKey, date, onSuccess, onFailure) =>
  (dispatch) => {
    dispatch(FetchStaffRequests.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_ALL_REQUEST_PATH}?page=${pageNumber}&limit=${noOfRows}&${valueAndKey?.key}=${valueAndKey?.value}&date=${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchStaffRequests.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          // onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
  };

export const PrintForm_Middleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    console.log(id);
    dispatch(Print_Form.Print());
    axios
      .get(`${BASEURL}${PRINT_FORM_PATH}/pdf/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        // if (res.status === 201) {
        //   dispatch(Print_Form.PrintSuccess(res.data.data));
        //   dispatch(onSuccess(res.data.message));
        // }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          // onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
  };

export const PayForm_Fee_Middleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(Pay_Form_Fee.Fee());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${PAY_FORM_FEE_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          dispatch(Pay_Form_Fee.FeeSuccess(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          onFailure(error.response.data.message);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
  };
