import {
  FETCH_MANAGER_APPLICATION_LIST,
  FETCH_MANAGER_APPLICATION_LIST_SUCCESS,
  FETCH_MANAGER_APPLICATION_LIST_FAILURE,
  POST_APPLICATION_LIST,
  POST_APPLICATION_LIST_SUCCESS,
  POST_APPLICATION_LIST_FAILURE,
  CHECK_BY_FORM_NO,
  CHECK_BY_FORM_NO_SUCCESS,
  CHECK_BY_FORM_NO_FAILURE,
} from "../constant.js";

export class FetchManagerApp {
  static Fetch() {
    return {
      type: FETCH_MANAGER_APPLICATION_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_MANAGER_APPLICATION_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_MANAGER_APPLICATION_LIST_FAILURE,
      error,
    };
  }
}

export class PostAppList {
  static Create() {
    return {
      type: POST_APPLICATION_LIST,
    };
  }
  static CreateSuccess(response) {
    return {
      type: POST_APPLICATION_LIST_SUCCESS,
      payload: response,
    };
  }
  static CreateFailure(error) {
    return {
      type: POST_APPLICATION_LIST_FAILURE,
      error,
    };
  }
}

export class CheckForm {
  static Check() {
    return {
      type: CHECK_BY_FORM_NO,
    };
  }
  static CheckSuccess(response) {
    return {
      type: CHECK_BY_FORM_NO_SUCCESS,
      payload: response,
    };
  }
  static CheckFailure(error) {
    return {
      type: CHECK_BY_FORM_NO_FAILURE,
      error,
    };
  }
}
