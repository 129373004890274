import {
  GET_PAYMENT_THROUGH,
  GET_PAYMENT_THROUGH_SUCCESS,
  GET_PAYMENT_THROUGH_FAILURE,
  CREATE_PAYMENT_RECEIPT,
  CREATE_PAYMENT_RECEIPT_SUCCESS,
  CREATE_PAYMENT_RECEIPT_FAILURE,
} from "../constant";

export class GetpaymentThrough {
  static PaymentThrough() {
    return {
      type: GET_PAYMENT_THROUGH,
    };
  }
  static PaymentThrough_Success(response) {
    return {
      type: GET_PAYMENT_THROUGH_SUCCESS,
      payload: response,
    };
  }
  static PaymentThrough_Failure(error) {
    return {
      type: GET_PAYMENT_THROUGH_FAILURE,
      error,
    };
  }
}

export class CreatePaymentReceipt {
  static createReceipt() {
    return {
      type: CREATE_PAYMENT_RECEIPT,
    };
  }
  static createReceipt_Success(response) {
    return {
      type: CREATE_PAYMENT_RECEIPT_SUCCESS,
      payload: response,
    };
  }
  static createReceipt_Failure(error) {
    return {
      type: CREATE_PAYMENT_RECEIPT_FAILURE,
      error,
    };
  }
}
