import axios from "axios";
import { PrintExcel } from "../action/index";
import { PRINT_EXCEL_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const PrintExcelMiddleware = (cnic, onSuccess, onFailure) => (dispatch) => {
  dispatch(PrintExcel._PrintExcel());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${PRINT_EXCEL_PATH}/${cnic}` ,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(PrintExcel._PrintExcel_Success(res.data.data));
      dispatch(onSuccess(res.data.url));
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
      }
    });
};