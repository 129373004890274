import {
  PLOT_TYPE,
  PLOT_TYPE_SUCCESS,
  PLOT_TYPE_FAILURE,
  BALLOTING,
  BALLOTING_SUCCESS,
  BALLOTING_FAILURE,
  WINNER_LIST,
  WINNER_LIST_SUCCESS,
  WINNER_LIST_FAILURE,
  MEMBERS_COUNT_RESIDENTIAL,
  MEMBERS_COUNT_RESIDENTIAL_SUCCESS,
  MEMBERS_COUNT_RESIDENTIAL_FAILURE,
} from "../constant";

const INITIAL_STATE = {
  plotType: null,
  winnerList: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case MEMBERS_COUNT_RESIDENTIAL:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        members: null,
        error: {},
      };
    case MEMBERS_COUNT_RESIDENTIAL_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        members: action.payload,
      };
    case MEMBERS_COUNT_RESIDENTIAL_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case PLOT_TYPE:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        plotType: null,
        error: {},
      };
    case PLOT_TYPE_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        plotType: action.payload,
      };
    case PLOT_TYPE_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case BALLOTING:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        res: null,
        error: {},
      };
    case BALLOTING_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        res: action.payload,
      };
    case BALLOTING_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case WINNER_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        winnerList: null,
        error: {},
      };
    case WINNER_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        winnerList: action.payload,
      };
    case WINNER_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
