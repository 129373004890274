import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Input,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import TableRequestStaffView from "../components/TableRequestStaffView";
import { connect } from "react-redux";
import {
  FetchAllStaff_Middleware,
  FetchManagerRequest_Middleware,
} from "../middleware";
import Pagination from "components/Pagination/Pagination";

const ManagerRequestView = ({
  _FetchManagerRequest_Middleware,
  ManagerRequests,
  PayResponse,
  AllStaff,
  _FetchAllStaff_Middleware,
}) => {
  const [filter, setFilter] = useState("");
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [date, setDate] = useState("");
  const [criteriaType, setCriteriaType] = useState({
    criteria: "",
  });
  const [valueAndKey, setValueAndKey] = useState({});

  ///////////----------Pagination--------------//////////////
  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};
  const getReceiptByFields = () => {
    // let filterPosts = ManagerRequests.filter((i) => {
    //   if (filter == i.id) {
    //     return i;
    //   } else if (filter == i.name.toLowerCase()) {
    //     return i;
    //   } else if (filter == i.Amount) {
    //     return i;
    //   } else if (filter == "") {
    //     return i;
    //   }
    // });
    // setPosts(filterPosts);
    _FetchManagerRequest_Middleware(
      pageNumber,
      noOfRows,
      filter,
      onSuccess,
      onFailure
    );
  };

  const ToggleViewReceiptModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };
  //////////-------useEffects--------//////////////

  useEffect(() => {
    if (ManagerRequests) {
      setPosts(ManagerRequests);
    }
  }, [ManagerRequests]);

  useEffect(() => {
    _FetchManagerRequest_Middleware(
      pageNumber,
      noOfRows,
      valueAndKey,
      date,
      onSuccess,
      onFailure
    );
  }, [pageNumber, noOfRows, valueAndKey, date, PayResponse]);

  useEffect(() => {
    setPageNumber(1);
  }, [valueAndKey]);

  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      setValueAndKey({
        key: key,
        value: value,
      });
    });
  }, [filter]);

  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb--3">Form Request</h2>
                  </Col>
                </Row>
                <hr />
              </CardHeader>
              <CardBody className="mt--5">
                <Row>
                  <Col xl="2" lg="4" md="4" sm="6" xs="6">
                    <label className="form-control-label">Rows Per Pages</label>
                    <Input
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={(e) => onChangeNoOfRows(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </Input>
                  </Col>
                  <Col lg="3" md="3" sm="6">
                    <label className="form-control-label" for="input-username">
                      Search by Criteria
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setCriteriaType({
                          ...criteriaType,
                          criteria: e.target.value,
                        })
                      }
                    >
                      <option value="">Search Criteria</option>
                      <option value="name">Name</option>
                      <option value="CNIC">CNIC</option>
                      <option value="phone">Phone</option>
                      {/* <option value="email">Email</option> */}
                      <option value="status">Status</option>
                    </select>
                  </Col>
                  <Col lg="3" md="3" sm="6">
                    <label className="form-control-label" for="input-username">
                      Search
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder=""
                      value={filter[criteriaType.criteria]}
                      onChange={(e) =>
                        setFilter({ [criteriaType.criteria]: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    <br />
                  </Col>
                  <Col lg="3" md="3" sm="6">
                    <label className="form-control-label" for="input-username">
                      Date
                    </label>
                    <input
                      type="date"
                      id="input-username"
                      className="form-control"
                      onChange={(e) => {
                        let date1 = e.target.value.split("T")[0];
                        setDate(date1);
                      }}
                    ></input>
                  </Col>
                </Row>
                <TableRequestStaffView post={paginatedPosts} />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  ManagerRequests: state.managerRequest.ManagerRequests,
  PayResponse: state.managerRequest.PayResponse,
  AllStaff: state.managerRequest.AllStaff,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _FetchManagerRequest_Middleware: (
      pageNumber,
      noOfRows,
      valueAndKey,
      date,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchManagerRequest_Middleware(
          pageNumber,
          noOfRows,
          valueAndKey,
          date,
          onSuccess,
          onFailure
        )
      ),
    _FetchAllStaff_Middleware: (onSuccess, onFailure) =>
      dispatch(FetchAllStaff_Middleware(onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(ManagerRequestView);
