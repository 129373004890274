import { connect } from "react-redux";

import Staff from "./Staff";
import { InsertStaffMiddleware,EditStaffMiddleware} from "../middleware";


const mapStateToProps = (state) => ({

  staff:state.agentStaff.staff,
edit:state.agentStaff.edit,

 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    EditStaffMiddleware:(id,body,onSuccess,onFailure)=>
    dispatch(EditStaffMiddleware(id, body, onSuccess, onFailure)),
    InsertStaffMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(InsertStaffMiddleware(body, OnSuccess, OnFailure)),
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Staff);
