import {
 
  CLIENT,CLIENT_SUCCESS,CLIENT_FAILURE, 

  
} from "../constant";
const INITIAL_STATE = {

  Client:null,

 
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;

  case CLIENT:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          Client: null,
          error: {},
        };
      case CLIENT_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          Client: action.payload,
        };
      case CLIENT_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };

     
  }
};
