import {
 
  CUSTOMEREDIT,CUSTOMEREDIT_SUCCESS,CUSTOMEREDIT_FAILURE
} from "../constant";
const INITIAL_STATE = {

  customer:null,
  edit:null,
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
      case CUSTOMEREDIT:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          edit: null,
          error: {},
        };
      case CUSTOMEREDIT_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          edit: action.payload,
        };
      case CUSTOMEREDIT_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


  
     
  }
};
