import React, { useEffect, useRef, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Container,
  Card,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import swal from "sweetalert";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ImportFileMiddleware, PrintExcelMiddleware } from "../middleware";
import { BASEURL } from "config/api/URL";
import validate from "components/Utilities Component/ValidationWrapper";
import { FetchBanksMiddleware } from "views/AdminView/FormGenerate/middleware";

const FormImport = ({
  ImportFileMiddleware,
  importFileRes,
  FetchBanksMiddleware,
  getBanks,
}) => {
  const inputFileRef = useRef(null);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [excelData, setExcelData] = useState(null);
  const [body, setBody] = useState({
    bankId: "",
    file: [],
  });

  const [error, setError] = useState({
    bankIdError: null,
    fileError: null,
  });
  const CheckFields = (name) => {
    if (name === "bankId") {
      setError({
        ...error,
        bankIdError: validate("required", body.bankId),
      });
    } else if (name === "file") {
      setError({
        ...error,
        fileError: validate("required", body.file),
      });
    }
  };
  const handleUploadFile = () => {
    setError({
      ...error,
      bankIdError: validate("required", body.bankId),
      fileError: validate("required", body.file),
    });
    if (body.file?.length > 0 && body.bankId !== "") {
      let formData = new FormData();
      formData.append("files", body.file[0]);
      formData.append("bankId", body.bankId);
      ImportFileMiddleware(formData, onFileUploadSuccess, onFileUploadFailure);
    }
  };

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const onFileUploadSuccess = (res) => {
    if (res?.data?.errorReport && res?.data?.errorReport?.length > 0) {
      let tempArr = [];
      res?.data?.errorReport?.map((item) => {
        tempArr.push({
          ["Name"]: item.data.Name,
          ["CNIC"]: item.data.CNIC.toString(),
          ["Phone Number"]: item.data["Phone Number"].toString(),
          ["Email"]: item.data.Email,
          ["Deposite Number"]: item.data["Deposite Number"],
          ["Register Number"]: item.data["Register Number"],
          ["Plot Category"]: item.data["Plot Category"],
          ["Date"]: item.data.Date,
          ["Remarks"]: item.message,
        });
      });
      // setExcelData(tempArr);
      exportToCSV(tempArr, "Error Report");
    }
  };
  const onFileUploadFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };

  useEffect(() => {
    FetchBanksMiddleware();
  }, []);
  // useEffect(()=>{

  // },[excelData])
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h1 className="mb-0">Import File</h1>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Select Bank <span className="text-red">*</span>
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setBody({ ...body, bankId: e.target.value })
                      }
                      onBlur={() => CheckFields("bankId")}
                      value={body.bankId}
                    >
                      <option value="">Search Bank</option>
                      {getBanks?.items?.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                    {error.bankIdError !== "" && error.bankIdError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.bankIdError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Label
                      className="form-control-label mr-2"
                      for="input-username"
                    >
                      Upload Bank file:
                    </Label>
                    <Input
                      type="file"
                      name="file"
                      onChange={(e) => {
                        let arr = [];
                        Array.from(e.target.files).map((file) => {
                          arr.push(file);
                        });
                        setBody({ ...body, file: body.file.concat(arr) });
                      }}
                      ref={inputFileRef}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    {error.fileError !== "" && error.fileError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.fileError}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="6" md="6" sm="6" style={{ marginTop: "30px" }}>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      onClick={handleUploadFile}
                    >
                      <span className="btn-inner--icon">Submit</span>
                    </Button>
                  </Col>
                </Row>
                <br />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  importFileRes: state.FormImport.importFileRes,
  getBanks: state.FetchBanksReducer.getBanks,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    ImportFileMiddleware: (body, onSuccess, onFailure) =>
      dispatch(ImportFileMiddleware(body, onSuccess, onFailure)),
    FetchBanksMiddleware: () => dispatch(FetchBanksMiddleware()),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(FormImport);
