import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import validate from "components/Utilities Component/ValidationWrapper";
import Logo from "assets/img/whiteee1.png";
import { connect } from "react-redux";
import { ResetForgotPassword } from "../middleware";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";

const CustomerChangePass = ({ _ResetForgotPassword }) => {
  let history = useHistory();
  const location = useLocation();
  const token = location.state.token;
  let string = location?.state?.string;
  const [body, setBody] = useState({
    password: "",
  });

  const [error, setError] = useState({
    passwordError: "",
  });
  const CheckFields = (name) => {
    if (name === "password") {
      setError({
        ...error,
        passwordError: validate("password", body.password),
      });
    }
  };

  const onSuccess = (res) => {
    swal({
      title: "Congratulations!",
      text: "Password has been changed",
      icon: "success",
    }).then((isOk) => {
      let path =
        string == "STAFF"
          ? "/auth/staffLogin"
          : string == "AGENT"
          ? "/auth/agentLogin"
          : "/auth/login";
      history.push(path);
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const handleDispatch = () => {
    if (body.code !== "") {
      _ResetForgotPassword(body, token, onSuccess, onFailure);
    }
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="customLogin">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img height="150px" width="150px" src={Logo} /> <br />
              <h3 style={{ color: "white" }}>
                <b>Change Password</b>
              </h3>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="New Password"
                    type="password"
                    onBlur={() => CheckFields("password")}
                    value={body.newPass}
                    onChange={(e) =>
                      setBody({ ...body, password: e.target.value })
                    }
                  />
                </InputGroup>
                {error.passwordError !== "" && error.passwordError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.passwordError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  onClick={handleDispatch}
                >
                  Submit
                </Button>
              </div>
            </Form>

            <Row className="mt-3 text-center">
              {/* <Col xs="12">
                <a className="text-light" href="/auth/login">
                  <small style={{ color: "black" }}>Back to Login Page</small>
                </a>
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  // GetUserJWTLogin: state.JwtCredential.GetUserJWTLogin
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _ResetForgotPassword: (body, token, onSuccess, onFailure) =>
      dispatch(ResetForgotPassword(body, token, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(CustomerChangePass);
