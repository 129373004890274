import axios from "axios";
import {
  FetchAllManager,
  FetchBranchs,
  // FetchRoles,
  // StaffSignUp,
  // AdminSignUp,
  DeleteManager,
  EditManager,
  ManagerSignUp,
} from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_MANAGER_PATH,
  FETCH_BRANCHS_BY_ID_PATH,
  DELETE_MANAGER_PATH,
  EDIT_MANAGER_PATH,
  MANAGER_SIGNUP_PATH,
} from "../constant";

export const FetchManager_Middleware =
  (pageNumber, noOfRows, valueAndKey, bankId, onSuccess, onFailure) =>
  (dispatch) => {
    dispatch(FetchAllManager.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_MANAGER_PATH}?page=${pageNumber}&limit=${noOfRows}&${valueAndKey?.key}=${valueAndKey?.value}&bankId=${bankId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchAllManager.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };

export const FetchBranchesById_Middleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchBranchs.Fetch());
    axios
      .get(`${BASEURL}${FETCH_BRANCHS_BY_ID_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchBranchs.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response);
        } else if (error.request) {
        } else {
        }
      });
  };

// export const FetchRoles_Middleware = (onSuccess, onFailure) => (dispatch) => {
//   dispatch(FetchRoles.Fetch());
//   axios
//     .get(`${BASEURL}${FETCH_ROLES_PATH}`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         dispatch(FetchRoles.FetchSuccess(res.data.data));
//         dispatch(onSuccess(res.data.message));
//       }
//     })
//     .catch(function (error) {
//       if (error.response) {
//         console.log(error.response);
//       } else if (error.request) {
//       } else {
//       }
//     });
// };//ManagerSignUp

// export const Staff_SignUp = (body, onSuccess, onFailure) => (dispatch) => {
//   dispatch(StaffSignUp.SignUp());
//   let token = localStorage.getItem("auth");

//   axios
//     .post(`${BASEURL}${STAFF_SIGNUP_PATH}`, body, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       if (res.status === 201) {
//         dispatch(StaffSignUp.SignUpSuccess(res.data.message));
//         dispatch(onSuccess(res.data.message));
//       }
//     })
//     .catch(function (error) {
//       if (error.response) {
//         console.log(error.response);
//         onFailure(error.response.data);
//       }
//     });
// };

export const Manager_SignUp = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(ManagerSignUp.SignUp());
  let token = localStorage.getItem("auth");

  axios
    .post(`${BASEURL}${MANAGER_SIGNUP_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 201) {
        dispatch(ManagerSignUp.SignUpSuccess(res.data.message));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data.message);
      }
    });
};
// export const Admin_SignUp = (body, onSuccess, onFailure) => (dispatch) => {
//   dispatch(AdminSignUp.SignUp());
//   let token = localStorage.getItem("auth");

//   axios
//     .post(`${BASEURL}${ADMIN_SIGNUP_PATH}`, body, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       if (res.status === 201) {
//         dispatch(AdminSignUp.SignUpSuccess(res.data.message));
//         dispatch(onSuccess(res.data.message));
//       }
//     })
//     .catch(function (error) {
//       if (error.response) {
//         console.log(error.response);
//         onFailure(error.response.data);
//       } else if (error.request) {
//       } else {
//       }
//     });
// };

export const DeleteById_Middleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(DeleteManager.Delete());
    let token = localStorage.getItem("auth");
    axios
      .delete(`${BASEURL}${DELETE_MANAGER_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(DeleteManager.DeleteSuccess(res.data.message));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response);
        }
      });
  };

export const EditById_Middleware =
  (body, id, onSuccess, onFailure) => (dispatch) => {
    dispatch(EditManager.Edit());
    let token = localStorage.getItem("auth");
    axios
      .patch(`${BASEURL}${EDIT_MANAGER_PATH}/${id}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(EditManager.EditSuccess(res.data.message));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response);
        }
      });
  };
