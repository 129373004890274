import React, { useEffect } from "react";

import { useState } from "react";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router";
import swal from "sweetalert";


const TableOfAgentList = (props) => {

  let sNo = 1;
 
  let history = useHistory();

  

  const verification=(post)=>{
    swal({
      title: "Warning!",
      text: "Are You Sure You Want To Verify This Agent?",
      icon: "warning",
       buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let id=post?.realEstate?.id
        props.AgentVerifyMiddleware(id,onSuccess,onFailure)
      }
    });
  
  }
  const unVerification=(post)=>{
    swal({
      title: "Warning!",
      text: "Are You Sure You Want To Unverified This Agent?",
      icon: "warning",
       buttons : true,
    }).then((isOk) => {
      if (isOk) {
    let id=post?.realEstate?.id
    props.AgentVerifyMiddleware(id,onSuccess,onFailure)
  }
});
  }






 const onSuccess=()=>{

 
   
     swal({
       title: "Success!",
       text: "Agent Verified/Unverified  Successfully",
       icon: "success",
       // buttons : true,
     }).then((isOk) => {
       if (isOk) {
        //  let path = "/user/CustomerList";
        //  history.push(path);
       }
     });
   };
   const onFailure = (error) => {
     
     swal("Sorry!", error.message, "error");
 
   };
  

 


  return (
    <>
    

      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929", color:"white" }} >
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Agent Name</th>
            <th scope="col">CNIC</th>
            <th scope="col">Email</th>
            <th scope="col">Estate Name</th>
           
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {props.paginatedPosts !== null &&
          props.paginatedPosts !== undefined ? (
            props.paginatedPosts.map((posts, index) => {
              return (
                <tr>
                  <td>{sNo++}</td>
                  <td>{posts?.fullName}</td>
                  <td>{posts?.cnic}</td>
                  <td>{posts?.email}</td>
                  <td>{posts?.realEstate?.estateName}</td>
                <td>{posts?.isVerified==false ? (
                      <span className="badge badge-danger">
                      Not Verified
                      </span>
                    ) : posts?.isVerified==true ? (
                      <span className="badge badge-success">
                        Verified
                      </span>
                    ) : (
                      ""
                    )}</td>
               
                <th> 
              <Button
                      style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                    onClick={(e) => verification(posts)}
                      id="info"
                      disabled={posts.isVerified==true}
                   
                    >
                      <span className="btn-inner--icon">
                        Verify
                      </span>
                    </Button>
                    <Button
                      style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                    onClick={(e) => unVerification(posts)}
                      id="info"
                      disabled={posts?.isVerified==false}
                   
                    >
                      <span className="btn-inner--icon">
                        Unverified
                      </span>
                    </Button>
                   
                    </th>
                </tr>
              );
            }
            )
          ) : (
            <tr>
              <th></th>
              <th></th>

              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
         
          
        </tbody>
      </Table>
    </>
  );
};
export default TableOfAgentList;
