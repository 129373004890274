import { 
  BANK,BANK_SUCCESS,BANK_FAILURE,
 BANKEDIT,BANKEDIT_FAILURE,BANKEDIT_SUCCESS
} from "../constant.js";
export class BankEditDetail {
  static BankEdit() {
    return {
      type: BANKEDIT
  }
}
  static BankEdit_Success(response) {
    return {
      type: BANKEDIT_SUCCESS,
      payload: response,
    };
  }
  static BankEdit_Failure(error) {
    return {
      type: BANKEDIT_FAILURE,
      error,
    };
  }
 
}

export class BankDetail {
  static Bank() {
    return {
      type: BANK
  }
}
  static Bank_Success(response) {
    return {
      type: BANK_SUCCESS,
      payload: response,
    };
  }
  static Bank_Failure(error) {
    return {
      type: BANK_FAILURE,
      error,
    };
  }
 
}

