import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Headers from "components/Headers/Header1";
import { Button } from "reactstrap";
import newwlogoo from "assets/img/squarepro1.png";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GetSocketRequestDetails } from "sockets/socket";

const PaymentConfirmation = () => {
  var history = useHistory();
  var location = useLocation();

  const [state, setstate] = useState("");
  const [url, seturl] = useState("");
  let role = localStorage.getItem("roleid");
  useEffect(() => {
    setstate(location.state);
    console.log(location.state, "location.state");
    seturl(location.state?.blinqInvoice?.clickToPayUrl);
  }, [location.state]);

  const dateFunction = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Karachi",
    });
    return nDate;
  };
  const paymentLater = () => {
    history.push({ pathname: "/user/requestUserView" });
  };
  const onblinq = () => {
    GetSocketRequestDetails(state.id, history);
    window.open(`${url}`, "", "width=700,height=500,left=400,top=120,");
    // window.open(url, "_blank");
  };

  return (
    <div>
      <Headers />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card
              // style={{ marginLeft: "120px", marginRight: "120px" }}
              className="shadow m-auto w-50 "
            >
              <CardHeader
                className="border-0"
                style={{ backgroundColor: "#d69929" }}
              >
                <Row>
                  <Col lg="8" md="8" sm="8">
                    <img style={{ width: "25%" }} src={newwlogoo} />
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <h3 style={{ color: "#ffff" }}>Order id:{state.id}</h3>
                    {/* <br /> */}
                    <h3 style={{ color: "#ffff" }}>
                      {dateFunction(state.updatedAt)}
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              {/* <Divider /> */}
              {/* <hr /> */}

              <CardBody>
                <form>
                  <div>
                    <Row>
                      {/* <Col lg="2" md="2" sm="6">
                        {" "}
                      </Col> */}
                      <Col lg="10" md="10" sm="6">
                        <h1>{state.name}</h1>
                      </Col>
                      <Col lg="2" md="2" sm="5"></Col>
                    </Row>
                    <Row>
                      <Col lg="4" md="4" sm="5">
                        <h4>Email:</h4>
                      </Col>
                      <Col lg="4" md="4" sm="5">
                        <h4> {state.user?.email}</h4>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="4" md="4" sm="5">
                        <h4> Phone: </h4>
                      </Col>
                      <Col lg="4" md="4" sm="5">
                        <h4> {state.user?.phone}</h4>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="4" md="4" sm="5">
                        <h4>Agent Name:</h4>
                      </Col>
                      <Col lg="4" md="4" sm="5">
                        <h4> {state?.createdByUser?.fullName}</h4>
                      </Col>
                    </Row>
                    {role == 5 ? (
                      <Row>
                        <Col lg="4" md="4" sm="5">
                          <h4> Agency Name:</h4>
                        </Col>
                        <Col lg="4" md="4" sm="5">
                          <h4>
                            {state?.createdByUser?.realEstate?.estateName}
                          </h4>
                          <h4>
                            {
                              state?.createdByUser?.realEstateStaffProfile
                                ?.estateName
                            }
                          </h4>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <br />

                    <Row>
                      <Col lg="4" md="4" sm="5">
                        <h4> No of Forms</h4>
                      </Col>
                      <Col lg="4" md="4" sm="5">
                        <h4> {state.NumberOfApplication}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4" md="4" sm="5">
                        <h4> Total</h4>
                      </Col>
                      <Col lg="4" md="4" sm="5">
                        <h4>Rs:{state.Amount}</h4>
                      </Col>
                    </Row>

                    <br />
                    <br />
                    <Row>
                      <Col lg="6" md="6" sm="5">
                        <Button
                          style={{ backgroundColor: "#015652", color: "white" }}
                          id="search"
                          size="md"
                          onClick={paymentLater}
                        >
                          <span className="btn-inner--text"> Pay Later</span>
                        </Button>
                        <Button
                          style={{ backgroundColor: "#015652", color: "white" }}
                          id="search"
                          size="md"
                          onClick={onblinq}
                        >
                          <span className="btn-inner--text"> Pay Now</span>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentConfirmation;
