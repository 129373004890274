import React, { useEffect, useRef, useState } from "react";
// import Loader from "react-loader-spinner";
// import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table, Label } from "reactstrap";
import swal from "sweetalert";
// import ComponentToPrint from "./ComponentToPrint";
// import UpdatePaymentReceipt from "./UpdatePaymentReceipt";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PayNowModal from "./PayNowModal";

const TableRequestStaffView = ({ onChangeNoOfRows, post }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const ToggleViewPayNowModal = (data) => {
    setIsOpen(!isOpen);
    setModalData(data);
  };
  return (
    <div>
      <PayNowModal
        toggle={ToggleViewPayNowModal}
        modal={isOpen}
        user={modalData}
      />
      <Row className="mt--5">
        <Col xl="2" lg="4" md="4" sm="6" xs="6">
          <Label className="form-control-label"> Rows Per Pages </Label>
          <Input
            id="exampleFormControlSelect1"
            type="select"
            onChange={(e) => onChangeNoOfRows(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Input>
        </Col>
      </Row>
      <br />
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Application No</th>
            <th scope="col">Customer Name</th>
            <th scope="col">CNIC</th>
            {/* <th scope="col">Email</th> */}
            <th scope="col">Phone Number</th>
            {/* <th scope="col">Oreder ID</th> */}
            <th scope="col">Status</th>
            {/* <th scope="col">Action</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {post !== null && post !== undefined && post.length > 0 ? (
            post.map((request, index) => {
              return (
                <tr key={request.id}>
                  <td>{index + 1}</td>
                  <td>{request.applicationRequestId}</td>
                  <td>{request.name}</td>
                  <td>{request.cnic}</td>
                  {/* <td>{request.name}</td> */}
                  <td>{request.phone}</td>
                  {/* <td>{request.name}</td> */}
                  <td>
                    <span class="badge badge-success">{request.status}</span>
                  </td>
                  {/* <td>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      id="search"
                      size="sm"
                      onClick={() => ToggleViewPayNowModal(request)}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Pay Now</span>
                    </Button>
                  </td> */}
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableRequestStaffView;
