import {
  FETCH_AGENT_APP_LIST,
  FETCH_AGENT_APP_LIST_SUCCESS,
  FETCH_AGENT_APP_LIST_FAILURE,
} from "../constant";

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  PriceResponse: null,
  agentAppList: [],
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_AGENT_APP_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        agentAppList: [],
        error: {},
      };
    case FETCH_AGENT_APP_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        agentAppList: action.payload,
      };
    case FETCH_AGENT_APP_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
