import React, { useEffect, useRef, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Input,
  Container,
  Card,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import validate from "components/Utilities Component/ValidationWrapper";
import {
  _GetpaymentThrough,
  _CreatePaymentReceipt,
  EditById_Middleware,
} from "../middleware";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";

const AddNewStaff = ({ _EditById_Middleware }) => {
  const location = useLocation();
  const userInfo = location.state;
  const history = useHistory();
  const [staff, setStaff] = useState({
    fullName: userInfo?.user ? userInfo.user.fullName : "",
    cnic: userInfo?.user ? userInfo.user.cnic : "",
    email: userInfo?.user ? userInfo.user.email : "",
    phone: userInfo?.user ? userInfo.user.phone : "",
    bankId: userInfo?.user ? userInfo.bankId : "",
    branchId: userInfo?.user ? userInfo.branchId : "",
    profilePhoto: userInfo?.user ? userInfo.user.profilePhoto : "",
    whatsAppNo: userInfo?.user ? userInfo.user.whatsAppNo : "",
  });

  const [error, setError] = useState({
    nameError: null,
    mobileError: null,
    whatsAppNoError: null,
  });

  const CheckFields = (name) => {
    if (name === "name") {
      setError({
        ...error,
        nameError: validate("required", staff.fullName),
      });
    } else if (name === "mobile") {
      setError({
        ...error,
        mobileError: validate("phoneNumber", staff.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("phoneNumber", staff.whatsAppNo),
      });
    }
  };

  const onSuccessUpdate = (msg) => {
    swal({
      title: "Congratulations!",
      text: `${msg}`,
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push("/user/staffList");
      }
    });
  };
  const onFailureUpdate = (msg) => {
    swal("Sorry!", msg, "error");
  };

  const handleSendData = () => {
    setError({
      ...error,
      nameError: validate("required", staff.fullName),
      mobileError: validate("phoneNumber", staff.phone),
      whatsAppNoError: validate("phoneNumber", staff.whatsAppNo),
    });
    if (
      staff.fullName !== "" &&
      staff.phone !== "" &&
      staff.whatsAppNo !== ""
    ) {
      let body = {
        fullName: staff.fullName,
        phone: staff.phone.includes("+") ? staff.phone : `+${staff.phone}`,
        whatsAppNo: staff.whatsAppNo.includes("+")
          ? staff.whatsAppNo
          : `+${staff.whatsAppNo}`,
      };
      _EditById_Middleware(
        body,
        userInfo.userId,
        onSuccessUpdate,
        onFailureUpdate
      );
    }
  };
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h3 className="mb-0">Update Staff</h3>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Name <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Client Name"
                      value={staff.fullName}
                      onBlur={() => CheckFields("name")}
                      onChange={(e) =>
                        setStaff({ ...staff, fullName: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.nameError !== "" && error.nameError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.nameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      CNIC <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter your CNIC"
                      value={staff.cnic}
                      maxLength={13}
                      onBlur={() => CheckFields("CNIC")}
                      onChange={(e) =>
                        setStaff({ ...staff, cnic: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      disabled={userInfo?.user}
                    ></input>
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      WhatsApp No. <span className="text-red">*</span>
                    </label>

                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={staff.whatsAppNo}
                      placeholder="92xxxxxxxxxx"
                      onBlur={() => CheckFields("whatsAppNo")}
                      onChange={(e) =>
                        setStaff({ ...staff, whatsAppNo: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      maxLength={staff.whatsAppNo.includes("+") ? 13 : 12}
                    ></input>
                    {error.whatsAppNoError !== "" &&
                      error.whatsAppNoError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.whatsAppNoError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Mobile No. <span className="text-red">*</span>
                    </label>

                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={staff.phone}
                      placeholder="92xxxxxxxxxx"
                      maxLength={staff.phone.includes("+") ? 13 : 12}
                      onBlur={() => CheckFields("mobile")}
                      onChange={(e) =>
                        setStaff({ ...staff, phone: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                    ></input>
                    {error.mobileError !== "" && error.mobileError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.mobileError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <label className="form-control-label" for="input-username">
                      Email <span className="text-red">*</span>
                    </label>
                    <input
                      type="email"
                      id="input-username"
                      className="form-control"
                      value={staff.email}
                      placeholder="Enter Email .."
                      onBlur={() => CheckFields("email")}
                      onChange={(e) =>
                        setStaff({ ...staff, email: e.target.value })
                      }
                      autoComplete="off"
                      disabled={userInfo?.user}
                    ></input>
                  </Col>
                  <br />
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      id="save"
                      onClick={handleSendData}
                    >
                      <span className="btn-inner--icon">Submit</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _EditById_Middleware: (body, id, onSuccess, onFailure) =>
      dispatch(EditById_Middleware(body, id, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AddNewStaff);
