import axios from "axios";
import { BankListDetail, BankDeleteDetail } from "../action/index";
import { BANKLIST_PATH, BANKDELETE_PATH } from "../constant";
import { BASEURL } from "config/api/URL";
export const DeleteBankMiddleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(BankDeleteDetail.BankDelete());
    let token = localStorage.getItem("auth");
    axios
      .delete(`${BASEURL}${BANKDELETE_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(BankDeleteDetail.BankDelete_Success(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };

export const GetBankListMiddleware =
  (pageNumber, noOfRows, onSuccess, onFailure) => (dispatch) => {
    dispatch(BankListDetail.BankList());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${BANKLIST_PATH}?page=${pageNumber}&limit=${noOfRows}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(BankListDetail.BankList_Success(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          // onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };
