import {
  FETCH_FEEDBACK_LIST,
  FETCH_FEEDBACK_LIST_SUCCESS,
  FETCH_FEEDBACK_LIST_FAILURE,
  FETCH_FEEDBACK_LIST_PATH,
} from "../constant.js";

const INITIAL_STATE = {
  Reports: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
  AdminFeedbackList: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FETCH_FEEDBACK_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        AdminFeedbackList: [],
        error: {},
      };
    case FETCH_FEEDBACK_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        AdminFeedbackList: action.payload,
      };
    case FETCH_FEEDBACK_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
