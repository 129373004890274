export const FETCH_ALL_REQUEST = "FETCH_ALL_REQUEST";
export const FETCH_ALL_REQUEST_SUCCESS = "FETCH_ALL_REQUEST_SUCCESS";
export const FETCH_ALL_REQUEST_FAILURE = "FETCH_ALL_REQUEST_FAILURE"; //application-request/list-for-manager
// export const FETCH_ALL_REQUEST_PATH = "customs/get-list-for-manager";
export const FETCH_ALL_REQUEST_PATH = "application-request/list-for-manager";

export const FETCH_ALL_STAFF = "FETCH_ALL_STAFF";
export const FETCH_ALL_STAFF_SUCCESS = "FETCH_ALL_STAFF_SUCCESS";
export const FETCH_ALL_STAFF_FAILURE = "FETCH_ALL_STAFF_FAILURE";
export const FETCH_ALL_STAFF_PATH = "staff-profile/get-all-staff/for-manager";

export const PRINT_FORM = "PRINT_FORM";
export const PRINT_FORM_SUCCESS = "PRINT_FORM_SUCCESS";
export const PRINT_FORM_FAILURE = "PRINT_FORM_FAILURE";
export const PRINT_FORM_PATH = "uploadfile/download/form";

export const PAY_FORM_FEE = "PAY_FORM_FEE";
export const PAY_FORM_FEE_SUCCESS = "PAY_FORM_FEE_SUCCESS";
export const PAY_FORM_FEE_FAILURE = "PAY_FORM_FEE_FAILURE";
export const PAY_FORM_FEE_PATH = "application-request/pay-form-fee-for-staff";
