import axios from "axios";
import { CustomerDetail,CustomerEditDetail } from "../action/index";
import { CUSTOMER_PATH,CUSTOMEREDIT_PATH } from "../constant";
import { BASEURL } from "config/api/URL";
export const EditCustomerMiddleware = (id,body, OnSuccess, OnFailure) => (dispatch) => {
 
 
   dispatch(CustomerEditDetail.CustomerEdit());
 
   let token = localStorage.getItem("auth");
   axios
     .patch(`${BASEURL}${CUSTOMEREDIT_PATH}/${id}`, body, {
       headers: {
         "Content-Type": "application/json",
         Authorization: `bearer ${token}`,
       },
     })
     .then((res) => {
       
      // if (res.data.status === true) {
         dispatch(CustomerEditDetail.CustomerEdit_Success(res.data));
         dispatch(OnSuccess(res.data.message));
      // } 
       
     })
     .catch(function (error) {
       if (error.response) {
        
         OnFailure(error.response.data)
       } else if (error.request) {
       } else {
       }
     });
 };
 


export const InsertCustomerMiddleware = (body, OnSuccess, OnFailure) => (dispatch) => {


  dispatch(CustomerDetail.Customer());

  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${CUSTOMER_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      // console.log(res.data);
      //if (res.data.status === true) {
        dispatch(CustomerDetail.Customer_Success(res.data));
        dispatch(OnSuccess(res.data.message));
   //   } 
      // else {
      //   dispatch(UserSignUp.SignUpFailure(res.data.message));
      //   dispatch(OnFailure(res.data.message));
      // }
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        // console.log(error.response.data);
        OnFailure(error.response.data)
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};
