import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Input, Row, Table, Label, CardBody } from "reactstrap";
import moment from "moment";
import { BASEURL } from "config/api/URL";
import { useHistory } from "react-router-dom";
import { GetSocketRequestDetails } from "sockets/socket";

const TableRequestUserView = ({ post, onChangeNoOfRows }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewReceipt, setViewReceipt] = useState(false);
  // const [isOk, setIsOk] = useState(false);
  // const [printReceiptInfo, setPrintReceiptInfo] = useState({});
  const componentRef = useRef();
  var history = useHistory();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  // const print = (receipt) => {
  //   setPrintReceiptInfo(receipt);
  //   setIsOk(true);
  // };

  // const ToggleUpdateReceiptModal = () => {
  //   setIsOpen(!isOpen);
  // };
  const ToggleViewReceiptModal = (post) => {
    setIsViewModalOpen(!isViewModalOpen);
    setViewReceipt(post);
  };

  console.log(post, "post");
  const payNow = (receipt) => {
    history.push({ pathname: "/user/PaymentConfirmation", state: receipt });
  };
  //  const proceed=()=>{
  //   history.push({pathname:'/user/ApplicationList'} )
  //  }
  // const print=(id)=>{
  // let token=localStorage.getItem("auth")
  // window.open(`${BASEURL}/real-estate/download-application/${id}/${token}`,"_blank")
  // }
  const dateFunction = (date) => {
    const nDate = new moment(date).format("DD MMM, YYYY");

    console.log("nDate", nDate);
    return nDate;
  };

  // useEffect(()=>{
  //   console.log("TETRETETE")

  // history.push("/zaman")
  // },[])
  return (
    <div>
     {/* <CardBody> */}
      
   
      
      {/* </CardBody> */}
      {/* <Row className="mt-5"> */}
       
      {/* </Row> */}
      {/* <br /> */}
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Application No</th>
            <th scope="col">No. Of Applications</th>
            <th scope="col">Name</th>
            <th scope="col">CNIC</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Whatsapp No</th>
        
            
            {/* <th scope="col">Type</th> */}
            {/* <th scope="col">Date</th> */}
            
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {post !== null && post !== undefined && post.length > 0 ? (
            post.map((request, index) => {
              {
                GetSocketRequestDetails(request.id, history);
              }
              return (
                <tr key={request.id}>
                  <td>{index + 1}</td>
                  <td>{request.id}</td>
                  <td>{request.NumberOfApplication}</td>
                  <td>{request.name}</td>
                  <td>{request.user.cnic}</td>
                  <td>{request.user.email}</td>
                  <td>{request.user.phone}</td>
                  <td>{request.user.whatsAppNo}</td>
                  {/* <td>{request.name}</td> */}
                  {/* <td>{request.type}</td> */}
                  {/* <td>{dateFunction(request.createdAt)}</td> */}
                 
                  <td>{request.Amount}</td>
                  <td>
                    {request?.Status == "Pending" ? (
                      <span className="badge badge-danger">
                        {request?.Status}
                      </span>
                    ) : request?.Status == "Paid" ? (
                      <span className="badge badge-success">
                        {request?.Status}
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <Button
                      disabled={request.Status == "Paid"}
                      style={{ backgroundColor: "#015652", color: "white" }}
                      id="search"
                      size="sm"
                      onClick={() => payNow(request)}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Pay Now</span>
                    </Button>
                    {/* <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      id="search"
                      size="sm"

                       onClick={() => print(request.id)}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Print</span>
                    </Button> */}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableRequestUserView;
