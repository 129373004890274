import React, { useEffect, useRef, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Input,
  Container,
  Card,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import validate from "components/Utilities Component/ValidationWrapper";
import {
  // _GetpaymentThrough,
  // _CreatePaymentReceipt,
  FetchBranchesById_Middleware,
  // FetchRoles_Middleware,
  // Staff_SignUp,
  // Admin_SignUp,
  EditById_Middleware,
  Manager_SignUp,
} from "../middleware";
import { GetBankListMiddleware } from "views/AdminView/BankList/middleware";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";

const AddNewManager = ({
  _GetBankListMiddleware,
  _FetchBranchesById_Middleware,
  // _FetchRoles_Middleware,
  // _Staff_SignUp,
  // _Admin_SignUp,
  response,
  BranchesById,
  // Roles,
  _EditById_Middleware,
  _Manager_SignUp,
}) => {
  const location = useLocation();
  const userInfo = location.state;
  const history = useHistory();
  const [banks, setBanks] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [isTrue, setIsTrue] = useState(false);
  // const [bankID, setBankID] = useState();
  const [staff, setStaff] = useState({
    fullName: userInfo?.user ? userInfo.user.fullName : "",
    cnic: userInfo?.user ? userInfo.user.cnic : "",
    email: userInfo?.user ? userInfo.user.email : "",
    phone: userInfo?.user ? userInfo.user.phone : "",
    // role: userInfo?.user ? userInfo.user.roleId : "",
    bankId: userInfo?.user ? userInfo.bankId : "",
    branchId: userInfo?.user ? userInfo.branchId : "",
    profilePhoto: userInfo?.user ? userInfo.user.profilePhoto : "",
    whatsAppNo: userInfo?.user ? userInfo.user.whatsAppNo : "",
  });

  const [error, setError] = useState({
    nameError: null,
    cnicError: null,
    mobileError: null,
    // whatsAppNoError: null,
    bankError: null,
    emailError: null,
    branchError: null,
  });

  const CheckFields = (name) => {
    if (name === "name") {
      setError({
        ...error,
        nameError: validate("required", staff.fullName),
      });
    } else if (name === "CNIC") {
      setError({
        ...error,
        cnicError: validate("CNIC", staff.cnic),
      });
    } else if (name === "mobile") {
      setError({
        ...error,
        mobileError: validate("phoneNumber", staff.phone),
      });
    }
    // else if (name === "whatsAppNo") {
    //   setError({
    //     ...error,
    //     whatsAppNoError: validate("phoneNumber", staff.whatsAppNo),
    //   });
    // }
    else if (name === "bank") {
      setError({
        ...error,
        bankError: validate("required", staff.bankId),
      });
    } else if (name === "branch") {
      setError({
        ...error,
        branchError: validate("required", staff.branchId),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", staff.email),
      });
    }
  };
  // const onStaffSignUpSuccess = (msg) => {
  //   swal({
  //     title: "Congratulations!",
  //     text: `${msg} successfully!`,
  //     icon: "success",
  //   }).then((isOk) => {
  //     if (isOk) {
  //       history.push("/user/StaffList");
  //     }
  //   });
  // };
  // const onStaffSignUpFailure = (msg) => {
  //   console.log(msg);
  //   swal("Sorry!", "Something went wrong please contact to admin", "error");
  // };
  const onManagerSignUpSuccess = (msg) => {
    swal({
      title: "Congratulations!",
      text: `${msg} successfully!`,
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push("/user/managerList");
      }
    });
  };
  const onManagerSignUpFailure = (msg) => {
    swal("Sorry!", msg, "error");
    setIsTrue(false);
  };
  const onSuccessUpdate = (msg) => {
    swal({
      title: "Congratulations!",
      text: `${msg} successfully!`,
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push("/user/managerList");
      }
    });
  };
  const onFailureUpdate = (msg) => {
    swal("Sorry!", msg, "error");
    setIsTrue(false);
  };
  const onBankSuccess = () => {};
  const onBankFailure = () => {};
  // const onRolesSuccess = () => {};
  // const onRolesFailure = () => {};
  const onBranchSuccess = () => {};
  const onBranchFailure = () => {};

  const handleSendData = () => {
    setError({
      ...error,
      nameError: validate("required", staff.fullName),
      cnicError: validate("CNIC", staff.cnic),
      mobileError: validate("phoneNumber", staff.phone),
      bankError: validate("required", staff.bankId),
      branchError: validate("required", staff.branchId),
      emailError: validate("email", staff.email),
      // whatsAppNoError: validate("phoneNumber", staff.whatsAppNo),
    });
    if (
      staff.fullName !== "" &&
      staff.cnic !== "" &&
      staff.phone !== "" &&
      staff.bankId !== "" &&
      staff.email !== "" &&
      staff.branchId !== "" &&
      // staff.whatsAppNo != "" &&
      userInfo === undefined
    ) {
      setIsTrue(true);
      let body = {
        fullName: staff.fullName,
        cnic: staff.cnic,
        phone: `+${staff.phone}`,
        whatsAppNo:
          staff.whatsAppNo == "" ? staff.whatsAppNo : `+${staff.whatsAppNo}`,
        bankId: parseInt(staff.bankId),
        email: staff.email,
        branchId: parseInt(staff.branchId),
        profilePhoto: "ok.png",
      };
      _Manager_SignUp(body, onManagerSignUpSuccess, onManagerSignUpFailure);
    } else if (
      staff.fullName !== "" &&
      staff.cnic !== "" &&
      staff.phone !== "" &&
      staff.bankId !== "" &&
      staff.email !== "" &&
      staff.branchId !== "" &&
      userInfo !== undefined
    ) {
      setIsTrue(true);
      let body = {
        fullName: staff.fullName,
        // cnic: staff.cnic,
        phone: staff.phone,
        // bankId: staff.bankId,
        // email: staff.email,
        // branchId: staff.branchId,
        whatsAppNo:
          staff.whatsAppNo == ""
            ? staff.whatsAppNo
            : staff.whatsAppNo.includes("+")
            ? staff.whatsAppNo
            : `+${staff.whatsAppNo}`,
      };
      _EditById_Middleware(
        body,
        userInfo.userId,
        onSuccessUpdate,
        onFailureUpdate
      );
    }
  };

  const handleChange = (id) => {
    setStaff({ ...staff, bankId: id });
    _FetchBranchesById_Middleware(id, onBranchSuccess, onBranchFailure);
  };

  useEffect(() => {
    _GetBankListMiddleware(1, 100, onBankSuccess, onBankFailure);
  }, []);

  useEffect(() => {
    setBanks(response?.items);
  }, [response]);

  useEffect(() => {
    setBranchs(BranchesById);
  }, [BranchesById]);
  useEffect(() => {
    if (userInfo?.bankId) {
      _FetchBranchesById_Middleware(
        userInfo.bankId,
        onBranchSuccess,
        onBranchFailure
      );
    }
  }, [userInfo?.bankId]);
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h3 className="mb-0">Add New Manager</h3>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Name <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Client Name"
                      value={staff.fullName}
                      onBlur={() => CheckFields("name")}
                      onChange={(e) =>
                        setStaff({ ...staff, fullName: e.target.value })
                      }
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (!/^[A-Za-z ]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></input>
                    {error.nameError !== "" && error.nameError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.nameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      CNIC <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter your CNIC"
                      value={staff.cnic}
                      maxLength={13}
                      onBlur={() => CheckFields("CNIC")}
                      onChange={(e) =>
                        setStaff({ ...staff, cnic: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      disabled={userInfo !== undefined}
                    ></input>
                    {error.cnicError !== "" && error.cnicError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.cnicError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      WhatsApp No.
                    </label>

                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={staff.whatsAppNo}
                      placeholder="92xxxxxxxxxx"
                      maxLength={staff.whatsAppNo.includes("+") ? 13 : 12}
                      onBlur={() => CheckFields("whatsAppNo")}
                      onChange={(e) =>
                        setStaff({ ...staff, whatsAppNo: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                    ></input>
                    {/* {error.whatsAppNoError !== "" &&
                      error.whatsAppNoError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.whatsAppNoError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )} */}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Mobile No. <span className="text-red">*</span>
                    </label>

                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={staff.phone}
                      placeholder="92xxxxxxxxxx"
                      maxLength={staff.phone.includes("+") ? 13 : 12}
                      onBlur={() => CheckFields("mobile")}
                      onChange={(e) =>
                        setStaff({ ...staff, phone: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                    ></input>
                    {error.mobileError !== "" && error.mobileError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.mobileError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Bank <span className="text-red">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={staff.bankId}
                      onChange={(e) => handleChange(e.target.value)}
                      onBlur={() => CheckFields("bank")}
                      disabled={userInfo !== undefined}
                    >
                      <option value="">Select Bank</option>
                      {banks !== null && banks !== undefined
                        ? banks.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>
                                {data.bankName}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                    {error.bankError !== "" && error.bankError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.bankError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Branch Code <span className="text-red">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={staff.branchId}
                      onBlur={() => CheckFields("branch")}
                      onChange={(e) =>
                        setStaff({ ...staff, branchId: e.target.value })
                      }
                      disabled={userInfo !== undefined}
                    >
                      <option>Select Branch Code</option>
                      {branchs !== null && branchs !== undefined
                        ? branchs.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>
                                {data.branchCode}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                    {error.branchError !== "" && error.branchError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.branchError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <br />
                </Row>
                <Row>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <label className="form-control-label" for="input-username">
                      Email <span className="text-red">*</span>
                    </label>
                    <input
                      type="email"
                      id="input-username"
                      className="form-control"
                      value={staff.email}
                      placeholder="Enter Email .."
                      onBlur={() => CheckFields("email")}
                      onChange={(e) =>
                        setStaff({ ...staff, email: e.target.value })
                      }
                      autoComplete="off"
                      disabled={userInfo !== undefined}
                    ></input>
                    {error.emailError !== "" && error.emailError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.emailError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <br />
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      id="save"
                      onClick={handleSendData}
                      disabled={isTrue}
                    >
                      <span className="btn-inner--icon">Submit</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  response: state.bankList.response,
  BranchesById: state.managerList.BranchesById,
  // Roles: state.managerList.Roles,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _GetBankListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
    _FetchBranchesById_Middleware: (id, onSuccess, onFailure) =>
      dispatch(FetchBranchesById_Middleware(id, onSuccess, onFailure)),
    // _FetchRoles_Middleware: (onSuccess, onFailure) =>
    //   dispatch(FetchRoles_Middleware(onSuccess, onFailure)),
    // _Staff_SignUp: (body, onSuccess, onFailure) =>
    //   dispatch(Staff_SignUp(body, onSuccess, onFailure)),
    _Manager_SignUp: (body, onSuccess, onFailure) =>
      dispatch(Manager_SignUp(body, onSuccess, onFailure)),
    // _Admin_SignUp: (body, onSuccess, onFailure) =>
    //   dispatch(Admin_SignUp(body, onSuccess, onFailure)),
    _EditById_Middleware: (body, onSuccess, onFailure) =>
      dispatch(EditById_Middleware(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AddNewManager);
