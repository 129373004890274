import axios from "axios";
import {
  MeetingNotification,
  NotificationListDetail,
  NotificationRead,
  UpdateNotification,
} from "../action";
import {
  NOTIFICATIONS_PATH,
  UPDATE_NOTIFICATIONS_PATH,
  READ_NOTIFICATIONS_PATH,
} from "../constant";
import { BASEURL } from "../../../../config/api/URL";

export const GetNotificationListMiddleware =
  (pageNumber, noOfNotify, onSuccess, onFailure) => (dispatch) => {
    dispatch(NotificationListDetail.Notificationlist());
    let token = localStorage.getItem("auth");
    // const limit = 100;
    axios
      .get(
        `${BASEURL}${NOTIFICATIONS_PATH}?page=${pageNumber}&limit=${noOfNotify}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log("Notification Response", res.data.data.notification);
        dispatch(
          NotificationListDetail.Notificationlist_Success(res.data.data)
        );
        // dispatch(onSuccess(res.message));
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
        } else {
        }
      });
  };
export const ReadNotifcationMiddleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    // console.log("BOODY", body);
    dispatch(NotificationRead.NotificationsRead());
    let token = localStorage.getItem("auth");
    // const limit = 100;
    axios
      .patch(`${BASEURL}${READ_NOTIFICATIONS_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log("Notification Response", res.data.data);
        // dispatch(NotificationRead.NotificationRead_Success(res.data.data));
        // dispatch(onSuccess(res.message));
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
        } else {
        }
      });
  };

export const CheckNotification = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(MeetingNotification.Notification());
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${NOTIFICATIONS_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.data.status === true) {
        dispatch(MeetingNotification.NotificationSuccess(res.data.response));

        dispatch(onSuccess(res.data.message));
      } else {
        dispatch(MeetingNotification.NotificationFailure(res.data.message));

        dispatch(onFailure(res.data.message));
      }
    })
    .catch((error) => dispatch(MeetingNotification.NotificationFailure(error)));
};

export const UpdateNotificationStatus =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(UpdateNotification.Update());
    let token = localStorage.getItem("auth");
    axios
      .put(`${BASEURL}${UPDATE_NOTIFICATIONS_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          dispatch(UpdateNotification.UpdateSuccess(res.data.response));

          dispatch(onSuccess(res.data.message));
        } else {
          dispatch(UpdateNotification.UpdateFailure(res.data.message));

          dispatch(onFailure(res.data.message));
        }
      })
      .catch((error) => dispatch(UpdateNotification.UpdateFailure(error)));
  };
