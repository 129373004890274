export const FETCH_ALL_RECEIPT = "FETCH_ALL_RECEIPT";
export const FETCH_ALL_RECEIPT_SUCCESS = "FETCH_ALL_RECEIPT_SUCCESS";
export const FETCH_ALL_RECEIPT_FAILURE = "FETCH_ALL_RECEIPT_FAILURE";
export const FETCH_ALL_RECEIPT_PATH = "reciept/getDetails";

export const CANCELATION = "CANCELATION";
export const CANCELATION_SUCCESS = "CANCELATION_SUCCESS";
export const CANCELATION_FAILURE = "CANCELATION_FAILURE";
export const CANCELATION_PATH = "reciept/cancelReceipt";

export const STATUS = "STATUS";
export const STATUS_SUCCESS = "STATUS_SUCCESS";
export const STATUS_FAILURE = "STATUS_FAILURE";
export const STATUS_PATH = "status/getbyid";
