import React, { useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Container,
  Card,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import validate from "components/Utilities Component/ValidationWrapper";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { BlockedMiddleware, VerifyMiddleware } from "../middleware";

const Verify_Blocked = ({ _VerifyMiddleware, _BlockedMiddleware }) => {
  const [isTrue, setIsTrue] = useState(false);
  const [isTrue2, setIsTrue2] = useState(false);
  const [body, setBody] = useState({
    portalType: "",
    formNo: "",
    depositSlipNo: "",
  });

  const [error, setError] = useState({
    portalTypeError: null,
    formNoError: null,
    depositSlipNoError: null,
  });

  const CheckFields = (name) => {
    if (name === "portalType") {
      setError({
        ...error,
        portalTypeError: validate("required", body.portalType),
      });
    } else if (name === "formNo") {
      setError({
        ...error,
        formNoError: validate("required", body.formNo),
      });
    } else if (name === "depositSlipNo") {
      setError({
        ...error,
        depositSlipNoError: validate("required", body.depositSlipNo),
      });
    }
  };

  const onSuccess = (msg) => {
    swal("Congratulations!", msg, "success");
    setBody({ portalType: "", formNo: "", depositSlipNo: "" });
  };
  const onFailure = (msg) => {
    swal("Sorry!", msg.message, "error");
    setIsTrue(false);
    setIsTrue2(true);
  };

  const onSuccess2 = (msg) => {
    swal("Congratulations!", msg, "success");
    setIsTrue2(false);
    setBody({ portalType: "", formNo: "", depositSlipNo: "" });
  };
  const onFailure2 = (msg) => {
    swal("Sorry!", msg.message, "error");
  };

  const handleSendData = () => {
    setError({
      ...error,
      portalTypeError: validate("required", body.portalType),
      formNoError: validate("required", body.formNo),
      depositSlipNoError: validate("required", body.depositSlipNo),
    });
    if (
      body.depositSlipNo !== "" &&
      body.formNo !== "" &&
      body.portalType !== ""
    ) {
      setIsTrue(true);
      _VerifyMiddleware(body, onSuccess, onFailure);
    }
  };
  const handleSendData2 = () => {
    setError({
      ...error,
      portalTypeError: validate("required", body.portalType),
      formNoError: validate("required", body.formNo),
      depositSlipNoError: validate("required", body.depositSlipNo),
    });
    if (body.formNo !== "" && body.portalType !== "") {
      _BlockedMiddleware(
        { formNo: body.formNo, portalType: body.portalType },
        onSuccess2,
        onFailure2
      );
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h1 className="mb-0">Verify</h1>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Search by Portal <span className="text-red">*</span>
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setBody({ ...body, portalType: e.target.value })
                      }
                      onBlur={() => CheckFields("portalType")}
                      value={body.portalType}
                    >
                      <option value="">Search Type</option>
                      <option value="BANKING">BANKING</option>
                      <option value="REALESTATE">REALESTATE</option>
                    </select>
                    {error.portalTypeError !== "" &&
                      error.portalTypeError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.portalTypeError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Form No. <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Form number here"
                      value={body.formNo}
                      onBlur={() => CheckFields("formNo")}
                      onChange={(e) =>
                        setBody({ ...body, formNo: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.formNoError !== "" && error.formNoError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.formNoError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Deposit Slip No. <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={body.depositSlipNo}
                      placeholder="Enter Slip number"
                      maxLength={11}
                      onBlur={() => CheckFields("depositSlipNo")}
                      onChange={(e) =>
                        setBody({ ...body, depositSlipNo: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9-+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      autoComplete="off"
                    ></input>
                    {error.depositSlipNoError !== "" &&
                      error.depositSlipNoError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.depositSlipNoError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      id="save"
                      onClick={handleSendData}
                      disabled={isTrue}
                    >
                      <span className="btn-inner--icon">Verify</span>
                    </Button>
                    {isTrue2 ? (
                      <Button
                        style={{ backgroundColor: "#015652", color: "white" }}
                        className="btn-icon btn-2"
                        type="button"
                        id="save"
                        onClick={handleSendData2}
                        // disabled={isTrue}
                      >
                        <span className="btn-inner--icon">Block</span>
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  verify: state.verify_blocked.verify,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _VerifyMiddleware: (body, onSuccess, onFailure) =>
      dispatch(VerifyMiddleware(body, onSuccess, onFailure)),
    _BlockedMiddleware: (body, onSuccess, onFailure) =>
      dispatch(BlockedMiddleware(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Verify_Blocked);
