import React from "react";
import Headers from "components/Headers/Header1";
import Filteration from "./Filteration";
import {Container,Card,Row,} from "reactstrap";


const Branch = (props) => {
 

  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
            <Filteration  InsertBranchMiddleware={props.InsertBranchMiddleware}  
            EditBranchMiddleware={props.EditBranchMiddleware} 
            GetBankListMiddleware={props.GetBankListMiddleware} response={props.response}
            ></Filteration>
             
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Branch;
