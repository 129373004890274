import {
  APP_REQUEST_TOTAL_COUNT,
  APP_REQUEST_TOTAL_COUNT_SUCCESS,
  APP_REQUEST_TOTAL_COUNT_FAILURE,
  BANK_APP_TOTAL_COUNT,
  BANK_APP_TOTAL_COUNT_SUCCESS,
  BANK_APP_TOTAL_COUNT_FAILURE,
  CUSTOMER_REQ_TOTAL_COUNT,
  CUSTOMER_REQ_TOTAL_COUNT_SUCCESS,
  CUSTOMER_REQ_TOTAL_COUNT_FAILURE,
  REAL_STATE_TOTAL_COUNT,
  REAL_STATE_TOTAL_COUNT_SUCCESS,
  REAL_STATE_TOTAL_COUNT_FAILURE,
  APPREQUEST,
  APPREQUEST_FAILURE,
  APPREQUEST_SUCCESS,
  BANKAPP,
  BANKAPP_FAILURE,
  BANKAPP_SUCCESS,
  AGENTAPP,
  AGENTAPP_FAILURE,
  AGENTAPP_SUCCESS,
  CUSTOMERAPP,
  CUSTOMERAPP_FAILURE,
  CUSTOMERAPP_SUCCESS,
} from "../constant";

const INITIAL_STATE = {
  req: null,
  bank: null,
  agent: null,
  customer: null,
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case REAL_STATE_TOTAL_COUNT:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        RealStateCount_req: null,
        error: {},
      };
    case REAL_STATE_TOTAL_COUNT_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        RealStateCount_req: action.payload,
      };
    case REAL_STATE_TOTAL_COUNT_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case CUSTOMER_REQ_TOTAL_COUNT:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        CustomerCount_req: null,
        error: {},
      };
    case CUSTOMER_REQ_TOTAL_COUNT_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        CustomerCount_req: action.payload,
      };
    case CUSTOMER_REQ_TOTAL_COUNT_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case BANK_APP_TOTAL_COUNT:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        BankCount_req: null,
        error: {},
      };
    case BANK_APP_TOTAL_COUNT_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        BankCount_req: action.payload,
      };
    case BANK_APP_TOTAL_COUNT_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case APPREQUEST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        req: null,
        error: {},
      };
    case APPREQUEST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        req: action.payload,
      };
    case APPREQUEST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case APP_REQUEST_TOTAL_COUNT:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Total_req: null,
        error: {},
      };
    case APP_REQUEST_TOTAL_COUNT_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Total_req: action.payload,
      };
    case APP_REQUEST_TOTAL_COUNT_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case BANKAPP:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        bank: null,
        error: {},
      };
    case BANKAPP_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        bank: action.payload,
      };
    case BANKAPP_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case CUSTOMERAPP:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        customer: null,
        error: {},
      };
    case CUSTOMERAPP_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        customer: action.payload,
      };
    case CUSTOMERAPP_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case AGENTAPP:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        agent: null,
        error: {},
      };
    case AGENTAPP_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        agent: action.payload,
      };
    case AGENTAPP_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case BANKAPP:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        bank: null,
        error: {},
      };
    case BANKAPP_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        bank: action.payload,
      };
    case BANKAPP_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case APPREQUEST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        req: null,
        error: {},
      };
    case APPREQUEST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        req: action.payload,
      };
    case APPREQUEST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    case APPREQUEST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        req: null,
        error: {},
      };
    case APPREQUEST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        req: action.payload,
      };
    case APPREQUEST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
