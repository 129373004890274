import axios from "axios";
import { PlotType, Balloting, WinnerList, MembersCount } from "../action/index";
import {
  PLOT_TYPE_PATH,
  BALLOTING_PATH,
  WINNER_LIST_PATH,
  MEMBERS_COUNT_RESIDENTIAL_PATH,
} from "../constant";
import { BASEURL } from "config/api/URL";

export const MembersCountMiddleware = (onSuccess, onFailure) => (dispatch) => {
  dispatch(MembersCount.Fetch());
  let token = localStorage.getItem("auth");

  axios
    .get(`${BASEURL}${MEMBERS_COUNT_RESIDENTIAL_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(MembersCount.Fetch_Success(res.data.data));
      dispatch(onSuccess(res.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
      }
    });
};

export const PlotTypeMiddleware = (onSuccess, onFailure) => (dispatch) => {
  dispatch(PlotType.Fetch());
  axios
    .get(`${BASEURL}${PLOT_TYPE_PATH}`)
    .then((res) => {
      dispatch(PlotType.Fetch_Success(res.data.data));
      dispatch(onSuccess(res.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        onFailure(error.response.data);
      }
    });
};

export const BallotingMiddleware =
  (body, onSuccess, onFailure, onProgress) => (dispatch) => {
    dispatch(Balloting.Balloting());
    let token = localStorage.getItem("auth");
    let progress = 0;

    axios
      .post(`${BASEURL}${BALLOTING_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        onDownloadProgress(progressEvent) {
          progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          onProgress(progress);
        },
      })
      .then((res) => {
        dispatch(Balloting.Balloting_Success(res.data.data));
        dispatch(onSuccess(res.data.message));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
          onProgress(0);
        }
      });
  };

export const WinnerListMiddleware =
  (
    pageNumber,
    noOfRows,
    portal,
    status,
    searchplotType,
    onSuccess,
    onFailure
  ) =>
  (dispatch) => {
    dispatch(WinnerList.Fetch());
    let token = localStorage.getItem("auth");

    axios
      .get(
        `${BASEURL}${WINNER_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}&residentalId=${searchplotType}&portalType=${portal}&ballotingStatus=${status}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(WinnerList.Fetch_Success(res.data.data.items));
        dispatch(onSuccess(res.data.message));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };
