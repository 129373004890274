import axios from "axios";
import {
  AppReqDetail,
  BankAppDetail,
  Total_AppReqDetail,
  Total_BankAppDetail,
  Total_RealStateReqDetail,
  Total_CustomerReqDetail,
  AgentAppDetail,
  CustomerAppDetail,
} from "../action/index";
import {
  APPREQUEST_PATH,
  BANKAPP_PATH,
  APP_REQUEST_TOTAL_COUNT_PATH,
  BANK_APP_TOTAL_COUNT_PATH,
  REAL_STATE_TOTAL_COUNT_PATH,
  CUSTOMER_REQ_TOTAL_COUNT_PATH,
  AGENTAPP_PATH,
  CUSTOMERAPP_PATH,
} from "../constant";
import { BASEURL } from "config/api/URL";

export const CustomerAppCountMiddleware =
  (valueAndKey, onSuccess, onFailure) => (dispatch) => {
    dispatch(CustomerAppDetail.CustomerApp());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${CUSTOMERAPP_PATH}?status=${valueAndKey}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(CustomerAppDetail.CustomerApp_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
      })
      .catch(function (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  };

export const AgentAppCountMiddleware =
  (valueAndKey, onSuccess, onFailure) => (dispatch) => {
    dispatch(AgentAppDetail.AgentApp());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${AGENTAPP_PATH}?status=${valueAndKey}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(AgentAppDetail.AgentApp_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
      })
      .catch(function (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  };

export const BankAppCountMiddleware = (onSuccess, onFailure) => (dispatch) => {
  //&createdBy=${null}

  dispatch(BankAppDetail.BankApp());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${BANKAPP_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(BankAppDetail.BankApp_Success(res.data.data));
      dispatch(onSuccess(res.data.data));
    })
    .catch(function (error) {
      if (error.response) {
      } else if (error.request) {
      } else {
      }
    });
};

export const AppReqCountMiddleware =
  (status, valueAndKey, onSuccess, onFailure) => (dispatch) => {
    dispatch(AppReqDetail.AppRequest());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${APPREQUEST_PATH}?Status=${status}&roleTitle=${
          valueAndKey?.key || "user"
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(AppReqDetail.AppRequest_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
        // }
      })
      .catch(function (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  };

export const Total_AppReqCountMiddleware =
  (onSuccess, onFailure) => (dispatch) => {
    dispatch(Total_AppReqDetail.AppRequest());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${APP_REQUEST_TOTAL_COUNT_PATH}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(Total_AppReqDetail.AppRequest_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
      })
      .catch(function (error) {
        if (error.response) {
        }
      });
  };

export const Total_BankAppCountMiddleware =
  (onSuccess, onFailure) => (dispatch) => {
    dispatch(Total_BankAppDetail.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${BANK_APP_TOTAL_COUNT_PATH}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(Total_BankAppDetail.Fetch_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
      })
      .catch(function (error) {
        if (error.response) {
        }
      });
  };

export const Total_RealStateAppCountMiddleware =
  (onSuccess, onFailure) => (dispatch) => {
    dispatch(Total_RealStateReqDetail.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${REAL_STATE_TOTAL_COUNT_PATH}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(Total_RealStateReqDetail.Fetch_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
      })
      .catch(function (error) {
        if (error.response) {
        }
      });
  };

export const Total_CustomerAppCountMiddleware =
  (onSuccess, onFailure) => (dispatch) => {
    dispatch(Total_CustomerReqDetail.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${CUSTOMER_REQ_TOTAL_COUNT_PATH}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(Total_CustomerReqDetail.Fetch_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
      })
      .catch(function (error) {
        if (error.response) {
        }
      });
  };
