import React, { useEffect, useRef, useState } from "react";
// import Loader from "react-loader-spinner";
// import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table, Label } from "reactstrap";
import swal from "sweetalert";
// import ComponentToPrint from "./ComponentToPrint";
import PriceUpdateModal from "./PriceUpdateModal";
import jsPDF from "jspdf";
import "jspdf-autotable";

const TablePricingListView = ({ formPrice, _UpdateFormPricing_Middleware }) => {
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [oldPrice, setOldPrice] = useState("");

  const ToggleViewReqListModal = (price) => {
    setIsViewModalOpen(!isViewModalOpen);
    setOldPrice(price);
  };
  const onSuccessCancelation = () => {
    swal("Form Cancel successfully!", "", "success");
  };
  const onFailureCancelation = () => {};
  // const cancelForm = (formNo) => {
  //   let body = {
  //     FormNo: formNo,
  //   };
  //   _FormCancelation(body, onSuccessCancelation, onFailureCancelation);
  // };
  return (
    <div>
      <PriceUpdateModal
        toggle={ToggleViewReqListModal}
        modal={isViewModalOpen}
        oldPrice={oldPrice}
        _UpdateFormPricing_Middleware={_UpdateFormPricing_Middleware}
      />
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Item Name</th>
            <th scope="col">Price</th>
            <th scope="col">Description</th>
            <th scope="col">Date</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {formPrice !== null && formPrice !== undefined ? (
            <tr>
              <td>{formPrice.id}</td>
              <td>{formPrice.item}</td>
              <td>{formPrice.price}</td>
              <td>{formPrice.description}</td>
              <td>{formPrice.createdAt?.split("T")[0]}</td>
              <td>
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  onClick={() => ToggleViewReqListModal(formPrice.price)}
                  size={35}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TablePricingListView;
