import {
  STAFF_LOGIN,
  STAFF_LOGIN_SUCCESS,
  STAFF_LOGIN_FAILURE,
} from "../constant.js";
const INITIAL_STATE = {
  Data: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case STAFF_LOGIN:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Data: null,
        error: {},
      };
    case STAFF_LOGIN_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Data: action.payload,
      };
    case STAFF_LOGIN_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
