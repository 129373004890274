import { 
  CUSTOMER,CUSTOMER_SUCCESS,CUSTOMER_FAILURE,
  CUSTOMEREDIT,CUSTOMEREDIT_FAILURE,CUSTOMEREDIT_SUCCESS
} from "../constant.js";
export class CustomerEditDetail {
  static CustomerEdit() {
    return {
      type: CUSTOMEREDIT
  }
}
  static CustomerEdit_Success(response) {
    return {
      type: CUSTOMEREDIT_SUCCESS,
      payload: response,
    };
  }
  static CustomerEdit_Failure(error) {
    return {
      type: CUSTOMEREDIT_FAILURE,
      error,
    };
  }
 
}

export class CustomerDetail {
  static Customer() {
    return {
      type: CUSTOMER
  }
}
  static Customer_Success(response) {
    return {
      type: CUSTOMER_SUCCESS,
      payload: response,
    };
  }
  static Customer_Failure(error) {
    return {
      type: CUSTOMER_FAILURE,
      error,
    };
  }
 
}

