import axios from "axios";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CardBody,
  Card,
  Table,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import { useState, useEffect } from "react";
// import { BASEURL, RECORDING_BASE_URL } from "config/api/URL";
// import { RECORDING_PATH } from "../constant";
import { RECORDING_BASE_URL } from "config/api/URL";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
const Information = ({ viewReqList, modal, toggle }) => {
  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader style={{ backgroundColor: "#d69929" }} toggle={toggle}>
        <h3 style={{ color: "white" }}>Details Of Applicant</h3>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Amount:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{viewReqList.Amount}</label>
          </Col>
          <Col lg="2" md="2" sm="6">
            <label>Status:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{viewReqList.Status}</label>
          </Col>
        </Row>

        <Row>
          <Col lg="2" md="2" sm="6">
            <label>Order Type:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{viewReqList.orderType}</label>
          </Col>
          <Col lg="2" md="2" sm="6">
            <label>Order Data:</label>
          </Col>
          <Col lg="4" md="4" sm="6">
            <label>{viewReqList.createdAt?.split("T")[0]}</label>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ backgroundColor: "#d69929" }}>
        <Button
          style={{ backgroundColor: "#015652", color: "white" }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Information;
