import React, { useEffect } from "react";
import swal from "sweetalert";
import { useState } from "react";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router";


const TableOfAgentStaffList = (props) => {



  let history = useHistory();


  




  const dateFunction = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Karachi",
    });
    return nDate;
  };
  

 

const onEdit=(e)=>{
 
    
    history.push({
      pathname:'/user/Staff',
      state:e
    })

}
 const onDelete=(e)=>{
  swal({
    title: "Warning",
    text: "Are You Sure You Want To Delete This Staff?",
    icon: "warning",
     buttons : true,
    
  }).then((isOk) => {
    if (isOk) {
      let id=e;
      props.DeleteAgentMiddleware(id,onSuccess, onFailure)
    }
  });

 }

const onSuccess=()=>{
  swal({
    title: "Successfully!",
    text: "Staff Deleted successfully",
    icon: "success",
    // buttons : true,
  }).then((isOk) => {
    if (isOk) {
      
    }
  });
}
const onFailure=()=>{}
  return (
    <>
   

      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929", color:"white" }} >
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Name</th>
            <th scope="col">CNIC</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
           
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.paginatedPosts !== null &&
          props.paginatedPosts !== undefined ? (
            props.paginatedPosts.map((posts, index) => {
              return (
                <tr>
                  <td>{index+1}</td>
                  <td>{posts?.fullName}</td>
                  <td>{posts?.cnic}</td>
                  <td>{posts?.phone}</td>
                  <td>{posts?.email}</td>
              
                 <td>
                 <Button
                      style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                    onClick={() => onEdit(posts)}
                      id="info"
                   
                    >
                     <i class="fas fa-edit" aria-hidden="true"></i>
                      {/* <span className="btn-inner--icon">
                       Edit
                      </span> */}
                    </Button>
                    <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={()=> onDelete(posts?.realEstateStaffProfile?.id)}
                      id="info"
                    > <i class="fas fa-trash-alt" ></i>
                    
                    </Button>
                 </td>
                </tr>
              );
            }
            )
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th><h3>No data found</h3></th>
              <th></th>
            </tr>
          )}
          
        </tbody>
      </Table>
    </>
  );
};
export default TableOfAgentStaffList;
