import { SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE } from "../constant.js";
export class UserSignUp {
  static SignUp() {
    return {
      type: SIGNUP,
    };
  }
  static SignUpSuccess(response) {
    return {
      type: SIGNUP_SUCCESS,
      payload: response,
    };
  }
  static SignUpFailure(error) {
    return {
      type: SIGNUP_FAILURE,
      error,
    };
  }
}
