import React from "react";
import "./KGC_BankVoucher.css";
import logo from "../../../../assets/img/black variation.png";
import footer from "../footer.jpg";

class KGC_BankVoucher extends React.Component {
  render() {
    return (
      <div>
        {this.props.formlist !== null && this.props.formlist !== undefined
          ? this.props.formlist?.items?.map((item, index) => {
              return (
                <page size="A4" key={index}>
                  <div
                    style={{
                      // marginTop: "0%",
                      display: "flex",
                      width: "1100px",
                      height: "792px",
                      border: "1px solid black",
                      // margin: "10px 10px",
                      // marginRight: "100px",
                      // marginTop: "10px",
                      // padding: " 20px 60px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      // lineHeight: "24px",
                      fontFamily:
                        "Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif",
                      color: "rgb(33, 33, 33)",
                    }}
                  >
                    <div style={{ width: "500px", border: "1px solid black" }}>
                      <div class="regForm-header">
                        <div class=" img_text1">
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/imageKPK%20logo-01-1666954209159.png"
                            style={{
                              width: "100%",
                              height: "100%",
                              marginLeft: "-10px",
                              marginTop: "10px",
                            }}
                          />
                          <div style={{ marginTop: "0px" }}>
                            <span style={{ fontSize: "12px" }}> Date:</span>
                            <span>
                              <input
                                style={{
                                  borderTop: "none",
                                  borderRight: "none",
                                  borderLeft: "none",
                                  borderBottom: "1px soild black",
                                  outline: "none",
                                  background: "transparent",
                                  width: "70%",
                                  height: "12px",
                                }}
                                type="text"
                                value=""
                              />
                            </span>
                          </div>
                        </div>
                        <div class=" img_text">
                          <div
                            style={{
                              width: "100px",
                              height: "107px",
                              // paddingTop: "20px",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "22px",
                            }}
                          >
                            <img
                              src={this.props.bankById?.logo}
                              style={{ width: "100%" }}
                              alt=""
                            />
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              width: "100%",
                              marginTop: "0px",
                            }}
                          >
                            <div>
                              <h5 style={{ color: "black" }}>CUSTOMER COPY</h5>
                            </div>
                            <div>
                              <div style={{ border: "1px solid black" }}>
                                <small> Deposit Slip No.</small>
                              </div>
                              <div
                                style={{
                                  border: "1px solid black",
                                  borderTop: "0px",
                                }}
                              >
                                {item.depositNo}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="regForm-body" style={{ marginTop: "-80px" }}>
                        <div class="section_one">
                          <div class="sub_header">
                            <small>
                              Applicant Personal Information for Registration.
                            </small>
                          </div>

                          <h4 className="headind4"></h4>
                          <div style={{ marginTop: "-20px" }}>
                            <div class="flex_margin">
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex1">Name:</div>
                                  <div class="flex8">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>

                            <div class="dflex" style={{ marginBottom: "16px" }}>
                              <small class="flex1">
                                <span>
                                  <div>
                                    CNIC No.:
                                    <span
                                      class="cnic_col1"
                                      style={{ marginLeft: "3px" }}
                                    ></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                    <span class="cnic_col1"></span>
                                  </div>
                                </span>
                              </small>
                            </div>
                            <div class="dflex" style={{ marginBottom: "12px" }}>
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex3">Cell/WhatsApp No:</div>
                                  <div class="flex7">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>
                            <div class="dflex mb10">
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex3"> Email (If available)</div>
                                  <div class="flex7">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      // class="inputBorder"
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>

                            <div class="flex_margin">
                              <small class="flex2">
                                <span class="dflex">
                                  <div class="flex7">Amount PKR:</div>
                                  <div class="flex4">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                        textAlign: "center",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                      }}
                                      // class="inputBorder"
                                      type="text"
                                      value="2800/-"
                                    />
                                  </div>
                                </span>
                              </small>
                              <small class="flex4">
                                <span class="dflex">
                                  <div
                                    class="flex4"
                                    style={{
                                      wordSpacing: "-1px",
                                    }}
                                  >
                                    Amount in Words:
                                  </div>
                                  <div class="flex6">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                        textAlign: "center",
                                        fontSize: "9px",
                                        wordSpacing: "-1px",
                                        fontWeight: "bold",
                                      }}
                                      type="text"
                                      value="Two Thousand Eight Hundred"
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            marginTop: "-15px",
                          }}
                        >
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 5 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 7 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 10 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 14 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 1 Kanal</div>
                            </span>
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "15px",
                            marginTop: "10px",
                            border: "1px solid black",
                            width: "95%",
                            height: "35px",
                            overflow: "hidden",
                            alignItems: "center",
                          }}
                        >
                          <small class="flex1">
                            <span class="dflex">
                              <span
                                style={{
                                  color: "red",
                                  backgroundColor: "lightgray",
                                  height: "40px",
                                  padding: "15px 4px",
                                  overflow: "hidden",
                                }}
                              >
                                BANK USE ONLY
                              </span>
                              <div style={{ padding: "15px 4px" }}>
                                {" "}
                                KGC Ref No.
                              </div>
                            </span>
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "8px",
                            width: "95%",
                          }}
                        >
                          <small class="flex1">
                            <div>
                              <strong>
                                ACCOUNT TITLE: {this.props.bankById?.contantTwo}
                              </strong>
                            </div>
                            <div
                              style={{
                                // fontSize: "12px",
                                // color: "gray",
                                height: "30px",
                                overflow: "hidden",
                              }}
                            >
                              <strong>
                                A/C # {this.props.bankById?.bankAccountNo}
                              </strong>
                            </div>
                          </small>
                        </div>
                        <div
                          class="flex_margin text_al_center"
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                            marginTop: "-11px",
                            width: "95%",
                            padding: "4px 4px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div class=" text_al_center">
                            <div
                              style={{
                                height: "60px",
                                width: "160%",
                                border: "1px solid black",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "160%",
                              }}
                            >
                              <small>DEPOSITOR SIGN</small>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                height: "60px",
                                width: "145%",
                                borderBottom: "1px solid black",
                                marginLeft: "-40px",
                              }}
                            ></div>
                            <div
                              style={{
                                // width: "140%",
                                marginRight: "15px",
                              }}
                            >
                              <small>BANK STAMP & SIGN</small>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "8px",
                            width: "95%",
                            border: "1px solid black",
                            color: "red",
                            padding: "4px 4px",
                          }}
                        >
                          <small class="flex1">
                            <div>
                              {/* <strong> */}* THIS RECEIPT HAS TO BE PRESENTED
                              AS PROOF OF PAYMENT
                              {/* </strong> */}
                            </div>
                            <div>
                              {/* <strong> */}* FOR MORE DETAILS VISIT OUR
                              WEBSITE OR CONTACT OUR HELPLINE
                              {/* </strong> */}
                            </div>
                          </small>
                        </div>
                        <div
                          class="dflex"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            // marginTop: "-5px",
                            marginBottom: "-5px",
                            width: "95%",
                            justifyContent: "space-around",
                            color: "blue",
                          }}
                        >
                          <div>
                            <span>
                              <i class="fa fa-globe" aria-hidden="true"></i>
                            </span>
                            <span>
                              <small> www.khybergolfcity.com.pk</small>
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            <span>
                              <i class="fa fa-phone" aria-hidden="true"></i>
                            </span>
                            <span>
                              <small style={{ display: "flex" }}>
                                {" "}
                                +92 304-111-
                                <span style={{ marginTop: "-8px" }}>
                                  <div
                                    style={{
                                      marginBottom: "-8px",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    5 4 2 7
                                  </div>
                                  <div>(KGCP)</div>
                                </span>
                              </small>
                            </span>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "2px",
                            width: "95%",
                            padding: "4px 4px",
                          }}
                        >
                          <small
                            style={{
                              lineHeight: "12px",
                              height: "96px",
                              overflow: "hidden",
                            }}
                          >
                            {this.props.bankById?.footer}
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "16px",
                            marginTop: "-10px",
                            width: "95%",
                            height: "54px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {/* <img
                            src={footer}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          /> */}
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/imagefooter-1666958044644.jpg"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>{" "}
                    <div style={{ width: "500px", border: "1px solid black" }}>
                      <div class="regForm-header">
                        <div class=" img_text1">
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/imageKPK%20logo-01-1666954209159.png"
                            style={{
                              width: "100%",
                              height: "100%",
                              marginLeft: "-10px",
                              marginTop: "10px",
                            }}
                          />
                          <div style={{ marginTop: "0px" }}>
                            <span style={{ fontSize: "12px" }}> Date:</span>
                            <span>
                              <input
                                style={{
                                  borderTop: "none",
                                  borderRight: "none",
                                  borderLeft: "none",
                                  borderBottom: "1px soild black",
                                  outline: "none",
                                  background: "transparent",
                                  width: "70%",
                                  height: "12px",
                                }}
                                type="text"
                                value=""
                              />
                            </span>
                          </div>
                        </div>
                        <div class=" img_text">
                          <div
                            style={{
                              width: "100px",
                              height: "107px",
                              // paddingTop: "20px",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "22px",
                            }}
                          >
                            <img
                              src={this.props.bankById?.logo}
                              style={{ width: "100%" }}
                              alt=""
                            />
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              width: "100%",
                              marginTop: "0px",
                            }}
                          >
                            <div>
                              <h5 style={{ color: "black" }}>KGC COPY</h5>
                            </div>
                            <div>
                              <div style={{ border: "1px solid black" }}>
                                <small> Deposit Slip No.</small>
                              </div>
                              <div
                                style={{
                                  border: "1px solid black",
                                  borderTop: "0px",
                                }}
                              >
                                {item.depositNo}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="regForm-body" style={{ marginTop: "-80px" }}>
                        <div class="section_one">
                          <div class="sub_header">
                            <small>
                              Applicant Personal Information for Registration.
                            </small>
                          </div>

                          <h4 className="headind4"></h4>
                          <div style={{ marginTop: "-20px" }}>
                            <div class="flex_margin">
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex1">Name:</div>
                                  <div class="flex8">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>

                            <div class="dflex" style={{ marginBottom: "16px" }}>
                              <small class="flex1">
                                <span>
                                  <div>
                                    CNIC No.:
                                    <span
                                      class="cnic_col1"
                                      style={{ marginLeft: "3px" }}
                                    ></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                    <span class="cnic_col1"></span>
                                  </div>
                                </span>
                              </small>
                            </div>
                            <div class="dflex" style={{ marginBottom: "12px" }}>
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex3">Cell/WhatsApp No:</div>
                                  <div class="flex7">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>
                            <div class="dflex mb10">
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex3"> Email (If available)</div>
                                  <div class="flex7">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      // class="inputBorder"
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>

                            <div class="flex_margin">
                              <small class="flex2">
                                <span class="dflex">
                                  <div class="flex7">Amount PKR:</div>
                                  <div class="flex4">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                        textAlign: "center",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                      }}
                                      // class="inputBorder"
                                      type="text"
                                      value="2800/-"
                                    />
                                  </div>
                                </span>
                              </small>
                              <small class="flex4">
                                <span class="dflex">
                                  <div
                                    class="flex4"
                                    style={{
                                      wordSpacing: "-1px",
                                    }}
                                  >
                                    Amount in Words:
                                  </div>
                                  <div class="flex6">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                        textAlign: "center",
                                        fontSize: "9px",
                                        wordSpacing: "-1px",
                                        fontWeight: "bold",
                                      }}
                                      type="text"
                                      value="Two Thousand Eight Hundred"
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            marginTop: "-15px",
                          }}
                        >
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 5 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 7 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 10 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 14 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 1 Kanal</div>
                            </span>
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "15px",
                            marginTop: "10px",
                            border: "1px solid black",
                            width: "95%",
                            height: "35px",
                            overflow: "hidden",
                            alignItems: "center",
                          }}
                        >
                          <small class="flex1">
                            <span class="dflex">
                              <span
                                style={{
                                  color: "red",
                                  backgroundColor: "lightgray",
                                  height: "40px",
                                  padding: "15px 4px",
                                  overflow: "hidden",
                                }}
                              >
                                BANK USE ONLY
                              </span>
                              <div style={{ padding: "15px 4px" }}>
                                {" "}
                                KGC Ref No.
                              </div>
                            </span>
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "8px",
                            width: "95%",
                          }}
                        >
                          <small class="flex1">
                            <div>
                              <strong>
                                ACCOUNT TITLE: {this.props.bankById?.contantTwo}
                              </strong>
                            </div>
                            <div
                              style={{
                                // fontSize: "12px",
                                // color: "gray",
                                height: "30px",
                                overflow: "hidden",
                              }}
                            >
                              <strong>
                                A/C # {this.props.bankById?.bankAccountNo}
                              </strong>
                            </div>
                          </small>
                        </div>
                        <div
                          class="flex_margin text_al_center"
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                            marginTop: "-11px",
                            width: "95%",
                            padding: "4px 4px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div class=" text_al_center">
                            <div
                              style={{
                                height: "60px",
                                width: "160%",
                                border: "1px solid black",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "160%",
                              }}
                            >
                              <small>DEPOSITOR SIGN</small>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                height: "60px",
                                width: "145%",
                                borderBottom: "1px solid black",
                                marginLeft: "-40px",
                              }}
                            ></div>
                            <div
                              style={{
                                // width: "140%",
                                marginRight: "15px",
                              }}
                            >
                              <small>BANK STAMP & SIGN</small>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "8px",
                            width: "95%",
                            border: "1px solid black",
                            color: "red",
                            padding: "4px 4px",
                          }}
                        >
                          <small class="flex1">
                            <div>
                              {/* <strong> */}* THIS RECEIPT HAS TO BE PRESENTED
                              AS PROOF OF PAYMENT
                              {/* </strong> */}
                            </div>
                            <div>
                              {/* <strong> */}* FOR MORE DETAILS VISIT OUR
                              WEBSITE OR CONTACT OUR HELPLINE
                              {/* </strong> */}
                            </div>
                          </small>
                        </div>
                        <div
                          class="dflex"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            // marginTop: "-5px",
                            marginBottom: "-5px",
                            width: "95%",
                            justifyContent: "space-around",
                            color: "blue",
                          }}
                        >
                          <div>
                            <span>
                              <i class="fa fa-globe" aria-hidden="true"></i>
                            </span>
                            <span>
                              <small> www.khybergolfcity.com.pk</small>
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            <span>
                              <i class="fa fa-phone" aria-hidden="true"></i>
                            </span>
                            <span>
                              <small style={{ display: "flex" }}>
                                {" "}
                                +92 304-111-
                                <span style={{ marginTop: "-8px" }}>
                                  <div
                                    style={{
                                      marginBottom: "-8px",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    5 4 2 7
                                  </div>
                                  <div>(KGCP)</div>
                                </span>
                              </small>
                            </span>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "2px",
                            width: "95%",
                            padding: "4px 4px",
                          }}
                        >
                          <small
                            style={{
                              lineHeight: "12px",
                              height: "96px",
                              overflow: "hidden",
                            }}
                          >
                            {this.props.bankById?.footer}
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "16px",
                            marginTop: "-10px",
                            width: "95%",
                            height: "55px",
                            backgroundColor: "lightgray",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div style={{ width: "500px", border: "1px solid black" }}>
                      <div class="regForm-header">
                        <div class=" img_text1">
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/imageKPK%20logo-01-1666954209159.png"
                            style={{
                              width: "100%",
                              height: "100%",
                              marginLeft: "-10px",
                              marginTop: "10px",
                            }}
                          />
                          <div style={{ marginTop: "0px" }}>
                            <span style={{ fontSize: "12px" }}> Date:</span>
                            <span>
                              <input
                                style={{
                                  borderTop: "none",
                                  borderRight: "none",
                                  borderLeft: "none",
                                  borderBottom: "1px soild black",
                                  outline: "none",
                                  background: "transparent",
                                  width: "70%",
                                  height: "12px",
                                }}
                                type="text"
                                value=""
                              />
                            </span>
                          </div>
                        </div>
                        <div class=" img_text">
                          <div
                            style={{
                              width: "100px",
                              height: "107px",
                              // paddingTop: "20px",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "22px",
                            }}
                          >
                            <img
                              src={this.props.bankById?.logo}
                              style={{ width: "100%" }}
                              alt=""
                            />
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              width: "100%",
                              marginTop: "0px",
                            }}
                          >
                            <div>
                              <h5 style={{ color: "black" }}>BANK COPY</h5>
                            </div>
                            <div>
                              <div style={{ border: "1px solid black" }}>
                                <small> Deposit Slip No.</small>
                              </div>
                              <div
                                style={{
                                  border: "1px solid black",
                                  borderTop: "0px",
                                }}
                              >
                                {item.depositNo}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="regForm-body" style={{ marginTop: "-80px" }}>
                        <div class="section_one">
                          <div class="sub_header">
                            <small>
                              Applicant Personal Information for Registration.
                            </small>
                          </div>

                          <h4 className="headind4"></h4>
                          <div style={{ marginTop: "-20px" }}>
                            <div class="flex_margin">
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex1">Name:</div>
                                  <div class="flex8">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>

                            <div class="dflex" style={{ marginBottom: "16px" }}>
                              <small class="flex1">
                                <span>
                                  <div>
                                    CNIC No.:
                                    <span
                                      class="cnic_col1"
                                      style={{ marginLeft: "3px" }}
                                    ></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1"></span>
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                    <span class="cnic_col1"></span>
                                  </div>
                                </span>
                              </small>
                            </div>
                            <div class="dflex" style={{ marginBottom: "12px" }}>
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex3">Cell/WhatsApp No:</div>
                                  <div class="flex7">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>
                            <div class="dflex mb10">
                              <small class="flex1">
                                <span class="dflex">
                                  <div class="flex3"> Email (If available)</div>
                                  <div class="flex7">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                      }}
                                      // class="inputBorder"
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>

                            <div class="flex_margin">
                              <small class="flex2">
                                <span class="dflex">
                                  <div class="flex7">Amount PKR:</div>
                                  <div class="flex4">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                        textAlign: "center",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                      }}
                                      // class="inputBorder"
                                      type="text"
                                      value="2800/-"
                                    />
                                  </div>
                                </span>
                              </small>
                              <small class="flex4">
                                <span class="dflex">
                                  <div
                                    class="flex4"
                                    style={{
                                      wordSpacing: "-1px",
                                    }}
                                  >
                                    Amount in Words:
                                  </div>
                                  <div class="flex6">
                                    <input
                                      style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px soild black",
                                        outline: "none",
                                        background: "transparent",
                                        width: "100%",
                                        height: "12px",
                                        textAlign: "center",
                                        fontSize: "9px",
                                        wordSpacing: "-1px",
                                        fontWeight: "bold",
                                      }}
                                      type="text"
                                      value="Two Thousand Eight Hundred"
                                    />
                                  </div>
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            marginTop: "-15px",
                          }}
                        >
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 5 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 7 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 10 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 14 Marla</div>
                            </span>
                          </small>
                          <small class="flex1">
                            <span class="dflex">
                              <span class="cnic_col"></span>
                              <div style={{ marginLeft: "7px" }}> 1 Kanal</div>
                            </span>
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "15px",
                            marginTop: "10px",
                            border: "1px solid black",
                            width: "95%",
                            height: "35px",
                            overflow: "hidden",
                            alignItems: "center",
                          }}
                        >
                          <small class="flex1">
                            <span class="dflex">
                              <span
                                style={{
                                  color: "red",
                                  backgroundColor: "lightgray",
                                  height: "40px",
                                  padding: "15px 4px",
                                  overflow: "hidden",
                                }}
                              >
                                BANK USE ONLY
                              </span>
                              <div style={{ padding: "15px 4px" }}>
                                {" "}
                                KGC Ref No.
                              </div>
                            </span>
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "8px",
                            width: "95%",
                          }}
                        >
                          <small class="flex1">
                            <div>
                              <strong>
                                ACCOUNT TITLE: {this.props.bankById?.contantTwo}
                              </strong>
                            </div>
                            <div
                              style={{
                                // fontSize: "12px",
                                // color: "gray",
                                height: "30px",
                                overflow: "hidden",
                              }}
                            >
                              <strong>
                                A/C # {this.props.bankById?.bankAccountNo}
                              </strong>
                            </div>
                          </small>
                        </div>
                        <div
                          class="flex_margin text_al_center"
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                            marginTop: "-11px",
                            width: "95%",
                            padding: "4px 4px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div class=" text_al_center">
                            <div
                              style={{
                                height: "60px",
                                width: "160%",
                                border: "1px solid black",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "160%",
                              }}
                            >
                              <small>DEPOSITOR SIGN</small>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                height: "60px",
                                width: "145%",
                                borderBottom: "1px solid black",
                                marginLeft: "-40px",
                              }}
                            ></div>
                            <div
                              style={{
                                // width: "140%",
                                marginRight: "15px",
                              }}
                            >
                              <small>BANK STAMP & SIGN</small>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "8px",
                            width: "95%",
                            border: "1px solid black",
                            color: "red",
                            padding: "4px 4px",
                          }}
                        >
                          <small class="flex1">
                            <div>
                              {/* <strong> */}* THIS RECEIPT HAS TO BE PRESENTED
                              AS PROOF OF PAYMENT
                              {/* </strong> */}
                            </div>
                            <div>
                              {/* <strong> */}* FOR MORE DETAILS VISIT OUR
                              WEBSITE OR CONTACT OUR HELPLINE
                              {/* </strong> */}
                            </div>
                          </small>
                        </div>
                        <div
                          class="dflex"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            // marginTop: "-5px",
                            marginBottom: "-5px",
                            width: "95%",
                            justifyContent: "space-around",
                            color: "blue",
                          }}
                        >
                          <div>
                            <span>
                              <i class="fa fa-globe" aria-hidden="true"></i>
                            </span>
                            <span>
                              <small> www.khybergolfcity.com.pk</small>
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            <span>
                              <i class="fa fa-phone" aria-hidden="true"></i>
                            </span>
                            <span>
                              <small style={{ display: "flex" }}>
                                {" "}
                                +92 304-111-
                                <span style={{ marginTop: "-8px" }}>
                                  <div
                                    style={{
                                      marginBottom: "-8px",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    5 4 2 7
                                  </div>
                                  <div>(KGCP)</div>
                                </span>
                              </small>
                            </span>
                          </div>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            marginTop: "2px",
                            width: "95%",
                            padding: "4px 4px",
                          }}
                        >
                          <small
                            style={{
                              lineHeight: "12px",
                              height: "96px",
                              overflow: "hidden",
                            }}
                          >
                            {this.props.bankById?.footer}
                          </small>
                        </div>
                        <div
                          class="flex_margin"
                          style={{
                            marginLeft: "10px",
                            fontSize: "16px",
                            marginTop: "-10px",
                            width: "95%",
                            height: "55px",
                            backgroundColor: "lightgray",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </page>
              );
            })
          : ""}
      </div>
    );
  }
}

export default KGC_BankVoucher;
