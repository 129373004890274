

import { 
  APPLICATIONLIST,APPLICATIONLIST_SUCCESS,APPLICATIONLIST_FAILURE,
  CUSTOMERSHOW,CUSTOMERSHOW_FAILURE,CUSTOMERSHOW_SUCCESS
 
} from "../constant.js";

export class CustomerShowDetail {
  static CustomerShow() {
    return {
      type: CUSTOMERSHOW,
    };
  }
  static CustomerShow_Success(response) {
    return {
      type: CUSTOMERSHOW_SUCCESS,
      payload: response,
    };
  }
  static CustomerShow_Failure(error) {
    return {
      type: CUSTOMERSHOW_FAILURE,
      error,
    };
  }
 
}



export class ApplicationListDetail {
  static Applicationlist() {
    return {
      type: APPLICATIONLIST,
    };
  }
  static Applicationlist_Success(response) {
    return {
      type: APPLICATIONLIST_SUCCESS,
      payload: response,
    };
  }
  static Applicationlist_Failure(error) {
    return {
      type: APPLICATIONLIST_FAILURE,
      error,
    };
  }
 
}

