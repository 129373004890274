import {
  FETCH_REALSTATE_DEALER,
  FETCH_REALSTATE_DEALER_SUCCESS,
  FETCH_REALSTATE_DEALER_FAILURE,
} from "../constant.js";

export class FetchDealers {
  static Fetch() {
    return {
      type: FETCH_REALSTATE_DEALER,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_REALSTATE_DEALER_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_REALSTATE_DEALER_FAILURE,
      error,
    };
  }
}
