export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCESS";
export const LOGIN_FAILURE = "LOGIN_FALURE";
//export const LOGIN_PATH = "auth/estate-agent/login";
export const LOGIN_PATH = "auth/user/login";

export const VERIFY_BY_EMAIL = "VERIFY_BY_EMAIL";
export const VERIFY_BY_EMAIL_SUCCESS = "VERIFY_BY_EMAIL_SUCCESS";
export const VERIFY_BY_EMAIL_FAILURE = "VERIFY_BY_EMAIL_FAILURE";
export const VERIFY_BY_EMAIL_PATH = "auth/account-verify";
