export const CREATE_STAFF_CUSTOMER = "CREATE_STAFF_CUSTOMER"; //auth/123123123123123
export const CREATE_STAFF_CUSTOMER_SUCCESS = "CREATE_STAFF_CUSTOMER_SUCCESS";
export const CREATE_STAFF_CUSTOMER_FAILURE = "CREATE_STAFF_CUSTOMER_FAILURE";
export const CREATE_STAFF_CUSTOMER_PATH = "customs/user/signup/for-staff";

export const VERIFY_USER_BY_CNIC = "VERIFY_USER_BY_CNIC";
export const VERIFY_USER_BY_CNIC_SUCCESS = "VERIFY_USER_BY_CNIC_SUCCESS";
export const VERIFY_USER_BY_CNIC_FAILURE = "VERIFY_USER_BY_CNIC_FAILURE";
export const VERIFY_USER_BY_CNIC_PATH =
  "auth";
