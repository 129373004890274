import React, { useState, useEffect } from "react";
import { Card, CardBody, Button, CardHeader, Row, Col, Input, CardFooter } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import newwlogoo from "assets/img/black variation.png";
import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";



const Filteration = (props) => {
  const history = useHistory();
  const location= useLocation();
  useEffect(() => {
    if(location.state!==null && location.state!==undefined)
    {
      setState({...state,
        fullName:location.state.user.fullName,
        cnic:location.state.user.cnic,
        phone:location.state.user.phone,
        email:location.state.user.email,
        noOfApplication:location.state.user.applicationRequest[0]?.NumberOfApplication,
       })
    }
    console.log("location.state",location.state)
   
  }, [location.state])
  

  const onSuccess = () => {
    // setState({...state,  bankName:"",
    // bankDescription:""})
  
    swal({
      title: "Success!",
      text: "Customer Added Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/CustomerList";
        history.push(path);
      }
    });
  };
  const onFailure = (error) => {
    
    swal("Sorry!", error.message, "error");

  };

 



  // let dataInArrayForPaginaion = [];
  // useEffect(() => {
  //   setPosts(null);
  //   setPageNumber(1);
  //   if (props.Client !== null && props.Client !== undefined) {
  //     for (let i = 0; i < props.Client.length; i++) {
  //       dataInArrayForPaginaion.push(props.Client[i]);
  //       setPosts(props.Client);
  //     }
  //   } else {
  //   }
  // }, [props.Client]);

  const [state, setState] = useState({
 fullName:"",
 cnic:"",
 email:"",
 phone:"",
 noOfApplication:"",
 profilePhoto:""
  });
 
  const onChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  

  //Start Validation
  const CheckFields = (name) => {
    if (name === "fullName") {
      setError({
        ...error,
        fullNameError: validate("required", state.fullName),
      });
    }
    else if(name==="cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", state.cnic),
      });
    }
    else if(name==="phone") {
      setError({
        ...error,
        phoneError: validate("required", state.phone),
      });
    }
    else if(name==="email") {
      setError({
        ...error,
        emailError: validate("email", state.email),
      });
    }
    else if(name==="noOfApplication") {
      setError({
        ...error,
        noOfApplicationError: validate("required", state.noOfApplication),
      });
    }
  };

  const [error, setError] = useState({
    phoneError: "",
    emailError:"",
    cnicError:"",
    fullNameError:"",
    noOfApplicationError:"",


  });
  

 

 

 const submit=()=>{
  setError({
    ...error,
    fullNameError: validate("required", state.fullName),
    cnicError: validate("CNIC", state.cnic),
    emailError: validate("email", state.email),
    phoneError: validate("required", state.phone),
    noOfApplicationError:validate("required",state.noOfApplication)
  });
  if(location.state!==null && location.state!==undefined && 
    error.fullNameError==null && error.cnicError==null &&
     error.noOfApplicationError==null && error.emailError==null && error.phoneError==null)
  {
    let id=location.state.id;
    let body = {
      fullName: state.fullName,
        cnic: state.cnic,
        Email: state.email,
        mobile: state.phone,
      
        noOfApplication: state.noOfApplication.toString()
    };
    props.EditCustomerMiddleware(id,body,onSuccesEdit,onFailureEdit)
   // props.InsertBankMiddleware(body, onSuccess, onFailure);

  }
  else if(error.fullNameError==null && error.emailError==null &&
     error.cnicError==null && error.phoneError==null && error.noOfApplicationError==null)
  {
    let body = {
    fullName: state.fullName,
      cnic: state.cnic,
      email: state.email,
      phone: "+"+state.phone,
      profilePhoto: "abc.png",
      noOfApplication: state.noOfApplication
  };
  props.InsertCustomerMiddleware(body, onSuccess, onFailure);

  }
  

 }
 const onSuccesEdit=()=>{

 setState({...state,  bankName:"",
    bankDescription:""})
  
    swal({
      title: "Success!",
      text: "Customer Edited Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/user/CustomerList";
        history.push(path);
      }
    });
  };
  const onFailureEdit = (error) => {
    
    swal("Sorry!", error.message, "error");

  };
  const debit=()=>{
    history.push({pathname:'/user/PaymentInfo'})
  }



  return (

        <Row>
          <div className="col">
            <Card
              // style={{ marginLeft: "120px", marginRight: "120px" }}
              className="shadow m-auto w-50 "
            >
              <CardHeader
                className="border-0"
                style={{ backgroundColor: "#d69929" }}
              >
                <Row>
                  <Col lg="4" md="4" sm="4">
                    <img
                      // alt={logo.imgAlt}
                      // className="navbar-brand-img"
                      style={{ width: "80%" }}
                      src={newwlogoo}
                    />
                  </Col>
                  <Col lg="8" md="8" sm="8">
                    <h2 style={{ color: "#ffff" }}>Khyber Golf City{state.id}</h2>
                    {/* <br /> */}
                    <h3 style={{ color: "#ffff" }}>
                      Reference Number: 0010021001
                    </h3>
                    <h2 style={{ color: "#ffff" }}>PKR: {state.id}</h2>
                    <h4 style={{ color: "#ffff" }}>
                      + additional payment method charges may apply
                    </h4>
                  </Col>
                </Row>
              </CardHeader>
              {/* <Divider /> */}
              {/* <hr /> */}

              <CardBody>
                <form>
                  <div>
                    <Row>
                      {/* <Col lg="2" md="2" sm="6">
                        {" "}
                      </Col> */}
                      <Col lg="12" md="12" sm="12">
                      <Button
                          style={{  color: "black", width:"100%" }}
                          id="search"
                          size="lg"
                          onClick={debit}
                        >
                         <span><i className="fa-duotone fa-building-columns"></i></span>
                          <h3 style={{textAlign:"left", color: "black" }} >Credit Card / Debit Card</h3>
                          <h4 style={{ textAlign:"left",color: "black" }} >Visa Master Card more</h4>
                        </Button>
                      </Col>
                      <Col lg="12" md="12" sm="12">
                      <Button
                          style={{  color: "black", width:"100%" }}
                          id="search"
                          size="lg"
                          // onClick={paymentLater}
                        >
                         <span><i className="fa-duotone fa-building-columns"></i></span>
                          <h3 style={{ color: "black" ,textAlign:"left"}} >Net Banking</h3>
                          <h4 style={{ color: "black" ,textAlign:"left" }} >Pay through bank's mobile internet banking</h4>
                        </Button>
                      </Col>
                    </Row>
                    <br />

                    
                  

                    <br />
                    <br />
                  
                  </div>
                </form>
              </CardBody>
              <CardFooter
                className="border-0"
                style={{ backgroundColor: "#d69929" }}
              >
                </CardFooter>
            </Card>
          </div>
        </Row>
     
  );
};
export default Filteration;
