import {
  FORM_LIST,
  FORM_LIST_SUCCESS,
  FORM_LIST_FAILURE,
  FETCH_BANKS_BY_ID,
  FETCH_BANKS_BY_ID_SUCCESS,
  FETCH_BANKS_BY_ID_FAILURE,
  LAUNCHED_FROMS,
  LAUNCHED_FROMS_SUCCESS,
  LAUNCHED_FROMS_FAILURE,
} from "../constant";

const INITIAL_STATE = {
  formlist: null,
  bankById: null,
  launchedForms: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case LAUNCHED_FROMS:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        launchedForms: null,
        error: {},
      };
    case LAUNCHED_FROMS_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        launchedForms: action.payload,
      };
    case LAUNCHED_FROMS_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case FETCH_BANKS_BY_ID:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        bankById: null,
        error: {},
      };
    case FETCH_BANKS_BY_ID_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        bankById: action.payload,
      };
    case FETCH_BANKS_BY_ID_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case FORM_LIST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        formlist: null,
        error: {},
      };
    case FORM_LIST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        formlist: action.payload,
      };
    case FORM_LIST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
