import {
  FETCH_MANAGER,
  FETCH_MANAGER_SUCCESS,
  FETCH_MANAGER_FAILURE,
  FETCH_BRANCHS_BY_ID,
  FETCH_BRANCHS_BY_ID_SUCCESS,
  FETCH_BRANCHS_BY_ID_FAILURE,
  DELETE_MANAGER,
  DELETE_MANAGER_SUCCESS,
  DELETE_MANAGER_FAILURE,
  EDIT_MANAGER,
  EDIT_MANAGER_SUCCESS,
  EDIT_MANAGER_FAILURE,
  MANAGER_SIGNUP,
  MANAGER_SIGNUP_SUCCESS,
  MANAGER_SIGNUP_FAILURE,
} from "../constant.js";

export class ManagerSignUp {
  static SignUp() {
    return {
      type: MANAGER_SIGNUP,
    };
  }
  static SignUpSuccess(response) {
    return {
      type: MANAGER_SIGNUP_SUCCESS,
      payload: response,
    };
  }
  static SignUpFailure(error) {
    return {
      type: MANAGER_SIGNUP_FAILURE,
      error,
    };
  }
}
export class FetchAllManager {
  static Fetch() {
    return {
      type: FETCH_MANAGER,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_MANAGER_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_MANAGER_FAILURE,
      error,
    };
  }
}

export class FetchBranchs {
  static Fetch() {
    return {
      type: FETCH_BRANCHS_BY_ID,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_BRANCHS_BY_ID_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_BRANCHS_BY_ID_FAILURE,
      error,
    };
  }
}

export class DeleteManager {
  static Delete() {
    return {
      type: DELETE_MANAGER,
    };
  }
  static DeleteSuccess(response) {
    return {
      type: DELETE_MANAGER_SUCCESS,
      payload: response,
    };
  }
  static DeleteFailure(error) {
    return {
      type: DELETE_MANAGER_FAILURE,
      error,
    };
  }
}

export class EditManager {
  static Edit() {
    return {
      type: EDIT_MANAGER,
    };
  }
  static EditSuccess(response) {
    return {
      type: EDIT_MANAGER_SUCCESS,
      payload: response,
    };
  }
  static EditFailure(error) {
    return {
      type: EDIT_MANAGER_FAILURE,
      error,
    };
  }
}
