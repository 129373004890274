import { connect } from "react-redux";

import { InsertCustomerDetail,showPlotType,showPlotSize,showPlotProject } from "../middleware";

import Personal from "./Personal";

const mapStateToProps = (state) => ({
  Data: state.membershipForm.Data,
  Type:state.membershipForm.Type,
  Size:state.membershipForm.Size,
  Project:state.membershipForm.Project,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    InsertCustomerDetail: (body, OnSuccess, OnFailure) =>
      dispatch(InsertCustomerDetail(body, OnSuccess, OnFailure)),
      showPlotType: (OnSuccess, OnFailure) =>
      dispatch(showPlotType( OnSuccess, OnFailure)),
      showPlotSize: (OnSuccess, OnFailure) =>
      dispatch(showPlotSize(OnSuccess, OnFailure)),
      showPlotProject: (OnSuccess, OnFailure) =>
      dispatch(showPlotProject(OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Personal);
