import axios from "axios";
import { FetchFeedbackList, SendQuery } from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_FEEDBACK_LIST,
  FETCH_FEEDBACK_LIST_SUCCESS,
  FETCH_FEEDBACK_LIST_FAILURE,
  FETCH_FEEDBACK_LIST_PATH,
  SEND_QUERY,
  SEND_QUERY_SUCCESS,
  SEND_QUERY_FAILURE,
  SEND_QUERY_PATH,
} from "../constant";

export const AdminFeedbackList_Middleware =
  (onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchFeedbackList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${FETCH_FEEDBACK_LIST_PATH}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("ISSUE TYPE DATA", res.data.data);
          dispatch(FetchFeedbackList.FetchSuccess(res.data.data));
          // dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log("ERR", error.response);
          // onFailure(error.response.data);
        }
      });
  };

export const Sendquerydetail = (body, OnSuccess, OnFailure) => (dispatch) => {
  dispatch(SendQuery.Send());
  console.log("BODY", body);
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${SEND_QUERY_PATH}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("DATA", res.data);
      dispatch(SendQuery.SendSuccess(res.data));
      dispatch(OnSuccess(res.data.data.message));
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response);
        OnFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};
