import { connect } from "react-redux";
import ApplicationList from "./ApplicationList";
import { GetApplicationListMiddleware,CustomerInfoMiddleware} from "../middleware";


const mapStateToProps = (state) => ({

  List:state.customerApplicationList.List,
info:state.customerApplicationList.info,

 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    GetApplicationListMiddleware: ( OnSuccess, OnFailure) =>
    dispatch(GetApplicationListMiddleware(OnSuccess, OnFailure)),
    CustomerInfoMiddleware:(id, onSuccess,onFailure)=>
    dispatch(CustomerInfoMiddleware(id,onSuccess,onFailure)),
   
 
     


   
  
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(ApplicationList);
