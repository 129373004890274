import {
  IMPORT_FILE,
  IMPORT_FILE_SUCCESS,
  IMPORT_FILE_FAILURE,
} from "../constant";

const INITIAL_STATE = {
  importFileRes: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case IMPORT_FILE:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        importFileRes: null,
        error: {},
      };
    case IMPORT_FILE_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        importFileRes: action.payload,
      };
    case IMPORT_FILE_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
