import {
  APPLICATIONREQUEST,
  APPLICATIONREQUEST_FAILURE,
  APPLICATIONREQUEST_SUCCESS,
  FETCH_ALL_REQUEST,
  FETCH_ALL_REQUEST_SUCCESS,
  FETCH_ALL_REQUEST_FAILURE,
  // FETCH_COUNT,
  // FETCH_COUNT_SUCCESS,
  // FETCH_COUNT_FAILURE,
  FETCH_PRICE,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
  FETCH_USER,
  FETCH_USER_FAILURE,FETCH_USER_SUCCESS
} from "../constant.js";

const INITIAL_STATE = {
  Reports: null,
  isLoading: false,
  isError: false,
  error: {},
  isLoggedIn: false,
  Response: null,
  Count: null,
  User:null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;

    case APPLICATIONREQUEST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        ReqResponse: null,
        error: {},
      };
    case APPLICATIONREQUEST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        ReqResponse: action.payload,
      };
    case APPLICATIONREQUEST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
      case FETCH_USER:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          User: null,
          error: {},
        };
      case FETCH_USER_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          User: action.payload,
        };
      case FETCH_USER_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };
  
    case FETCH_ALL_REQUEST:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        AllRequests: null,
        error: {},
      };
    case FETCH_ALL_REQUEST_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        AllRequests: action.payload,
      };
    case FETCH_ALL_REQUEST_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };

    // case FETCH_COUNT:
    //   return {
    //     ...states,
    //     isLoading: true,
    //     isError: false,
    //     isLoggedIn: false,
    //     Count: null,
    //     error: {},
    //   };
    // case FETCH_COUNT_SUCCESS:
    //   return {
    //     ...states,
    //     isLoading: false,
    //     isLoggedIn: true,
    //     Count: action.payload,
    //   };
    // case FETCH_COUNT_FAILURE:
    //   return {
    //     ...states,
    //     isLoading: false,
    //     isError: true,
    //     error: action.error,
    //   };

    case FETCH_PRICE:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Price: null,
        error: {},
      };
    case FETCH_PRICE_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        Price: action.payload,
      };
    case FETCH_PRICE_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
