import axios from "axios";
import { FetchBankAppDetailsList } from "../action";
import { BASEURL } from "config/api/URL";
import { FETCH_BANK_APP_DETAILS_LIST_PATH } from "../constant";

export const FetchBankAppDetailsList_Middleware =
  (pageNumber, noOfRows, valueAndKey, searchFilter, onSuccess, onFailure) =>
  (dispatch) => {
    dispatch(FetchBankAppDetailsList.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_BANK_APP_DETAILS_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}&${valueAndKey.key}=${valueAndKey.value}&${searchFilter.key}=${searchFilter.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchBankAppDetailsList.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };
