import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import PhoneInput from "react-phone-input-2";
import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
const Filteration = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [image, setImage] = useState("")
  const formData = new FormData();
  let testPhoneNum = /^(\+92|92)[0-9]{10}$/;
  const [disabledField, setdisabledField] = useState(false)
  useEffect(() => {
    if (location.state !== null && location.state !== undefined) {
      setdisabledField(true)
      setState({
        ...state,
        fullName: location.state?.fullName,
        cnic: location.state?.cnic,
        phone: location.state?.phone,
        whatsAppNo: location.state?.whatsAppNo,
        email: location.state?.email,
       
      });
    }
  }, [location.state]);


 

  const [state, setState] = useState({
    fullName: "",
    cnic: "",
    email: "",
    phone: "",
    whatsAppNo: "",
  
    profilePhoto: "",
  });

  const onChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  //Start Validation
  const CheckFields = (name) => {
    if (name === "fullName") {
      setError({
        ...error,
        fullNameError: validate("required", state.fullName),
      });
    } else if (name === "cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", state.cnic),
      });
    } else if (name === "phone") {
      setError({
        ...error,
        phoneError: validate("phoneNumber", state.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", state.email),
      });
    } 
  };

  const [error, setError] = useState({
    phoneError: "",
    whatsAppNoError: "",
    emailError: "",
    cnicError: "",
    fullNameError: "",
  
  });

  const submit = () => {
    
    setError({
      ...error,
      fullNameError: validate("required", state.fullName),
      cnicError: validate("CNIC", state.cnic),
      emailError: validate("email", state.email),
      phoneError: validate("phoneNumber", state.phone),
      whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
     
    });
    
    if (
      location.state !== null &&
      location.state !== undefined &&
      state.fullName!=="" && state.cnic!=="" &&state.email!=="" && state.phone!=="" && state.whatsAppNo!==""
    ) {  swal({
      title: "Warning",
      text: "Are You Sure You Want To Edit This Staff Information?",
      icon: "warning",
       buttons : true,
      
    }).then((isOk) => {
       if (isOk) {
   
      let id = location.state?.realEstateStaffProfile?.id;
  
      formData.append("fullName",state.fullName);
      formData.append("whatsAppNo",state.whatsAppNo?.includes('+')?state.whatsAppNo:`+${state.whatsAppNo}`);
      formData.append("phone",state.phone?.includes('+')?state.phone:`+${state.phone}`)
      props.EditStaffMiddleware(id, formData, onSuccesEdit, onFailureEdit);
    }
  });

}
 
    
   
  };
  const onSuccesEdit = () => {
 

    swal({
      title: "Success!",
      text: "Staff Edited Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
     
        let path = "/user/AgentStaffListAdmin";
        history.push(path);
     
    });
  };
  const onFailureEdit = (error) => {
    swal("Sorry!", error.message, "error");
  };
 

// const onImageChange = (event) => {
//  if (event.target.files && event.target.files[0]) {
//    (event.target.files[0]);
//  }
// }
const onImageChange =(event) => {
setImage(event.target.files[0])
};


// const handleSubmit = () => {
//   console.log("SUBMITTED");
//   const formData = new FormData();
//  formData.append("file", image);
//   // console.log("dfdfd", formData);
//   if (formData !== {}) {
//     const body = {
//       fullName: state.fullName,
//       phone: "+" + state.phone,
//       whatsAppNo: "+" + state.whatsAppNo,
//       file: formData,
//     };
   
//   }
// };

  return (
    <CardHeader className="border-0">
      <Row>
        <Col lg="2" md="2" sm="6">
          <h2>Add New Staff</h2>
        </Col>
        <Col lg="8" md="8" sm="6"></Col>
        <Col lg="2" md="2" sm="6"></Col>
      </Row>
      <hr />
      <CardBody>
        <form>
          <Row>
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Name*
              </label>
              <input
                type="text"
                
                onBlur={() => CheckFields("fullName")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={state.fullName}
                onKeyPress={(event) => {
                  if (!/[A-Z, a-z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => onChange(e.target.value, "fullName")}
                autoComplete="off"
              ></input>
              {error.fullNameError !== "" && error.fullNameError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.fullNameError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Cnic*
              </label>
              <input
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={13}
                disabled={disabledField}
                onBlur={() => CheckFields("cnic")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={state.cnic}
                onChange={(e) => onChange(e.target.value, "cnic")}
                autoComplete="off"
              ></input>
              {error.cnicError !== "" && error.cnicError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.cnicError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Email*
              </label>
              <input
                type="text"
                onBlur={() => CheckFields("email")}
                id="input-username"
                className="form-control"
                disabled={disabledField}
                placeholder=""
                value={state.email}
                onChange={(e) => onChange(e.target.value, "email")}
                autoComplete="off"
              ></input>
              {error.emailError !== "" && error.emailError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.emailError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="4" md="4" sm="6">
                <label className="form-control-label" for="input-username">
                  Phone No
                </label>
                <PhoneInput
                   placeholder="+92"
                  
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "phone")}
                  onBlur={() => CheckFields("phone")}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                    height:"100%"
                    
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="100%"
                  
                  //  containerStyle={{border:"none"}}
                  countryCodeEditable={false}
                  country={"pk"}
                  value={state.phone?state.phone:""}
                />

                {error.phoneError !== "" && error.phoneError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.phoneError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                  Whatsapp No
                </label>
                <PhoneInput
                  placeholder="+92"
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "whatsAppNo")}
                  onBlur={() => CheckFields("whatsAppNo")}
                 
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                    height:"100%"
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="true"
                  countryCodeEditable={false}
                  country={"pk"}
                  value={state.whatsAppNo?state.whatsAppNo:""}
                />

                {error.whatsAppNoError !== "" &&
                  error.whatsAppNoError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.whatsAppNoError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
              </Col>
            {/* <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                WhatsApp No*
              </label>
              <input
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={
                  location.state !== null && location.state !== undefined
                    ? 13
                    : 12
                }
                onBlur={() => CheckFields("whatsAppNo")}
                id="input-username"
                className="form-control"
                placeholder="92XXXXXXXXXX"
                value={state.whatsAppNo}
                onChange={(e) => onChange(e.target.value, "whatsAppNo")}
                autoComplete="off"
              ></input>
              {error.whatsAppNoError !== "" && error.whatsAppNoError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.whatsAppNoError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            
            <Col lg="4" md="4" sm="6">
              <label className="form-control-label" for="input-username">
                Phone No*
              </label>
              <input
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={
                  location.state !== null && location.state !== undefined
                    ? 13
                    : 12
                }
                onBlur={() => CheckFields("phone")}
                id="input-username"
                className="form-control"
                placeholder="92XXXXXXXXXX"
                value={state.phone}
                onChange={(e) => onChange(e.target.value, "phone")}
                autoComplete="off"
              ></input>
              {error.phoneError !== "" && error.phoneError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.phoneError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col> */}
            
            {/* <Col lg="4" md="4" sm="6">
            <label className="form-control-label" for="input-username">
                Profile Pic
              </label>
            <input 
              className="form-control"
              type="file" name="file"
              accept="image/*"
            onChange={onImageChange} 
             />
            </Col> */}
          </Row>
          <Row>
            <Col lg="4" md="4" sm="6">
              <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={submit}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       Submit
                      </span>
                    </Button>
              </Col>

            </Row>
           
            
           
        
        </form>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
