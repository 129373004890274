import React from "react";
import Headers from "components/Headers/Header1";
import Filteration from "./Filteration";
import { Container, Card, Row } from "reactstrap";

const CustomerList = (props) => {
  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filteration
              GetCustomerFileMiddleware={props.GetCustomerFileMiddleware}
              file={props.file}
              list={props.list}
                GetCustomerListMiddleware={props.GetCustomerListMiddleware}
                responseCustomer={props.responseCustomer}
                DeleteCustomerMiddleware={props.DeleteCustomerMiddleware}
                del={props.del}

                FetchUser_Middleware={props.FetchUser_Middleware} User={props.User} Price={props.Price}
                _FetchPrice_Middleware={props._FetchPrice_Middleware}

                InsertCustomerMiddleware ={props.InsertCustomerMiddleware}  
                customer={props.customer}
              ></Filteration>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default CustomerList;
