import axios from "axios";
import { FetchAllReceipt, FormCancelation, FormStatus } from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_ALL_RECEIPT_PATH,
  CANCELATION_PATH,
  STATUS_PATH,
} from "../constant";

export const _FetchAllReceipt = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(FetchAllReceipt.Fetch());
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${FETCH_ALL_RECEIPT_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(FetchAllReceipt.FetchSuccess(res.data));
        dispatch(onSuccess(res.statusText));
      } else {
        dispatch(FetchAllReceipt.FetchFailure(res.statusText));
        dispatch(onFailure(res.statusText));
      }
    })
    .catch((error) => dispatch(FetchAllReceipt.FetchFailure(error)));
};

export const _FormCancelation = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(FormCancelation.Cancelation());
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${CANCELATION_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(FormCancelation.CancelationSuccess(res.data));
        dispatch(onSuccess(res.statusText));
      } else {
        dispatch(FormCancelation.CancelationFailure(res.statusText));
        dispatch(onFailure(res.statusText));
      }
    })
    .catch((error) => dispatch(FormCancelation.CancelationFailure(error)));
};

export const _FormStatus = (onSuccess, onFailure) => (dispatch) => {
  dispatch(FormStatus.Status());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${STATUS_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(FormStatus.StatusSuccess(res.data));
        dispatch(onSuccess(res.statusText));
      } else {
        dispatch(FormStatus.StatusFailure(res.statusText));
        dispatch(onFailure(res.statusText));
      }
    })
    .catch((error) => dispatch(FormStatus.StatusFailure(error)));
};
