import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Spinner,
  Col,
  Row,
} from "reactstrap";
import "react-h5-audio-player/lib/styles.css";
import { AppReqMiddleware } from "../middleware";
import { connect } from "react-redux";
import swal from "sweetalert";
import { GetSocketRequestDetails } from "sockets/socket";
import Loading from "components/Loader/Loading";

const RequestUserViewModal = ({
  AppReqMiddleware,
  toggle,
  modal,
  count,
  pricing,
}) => {
  let numCount = parseInt(count?.total_forms);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [values, setValues] = useState({ counter: 1, price: pricing });
  const [state, setState] = useState("");
  const ream =
    count?.total_forms !== undefined ? 30 - count?.total_forms : "30";
  console.log("REMAINING", count?.total_forms);
  const handleClick1 = () => {
    setValues({
      counter: values.counter + 1,
      price: values.price + pricing,
    });
  };

  const handleClick2 = () => {
    setValues({
      counter: values.counter - 1,
      price: values.price - pricing,
    });
  };

  const confirm = () => {
    setLoading(true);
    let body = {
      NumberOfApplication: values.counter,
    };
    AppReqMiddleware(body, onSuccess, onFailure);
  };
  const onSuccess = (e) => {
    setState(e);
    setLoading(false);
    console.log("EEEE", e);
    window.fbq("track", "RequestFormConfirm");
    // GetSocketRequestDetails(e.id, history);
    swal({
      title: "Congratulations!",
      text: "Application Request",
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push({
          pathname: "/user/PaymentConfirmation",
          state: e,
        });
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
    setLoading(false);
  };

  useEffect(() => {
    setValues({ ...values, price: pricing });
  }, [pricing]);
  return (
    <Modal size="sm" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d69929" }}>
        <h1 style={{ color: "white", textAlign: "center", marginLeft: -22 }}>
          Request forms{" "}
        </h1>
        <div
          style={{
            fontSize: "120%",
            // position: "relative",
            // top: "5vh",
          }}
        >
          <p
            style={{ color: "white", fontWeight: "600", marginBottom: -10 }}
          >{`No. of remaining forms: ${ream}`}</p>
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "200%",
            // position: "absolute",
            width: "100%",
            height: "100%",
            // top: "-15%",
          }}
        >
          <div
            style={{
              fontSize: "150%",
              position: "relative",
              top: "0vh",
            }}
          >
            {values.counter}
          </div>

          <div className="buttons">
            <button
              style={{
                fontSize: "90%",
                position: "relative",
                top: "0vh",
                marginRight: "5px",
                backgroundColor: "#6d7075",
                borderRadius: "100%",
                color: "white",
                padding: "0px 15px",
                border: "none",
              }}
              onClick={handleClick2}
              disabled={values.counter === 1}
            >
              -
            </button>

            <button
              style={{
                fontSize: "90%",
                position: "relative",
                top: "0vh",
                marginLeft: "5px",
                backgroundColor: "#6d7075",
                borderRadius: "100%",
                color: "white",
                padding: "0px 11px",
                border: "none",
              }}
              onClick={handleClick1}
              disabled={values.counter === 30 - numCount || numCount === 30}
            >
              +
            </button>
          </div>
          <div
            style={{
              fontSize: "120%",
              position: "relative",
              top: "5vh",
            }}
          >
            <p>You can get upto 30 forms from one account</p>
          </div>
          <div></div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <h1 style={{ color: "#6d7075" }}>Rs:{values.price}</h1>
          </Col>
          <Col>
            <Button
              style={{
                backgroundColor: "#015652  ",
                color: "white",
                width: "100%",
              }}
              onClick={confirm}
              disabled={numCount === 30 || loading}
            >
              {loading ? <Loading attr="wait" /> : "Confirm"}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  AllReceipts: state.viewPaymentReceipt.AllReceipts,
  StatusResponse: state.viewPaymentReceipt.StatusResponse,
  Response: state.viewPaymentReceipt.Response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    AppReqMiddleware: (body, onSuccess, onFailure) =>
      dispatch(AppReqMiddleware(body, onSuccess, onFailure)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToPrpos
)(RequestUserViewModal);
