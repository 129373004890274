import axios from "axios";
import { connect } from "react-redux";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Table,
  FormGroup,
  Label,
  Form,
  Input,
} from "reactstrap";
import { useState, useEffect } from "react";
import validate from "components/Utilities Component/ValidationWrapper";
import { VerifyByCNIC } from "views/UserViews/MembershipForm/middleware";
import { GetBankListMiddleware } from "views/AdminView/BankList/middleware";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router";
const Information = ({
  _VerifyByCNIC,
  toggle,
  FormSubmitMiddleware,
  modal,
  detail,
  _GetBankListMiddleware,
  response,
  formdata,
  getApi,
  resetChecked,
  uncheck,
}) => {
  const [body, setBody] = useState({
    // price: "",
    name: "",
    cnic: "",
    // residentalId: "",
    email: "",
    // depositSlipNo: "",
    // bank: "",
  });
  const [banks, setBanks] = useState([]);
  const [depositSlipValidation, setdepositSlipValidation] = useState("");
  const [validationWhatsAppNo, setValidationWhatsAppNo] = useState("");
  const [checked, setChecked] = useState(false);
  const [disabledButton, setdisabledButton] = useState(false);
  const [selectedForm, setSelectedForm] = useState([]);
  const history = useHistory();
  const [error, setError] = useState({
    cnicError: "",
    nameError: "",
    phoneError: null,
    formNoError: "",
    slipNoError: "",
    categoryError: "",
    formPicError: "",
    depositSlipError: "",
    whatsappNoError: null,
    emailError: null,
    bankError: "",
  });
  const [newArr, setNewArr] = useState([]);
  const [newArrCount, setNewArrCount] = useState([]);
  const [formCount, setFormCount] = useState([]);
  const [image, setImage] = useState({ form: "", depositSlip: "" });
  useEffect(() => {
    let tempArr = [];
    let tempArrCount = [];
    if (formCount !== null && formCount !== undefined && formCount !== "") {
      formCount.map((data, index) => {
        tempArrCount.push(data);
        if (data.quantity < 5) {
          tempArr.push(data);
        }
      });
    }

    setNewArr(tempArr);
    setNewArrCount(tempArrCount);
  }, [formCount]);
  const handleCheck = (event, value) => {
    if (formdata.length == selectedForm.length && event.target.checked) {
      swal(
        "Sorry!",
        `Plots length must be equal to the forms you have selected! please check mark only ${formdata.length} plots`,
        "error"
      );
      event.target.checked = false;

      return;
    }
    var updatedList = [...selectedForm];
    if (event.target.checked) {
      updatedList = [...selectedForm, value.id];
      var items = updatedList.map((data, index) => {
        return {
          resPlotId: data,
          realEstateAppId: formdata[index].id,
        };
      });

      setBody({ ...body, realEstateApplicationIds: items });
    } else {
      updatedList.splice(selectedForm.indexOf(value.id), 1);
    }
    //  if (location.forms.length === selectedForm.length) {

    // }
    setSelectedForm(updatedList);
  };
  const checkdeposit = () => {
    if (body.depositSlipNo?.length < 4) {
      setdepositSlipValidation("Deposit Slip contain minimum 4 digit");
    } else {
      setdepositSlipValidation("");
    }
  };

  const CheckFields = (name) => {
    if (name === "cnic") {
      if (body.cnic?.length == 13) {
        _VerifyByCNIC(body?.cnic, onVerifySuccess, onVerifyFailure);
      } else {
        setFormCount("");
      }
      setError({
        ...error,
        cnicError: validate("CNIC", body.cnic),
      });
    } else if (name === "name") {
      setError({
        ...error,
        nameError: validate("required", body.name),
      });
    } else if (name === "mobile") {
      setError({
        ...error,
        phoneError: validate("phoneNumber", body.phone),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", body.email),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsappNoError: validate("phoneNumber", body.whatsAppNo),
      });
    } else if (name === "slipNo") {
      setError({
        ...error,
        slipNoError: validate("required", body.depositSlipNo),
      });
    } else if (name === "category") {
      setError({
        ...error,
        categoryError: validate("required", body.residentalId),
      });
    } else if (name === "form") {
      setError({
        ...error,
        formPicError: validate("required", image.form),
      });
    } else if (name === "depositSlip") {
      setError({
        ...error,
        depositSlipError: validate("required", image.depositSlip),
      });
    } else if (name === "bank") {
      setError({
        ...error,
        bankError: validate("required", body.bank),
      });
    }
  };
  const onVerifySuccess = (data) => {
    setFormCount(data);
  };
  useEffect(() => {
    _GetBankListMiddleware(1, 100, onBankSuccess, onBankFailure);
  }, []);
  const onVerifyFailure = () => {};
  useEffect(() => {
    setBanks(response?.items);
  }, [response]);
  //data from main form
  // useEffect(() => {
  //   if (detail !== null && detail !== undefined) {
  //     setBody({ ...body, formNo: detail?.formNo, id: detail?.id });
  //   }
  // }, [detail]);

  const onImageChange = (event, name) => {
    setImage({ ...image, [name]: event.target.files[0] });
  };

  const onSuccess = () => {
    setBody({
      ...body,
      price: "",
      name: "",
      cnic: "",
      // residentalId: "",
      email: "",
      whatsAppNo: "92",
      phone: "92",
      // depositSlipNo: "",
      // bank: "",
    });
    setFormCount("");
    setImage({ ...image, form: "", depositSlip: "" });
    setdisabledButton(false);
    setSelectedForm([]);
    // resetChecked();

    swal({
      title: "Success!",
      text: "Form Submitted Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        uncheck();
        resetChecked();
        // getApi();
        toggle();
      } else {
        uncheck();
        // getApi();
        resetChecked();
        toggle();
      }
    });
  };
  const onFailure = (error) => {
    setdisabledButton(false);
    swal("Sorry!", error, "error");
  };
  const close = () => {
    setError({
      ...error,
      cnicError: "",
      nameError: "",
      phoneError: null,
      formNoError: "",
      slipNoError: "",
      categoryError: "",
      formPicError: "",
      depositSlipError: "",
      whatsappNoError: null,
      emailError: null,
      bankError: "",
    });
    setBody({
      ...body,
      // price: "",
      name: "",
      cnic: "",
      // residentalId: "",
      email: "",
      whatsAppNo: "92",
      phone: "92",
      // depositSlipNo: "",
      // bank: "",
    });
    setFormCount("");
    setSelectedForm([]);
    setImage({ ...image, form: "", depositSlip: "" });
    toggle();
    setChecked(false);
  };

  const handleSendData = () => {
    const formData = new FormData();
    setError({
      ...error,
      nameError: validate("required", body.name),
      cnicError: validate("CNIC", body.cnic),
      categoryError: validate("required", body.residentalId),
      phoneError: validate("phoneNumber", body.phone),
      whatsappNoError: validate("phoneNumber", body.whatsAppNo),
      slipNoError: validate("required", body.depositSlipNo),
      formPicError: validate("required", image.form),
      depositSlipError: validate("required", image.depositSlip),
      emailError: validate("email", body.email),
      bankError: validate("required", body.bank),
    });
    // checkdeposit();

    if (selectedForm.length < formdata.length) {
      swal(
        "Sorry!",
        `Plots length must be equal to the forms you have selected! please check mark only ${formdata.length} plots`,
        "error"
      );
    } else if (
      body.name !== "" &&
      // body.depositSlipNo !== "" &&
      // body.depositSlipNo?.length >= 4 &&
      body?.cnic?.trim().length == 13 &&
      body.whatsAppNo?.trim().length == 13 &&
      body.phone?.trim().length == 13 &&
      body.email?.trim().length > 0 &&
      // body.residentalId !== "" &&
      // image.form !== "" &&
      // image.depositSlip !== "" &&
      error.emailError == null
      // &&body.bank !== ""
    ) {
      // let id = body.id;
      // formData.append("formFile", image.form);
      // //formData.append("depositSlipFile", image.depositSlip);
      // formData.append("name", body.name);
      // formData.append("cnic", body.cnic);
      // formData.append("phone", "+" + body.phone);
      // formData.append("whatsAppNo", "+" + body.whatsappNo);
      // formData.append("email", body.email);
      // //formData.append("depositSlipNo", body.depositSlipNo);
      // formData.append("residentalId", body.residentalId);
      //formData.append("bankId", parseInt(body.bank));
      setdisabledButton(true);
      FormSubmitMiddleware(body, onSuccess, onFailure);
      setChecked(false);
    }
  };
  const onBankSuccess = () => {};
  const onBankFailure = () => {};
  const handleChecked = () => {
    setChecked(!checked);
  };
  useEffect(() => {
    setBody({ ...body, whatsAppNo: checked == true ? body.phone : "92" });
  }, [checked]);

  return (
    <Modal size="lg" isOpen={modal} toggle={close}>
      <ModalHeader style={{ backgroundColor: "#d69929" }} toggle={close}>
        <h3 style={{ color: "white" }}>Form Submission Details</h3>
      </ModalHeader>
      <ModalBody>
        <div>
          <Row className=" mt-3">
            {/* <Col lg="2" md="2" sm="2" xs="2">
              <label className="form-control-label" for="input-username">
                Form No:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Form No."
                value={body.formNo}
                autoComplete="off"
                disabled
              />
            </Col> */}
            <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Name:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Enter Full Name"
                value={body.name}
                onBlur={() => CheckFields("name")}
                onChange={(e) => setBody({ ...body, name: e.target.value })}
                autoComplete="off"
                onKeyPress={(event) => {
                  if (!/[A-Z, a-z ]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              {error.nameError !== "" && error.nameError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.nameError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                CNIC:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Enter CNIC Number"
                value={body.cnic}
                maxLength={13}
                autoComplete="off"
                onBlur={() => CheckFields("cnic")}
                onChange={(e) => setBody({ ...body, cnic: e.target.value })}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {error.cnicError !== "" && error.cnicError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.cnicError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
          </Row>
          <Row className=" mt-3">
            <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Phone No
              </label>
              <PhoneInput
                placeholder="+92"
                className="input-group-alternative"
                onBlur={() => CheckFields("mobile")}
                onChange={(e) => {
                  let val = `+${e}`;
                  setBody({ ...body, phone: val });
                }}
                inputStyle={{
                  border: "1px solid lightgrey",
                  width: "100%",
                  height: "100%",
                }}
                inputExtraProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                fullWidth="100%"
                //  containerStyle={{border:"none"}}
                countryCodeEditable={false}
                country={"pk"}
                value={body.phone}
                disabled={checked}
              />

              {error.phoneError !== "" && error.phoneError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.phoneError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>
            <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Whatsapp No
              </label>
              <PhoneInput
                placeholder="+92"
                className="input-group-alternative"
                onBlur={() => CheckFields("whatsAppNo")}
                onChange={(e) => {
                  let val = `+${e}`;
                  setBody({ ...body, whatsAppNo: val });
                }}
                inputStyle={{
                  border: "1px solid lightgrey",
                  width: "100%",
                  height: "100%",
                }}
                inputExtraProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                fullWidth="true"
                countryCodeEditable={false}
                country={"pk"}
                value={body.whatsAppNo}
                disabled={checked}
              />

              {error.whatsappNoError !== "" && error.whatsappNoError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.whatsappNoError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
              {body.phone?.length == 13 ? (
                <div style={{ position: "relative", float: "right" }}>
                  <input
                    type="checkbox"
                    onClick={handleChecked}
                    // disabled={isUserExist}
                  />
                  <small>
                    <b> Same As Phone No</b>
                  </small>
                </div>
              ) : (
                ""
              )}
            </Col>

            <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Email*
              </label>
              <input
                type="text"
                onBlur={() => CheckFields("email")}
                id="input-username"
                className="form-control"
                placeholder=""
                value={body.email}
                onChange={(e) => setBody({ ...body, email: e.target.value })}
                autoComplete="off"
              ></input>
              {error.emailError !== "" && error.emailError !== null && (
                <small style={{ marginTop: "2px" }}>
                  <span style={{ color: "red" }}>
                    {error.emailError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col>

            {/* <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Bank
              </label>
              <select
                className="form-control"
                //  onChange={(e) => setBankId(e.target.value)}
                onBlur={() => CheckFields("bank")}
                onChange={(e) => setBody({ ...body, bank: e.target.value })}
              >
                <option value="">Search Bank</option>
                {banks !== null && banks !== undefined
                  ? banks.map((data) => {
                      return (
                        <option key={data.id} value={data.id}>
                          {data.bankName}
                        </option>
                      );
                    })
                  : ""}
              </select>
              {error.bankError !== "" && error.bankError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.bankError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col> */}

            {/* <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Deposit Slip No:
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder="Enter Slip Number"
                value={body.depositSlipNo}
                autoComplete="off"
                maxLength={11}
                onBlur={() => CheckFields("slipNo")}
                onChange={(e) =>
                  setBody({ ...body, depositSlipNo: e.target.value })
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {error.slipNoError !== "" && error.slipNoError !== null ? (
                <small>
                  <span style={{ color: "red" }}>
                    {error.slipNoError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              ) : depositSlipValidation !== "" ? (
                <small>
                  <span style={{ color: "red" }}>
                    {depositSlipValidation}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              ) : (
                ""
              )}
            </Col> */}
            {/* </Row>
          <Row> */}
            {/* <Col lg="6" md="6" sm="6" xs="6">
              <label className="form-control-label" for="input-username">
                Plot Category:
              </label>
              <select
                className="form-control"
                // value={body.residentalId}
                onBlur={() => CheckFields("category")}
                onChange={(e) =>
                  setBody({
                    ...body,
                    residentalId: JSON.parse(e.target.value).id,
                    price: JSON.parse(e.target.value).price,
                  })
                }
                disabled={newArr?.length == 0}
              >
                <option value="">Select Category</option>
                {newArr?.map((data) => {
                  return (
                    <option
                      key={data.id}
                      value={JSON.stringify({ id: data.id, price: data.price })}
                      // value={data.id}
                    >
                      {data.title}
                    </option>
                  );
                })}
              </select>
              {error.categoryError !== "" && error.categoryError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.categoryError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col> */}
            {/* <Col lg="6" md="6" sm="6">
              <label className="form-control-label" for="input-username">
                Price
              </label>
              <input
                type="text"
                id="input-username"
                className="form-control"
                placeholder=""
                disabled
                value={body.price}
              ></input>
            </Col> */}
            {/* <Col lg="6" md="6" sm="6">
              <label className="form-control-label" for="input-username">
                Form picture
              </label>
              <input
                className="form-control"
                type="file"
                name="file"
                accept="image/*"
                onBlur={() => CheckFields("form")}
                onChange={(e) => onImageChange(e, "form")}
              />
              {error.formPicError !== "" && error.formPicError !== null && (
                <small>
                  <span style={{ color: "red" }}>
                    {error.formPicError}{" "}
                    <i className="fas fa-exclamation-circle"></i>
                  </span>
                </small>
              )}
            </Col> */}
            {/* <Col lg="6" md="6" sm="6">
              <label className="form-control-label" for="input-username">
                Deposit slip picture
              </label>
              <input
                className="form-control"
                type="file"
                name="file"
                accept="image/*"
                onBlur={() => CheckFields("depositSlip")}
                onChange={(e) => onImageChange(e, "depositSlip")}
              />
              {error.depositSlipError !== "" &&
                error.depositSlipError !== null && (
                  <small>
                    <span style={{ color: "red" }}>
                      {error.depositSlipError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )} 
            </Col>*/}
          </Row>
          <br />

          <Row style={{ marginLeft: "1%", marginRight: "1%" }}>
            <Col lg="12" md="12" sm="12">
              <Row>
                <table
                  style={{ border: "1px solid lightgrey", width: "100%" }}
                  className="align-items-center"
                  responsive
                >
                  <thead
                    style={{
                      backgroundColor: "#d69929",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    <tr>
                      <th scope="col">Category</th>
                      {/* <th scope="col">Price</th> */}
                      <th scope="col">Purchased</th>
                      <th scope="col">Remaining</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "12px" }}>
                    {newArr !== null && newArr !== undefined ? (
                      newArr.map((data, index) => {
                        let arr = new Array(
                          data?.remaining <= formdata.length
                            ? data?.remaining
                            : formdata.length
                        )
                          .fill("")
                          .map((_, i) => i + 1);
                        return (
                          <tr>
                            <td> {data?.title}</td>
                            {/* <td>{data?.price}</td> */}
                            <td>{data?.quantity}</td>

                            <td>
                              <Form>
                                {arr.map(() => {
                                  return (
                                    <FormGroup check inline>
                                      <Label check>
                                        <Input
                                          onChange={(e) => handleCheck(e, data)}
                                          type="checkbox"
                                        />
                                      </Label>
                                    </FormGroup>
                                  );
                                })}
                              </Form>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <th></th>
                        <th>
                          <h3>No data found</h3>
                        </th>
                        <th></th>
                      </tr>
                    )}
                  </tbody>
                  {/* <tbody style={{ fontSize: "12px" }}>
                  {newArrCount !== null && newArrCount !== undefined ? (
                    newArrCount.map((data, index) => {
                      return (
                        <tr>
                          <td> {data?.title}</td>
                          
                          <td>{data?.quantity}</td>
                          <td>{data?.remaining}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <th></th>
                      <th>
                        <h3>No data found</h3>
                      </th>
                      <th></th>
                    </tr>
                  )}
                </tbody> */}
                </table>
              </Row>
            </Col>
          </Row>
        </div>
      </ModalBody>

      <ModalFooter style={{ backgroundColor: "#d69929" }}>
        <Row>
          <Col>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={handleSendData}
              disabled={disabledButton}
            >
              Submit
            </Button>
            <Button
              style={{ backgroundColor: "#015652  ", color: "white" }}
              onClick={close}
            >
              Close
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};
{
  /* <ModalFooter>
  <Row>
    <Col>
      <Button
        style={{ backgroundColor: "#015652  ", color: "white" }}
        // onClick={handleSendData}
        // disabled={isTrue}
      >
        Submit
      </Button>
      <Button
        style={{ backgroundColor: "#015652  ", color: "white" }}
        //onClick={close}
      >
        Close
      </Button>
    </Col>
  </Row>
</ModalFooter>
</Modal>
);
}; */
}

//export default Information;

const mapStateToProps = (state) => ({
  // CheckRes: state.staffAppList.CheckRes,
  CNIC_Response: state.CustomerMembershipForm.CNIC_Response,
  response: state.bankList.response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _GetBankListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(
        GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)
      ),
    _VerifyByCNIC: (body, onSuccess, onFailure) =>
      dispatch(VerifyByCNIC(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Information);
