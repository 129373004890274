import React, { useEffect, useRef, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Container,
  Card,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import validate from "components/Utilities Component/ValidationWrapper";
import swal from "sweetalert";
import {
  FetchBanksByIdMiddleware,
  FormListMiddleware,
  LaunchedFormMiddleware,
} from "../middleware";
import { FetchBanksMiddleware } from "views/AdminView/FormGenerate/middleware";
import TableFormList from "../components/TableFormList";
import Pagination from "components/Pagination/Pagination";
import KGC_BankVoucher from "views/AdminView/HtmlReciept/KGC_BankVoucher/KGC_BankVoucher";
import {
  FORM_LIST_SUCCESS,
  FETCH_BANKS_BY_ID_SUCCESS,
  LAUNCHED_FROMS_SUCCESS,
} from "../constant";
import { useReactToPrint } from "react-to-print";
import { ExportCSV } from "../components/ExportToCSV";
import store from "store";
import KGC_BankSingleVoucher from "views/AdminView/HtmlReciept/KGC_BankSingleVoucher/KGC_BankSingleVoucher";

const FormExport = ({
  FetchBanksMiddleware,
  getBanks,
  FormListMiddleware,
  formlist,
  FetchBanksByIdMiddleware,
  bankById,
  LaunchedFormMiddleware,
  launchedForms,
}) => {
  const componentRef = useRef();
  const componentRef2 = useRef();

  const [excelData, setExcelData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [issClicked, setIsClicked] = useState(false);

  const [body, setBody] = useState({
    bankId: "",
    startForm: "",
    endForm: "",
  });

  const [error, setError] = useState({
    bankIdError: null,
    startFormError: null,
    endFormError: null,
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: `Transfer Receipt ${transferPrintData?.users[0]?.saleorder?.SaleOrderNo}`,
    copyStyles: true,
  });
  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
    // documentTitle: `Transfer Receipt ${transferPrintData?.users[0]?.saleorder?.SaleOrderNo}`,
    copyStyles: true,
  });
  const print = () => {
    handlePrint();
    setIsClicked(false);
  };
  const CheckFields = (name) => {
    if (name === "bankId") {
      setError({
        ...error,
        bankIdError: validate("required", body.bankId),
      });
    } else if (name === "startForm") {
      setError({
        ...error,
        startFormError: validate("required", body.startForm),
      });
    } else if (name === "endForm") {
      setError({
        ...error,
        endFormError: validate("required", body.endForm),
      });
    }
  };

  const onSuccess = (msg) => {
    setIsLoading(false);
  };
  const onFailure = (msg) => {
    swal("Sorry!", msg.message, "error");
    setIsLoading(false);
  };

  const copy = (value) => {
    navigator.clipboard.writeText(value);
  };

  const handleSendData = () => {
    let endFormCount = body.endForm.split("-")[1];
    let startFormCount = body.startForm.split("-")[1];
    setError({
      ...error,
      bankIdError: validate("required", body.bankId),
      startFormError: validate("required", body.startForm),
      endFormError: validate("required", body.endForm),
    });
    if (
      body.bankId !== "" &&
      body.startForm !== "" &&
      body.endForm !== "" &&
      endFormCount &&
      startFormCount
    ) {
      let limit = endFormCount - startFormCount;
      FormListMiddleware(body, limit, onSuccess, onFailure);
      setIsLoading(true);
    } else if (endFormCount == undefined || startFormCount == undefined) {
      swal("Sorry!", "Invalid Form Range", "error");
    }
  };

  useEffect(() => {
    FetchBanksMiddleware();
    // store.dispatch({ type: FORM_LIST_SUCCESS, payload: null });
    // store.dispatch({ type: FETCH_BANKS_BY_ID_SUCCESS, payload: null });
    // store.dispatch({ type: LAUNCHED_FROMS_SUCCESS, payload: null });
    // setExcelData(null);
  }, []);

  useEffect(() => {
    setIsClicked(false);
    if (body.bankId !== "") {
      store.dispatch({ type: FORM_LIST_SUCCESS, payload: null });
      FetchBanksByIdMiddleware(body.bankId);
      LaunchedFormMiddleware(body.bankId);
    } else {
      store.dispatch({ type: FORM_LIST_SUCCESS, payload: null });
      store.dispatch({ type: LAUNCHED_FROMS_SUCCESS, payload: null });
      store.dispatch({ type: FETCH_BANKS_BY_ID_SUCCESS, payload: null });
    }
  }, [body.bankId]);

  useEffect(() => {
    if (formlist && formlist?.items?.length > 0) {
      let tempArr = [];
      formlist?.items?.map((item, index) => {
        tempArr.push({
          ["Sno"]: index + 1,
          ["Date"]: "",
          ["Deposit Number"]: item.depositNo,
          ["Register Number"]: item.refNo,
          ["Name"]: "",
          ["CNIC"]: "",
          ["Phone Number"]: "",
          ["Email"]: "",
          ["Plot Category"]: "",
          ["Bank Name"]: bankById.name,
          ["Branch Name"]: "",
          ["Branch Code"]: "",
          ["Amount"]: item.formPrice,
          ["Amount In Words"]: item.formPriceInWords,
        });
      });
      setExcelData(tempArr);
    }
  }, [formlist]);

  // useEffect(() => {
  //   // store.dispatch({ type: FORM_LIST_SUCCESS, payload: null });
  //   // store.dispatch({ type: FETCH_BANKS_BY_ID_SUCCESS, payload: null });
  //   // store.dispatch({ type: LAUNCHED_FROMS_SUCCESS, payload: null });
  //   // setExcelData(null);
  // }, [getBanks]);

  useEffect(() => {
    if (issClicked) {
      print();
    }
  }, [issClicked]);
  return (
    <>
      <Header />
      {issClicked ? (
        <div style={{ display: "none" }}>
          <KGC_BankVoucher
            ref={componentRef}
            bankById={bankById}
            formlist={formlist}
          />
        </div>
      ) : (
        ""
      )}

      <div style={{ display: "none" }}>
        <KGC_BankSingleVoucher ref={componentRef2} bankById={bankById} />
      </div>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h1 className="mb-0">Form Export</h1>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Select Bank <span className="text-red">*</span>
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setBody({ ...body, bankId: e.target.value })
                      }
                      onBlur={() => CheckFields("bankId")}
                      value={body.bankId}
                    >
                      <option value="">Search Bank</option>
                      {getBanks?.items?.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                    {error.bankIdError !== "" && error.bankIdError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.bankIdError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Start Form{" "}
                      <span className="text-red">
                        *
                        {launchedForms?.formStart?.depositNo
                          ? `(${launchedForms?.formStart?.depositNo})`
                          : ""}
                      </span>
                      {launchedForms?.formStart?.depositNo ? (
                        <span className="btn-inner--icon">
                          <i
                            class="fas fa-copy"
                            onClick={() =>
                              copy(launchedForms?.formStart?.depositNo)
                            }
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                          ></i>
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={body.startForm}
                      onBlur={() => CheckFields("startForm")}
                      onChange={(e) =>
                        setBody({ ...body, startForm: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.startFormError !== "" &&
                      error.startFormError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.startFormError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      End Form
                      <span className="text-red">
                        *
                        {launchedForms?.formEnd?.depositNo
                          ? `(${launchedForms?.formEnd?.depositNo})`
                          : ""}
                      </span>
                      {launchedForms?.formEnd?.depositNo ? (
                        <span className="btn-inner--icon">
                          <i
                            class="fas fa-copy"
                            onClick={() =>
                              copy(launchedForms?.formEnd?.depositNo)
                            }
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                          ></i>
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={body.endForm}
                      onBlur={() => CheckFields("endForm")}
                      onChange={(e) =>
                        setBody({ ...body, endForm: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                    {error.endFormError !== "" && error.endFormError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.endFormError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    {isLoading ? (
                      <Button
                        style={{
                          backgroundColor: "#015652",
                          color: "white",
                          marginTop: "30px",
                        }}
                        className="btn-icon btn-2"
                        type="button"
                        id="save"
                        disabled
                      >
                        <Spinner size="sm">Loading...</Spinner>
                        <span> Loading</span>
                      </Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "#015652",
                          color: "white",
                          marginTop: "30px",
                        }}
                        className="btn-icon btn-2"
                        type="button"
                        id="save"
                        onClick={handleSendData}
                      >
                        <span className="btn-inner--icon">Search</span>
                      </Button>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Total Form
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={formlist?.totalCount || ""}
                      autoComplete="off"
                      disabled
                    ></input>
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Available Form
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={formlist?.avaliableCount || ""}
                      autoComplete="off"
                      disabled
                    ></input>
                  </Col>
                  <Col
                    lg="6"
                    md="4"
                    sm="6"
                    xs="12"
                    style={{ marginTop: "30px" }}
                  >
                    <ExportCSV csvData={excelData} fileName="text-excel-doc" />

                    <Button
                      style={{ backgroundColor: "red", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      onClick={() => setIsClicked(true)}
                      disabled={formlist && bankById ? false : true}
                    >
                      <span className="btn-inner--icon">
                        <i className="far fa-file-pdf p-1"></i> PDF
                      </span>
                    </Button>
                    <Button
                      style={{ backgroundColor: "red", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      onClick={handlePrint2}
                      disabled={bankById ? false : true}
                    >
                      <span className="btn-inner--icon">
                        <i className="far fa-file-pdf p-1"></i> Single Form PDF
                      </span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                <TableFormList formlist={formlist} />
                {/* <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                /> */}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  getBanks: state.FetchBanksReducer.getBanks,
  formlist: state.FormExport.formlist,
  bankById: state.FormExport.bankById,
  launchedForms: state.FormExport.launchedForms,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    FetchBanksMiddleware: () => dispatch(FetchBanksMiddleware()),
    FormListMiddleware: (params, limit, onSuccess, onFailure) =>
      dispatch(FormListMiddleware(params, limit, onSuccess, onFailure)),
    FetchBanksByIdMiddleware: (id) => dispatch(FetchBanksByIdMiddleware(id)),
    LaunchedFormMiddleware: (id) => dispatch(LaunchedFormMiddleware(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(FormExport);
