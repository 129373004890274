import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
  Label,
  Input,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import TableRequestAgentView from "../components/TableRequestAgentView";
import { connect } from "react-redux";
import {
  FetchAllRequest_Middleware,
  // FetchCount_Middleware,
  FetchPrice_Middleware,
  FetchUser_Middleware,
} from "../middleware";
import Pagination from "components/Pagination/Pagination";
import RequestAgentViewModal from "../components/RequestAgentViewModal";
import KGC_AgentSingleVoucher from "views/AgentView/HtmlRecieptAgent/KGC_AgentSingleVoucher/KGC_AgentSingleVoucher";
import { useReactToPrint } from "react-to-print";

const RequestAgentView = ({
  _FormCancelation,
  _FetchAllRequest_Middleware,
  AllRequests,
  // _FetchCount_Middleware,
  // Count,
  _FetchPrice_Middleware,
  Price,
}) => {
  const [filter, setFilter] = useState("");
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [valuesAndKey, setValueAndKey] = useState({});
  const [criteriaType, setCriteriaType] = useState({
    criteria: "cnic",
  });
  ///////////----------Pagination--------------//////////////
  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = (e) => {};
  const onSuccessFetch = (e) => {
    if (e?.items) {
      setPosts(e?.items);
    }
  };
  const onFailure = () => {};

  const ToggleViewReceiptModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };
  //////////-------useEffects--------//////////////

  useEffect(() => {
    setPageNumber(1);
    // props.GetApplicationListMiddleware(pageNumber, noOfRows, valuesAndKey, onSuccess, onFailure);
    _FetchAllRequest_Middleware(
      pageNumber,
      noOfRows,
      valuesAndKey,
      onSuccessFetch,
      onFailure
    );
  }, [valuesAndKey]);

  useEffect(() => {
    _FetchAllRequest_Middleware(
      pageNumber,
      noOfRows,
      valuesAndKey,
      onSuccessFetch,
      onFailure
    );
  }, [pageNumber, noOfRows]);

  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      setValueAndKey({
        key: key,
        value: value,
      });
    });
  }, [filter]);
  useEffect(() => {
    setFilter({ [criteriaType.criteria]: "" });
  }, [criteriaType]);

  useEffect(() => {
    _FetchPrice_Middleware(onSuccess, onFailure);
  }, []);
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `chalan`,
  //   copyStyles: true,
  // });
  return (
    <>
      {/* <KGC_AgentSingleVoucher ref={componentRef} />
      <Button color="success" onClick={() => handlePrint()} style={{zIndex:"10000"}} size="sm">
        <i class="fas fa-print"></i>
      </Button> */}
      <Headers />
      <RequestAgentViewModal
        toggle={ToggleViewReceiptModal}
        modal={isViewModalOpen}
        //count={Count}
        pricing={Price}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Request List</h2>
                  </Col>
                  <Col lg="2" md="2" sm="2">
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      size="md"
                      id="search"
                      onClick={() => ToggleViewReceiptModal()}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Form Request</span>
                    </Button>
                  </Col>
                </Row>
                <hr />
              </CardHeader>
              <CardBody>
                <Row className="mt--5">
                  <Col xl="2" lg="4" md="4" sm="6" xs="6">
                    <Label className="form-control-label">
                      {" "}
                      Rows Per Pages{" "}
                    </Label>
                    <Input
                      id="exampleFormControlSelect1"
                      type="select"
                      onChange={(e) => onChangeNoOfRows(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </Input>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search Criteria
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setCriteriaType({
                          ...criteriaType,
                          criteria: e.target.value,
                        })
                      }
                    >
                      <option value="cnic">CNIC</option>
                      <option value="name">Name</option>

                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                      <option value="whatsAppNo">WhatsApp No</option>
                      <option value="Status">Payment status</option>
                    </select>
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder={
                        criteriaType.criteria == "name"
                          ? "Name"
                          : criteriaType.criteria == "cnic"
                          ? "XXXXXXXXXXXXX"
                          : criteriaType.criteria == "phone"
                          ? "92XXXXXXXXXX"
                          : criteriaType.criteria == "whatsAppNo"
                          ? "92XXXXXXXXXX"
                          : criteriaType.criteria == "email"
                          ? "abc@xyz.com"
                          : criteriaType.criteria == "Status"
                          ? "Paid/Pending"
                          : ""
                      }
                      value={filter[criteriaType.criteria]}
                      onChange={(e) =>
                        setFilter({ [criteriaType.criteria]: e.target.value })
                      }
                    ></input>
                    <br />
                  </Col>
                </Row>
                <TableRequestAgentView
                  onChangeNoOfRows={onChangeNoOfRows}
                  post={paginatedPosts}
                />
                <Pagination
                  pageNumber={pageNumber}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  totalPages={total_pages}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  AllRequests: state.agentRequest.AllRequests,
  //Count: state.customerRequest.Count,
  Price: state.agentRequest.Price,
  User: state.agentRequest.User,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    // _FetchCount_Middleware: (onSuccess, onFailure) =>
    //   dispatch(FetchCount_Middleware(onSuccess, onFailure)),
    _FetchPrice_Middleware: (onSuccess, onFailure) =>
      dispatch(FetchPrice_Middleware(onSuccess, onFailure)),
    _FetchAllRequest_Middleware: (
      pageNumber,
      noOfRows,
      valuesAndKey,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        FetchAllRequest_Middleware(
          pageNumber,
          noOfRows,
          valuesAndKey,
          onSuccess,
          onFailure
        )
      ),

    FetchUser_Middleware: (cnic, onSuccess, onFailure) =>
      dispatch(FetchUser_Middleware(cnic, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(RequestAgentView);
