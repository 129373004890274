import axios from "axios";
import { connect } from "react-redux";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  Table,
} from "reactstrap";
import { useState, useEffect } from "react";
import { VerifyByCNIC } from "views/UserViews/MembershipForm/middleware";

const ViewForm = ({
  toggle,

  modal,
  detail,
  _VerifyByCNIC,
}) => {
  const [state, setState] = useState({});
  useEffect(() => {
    if (detail !== null && detail !== undefined) {
      //console.log(props.detail,"props.state")
      console.log("CLIENT DATA AT NEW PAGE", detail);
      setState({
        ...state,
        name: detail?.name,
        email: detail?.email,
        phone: detail?.phone,
        cnic: detail?.cnic,
        whatsAppNo: detail?.whatsAppNo,
        depositSlipNo: detail?.depositSlipNo,
        formNo: detail?.formNo,

        residental: detail?.residental?.title,
        price: detail?.residental?.price,
        formUrl: detail?.formUrl,
        depositSlipUrl: detail?.depositSlipUrl,
      });
    }
  }, [detail]);

  useEffect(() => {
    if (state.cnic?.length == 13) {
      _VerifyByCNIC(state?.cnic, onVerifySuccess, onVerifyFailure);
    }
  }, [state.cnic]);

  const onVerifySuccess = (data) => {
    setFormCount(data);
  };
  const onVerifyFailure = () => {};
  const [newArr, setNewArr] = useState([]);
  const [formCount, setFormCount] = useState([]);
  useEffect(() => {
    let tempArr = [];
    console.log(formCount, "hello");
    formCount.map((data, index) => {
      //  if (data.online + data.bank + data.agent < 5) {
      if (data.quantity > 0) {
        tempArr.push(data);
      }
    });
    setNewArr(tempArr);
  }, [formCount]);

  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader style={{ backgroundColor: "#d69929" }} toggle={toggle}>
        <h3 style={{ color: "white" }}>Applicant Information</h3>
      </ModalHeader>
      <ModalBody>
        {/* <Row>
          <Col lg="6" md="6" sm="2">
            <h2></h2>
          </Col>
        </Row> */}
        <Row>
          <Col lg="12" md="12" sm="6">
            {/* <label className="form-control-label" for="input-username">
                Purchased Form:
              </label> */}
            <h4>Purchased Forms:</h4>
          </Col>
          <Col lg="12" md="12" sm="6">
            <Table
              style={{ border: "1px solid lightgrey" }}
              className="align-items-center"
              responsive
            >
              <thead style={{ backgroundColor: "#d69929", color: "white" }}>
                <tr>
                  <th scope="col">Category</th>
                  <th scope="col">Quantity</th>
                  {/* <th scope="col">Price</th> */}
                </tr>
              </thead>
              <tbody>
                {newArr !== null && newArr !== undefined ? (
                  newArr.map((data, index) => {
                    return (
                      <tr>
                        <td> {data.title}</td>
                        <td>{data?.quantity}</td>
                        {/* <td>{data?.price}</td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th></th>
                    <th>
                      <h3>No data found</h3>
                    </th>
                    <th></th>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col lg="3" md="3" sm="6">
            <h4>Name:</h4>
          </Col>
          <Col lg="3" md="3" sm="6">
            {" "}
            <label className="form-control-label" for="input-username">
              {state?.name}
            </label>
            {/* <h4>{state?.name}</h4>{" "} */}
          </Col>
          <Col lg="3" md="3" sm="6">
            <h4>CNIC:</h4>{" "}
          </Col>
          <Col lg="3" md="3" sm="6">
            {" "}
            <label className="form-control-label" for="input-username">
              {state?.cnic}
            </label>
            {/* <h4>{state?.cnic}</h4>{" "} */}
          </Col>
        </Row>

        <Row>
          <Col lg="3" md="3" sm="6">
            <h4>Email:</h4>{" "}
          </Col>
          <Col lg="3" md="3" sm="6">
            {" "}
            <label className="form-control-label" for="input-username">
              {state?.email}
            </label>
            {/* <h4>{state?.email}</h4>{" "} */}
          </Col>
          <Col lg="3" md="3" sm="6">
            <h4>Phone:</h4>{" "}
          </Col>
          <Col lg="3" md="3" sm="6">
            {" "}
            <label className="form-control-label" for="input-username">
              {state?.phone}
            </label>
            {/* <h4>{state?.phone}</h4>{" "} */}
          </Col>
        </Row>

        <Row>
          <Col lg="3" md="3" sm="6">
            <h4>whatsAppNo:</h4>{" "}
          </Col>
          <Col lg="3" md="3" sm="6">
            {" "}
            <label className="form-control-label" for="input-username">
              {state?.whatsAppNo}
            </label>
            {/* <h4>{state?.whatsAppNo}</h4>{" "} */}
          </Col>
          <Col lg="3" md="3" sm="6">
            <h4>Form No:</h4>{" "}
          </Col>
          <Col lg="3" md="3" sm="6">
            <label className="form-control-label" for="input-username">
              {state?.formNo}
            </label>
            {/* <h4>{state?.formNo}</h4>{" "} */}
          </Col>
        </Row>

        <Row>
          {/* <Col lg="3" md="3" sm="6">
            <h4>DepositSlip No:</h4>{" "}
          </Col>
          <Col lg="3" md="3" sm="6">
          <label className="form-control-label" for="input-username">{state?.depositSlipNo}</label>
       
          </Col> */}
          <Col lg="3" md="3" sm="2">
            <h4>Plot Size:</h4>{" "}
          </Col>
          <Col lg="3" md="3" sm="4">
            {" "}
            <label className="form-control-label" for="input-username">
              {state?.residental}
            </label>
          </Col>
        </Row>

        {/* <Row>
          <Col lg="3" md="3" sm="2">
            <h4>Price:</h4>{" "}
          </Col>
          <Col lg="3" md="3" sm="4">
            {" "}
            <label className="form-control-label" for="input-username">
              {state?.price}
            </label>
          
          </Col>
        </Row> */}
        {/* <hr /> */}
        {/* <Row>
          <Col lg="6" md="6" sm="6">
            <h3>Form</h3>
            <br />
            <img
              height={"200px"}
              width={"200px"}
              src={
                state.formUrl !== ""
                  ? state.formUrl
                  : require("../../../../assets/img/logo.png").default
              }
            />
          </Col>
          
        </Row> */}
        <Row></Row>
      </ModalBody>
      <ModalFooter style={{ backgroundColor: "#d69929" }}>
        <Button
          style={{ backgroundColor: "#015652", color: "white" }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

//export default ViewForm;
const mapStateToProps = (state) => ({
  // CheckRes: state.staffAppList.CheckRes,
  CNIC_Response: state.CustomerMembershipForm.CNIC_Response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    // _PostAppList_Middleware: (body, onSuccess, onFailure) =>
    //   dispatch(PostAppList_Middleware(body, onSuccess, onFailure)),
    // _CheckForm_Middleware: (body, onSuccess, onFailure) =>
    //   dispatch(CheckForm_Middleware(body, onSuccess, onFailure)),
    _VerifyByCNIC: (body, onSuccess, onFailure) =>
      dispatch(VerifyByCNIC(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(ViewForm);
