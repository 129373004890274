import axios from "axios";
import {
  ApplicationDetail,
  FetchAllRequests,
  FetchCount,
  FetchPricing,
} from "../action";
import { BASEURL } from "config/api/URL";
import {
  APPLICATIONREQUEST_PATH,
  FETCH_ALL_REQUEST_PATH,
  FETCH_COUNT_PATH,
  FETCH_PRICE_PATH,
} from "../constant";

export const AppReqMiddleware = (body, onSuccess, onFailure) => (dispatch) => {
  dispatch(ApplicationDetail.Application());
  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${APPLICATIONREQUEST_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.data.status === true) {
        dispatch(ApplicationDetail.ApplicationSuccess(res.data));
        dispatch(onSuccess(res.data.data));
      }
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        // console.log(error.response.data);
        onFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};

export const FetchAllRequest_Middleware =
  (pageNumber, noOfRows, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchAllRequests.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_ALL_REQUEST_PATH}?page=${pageNumber}&limit=${noOfRows}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchAllRequests.FetchSuccess(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          // onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };

export const FetchCount_Middleware = (onSuccess, onFailure) => (dispatch) => {
  dispatch(FetchCount.Fetch());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${FETCH_COUNT_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      // console.log("COUNT API", res.data);
      if (res.status === 200) {
        dispatch(FetchCount.FetchSuccess(res.data.data));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
        // onFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};

export const FetchPrice_Middleware = (onSuccess, onFailure) => (dispatch) => {
  dispatch(FetchPricing.Fetch());
  axios
    .get(`${BASEURL}${FETCH_PRICE_PATH}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(FetchPricing.FetchSuccess(res.data.data.price));
        dispatch(onSuccess(res.data.message));
      }
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
        // onFailure(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};
