import axios from "axios";
import { OTPVerification } from "../action";
import { OTP_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const otpMiddleware =
  (body, email, OnSuccess, OnFailure) => (dispatch) => {
    dispatch(OTPVerification.Otp());

    let newbody = {
      email: email,
      code: body.code,
    };
    console.log("BODY", newbody);
    // let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${OTP_PATH}`, newbody, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("DATA AFTER VERIFFY", res.data);
        if (res.data.status === true) {
          dispatch(OTPVerification.OtpSuccess(res.data));
          dispatch(OnSuccess(res.data));
        }
      })
      .catch(function (error) {
        if (error.response) {
          OnFailure(error.response.data);
        } else if (error.request) {
        }
      });
  };
