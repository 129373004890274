import React from "react";
import moment from "moment/moment";

import "./KGC_AgentSingleVoucher.css";

class KGC_AgentSingleVoucher extends React.Component {
  render() {
    let data = this.props.chalanData;
    let cnic = data?.cnic?.split("");
    cnic?.splice(5, 0, "-");
    cnic?.splice(13, 0, "-");

    let d = new Date().toISOString().split("T")[0];
    var date = moment(d).format("DD-MM-YYYY");
    console.log("data test", data);
    return (
      <div style={{ margin: 0, position: "absolute", top: "5%", left: 10 }}>
        <page size="A4">
          <div
            style={{
              // marginTop: "0%",
              display: "flex",
              width: "1100px",
              height: "690px",
              border: "1px solid black",
              // margin: "10px 10px",
              // marginRight: "100px",
              // marginTop: "10px",
              // padding: " 20px 60px",
              fontSize: "16px",
              fontWeight: "bold",
              // lineHeight: "24px",
              fontFamily:
                "Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif",
              color: "rgb(33, 33, 33)",
              backgroundColor: "white",
            }}
          >
            <div style={{ width: "500px", border: "1px solid black" }}>
              <div class="regForm-header">
                <div class=" img_text1">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/imageKPK%20logo-01-1666954209159.png"
                    style={{
                      width: "100%",
                      height: "100%",
                      marginLeft: "-10px",
                      marginTop: "10px",
                    }}
                  />
                  <div style={{ marginTop: "0px" }}>
                    <span style={{ fontSize: "12px" }}> Date:</span>
                    <span>
                      <input
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: "1px soild black",
                          outline: "none",
                          background: "transparent",
                          width: "70%",
                          height: "15px",
                          fontSize: "14px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        type="text"
                        value={date}
                      />
                    </span>
                  </div>
                </div>
                <div class=" img_text">
                  <div
                    style={{
                      width: "100px",
                      height: "107px",
                      // paddingTop: "20px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "22px",
                    }}
                  >
                    <img
                      src={data?.realEstateLogo || ""}
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "0px",
                    }}
                  >
                    <div>
                      <h5 style={{ color: "black" }}>CUSTOMER COPY</h5>
                    </div>
                    <div>
                      <div style={{ border: "1px solid black" }}>
                        <small> Receipt No.</small>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          borderTop: "0px",
                          height: "21px",
                        }}
                      >
                        {data?.receiptNo}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="regForm-body" style={{ marginTop: "-80px" }}>
                <div class="section_one">
                  <div class="sub_header">
                    <small>
                      Applicant Personal Information for Registration.
                    </small>
                  </div>

                  <h4 className="headind4"></h4>
                  <div style={{ marginTop: "-15px" }}>
                    <div class="flex_margin mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex1" style={{ fontSize: "14px" }}>
                            Name:
                          </div>
                          <div class="flex8">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "14px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                                paddingTop: "5px",
                              }}
                              type="text"
                              value={data?.name || "asdasd"}
                            />
                          </div>
                        </span>
                      </small>
                    </div>

                    <div class="dflex" style={{ marginBottom: "16px" }}>
                      <small class="flex1">
                        <span>
                          <div style={{ fontSize: "14px" }}>
                            CNIC No.:&nbsp;
                            {cnic &&
                              cnic.length > 0 &&
                              cnic.map((item, index) => {
                                if (index !== 5 && index !== 13) {
                                  return <span class="cnic_col1">{item}</span>;
                                } else if (index === 5) {
                                  return (
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                  );
                                } else if (index === 13) {
                                  return (
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                  );
                                }
                              })}
                            {/* <span
                              class="cnic_col1"
                              style={{ marginLeft: "3px" }}
                            ></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1 bgcolor-gray">-</span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1 bgcolor-gray">-</span>
                            <span class="cnic_col1"></span> */}
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex" style={{ marginBottom: "12px" }}>
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            Cell/WhatsApp No:
                          </div>
                          <div class="flex5">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "14px",
                                fontSize: "14px",
                              }}
                              type="text"
                              value={data?.phone}
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            {" "}
                            Email (If available)
                          </div>
                          <div class="flex5">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value={data?.email}
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            {" "}
                            Amount PKR:{" "}
                          </div>
                          <div class="flex8">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value="2800/-"
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            Amount in Words:
                          </div>
                          <div class="flex6">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value="Two Thousand Eight Hundred"
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    {/* 
                    <div class="flex_margin">
                      <small class="flex2">
                        <span class="dflex">
                          <div class="flex7" style={{ fontSize: "14px" }}>
                            Amount PKR:
                          </div>
                          <div class="flex4">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                              // class="inputBorder"
                              type="text"
                              value="2800/-"
                            />
                          </div>
                        </span>
                      </small>
                      <small class="flex4">
                        <span class="dflex">
                          <div
                            class="flex4"
                            style={{
                              wordSpacing: "-1px",
                              fontSize: "14px",
                            }}
                          >
                            Amount in Words:
                          </div>
                          <div class="flex6">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "12px",
                                textAlign: "center",
                                fontSize: "9px",
                                wordSpacing: "-1px",
                                fontWeight: "bold",
                              }}
                              type="text"
                              value="Two Thousand Eight Hundred"
                            />
                          </div>
                        </span>
                      </small>
                    </div> */}
                  </div>
                </div>
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    marginTop: "-15px",
                  }}
                >
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {" "}
                        {data?.residental.title === "5 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 5 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "7 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 7 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "10 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 10 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "14 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 14 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental?.title?.includes("kanal") ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 1 Kanal</div>
                    </span>
                  </small>
                </div>
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "15px",
                    marginTop: "10px",
                    border: "1px solid black",
                    width: "95%",
                    height: "35px",
                    overflow: "hidden",
                    alignItems: "center",
                  }}
                >
                  <small class="flex1">
                    <span class="dflex">
                      <span
                        style={{
                          color: "red",
                          backgroundColor: "lightgray",
                          height: "40px",
                          padding: "15px 4px",
                          overflow: "hidden",
                        }}
                      >
                        BANK USE ONLY
                      </span>
                      <div style={{ padding: "15px 4px" }}> KGC Ref No.</div>
                    </span>
                  </small>
                </div> */}
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "8px",
                    width: "95%",
                  }}
                >
                  <small class="flex1">
                    <div>
                      <strong>TITLE: KGC BUILDERS</strong>
                    </div>
                    <div
                      style={{
                        // fontSize: "12px",
                        // color: "gray",
                        height: "30px",
                        overflow: "hidden",
                      }}
                    >
                      <strong>
                        A/C # {this.props.bankById?.bankAccountNo}
                      </strong>
                    </div>
                  </small>
                </div> */}
                <div
                  class="flex_margin text_al_center"
                  style={{
                    marginLeft: "5px",
                    fontSize: "12px",
                    marginTop: "-6px",
                    width: "95%",
                    padding: "4px 4px",
                    justifyContent: "space-between",
                  }}
                >
                  <div class=" text_al_center">
                    {/* <div
                      style={{
                        height: "60px",
                        width: "160%",
                        border: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "160%",
                      }}
                    >
                      <small>DEPOSITOR SIGN</small>
                    </div> */}
                  </div>
                  <div>
                    <div
                      style={{
                        height: "60px",
                        width: "145%",
                        borderBottom: "1px solid black",
                        marginLeft: "-40px",
                      }}
                    ></div>
                    <div
                      style={{
                        // width: "140%",
                        marginRight: "15px",
                      }}
                    >
                      <small>AGENT STAMP & SIGN</small>
                    </div>
                  </div>
                </div>
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "8px",
                    width: "95%",
                    border: "1px solid black",
                    color: "red",
                    padding: "4px 4px",
                  }}
                >
                  <small class="flex1">
                    <div>
                      {/* <strong> */}* THIS RECEIPT HAS TO BE PRESENTED AS
                      PROOF OF PAYMENT
                      {/* </strong> */}
                    </div>
                    <div>
                      {/* <strong> */}* FOR MORE DETAILS VISIT OUR WEBSITE OR
                      CONTACT OUR HELPLINE
                      {/* </strong> */}
                    </div>
                  </small>
                </div>
                <div
                  class="dflex"
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    marginTop: "15px",
                    marginBottom: "-5px",
                    width: "95%",
                    justifyContent: "space-around",
                    color: "blue",
                  }}
                >
                  <div>
                    <span>
                      <i class="fa fa-globe" aria-hidden="true"></i>
                    </span>
                    <span>
                      <small> www.khybergolfcity.com.pk</small>
                    </span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <span>
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    <span>
                      <small style={{ display: "flex" }}>
                        {" "}
                        +92 304-111-
                        <span style={{ marginTop: "-8px" }}>
                          <div
                            style={{
                              marginBottom: "-5px",
                              marginLeft: "3px",
                              marginTop: "-5px",
                            }}
                          >
                            5 4 2 7
                          </div>
                          <div>(KGCP)</div>
                        </span>
                      </small>
                    </span>
                  </div>
                </div>
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "2px",
                    width: "95%",
                    padding: "4px 4px",
                  }}
                >
                  <small
                    style={{
                      lineHeight: "12px",
                      height: "96px",
                      overflow: "hidden",
                    }}
                  >
                    {this.props.bankById?.footer}
                  </small>
                </div> */}
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    marginTop: "15px",
                    width: "95%",
                    height: "54px",
                    backgroundColor: "lightgray",
                  }}
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/imagefooter-1666958044644.jpg"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
            {/* part2 */}
            <div style={{ width: "500px", border: "1px solid black" }}>
              <div class="regForm-header">
                <div class=" img_text1">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/imageKPK%20logo-01-1666954209159.png"
                    style={{
                      width: "100%",
                      height: "100%",
                      marginLeft: "-10px",
                      marginTop: "10px",
                    }}
                  />
                  <div style={{ marginTop: "0px" }}>
                    <span style={{ fontSize: "12px" }}> Date:</span>
                    <span>
                      <input
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: "1px soild black",
                          outline: "none",
                          background: "transparent",
                          width: "70%",
                          height: "15px",
                          fontSize: "14px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        type="text"
                        value={date}
                      />
                    </span>
                  </div>
                </div>
                <div class=" img_text">
                  <div
                    style={{
                      width: "100px",
                      height: "107px",
                      // paddingTop: "20px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "22px",
                    }}
                  >
                    <img
                      src={data?.realEstateLogo || ""}
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "0px",
                    }}
                  >
                    <div>
                      <h5 style={{ color: "black" }}>KGC COPY</h5>
                    </div>
                    <div>
                      <div style={{ border: "1px solid black" }}>
                        <small> Receipt No.</small>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          borderTop: "0px",
                          height: "21px",
                        }}
                      >
                        {data?.receiptNo}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="regForm-body" style={{ marginTop: "-80px" }}>
                <div class="section_one">
                  <div class="sub_header">
                    <small>
                      Applicant Personal Information for Registration.
                    </small>
                  </div>

                  <h4 className="headind4"></h4>
                  <div style={{ marginTop: "-15px" }}>
                    <div class="flex_margin mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex1" style={{ fontSize: "14px" }}>
                            Name:
                          </div>
                          <div class="flex8">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "14px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                                paddingTop: "5px",
                              }}
                              type="text"
                              value={data?.name || "asdasd"}
                            />
                          </div>
                        </span>
                      </small>
                    </div>

                    <div class="dflex" style={{ marginBottom: "16px" }}>
                      <small class="flex1">
                        <span>
                          <div style={{ fontSize: "14px" }}>
                            CNIC No.:&nbsp;
                            {cnic &&
                              cnic.length > 0 &&
                              cnic.map((item, index) => {
                                if (index !== 5 && index !== 13) {
                                  return <span class="cnic_col1">{item}</span>;
                                } else if (index === 5) {
                                  return (
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                  );
                                } else if (index === 13) {
                                  return (
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                  );
                                }
                              })}
                            {/* <span
                              class="cnic_col1"
                              style={{ marginLeft: "3px" }}
                            ></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1 bgcolor-gray">-</span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1 bgcolor-gray">-</span>
                            <span class="cnic_col1"></span> */}
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex" style={{ marginBottom: "12px" }}>
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            Cell/WhatsApp No:
                          </div>
                          <div class="flex5">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "14px",
                                fontSize: "14px",
                              }}
                              type="text"
                              value={data?.phone}
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            {" "}
                            Email (If available)
                          </div>
                          <div class="flex5">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value={data?.email}
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            {" "}
                            Amount PKR:{" "}
                          </div>
                          <div class="flex8">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value="2800/-"
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            Amount in Words:
                          </div>
                          <div class="flex6">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value="Two Thousand Eight Hundred"
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    {/* 
                    <div class="flex_margin">
                      <small class="flex2">
                        <span class="dflex">
                          <div class="flex7" style={{ fontSize: "14px" }}>
                            Amount PKR:
                          </div>
                          <div class="flex4">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                              // class="inputBorder"
                              type="text"
                              value="2800/-"
                            />
                          </div>
                        </span>
                      </small>
                      <small class="flex4">
                        <span class="dflex">
                          <div
                            class="flex4"
                            style={{
                              wordSpacing: "-1px",
                              fontSize: "14px",
                            }}
                          >
                            Amount in Words:
                          </div>
                          <div class="flex6">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "12px",
                                textAlign: "center",
                                fontSize: "9px",
                                wordSpacing: "-1px",
                                fontWeight: "bold",
                              }}
                              type="text"
                              value="Two Thousand Eight Hundred"
                            />
                          </div>
                        </span>
                      </small>
                    </div> */}
                  </div>
                </div>
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    marginTop: "-15px",
                  }}
                >
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {" "}
                        {data?.residental.title === "5 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 5 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "7 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 7 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "10 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 10 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "14 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 14 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental?.title?.includes("kanal") ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 1 Kanal</div>
                    </span>
                  </small>
                </div>
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "15px",
                    marginTop: "10px",
                    border: "1px solid black",
                    width: "95%",
                    height: "35px",
                    overflow: "hidden",
                    alignItems: "center",
                  }}
                >
                  <small class="flex1">
                    <span class="dflex">
                      <span
                        style={{
                          color: "red",
                          backgroundColor: "lightgray",
                          height: "40px",
                          padding: "15px 4px",
                          overflow: "hidden",
                        }}
                      >
                        BANK USE ONLY
                      </span>
                      <div style={{ padding: "15px 4px" }}> KGC Ref No.</div>
                    </span>
                  </small>
                </div> */}
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "8px",
                    width: "95%",
                  }}
                >
                  <small class="flex1">
                    <div>
                      <strong>TITLE: KGC BUILDERS</strong>
                    </div>
                    <div
                      style={{
                        // fontSize: "12px",
                        // color: "gray",
                        height: "30px",
                        overflow: "hidden",
                      }}
                    >
                      <strong>
                        A/C # {this.props.bankById?.bankAccountNo}
                      </strong>
                    </div>
                  </small>
                </div> */}
                <div
                  class="flex_margin text_al_center"
                  style={{
                    marginLeft: "5px",
                    fontSize: "12px",
                    marginTop: "-6px",
                    width: "95%",
                    padding: "4px 4px",
                    justifyContent: "space-between",
                  }}
                >
                  <div class=" text_al_center">
                    {/* <div
                      style={{
                        height: "60px",
                        width: "160%",
                        border: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "160%",
                      }}
                    >
                      <small>DEPOSITOR SIGN</small>
                    </div> */}
                  </div>
                  <div>
                    <div
                      style={{
                        height: "60px",
                        width: "145%",
                        borderBottom: "1px solid black",
                        marginLeft: "-40px",
                      }}
                    ></div>
                    <div
                      style={{
                        // width: "140%",
                        marginRight: "15px",
                      }}
                    >
                      <small>AGENT STAMP & SIGN</small>
                    </div>
                  </div>
                </div>
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "8px",
                    width: "95%",
                    border: "1px solid black",
                    color: "red",
                    padding: "4px 4px",
                  }}
                >
                  <small class="flex1">
                    <div>
                      {/* <strong> */}* THIS RECEIPT HAS TO BE PRESENTED AS
                      PROOF OF PAYMENT
                      {/* </strong> */}
                    </div>
                    <div>
                      {/* <strong> */}* FOR MORE DETAILS VISIT OUR WEBSITE OR
                      CONTACT OUR HELPLINE
                      {/* </strong> */}
                    </div>
                  </small>
                </div>
                <div
                  class="dflex"
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    marginTop: "15px",
                    marginBottom: "-5px",
                    width: "95%",
                    justifyContent: "space-around",
                    color: "blue",
                  }}
                >
                  <div>
                    <span>
                      <i class="fa fa-globe" aria-hidden="true"></i>
                    </span>
                    <span>
                      <small> www.khybergolfcity.com.pk</small>
                    </span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <span>
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    <span>
                      <small style={{ display: "flex" }}>
                        {" "}
                        +92 304-111-
                        <span style={{ marginTop: "-8px" }}>
                          <div
                            style={{
                              marginBottom: "-5px",
                              marginLeft: "3px",
                              marginTop: "-5px",
                            }}
                          >
                            5 4 2 7
                          </div>
                          <div>(KGCP)</div>
                        </span>
                      </small>
                    </span>
                  </div>
                </div>
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "2px",
                    width: "95%",
                    padding: "4px 4px",
                  }}
                >
                  <small
                    style={{
                      lineHeight: "12px",
                      height: "96px",
                      overflow: "hidden",
                    }}
                  >
                    {this.props.bankById?.footer}
                  </small>
                </div> */}
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    marginTop: "15px",
                    width: "95%",
                    height: "55px",
                    backgroundColor: "lightgray",
                  }}
                ></div>
              </div>
            </div>
            {/* part3 */}
            <div style={{ width: "500px", border: "1px solid black" }}>
              <div class="regForm-header">
                <div class=" img_text1">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/imageKPK%20logo-01-1666954209159.png"
                    style={{
                      width: "100%",
                      height: "100%",
                      marginLeft: "-10px",
                      marginTop: "10px",
                    }}
                  />
                  <div style={{ marginTop: "0px" }}>
                    <span style={{ fontSize: "12px" }}> Date:</span>
                    <span>
                      <input
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: "1px soild black",
                          outline: "none",
                          background: "transparent",
                          width: "70%",
                          height: "15px",
                          fontSize: "14px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        type="text"
                        value={date}
                      />
                    </span>
                  </div>
                </div>
                <div class=" img_text">
                  <div
                    style={{
                      width: "100px",
                      height: "107px",
                      // paddingTop: "20px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "22px",
                    }}
                  >
                    <img
                      src={data?.realEstateLogo || ""}
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "0px",
                    }}
                  >
                    <div>
                      <h5 style={{ color: "black" }}>AGENT COPY</h5>
                    </div>
                    <div>
                      <div style={{ border: "1px solid black" }}>
                        <small> Receipt No.</small>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          borderTop: "0px",
                          height: "21px",
                        }}
                      >
                        {data?.receiptNo}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="regForm-body" style={{ marginTop: "-80px" }}>
                <div class="section_one">
                  <div class="sub_header">
                    <small>
                      Applicant Personal Information for Registration.
                    </small>
                  </div>

                  <h4 className="headind4"></h4>
                  <div style={{ marginTop: "-15px" }}>
                    <div class="flex_margin mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex1" style={{ fontSize: "14px" }}>
                            Name:
                          </div>
                          <div class="flex8">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "14px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                                paddingTop: "5px",
                              }}
                              type="text"
                              value={data?.name || "asdasd"}
                            />
                          </div>
                        </span>
                      </small>
                    </div>

                    <div class="dflex" style={{ marginBottom: "16px" }}>
                      <small class="flex1">
                        <span>
                          <div style={{ fontSize: "14px" }}>
                            CNIC No.:&nbsp;
                            {cnic &&
                              cnic.length > 0 &&
                              cnic.map((item, index) => {
                                if (index !== 5 && index !== 13) {
                                  return <span class="cnic_col1">{item}</span>;
                                } else if (index === 5) {
                                  return (
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                  );
                                } else if (index === 13) {
                                  return (
                                    <span class="cnic_col1 bgcolor-gray">
                                      -
                                    </span>
                                  );
                                }
                              })}
                            {/* <span
                              class="cnic_col1"
                              style={{ marginLeft: "3px" }}
                            ></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1 bgcolor-gray">-</span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1"></span>
                            <span class="cnic_col1 bgcolor-gray">-</span>
                            <span class="cnic_col1"></span> */}
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex" style={{ marginBottom: "12px" }}>
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            Cell/WhatsApp No:
                          </div>
                          <div class="flex5">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "14px",
                                fontSize: "14px",
                              }}
                              type="text"
                              value={data?.phone}
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            {" "}
                            Email (If available)
                          </div>
                          <div class="flex5">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value={data?.email}
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            {" "}
                            Amount PKR:{" "}
                          </div>
                          <div class="flex8">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value="2800/-"
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    <div class="dflex mb10">
                      <small class="flex1">
                        <span class="dflex">
                          <div class="flex3" style={{ fontSize: "14px" }}>
                            Amount in Words:
                          </div>
                          <div class="flex6">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                fontSize: "14px",
                                paddingBottom: "5px",
                              }}
                              type="text"
                              value="Two Thousand Eight Hundred"
                            />
                          </div>
                        </span>
                      </small>
                    </div>
                    {/* 
                    <div class="flex_margin">
                      <small class="flex2">
                        <span class="dflex">
                          <div class="flex7" style={{ fontSize: "14px" }}>
                            Amount PKR:
                          </div>
                          <div class="flex4">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "16px",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                              // class="inputBorder"
                              type="text"
                              value="2800/-"
                            />
                          </div>
                        </span>
                      </small>
                      <small class="flex4">
                        <span class="dflex">
                          <div
                            class="flex4"
                            style={{
                              wordSpacing: "-1px",
                              fontSize: "14px",
                            }}
                          >
                            Amount in Words:
                          </div>
                          <div class="flex6">
                            <input
                              style={{
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px soild black",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                                height: "12px",
                                textAlign: "center",
                                fontSize: "9px",
                                wordSpacing: "-1px",
                                fontWeight: "bold",
                              }}
                              type="text"
                              value="Two Thousand Eight Hundred"
                            />
                          </div>
                        </span>
                      </small>
                    </div> */}
                  </div>
                </div>
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    marginTop: "-15px",
                  }}
                >
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {" "}
                        {data?.residental.title === "5 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 5 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "7 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 7 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "10 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 10 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental.title === "14 marla" ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 14 Marla</div>
                    </span>
                  </small>
                  <small class="flex1">
                    <span class="dflex">
                      <span class="cnic_col">
                        {data?.residental?.title?.includes("kanal") ? (
                          <img
                            alt="..."
                            src={require("./check-solid.svg").default}
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div style={{ marginLeft: "7px" }}> 1 Kanal</div>
                    </span>
                  </small>
                </div>
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "15px",
                    marginTop: "10px",
                    border: "1px solid black",
                    width: "95%",
                    height: "35px",
                    overflow: "hidden",
                    alignItems: "center",
                  }}
                >
                  <small class="flex1">
                    <span class="dflex">
                      <span
                        style={{
                          color: "red",
                          backgroundColor: "lightgray",
                          height: "40px",
                          padding: "15px 4px",
                          overflow: "hidden",
                        }}
                      >
                        BANK USE ONLY
                      </span>
                      <div style={{ padding: "15px 4px" }}> KGC Ref No.</div>
                    </span>
                  </small>
                </div> */}
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "8px",
                    width: "95%",
                  }}
                >
                  <small class="flex1">
                    <div>
                      <strong>TITLE: KGC BUILDERS</strong>
                    </div>
                    <div
                      style={{
                        // fontSize: "12px",
                        // color: "gray",
                        height: "30px",
                        overflow: "hidden",
                      }}
                    >
                      <strong>
                        A/C # {this.props.bankById?.bankAccountNo}
                      </strong>
                    </div>
                  </small>
                </div> */}
                <div
                  class="flex_margin text_al_center"
                  style={{
                    marginLeft: "5px",
                    fontSize: "12px",
                    marginTop: "-6px",
                    width: "95%",
                    padding: "4px 4px",
                    justifyContent: "space-between",
                  }}
                >
                  <div class=" text_al_center">
                    {/* <div
                      style={{
                        height: "60px",
                        width: "160%",
                        border: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "160%",
                      }}
                    >
                      <small>DEPOSITOR SIGN</small>
                    </div> */}
                  </div>
                  <div>
                    <div
                      style={{
                        height: "60px",
                        width: "145%",
                        borderBottom: "1px solid black",
                        marginLeft: "-40px",
                      }}
                    ></div>
                    <div
                      style={{
                        // width: "140%",
                        marginRight: "15px",
                      }}
                    >
                      <small>AGENT STAMP & SIGN</small>
                    </div>
                  </div>
                </div>
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "8px",
                    width: "95%",
                    border: "1px solid black",
                    color: "red",
                    padding: "4px 4px",
                  }}
                >
                  <small class="flex1">
                    <div>
                      {/* <strong> */}* THIS RECEIPT HAS TO BE PRESENTED AS
                      PROOF OF PAYMENT
                      {/* </strong> */}
                    </div>
                    <div>
                      {/* <strong> */}* FOR MORE DETAILS VISIT OUR WEBSITE OR
                      CONTACT OUR HELPLINE
                      {/* </strong> */}
                    </div>
                  </small>
                </div>
                <div
                  class="dflex"
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    marginTop: "15px",
                    marginBottom: "-5px",
                    width: "95%",
                    justifyContent: "space-around",
                    color: "blue",
                  }}
                >
                  <div>
                    <span>
                      <i class="fa fa-globe" aria-hidden="true"></i>
                    </span>
                    <span>
                      <small> www.khybergolfcity.com.pk</small>
                    </span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <span>
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    <span>
                      <small style={{ display: "flex" }}>
                        {" "}
                        +92 304-111-
                        <span style={{ marginTop: "-8px" }}>
                          <div
                            style={{
                              marginBottom: "-5px",
                              marginLeft: "3px",
                              marginTop: "-5px",
                            }}
                          >
                            5 4 2 7
                          </div>
                          <div>(KGCP)</div>
                        </span>
                      </small>
                    </span>
                  </div>
                </div>
                {/* <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    marginTop: "2px",
                    width: "95%",
                    padding: "4px 4px",
                  }}
                >
                  <small
                    style={{
                      lineHeight: "12px",
                      height: "96px",
                      overflow: "hidden",
                    }}
                  >
                    {this.props.bankById?.footer}
                  </small>
                </div> */}
                <div
                  class="flex_margin"
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    marginTop: "15px",
                    width: "95%",
                    height: "55px",
                    backgroundColor: "lightgray",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </page>
      </div>
    );
  }
}

export default KGC_AgentSingleVoucher;
