import {
  CREATE_STAFF_CUSTOMER,
  CREATE_STAFF_CUSTOMER_SUCCESS,
  CREATE_STAFF_CUSTOMER_FAILURE,
  VERIFY_USER_BY_CNIC,
  VERIFY_USER_BY_CNIC_SUCCESS,
  VERIFY_USER_BY_CNIC_FAILURE,
} from "../constant";

export class CreateStaffCustomer {
  static create() {
    return {
      type: CREATE_STAFF_CUSTOMER,
    };
  }
  static createSuccess(response) {
    return {
      type: CREATE_STAFF_CUSTOMER_SUCCESS,
      payload: response,
    };
  }
  static createFailure(error) {
    return {
      type: CREATE_STAFF_CUSTOMER_FAILURE,
      error,
    };
  }
}

export class VerifyUser_ByCNIC {
  static verify() {
    return {
      type: VERIFY_USER_BY_CNIC,
    };
  }
  static verify_Success(response) {
    return {
      type: VERIFY_USER_BY_CNIC_SUCCESS,
      payload: response,
    };
  }
  static verify_Failure(error) {
    return {
      type: VERIFY_USER_BY_CNIC_FAILURE,
      error,
    };
  }
}
