import React, { useEffect, useRef, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Input,
  Container,
  Card,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import { useHistory } from "react-router-dom";
import validate from "components/Utilities Component/ValidationWrapper";
import { _CreateManagerCustomer } from "../middleware";
import swal from "sweetalert";
import { VerifyUserByCNIC } from "views/StaffViews/AddNewOrders/middleware";

const CreateCustomer = ({ CreateManagerCustomer, _VerifyUserByCNIC }) => {
  let history = useHistory();
  let testPhoneNum = /^(\+92|92)[0-9]{10}$/;
  const [isTrue, setIsTrue] = useState(false);
  const [validationPhoneNo, setValidationPhoneNo] = useState("");
  const [validationWhatsAppNo, setValidationWhatsAppNo] = useState("");
  const [isUserExist, setIsUserExist] = useState(false);
  const [checked, setChecked] = useState(false);
  const [formCount, setFormCount] = useState("");
  const [body, setBody] = useState({
    cnic: "",
    fullName: "",
    email: "",
    phone: "",
    whatsAppNo: "",
    profilePhoto: "ok",
    noOfApplication: 1,
  });
  const [error, setError] = useState({
    cnicError: null,
    nameError: null,
    emailError: null,
    phoneError: null,
    whatsAppNoError: null,
    noOfAppError: null,
  });

  const onSuccess = () => {
    swal({
      title: "Congratulations!",
      text: "Form is generated successfully",
      type: "success",
      icon: "success",
    }).then(function (isConfirm) {
      if (isConfirm) {
        history.push({
          pathname: "/user/requestManagerView",
        });
      } else {
      }
    });
  };
  const onFailure = (message) => {
    swal("Sorry!", message, "error");
    setIsTrue(false);
  };
  const onVerifySuccess = (data) => {
    setFormCount(data?.PreviousCount?.[0]?.sum);
    if (data.hasOwnProperty("user")) {
      setBody({
        ...body,
        fullName: data.user?.fullName,
        email: data.user?.email,
        phone: data.user?.phone,
        whatsAppNo: data.user?.whatsAppNo,
        profilePhoto: data.user?.custom?.profilePhoto,
      });
      setIsUserExist(true);
      setValidationWhatsAppNo("");
      setValidationPhoneNo("");
      setError({
        cnicError: null,
        nameError: null,
        emailError: null,
        phoneError: null,
        whatsAppNoError: null,
        noOfAppError: null,
      });
    } else if (data?.length === 0) {
      setBody({
        ...body,
        fullName: "",
        email: "",
        phone: "",
        whatsAppNo: "",
        profilePhoto: "ok.png",
      });
      setIsUserExist(false);
    }
  };
  const onVerifyFailure = () => {};
  const CheckFields = (name) => {
    if (name === "cnic") {
      if (body.cnic?.length == 13) {
        _VerifyUserByCNIC(body?.cnic, onVerifySuccess, onVerifyFailure);
      }
      setError({
        ...error,
        cnicError: validate("CNIC", body.cnic),
      });
    } else if (name === "name") {
      setError({
        ...error,
        nameError: validate("required", body.fullName),
      });
    } else if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", body.email),
      });
    } else if (name === "mobile") {
      setError({
        ...error,
        phoneError: validate("phNumber", body.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("whatsappNumber", body.whatsAppNo),
      });
    } else if (name === "noOfApp") {
      setError({
        ...error,
        noOfAppError: validate("required", body.noOfApplication),
      });
    }
  };
  const handleSendData = () => {
    setError({
      ...error,
      nameError: validate("required", body.fullName),
      cnicError: validate("CNIC", body.cnic),
      emailError: validate("email", body.email),
      phoneError: validate("phNumber", body.phone),
      whatsAppNoError: validate("whatsappNumber", body.whatsAppNo),
      noOfAppError: validate("required", body.noOfApplication),
    });
    checkNumber();
    if (
      body.fullName !== "" &&
      body.cnic?.length == 13 &&
      error.emailError == null &&
      testPhoneNum.test(body.phone) &&
      testPhoneNum.test(body.whatsAppNo) &&
      body.noOfApplication > 0
    ) {
      setIsTrue(true);
      let body1 = {
        cnic: body.cnic,
        fullName: body.fullName,
        email: body.email,
        phone: `+${body.phone}`,
        whatsAppNo: `+${body.whatsAppNo}`,
        profilePhoto: "ok",
        noOfApplication: parseInt(body.noOfApplication),
      };
      CreateManagerCustomer(body1, onSuccess, onFailure);
    }
  };
  const handleSendExistData = () => {
    setIsTrue(true);
    let body1 = {
      cnic: body.cnic,
      fullName: body.fullName,
      email: body.email,
      phone: body.phone,
      whatsAppNo: body.whatsAppNo,
      profilePhoto: "ok",
      noOfApplication: parseInt(body.noOfApplication),
    };
    CreateManagerCustomer(body1, onSuccess, onFailure);
  };
  const checkNumber = () => {
    if (!testPhoneNum.test(body.phone) && error.phoneError == null) {
      setValidationPhoneNo("Enter number in valid form");
    } else {
      setValidationPhoneNo("");
    }
    if (!testPhoneNum.test(body.whatsAppNo) && error.whatsAppNoError == null) {
      setValidationWhatsAppNo("Enter number in valid form");
    } else {
      setValidationWhatsAppNo("");
    }
  };
  const handleChecked = () => {
    setChecked(!checked);
  };
  useEffect(() => {
    setBody({ ...body, whatsAppNo: checked == true ? body.phone : "" });
  }, [checked]);
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="4">
                    <h3 className="mb-0">Customer Detail Form</h3>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="4"></Col>
                  <Col lg="2" md="2" sm="6" xs="4">
                    <label className="form-control-label" for="input-username">
                      Forms Available
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={
                        formCount !== "" && formCount !== undefined
                          ? 30 - formCount
                          : 30
                      }
                      disabled={true}
                    ></input>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      CNIC <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Customer CNIC"
                      value={body.cnic}
                      maxLength={13}
                      onBlur={() => CheckFields("cnic")}
                      autoComplete="off"
                      onChange={(e) =>
                        setBody({ ...body, cnic: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></input>
                    {error.cnicError !== "" && error.cnicError !== null && (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.cnicError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Customer Name <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Customer Name .."
                      onBlur={() => CheckFields("name")}
                      value={body.fullName}
                      autoComplete="off"
                      disabled={isUserExist}
                      onChange={(e) =>
                        setBody({ ...body, fullName: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (!/^[A-Za-z ]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></input>
                    {error.nameError !== "" && error.nameError !== null && (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.nameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Phone No. <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="92xxxxxxxxxx"
                      value={body.phone}
                      maxLength={12}
                      onBlur={() => CheckFields("mobile")}
                      onChange={(e) =>
                        setBody({ ...body, phone: e.target.value })
                      }
                      autoComplete="off"
                      disabled={isUserExist || checked}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></input>
                    {error.phoneError !== "" && error.phoneError !== null ? (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.phoneError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    ) : validationPhoneNo !== "" ? (
                      <small>
                        <span style={{ color: "red" }}>
                          {validationPhoneNo}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Email
                    </label>
                    <input
                      type="email"
                      id="input-username"
                      className="form-control"
                      placeholder="Enter Email .."
                      value={body.email}
                      autoComplete="off"
                      disabled={isUserExist}
                      onBlur={() => CheckFields("email")}
                      onChange={(e) =>
                        setBody({ ...body, email: e.target.value })
                      }
                    ></input>
                    {error.emailError !== "" && error.emailError !== null && (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.emailError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      WhatsApp No. <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="92xxxxxxxxxx"
                      value={body.whatsAppNo}
                      maxLength={12}
                      onBlur={() => CheckFields("whatsAppNo")}
                      onChange={(e) =>
                        setBody({ ...body, whatsAppNo: e.target.value })
                      }
                      autoComplete="off"
                      disabled={isUserExist || checked}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></input>
                    {error.whatsAppNoError !== "" &&
                    error.whatsAppNoError !== null ? (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.whatsAppNoError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    ) : validationWhatsAppNo !== "" ? (
                      <small>
                        <span style={{ color: "red" }}>
                          {validationWhatsAppNo}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    ) : (
                      ""
                    )}
                    {body.phone?.length == 12 ? (
                      <div>
                        <input
                          type="checkbox"
                          onClick={handleChecked}
                          disabled={isUserExist}
                        />
                        <small> Same as Phone</small>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col lg="4" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Num. Of Applications
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      onBlur={() => CheckFields("noOfApp")}
                      autoComplete="off"
                      onChange={(e) => {
                        const regex = /\b([1-9]|[12][0-9]|3[00])\b/;
                        if (
                          e.target.value === "" ||
                          regex.test(e.target.value)
                        ) {
                          setBody({ ...body, noOfApplication: e.target.value });
                        }
                      }}
                      value={body.noOfApplication}
                    ></input>
                    {error.noOfAppError !== "" && error.noOfAppError !== null && (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.noOfAppError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <br />
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      id="save"
                      onClick={
                        isUserExist ? handleSendExistData : handleSendData
                      }
                      disabled={isTrue}
                    >
                      <span className="btn-inner--icon">Submit</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  // PaymentThroughData: state.paymentReceipt.PaymentThroughData,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    CreateManagerCustomer: (body, onSuccess, onFailure) =>
      dispatch(_CreateManagerCustomer(body, onSuccess, onFailure)),
    _VerifyUserByCNIC: (cnic, OnSuccess, OnFailure) =>
      dispatch(VerifyUserByCNIC(cnic, OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(CreateCustomer);
