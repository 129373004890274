import { connect } from "react-redux";
import StaffLogin from "./StaffLogin";
import { StaffLoginMiddleware } from "../middleware";
const mapStateToProps = (state) => ({
  isLoading: state.staffLogin.isLoading,
  isError: state.staffLogin.isError,
  Data: state.staffLogin.Data,
  Error: state.staffLogin.error,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    StaffLoginMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(StaffLoginMiddleware(body, OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(StaffLogin);
