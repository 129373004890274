

import { 
  BANKLIST,BANKLIST_SUCCESS,BANKLIST_FAILURE,
  BANKDELETE,BANKDELETE_FAILURE,BANKDELETE_SUCCESS
 
} from "../constant.js";

export class BankDeleteDetail {
  static BankDelete() {
    return {
      type: BANKDELETE
  }
}
  static BankDelete_Success(response) {
    return {
      type: BANKDELETE_SUCCESS,
      payload: response,
    };
  }
  static BankDelete_Failure(error) {
    return {
      type: BANKDELETE_FAILURE,
      error,
    };
  }
 
}

export class BankListDetail {
  static BankList() {
    return {
      type: BANKLIST
  }
}
  static BankList_Success(response) {
    return {
      type: BANKLIST_SUCCESS,
      payload: response,
    };
  }
  static BankList_Failure(error) {
    return {
      type: BANKLIST_FAILURE,
      error,
    };
  }
 
}

