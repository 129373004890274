import {
  APP_REQUEST_TOTAL_COUNT,
  APP_REQUEST_TOTAL_COUNT_SUCCESS,
  APP_REQUEST_TOTAL_COUNT_FAILURE,
  APPREQUEST,
  APPREQUEST_FAILURE,
  APPREQUEST_SUCCESS,
  BANKAPP,
  BANKAPP_FAILURE,
  BANKAPP_SUCCESS,
  BANK_APP_TOTAL_COUNT,
  BANK_APP_TOTAL_COUNT_SUCCESS,
  BANK_APP_TOTAL_COUNT_FAILURE,
  CUSTOMER_REQ_TOTAL_COUNT,
  CUSTOMER_REQ_TOTAL_COUNT_SUCCESS,
  CUSTOMER_REQ_TOTAL_COUNT_FAILURE,
  REAL_STATE_TOTAL_COUNT,
  REAL_STATE_TOTAL_COUNT_SUCCESS,
  REAL_STATE_TOTAL_COUNT_FAILURE,
  AGENTAPP,
  AGENTAPP_FAILURE,
  AGENTAPP_SUCCESS,
  CUSTOMERAPP,
  CUSTOMERAPP_FAILURE,
  CUSTOMERAPP_SUCCESS,
} from "../constant";

export class Total_RealStateReqDetail {
  static Fetch() {
    return {
      type: REAL_STATE_TOTAL_COUNT,
    };
  }
  static Fetch_Success(response) {
    return {
      type: REAL_STATE_TOTAL_COUNT_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: REAL_STATE_TOTAL_COUNT_FAILURE,
      error,
    };
  }
}

export class Total_CustomerReqDetail {
  static Fetch() {
    return {
      type: CUSTOMER_REQ_TOTAL_COUNT,
    };
  }
  static Fetch_Success(response) {
    return {
      type: CUSTOMER_REQ_TOTAL_COUNT_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: CUSTOMER_REQ_TOTAL_COUNT_FAILURE,
      error,
    };
  }
}

export class CustomerAppDetail {
  static CustomerApp() {
    return {
      type: CUSTOMERAPP,
    };
  }
  static CustomerApp_Success(response) {
    return {
      type: CUSTOMERAPP_SUCCESS,
      payload: response,
    };
  }
  static CustomerApp_Failure(error) {
    return {
      type: CUSTOMERAPP_FAILURE,
      error,
    };
  }
}
export class AgentAppDetail {
  static AgentApp() {
    return {
      type: AGENTAPP,
    };
  }
  static AgentApp_Success(response) {
    return {
      type: AGENTAPP_SUCCESS,
      payload: response,
    };
  }
  static AgentApp_Failure(error) {
    return {
      type: AGENTAPP_FAILURE,
      error,
    };
  }
}
export class BankAppDetail {
  static BankApp() {
    return {
      type: BANKAPP,
    };
  }
  static BankApp_Success(response) {
    return {
      type: BANKAPP_SUCCESS,
      payload: response,
    };
  }
  static BankApp_Failure(error) {
    return {
      type: BANKAPP_FAILURE,
      error,
    };
  }
}
export class AppReqDetail {
  static AppRequest() {
    return {
      type: APPREQUEST,
    };
  }
  static AppRequest_Success(response) {
    return {
      type: APPREQUEST_SUCCESS,
      payload: response,
    };
  }
  static AppRequest_Failure(error) {
    return {
      type: APPREQUEST_FAILURE,
      error,
    };
  }
}

export class Total_AppReqDetail {
  static AppRequest() {
    return {
      type: APP_REQUEST_TOTAL_COUNT,
    };
  }
  static AppRequest_Success(response) {
    return {
      type: APP_REQUEST_TOTAL_COUNT_SUCCESS,
      payload: response,
    };
  }
  static AppRequest_Failure(error) {
    return {
      type: APP_REQUEST_TOTAL_COUNT_FAILURE,
      error,
    };
  }
}

export class Total_BankAppDetail {
  static Fetch() {
    return {
      type: BANK_APP_TOTAL_COUNT,
    };
  }
  static Fetch_Success(response) {
    return {
      type: BANK_APP_TOTAL_COUNT_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: BANK_APP_TOTAL_COUNT_FAILURE,
      error,
    };
  }
}