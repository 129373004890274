import React, { useEffect } from "react";
import swal from "sweetalert";
import { useState } from "react";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router";


const TableOfAgentStaffList = (props) => {



  let history = useHistory();


  




  const dateFunction = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Karachi",
    });
    return nDate;
  };
  

  // useEffect(() => {
  //   if (props.GetUserJWTLogin === false) {
  //     localStorage.removeItem("auth");
  //     window.location.href = "/auth/login";
  //   } else {
  //     if (props.paginatedPosts !== null && props.paginatedPosts !== undefined) {
  //       let str = toString(props.paginatedPosts.Datetime);
  //       // str = str.split("T");
  //       for (let i = 0; i < props.paginatedPosts.length; i++) {
  //         if (
  //           props.paginatedPosts.Agentname == props.state.agentName &&
  //           props.state.date === ""
  //         ) {
  //         } else if (
  //           str[0] == props.state.date &&
  //           props.state.agentName === ""
  //         ) {
  //         } else if (
  //           str[0] == props.state.date &&
  //           props.paginatedPosts.Agentname == props.state.agentName
  //         ) {
  //         } else if (
  //           props.state.agentName === "" &&
  //           props.state.date === "" &&
  //           (props.paginatedPosts.teamid == props.teamId ||
  //             props.teamId == null)
  //         ) {
  //         }
  //       }
  //     }
  //   }
  // }, [props.paginatedPosts]);

 

const onEdit=(e)=>{
 
    
    history.push({
      pathname:'/user/AgentStaff',
      state:e
    })

}
 const onDelete=(e)=>{
  swal({
    title: "Warning",
    text: "Are You Sure You Want To Delete This Agent?",
    icon: "warning",
     buttons : true,
  }).then((isOk) => {
    if (isOk) {
      let id=e;
      props.DeleteAgentStaffMiddleware(id,onSuccessDelete,onFailureDelete)
    }
  });

 }
const verify=(post)=>{

  swal({
    title: "Warning!",
    text: "Are You Sure You Want To verified This Agent?",
    icon: "warning",
     buttons : true,
  }).then((isOk) => {
    if (isOk) {
      let id=post?.realEstateStaffProfile?.id
      props.AgentStaffVerifyMiddleware(id,onSuccess, onFailure)
}
});

 }
 const onSuccessDelete=()=>{
  swal({
    title: "Successfully!",
    text: "Agent deleted successfully",
    icon: "success",
    // buttons : true,
  }).then((isOk) => {
    if (isOk) {
      
    }
  });
}
const onFailureDelete=(e)=>{
  swal("Unsuccess",e.message,"warning")
}

const onSuccess=()=>{
  swal({
    title: "Successfully!",
    text: "Agent Staff Verify successfully",
    icon: "success",
    // buttons : true,
  }).then((isOk) => {
    if (isOk) {
      
    }
  });
}
const onFailure=(e)=>{
  swal("Unsuccess",e.message,"warning")
}
  return (
    <>
   

      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929", color:"white" }} >
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Name</th>
            <th scope="col">CNIC</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col">Created By</th>
            <th scope="col">Status</th>
           
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.paginatedPosts !== null &&
          props.paginatedPosts !== undefined ? (
            props.paginatedPosts.map((posts, index) => {
              return (
                <tr>
                  <td>{index+1}</td>
                  <td>{posts?.fullName}</td>
                  <td>{posts?.cnic}</td>
                  <td>{posts?.phone}</td>
                  <td>{posts?.email}</td>
                  <td>{posts?.realEstateStaffProfile?.createdByUser?.fullName}</td>
                  <td>{posts?.isVerified==false ? (
                      <span className="badge badge-danger">
                      Not Verified
                      </span>
                    ) : posts?.isVerified==true ? (
                      <span className="badge badge-success">
                        Verified
                      </span>
                    ) : (
                      ""
                    )}</td>

              
                 <td>
                 <Button
                      style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                    onClick={() => onEdit(posts)}
                      id="info"
                   
                    >
                      <span className="btn-inner--icon">
                       Edit
                      </span>
                    </Button>
                    <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={()=> onDelete(posts?.realEstateStaffProfile?.id)}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                      Delete
                      </span>
                    </Button>
                      <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={()=> verify(posts)}
                      id="info"
                      disabled={posts.isVerified==true}
                    >
                      <span className="btn-inner--icon">
                      verify
                      </span>
                    </Button>
                      {/* <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={()=> verify(posts)}
                      id="info"
                      disabled={posts.isVerified==false}
                    >
                      <span className="btn-inner--icon">
                      unverify
                      </span>
                    </Button> */}
                 </td>
                </tr>
              );
            }
            )
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th><h3>No data found</h3></th>
              <th></th>
            </tr>
          )}
          
        </tbody>
      </Table>
    </>
  );
};
export default TableOfAgentStaffList;
