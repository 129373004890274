import axios from "axios";
import { ClientDetail } from "../action/index";

import { CLIENT_PATH } from "../constant";

import { BASEURL } from "config/api/URL";

export const showClientDetail = (body, OnSuccess, OnFailure) => (dispatch) => {
  dispatch(ClientDetail.Client());

  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${CLIENT_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.data.status === true) {
        dispatch(ClientDetail.Client_Success(res.data.response));

        dispatch(OnSuccess(res.data.message));
      } else {
        dispatch(ClientDetail.Client_Failure(res.data.message));
        dispatch(OnFailure(res.data.message));
      }
    })
    .catch((error) => dispatch(ClientDetail.Client_Failure(error)));
};
