import axios from "axios";
import { GetpaymentThrough, CreatePaymentReceipt } from "../action";
import { BASEURL } from "config/api/URL";
import {
  GET_PAYMENT_THROUGH_PATH,
  CREATE_PAYMENT_RECEIPT_PATH,
} from "../constant";

export const _GetpaymentThrough = (onSuccess, onFailure) => (dispatch) => {
  dispatch(GetpaymentThrough.PaymentThrough());
  let token = localStorage.getItem("auth");
  axios
    .get(`${BASEURL}${GET_PAYMENT_THROUGH_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.data.status === true) {
        dispatch(GetpaymentThrough.PaymentThrough_Success(res.data.response));
        dispatch(onSuccess(res.data.message));
      } else {
        dispatch(GetpaymentThrough.PaymentThrough_Failure(res.data.message));
        dispatch(onFailure(res.data.message));
      }
    })
    .catch((error) =>
      dispatch(GetpaymentThrough.PaymentThrough_Failure(error))
    );
};

export const _CreatePaymentReceipt =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(CreatePaymentReceipt.createReceipt());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CREATE_PAYMENT_RECEIPT_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            CreatePaymentReceipt.createReceipt_Success(res.data.response)
          );
          dispatch(onSuccess(res.data.message));
        } else {
          dispatch(
            CreatePaymentReceipt.createReceipt_Failure(res.data.message)
          );
          dispatch(onFailure(res.data.message));
        }
      })
      .catch((error) =>
        dispatch(CreatePaymentReceipt.createReceipt_Failure(error))
      );
  };
