import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Label,
  Col,
} from "reactstrap";
import validate from "components/Utilities Component/ValidationWrapper";
import Logo from "assets/img/whiteee1.png";
import { connect } from "react-redux";
import { VerifyForgotPass_Code } from "../middleware";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";

const CustomerCodeVerify = ({ _VerifyForgotPass_Code }) => {
  let history = useHistory();
  const location = useLocation();
  let string = location?.state?.string;
  const [body, setBody] = useState({
    code: "",
  });

  const [error, setError] = useState({
    codeError: "",
  });
  const CheckFields = (name) => {
    if (name === "code") {
      setError({
        ...error,
        codeError: validate("required", body.code),
      });
    }
  };
  const onSuccess = (res) => {
    swal({
      title: "Congratulations!",
      text: "Verified Successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/auth/cuChangePassword";
        history.push({
          pathname: path,
          state: {
            token: res.data.token,
            string: string,
          },
        });
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const handleDispatch = () => {
    if (body.code !== "") {
      _VerifyForgotPass_Code(body, onSuccess, onFailure);
    }
  };
  return (
    <>
      {/* <NotificationAlert ref={notify} /> */}
      <Col lg="5" md="7">
        <Card className="customLogin">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img height="150px" width="150px" src={Logo} /> <br />
              <h3 style={{ color: "white" }}>
                <b>Verify Code</b>
              </h3>
            </div>
            <Form role="form">
              <Label
                style={{ color: "white" }}
                className="form-control-label"
                for="input-username"
              >
                Account verification code for khybergolfcity
              </Label>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Enter your code here"
                    type="text"
                    onBlur={() => CheckFields("code")}
                    value={body.code}
                    onChange={(e) => setBody({ ...body, code: e.target.value })}
                  />
                </InputGroup>
                {error.codeError !== "" && error.codeError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.codeError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  onClick={handleDispatch}
                >
                  Verify
                </Button>
              </div>
            </Form>

            <Row className="mt-3 text-center">
              {/* <Col xs="12">
                <a className="text-light" href="/auth/cuChangePassword">
                  <small style={{ color: "black" }}>Change pass</small>
                </a>
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  // GetUserJWTLogin: state.JwtCredential.GetUserJWTLogin
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _VerifyForgotPass_Code: (body, onSuccess, onFailure) =>
      dispatch(VerifyForgotPass_Code(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(CustomerCodeVerify);
