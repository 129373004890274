import {
  CREATE_QUERY,
  CREATE_QUERY_SUCCESS,
  CREATE_QUERY_FAILURE,
  CREATE_QUERY_PATH,
} from "../constant.js";

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: {},
  Response: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case CREATE_QUERY:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Response: null,
        error: {},
      };
    case CREATE_QUERY_SUCCESS:
      return {
        ...states,
        isLoading: false,
        Response: action.payload,
      };
    case CREATE_QUERY_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
