import {
 
  AGENTLIST,AGENTLIST_SUCCESS,AGENTLIST_FAILURE, 
  AGENTSTAFFDELETE,AGENTSTAFFDELETE_SUCCESS,AGENTSTAFFDELETE_FAILURE,
  AGENTSTAFFVERIFY,AGENTSTAFFVERIFY_FAILURE,AGENTSTAFFVERIFY_SUCCESS


  
} from "../constant";
const INITIAL_STATE = {

  responseAgent:null,
 del:null,
  verify:null

 

 
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
        case AGENTSTAFFVERIFY:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          verify: null,
          error: {},
        };
      case AGENTSTAFFVERIFY_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          verify: action.payload,
        };
      case AGENTSTAFFVERIFY_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };

    case AGENTSTAFFDELETE:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          del: null,
          error: {},
        };
      case AGENTSTAFFDELETE_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          del: action.payload,
        };
      case AGENTSTAFFDELETE_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


  case AGENTLIST:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          responseAgent: null,
          error: {},
        };
      case AGENTLIST_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          responseAgent: action.payload,
        };
      case AGENTLIST_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };

     
  }
};
