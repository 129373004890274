import { connect } from "react-redux";

import Branch from "./Branch";
import { InsertBranchMiddleware,EditBranchMiddleware} from "../middleware";
import { GetBankListMiddleware } from "views/AdminView/BankList/middleware";


const mapStateToProps = (state) => ({

  branchRes:state.branch.branchRes,
edit:state.branch.edit,
response:state.bankList.response,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    EditBranchMiddleware:(id,body,onSuccess,onFailure)=>
    dispatch(EditBranchMiddleware(id, body, onSuccess, onFailure)),
    InsertBranchMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(InsertBranchMiddleware(body, OnSuccess, OnFailure)),
      GetBankListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)),
      
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Branch);
