export const FORM_LIST = "FORM_LIST";
export const FORM_LIST_SUCCESS = "FORM_LIST_SUCCESS";
export const FORM_LIST_FAILURE = "FORM_LIST_FAILURE";
export const FORM_LIST_PATH = "bank-manual-flow/formlist";

export const FETCH_BANKS_BY_ID = "FETCH_BANKS_BY_ID";
export const FETCH_BANKS_BY_ID_SUCCESS = "FETCH_BANKS_BY_ID_SUCCESS";
export const FETCH_BANKS_BY_ID_FAILURE = "FETCH_BANKS_BY_ID_FAILURE";
export const FETCH_BANKS_BY_ID_PATH = "bank-manual-flow/bank";

export const LAUNCHED_FROMS = "LAUNCHED_FROMS";
export const LAUNCHED_FROMS_SUCCESS = "LAUNCHED_FROMS_SUCCESS";
export const LAUNCHED_FROMS_FAILURE = "LAUNCHED_FROMS_FAILURE";
export const LAUNCHED_FROMS_PATH = "bank-manual-flow/lunched-forms";
