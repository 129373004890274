

import { 
  AGENTLIST,AGENTLIST_SUCCESS,AGENTLIST_FAILURE,
   AGENTVERIFY,AGENTVERIFY_FAILURE,AGENTVERIFY_SUCCESS
 
} from "../constant.js";

export class AgentVerifyDetail {
  static AgentVerify() {
    return {
      type: AGENTVERIFY,
    };
  }
  static AgentVerify_Success(response) {
    return {
      type: AGENTVERIFY_SUCCESS,
      payload: response,
    };
  }
  static AgentVerify_Failure(error) {
    return {
      type: AGENTVERIFY_FAILURE,
      error,
    };
  }
 
}



export class AgentListDetail {
  static Agentlist() {
    return {
      type: AGENTLIST,
    };
  }
  static Agentlist_Success(response) {
    return {
      type: AGENTLIST_SUCCESS,
      payload: response,
    };
  }
  static Agentlist_Failure(error) {
    return {
      type: AGENTLIST_FAILURE,
      error,
    };
  }
 
}

