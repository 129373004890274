import { connect } from "react-redux";

import BankList from "./BankList";
import { GetBankListMiddleware,DeleteBankMiddleware} from "../middleware";


const mapStateToProps = (state) => ({

  response:state.bankList.response,
  del:state.bankList.del,


 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    DeleteBankMiddleware:(id,onSuccess,onFailure)=>
    dispatch(DeleteBankMiddleware(id,onSuccess,onFailure)),
    GetBankListMiddleware: (pageNumber, noOfRows, onSuccess, onFailure) =>
      dispatch(GetBankListMiddleware(pageNumber, noOfRows, onSuccess, onFailure)),
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(BankList);
