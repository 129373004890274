import axios from "axios";
import { BankDetail,BankEditDetail } from "../action/index";
import { BANK_PATH,BANKEDIT_PATH } from "../constant";
import { BASEURL } from "config/api/URL";
export const EditBankMiddleware = (id,body, OnSuccess, OnFailure) => (dispatch) => {
 
 
   dispatch(BankEditDetail.BankEdit());
 
   let token = localStorage.getItem("auth");
   axios
     .patch(`${BASEURL}${BANKEDIT_PATH}/${id}`, body, {
       headers: {
         "Content-Type": "application/json",
         Authorization: `bearer ${token}`,
       },
     })
     .then((res) => {
       
       if (res.data.status === true) {
         dispatch(BankEditDetail.BankEdit_Success(res.data));
         dispatch(OnSuccess(res.data.message));
       } 
       
     })
     .catch(function (error) {
       if (error.response) {
        
         OnFailure(error.response.data)
       } else if (error.request) {
       } else {
       }
     });
 };
 


export const InsertBankMiddleware = (body, OnSuccess, OnFailure) => (dispatch) => {

  dispatch(BankDetail.Bank());

  let token = localStorage.getItem("auth");
  axios
    .post(`${BASEURL}${BANK_PATH}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      // console.log(res.data);
      if (res.data.status === true) {
        dispatch(BankDetail.Bank_Success(res.data));
        dispatch(OnSuccess(res.data.message));
      } 
      // else {
      //   dispatch(UserSignUp.SignUpFailure(res.data.message));
      //   dispatch(OnFailure(res.data.message));
      // }
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        // console.log(error.response.data);
        OnFailure(error.response.data)
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
      }
    });
};
