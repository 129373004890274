import axios from "axios";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CardBody,
  Card,
  Table,
  CardHeader,
  Col,
  Row,
  Label,
  Input,
} from "reactstrap";
import { useState, useEffect } from "react";
// import { BASEURL, RECORDING_BASE_URL } from "config/api/URL";
// import { RECORDING_PATH } from "../constant";
import { RECORDING_BASE_URL } from "config/api/URL";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import swal from "sweetalert";
const PriceUpdateModal = ({
  viewReqList,
  modal,
  toggle,
  oldPrice,
  _UpdateFormPricing_Middleware,
}) => {
  const [updatePrice, setUpdatePrice] = useState("");

  const onSuccess = () => {
    swal("Price Update successfully!", "", "success");
    toggle();
  };
  const onFailure = () => {
    swal("Sorry!", "Something went wrong", "error");
  };
  const handleUpdate = () => {
    if (updatePrice !== "") {
      _UpdateFormPricing_Middleware(
        { price: parseInt(updatePrice) },
        onSuccess,
        onFailure
      );
    } else swal("Sorry!", "Price Required", "error");
  };
  useEffect(() => {
    setUpdatePrice(oldPrice);
  }, [oldPrice]);
  return (
    <Modal size="sm" isOpen={modal} toggle={toggle}>
      <ModalHeader style={{ backgroundColor: "#d69929" }}>
        <h3 style={{ color: "white" }}>Update Price</h3>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col lg="12" md="12" sm="12">
            <Label>Price:</Label>
            <Input
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9-+]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              id="input-username"
              className="form-control"
              placeholder=""
              value={updatePrice}
              onChange={(e) => setUpdatePrice(e.target.value)}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ backgroundColor: "#015652", color: "white" }}
          onClick={handleUpdate}
        >
          Update
        </Button>
        <Button
          style={{ backgroundColor: "#015652", color: "white" }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PriceUpdateModal;
