import axios from "axios";
import { FetchManagerApp, PostAppList, CheckForm } from "../action";
import { BASEURL } from "config/api/URL";
import {
  FETCH_MANAGER_APPLICATION_LIST_PATH,
  POST_APPLICATION_LIST_PATH,
  CHECK_BY_FORM_NO_PATH,
} from "../constant";

export const FetchManagerApp_Middleware =
  (pageNumber, noOfRows, id, onSuccess, onFailure) => (dispatch) => {
    dispatch(FetchManagerApp.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${FETCH_MANAGER_APPLICATION_LIST_PATH}?page=${pageNumber}&limit=${noOfRows}&id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(FetchManagerApp.FetchSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        }
      });
  };

export const PostAppList_Middleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(PostAppList.Create());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${POST_APPLICATION_LIST_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch(PostAppList.CreateSuccess(res.data.data.items));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        }
      });
  };

export const CheckForm_Middleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(CheckForm.Check());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${CHECK_BY_FORM_NO_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch(CheckForm.CheckSuccess(res.data));
          dispatch(onSuccess(res.data.data));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        }
      });
  };
