import React, { useEffect, useRef, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Input,
  Container,
  Card,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ComponentToPrint from "../components/ComponentToPrint";
import validate from "../../../components/Utilities Component/ValidationWrapper";
import { _GetpaymentThrough, _CreatePaymentReceipt } from "../middleware";
import swal from "sweetalert";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../images/newlogo.png";

const PaymentReceipt = ({
  _GetpaymentThrough,
  PaymentThroughData,
  _CreatePaymentReceipt,
}) => {
  let history = useHistory();
  const location = useLocation();
  const inputFileRef = useRef(null);
  const componentRef = useRef();

  const [receiptData, setReceiptData] = useState({
    remarks: "",
    amount: "",
    paymentThroughId: "",
    paymentRef: "",
  });
  const [error, setError] = useState({
    paymentThroughError: null,
    amountError: null,
    remarksError: null,
    paymentRefError: null,
  });
  const [report, setReport] = useState({
    docs: [],
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onSuccessSendReceipt = () => {
    printDocument();
    swal({
      title: "Congratulations!",
      text: "Your Receipt is created successfully",
      type: "success",
      icon: "success",
    }).then(function (isConfirm) {
      if (isConfirm) {
        history.push({
          pathname: "/admin/ViewCustomerDetail",
        });
      } else {
      }
    });
  };
  const onFailureSendReceipt = () => {};
  const handleSendData = () => {
    let formData = new FormData();
    if (
      receiptData.paymentThroughId != 1 &&
      receiptData.paymentThroughId !== ""
    ) {
      setError({
        ...error,
        paymentThroughError: validate("required", receiptData.paymentThroughId),
        amountError: validate("required", receiptData.amount),
        remarksError: validate("required", receiptData.remarks),
        paymentRefError: validate(
          "CheaqueNumberRequired",
          receiptData.paymentRef
        ),
      });

      if (
        receiptData.paymentThroughId == "" ||
        receiptData.paymentThroughId == null ||
        receiptData.amount == "" ||
        receiptData.amount == null ||
        receiptData.remarks == "" ||
        receiptData.remarks == null ||
        receiptData.paymentRef == "" ||
        receiptData.paymentRef == null
      ) {
        swal("Sorry!", "Please Fill all required fields", "error");
      } else {
        let file = report.docs;
        file.forEach((file) => {
          formData.append("photos", file);
        });
        formData.append("remarks", receiptData.remarks);
        formData.append(
          "amount",
          new Intl.NumberFormat().format(receiptData.amount)
        );
        formData.append("paymentThroughId", receiptData.paymentThroughId);
        formData.append("FormNo", location.state?.receiptNo);
        formData.append("userId", location.state?.id);
        formData.append("PaymentReferenceNo", receiptData.paymentRef);
        _CreatePaymentReceipt(
          formData,
          onSuccessSendReceipt,
          onFailureSendReceipt
        );
      }
    } else {
      setReceiptData({
        ...receiptData,
        paymentRef: "",
      });
      setError({
        ...error,
        paymentThroughError: validate("required", receiptData.paymentThroughId),
        amountError: validate("required", receiptData.amount),
        remarksError: validate("required", receiptData.remarks),
      });

      if (
        receiptData.paymentThroughId == "" ||
        receiptData.paymentThroughId == null ||
        receiptData.amount == "" ||
        receiptData.amount == null ||
        receiptData.remarks == "" ||
        receiptData.remarks == null
      ) {
        swal("Sorry!", "Please Fill all required fields", "error");
      } else {
        let file = report.docs;
        file.forEach((file) => {
          formData.append("photos", file);
        });
        formData.append("remarks", receiptData.remarks);
        formData.append(
          "amount",
          new Intl.NumberFormat().format(receiptData.amount)
        );
        formData.append("paymentThroughId", receiptData.paymentThroughId);
        formData.append("FormNo", location.state?.receiptNo);
        formData.append("userId", location.state?.id);
        formData.append("PaymentReferenceNo", "");
        _CreatePaymentReceipt(
          formData,
          onSuccessSendReceipt,
          onFailureSendReceipt
        );
      }
    }
  };
  const handleDelete = (name) => {
    let filteredArray = report.docs?.filter((i) => i.name !== name);
    setReport({
      ...report,
      docs: filteredArray,
    });
  };
  const CheckFields = (name) => {
    if (name === "paymentThroughId") {
      setError({
        ...error,
        paymentThroughError: validate("required", receiptData.paymentThroughId),
      });
    } else if (name === "amount") {
      setError({
        ...error,
        amountError: validate("required", receiptData.amount),
      });
    } else if (name === "remarks") {
      setError({
        ...error,
        remarksError: validate("required", receiptData.remarks),
      });
    } else if (name === "paymentRef") {
      setError({
        ...error,
        paymentRefError: validate(
          "CheaqueNumberRequired",
          receiptData.paymentRef
        ),
      });
    }
  };
  const printDocument = () => {
    let date = new Date().toISOString().split("T")[0];

    const doc = new jsPDF();
    doc.addImage(Logo, "png", 15, 5, 50, 20);
    doc.text("Payment Receipt", 88, 40);
    doc.setFontSize(10);
    doc.text("Date: " + date, 168, 18);
    doc.autoTable({
      theme: "grid",
      head: [["Customer Details", ""]],
      body: [
        ["Receipt No.", location.state?.receiptNo],
        ["Name", location.state?.name],
        ["Contact No.", location.state?.whatsappNumber],
        ["Email", location.state?.email],
        ["CNIC No.", location.state?.cnic],
      ],
      margin: { top: 55 },
      styles: { lineColor: 10, cellWidth: 91 },
    });
    doc.autoTable({
      theme: "grid",
      head: [["Payment Details", ""]],
      body: [
        [
          "Payment Through",
          receiptData.paymentThroughId == "1"
            ? "Cash"
            : receiptData.paymentThroughId == "2"
            ? "Cheque"
            : receiptData.paymentThroughId == "3"
            ? "Online"
            : receiptData.paymentThroughId == "4"
            ? "Pay Order"
            : "",
        ],
        [
          "Payment Ref #",
          receiptData.paymentRef ? receiptData.paymentRef : "-",
        ],
        ["Amount", receiptData.amount],

        ["Remarks", receiptData.remarks],
      ],
      margin: { top: 55 },
      styles: { lineColor: 10, cellWidth: 91 },
    });
    doc.setFontSize(10);
    doc.text("Singnature:________________", 145, 170);

    doc.save(`Payment receipt ${location.state?.receiptNo}.pdf`);
  };
  const onSucccess = () => {};
  const onFailure = () => {};
  useEffect(() => {
    _GetpaymentThrough(onSucccess, onFailure);
  }, []);
  useEffect(() => {
    if (location.state === undefined) {
      swal({
        title: "Sorry!",
        text: "Data Not found, redirecting to View Forms",
      }).then(function (isConfirm) {
        if (isConfirm) {
          history.push({
            pathname: "/admin/ViewCustomerDetail",
          });
        } else {
        }
      });
    }
  }, [location]);
  return (
    <>
      <Header />
      <ComponentToPrint
        ref={componentRef}
        printReceiptInfo={receiptData}
        user={location.state}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h3 className="mb-0">Payment Receipt</h3>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                {/* <section id="content"> */}
                <Row>
                  <Col lg="1" md="1" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Form No.
                    </label>
                    <input
                      value={location.state?.receiptNo}
                      // disabled
                      type="text"
                      id="input-username"
                      className="form-control"
                      disabled
                    ></input>
                  </Col>
                </Row>

                <br />
                <hr style={{ margin: "0px" }} />
                <Row>
                  <Col lg="3" md="3" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Name
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Client Name"
                      value={location.state?.name}
                      disabled
                    ></input>
                  </Col>
                  <Col lg="3" md="3" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Contact No.
                    </label>

                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={location.state?.whatsappNumber}
                      disabled
                    ></input>
                  </Col>

                  <Col lg="3" md="3" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Email
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={location.state?.email}
                      disabled
                    ></input>
                  </Col>
                  <Col lg="3" md="3" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      CNIC
                    </label>

                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      value={location.state?.cnic}
                      disabled
                    ></input>
                  </Col>
                  <br />
                </Row>
                <Row>
                  <Col lg="3" md="3" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      <span className="text-red">*</span> Payment Through
                    </label>
                    <Input
                      type="select"
                      onBlur={() => CheckFields("paymentThrougId")}
                      value={receiptData.paymentThroughId}
                      onChange={(e) =>
                        setReceiptData({
                          ...receiptData,
                          paymentThroughId: e.target.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      {PaymentThroughData !== null &&
                        PaymentThroughData !== undefined &&
                        PaymentThroughData.map((val) => {
                          return (
                            <option key={val.id} value={val.id}>
                              {val.title}
                            </option>
                          );
                        })}
                    </Input>
                    {error.paymentThroughError !== "" &&
                      error.paymentThroughError !== null && (
                        <small>
                          <span style={{ color: "red" }}>
                            {error.paymentThroughError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>
                  {receiptData.paymentThroughId != 1 &&
                  receiptData.paymentThroughId !== "" ? (
                    <Col lg="3" md="3" sm="6" xs="12">
                      <label
                        className="form-control-label"
                        for="input-username"
                      >
                        <span className="text-red">*</span> Payment Ref #
                      </label>
                      <input
                        type="text"
                        id="input-username"
                        className="form-control"
                        placeholder="0000000000"
                        onBlur={() => CheckFields("paymentRef")}
                        onChange={(e) =>
                          setReceiptData({
                            ...receiptData,
                            paymentRef: e.target.value,
                          })
                        }
                        value={receiptData.paymentRef}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      ></input>
                      {error.paymentRefError !== "" &&
                        error.paymentRefError !== null && (
                          <small>
                            <span style={{ color: "red" }}>
                              {error.paymentRefError}{" "}
                              <i className="fas fa-exclamation-circle"></i>
                            </span>
                          </small>
                        )}
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col lg="3" md="3" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      <span className="text-red">*</span> Amount
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder="Amount"
                      onBlur={() => CheckFields("amount")}
                      onChange={(e) =>
                        setReceiptData({
                          ...receiptData,
                          amount: e.target.value,
                        })
                      }
                      value={receiptData.amount}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></input>
                    {error.amountError !== "" && error.amountError !== null && (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.amountError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>

                  <Col lg="12" md="12" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      <span className="text-red">*</span> Remarks:
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      onBlur={() => CheckFields("remarks")}
                      value={receiptData.remarks}
                      onChange={(e) =>
                        setReceiptData({
                          ...receiptData,
                          remarks: e.target.value,
                        })
                      }
                    ></Input>
                    {error.remarksError !== "" && error.remarksError !== null && (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.remarksError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <Label
                      className="form-control-label mr-2"
                      for="input-username"
                    >
                      Upload File
                    </Label>
                    <Input
                      type="file"
                      multiple={true}
                      name="docs"
                      style={{ color: "rgba(0, 0, 0, 0)" }}
                      onChange={(e) => {
                        let arr = [];
                        Array.from(e.target.files).map((file) => {
                          arr.push(file);
                        });
                        setReport({
                          ...report,
                          docs: report.docs.concat(arr),
                        });
                      }}
                      ref={inputFileRef}
                    />
                  </Col>
                </Row>
                {report.docs.length > 0
                  ? report.docs.map((file, index) => {
                      return (
                        <Row key={index}>
                          <Col
                            lg="4"
                            md="6"
                            sm="12"
                            className="d-flex justify-content-between"
                            key={index}
                          >
                            <Label for="files" className=" mt-2">
                              {file.name}
                            </Label>
                            <i
                              className="fas fa-trash "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(file.name)}
                            ></i>
                          </Col>
                        </Row>
                      );
                    })
                  : ""}
                <br />
                {/* </section> */}
                <Row>
                  <Col>
                    <Button
                      className="btn-icon btn-2"
                      color="success"
                      type="button"
                      id="save"
                      onClick={handleSendData}
                    >
                      <span className="btn-inner--icon">Save</span>
                    </Button>
                    {/* <Button
                      className="btn-icon btn-2"
                      color="info"
                      type="button"
                      id="proceed"
                      onClick={printDocument}
                    >
                      <span className="btn-inner--icon">Print</span>
                    </Button> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  PaymentThroughData: state.paymentReceipt.PaymentThroughData,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _CreatePaymentReceipt: (body, onSuccess, onFailure) =>
      dispatch(_CreatePaymentReceipt(body, onSuccess, onFailure)),
    _GetpaymentThrough: (onSuccess, onFailure) =>
      dispatch(_GetpaymentThrough(onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(PaymentReceipt);
