import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import TableOfAgentStaffList from "./TableOfAgentStaffList";
import { useHistory } from "react-router-dom";



const Filteration = (props) => {
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);

 
  const history = useHistory();

  useEffect(() => {
    
    props.GetAgentListMiddleware(pageNumber, noOfRows, onSuccess, onFailure);
   }, [pageNumber, noOfRows,]);
  

    useEffect(() => {
     props.GetAgentListMiddleware(pageNumber, noOfRows, onSuccess, onFailure);
   }, [props.del])
 

 

 
  // let dataInArrayForPaginaion = [];
  // useEffect(() => {
  //   setPosts(null);
  //   setPageNumber(1);
  //   if (props.Client !== null && props.Client !== undefined) {
  //     for (let i = 0; i < props.Client.length; i++) {
  //       dataInArrayForPaginaion.push(props.Client[i]);
  //       setPosts(props.Client);
  //     }
  //   } else {
  //   }
  // }, [props.Client]);
  useEffect(() => {
  
    if (props.responseAgent!==null && props.responseAgent!==undefined) {
      setPosts(props.responseAgent);
    }
  }, [props.responseAgent]);

  console.log(props.responseAgent,"props.responseAgent")




  
 
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  ///////////----------Pagination--------------//////////////

  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };

  
  
console.log(paginatedPosts,"paginatedpost")



  const addNewStaff=()=>{
//history.push({pathname:'/user/BankList'})
let path = "/user/Staff";
history.push(path);

  }





 const onSuccess = () => {
    
  };
  const onFailure= () => {};

  return (
    <CardHeader className="border-0">
    <Row>
      <Col  lg="2" md="2" sm="6">
        <h2>
       Staff List 
        </h2>
      </Col>
      <Col  lg="8" md="8" sm="6"></Col>
      <Col  lg="2" md="2" sm="6"> 
      <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={addNewStaff}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       Add New Staff
                      </span>
                    </Button>
      </Col>
      
    </Row>
 
  
      <hr/>
      <CardBody>
     
        <Row className="mt--5">
          <Col lg="2" md="2" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
        </Row>
        <br />
        <TableOfAgentStaffList
           
           DeleteAgentMiddleware={props.DeleteAgentMiddleware} del={props.del}
            // onChangeNoOfRows={onChangeNoOfRows}
            paginatedPosts={paginatedPosts}
         
          //response={props.response}
        ></TableOfAgentStaffList>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
