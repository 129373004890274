import { connect } from "react-redux";
import ApplicationList from "./ApplicationList";
import {
  GetApplicationListMiddleware,
  FormSubmitMiddleware,
  UploadImageMiddleware,
  getChalanDataMiddleware,
} from "../middleware";

const mapStateToProps = (state) => ({
  List: state.agentApplicationList.List,
  info: state.agentApplicationList.info,
  chalanData: state.agentApplicationList.chalanData,
  // image:state.agentApplicationList.image,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    GetApplicationListMiddleware: (
      pageNumber,
      NoOfRows,
      valuesAndKey,
      OnSuccess,
      OnFailure
    ) =>
      dispatch(
        GetApplicationListMiddleware(
          pageNumber,
          NoOfRows,
          valuesAndKey,
          OnSuccess,
          OnFailure
        )
      ),
    FormSubmitMiddleware: (body, onSuccess, onFailure) =>
      dispatch(FormSubmitMiddleware(body, onSuccess, onFailure)),
    // UploadImageMiddleware:(body,onSuccess,onFailure)=>
    // dispatch(UploadImageMiddleware(body,onSuccess,onFailure)),
    getChalanDataMiddleware: (id, onSuccess, onFailure) =>
      dispatch(getChalanDataMiddleware(id, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(ApplicationList);
