import { connect } from "react-redux";
import AgentList from "./AgentList";
import { GetAgentListMiddleware,
  AgentVerifyMiddleware
} from "../middleware";


const mapStateToProps = (state) => ({

  List:state.agentList.List,
verify:state.agentList.verify,

 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    GetAgentListMiddleware: (page, limit, valuesAndKey, OnSuccess, OnFailure) =>
    dispatch(GetAgentListMiddleware(page, limit, valuesAndKey,OnSuccess, OnFailure)),
    AgentVerifyMiddleware:(id, onSuccess,onFailure)=>
    dispatch(AgentVerifyMiddleware(id,onSuccess,onFailure)),
   
 
     


   
  
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(AgentList);
