import {
  CREATE_MANAGER_CUSTOMER,
  CREATE_MANAGER_CUSTOMER_SUCCESS,
  CREATE_MANAGER_CUSTOMER_FAILURE,
} from "../constant";

export class CreateManagerCustomer {
  static create() {
    return {
      type: CREATE_MANAGER_CUSTOMER,
    };
  }
  static createSuccess(response) {
    return {
      type: CREATE_MANAGER_CUSTOMER_SUCCESS,
      payload: response,
    };
  }
  static createFailure(error) {
    return {
      type: CREATE_MANAGER_CUSTOMER_FAILURE,
      error,
    };
  }
}
