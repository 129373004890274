import axios from "axios";
import { ImportFileAction } from "../action/index";
import { IMPORT_FILE_PATH } from "../constant";
import { BASEURL } from "config/api/URL";

export const ImportFileMiddleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(ImportFileAction.Upload());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${IMPORT_FILE_PATH}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(ImportFileAction.Upload_Success(res.data.data));
        dispatch(onSuccess(res.data));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
        }
      });
  };
