import axios from "axios";
import {
  BranchListDetail,
  BranchDeleteDetail,
  FileUpload,
} from "../action/index";
import {
  BRANCHLIST_PATH,
  BRANCHDELETE_PATH,
  FILE_UPLOAD_PATH,
} from "../constant";
import { BASEURL } from "config/api/URL";
export const DeleteBranchMiddleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(BranchDeleteDetail.BranchDelete());
    let token = localStorage.getItem("auth");
    axios
      .delete(`${BASEURL}${BRANCHDELETE_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(BranchDeleteDetail.BranchDelete_Success(res.data.data));
          dispatch(onSuccess(res.data.message));
        }
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };

export const GetBranchListMiddleware =
  (pageNumber, noOfRows, onSuccess, onFailure) => (dispatch) => {
    dispatch(BranchListDetail.BranchList());
    let token = localStorage.getItem("auth");
    axios
      .get(
        `${BASEURL}${BRANCHLIST_PATH}?page=${pageNumber}&limit=${noOfRows}`,
        {
          headers: {
            "Content-Type": "application/json",
            //Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        //  if (res.status === 200) {
        dispatch(BranchListDetail.BranchList_Success(res.data.data.items));
        dispatch(onSuccess(res.message));
        // }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          // onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };

export const FileUpload_Middleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    dispatch(FileUpload.Upload());
    axios
      .post(`${BASEURL}${FILE_UPLOAD_PATH}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        //  if (res.status === 201) {
        dispatch(FileUpload.Upload_Success(res.data.data.items));
        dispatch(onSuccess(res.data.message));
        // }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          onFailure(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // .catch((error) => dispatch(FetchAllRequests.FetchFailure(error)));
  };
