import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import validate from "components/Utilities Component/ValidationWrapper";
import Logo from "assets/img/black variation.png";
import { connect } from "react-redux";
import { VerifyUserByEmail } from "../middleware";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const VerifyLoginUser = ({ VerifyUserByEmail }) => {
  let history = useHistory();
  // const notify = useRef(null);
  const [body, setBody] = useState({
    email: "",
  });

  const [error, setError] = useState({
    emailError: "",
  });
  const CheckFields = (name) => {
    if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", body.email),
      });
    }
  };
  const onSuccess = () => {
    swal({
      // title: "Congratulations!",
      text: "Verfication code successfully sent on email and on your mobile number",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        localStorage.setItem("email", body.email);
        const email = body.email;
        let path = "/auth/otp";
        // history.push(path);
        history.push({
          pathname: path,
          email: email,
        });
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const handleDispatch = () => {
    if (body.email !== "") {
      VerifyUserByEmail(body, onSuccess, onFailure);
    }
    //  else if (body.cnic.length < 13) {
    //   swal("Sorry!", "CNIC is not valid, 13 digits required", "error");
    // } else {
    //   swal("Sorry!", "Please Enter CNIC and Password", "error");
    // }
  };
  return (
    <>
      {/* <NotificationAlert ref={notify} /> */}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-1 bg-pic">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img height="150px" width="150px" src={Logo} /> <br />
              <h3>
                {/* <b> */}
                {/* Verification Code */}
                {/* </b> */}
              </h3>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Enter your email here"
                    type="email"
                    onBlur={() => CheckFields("email")}
                    value={body.email}
                    onChange={(e) =>
                      setBody({ ...body, email: e.target.value })
                    }
                  />
                </InputGroup>
                {error.emailError !== "" && error.emailError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.emailError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-4"
                  type="button"
                  onClick={handleDispatch}
                >
                  Submit
                </Button>
              </div>
            </Form>
            <Row className="mt-3 text-center"></Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  // GetUserJWTLogin: state.JwtCredential.GetUserJWTLogin
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    VerifyUserByEmail: (body, onSuccess, onFailure) =>
      dispatch(VerifyUserByEmail(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(VerifyLoginUser);
