import {
  FORM_LIST,
  FORM_LIST_SUCCESS,
  FORM_LIST_FAILURE,
  FETCH_BANKS_BY_ID,
  FETCH_BANKS_BY_ID_SUCCESS,
  FETCH_BANKS_BY_ID_FAILURE,
  LAUNCHED_FROMS,
  LAUNCHED_FROMS_SUCCESS,
  LAUNCHED_FROMS_FAILURE,
} from "../constant";

export class LaunchedFormAction {
  static Fetch() {
    return {
      type: LAUNCHED_FROMS,
    };
  }
  static Fetch_Success(response) {
    return {
      type: LAUNCHED_FROMS_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: LAUNCHED_FROMS_FAILURE,
      error,
    };
  }
}

export class FormListAction {
  static Fetch() {
    return {
      type: FORM_LIST,
    };
  }
  static Fetch_Success(response) {
    return {
      type: FORM_LIST_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: FORM_LIST_FAILURE,
      error,
    };
  }
}

export class FetchBanksByIdAction {
  static Fetch() {
    return {
      type: FETCH_BANKS_BY_ID,
    };
  }
  static Fetch_Success(response) {
    return {
      type: FETCH_BANKS_BY_ID_SUCCESS,
      payload: response,
    };
  }
  static Fetch_Failure(error) {
    return {
      type: FETCH_BANKS_BY_ID_FAILURE,
      error,
    };
  }
}
