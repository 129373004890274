import {
  FETCH_ADMIN_REQ_LIST,
  FETCH_ADMIN_REQ_LIST_SUCCESS,
  FETCH_ADMIN_REQ_LIST_FAILURE,
  FETCH_MANAGER_LIST,
  FETCH_MANAGER_LIST_SUCCESS,
  FETCH_MANAGER_LIST_FAILURE,
  FETCH_STAFF_LIST,
  FETCH_STAFF_LIST_SUCCESS,
  FETCH_STAFF_LIST_FAILURE,
  FETCH_AGENT_LIST,
  FETCH_AGENT_LIST_SUCCESS,
  FETCH_AGENT_LIST_FAILURE,
  FETCH_AGENT_STAFF_LIST,
  FETCH_AGENT_STAFF_LIST_SUCCESS,
  FETCH_AGENT_STAFF_LIST_FAILURE,
} from "../constant.js";
export class FetchAgentStaffList {
  static Fetch() {
    return {
      type: FETCH_AGENT_STAFF_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_AGENT_STAFF_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_AGENT_STAFF_LIST_FAILURE,
      error,
    };
  }
}
export class FetchAgentList {
  static Fetch() {
    return {
      type: FETCH_AGENT_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_AGENT_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_AGENT_LIST_FAILURE,
      error,
    };
  }
}
export class FetchAppList {
  static Fetch() {
    return {
      type: FETCH_ADMIN_REQ_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_ADMIN_REQ_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_ADMIN_REQ_LIST_FAILURE,
      error,
    };
  }
}

export class FetchManagerList {
  static Fetch() {
    return {
      type: FETCH_MANAGER_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_MANAGER_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_MANAGER_LIST_FAILURE,
      error,
    };
  }
}

export class FetchStaffList {
  static Fetch() {
    return {
      type: FETCH_STAFF_LIST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_STAFF_LIST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_STAFF_LIST_FAILURE,
      error,
    };
  }
}
