import {
  FETCH_BANKS,
  FETCH_BANKS_FAILURE,
  FETCH_BANKS_SUCCESS,
  FORM_DEPOSIT,
  FORM_DEPOSIT_SUCCESS,
  FORM_DEPOSIT_FAILURE,
  FORM_GENERATE,
  FORM_GENERATE_SUCCESS,
  FORM_GENERATE_FAILURE,
} from "../constant";

const INITIAL_STATE = {
  getBanks: null,
  getFormDeposit: null,
  formGenRes: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
    case FORM_DEPOSIT:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        getFormDeposit: null,
        error: {},
      };
    case FORM_DEPOSIT_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        getFormDeposit: action.payload,
      };
    case FORM_DEPOSIT_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case FORM_GENERATE:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        formGenRes: null,
        error: {},
      };
    case FORM_GENERATE_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        formGenRes: action.payload,
      };
    case FORM_GENERATE_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case FETCH_BANKS:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        getBanks: null,
        error: {},
      };
    case FETCH_BANKS_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        getBanks: action.payload,
      };
    case FETCH_BANKS_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
