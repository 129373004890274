import { OTP, OTP_SUCCESS, OTP_FAILURE } from "../constant.js";
export class OTPVerification {
  static Otp() {
    return {
      type: OTP,
    };
  }
  static OtpSuccess(response) {
    // console.log("RESPONSE DATA AT PAYLOAD", response);
    return {
      type: OTP_SUCCESS,
      payload: response,
    };
  }
  static OtpFailure(error) {
    return {
      type: OTP_FAILURE,
      error,
    };
  }
}
