import React, { useEffect, useRef, useState } from "react";
import {
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
  Container,
  Card,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import Header from "components/Headers/Header1";
import validate from "components/Utilities Component/ValidationWrapper";
import swal from "sweetalert";
import {
  FetchBanksMiddleware,
  FetchFormDepositMiddleware,
  FormGenerateMiddleware,
} from "../middleware";
import { useHistory } from "react-router-dom";

const FormGenerate = ({
  FetchBanksMiddleware,
  getBanks,
  FetchFormDepositMiddleware,
  FormGenerateMiddleware,
}) => {
  const history = useHistory();

  const [body, setBody] = useState({
    bankId: "",
    slipCount: "",
    depositNoFrom: "",
    depositNoTo: "",
  });

  const [error, setError] = useState({
    bankIdError: null,
    slipCountError: null,
  });

  const CheckFields = (name) => {
    if (name === "bankId") {
      setError({
        ...error,
        bankIdError: validate("required", body.bankId),
      });
    } else if (name === "slipCount") {
      setError({
        ...error,
        slipCountError: validate("required", body.slipCount),
      });
    }
  };

  const onSuccessFormGen = (msg) => {
    swal({
      title: "Congratulations!",
      text: msg,
      icon: "success",
    }).then((isOk) => {
      if (isOk) {
        history.push("/user/FormExport");
      }
    });
  };
  const onFailureFormGen = (msg) => {
    swal("Sorry!", msg.message, "error");
  };

  // const onSuccess2 = (msg) => {
  //   swal("Congratulations!", msg, "success");
  //   setIsTrue2(false);
  //   setBody({ portalType: "", formNo: "", depositSlipNo: "" });
  // };
  // const onFailure2 = (msg) => {
  //   swal("Sorry!", msg.message, "error");
  // };

  const handleSendData = () => {
    setError({
      ...error,
      bankIdError: validate("required", body.bankId),
      slipCountError: validate("required", body.slipCount),
    });
    if (
      body.bankId !== "" &&
      body.slipCount !== "" &&
      body.slipCount <= 10000 &&
      body.depositNoFrom !== "" &&
      body.depositNoTo !== ""
    ) {
      FormGenerateMiddleware(body, onSuccessFormGen, onFailureFormGen);
    } else if (body.slipCount > 10000) {
      swal("Sorry!", "Form not more than 10000", "error");
    }
  };
  const onSuccessFormDep = (res) => {
    setBody({
      ...body,
      depositNoFrom: res.data.depositNo,
      depositNoTo: "",
      slipCount: "",
    });
  };
  const onFailureFormDep = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const endFromCount = (depositNo, count) => {
    try {
      const depositNumberTemp = depositNo.split("-");
      const depositNumber = depositNumberTemp[1];
      const BankCode = depositNumberTemp[0];
      const depositIncre = (parseInt(depositNumber) + Number(count)).toString();
      const reg = new RegExp(depositNumber);
      const nextFromNumString = depositNumber.replace(reg, depositIncre);
      return BankCode + "-" + nextFromNumString;
    } catch (error) {
      // throw new InternalServerErrorException(error);
    }
  };
  useEffect(() => {
    if (body.bankId !== "") {
      FetchFormDepositMiddleware(
        body.bankId,
        onSuccessFormDep,
        onFailureFormDep
      );
    }
  }, [body.bankId]);
  useEffect(() => {
    if (body.slipCount !== "") {
      let count = endFromCount(body.depositNoFrom, body.slipCount);
      setBody({ ...body, depositNoTo: count });
    }
  }, [body.slipCount]);

  useEffect(() => {
    FetchBanksMiddleware();
  }, []);
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="12">
                    <h1 className="mb-0">Form Generate</h1>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="12"></Col>
                  <Col lg="2" md="2" sm="6" xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Select Bank <span className="text-red">*</span>
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setBody({ ...body, bankId: e.target.value })
                      }
                      onBlur={() => CheckFields("bankId")}
                      value={body.bankId}
                    >
                      <option value="">Search Bank</option>
                      {getBanks?.items?.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                    {error.bankIdError !== "" && error.bankIdError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.bankIdError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Start From <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      // placeholder="Enter branch code here"
                      // value={body.depositNoFrom}
                      // onBlur={() => CheckFields("depositNoFrom")}
                      // onChange={(e) =>
                      //   setBody({ ...body, depositNoFrom: e.target.value })
                      // }
                      value={body.depositNoFrom}
                      disabled
                      autoComplete="off"
                    ></input>
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      End Form <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      // placeholder="Enter form content here"
                      // value={body.depositNoTo}
                      // onBlur={() => CheckFields("contantOne")}
                      // onChange={(e) =>
                      //   setBody({ ...body, contantOne: e.target.value })
                      // }
                      value={body.depositNoTo}
                      autoComplete="off"
                      disabled
                    ></input>
                  </Col>
                  <Col lg="3" md="4" sm="6" xs="12">
                    <label className="form-control-label" for="input-username">
                      Total Form <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      // placeholder="Enter form footer content here"
                      value={body.slipCount}
                      onBlur={() => CheckFields("slipCount")}
                      onChange={(e) =>
                        setBody({ ...body, slipCount: e.target.value })
                      }
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></input>
                    {error.slipCountError !== "" &&
                      error.slipCountError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.slipCountError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      className="btn-icon btn-2"
                      type="button"
                      id="save"
                      onClick={handleSendData}
                    >
                      <span className="btn-inner--icon">Generate</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  getBanks: state.FetchBanksReducer.getBanks,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    FetchBanksMiddleware: () => dispatch(FetchBanksMiddleware()),
    FetchFormDepositMiddleware: (id, onSuccess, onFailure) =>
      dispatch(FetchFormDepositMiddleware(id, onSuccess, onFailure)),
    FormGenerateMiddleware: (body, onSuccess, onFailure) =>
      dispatch(FormGenerateMiddleware(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(FormGenerate);
