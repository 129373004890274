import {
  CUSTOMER,
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILURE,
  PLOTTYPE,
  PLOTTYPE_SUCCESS,
  PLOTTYPE_FAILURE,
  CUSTOMERDRAFT_SUCCESS,
  CUSTOMERDRAFT,
  CUSTOMERDRAFT_FAILURE,
  CUSTOMERDRAFTID,
  CUSTOMERDRAFTID_FAILURE,
  CUSTOMERDRAFTID_SUCCESS,
  CUSTOMERUPDATE,
  CUSTOMERUPDATE_FAILURE,
  CUSTOMERUPDATE_SUCCESS,
  CUSTOMERDRAFTUPDATE,
  CUSTOMERDRAFTUPDATE_FAILURE,
  CUSTOMERDRAFTUPDATE_SUCCESS,
  VERIFY_BY_CNIC,
  VERIFY_BY_CNIC_SUCCESS,
  VERIFY_BY_CNIC_FAILURE,
} from "../constant";
const INITIAL_STATE = {
  Data: null,
  Draft: null,
  DraftData: null,
  isLoading: false,
  isError: false,
  error: {},
  Type: null,
  Update: null,
  DraftUpdate: null,
  CNIC_Response: [],
};
export default (body = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return body;

    case VERIFY_BY_CNIC:
      return {
        ...body,
        isLoading: true,
        isError: false,
        CNIC_Response: [],
      };
    case VERIFY_BY_CNIC_SUCCESS:
      return {
        ...body,
        isLoading: false,
        CNIC_Response: action.payload,
      };
    case VERIFY_BY_CNIC_FAILURE:
      return {
        ...body,

        isError: true,
        error: action.error,
      };
    case CUSTOMERDRAFTUPDATE:
      return {
        ...body,
        isLoading: true,
        isError: false,
        DraftUpdate: null,
      };
    case CUSTOMERDRAFTUPDATE_SUCCESS:
      return {
        ...body,
        isLoading: false,

        DraftUpdate: action.payload,
      };
    case CUSTOMERDRAFTUPDATE_FAILURE:
      return {
        ...body,

        isError: true,
        error: action.error,
      };
    case CUSTOMERUPDATE:
      return {
        ...body,
        isLoading: true,
        isError: false,
        Update: null,
      };
    case CUSTOMERUPDATE_SUCCESS:
      return {
        ...body,
        isLoading: false,

        Update: action.payload,
      };
    case CUSTOMERUPDATE_FAILURE:
      return {
        ...body,

        isError: true,
        error: action.error,
      };
    case CUSTOMERDRAFTID:
      return {
        ...body,
        isLoading: true,
        isError: false,

        DraftData: null,
      };
    case CUSTOMERDRAFTID_SUCCESS:
      return {
        ...body,
        isLoading: false,

        DraftData: action.payload,
      };
    case CUSTOMERDRAFTID_FAILURE:
      return {
        ...body,

        isError: true,
        error: action.error,
      };

    case CUSTOMERDRAFT:
      return {
        ...body,
        isLoading: true,
        isError: false,

        Draft: null,
      };
    case CUSTOMERDRAFT_SUCCESS:
      return {
        ...body,
        isLoading: false,

        Draft: action.payload,
      };
    case CUSTOMERDRAFT_FAILURE:
      return {
        ...body,

        isError: true,
        error: action.error,
      };

    case CUSTOMER:
      return {
        ...body,
        isLoading: true,
        isError: false,

        Data: null,
      };
    case CUSTOMER_SUCCESS:
      return {
        ...body,
        isLoading: false,

        Data: action.payload,
      };
    case CUSTOMER_FAILURE:
      return {
        ...body,

        isError: true,
        error: action.error,
      };
    case PLOTTYPE:
      return {
        ...body,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        Type: null,
        error: {},
      };
    case PLOTTYPE_SUCCESS:
      return {
        ...body,
        isLoading: false,
        isLoggedIn: true,
        Type: action.payload,
      };
    case PLOTTYPE_FAILURE:
      return {
        ...body,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    // case PLOTSIZE:
    //   return {
    //     ...body,
    //     isLoading: true,
    //     isError: false,
    //     isLoggedIn: false,
    //     Size: null,
    //     error: {},
    //   };
    // case PLOTSIZE_SUCCESS:
    //   return {
    //     ...body,
    //     isLoading: false,
    //     isLoggedIn: true,
    //     Size: action.payload,
    //   };
    // case PLOTSIZE_FAILURE:
    //   return {
    //     ...body,
    //     isLoading: false,
    //     isError: true,
    //     error: action.error,
    //   };
  }
};
