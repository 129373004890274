import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Container,
  Card,
  Form,
  FormGroup,
  CardHeader,
  Button,
  CardBody,
  Col,
  Label,
  Input,
} from "reactstrap";
import Headers from "components/Headers/Header1";
import { connect } from "react-redux";
import { AdminFeedbackList_Middleware, Sendquerydetail } from "../middleware";
import swal from "sweetalert";
const SendQuery = (props) => {
  const inputFileRef = useRef(null);
  const [queryType, setQueryType] = useState("");
  const [queryText, setQueryText] = useState("");
  const [data, setData] = useState([]);
  const [report, setReport] = useState({
    title: "",
    docs: [],
    description: "",
    support_type: 1,
  });
  useEffect(() => {
    props.AdminFeedbackList_Middleware(onSuccess, onFailure);
  }, []);
  useEffect(() => {
    if (
      props.AdminFeedbackList !== null &&
      props.AdminFeedbackList !== undefined
    ) {
      setData(props.AdminFeedbackList);
      console.log("ISSUE DATA AT COMPOMENT", props.AdminFeedbackList);
    }
  }, [props.AdminFeedbackList]);
  const handleDelete = (name) => {
    let filteredArray = report.docs?.filter((i) => i.name !== name);
    setReport({
      ...report,
      docs: filteredArray,
    });
  };
  const onSuccess = () => {
    swal({
      title: "Success!",
      text: "Issue submitted Successfully.",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        setQueryText("");
        setReport({
          ...report,
          docs: [],
        });
        // setQueryType("");
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const handleSubmit = () => {
    let formData = new FormData();
    let file = report.docs;
    file.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("issueTypeId", parseInt(queryType));
    formData.append("query", queryText);
    props.Sendquerydetail(formData, onSuccess, onFailure);
  };

  return (
    <>
      <Headers />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h2 className="mb-0">Query form</h2>
                  </Col>
                </Row>
                <hr style={{ marginTop: 10 }} />
              </CardHeader>
              <CardBody>
                <Row style={{ marginTop: -50 }}>
                  <Col xl="4" lg="4" md="4" sm="4" xs="4">
                    <Label className="form-control-label">Select issue *</Label>
                    <Input
                      id="exampleFormControlSelect1"
                      type="select"
                      value={queryType}
                      onChange={(e) => setQueryType(e.target.value)}
                    >
                      <option key={null} value="">
                        select
                      </option>
                      {data !== null &&
                      data !== undefined &&
                      data.length > 0 ? (
                        data.map((item, i) => {
                          return <option value={item.id}>{item.name}</option>;
                        })
                      ) : (
                        <option unselectable={true} key={null} value={null}>
                          data not found
                        </option>
                      )}
                    </Input>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xl="8" lg="8" md="8" sm="8" xs="8">
                    <Form>
                      <FormGroup>
                        <Label className="form-control-label">
                          Write a query *
                        </Label>
                        <Input
                          value={queryText}
                          onChange={(e) => {
                            setQueryText(e.target.value);
                          }}
                          rows={6}
                          placeholder="Write your any query here"
                          style={{ resize: "none" }}
                          type="textarea"
                          name="text"
                          id="exampleText"
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row style={{ marginTop: -10 }}>
                  <Col xl="8" lg="8" md="8" sm="8" xs="8">
                    <Form>
                      <FormGroup>
                        <Label
                          className="form-control-label mr-2"
                          for="input-username"
                        >
                          Upload Images{" "}
                          <span style={{ color: "" }}>
                            {" "}
                            (Note: Upto three images can be uploaded){" "}
                          </span>
                        </Label>
                        <Input
                          type="file"
                          multiple={true}
                          name="docs"
                          style={{ color: "rgba(0, 0, 0, 0)", width: "23%" }}
                          onChange={
                            (e) => {
                              let arr = [];
                              const MAX_LENGTH = 3;
                              if (
                                Array.from(e.target.files).length > MAX_LENGTH
                              ) {
                                e.preventDefault();
                                swal(
                                  "Sorry!",
                                  `Cannot upload files more than ${MAX_LENGTH}`,
                                  "error"
                                );
                                // alert(
                                //   `Cannot upload files more than ${MAX_LENGTH}`
                                // );
                                return;
                              } else {
                                Array.from(e.target.files).map((file) => {
                                  arr.push(file);
                                });
                                setReport({
                                  ...report,
                                  docs: report.docs.concat(arr),
                                });
                              }
                            }
                            // setReport({
                            //   ...report,
                            //   docs: Array.from(e.target.files),
                            // })
                          }
                          ref={inputFileRef}
                          // accept="image/gif, image/jpeg, image/png"
                          accept=".png, .jpg, .jpeg"
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                {report.docs.length > 0
                  ? report.docs.map((file, index) => {
                      return (
                        <Row key={index} style={{ marginTop: -15 }}>
                          <Col
                            lg="4"
                            md="6"
                            sm="12"
                            className="d-flex justify-content-between"
                          >
                            <Label for="files" className=" mt-3">
                              {file.name}
                            </Label>
                            <i
                              className="fas fa-trash "
                              style={{ cursor: "pointer", marginTop: 14 }}
                              onClick={() => handleDelete(file.name)}
                            ></i>
                          </Col>
                        </Row>
                      );
                    })
                  : ""}
                <Row className="mt-1">
                  <Col lg="5" md="5" sm="5">
                    <Button
                      style={{
                        backgroundColor: "#015652",
                        color: "white",
                        // float: "right",
                        justifyContent: "flex-end",
                        alignSelf: "flex-end",
                      }}
                      size="md"
                      id="search"
                      onClick={() => {
                        if (report.docs?.length > 3) {
                          swal(
                            "Sorry!",
                            "Cannot upload files more than 3",
                            "error"
                          );
                        } else if (
                          queryText?.trim().length > 0 &&
                          queryType?.trim().length > 0
                        ) {
                          handleSubmit();
                        } else {
                          swal("Sorry!", "All * fields are required.", "error");
                        }
                      }}
                    >
                      <span className="btn-inner--icon">Send query</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  AdminFeedbackList: state.getIssueList.AdminFeedbackList,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    AdminFeedbackList_Middleware: (onSuccess, onFailure) =>
      dispatch(AdminFeedbackList_Middleware(onSuccess, onFailure)),
    Sendquerydetail: (body, onSuccess, onFailure) =>
      dispatch(Sendquerydetail(body, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(SendQuery);
