import React from "react";
import Headers from "components/Headers/Header1";
import Filteration from "./Filteration";
import { Container, Card, Row } from "reactstrap";

const ViewCustomerDetail = (props) => {
  //  console.log(props.List,"props.list")

  return (
    <>
      <Headers />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filteration
                List={props.List}
                GetApplicationListMiddleware={
                  props.GetApplicationListMiddleware
                }
                CustomerInfoMiddleware={props.CustomerInfoMiddleware}
                info={props.info}
              ></Filteration>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default ViewCustomerDetail;
