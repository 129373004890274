export const FETCH_BANKS = "FETCH_BANKS";
export const FETCH_BANKS_SUCCESS = "FETCH_BANKS_SUCCESS";
export const FETCH_BANKS_FAILURE = "FETCH_BANKS_FAILURE";
export const FETCH_BANKS_PATH = "bank-manual-flow/bank?page=1&limit=500";

export const FORM_DEPOSIT = "FORM_DEPOSIT";
export const FORM_DEPOSIT_SUCCESS = "FORM_DEPOSIT_SUCCESS";
export const FORM_DEPOSIT_FAILURE = "FORM_DEPOSIT_FAILURE";
export const FORM_DEPOSIT_PATH = "bank-manual-flow/form-desposit";

export const FORM_GENERATE = "FORM_GENERATE";
export const FORM_GENERATE_SUCCESS = "FORM_GENERATE_SUCCESS";
export const FORM_GENERATE_FAILURE = "FORM_GENERATE_FAILURE";
export const FORM_GENERATE_PATH = "bank-manual-flow/form-generate";
