import React, { useEffect, useRef, useState } from "react";
// import Loader from "react-loader-spinner";
// import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table, Label } from "reactstrap";
import swal from "sweetalert";
// import ComponentToPrint from "./ComponentToPrint";
// import UpdatePaymentReceipt from "./UpdatePaymentReceipt";
import ViewReceiptModal from "./ViewReceiptModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../images/newlogo.png";

const TableViewPaymentReceipts = ({
  post,
  _FormCancelation,
  onChangeNoOfRows,
}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewReceipt, setViewReceipt] = useState(false);
  // const [isOk, setIsOk] = useState(false);
  // const [printReceiptInfo, setPrintReceiptInfo] = useState({});
  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  // const print = (receipt) => {
  //   setPrintReceiptInfo(receipt);
  //   setIsOk(true);
  // };
  const printDocument = (receipt) => {
    let date = new Date().toISOString().split("T")[0];
    const doc = new jsPDF();
    doc.addImage(Logo, "png", 15, 5, 50, 20);
    doc.text("Payment Receipt", 88, 40);
    doc.setFontSize(10);
    doc.text("Date: " + date, 168, 18);
    doc.autoTable({
      theme: "grid",
      head: [["Customer Details", ""]],
      body: [
        ["Receipt No.", receipt.receiptNo],
        ["Name", receipt.name],
        ["Contact No.", receipt.whatsappNumber],
        ["Email", receipt.email],
        ["CNIC No.", receipt.cnic],
      ],
      margin: { top: 55 },
      styles: { lineColor: 10, cellWidth: 91 },
    });
    doc.autoTable({
      theme: "grid",
      head: [["Payment Details", ""]],
      body: [
        ["Payment Through", receipt.receipts?.[0].paymentThrough.title],
        [
          "Payment Ref #",
          receipt.receipts?.[0].PaymentReferenceNo
            ? receipt.receipts?.[0].PaymentReferenceNo
            : "-",
        ],
        ["Amount", receipt.receipts?.[0].amount],
        ["Remarks", receipt.receipts?.[0].remarks],
      ],
      margin: { top: 55 },
      styles: { lineColor: 10, cellWidth: 91 },
    });
    doc.setFontSize(10);
    doc.text("Singnature:________________", 145, 170);

    doc.save(`Payment receipt ${receipt.receiptNo}.pdf`);
  };
  // const ToggleUpdateReceiptModal = () => {
  //   setIsOpen(!isOpen);
  // };
  const ToggleViewReceiptModal = (post) => {
    setIsViewModalOpen(!isViewModalOpen);
    setViewReceipt(post);
  };
  const onSuccessCancelation = () => {
    swal("Form Cancel successfully!", "", "success");
  };
  const onFailureCancelation = () => {};
  const cancelForm = (formNo) => {
    let body = {
      FormNo: formNo,
    };
    _FormCancelation(body, onSuccessCancelation, onFailureCancelation);
  };
  // useEffect(() => {
  //   if (isOk === true) {
  //     handlePrint();
  //     setIsOk(false);
  //   }
  // }, [isOk]);
  return (
    <div>
      {/* <UpdatePaymentReceipt toggle={ToggleUpdateReceiptModal} modal={isOpen} /> */}
      {/* <ViewReceiptModal
        toggle={ToggleViewReceiptModal}
        modal={isViewModalOpen}
        viewReceipt={viewReceipt}
      /> */}

      {/* <ComponentToPrint
        ref={componentRef}
        // printReceiptInfo={printReceiptInfo}
      /> */}
      <Row className="mt-5">
        <Col xl="2" lg="4" md="4" sm="6" xs="6">
          <Label className="form-control-label"> Rows Per Pages </Label>
          <Input
            id="exampleFormControlSelect1"
            type="select"
            onChange={(e) => onChangeNoOfRows(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Input>
        </Col>
      </Row>
      <br />
      <Table className="align-items-center" responsive>
        <thead className="thead-dark">
          <tr>
            {/* <th scope="col">S.No.</th> */}
            <th scope="col">Request No.</th>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Date</th>
            <th scope="col">No. Of Applications</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {/* {post !== null && post !== undefined && post.length > 0 ? (
            post.map((receipt, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{receipt.receiptNo}</td>
                  <td>{receipt.name}</td>
                  <td>{receipt.whatsappNumber}</td>
                  <td>{receipt.email}</td>
                  <td>{receipt.cnic}</td>
                  <td>{receipt.receipts[0].paymentThrough?.title}</td>
                  <td>{receipt.receipts[0].amount}</td>
                  <td>{receipt.receipt_status?.name}</td>
                  <td>
                    <Button
                      color="success"
                      id="search"
                      size="sm"
                      onClick={() => ToggleViewReceiptModal(receipt)}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-eye ml-1 mb-1"></i>
                      </span>
                    </Button>
                    <Button
                      color="danger"
                      id="search"
                      size="sm"
                      onClick={() => cancelForm(receipt.receipts[0].FormNo)}
                      disabled={receipt.receiptStatusId === 3}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-times ml-1 mb-1"></i>
                      </span>
                    </Button>
                    <Button
                      color="info"
                      size="sm"
                      // onClick={() => print(receipt)}
                      onClick={() => printDocument(receipt)}
                      disabled={receipt.receiptStatusId === 3}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-print ml-1 mb-1"></i>
                      </span>
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )} */}
          <tr>
            <td>00000</td>
            <td>Syed Muhammad Shujjat Ali Rizvi</td>
            <td>Proccessing Fess</td>
            <td>30-Dec-2020</td>
            <td>1</td>
            <td>1500</td>
            <td>
              <span class="badge badge-danger">PENDING</span>
            </td>
            <td>
              <Button
                color="danger"
                id="search"
                size="sm"
                // onClick={() => ToggleViewReceiptModal(receipt)}
              >
                <span className="btn-inner--text"></span>
                <span className="btn-inner--icon">
                  {/* <i className="fas fa-eye ml-1 mb-1"></i> */}
                  Pay Now
                </span>
              </Button>
              {/* <Button
                      color="danger"
                      id="search"
                      size="sm"
                      onClick={() => cancelForm(receipt.receipts[0].FormNo)}
                      disabled={receipt.receiptStatusId === 3}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-times ml-1 mb-1"></i>
                      </span>
                    </Button>
                    <Button
                      color="info"
                      size="sm"
                      // onClick={() => print(receipt)}
                      onClick={() => printDocument(receipt)}
                      disabled={receipt.receiptStatusId === 3}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-print ml-1 mb-1"></i>
                      </span>
                    </Button> */}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableViewPaymentReceipts;
