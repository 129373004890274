import React, {  useState } from "react";
import { Button, Col, Input, Row, Table, Label } from "reactstrap";
import RequestStaffViewModal from "./RequestStaffViewModal";
import PayNowModal from "./PayNowModal";
import { connect } from "react-redux";
import { PrintForm_Middleware } from "../middleware";

import { BASEURL } from "config/api/URL";

const TableRequestStaffView = ({
  post,
}) => {
  let token = localStorage.getItem("auth");
  let userId = JSON.parse(localStorage.getItem("user")).id;
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isPaynowModalOpen, setIsPaynowModalOpen] = useState(false);
  const [paynowModalData, setPaynowModalData] = useState({});

  const ToggleViewRequestModal = (data) => {
    setIsOpen(!isOpen);
    setModalData(data);
  };

  const ToggleViewPayNowModal = (data) => {
    setIsPaynowModalOpen(!isPaynowModalOpen);
    setPaynowModalData(data);
  };
  const onSuccess = () => {};
  const onFailure = () => {};
  // const handlePrint = (id) => {
  //   _PrintForm_Middleware(id, onSuccess, onFailure);
  // };
  const handlePrint = (id) => {
    window.open(`${BASEURL}uploadfile/download/form/pdf/${id}`, "_blank");
  };
  return (
    <div>
      <RequestStaffViewModal
        toggle={ToggleViewRequestModal}
        modal={isOpen}
        user={modalData}
      />
      <PayNowModal
        toggle={ToggleViewPayNowModal}
        modal={isPaynowModalOpen}
        user={paynowModalData}
      />
      <br />
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Name</th>
            <th scope="col">Application No.</th>
            <th scope="col">CNIC</th>
            <th scope="col">Phone</th>
            <th scope="col">Type</th>
            <th scope="col">Date</th>
            <th scope="col">Quantity</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {post !== null && post !== undefined && post.length > 0 ? (
            post?.map((request, index) => {
              return (
                <tr key={request?.id}>
                  <td>{index + 1}</td>
                  <td>{request?.name}</td>
                  <td>{request?.id}</td>
                  <td>{request?.user?.cnic}</td>
                  <td>{request?.user?.phone}</td>
                  <td>{request?.type}</td>
                  <td>{request?.createdAt?.split("T")[0]}</td>
                  <td>{request?.NumberOfApplication}</td>
                  <td>{request?.Amount}</td>
                  <td>
                    {request?.Status == "Pending" ? (
                      <span className="badge badge-danger">
                        {request?.Status}
                      </span>
                    ) : request?.Status == "Paid" ? (
                      <span className="badge badge-success">
                        {request?.Status}
                      </span>
                    ) : (
                      ""
                    )}
                  </td>

                  <td>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      id="search"
                      size="sm"
                      onClick={() => ToggleViewRequestModal(request)}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">View</span>
                    </Button>
                    {request?.Status == "Pending" ? (
                      <Button
                        style={{
                          backgroundColor: "#015652",
                          color: "white",
                          width: "75px",
                        }}
                        id="search"
                        size="sm"
                        onClick={() => ToggleViewPayNowModal(request)}
                      >
                        <span className="btn-inner--text"></span>
                        <span className="btn-inner--icon">Pay Now</span>
                      </Button>
                    ) : request?.Status == "Paid" ? (
                      <a
                        style={{
                          color: "white",
                        }}
                        href={`${BASEURL}uploadfile/download/pdfd/${request?.id}/${userId}`}
                        target="_blank"
                      >
                        <Button
                          style={{
                            backgroundColor: "#015652",
                            color: "white",
                            width: "80px",
                          }}
                          id="search"
                          size="sm"
                        >
                          Print
                        </Button>
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}; //PrintForm_Middleware

const mapStateToProps = (state) => ({
  // StaffRequests: state.staffRequest.StaffRequests,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    _PrintForm_Middleware: (body, onSuccess, onFailure) =>
      dispatch(PrintForm_Middleware(body, onSuccess, onFailure)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToPrpos
)(TableRequestStaffView);
