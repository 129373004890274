import {
  FETCH_ALL_REQUEST,
  FETCH_ALL_REQUEST_SUCCESS,
  FETCH_ALL_REQUEST_FAILURE,
  PRINT_FORM,
  PRINT_FORM_SUCCESS,
  PRINT_FORM_FAILURE,
  PAY_FORM_FEE,
  PAY_FORM_FEE_SUCCESS,
  PAY_FORM_FEE_FAILURE,
} from "../constant.js";

export class FetchStaffRequests {
  static Fetch() {
    return {
      type: FETCH_ALL_REQUEST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_ALL_REQUEST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_ALL_REQUEST_FAILURE,
      error,
    };
  }
}

export class Print_Form {
  static Print() {
    return {
      type: PRINT_FORM,
    };
  }
  static PrintSuccess(response) {
    return {
      type: PRINT_FORM_SUCCESS,
      payload: response,
    };
  }
  static PrintFailure(error) {
    return {
      type: PRINT_FORM_FAILURE,
      error,
    };
  }
}

export class Pay_Form_Fee {
  static Fee() {
    return {
      type: PAY_FORM_FEE,
    };
  }
  static FeeSuccess(response) {
    return {
      type: PAY_FORM_FEE_SUCCESS,
      payload: response,
    };
  }
  static FeeFailure(error) {
    return {
      type: PAY_FORM_FEE_FAILURE,
      error,
    };
  }
}
