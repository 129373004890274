import {
  STAFF_LOGIN,
  STAFF_LOGIN_SUCCESS,
  STAFF_LOGIN_FAILURE,
} from "../constant.js";
export class StaffLogin {
  static Login() {
    return {
      type: STAFF_LOGIN,
    };
  }
  static LoginSuccess(response) {
    return {
      type: STAFF_LOGIN_SUCCESS,
      payload: response,
    };
  }
  static LoginFailure(error) {
    return {
      type: STAFF_LOGIN_FAILURE,
      error,
    };
  }
}
