import RequestUserView from "views/UserViews/Request/container";
import ApplicationList from "views/UserViews/ApplicationList/container";
import CustomerMembershipForm from "views/UserViews/MembershipForm/container";
import PaymentConfirmation from "views/UserViews/Request/components/PaymentConfirmation";
import Payment from "views/UserViews/Payment/container";
import SendQuery from "../components/Support/Sendquery/container";
import QueryList from "../components/Support/Querieslist/container";
import PaymentInfo from "views/UserViews/PaymentInfo/container";
import { Welcome } from "views/UserViews/Welcome/Welcome";

var routes = [
  {
    path: "/CustomerMembershipForm",
    name: "CustomerMembershipForm",
    icon: "fas fa-angle-right",
    component: CustomerMembershipForm,
    layout: "/user",
    isView: false,
  },
  {
    path: "/PaymentConfirmation",
    name: "PaymentConfirmation",
    icon: "fas fa-angle-right",
    component: PaymentConfirmation,
    layout: "/user",
    isView: false,
  },
  {
    path: "/requestUserView",
    name: "Step 1: Request",
    icon: "fas fa-angle-right",
    component: RequestUserView,
    layout: "/user",
    isView: true,
  },
  {
    path: "/ApplicationList",
    name: "Step 2: Registration",
    icon: "fas fa-angle-right",
    component: ApplicationList,
    layout: "/user",
    isView: true,
  },
  {
    path: "/Payment",
    name: "Payment",
    icon: "fas fa-angle-right",
    component: Payment,
    layout: "/user",
    isView: false,
  },
  {
    path: "/PaymentInfo",
    name: "PaymentInfo",
    icon: "fas fa-angle-right",
    component: PaymentInfo,
    layout: "/user",
    isView: false,
  },
  {
    name: "Support",
    icon: "fab fa-wpforms ",
    isView: true,
    subNav: [
      {
        path: "/sendQuery",
        name: "Send query",
        icon: "fas fa-angle-right",
        component: SendQuery,
        layout: "/user",
        isView: true,
      },
      {
        path: "/getQueries",
        name: "View Queries List",
        icon: "fas fa-angle-right",
        component: QueryList,
        layout: "/user",
        isView: true,
      },
    ],
  },
];
export default routes;
