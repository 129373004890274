import React, { useEffect } from "react";
import swal from "sweetalert";
import { useState } from "react";
import { Button, Table, Input } from "reactstrap";

import { useHistory } from "react-router";
import Information from "../helpers/Information";
import { GetSocketRequestDetails } from "sockets/socket";
import { GetSocketPaymentDetails } from "sockets/socket";

const TableOfViewCustomer = (props) => {
  const { pageSize, pageIndex, handleData } = props;

  useEffect(() => {
    console.log("PAGE SIE USEFECT");
    setChecked([]);
  }, [pageSize]);

  let roleId = localStorage.getItem("roleid");
  let sNo = 1;
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [draftDis, setDraftDis] = useState(null);
  useEffect(() => {
    if (checked.length > 0 && pageIndex > 1) {
      handleData(true);
    } else {
      handleData(false);
    }
  }, [pageIndex, checked]);
  let history = useHistory();

  const [ClientDetail, setClientDetail] = useState({});
  const toggler = (detail) => {
    // console.log("CLIENTDETAIL", detail);
    setClientDetail(detail);
    setIsOpen(!isOpen);
  };

  let arr = [];

  const onMembership = () => {
    history.push({
      pathname: "/user/CustomerMembershipForm",
      // state: post,
      forms: checked,
    });
    // if (checked.length > 0) {
    //   history.push({
    //     pathname: "/user/CustomerMembershipForm",
    //     // state: post,
    //     forms: checked,
    //   });
    // } else {
    //   swal("Sorry!", "At least select one form", "error");
    // }
  };
  // Add/Remove checked item from list
  // const handleCheck = (event, value) => {
  //   // swal("Sorry!", "You can't select more than 5 forms", "error");
  //   var updatedList = [...checked];
  //   if (event.target.checked) {
  //     updatedList = [...checked, value.id];
  //     console.log("UPDATEDLIST", updatedList);
  //   } else {
  //     updatedList.splice(checked.indexOf(value.id), 1);
  //     console.log("UPDATEDLIST", updatedList);
  //   }
  //   setChecked(updatedList);
  // };
  // const dateFunction = (date) => {
  //   const nDate = new Date(date).toLocaleString("en-US", {
  //     timeZone: "Asia/Karachi",
  //   });
  //   return nDate;
  // };
  const handleCheck = (event, value) => {
    // console.log("vale", value);
    if (checked.length > 4 && event.target.checked) {
      swal("Sorry!", "You can't select more than 5 forms", "error");
      event.target.checked = false;
    }
    if (value.status == "DRAFT" && event.target.checked) {
      setDraftDis(value);
    } else if (value.status == "DRAFT" && !event.target.checked) {
      setDraftDis(null);
    }

    var updatedList = [...checked];
    // console.log("UDPATD LIST UPER", updatedList);
    if (event.target.checked) {
      updatedList = [...checked, { id: value.id, status: value.status }];
      // console.log("UPDATEDLIST", updatedList);
    } else if (!event.target.checked) {
      let tempArr = updatedList.filter((item) => value.id !== item.id);
      updatedList = [...tempArr];
    }
    setChecked(updatedList);
  };

  // useEffect(() => {
  //   if (props.GetUserJWTLogin === false) {
  //     localStorage.removeItem("auth");
  //     window.location.href = "/auth/login";
  //   } else {
  //     if (props.paginatedPosts !== null && props.paginatedPosts !== undefined) {
  //       let str = toString(props.paginatedPosts.Datetime);
  //       // str = str.split("T");
  //       for (let i = 0; i < props.paginatedPosts.length; i++) {
  //         if (
  //           props.paginatedPosts.Agentname == props.state.agentName &&
  //           props.state.date === ""
  //         ) {
  //         } else if (
  //           str[0] == props.state.date &&
  //           props.state.agentName === ""
  //         ) {
  //         } else if (
  //           str[0] == props.state.date &&
  //           props.paginatedPosts.Agentname == props.state.agentName
  //         ) {
  //         } else if (
  //           props.state.agentName === "" &&
  //           props.state.date === "" &&
  //           (props.paginatedPosts.teamid == props.teamId ||
  //             props.teamId == null)
  //         ) {
  //         }
  //       }
  //     }
  //   }
  // }, [props.paginatedPosts]);

  const [isOpenChangeModal, setIsOpenChangeModal] = useState(false);
  const [ClientDetailModal, setClientDetailModal] = useState({});
  const Modaldata = (posts) => {
    setClientDetailModal(posts);
    setIsOpenChangeModal(!isOpenChangeModal);
  };

  return (
    <>
      <Information
        modal={isOpen}
        toggle={toggler}
        CustomerInfoMiddleware={props.CustomerInfoMiddleware}
        info={props.info}
        detail={ClientDetail}
      />
      <Button
        style={{
          backgroundColor: "#015652",
          color: "white",
          // width: "20%",
          marginTop: "10px",
        }}
        disabled={checked.length <= 0 ? true : false}
        size="md"
        id="search"
        onClick={() => onMembership()}
      >
        <span className="btn-inner--text"></span>
        <span className="btn-inner--icon">{`Proceed with ${checked.length} Forms`}</span>
      </Button>
      <Table style={{ marginTop: 8 }} className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929", color: "white" }}>
          <tr>
            <th scope="col"></th>
            <th scope="col">ID</th>
            <th scope="col">Unique form ID</th>
            <th scope="col">Application Request ID</th>
            <th scope="col">Applicant Name</th>
            <th scope="col">CNIC</th>
            {/* <th scope="col">Email</th> */}
            {/* <th scope="col">WhatsApp Number</th> */}
            {/* <th scope="col">Payment Ref</th> */}
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {props.paginatedPosts !== null &&
          props.paginatedPosts !== undefined ? (
            props.paginatedPosts.map((posts, i) => {
              const index = i + pageIndex * pageSize;
              {
                GetSocketPaymentDetails(posts.id, history);
              }
              return (
                <tr key={index}>
                  <td>
                    <Input
                      style={{ position: "static" }}
                      type="checkbox"
                      name="check"
                      disabled={
                        posts.status == "APPLIED" ||
                        posts.status == "PAYMENT_PENDING" ||
                        draftDis !== null
                          ? draftDis?.id !== posts.id
                          : "" || checked.length > 0
                          ? checked[0].status !== posts.status
                          : ""
                      }
                      value={posts.id}
                      onChange={(e) => handleCheck(e, posts)}
                    />
                  </td>
                  <td>{posts.id}</td>
                  <td>{posts.uniqueFormId}</td>
                  <td>{posts.applicationRequestId}</td>
                  <td>{posts.name}</td>
                  <td>{posts.cnic}</td>
                  {/* <td>{posts.email}</td> */}
                  {/* <td>{posts.whatsAppNo}</td> */}
                  {/* <td>{posts.paymentRef}</td> */}
                  <td>
                    <span
                      class={
                        posts.status.includes("PENDING")
                          ? "badge badge-warning"
                          : posts.status.includes("APPLIED")
                          ? "badge badge-success"
                          : posts.status.includes("NEW")
                          ? "badge badge-info"
                          : "badge badge-primary"
                      }
                    >
                      {posts.status}
                    </span>
                  </td>
                  <th>
                    {posts.status.includes("PENDING") ? (
                      <>
                        {/* <Button
                        style={{ backgroundColor: "#015652", color: "white" }}
                        size="sm"
                        onClick={
                          () =>
                            history.push({
                              pathname: "/user/PaymentConfirmation",
                              state: posts,
                            })
                          // console.log("GO FOR PAYMENT", posts)
                        }
                        id="info"
                        disabled={posts.status == "APPLIED"}
                      >
                        <span className="btn-inner--icon">Pay Now</span>
                      </Button> */}
                      </>
                    ) : (
                      <>
                        {/* null */}
                        {/* <Button
                        style={{ backgroundColor: "#015652", color: "white" }}
                        size="sm"
                        onClick={(e) => onMembership(posts)}
                        id="info"
                        disabled={posts.status == "APPLIED"}
                      >
                        <span className="btn-inner--icon">Proceed</span>
                      </Button> */}
                      </>
                    )}
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      size="sm"
                      onClick={(e) => toggler(posts)}
                      id="info"
                    >
                      <span className="btn-inner--icon">View</span>
                    </Button>
                  </th>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>

              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}

          {/* <th> 
              <Button
                      style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                    onClick={(e) => onMembership()}
                      id="info"
                   
                    >
                      <span className="btn-inner--icon">
                       Proceed
                      </span>
                    </Button>
                    <Button
                       style={{backgroundColor:"#015652", color:"white"}}
                      size="sm"
                       onClick={(e) => toggler()}
                      id="info"
                    >
                      <span className="btn-inner--icon">
                       View
                      </span>
                    </Button>
                    </th> */}
          {/* </tr> */}
        </tbody>
      </Table>
    </>
  );
};
export default TableOfViewCustomer;
