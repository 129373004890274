import {
  DEALER_REG,
  DEALER_REG_SUCCESS,
  DEALER_REG_FAILURE,
} from "../constant";

const INITIAL_STATE = {
  dealerRegRes: null,
};

export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;

    case DEALER_REG:
      return {
        ...states,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        dealerRegRes: null,
        error: {},
      };
    case DEALER_REG_SUCCESS:
      return {
        ...states,
        isLoading: false,
        isLoggedIn: true,
        dealerRegRes: action.payload,
      };
    case DEALER_REG_FAILURE:
      return {
        ...states,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
