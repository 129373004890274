/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import routes from "routes/routes.js";
import DealerRegistration from "views/AdminView/DealerRegistration/container";

//import Picture from "../assets/img/lg.jpg";
//import Picture from "https://s3.ap-south-1.amazonaws.com/khybergolfcity.com/images/marbel-light.jpg"
const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  let Picture =
    "https://di7b0povdn4ov.cloudfront.net/khybergolfproduction_StaticContent/marbellight.jpg";

  // React.useEffect(() => {
  //   document.body.style.backgroundImage = `url('${Picture}')`;
  //   document.body.style.backgroundSize = "cover";
  //   return () => {
  //     document.body.style.backgroundImage = `url('')`;
  //   };
  // }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <Row>
          <Col lg="12" md="12" sm="12">
            <Switch>
              <Route path="/dealer/registration" component={DealerRegistration} />
            </Switch>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Auth;
