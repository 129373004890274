import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFY_BY_EMAIL,
  VERIFY_BY_EMAIL_SUCCESS,
  VERIFY_BY_EMAIL_FAILURE,
} from "../constant.js";

export class UserLogin {
  static Login() {
    return {
      type: LOGIN,
    };
  }
  static LoginSuccess(response) {
    return {
      type: LOGIN_SUCCESS,
      payload: response,
    };
  }
  static LoginFailure(error) {
    return {
      type: LOGIN_FAILURE,
      error,
    };
  }
}

export class VerifyByEmail {
  static Account_verify() {
    return {
      type: VERIFY_BY_EMAIL,
    };
  }
  static Account_verifySuccess(response) {
    return {
      type: VERIFY_BY_EMAIL_SUCCESS,
      payload: response,
    };
  }
  static Account_verifyFailure(error) {
    return {
      type: VERIFY_BY_EMAIL_FAILURE,
      error,
    };
  }
}
