import {
  APPLICATIONREQUEST,
  APPLICATIONREQUEST_FAILURE,
  APPLICATIONREQUEST_SUCCESS,
  FETCH_ALL_REQUEST,
  FETCH_ALL_REQUEST_SUCCESS,
  FETCH_ALL_REQUEST_FAILURE,
  // FETCH_COUNT,
  // FETCH_COUNT_SUCCESS,
  // FETCH_COUNT_FAILURE,
  FETCH_PRICE,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
  FETCH_USER, FETCH_USER_FAILURE,FETCH_USER_SUCCESS
} from "../constant.js";


export class FetchUserDetail {
  static FetchUser() {
    return {
      type: FETCH_USER,
    };
  }
  static FetchUserSuccess(response) {
    return {
      type: FETCH_USER_SUCCESS,
      payload: response,
    };
  }
  static FetchUserFailure(error) {
    return {
      type: FETCH_USER_FAILURE,
      error,
    };
  }
}
export class ApplicationDetail {
  static Application() {
    return {
      type: APPLICATIONREQUEST,
    };
  }
  static ApplicationSuccess(response) {
    return {
      type: APPLICATIONREQUEST_SUCCESS,
      payload: response,
    };
  }
  static ApplicationFailure(error) {
    return {
      type: APPLICATIONREQUEST_FAILURE,
      error,
    };
  }
}

export class FetchAllRequests {
  static Fetch() {
    return {
      type: FETCH_ALL_REQUEST,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_ALL_REQUEST_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_ALL_REQUEST_FAILURE,
      error,
    };
  }
}

// export class FetchCount {
//   static Fetch() {
//     return {
//       type: FETCH_COUNT,
//     };
//   }
//   static FetchSuccess(response) {
//     return {
//       type: FETCH_COUNT_SUCCESS,
//       payload: response,
//     };
//   }
//   static FetchFailure(error) {
//     return {
//       type: FETCH_COUNT_FAILURE,
//       error,
//     };
//   }
// }

export class FetchPricing {
  static Fetch() {
    return {
      type: FETCH_PRICE,
    };
  }
  static FetchSuccess(response) {
    return {
      type: FETCH_PRICE_SUCCESS,
      payload: response,
    };
  }
  static FetchFailure(error) {
    return {
      type: FETCH_PRICE_FAILURE,
      error,
    };
  }
}
