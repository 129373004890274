import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  InputGroup,
  Row,
  Col,
  Input,
} from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import Logo from "assets/img/whiteee1.png";
import { useHistory, Redirect } from "react-router-dom";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import url from "socket.io-client/lib/url";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const SignUp = (props) => {
  //hooks import
  let history = useHistory();
  const [arrCountry, setArrCountry] = useState([]);
  const [arrCity, setArrCity] = useState([]);
  const [checked, setChecked] = useState(false);
  // const [isUserExist, setIsUserExist] = useState(false);
  const [state, setstate] = useState({
    password: "",
    confirmPassword: "",
    fullName: "",
    email: "",
    cnic: "",
    phone: "",
    estateName: "",
    address: "",
    whatsAppNo: "",
    city: "",
    country: "",
    file: "",
  });
  const [addressValidation, setAddressValidation] = useState("");
  const [error, setError] = useState({
    nameError: "",
    cnicError: "",
    phoneError: "",
    emailError: null,
    passwordError: "",
    confirmPasswordError: "",
    shopAddressError: "",
    shopNameError: "",
    whatsAppNoError: "",
    countryError: "",
    cityError: "",
    countryError: "",
    fileError: "",
  });
  const [type, settype] = useState(false);
  const [confirmType, setconfirmType] = useState(false);
  useEffect(() => {
    props.country_Middleware(onSuccessCountry, onFailureCountry);
  }, [true]);
  useEffect(() => {
    let tempArrCountry = [];

    if (props.cityData !== null && props.cityData !== undefined) {
      props.cityData.map((data, index) => {
        tempArrCountry.push(data);
      });
    }

    setArrCity(tempArrCountry);
  }, [props.cityData]);
  useEffect(() => {
    let tempArrCountry = [];

    if (props.countryData !== null && props.countryData !== undefined) {
      props.countryData.map((data, index) => {
        tempArrCountry.push(data);
      });
    }

    setArrCountry(tempArrCountry);
  }, [props.countryData]);

  const handleClick = () => {
    settype(!type);
  };

  const handleConfirm = () => {
    setconfirmType(!confirmType);
  };
  const CheckFields = (name) => {
    if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", state.email),
      });
    } else if (name === "file") {
      setError({
        ...error,
        fileError: validate("required", state.file),
      });
    } else if (name === "password") {
      setError({
        ...error,
        passwordError: validate("signupPassword", state.password),
      });
    } else if (name === "name") {
      setError({
        ...error,
        nameError: validate("fullName", state.fullName),
      });
    } else if (name === "cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", state.cnic),
      });
    } else if (name === "phone") {
      setError({
        ...error,
        phoneError: validate("phoneNumber", state.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
      });
    } else if (name === "confirmPassword") {
      if (state.confirmPassword !== "") {
        setError({
          ...error,
          confirmPasswordError:
            state.password != state.confirmPassword
              ? "Mismatch Password"
              : null,
        });
      } else {
        setError({
          ...error,
          confirmPasswordError:
            state.confirmPassword == "" ? "Enter Confirm Password" : null,
        });
      }
    } else if (name === "estateName") {
      setError({
        ...error,
        shopNameError: validate("required", state.estateName),
      });
    } else if (name === "address") {
      setError({
        ...error,
        shopAddressError: validate("required", state.address),
      });
      checkAddress();
    } else if (name === "country") {
      setError({
        ...error,
        countryError: validate("required", state.country),
      });
    } else if (name === "city") {
      setError({
        ...error,
        cityError: validate("required", state.city),
      });
    }
  };

  const checkAddress = () => {
    if (state.address?.length < 10) {
      setAddressValidation("Address must be or greater than 10 digits");
    } else {
      setAddressValidation("");
    }
  };
  const onChange = (val, name) => {
    setstate({ ...state, [name]: val });
  };
  useEffect(() => {
    if (
      state.country !== null &&
      state.country !== undefined &&
      state.country !== ""
    ) {
      props.city_Middleware(state.country, onSuccessCity, onFailureCity);
    } else {
      setArrCity([]);
      setstate({ ...state, city: "" });
    }
  }, [state.country]);
  const handleChecked = () => {
    setChecked(!checked);
  };
  useEffect(() => {
    setstate({ ...state, whatsAppNo: checked == true ? state.phone : "92" });
  }, [checked]);
  const SignUp = () => {
    setError({
      ...error,
      emailError: validate("email", state.email),
      passwordError: validate("signupPassword", state.password),
      nameError: validate("fullName", state.fullName),
      cnicError: validate("CNIC", state.cnic),
      phoneError: validate("phoneNumber", state.phone),
      shopNameError: validate("required", state.estateName),
      shopAddressError: validate("required", state.address),
      whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
      countryError: validate("required", state.country),
      cityError: validate("required", state.city),
      fileError: validate("required", state.file),
      confirmPasswordError:
        state.password != state.confirmPassword
          ? "Mismatch Password"
          : state.confirmPassword == ""
          ? "Enter Confirm Password"
          : null,
      // state.confirmPassword == "" ? "Enter Confirm Password" : null,

      // confirmPasswordError: validate("confirmSignupPassword",state.confirmPassword, state.password)
    });
    checkAddress();
    if (
      state.fullName !== "" &&
      state.password !== "" &&
      //state.email !== "" &&
      error.emailError == null &&
      state.address?.length >= 10 &&
      state?.cnic?.trim().length == 13 &&
      state.whatsAppNo?.trim().length == 12 &&
      state.phone?.trim().length == 12 &&
      // state.cnic !== "" &&
      // state.phone !== "" &&
      state.estateName !== "" &&
      state.confirmPassword !== "" &&
      state.confirmPassword == state.password &&
      state.country !== "" &&
      state.city !== "" &&
      state.file !== ""
    ) {
      let formData = new FormData();
      formData.append("fullName", state.fullName);
      formData.append("file", state.file);
      formData.append("cnic", state.cnic);
      formData.append("email", state.email);
      formData.append("phone", "+" + state.phone);
      formData.append("password", state.password);
      formData.append("estateName", state.estateName);
      formData.append("address", state.address);
      formData.append("yearsOfExperience", state.yearsOfExperience);
      formData.append("whatsAppNo", "+" + state.whatsAppNo);
      formData.append("countryId", parseInt(state.country));
      formData.append("cityId", parseInt(state.city));
      let body = {
        fullName: state.fullName,
        cnic: state.cnic,
        email: state.email,
        phone: "+" + state.phone,
        password: state.password,
        estateName: state.estateName,
        profilePhoto: "abc.jpg",
        address: state.address,
        yearsOfExperience: state.yearsOfExperience,
        whatsAppNo: "+" + state.whatsAppNo,
        countryId: parseInt(state.country),
        cityId: parseInt(state.city),
      };
      console.log(state);
      window.fbq("track", "AgentSignup");
      props.SignUpMiddleware(formData, onSuccess, onFailure);
    }
  };

  const onSuccessCountry = () => {};
  const onFailureCountry = () => {};
  const onSuccessCity = () => {};
  const onFailureCity = () => {};

  const onSuccess = (e) => {
    setstate({
      ...state,
      fullName: "",
      password: "",
      cnic: "",
      whatsAppNo: "",
      email: "",
      confirmPassword: "",
      phone: "",
      estateName: "",
      address: "",
      yearsOfExperience: "",
      file: "",
    });

    swal({
      title: "Successful",
      text: e,
      icon: "success",
    }).then((isOk) => {
      //if (isOk) {
      let path = "/auth/AgentLogin";
      history.push(path);
      //}
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const [url, setUrl] = useState("");
  const changeHandler = (event) => {
    const url = URL.createObjectURL(event.target.files[0]);
    setUrl(url);
    onChange(event.target.files[0], "file");
  };
  return (
    <>
      <Col lg="6" md="7">
        <Card className="customLogin">
          <CardBody className="px-lg-5 py-lg-2">
            <div className="text-center text-muted mb-4">
              <img height="140px" width="130px" src={Logo} /> <br />
              <h3 style={{ color: "white" }}>
                <b>Sign Up</b>
                <br />
              </h3>
              <h5>
                <a className="text-light" href="/auth/AgentLogin">
                  <b style={{ color: "black" }}>
                    <u>Already have an account? </u>{" "}
                    <span style={{ color: "white" }}>click here</span>
                  </b>
                </a>
              </h5>
            </div>
            <Row
            //</CardBody>className="mt-2 text-center"
            >
              <Col lg="4" md="4" sm="4"></Col>
              <Col lg="4" md="4" sm="4">
                <label
                  style={{
                    width: "110px",
                    height: "110px",
                    border: "5px solid white",
                    borderRadius: "100px",
                    overflow: "hidden",
                    backgroundColor: "whitesmoke",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    backgroundImage: `url(${url})`,
                    backgroundPosition: "center",
                    backgroundSize: "100% auto",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      display: url !== "" ? "none" : "",
                    }}
                  >
                    <div>
                      <i class="fa fa-upload" aria-hidden="true"></i>
                    </div>
                    <div>Agency Logo</div>
                  </div>

                  <Input
                    type="file"
                    name="logo"
                    onChange={changeHandler}
                    onBlur={() => CheckFields("file")}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </label>
                {error.fileError !== "" && error.fileError !== null && (
                  <small style={{ marginTop: "2px" ,marginLeft:"20px"}}>
                    <span style={{ color: "red" }}>
                      {error.fileError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="4" md="4" sm="4"></Col>
            </Row>
            <Row
            //</CardBody>className="mt-2 text-center"
            >
              <Col lg="12" md="12" sm="12">
                <h5 style={{ textAlign: "justify", color: "white" }}>
                  Note: Password must contain at least 1 upper case letter and
                  lower case letters, password must be longer than 8 characters.
                </h5>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label"
                  for="input-username"
                >
                  Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  onBlur={() => CheckFields("name")}
                  value={state.fullName}
                  onChange={(e) => onChange(e.target.value, "fullName")}
                  onKeyPress={(event) => {
                    if (!/[A-Z, a-z ]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                {error.nameError !== "" && error.nameError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.nameError}{" "}
                      <i className="fas fa-exclamation-circle mb--2"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label"
                  for="input-username"
                >
                  cnic
                </label>
                <input
                  className="form-control"
                  type="text"
                  maxLength={13}
                  onBlur={() => CheckFields("cnic")}
                  value={state.cnic}
                  onChange={(e) => onChange(e.target.value, "cnic")}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                ></input>

                {error.cnicError !== "" && error.cnicError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.cnicError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="12" md="12" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label"
                  for="input-username"
                >
                  Phone No
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  // className="form-control"
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "phone")}
                  onBlur={() => CheckFields("phone")}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="100%"
                  disabled={checked}
                  //  containerStyle={{border:"none"}}
                  countryCodeEditable={false}
                  country={"pk"}
                  // value={state.phone}
                />

                {error.phoneError !== "" && error.phoneError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.phoneError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="12" md="12" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  Whatsapp No
                </label>
                <PhoneInput
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "whatsAppNo")}
                  onBlur={() => CheckFields("whatsAppNo")}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    width: "100%",
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  disabled={checked}
                  fullWidth="true"
                  //  containerStyle={{border:"none"}}
                  countryCodeEditable={false}
                  country={"pk"}
                  value={state.whatsAppNo}
                />

                {error.whatsAppNoError !== "" &&
                  error.whatsAppNoError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.whatsAppNoError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
                {state.phone?.length == 12 ? (
                  <div style={{ position: "relative", float: "right" }}>
                    <input
                      type="checkbox"
                      onClick={handleChecked}
                      // disabled={isUserExist}
                    />
                    <small>
                      <b> Same As Phone No</b>
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  Email
                </label>
                <input
                  className="form-control"
                  type="email"
                  onBlur={() => CheckFields("email")}
                  value={state.email}
                  onChange={(e) => onChange(e.target.value, "email")}
                ></input>
                {error.emailError !== "" && error.emailError !== null && (
                  <small>
                    <span style={{ color: "red" }}>
                      {error.emailError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  Password
                </label>
                <InputGroup>
                  <input
                    type={type ? "text" : "password"}
                    className="form-control"
                    onBlur={() => CheckFields("password")}
                    value={state.password}
                    onChange={(e) => onChange(e.target.value, "password")}
                  ></input>
                  <span
                    onClick={handleClick}
                    style={{
                      paddingTop: "10px",
                      backgroundColor: "white",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      borderTop: "1px solid lightgrey",
                      borderRight: "1px solid lightgrey",
                      borderBottom: "1px solid lightgrey",
                      paddingRight: "5px",
                    }}
                  >
                    {type == false ? (
                      <i class="fa fa-eye"></i>
                    ) : (
                      <i class="fas fa-eye-slash"></i>
                    )}
                  </span>
                </InputGroup>

                {error.passwordError !== "" && error.passwordError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.passwordError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  Re-enter Password
                </label>
                <InputGroup>
                  <input
                    type={confirmType ? "text" : "password"}
                    className="form-control"
                    onBlur={() => CheckFields("confirmPassword")}
                    value={state.confirmPassword}
                    onChange={(e) =>
                      onChange(e.target.value, "confirmPassword")
                    }
                  ></input>
                  <span
                    onClick={handleConfirm}
                    style={{
                      paddingTop: "10px",
                      backgroundColor: "white",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      borderTop: "1px solid lightgrey",
                      borderRight: "1px solid lightgrey",
                      borderBottom: "1px solid lightgrey",
                      paddingRight: "5px",
                    }}
                  >
                    {confirmType == false ? (
                      <i class="fa fa-eye"></i>
                    ) : (
                      <i class="fas fa-eye-slash"></i>
                    )}
                  </span>
                </InputGroup>
                {error.confirmPasswordError !== "" &&
                  error.confirmPasswordError !== null && (
                    <small style={{ marginTop: "2px" }}>
                      <span style={{ color: "red" }}>
                        {error.confirmPasswordError}{" "}
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </small>
                  )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  Agency Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  onBlur={() => CheckFields("estateName")}
                  value={state.estateName}
                  onChange={(e) => onChange(e.target.value, "estateName")}
                  onKeyPress={(event) => {
                    if (!/[A-Z, a-z ]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                ></input>
                {error.shopNameError !== "" && error.shopNameError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.shopNameError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  Agency Address
                </label>
                <input
                  className="form-control"
                  type="text"
                  onBlur={() => CheckFields("address")}
                  value={state.address}
                  onChange={(e) => onChange(e.target.value, "address")}
                ></input>
                {error.shopAddressError !== "" &&
                error.shopAddressError !== null ? (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.shopAddressError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                ) : addressValidation !== "" ? (
                  <small>
                    <span style={{ color: "red" }}>
                      {addressValidation}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                ) : (
                  ""
                )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  Country
                </label>
                <select
                  className="form-control"
                  onBlur={() => CheckFields("country")}
                  value={state.country}
                  onChange={(e) => onChange(e.target.value, "country")}
                  disabled={arrCountry?.length == 0}
                >
                  <option value="">Select Country</option>
                  {arrCountry?.map((data) => {
                    return (
                      <option
                        key={data.id}
                        //value={JSON.stringify({ id: data.id, price: data.price })}
                        value={data.id}
                      >
                        {data.title}
                      </option>
                    );
                  })}
                </select>

                {error.countryError !== "" && error.countryError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.countryError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  City
                </label>
                <select
                  className="form-control"
                  onBlur={() => CheckFields("city")}
                  value={state.city}
                  onChange={(e) => onChange(e.target.value, "city")}
                  disabled={arrCity?.length == 0}
                >
                  <option value="">Select City</option>
                  {arrCity?.map((data) => {
                    return (
                      <option
                        key={data.id}
                        //value={JSON.stringify({ id: data.id, price: data.price })}
                        value={data.id}
                      >
                        {data.title}
                      </option>
                    );
                  })}
                </select>

                {error.cityError !== "" && error.cityError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.cityError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </Col>
              <Col lg="6" md="6" sm="6">
                <label
                  style={{ color: "white" }}
                  className="form-control-label mb--5"
                  for="input-username"
                >
                  Years Of Experience
                </label>
                <input
                  type="text"
                  className="form-control"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength="2"
                  value={state.yearsOfExperience}
                  onChange={(e) => {
                    const regex = /\b([1-9]|[12][0-9]|3[0-9])\b/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      onChange(e.target.value, "yearsOfExperience");
                    }
                  }}
                  // onChange={(e) =>
                  //   onChange(e.target.value, "yearsOfExperience")
                  // }
                ></input>
              </Col>
            </Row>
            <div className="text-center mt-3">
              <Button
                style={{ backgroundColor: "#015652", color: "white" }}
                className="my-1"
                type="button"
                onClick={SignUp}
              >
                Sign Up
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SignUp;
// const mapStateToProps = (state) => ({
//   // GetUserJWTLogin: state.JwtCredential.GetUserJWTLogin
// });
// const mapDispatchToPrpos = (dispatch) => {
//   return {
//     SignUpMiddleware: (body, OnSuccess, OnFailure) =>
//       dispatch(SignUpMiddleware(body, OnSuccess, OnFailure)),
//   };
// };
// export default connect(mapStateToProps, mapDispatchToPrpos)(SignUp);
