export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FALURE";
export const FORGET_PASSWORD_PATH = "auth/forgot-password";

export const VERIFY_FORGET_PASSWORD_CODE = "VERIFY_FORGET_PASSWORD_CODE";
export const VERIFY_FORGET_PASSWORD_CODE_SUCCESS =
  "VERIFY_FORGET_PASSWORD_CODE_SUCCESS";
export const VERIFY_FORGET_PASSWORD_CODE_FAILURE =
  "VERIFY_FORGET_PASSWORD_CODE_FAILURE";
export const VERIFY_FORGET_PASSWORD_CODE_PATH = "auth/fp-code-verify";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_PATH = "auth/change-password";
