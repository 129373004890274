import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Headers from "components/Headers/Header1";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import {
  Total_AppReqCountMiddleware,
  Total_BankAppCountMiddleware,
  Total_CustomerAppCountMiddleware,
  Total_RealStateAppCountMiddleware,
  AppReqCountMiddleware,
  BankAppCountMiddleware,
  AgentAppCountMiddleware,
  CustomerAppCountMiddleware,
} from "../middleware";
import { connect } from "react-redux";
import "./style.css";
import { BASEURL } from "../../../../config/api/URL";
import { networkPoll } from "sockets/Networkpolling";
import socketIOClient from "socket.io-client";
import PrintReport from "../components/PrintReport";
const apiPath = "notification/mark-read";

var well = {
  boxShadow:
    "3px 3px 3px 3px rgba(0, 0, 0, 0.16), 3px 3px 3px 3px rgba(0, 0, 0, 0.16)",
  borderRadius: "15px",
};

const Dashboard = (props) => {
  const [reqCount, setreqCount] = useState("");
  const [totalOnline, setTotalOnline] = useState(0);
  const [customerOnline, setCustomerOnline] = useState(0);
  const [agentOnline, setAgentOnline] = useState(0);
  const [bankOnline, setBankOnline] = useState(0);
  const [adminOnline, setAdminOnline] = useState(0);
  const [bankAppCount, setbankAppCount] = useState("");
  const [agentAppCount, setagentAppCount] = useState("");
  const [customerAppCount, setcustomerAppCount] = useState("");
  const [totalAppReqCount, settotalAppReqCount] = useState("");
  const [totalBankAppCount, settotalBankAppCount] = useState("");
  const [totalAgentAppCount, settotalAgentAppCount] = useState("");
  const [totalCustomerAppCount, settotalCustomerAppCount] = useState("");
  const [filter, setFilter] = useState("");
  const [valuesAndKey, setValueAndKey] = useState({});
  const [criteriaType, setCriteriaType] = useState({
    criteria: "user",
  });
  const [yearAppReqCount, setyearAppReqCount] = useState("");
  const [yearBankAppCount, setyearBankAppCount] = useState("");
  const [yearAgentAppCount, setyearAgentAppCount] = useState("");
  const [yearCustomerAppCount, setyearCustomerAppCount] = useState("");
  const [agentStatus, setagentStatus] = useState("");
  const [appReqStatus, setAppReqStatus] = useState("");
  const [customerStatus, setcustomerStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const current = new Date();

  useEffect(() => {
    props.AppReqCountMiddleware(
      appReqStatus,
      valuesAndKey,
      onSuccess,
      onFailure
    );
  }, [valuesAndKey]);
  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      setValueAndKey({
        key: key,
        value: value,
      });
    });
  }, [filter]);
  useEffect(() => {
    setFilter({ [criteriaType.criteria]: "" });
  }, [criteriaType]);
  useEffect(() => {
    setyearAppReqCount(current.getFullYear());
    setyearBankAppCount(current.getFullYear());
    setyearAgentAppCount(current.getFullYear());
    setyearCustomerAppCount(current.getFullYear());
    props.AppReqCountMiddleware(
      appReqStatus,
      valuesAndKey,
      onSuccess,
      onFailure
    );
    props.BankAppCountMiddleware(onSuccessBank, onFailureBank);
    props.AgentAppCountMiddleware(agentStatus, onSuccessAgent, onFailureAgent);
    props.CustomerAppCountMiddleware(
      customerStatus,
      onSuccessCustomer,
      onFailureCustomer
    );
    props._Total_AppReqCountMiddleware(onSuccessCount, onFailureCount);
    props._Total_BankAppCountMiddleware(onSuccessCount, onFailureCount);
    props._Total_CustomerAppCountMiddleware(onSuccessCount, onFailureCount);
    props._Total_RealStateAppCountMiddleware(onSuccessCount, onFailureCount);
  }, []);
  // const onSuccess = (e) => {
  //   setreqCount(e);
  // };
  const onSuccessCount = (e) => {};
  const onFailureCount = (e) => {};
  //Application Request

  useEffect(() => {
    props.BankAppCountMiddleware(onSuccessBank, onFailureBank);
    props.AppReqCountMiddleware(
      appReqStatus,
      valuesAndKey,
      onSuccess,
      onFailure
    );
    props.AgentAppCountMiddleware(agentStatus, onSuccessAgent, onFailureAgent);
    props.CustomerAppCountMiddleware(
      customerStatus,
      onSuccessCustomer,
      onFailureCustomer
    );
  }, [appReqStatus, agentStatus, customerStatus]);

  //Agent Application
  const onSuccessAgent = (e) => {
    setagentAppCount(e);
  };

  useEffect(() => {
    let tempArr = [];
    let i = 0;
    let yearCheck;
    let monthCheck;
    let arr = [
      {
        year: yearAgentAppCount,
        month: 1,
        monthName: "Jan",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 2,
        monthName: "Feb",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 3,
        monthName: "Mar",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 4,
        monthName: "Apr",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 5,
        monthName: "May",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 6,
        monthName: "Jun",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 7,
        monthName: "Jul",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 8,
        monthName: "Aug",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 9,
        monthName: "Sept",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 10,
        monthName: "Oct",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 11,
        monthName: "Nov",
        count: 0,
      },
      {
        year: yearAgentAppCount,
        month: 12,
        monthName: "Dec",
        count: 0,
      },
    ];
    while (i < agentAppCount.length) {
      yearCheck = agentAppCount[i]?.m?.split("-")[0];
      monthCheck = agentAppCount[i]?.m?.split("-")[1];
      if (yearCheck == yearAgentAppCount) {
        function toMonthName(monthNumber) {
          const date = new Date();
          date.setMonth(monthNumber - 1);
          return date.toLocaleString("en-US", {
            month: "long",
          });
        }
        arr.splice(monthCheck - 1, 1, {
          year: yearCheck.toString(),
          month: +monthCheck,
          monthName: toMonthName(+monthCheck),
          count: +agentAppCount[i].count,
        });
        i++;
      } else {
        i++;
      }
    }
    arr.map((item) => {
      tempArr.push(item.count);
    });
    settotalAgentAppCount(tempArr);
  }, [yearAgentAppCount, agentAppCount]);

  useEffect(() => {
    setState({
      ...state,
      agentAppCount: [
        {
          name: "Agent Application Count",
          type: "column",
          color: "#01524f",
          data: [...totalAgentAppCount],
        },
      ],
      optionsMixedChartAgent: {
        yaxis: {
          tickAmount: 10,
          min: 0,
          max: Math.max(...totalAgentAppCount) + 100,
        },
      },
    });
  }, [totalAgentAppCount]);

  //Application Request
  const onSuccess = (e) => {
    setreqCount(e);
  };
  useEffect(() => {
    // let tempArr = [];
    // for (let i = 0; i < reqCount.length; i++) {
    //   if (reqCount[i].m.includes(yearAppReqCount)) {
    //     tempArr.push(Number(reqCount[i].count));
    //   }
    // }
    // settotalAppReqCount(tempArr);
    let tempArr = [];
    let i = 0;
    let yearCheck;
    let monthCheck;
    let arr = [
      {
        year: yearAppReqCount,
        month: 1,
        monthName: "Jan",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 2,
        monthName: "Feb",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 3,
        monthName: "Mar",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 4,
        monthName: "Apr",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 5,
        monthName: "May",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 6,
        monthName: "Jun",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 7,
        monthName: "Jul",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 8,
        monthName: "Aug",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 9,
        monthName: "Sept",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 10,
        monthName: "Oct",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 11,
        monthName: "Nov",
        count: 0,
      },
      {
        year: yearAppReqCount,
        month: 12,
        monthName: "Dec",
        count: 0,
      },
    ];
    while (i < reqCount.length) {
      yearCheck = reqCount[i]?.m?.split("-")[0];
      monthCheck = reqCount[i]?.m?.split("-")[1];
      if (yearCheck == yearAppReqCount) {
        function toMonthName(monthNumber) {
          const date = new Date();
          date.setMonth(monthNumber - 1);
          return date.toLocaleString("en-US", {
            month: "long",
          });
        }
        arr.splice(monthCheck - 1, 1, {
          year: yearCheck.toString(),
          month: +monthCheck,
          monthName: toMonthName(+monthCheck),
          count: +reqCount[i].sum,
        });
        i++;
      } else {
        i++;
      }
    }
    arr.map((item) => {
      tempArr.push(item.count);
    });
    settotalAppReqCount(tempArr);
  }, [yearAppReqCount, reqCount]);

  useEffect(() => {
    setState({
      ...state,
      appReqCount: [
        {
          name: "Application Request Count",
          type: "column",
          data: [...totalAppReqCount],
        },
      ],
      optionsMixedChart: {
        yaxis: {
          tickAmount: 10,
          min: 0,
          max: Math.max(...totalAppReqCount) + 100,
        },
      },
    });
  }, [totalAppReqCount]);

  //Bank Application Count

  const onSuccessBank = (e) => {
    setbankAppCount(e);
  };
  useEffect(() => {
    let tempArr = [];
    let i = 0;
    let yearCheck;
    let monthCheck;
    let arr = [
      {
        year: yearBankAppCount,
        month: 1,
        monthName: "Jan",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 2,
        monthName: "Feb",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 3,
        monthName: "Mar",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 4,
        monthName: "Apr",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 5,
        monthName: "May",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 6,
        monthName: "Jun",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 7,
        monthName: "Jul",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 8,
        monthName: "Aug",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 9,
        monthName: "Sept",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 10,
        monthName: "Oct",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 11,
        monthName: "Nov",
        count: 0,
      },
      {
        year: yearBankAppCount,
        month: 12,
        monthName: "Dec",
        count: 0,
      },
    ];
    while (i < bankAppCount.length) {
      yearCheck = bankAppCount[i]?.m?.split("-")[0];
      monthCheck = bankAppCount[i]?.m?.split("-")[1];
      if (yearCheck == yearBankAppCount) {
        function toMonthName(monthNumber) {
          const date = new Date();
          date.setMonth(monthNumber - 1);
          return date.toLocaleString("en-US", {
            month: "long",
          });
        }
        arr.splice(monthCheck - 1, 1, {
          year: yearCheck.toString(),
          month: +monthCheck,
          monthName: toMonthName(+monthCheck),
          count: +bankAppCount[i].count,
        });
        i++;
      } else {
        i++;
      }
    }
    arr.map((item) => {
      tempArr.push(item.count);
    });
    settotalBankAppCount(tempArr);
  }, [yearBankAppCount, bankAppCount]);

  useEffect(() => {
    setState({
      ...state,
      bankAppCount: [
        {
          name: "Bank Application Count",
          type: "column",
          data: [...totalBankAppCount],
          color: "#8f8e8e",
        },
      ],
      optionsMixedChartBank: {
        yaxis: {
          tickAmount: 10,
          min: 0,
          max: Math.max(...totalBankAppCount) + 100,
        },
      },
    });
  }, [totalBankAppCount]);

  //application customer

  const onSuccessCustomer = (e) => {
    setcustomerAppCount(e);
  };
  useEffect(() => {
    let tempArr = [];
    let i = 0;
    let yearCheck;
    let monthCheck;
    let arr = [
      {
        year: yearCustomerAppCount,
        month: 1,
        monthName: "Jan",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 2,
        monthName: "Feb",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 3,
        monthName: "Mar",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 4,
        monthName: "Apr",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 5,
        monthName: "May",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 6,
        monthName: "Jun",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 7,
        monthName: "Jul",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 8,
        monthName: "Aug",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 9,
        monthName: "Sept",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 10,
        monthName: "Oct",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 11,
        monthName: "Nov",
        count: 0,
      },
      {
        year: yearCustomerAppCount,
        month: 12,
        monthName: "Dec",
        count: 0,
      },
    ];
    while (i < customerAppCount.length) {
      yearCheck = customerAppCount[i]?.m?.split("-")[0];
      monthCheck = customerAppCount[i]?.m?.split("-")[1];
      if (yearCheck == yearCustomerAppCount) {
        function toMonthName(monthNumber) {
          const date = new Date();
          date.setMonth(monthNumber - 1);
          return date.toLocaleString("en-US", {
            month: "long",
          });
        }
        arr.splice(monthCheck - 1, 1, {
          year: yearCheck.toString(),
          month: +monthCheck,
          monthName: toMonthName(+monthCheck),
          count: +customerAppCount[i].count,
        });
        i++;
      } else {
        i++;
      }
    }
    arr.map((item) => {
      tempArr.push(item.count);
    });
    settotalCustomerAppCount(tempArr);
  }, [yearCustomerAppCount, customerAppCount]);

  useEffect(() => {
    setState({
      ...state,
      customerAppCount: [
        {
          name: "Customer Application Count",
          type: "column",
          data: [...totalCustomerAppCount],
          color: "#8f8e8e",
        },
      ],
      optionsMixedChartCustomer: {
        yaxis: {
          tickAmount: 10,
          min: 0,
          max: Math.max(...totalCustomerAppCount) + 100,
        },
      },
    });
  }, [totalCustomerAppCount]);
  const MINUTE_MS = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      getOnlineUsers();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  const getOnlineUsers = () => {
    getTotals();
    getCustomers();
    getAgents();
    getAdmins();
    getBanks();
    // getRealStates()
  };
  const getTotals = () => {
    const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
    const token = localStorage.getItem("auth");

    const socket = socketIOClient(
      `${ENDPOINT}?token=Bearer ${token}`,
      networkPoll
    );
    socket.on(`total-online`, (data) => {
      // play();
      if (data) {
        console.log("Total onlines", data);
        setTotalOnline(data.total_online);
      }
    });
    // socket.close();
  };
  const getCustomers = () => {
    const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
    const token = localStorage.getItem("auth");

    const socket = socketIOClient(
      `${ENDPOINT}?token=Bearer ${token}`,
      networkPoll
    );
    socket.on(`customer-online`, (data) => {
      // play();
      if (data) {
        console.log("Total customer onlines", data);
        setCustomerOnline(data.total_online);
      }
    });
    // socket.close();
  };
  const getAgents = () => {
    const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
    const token = localStorage.getItem("auth");

    const socket = socketIOClient(
      `${ENDPOINT}?token=Bearer ${token}`,
      networkPoll
    );
    socket.on(`estate-online`, (data) => {
      // play();
      if (data) {
        console.log("Total agent onlines", data);
        setAgentOnline(data.total_online);
      }
    });
    // socket.close();
  };
  const getAdmins = () => {
    const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
    const token = localStorage.getItem("auth");

    const socket = socketIOClient(
      `${ENDPOINT}?token=Bearer ${token}`,
      networkPoll
    );
    socket.on(`admin-online`, (data) => {
      // play();
      if (data) {
        console.log("Total agent onlines", data);
        setAdminOnline(data.total_online);
      }
    });
    // socket.close();
  };
  const getBanks = () => {
    const ENDPOINT = "https://backend.khybergolfcity.com/realtime";
    const token = localStorage.getItem("auth");

    const socket = socketIOClient(
      `${ENDPOINT}?token=Bearer ${token}`,
      networkPoll
    );
    socket.on(`bank-online`, (data) => {
      // play();
      if (data) {
        console.log("Total agent onlines", data);
        setBankOnline(data.total_online);
      }
    });
    // socket.close();
  };
  const onFailure = () => {};
  const onFailureBank = () => {};
  const onFailureAgent = () => {};
  const onFailureCustomer = () => {};
  const [state, setState] = useState({
    optionsMixedChartCustomer: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      stroke: {
        width: [4, 0, 0],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      markers: {
        size: 6,
        strokeWidth: 3,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      // yaxis: {
      //   tickAmount: 10,
      //   min: 0,
      //   max:1000,
      // },
    },
    optionsMixedChartAgent: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      stroke: {
        width: [4, 0, 0],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      markers: {
        size: 6,
        strokeWidth: 3,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      // yaxis: {
      //   tickAmount: 10,
      //   min: 0,
      //   max:1000,
      // },
    },
    optionsMixedChartBank: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      stroke: {
        width: [4, 0, 0],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      markers: {
        size: 6,
        strokeWidth: 3,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      // yaxis: {
      //   tickAmount: 10,
      //   min: 0,
      //   max:1000,
      // },
    },
    optionsMixedChart: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      stroke: {
        width: [4, 0, 0],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      markers: {
        size: 6,
        strokeWidth: 3,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      // yaxis: {
      //   tickAmount: 10,
      //   min: 0,
      //   max:1000,
      // },
    },
    appReqCount: [
      {
        name: "Application Request Count",
        type: "column",
        color: "#01524f",
        data: [...totalAppReqCount],
        // data: totalAppReqCount,
      },
    ],
    bankAppCount: [
      {
        name: "Application Count",
        type: "column",
        data: [...totalBankAppCount],
        //data: [93, 12, 94, 71, 92, 56, 81, 19, 45, 76, 23, 43],
      },
    ],
    agentAppCount: [
      {
        name: "Application Count",
        type: "column",

        data: [...totalAgentAppCount],
        //data: [93, 12, 94, 71, 92, 56, 81, 19, 45, 76, 23, 43],
      },
    ],
    customerAppCount: [
      {
        name: "Application Count",
        type: "column",
        data: [...totalCustomerAppCount],
        //data: [93, 12, 94, 71, 92, 56, 81, 19, 45, 76, 23, 43],
      },
    ],
    optionsRadial: {
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#8f8e8e",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#8f8e8e",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -20,
              show: true,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              formatter: function (val) {
                return val;
              },
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
    },
    seriesRadial: [76],
    optionsBar: {
      chart: {
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 0,
      },
      xaxis: {
        categories: ["Fav Color"],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.35,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [90, 0, 100],
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "right",
      },
    },
    seriesBar: [
      {
        name: "green",
        data: [41],
      },
      {
        name: "yellow",
        data: [12],
      },
      {
        name: "red",
        data: [65],
      },
    ],
  });
  const ToggleFormSubmissionModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Headers />
      <PrintReport
        toggle={ToggleFormSubmissionModal}
        // _BallotingMiddleware={_BallotingMiddleware}
        modal={isOpen}
        // plotType={plotType}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="10" md="10" sm="10">
                    <h1 className="mb-0">Dashboard</h1>
                  </Col>
                  <Col lg="2" md="2" sm="2">
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      size="md"
                      id="search"
                      onClick={() => ToggleFormSubmissionModal()}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">Print Report</span>
                    </Button>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col lg="2" md="3" sm="10">
                    <Card className="card-stats mb-4 mb-xl-0 " style={well}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-green text-uppercase text-muted mb-0"
                            >
                              <b>Total online</b>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalOnline}
                            </span>
                          </div>
                        </Row>
                        <br />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2" md="3" sm="10"></Col>
                  <Col lg="2" md="3" sm="10">
                    <Card className="card-stats mb-4 mb-xl-0 " style={well}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-blue text-uppercase text-muted mb-0"
                            >
                              <b>Application Request</b>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props?.Total_req?.sum}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2" md="3" sm="10">
                    <Card className="card-stats mb-4 mb-xl-0 " style={well}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-blue text-uppercase text-muted mb-0"
                            >
                              <b>
                                Bank <br />
                                Application
                              </b>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props?.BankCount_req?.count}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2" md="3" sm="10">
                    <Card className="card-stats mb-4 mb-xl-0 " style={well}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-blue text-uppercase text-muted mb-0"
                            >
                              <b>Customer Application</b>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props?.CustomerCount_req?.count}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2" md="3" sm="10">
                    <Card className="card-stats mb-4 mb-xl-0 " style={well}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-blue text-uppercase text-muted mb-0"
                            >
                              <b>Real State Application</b>
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props?.RealStateCount_req?.count}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginTop: "4%" }}>
                  <Col lg="3" md="3" sm="3">
                    <Card
                      className="card-stats mb-4 mb-xl-0 "
                      style={{
                        textAlign: "center",
                        boxShadow:
                          "3px 3px 3px 3px rgba(0, 0, 0, 0.16), 3px 3px 3px 3px rgba(0, 0, 0, 0.16)",
                        borderRadius: "15px",
                      }}
                    >
                      <CardBody>
                        <Row>
                          <div
                            style={{ marginBottom: "-30px" }}
                            className="col"
                          >
                            <CardTitle
                              tag="h5"
                              className="text-green text-uppercase text-muted mb-0"
                            >
                              <b>Customer online</b>
                            </CardTitle>
                            <span
                              className="h2 font-weight-bold"
                              style={{ marginTop: "2%" }}
                            >
                              {customerOnline}
                            </span>
                          </div>
                        </Row>
                        <br />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="3" sm="3">
                    <Card
                      className="card-stats mb-4 mb-xl-0 "
                      style={{
                        textAlign: "center",
                        boxShadow:
                          "3px 3px 3px 3px rgba(0, 0, 0, 0.16), 3px 3px 3px 3px rgba(0, 0, 0, 0.16)",
                        borderRadius: "15px",
                      }}
                    >
                      <CardBody>
                        <Row>
                          <div
                            style={{ marginBottom: "-30px" }}
                            className="col"
                          >
                            <CardTitle
                              tag="h5"
                              className="text-green text-uppercase text-muted mb-0"
                            >
                              <b>Agent online</b>
                            </CardTitle>
                            <span
                              className="h2 font-weight-bold"
                              style={{ marginTop: "2%" }}
                            >
                              {agentOnline}
                            </span>
                          </div>
                        </Row>
                        <br />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="3" sm="3">
                    <Card
                      className="card-stats mb-4 mb-xl-0 "
                      style={{
                        textAlign: "center",
                        boxShadow:
                          "3px 3px 3px 3px rgba(0, 0, 0, 0.16), 3px 3px 3px 3px rgba(0, 0, 0, 0.16)",
                        borderRadius: "15px",
                      }}
                    >
                      <CardBody>
                        <Row>
                          <div
                            style={{ marginBottom: "-30px" }}
                            className="col"
                          >
                            <CardTitle
                              tag="h5"
                              className="text-green text-uppercase text-muted mb-0"
                            >
                              <b>Bank online</b>
                            </CardTitle>
                            <span
                              className="h2 font-weight-bold"
                              style={{ marginTop: "2%" }}
                            >
                              {bankOnline}
                            </span>
                          </div>
                        </Row>
                        <br />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="3" sm="3">
                    <Card
                      className="card-stats mb-4 mb-xl-0 "
                      style={{
                        textAlign: "center",
                        boxShadow:
                          "3px 3px 3px 3px rgba(0, 0, 0, 0.16), 3px 3px 3px 3px rgba(0, 0, 0, 0.16)",
                        borderRadius: "15px",
                      }}
                    >
                      <CardBody>
                        <Row>
                          <div
                            style={{ marginBottom: "-30px" }}
                            className="col"
                          >
                            <CardTitle
                              tag="h5"
                              className="text-green text-uppercase text-muted mb-0"
                            >
                              <b>Admin online</b>
                            </CardTitle>
                            <span
                              className="h2 font-weight-bold"
                              style={{ marginTop: "2%" }}
                            >
                              {adminOnline}
                            </span>
                          </div>
                        </Row>
                        <br />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col>
                    <h2 style={{ textAlign: "center" }}>
                      Application Request Count
                    </h2>
                    <Row>
                      <Col lg="3" md="3" sm="10">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          Role
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) =>
                            setCriteriaType({
                              ...criteriaType,
                              criteria: e.target.value,
                            })
                          }
                        >
                          <option value="user">Customer</option>
                          <option value="manager">Bank</option>
                          <option value="agent">Agent</option>
                        </select>
                      </Col>
                      <Col lg="3" md="3" sm="10">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          Year
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => setyearAppReqCount(e.target.value)}
                        >
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                        </select>
                      </Col>
                      <Col lg="3" md="3" sm="10">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          status
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => setAppReqStatus(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="Pending">Pending</option>
                          <option value="Paid">Paid</option>
                        </select>
                      </Col>
                    </Row>

                    <Chart
                      options={state.optionsMixedChart}
                      series={state.appReqCount}
                      type="line"
                      width="500"
                    />
                  </Col>
                  <Col>
                    <h2 style={{ textAlign: "center" }}>
                      Application Count Bank
                    </h2>
                    <Row>
                      <Col lg="3" md="3" sm="10">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          Year
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => setyearBankAppCount(e.target.value)}
                        >
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                        </select>
                      </Col>
                    </Row>

                    <Chart
                      options={state.optionsMixedChartBank}
                      series={state.bankAppCount}
                      type="line"
                      width="500"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 style={{ textAlign: "center" }}>
                      Application Count Customer
                    </h2>
                    <Row>
                      <Col lg="3" md="3" sm="10">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          Year
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) =>
                            setyearCustomerAppCount(e.target.value)
                          }
                        >
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                        </select>
                      </Col>
                      <Col lg="3" md="3" sm="10">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          status
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => setcustomerStatus(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="PAYMENT_PENDING">Pending</option>
                          <option value="APPLIED">Applied</option>
                          <option value="DRAFT">Draft</option>
                          <option value="NEW">New</option>
                        </select>
                      </Col>
                    </Row>

                    <Chart
                      options={state.optionsMixedChartCustomer}
                      series={state.customerAppCount}
                      type="line"
                      width="500"
                    />
                  </Col>
                  <Col>
                    <h2 style={{ textAlign: "center" }}>
                      Application Count Agent
                    </h2>
                    <Row>
                      <Col lg="3" md="3" sm="10">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          Year
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => setyearAgentAppCount(e.target.value)}
                        >
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                        </select>
                      </Col>
                      <Col lg="3" md="3" sm="10">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          status
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => setagentStatus(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="new">New</option>
                          <option value="submitted">Submitted</option>
                        </select>
                      </Col>
                    </Row>

                    <Chart
                      options={state.optionsMixedChartAgent}
                      series={state.agentAppCount}
                      type="line"
                      width="500"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
  // }
};

const mapStateToProps = (state) => ({
  Total_req: state.dashboardRed.Total_req,
  BankCount_req: state.dashboardRed.BankCount_req,
  CustomerCount_req: state.dashboardRed.CustomerCount_req,
  RealStateCount_req: state.dashboardRed.RealStateCount_req,
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    AppReqCountMiddleware: (valuesAndKey, onSuccess, onFailure) =>
      dispatch(AppReqCountMiddleware(valuesAndKey, onSuccess, onFailure)),
    BankAppCountMiddleware: (valuesAndKey, onSuccess, onFailure) =>
      dispatch(BankAppCountMiddleware(valuesAndKey, onSuccess, onFailure)),
    _Total_AppReqCountMiddleware: (onSuccess, onFailure) =>
      dispatch(Total_AppReqCountMiddleware(onSuccess, onFailure)),
    _Total_BankAppCountMiddleware: (onSuccess, onFailure) =>
      dispatch(Total_BankAppCountMiddleware(onSuccess, onFailure)),
    _Total_RealStateAppCountMiddleware: (onSuccess, onFailure) =>
      dispatch(Total_RealStateAppCountMiddleware(onSuccess, onFailure)),
    _Total_CustomerAppCountMiddleware: (onSuccess, onFailure) =>
      dispatch(Total_CustomerAppCountMiddleware(onSuccess, onFailure)),

    AgentAppCountMiddleware: (valuesAndKey, onSuccess, onFailure) =>
      dispatch(AgentAppCountMiddleware(valuesAndKey, onSuccess, onFailure)),
    CustomerAppCountMiddleware: (valuesAndKey, onSuccess, onFailure) =>
      dispatch(CustomerAppCountMiddleware(valuesAndKey, onSuccess, onFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Dashboard);
