import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  VERIFY_FORGET_PASSWORD_CODE,
  VERIFY_FORGET_PASSWORD_CODE_SUCCESS,
  VERIFY_FORGET_PASSWORD_CODE_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "../constant.js";

export class ForgetPass {
  static ForgetPassword() {
    return {
      type: FORGET_PASSWORD,
    };
  }
  static ForgetPasswordSuccess(response) {
    return {
      type: FORGET_PASSWORD_SUCCESS,
      payload: response,
    };
  }
  static ForgetPasswordFailure(error) {
    return {
      type: FORGET_PASSWORD_FAILURE,
      error,
    };
  }
}

export class VerifyForgetPassCode {
  static ForgetPasswordCode() {
    return {
      type: VERIFY_FORGET_PASSWORD_CODE,
    };
  }
  static ForgetPasswordCodeSuccess(response) {
    return {
      type: VERIFY_FORGET_PASSWORD_CODE_SUCCESS,
      payload: response,
    };
  }
  static ForgetPasswordCodeFailure(error) {
    return {
      type: VERIFY_FORGET_PASSWORD_CODE_FAILURE,
      error,
    };
  }
}

export class ResetPassword {
  static _ResetPassword() {
    return {
      type: RESET_PASSWORD,
    };
  }
  static _ResetPasswordSuccess(response) {
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload: response,
    };
  }
  static _ResetPasswordFailure(error) {
    return {
      type: RESET_PASSWORD_FAILURE,
      error,
    };
  }
}
