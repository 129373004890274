import axios from "axios";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CardBody,
  Card,
  Table,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import { useState, useEffect } from "react";
// import { BASEURL, RECORDING_BASE_URL } from "config/api/URL";
// import { RECORDING_PATH } from "../constant";
import { RECORDING_BASE_URL, BASEURL } from "config/api/URL";
import moment from "moment";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import swal from "sweetalert";
import ImageModal from "components/Support/Querieslist/components/showModal";
const Information = ({ viewReqList, modal, toggle, resolve, callback }) => {
  const [url, setUrl] = useState("");
  const [showImage, setShowImge] = useState(false);
  console.log("RESOLVE MDDLE", resolve);
  const onSuccess = () => {
    swal({
      title: "Success!",
      text: "Issue resolved Successfully.",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        callback();
        toggle();
      } else {
        callback();
        toggle();
      }
    });
  };
  const onFailure = (error) => {
    swal("Sorry!", error.message, "error");
  };
  const handleResolve = (id) => {
    resolve.Resolve_Middleware(id, onSuccess, onFailure);
    // let token = localStorage.getItem("auth");
    // const RESOLVE_PATH = "support-system/resolve";
    // axios
    //   .patch(`${BASEURL}${RESOLVE_PATH}/${id}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `bearer ${token}`,
    //     },
    //   })
    //   .then((res) => {
    //     console.log("Resolve data", res.data.data);
    //     if (res.status === 200) {
    //       // dispatch(Resolveissue.ResolveSuccess(res.data.data.items));
    //       // dispatch(onSuccess(res.data.message));
    //     }
    //   })
    //   .catch(function (error) {
    //     if (error.response) {
    //       console.log("ERR", error.response);
    //       // onFailure(error.response.data);
    //     }
    //   });
  };
  const showModal = (url) => {
    setShowImge(!showImage);
    setUrl(url);
  };
  return (
    <>
      <ImageModal toggle={showModal} modal={showImage} url={url} />
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader style={{ backgroundColor: "#d69929" }} toggle={toggle}>
          <h3 style={{ color: "white" }}>Details Of query</h3>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col lg="2" md="2" sm="6">
              <label>ID:</label>
            </Col>
            <Col lg="4" md="4" sm="6">
              <label>{viewReqList?.id}</label>
            </Col>
          </Row>
          <Row>
            <Col lg="2" md="2" sm="6">
              <label>Issue Type:</label>
            </Col>
            <Col lg="4" md="4" sm="6">
              <label>{viewReqList?.issueType?.name}</label>
            </Col>
          </Row>
          {/* <Row>
            <Col lg="2" md="2" sm="6">
              <label>User Name:</label>
            </Col>
            <Col lg="4" md="4" sm="6">
              <label>{viewReqList?.user?.fullName}</label>
            </Col>
          </Row> */}
          <Row>
            <Col lg="2" md="2" sm="6">
              <label>User CNIC:</label>
            </Col>
            <Col lg="4" md="4" sm="6">
              <label>{viewReqList?.cnic}</label>
            </Col>
          </Row>
          <Row>
            <Col lg="2" md="2" sm="6">
              <label>User Email:</label>
            </Col>
            <Col lg="4" md="4" sm="6">
              <label>{viewReqList?.email}</label>
            </Col>
          </Row>

          <Row>
            <Col lg="2" md="2" sm="2">
              <label>Query:</label>
            </Col>
            <Col lg="8" md="8" sm="8">
              <p style={{ wordWrap: "break-word" }}>{viewReqList?.query}</p>
            </Col>
          </Row>
          {/* <Row>
            <Col lg="2" md="2" sm="2">
              <label>Reply:</label>
            </Col>
            <Col lg="8" md="8" sm="8">
              {viewReqList?.reply !== null ? (
                <p style={{ wordWrap: "break-word" }}>{viewReqList?.reply}</p>
              ) : (
                <span>Reply is not yet recieved</span>
              )}
            </Col>
          </Row> */}
          <Row>
            <Col lg="2" md="2" sm="2">
              <label>Images:</label>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Table>
                <tbody>
                  {viewReqList?.queryFiles?.length > 0 ? (
                    viewReqList.queryFiles.map((filePath, index) => {
                      let split = "screenshot2323232323";
                      return (
                        <tr key={index}>
                          <td>{`${split} (${index}).png`}</td>
                          <td>
                            <a
                              href={filePath}
                              className="fas fa-cloud-download-alt"
                              target="_blank"
                            ></a>
                          </td>
                          <td>
                            <i
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                showModal(filePath);
                              }}
                              className="fas fa-solid fa-eye"
                            ></i>
                            {/* <
                            href={filePath}
                            className="fas fa-solid fa-eye"
                            // target="_blank"
                          /> */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div>Images not found</div>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col lg="5" md="5" sm="5">
              <Button
                disabled={viewReqList?.isResolved}
                style={{
                  backgroundColor: "#015652",
                  color: "white",
                  // float: "right",
                  justifyContent: "flex-end",
                  alignSelf: "flex-end",
                }}
                size="md"
                id="search"
                onClick={() => handleResolve(viewReqList?.id)}
              >
                <span className="btn-inner--icon">Resolve</span>
              </Button>
            </Col>
          </Row>
        </ModalBody>
        {/* <ModalFooter style={{ backgroundColor: "#d69929" }}>
        <Button
          style={{ backgroundColor: "#015652", color: "white" }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter> */}
      </Modal>
    </>
  );
};

export default Information;
