import { combineReducers } from "redux";

import Login from "views/UserViews/Login/reducer";
import AgentLogin from "views/AgentView/Login/reducer";
import StaffLogin from "views/StaffViews/StaffLogin/reducer";
import Otp from "views/UserViews/OTP/reducer";
import MembershipForm from "views/UserViews/MembershipForm/reducer";
import CustomerRequest from "views/UserViews/Request/reducer";
import PaymentReceipt from "views/PaymentReceipt/reducer";
import ApplicationList from "views/UserViews/ApplicationList/reducer";
import AdminRequestList from "views/AdminView/AdminApplicationList/reducer";
import ViewPaymentReceipt from "views/ViewPaymentReceipt/reducer";
import SignUp from "views/UserViews/SignUp/reducer";
import Bank from "views/AdminView/Bank/reducer";
import BankList from "views/AdminView/BankList/reducer";
import BranchList from "views/AdminView/BranchList/reducer";
import Branch from "views/AdminView/Branch/reducer";
import StaffList from "views/AdminView/StaffList/reducer";
import ManagerList from "views/AdminView/ManagerList/reducer";
import ApplicationListAdmin from "views/AdminView/ApplicationList/reducer";
import CustomerListAdmin from "views/AdminView/CustomerList/reducer";
import Customer from "views/AdminView/Customer/reducer";
import Payment from "views/UserViews/Payment/reducer";
import PaymentInfo from "views/UserViews/PaymentInfo/reducer";
import StaffRequest from "views/StaffViews/StaffRequest/reducer";
import StaffAppList from "views/StaffViews/StaffSearch/reducer";
import AgentSignUp from "views/AgentView/SignUp/reducer";
import AgentList from "views/AdminView/AgentList/reducer";
import PricingModule from "views/AdminView/Pricing/reducer";
import AgentRequest from "views/AgentView/AgentRequest/reducer";
import ManagerRequest from "views/ManagerView/ManagerRequest/reducer";
import ManagerAppList from "views/ManagerView/ManagerFormList/reducer";
import AgentStaffList from "views/AgentView/AgentStaffList/reducer";
import AgentStaff from "views/AgentView/Staff/reducer";
import AgentListAdmin from "views/AgentView/AgentApplicationList/reducer";
import BankAppList from "views/AdminView/BankAppList/reducer";
import BankAppDetailsList from "views/AdminView/BankAppDetailsList/reducer";
import AgentStaffListAdmin from "views/AdminView/AgentStaffListAdmin/reducer";
import AgentStaffAdmin from "views/AdminView/AgentStaff/reducer";
import AdminFeedbackList from "views/AdminView/FeedbackList/reducer";
import notificationList from "../../components/Navbars/AdminNavbar/reducer";
import AgentAppList from "views/AdminView/AgentApplicationList/reducer";
import createIssue from "../../components/Support/Createquery/reducer";
import getIssueList from "../../components/Support/Sendquery/reducer";
import adminqueryList from "../../components/Support/Adminlist/reducer";
import userqueryList from "../../components/Support/Querieslist/reducer";
import DashboardRed from "views/AdminView/Dashboard/reducer";
import Balloting from "views/AdminView/Balloting/reducer";
import Verify_blocked from "views/AdminView/Verify_Blocked/reducer";
import BankRegistrationReducer from "views/AdminView/BankRegistration/reducer";
import FetchBanksReducer from "views/AdminView/FormGenerate/reducer";
import FormExport from "views/AdminView/FormExport/reducer";
import FormImport from "views/AdminView/FormImport/reducer";
import DealerReg from "views/AdminView/DealerRegistration/reducer";
import RealStateDealers from "views/AdminView/RealStateDealer/reducer";

export default combineReducers({
  agentLogin: AgentLogin,
  login: Login,
  staffLogin: StaffLogin,
  otp: Otp,
  paymentReceipt: PaymentReceipt,
  viewPaymentReceipt: ViewPaymentReceipt,
  customerApplicationList: ApplicationList,
  notificationList: notificationList,
  adminRequestList: AdminRequestList,
  CustomerMembershipForm: MembershipForm,
  signUp: SignUp,
  customerRequest: CustomerRequest,
  bank: Bank,
  staffList: StaffList,
  managerList: ManagerList,
  bankList: BankList,
  branchList: BranchList,
  branch: Branch,
  applicationListAdmin: ApplicationListAdmin,
  customerListAdmin: CustomerListAdmin,
  customer: Customer,
  payment: Payment,
  paymentInfo: PaymentInfo,
  staffRequest: StaffRequest,
  staffAppList: StaffAppList,
  agentSignUp: AgentSignUp,
  agentList: AgentList,
  agentRequest: AgentRequest,
  pricingModule: PricingModule,
  managerRequest: ManagerRequest,
  managerAppList: ManagerAppList,
  agentStaffList: AgentStaffList,
  agentStaff: AgentStaff,
  agentApplicationList: AgentListAdmin,
  agentStaffListAdmin: AgentStaffListAdmin,
  agentStaffAdmin: AgentStaffAdmin,
  bankAppList: BankAppList,
  bankAppDetailsList: BankAppDetailsList,
  adminFeedbackList: AdminFeedbackList,
  agentAppList: AgentAppList,
  createIssue: createIssue,
  getIssueList: getIssueList,
  adminqueryList: adminqueryList,
  userqueryList: userqueryList,
  dashboardRed: DashboardRed,
  balloting: Balloting,
  verify_blocked: Verify_blocked,
  BankRegistrationReducer: BankRegistrationReducer,
  FetchBanksReducer: FetchBanksReducer,
  FormExport: FormExport,
  FormImport: FormImport,
  DealerReg: DealerReg,
  RealStateDealers: RealStateDealers,
});
