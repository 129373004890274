import {
 
  BRANCH,BRANCH_SUCCESS,BRANCH_FAILURE, 
  BRANCHEDIT,BRANCHEDIT_SUCCESS,BRANCHEDIT_FAILURE
} from "../constant";
const INITIAL_STATE = {

  branchRes:null,
  edit:null,
};
export default (states = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return states;
      case BRANCHEDIT:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          edit: null,
          error: {},
        };
      case BRANCHEDIT_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          edit: action.payload,
        };
      case BRANCHEDIT_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };


  case BRANCH:
        return {
          ...states,
          isLoading: true,
          isError: false,
          isLoggedIn: false,
          branchRes: null,
          error: {},
        };
      case BRANCH_SUCCESS:
        return {
          ...states,
          isLoading: false,
          isLoggedIn: true,
          branchRes: action.payload,
        };
      case BRANCH_FAILURE:
        return {
          ...states,
          isLoading: false,
          isError: true,
          error: action.error,
        };

     
  }
};
