import React, { useState, useEffect } from "react";
import { CardBody, Button, CardHeader, Row, Col, Input } from "reactstrap";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import TableOfApplicationList from "./TableOfAgentList";
import { useHistory } from "react-router-dom";



const Filteration = (props) => {
  const history = useHistory();
  const [filter, setFilter] = useState("");
  const [noOfRows, setnoOfRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [valuesAndKey, setValueAndKey] = useState({});
  const [criteriaType, setCriteriaType] = useState({
    criteria: "",
  });
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  useEffect(() => {
    setPageNumber(1)
    props.GetAgentListMiddleware(pageNumber, noOfRows, valuesAndKey,  onSuccess, onFailure);
  }, [ valuesAndKey]);
  useEffect(() => {
    
    props.GetAgentListMiddleware(pageNumber, noOfRows, valuesAndKey,  onSuccess, onFailure);
  }, [pageNumber, noOfRows]);
   useEffect(() => {
 
     Object.entries(filter).forEach(([key, value]) => {
       setValueAndKey({
         key: key,
         value: value,
       });
     });
   }, [filter]);
   useEffect(() => {
    setFilter({ [criteriaType.criteria]: ""})
   }, [criteriaType])
   useEffect(() => {
    props.GetAgentListMiddleware(pageNumber, noOfRows, valuesAndKey,  onSuccess, onFailure);
   }, [props.verify])
   




  const toggler = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    if (props.List!==null && props.List!==undefined) {
      setPosts(props.List);
    }
  }, [props.List]);
  console.log(props.List,"props.list")
  // const getReceiptByFields = () => {

  //   let filterPosts = props.List.filter((i) => {
  //     if (filter == i?.id) {
  //       return i;
  //     } else if (filter == i?.name?.toLowerCase()) {
  //       return i;
  //     } else if (filter == i?.cnic) {
  //       return i;
  //     } else if (filter == "") {
  //       return i;
  //     }
  //   });
  //   setPosts(filterPosts);
  // };


 



  

  ///////////----------Pagination--------------//////////////

  let postNumber = 10;
  let paginatedPosts, total_pages;

  if (noOfRows != "") {
    postNumber = noOfRows;
  }
  const start = pageNumber * postNumber - postNumber;
  const end = start + postNumber;

  if (posts) {
    paginatedPosts = posts;
    // paginatedPosts = posts?.slice(start, end);
    total_pages = Math.ceil(posts.length / postNumber);
  }

  ///////////----------Functions--------------//////////////

  const onChangeNoOfRows = (val) => {
    setnoOfRows(parseInt(val));
    setPageNumber(1);
  };
  const handlePrev = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber) {
      setPageNumber(pageNumber + 1);
    } else {
      return;
    }
  };
  const onSuccess = () => {};
  const onFailure = () => {};


 
  return (
    <CardHeader className="border-0">
    <Row>
      <Col  lg="2" md="2" sm="6">
        <h2>
        Agent List 
        </h2>
      </Col>
      <Col  lg="8" md="8" sm="6"></Col>
      <Col  lg="2" md="2" sm="6">
   
      </Col>
      
    </Row>
    <hr/>
      <CardBody>
      
      <Row>
      <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by Selection
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setCriteriaType({
                          ...criteriaType,
                          criteria: e.target.value,
                        })
                      }
                    >
                      <option value="">Search Criteria</option>
                      <option value="fullName">Name</option>
                      <option value="cnic">CNIC</option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                      <option value="isVerified">Status</option>
                    </select>
                  </Col>
      <Col lg="4" md="4" sm="4">
                    <label className="form-control-label" for="input-username">
                      Search by
                    </label> 
                    <input
                      type="text"
                      id="input-username"
                      className="form-control"
                      placeholder={criteriaType.criteria=="fullName"?"Name":criteriaType.criteria=="cnic"?"XXXXXXXXXXXXX":criteriaType.criteria=="phone"?"92XXXXXXXXXX":criteriaType.criteria=="email"?"abc@xyz.com":criteriaType.criteria=="isVerified"?"Verified/Unverified":""}
                      value={filter[criteriaType.criteria]}
                      onChange={(e) =>
                        setFilter({ [criteriaType.criteria]: e.target.value })
                      }
                    ></input>
                    <br />
                  </Col>
                
                </Row>
      </CardBody>
      <CardBody>
        <Row>
          <Col lg="2" md="2" sm="4" xs="4">
            <label className="form-control-label"> Rows Per Pages </label>
            <Input
              id="exampleFormControlSelect1"
              type="select"
              onChange={(e) => onChangeNoOfRows(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </Col>
        </Row>
        <br />
        <TableOfApplicationList
       
          paginatedPosts={paginatedPosts}
          AgentVerifyMiddleware={props.AgentVerifyMiddleware} verify={props.verify}
         
        /> 
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">Page {pageNumber}</li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" onClick={handlePrev}>
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" onClick={handleNext}>
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </CardBody>
    </CardHeader>
  );
};
export default Filteration;
