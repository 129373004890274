import {
  CREATE_QUERY,
  CREATE_QUERY_SUCCESS,
  CREATE_QUERY_FAILURE,
  CREATE_QUERY_PATH,
} from "../constant.js";
export class CreateQuery {
  static Create() {
    return {
      type: CREATE_QUERY,
    };
  }
  static CreateSuccess(response) {
    return {
      type: CREATE_QUERY_SUCCESS,
      payload: response,
    };
  }
  static CreateSuccess(error) {
    return {
      type: CREATE_QUERY_FAILURE,
      error,
    };
  }
}
