import {
  PRINT_EXCEL,
  PRINT_EXCEL_SUCCESS,
  PRINT_EXCEL_FAILURE,
} from "../constant";

export class PrintExcel {
  static _PrintExcel() {
    return {
      type: PRINT_EXCEL,
    };
  }
  static _PrintExcel_Success(response) {
    return {
      type: PRINT_EXCEL_SUCCESS,
      payload: response,
    };
  }
  static _PrintExcel_Failure(error) {
    return {
      type: PRINT_EXCEL_FAILURE,
      error,
    };
  }
}
