import { connect } from "react-redux";
import Payment from "./Payment";

import { InsertCustomerMiddleware,EditCustomerMiddleware} from "../middleware";


const mapStateToProps = (state) => ({

  customer:state.payment.customer,
edit:state.payment.edit,

 
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    EditCustomerMiddleware:(id,body,onSuccess,onFailure)=>
    dispatch(EditCustomerMiddleware(id, body, onSuccess, onFailure)),
    InsertCustomerMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(InsertCustomerMiddleware(body, OnSuccess, OnFailure)),
   };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(Payment);
