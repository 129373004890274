import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import validate from "../../../../components/Utilities Component/ValidationWrapper";
import Logo from "assets/img/whiteee1.png";
import { useHistory, Redirect } from "react-router-dom";
import axios from "axios";
import { BASEURL } from "config/api/URL";
import swal from "sweetalert";
import { connect } from "react-redux";
import { JwtDashboard } from "../../../../store/helpers/JwtTimer/middleware";
import firebase from "config/firebase";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { SignUpMiddleware } from "../middleware";
import Loading from "components/Loader/Loading";
const SignUp = (props) => {
  //hooks import
  const [loading, SetLoading] = useState(false);
  let history = useHistory();
  const notify = useRef(null);
  const [checked, setChecked] = useState(false);
  const handleChecked = () => {
    setChecked(!checked);
  };
  useEffect(() => {
    setstate({ ...state, whatsAppNo: checked == true ? state.phone : "92" });
  }, [checked]);
  const [type, settype] = useState(false);
  const handleClick = () => {
    // settype({...type,inputType:"password"? "text":"password"})
    settype(!type);
  };
  const [type1, settype1] = useState(false);
  const handleClick1 = () => {
    // settype({...type,inputType:"password"? "text":"password"})
    settype1(!type1);
  };
  const [state, setstate] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    nameError: "",
    cnicError: "",
    phoneError: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
    whatsAppNoError: "",
  });
  const CheckFields = (name) => {
    if (name === "email") {
      setError({
        ...error,
        emailError: validate("email", state.email),
      });
    } else if (name === "password") {
      setError({
        ...error,
        passwordError: validate("signupPassword", state.password),
      });
    } else if (name === "name") {
      setError({
        ...error,
        nameError: validate("fullName", state.fullName),
      });
    } else if (name === "cnic") {
      setError({
        ...error,
        cnicError: validate("CNIC", state.cnic),
      });
    } else if (name === "phone") {
      setError({
        ...error,
        phoneError: validate("phoneNumber", state.phone),
      });
    } else if (name === "whatsAppNo") {
      setError({
        ...error,
        whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
      });
    } else if (name === "confirmPassword") {
      if (state.confirmPassword !== "") {
        setError({
          ...error,
          confirmPasswordError:
            state.password != state.confirmPassword
              ? "Mismatch Password"
              : null,
          // validate("confirmSignupPassword",state.confirmPassword),
        });
      } else {
        setError({
          ...error,
          confirmPasswordError:
            state.confirmPassword == "" ? "Enter Confirm Password" : null,
        });
      }
    }
  };
  const onChange = (val, name) => {
    setstate({ ...state, [name]: val });
  };
  const SignUp = () => {
    if (
      state.fullName == "" ||
      state.password == "" ||
      state.email == "" ||
      state.cnic == "" ||
      state.phone == "" ||
      state.confirmPassword == "" ||
      state.confirmPassword == null
      // state.whatsAppNo == ""
    ) {
      setError({
        ...error,
        emailError: validate("email", state.email),
        passwordError: validate("signupPassword", state.password),
        nameError: validate("fullName", state.fullName),
        cnicError: validate("CNIC", state.cnic),
        phoneError: validate("phoneNumber", state.phone),
        // whatsAppNoError: validate("phoneNumber", state.whatsAppNo),
        confirmPasswordError: validate(
          "confirmSignupPassword",
          state.confirmPassword,
          state.password
        ),
      });
    } else if (state?.whatsAppNo?.trim().length <= 11) {
      swal("Sorry!", "Please Enter valid Whatsapp No.", "error");
      // setstate({ ...state, status: "" });
    } else if (state?.phone?.trim().length <= 11) {
      // delete getlead.profession;
      swal("Sorry!", "Invalid phone number", "error");
    } else if (state.password !== state.confirmPassword) {
      swal("Sorry!", "Password should be matched.", "error");
    } else if (
      error.emailError == null &&
      error.passwordError == null &&
      error.nameError == null &&
      error.cnicError == null &&
      error.phoneError == null &&
      error.confirmPasswordError == null
      // error.whatsAppNoError == null
    ) {
      let body = {
        fullName: state.fullName,
        cnic: state.cnic,
        email: state.email,
        phone: "+" + state.phone,
        whatsAppNo: "+" + state.whatsAppNo,
        password: state.password,
      };
      console.log(body);
      window.fbq("track", "Unverified_Customer");
      SetLoading(true);
      props.SignUpMiddleware(body, onSuccess, onFailure);
    }
  };

  const onSuccess = () => {
    SetLoading(false);
    swal({
      title: "Congratulations!",
      text: "You have Sign up successfully",
      icon: "success",
      // buttons : true,
    }).then((isOk) => {
      if (isOk) {
        let path = "/auth/otp";
        // history.push(path);
        history.push({
          pathname: path,
          email: state.email,
        });
      }
    });
  };
  const onFailure = (error) => {
    SetLoading(false);
    swal("Sorry!", error.message, "error");
  };
  return (
    <>
      <Col lg="6" md="7">
        <Card className="customLogin">
          <CardBody className="px-lg-5 py-lg-2">
            <div className="text-center text-muted mb-4">
              <img height="170px" width="170px" src={Logo} /> <br />
              <h3 style={{ color: "white" }}>
                <b>Sign Up</b>
              </h3>
            </div>
            <Row style={{ marginTop: -16 }} className="text-center">
              {/* <Col xs="12">
                <a className="text-light" href="/auth/otp">
                  <small style={{ color: "black" }}>OTP</small>
                </a>
              </Col> */}
              <Col xs="12">
                <a
                  className="text-light"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/auth/login");
                  }}
                >
                  <b style={{ color: "black" }}>
                    <u>Already have an account? </u>{" "}
                    <span style={{ color: "white" }}>click here</span>
                  </b>
                </a>
              </Col>
            </Row>
            <Row
              style={{ marginTop: 20 }}
              //</CardBody>className="mt-2 text-center"
            >
              <Col lg="12" md="12" sm="12">
                <h5 style={{ textAlign: "justify", color: "white" }}>
                  Note: Password must contain at least 1 upper case letter and
                  lower case letters, password must be longer than 8 characters.
                </h5>
              </Col>
            </Row>
            <Form style={{ marginTop: 15 }} role="form">
              <Row>
                <Col lg="6" md="6" sm="12">
                  <Label
                    style={{ color: "white" }}
                    className="form-control-label"
                    for="input-username"
                  >
                    Full Name *
                  </Label>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Enter name here"
                        type="text"
                        onBlur={() => CheckFields("name")}
                        value={state.fullName}
                        onChange={(e) => {
                          let val = e.target.value;

                          val = val.replace(/[^A-Za-z ]/gi, "");
                          onChange(val, "fullName");
                        }}
                      />
                    </InputGroup>
                    {error.nameError !== "" && error.nameError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.nameError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <Label
                    style={{ color: "white" }}
                    className="form-control-label"
                    for="input-username"
                  >
                    CNIC *
                  </Label>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Enter CNIC here"
                        type="text"
                        maxLength={13}
                        onBlur={() => CheckFields("cnic")}
                        value={state.cnic}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => onChange(e.target.value, "cnic")}
                      />
                    </InputGroup>
                    {error.cnicError !== "" && error.cnicError !== null && (
                      <small style={{ marginTop: "2px" }}>
                        <span style={{ color: "red" }}>
                          {error.cnicError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Label
                style={{ color: "white" }}
                className="form-control-label"
                for="input-username"
              >
                Phone No *
              </Label>
              <FormGroup>
                <PhoneInput
                  className="input-group-alternative"
                  onChange={(e) => onChange(e, "phone")}
                  onBlur={() => CheckFields("phone")}
                  inputStyle={{
                    border: "none",
                    width: "100%",
                    height: "auto",
                  }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  fullWidth="true"
                  //  containerStyle={{border:"none"}}
                  countryCodeEditable={false}
                  country={"pk"}
                  // value={state.phone}
                />

                {error.phoneError !== "" && error.phoneError !== null && (
                  <small style={{ marginTop: "2px" }}>
                    <span style={{ color: "red" }}>
                      {error.phoneError}{" "}
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </small>
                )}
              </FormGroup>
              <Row>
                <Col lg="6" md="6" sm="12">
                  <Label
                    style={{ color: "white" }}
                    className="form-control-label"
                    for="input-username"
                  >
                    WhatsApp No.
                  </Label>
                  <FormGroup>
                    <PhoneInput
                      disabled={checked}
                      className="input-group-alternative"
                      onChange={(e) => onChange(e, "whatsAppNo")}
                      // onBlur={() => CheckFields("whatsAppNo")}
                      inputStyle={{
                        width: "100%",
                        border: "none",
                        height: "43px",
                      }}
                      inputExtraProps={{
                        name: "whatsAppNo",
                        required: true,
                        autoFocus: true,
                      }}
                      // styl
                      fullWidth="true"
                      //  containerStyle={{border:"none"}}
                      countryCodeEditable={false}
                      country={"pk"}
                      value={state.whatsAppNo}
                      // value={state.phone}
                    />

                    {error.whatsAppNoError !== "" &&
                      error.whatsAppNoError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.whatsAppNoError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                    {state.phone?.length == 12 ? (
                      <div style={{ position: "relative", float: "left" }}>
                        <input
                          type="checkbox"
                          onClick={handleChecked}
                          // disabled={isUserExist}
                        />
                        <small>
                          <b> Same As Phone No</b>
                        </small>
                      </div>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <Label
                    style={{ color: "white" }}
                    className="form-control-label"
                    for="input-username"
                  >
                    Email *
                  </Label>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Enter email here"
                        type="email"
                        onBlur={() => CheckFields("email")}
                        value={state.email}
                        onChange={(e) => onChange(e.target.value, "email")}
                      />
                    </InputGroup>
                    {error.emailError !== "" && error.emailError !== null && (
                      <small>
                        <span style={{ color: "red" }}>
                          {error.emailError}{" "}
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6" md="6" sm="12">
                  <Label
                    style={{ color: "white" }}
                    className="form-control-label"
                    for="input-username"
                  >
                    Create Password *
                  </Label>
                  <FormGroup>
                    <InputGroup
                      style={{ backgroundColor: "white" }}
                      className="input-group-alternative"
                    >
                      <Input
                        placeholder="Enter password"
                        type={type ? "text" : "password"}
                        onBlur={() => CheckFields("password")}
                        value={state.password}
                        onChange={(e) => onChange(e.target.value, "password")}
                      />
                      <span
                        className="password__show"
                        onClick={handleClick}
                        style={{
                          paddingTop: "10px",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {type == false ? (
                          <i class="fa fa-eye"></i>
                        ) : (
                          <i class="fas fa-eye-slash"></i>
                        )}
                      </span>
                    </InputGroup>
                    {error.passwordError !== "" &&
                      error.passwordError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.passwordError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <Label
                    style={{ color: "white" }}
                    className="form-control-label"
                    for="input-username"
                  >
                    Re-enter Password *
                  </Label>
                  <FormGroup>
                    <InputGroup
                      style={{ backgroundColor: "white" }}
                      className="input-group-alternative"
                    >
                      <Input
                        type={type1 ? "text" : "password"}
                        placeholder="Re-enter Password"
                        onBlur={() => CheckFields("confirmPassword")}
                        value={state.confirmPassword}
                        onChange={(e) =>
                          onChange(e.target.value, "confirmPassword")
                        }
                      />
                      <span
                        className="password__show"
                        onClick={handleClick1}
                        style={{
                          paddingTop: "10px",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {type1 == false ? (
                          <i class="fa fa-eye"></i>
                        ) : (
                          <i class="fas fa-eye-slash"></i>
                        )}
                      </span>
                    </InputGroup>
                    {error.confirmPasswordError !== "" &&
                      error.confirmPasswordError !== null && (
                        <small style={{ marginTop: "2px" }}>
                          <span style={{ color: "red" }}>
                            {error.confirmPasswordError}{" "}
                            <i className="fas fa-exclamation-circle"></i>
                          </span>
                        </small>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  style={{ backgroundColor: "#015652", color: "white" }}
                  className="my-1"
                  type="button"
                  disabled={loading}
                  onClick={SignUp}
                >
                  {loading ? <Loading attr="Sign Up" /> : "Sign Up"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  // GetUserJWTLogin: state.JwtCredential.GetUserJWTLogin
});
const mapDispatchToPrpos = (dispatch) => {
  return {
    SignUpMiddleware: (body, OnSuccess, OnFailure) =>
      dispatch(SignUpMiddleware(body, OnSuccess, OnFailure)),
  };
};
export default connect(mapStateToProps, mapDispatchToPrpos)(SignUp);
