import React, { useEffect, useRef, useState } from "react";
// import Loader from "react-loader-spinner";
// import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table, Label } from "reactstrap";
import swal from "sweetalert";
// import ComponentToPrint from "./ComponentToPrint";
import Information from "./Information";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
const TableFeedbackListView = ({ post, onChangeNoOfRows }) => {
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewReqList, setViewReqList] = useState("");
  console.log("DATA AT TABLE", post);
  const ToggleViewReqListModal = (post) => {
    setIsViewModalOpen(!isViewModalOpen);
    setViewReqList(post);
  };
  return (
    <div>
      <Information
        toggle={ToggleViewReqListModal}
        modal={isViewModalOpen}
        viewReqList={viewReqList}
      />

      {/* <ComponentToPrint
        ref={componentRef}
        // printReceiptInfo={printReceiptInfo}
      /> */}
      <Row className="mt-0">
        <Col xl="2" lg="4" md="4" sm="6" xs="6">
          <Label className="form-control-label"> Rows Per Pages </Label>
          <Input
            id="exampleFormControlSelect1"
            type="select"
            onChange={(e) => onChangeNoOfRows(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Input>
        </Col>
      </Row>
      <br />
      <Table className="align-items-center" responsive>
        <thead style={{ backgroundColor: "#d69929 ", color: "white" }}>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Subject</th>
            {/* <th scope="col">Date</th> */}
            {/* <th scope="col"></th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {post !== null && post !== undefined && post.length > 0 ? (
            post.map((request, index) => {
              return (
                <tr key={request.id}>
                  <td>{request?.id}</td>
                  <td>{request?.name}</td>
                  <td>{request?.email}</td>
                  <td>{request?.subject}</td>
                  {/* <td>
                    {moment(request?.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td> */}

                  <td>
                    <Button
                      style={{ backgroundColor: "#015652", color: "white" }}
                      id="search"
                      size="sm"
                      onClick={() => ToggleViewReqListModal(request)}
                    >
                      <span className="btn-inner--text"></span>
                      <span className="btn-inner--icon">View</span>
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <h3>No data found</h3>
              </th>
              <th></th>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableFeedbackListView;
