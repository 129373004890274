


export const AGENTLIST = "AGENTLIST";
export const AGENTLIST_SUCCESS = "AGENTLIST_SUCCESS";
export const AGENTLIST_FAILURE = "AGENTLIST_FAILURE";
export const AGENTLIST_PATH = "real-estate/for-admin";

export const AGENTVERIFY = "AGENTVERIFY";
export const AGENTVERIFY_SUCCESS = "AGENTVERIFY_SUCCESS";
export const AGENTVERIFY_FAILURE = "AGENTVERIFY_FAILURE";
export const AGENTVERIFY_PATH = "real-estate/verify-estate-agent/for-admin";


