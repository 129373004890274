import axios from "axios";

import {
  ApplicationListDetail,
  FormSubmitDetail,
  //UploadImageDetail
  getChalanDataAction,
} from "../action/index";

import {
  APPLICATIONLIST_PATH,
  FORMSUBMIT_PATH,
  // UPLOADIMAGES_PATH
  CHALAN_DATA_PATH,
} from "../constant";

import { BASEURL } from "config/api/URL";

// export const UploadImageMiddleware =
//   (body, onSuccess, onFailure) => (dispatch) => {

//     dispatch(UploadImageDetail.UploadImage());
//     let token = localStorage.getItem("auth");
//     axios
//       .post(
//         `${BASEURL}${UPLOADIMAGES_PATH}`,body,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `bearer ${token}`,
//           },
//         }
//       )
//       .then((res) => {

//         dispatch(UploadImageDetail.UploadImage_Success(res.data.data));
//         dispatch(onSuccess(res.data.data));

//       })
//       .catch(function (error) {
//         if (error.response) {
//           onFailure(error.response.data);
//           console.log(error.response.data)
//         } else if (error.request) {}
//         else {}
//       });

//   };

export const FormSubmitMiddleware =
  (body, onSuccess, onFailure) => (dispatch) => {
    // console.log("SUCESS", body);
    dispatch(FormSubmitDetail.FormSubmit());
    let token = localStorage.getItem("auth");
    axios
      .post(`${BASEURL}${FORMSUBMIT_PATH}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(FormSubmitDetail.FormSubmit_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data.message);
        } else if (error.request) {
        } else {
        }
      });
  };

export const GetApplicationListMiddleware =
  (pageNumber, noOfRows, valuesAndKey, onSuccess, onFailure) => (dispatch) => {
    dispatch(ApplicationListDetail.Applicationlist());
    let token = localStorage.getItem("auth");
    let path = `${BASEURL}${APPLICATIONLIST_PATH}?page=${pageNumber}&limit=${noOfRows}&${valuesAndKey?.key}=${valuesAndKey?.value},`;
    console.log("middleware");
    axios
      .get(
        `${BASEURL}${APPLICATIONLIST_PATH}?page=${pageNumber}&limit=${noOfRows}&${valuesAndKey?.key}=${valuesAndKey?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("RESDATA", res.data);
        dispatch(
          ApplicationListDetail.Applicationlist_Success(res.data.data.items)
        );
        dispatch(onSuccess(res.message));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
          console.log(error.response);
        } else if (error.request) {
        } else {
        }
      });
  };

export const getChalanDataMiddleware =
  (id, onSuccess, onFailure) => (dispatch) => {
    dispatch(getChalanDataAction.Fetch());
    let token = localStorage.getItem("auth");
    axios
      .get(`${BASEURL}${CHALAN_DATA_PATH}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESDATA", res.data);
        dispatch(getChalanDataAction.Fetch_Success(res.data.data));
        dispatch(onSuccess(res.data.data));
      })
      .catch(function (error) {
        if (error.response) {
          onFailure(error.response.data);
          console.log(error.response);
        }
      });
  };
